import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../elements/Button';
import { bindActionCreators } from 'redux';
import { getCreditCards, linkCustomer } from '../../../store/actions/memberActions';

class ShowCreditCardsModal extends Component {

    submit = () => {
        const { phoneNumber, setModal, boundGetCreditCards, boundLinkCustomer } = this.props;
        boundLinkCustomer(phoneNumber, setModal);
        
        setModal('');
    };

    cancel = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        const { phoneNumber } = this.props;
        return (
            <div className="modal-content show-cards-modal">
                             <div className="masterpass-logo">
                    <img src="/images/masterpass.svg" alt="masterpass.svg"/>
                </div>
                                <div className="modal-content__header  center">
                    Bilgilendirme
                </div>
                <div className="popup__detail show-cards-modal__text">
                Masterpass'te kayıtlı kartlarınızı Little Ceasers'ta kullanmak ister misiniz?                   
                </div>
   
                <div className="show-cards-modal__buttons-row center">
                <div className="show-cards-modal__button" >
                        <Button color="white" onClick={this.cancel}>
                            Hayır
                        </Button>
                    </div>
                    <div className="show-cards-modal__button" >
                        <Button color="orange" onClick={this.submit}>
                            Evet
                        </Button>
                    </div>
                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    phoneNumber: `90${store.user.user.personPhones[0].number}`
});

const mapDispatchToProps = dispatch => ({
    boundGetCreditCards: bindActionCreators(getCreditCards, dispatch),
    boundLinkCustomer: bindActionCreators(linkCustomer, dispatch),
});

ShowCreditCardsModal.propTypes = {
    boundGetCreditCards: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowCreditCardsModal);