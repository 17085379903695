import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPromotionCode } from '../../store/actions/productActions';
import { getBasket } from '../../store/actions/basketActions';
import TextInput from '../elements/TextInput';
import Button from '../elements/Button';
import { withRouter } from 'react-router-dom';

class AddCouponModal extends Component {
    state = {
        couponCode: ''
    };

    submit = () => {
        const { boundGetPromotionCode, boundGetBasket, setModal, history } = this.props;
        const { couponCode } = this.state;

        boundGetPromotionCode(couponCode).then(response =>
            boundGetBasket().then(() => {
                setModal('');
                history.push(response.data.result.redirectUri);
            }));
    };

    couponCodeInputHandler = event => this.setState({ couponCode: event.target.value });

    render() {
        const { couponCode } = this.state;

        return (
            <div className="modal-content add-coupon-modal">
                <div className="add-coupon-modal__image-container">
                    <img src="/images/homepage/coupon-code.png" alt="" className="add-coupon-modal__image"/>
                </div>
                <div className="add-coupon-modal__body">
                    <div className="add-coupon-modal__header">
                        Kupon Kodu Kullan
                    </div>
                    <div className="add-coupon-modal__subheader">
                        Kazandığın kupon kodunu buraya girerek avantajlı ürünlerden yararlanabilirsin.
                    </div>
                    <TextInput placeholder="Kupon kodunu yaz"
                               value={couponCode}
                               onChange={this.couponCodeInputHandler}
                               containerClassName="add-coupon-modal__input"
                    />
                    <Button onClick={this.submit} className="add-coupon-modal__button">
                        Gönder
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({
    boundGetPromotionCode: bindActionCreators(getPromotionCode, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch)
});

AddCouponModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    boundGetPromotionCode: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCouponModal));
