import api, { saveAuthToken, handleRequest, saveCustomerPhoneId } from '../../api';
import { getUserPermissionValue } from '../../utils';

export const SET_USER = 'SET_USER';
export const SET_FORGOT_PASSWORD_EMAIL = 'SET_FORGOT_PASSWORD_EMAIL';
export const SET_RESET_PASSWORD_HASH = 'SET_RESET_PASSWORD_HASH';
export const SET_IS_UPDATE_USERS_DATA = 'SET_IS_UPDATE_USERS_DATA';
export const SET_IS_NEW_USER = 'SET_IS_NEW_USER';
export const SET_IS_CHECKOUT = 'SET_IS_CHECKOUT';

export const setUser = user => ({
    type: SET_USER,
    payload: user
});

export const setForgotPasswordEmail = email => ({
    type: SET_FORGOT_PASSWORD_EMAIL,
    payload: email
});

export const setResetPasswordHash = hash => ({
    type: SET_RESET_PASSWORD_HASH,
    payload: hash
});

export const setIsUpdateUsersData = isUpdateUsersData => ({
    type: SET_IS_UPDATE_USERS_DATA,
    payload: isUpdateUsersData
});

export const setIsNewUser = isNewUser => ({
    type: SET_IS_NEW_USER,
    payload: isNewUser
});

export const setIsCheckout = isCheckout => ({
    type: SET_IS_CHECKOUT,
    payload: isCheckout
});

export const getUser = () => dispatch =>
    api.get('web/Member/GetMemberInfo', dispatch)
        .then(response => {
            return handleRequest(response, () => dispatch(setUser(response.data.result)));
        });

export const updateUser = (name, email, phone, birthDate, genderTypeId, genderType, approveMailInform, approveSmsInform) => dispatch =>
    api.put('web/Member/UpdateMemberInfo', {
        Email: email,
        NameSurname: name,
        Phone: phone,
        Birthdate: birthDate,
        genderType,
        genderTypeId,
        approveMailInform,
        approveSmsInform
    }, dispatch)
        .then(response => handleRequest(response));

export const updateUserPermissions = (user, permissions) => dispatch =>
    api.put(
        'web/Member/UpdateMemberMarketingPermissions',
        {
            CampaignInform: getUserPermissionValue(user, 'CampaignInform'),
            SmsInform: getUserPermissionValue(user, 'SmsInform'),
            IsLoyaltyApproved: getUserPermissionValue(user, 'IsLoyaltyApproved'),
            ...permissions
        },
        dispatch
    )
        .then(response => handleRequest(response));

export const changePassword = (oldPassword, newPassword) => dispatch =>
    api.put('web/Member/ChangePassword', { oldPassword, newPassword }, dispatch)
        .then(response => handleRequest(response));

export const deactivateUser = () => dispatch =>
    api.put('web/Member/DeactivateMember', null, dispatch)
        .then(response => handleRequest(response));

export const login = (email, password) => dispatch =>
    api.post('web/Member/Login', { Username: email, Password: password }, dispatch)
        .then(response => handleRequest(response, () => {
            saveAuthToken(response.data.result.token, false);
            return dispatch(setUser(response.data.result));
        }));

export const register = (nameSurname, email, phone, password, isMailingConfirmed, isSmsConfirmed, isLoyaltyConfirmed) => dispatch =>
    api.post('web/Member/Register', {
        NameSurname: nameSurname,
        Email: email,
        Phone: phone,
        Password: password,
        CampaignInform: isMailingConfirmed,
        SmsInform: isSmsConfirmed,
        IsLoyaltyApproved: isLoyaltyConfirmed
    }, dispatch)
        .then(response => {
                return handleRequest(response, () => {
                    saveAuthToken(response.data.result.token, false);
                    dispatch(setUser(response.data.result));
                    return response;
                });
            }
        );

export const requestPasswordReset = email => dispatch =>
    api.post(`web/Member/ForgotPassword?email=${email}`, null, dispatch)
        .then(response => handleRequest(response, () => {
            dispatch(setForgotPasswordEmail(email));
            return response;
        }));

export const resetPassword = (email, resetHash, newPassword) => dispatch =>
    api.post('web/Member/ResetPassword', { email, resetHash, newPassword }, dispatch)
        .then(response => handleRequest(response));

export const loginWithFacebook = accessToken => dispatch =>
    api.get('web/Member/FacebookConnectLogin?accessToken=' + accessToken, dispatch)
        .then(response => handleRequest(response, () => {
            saveAuthToken(response.data.result.token, false);
            return dispatch(setUser(response.data.result));
        }));

export const registerWithFacebook = (accessToken) => dispatch =>
    api.get('web/Member/FacebookConnect?accessToken=' + accessToken, dispatch)
        .then(response => handleRequest(response, () => {
            saveAuthToken(response.data.result.token, false);
            return dispatch(setUser(response.data.result));
        }));

export const getCustomerPhoneId = phoneNumber => dispatch =>
    api.get('web/Member/VerifyPhoneNumber?phoneNumber=' + phoneNumber, dispatch)
        .then(response => handleRequest(response, () => {
            saveCustomerPhoneId(response.data.result);
        }));

export const checkPhoneСonfirmedSmsCode = (id, code) => dispatch =>
    api.post('web/Member/VerifyDigitalCode ', { id, code }, dispatch)
        .then(response => handleRequest(response));
