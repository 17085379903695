import { SET_PRODUCT_DEPARTMENTS, SET_PRODUCT_SIZES, SET_PROMOTION_CODE } from '../actions/productActions';

const initialState = {
  productDepartments: [],
  productSizes: [],
  promotionCode: ''
};

const productReducer = (state = initialState, action) => {
     
    switch(action.type) {
      case SET_PRODUCT_DEPARTMENTS:
        return {
          ...state,
          productDepartments: action.payload
        }

      case SET_PRODUCT_SIZES:
        return {
          ...state,
          productSizes: action.payload
        }

      case SET_PROMOTION_CODE:
          return {
            ...state,
            promotionCode: action.payload
          }

      default: 
      return state
    }
}

export default productReducer
