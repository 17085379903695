import api, { handleRequest } from "../../api";
import { getFromLocalStorage } from "../../storage";

export const SET_BASKET = 'SET_BASKET';

export const setBasket = response => ({
    type: SET_BASKET,
    payload: response
});

export const getBasket = (shouldGetNewBasketId = false) => dispatch => {
    const basketId = JSON.parse(getFromLocalStorage('basketId')) !== null ? JSON.parse(getFromLocalStorage('basketId')): null;
    const query = basketId && !shouldGetNewBasketId ? `?existingOrderId=${basketId}` : '';
    return api.get(`web/Basket/GetBasket${query}`, dispatch)
        .then(response => handleRequest(response, () => dispatch(setBasket(response.data.result))));
};

export const getBasketWOPromotion = (shouldGetNewBasketId = false) => dispatch => {
    const basketId = JSON.parse(getFromLocalStorage('basketId')) !== null ? JSON.parse(getFromLocalStorage('basketId')): null;
    const query = basketId && !shouldGetNewBasketId ? `?existingOrderId=${basketId}&applyPromotions=false` : '?applyPromotions=false';
    return api.get(`web/Basket/GetBasket${query}`, dispatch)
        .then(response => handleRequest(response, () => dispatch(setBasket(response.data.result))));
};

export const addToBasket = (product, orderId) => dispatch =>
    api.post('web/Basket/AddToBasket', { ...product, existingOrderId: orderId }, dispatch)
        .then(response => handleRequest(response));

export const updateBasketCount = (orderItemId, quantity) => dispatch =>
    api.post('web/Basket/UpdateBasketCount', { OrderItemId: orderItemId, Quantity: quantity }, dispatch)
        .then(response => handleRequest(response));

export const removeFromBasket = (orderItemId, basketId) => dispatch =>
    api.delete(`web/Basket/RemoveFromBasket?id=${orderItemId}&existingOrderId=${basketId}`, dispatch)
        .then(response => handleRequest(response));

export const addToBasketByProductId = (orderItemId, basketId) => dispatch =>
    api.get(`web/Basket/AddToBasketWithProductId?productId=${orderItemId}&existingOrderId=${basketId}`, dispatch)
        .then(response => handleRequest(response));

export const applyPromotionCode = (orderId, promotionCode) => dispatch =>
    api.post('web/Basket/AddPromotion', { OrderId: orderId, PromotionCode: promotionCode }, dispatch)
         .then(response => handleRequest(response));

export const removePromotionCode = orderId => dispatch =>
    api.post('web/Basket/RemovePRomotion?orderPromotionId=' + orderId, {}, dispatch)
         .then(response => handleRequest(response));

export const selectPromotionCode = (orderId, promotionCode) => dispatch =>
    api.post('web/Basket/SelectPromotion?promotion=' + promotionCode + '&_OrderID=' + orderId, {}, dispatch)
         .then(response => handleRequest(response));
