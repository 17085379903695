import React from 'react';
import CorporateMenu from '../../corporate-menu/CorporateMenu';
import BreadCrumbs from '../../../elements/BreadCrumbs';
import { sendPageView } from '../../../../googleAnalyticsHelper';

const Iso9001 = () => {
    sendPageView('/kurumsal/iso-9001-2015-kalite-belgesi', 'iso-9001 Kalite Belgesi - Little Caesars'); //for ga
    return <section class="institutional-structures">
        <div class="container">
            <BreadCrumbs />
            <div class="institutional-structures-detail">
                <div class="institutional-structures-detail-content clearfix">
                    <CorporateMenu menuType='quality' />
      
                    <div class="col-md-9">
                        <div class="corpareta-detail">
                            <h1>ISO 9001 : 2015 Kalite Belgesi</h1>
                            <div class="row">
      
                                <div class="col-sm-3 col-xs-6">
                                    <a class="certificate" target="_blank" href="/images/kurumsal/kalite/iso-9001/big/celebi-iso-9001-2015.png">
                                        <img class="img-responsive" src="/images/kurumsal/kalite/iso-9001/celebi-iso-9001-2015-thumb.png" alt="celebi-iso-9001-2015-thumb.png" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
};

export default Iso9001;