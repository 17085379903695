import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CorporateMenu from '../../corporate-menu/CorporateMenu';
import TextInput from '../../../elements/TextInput';
import getValidator from '../../../../getValidator';
import LcPhoneInput from '../../../elements/LcPhoneInput';
import DropdownList from '../../../elements/DropdownList';
import { bindActionCreators } from 'redux';
import { getCities, getAllCities, getAllTownsOfCity, getRestaurants } from '../../../../store/actions/addressActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendJobApplication } from '../../../../store/actions/corporateActions';
import Checkbox from '../../../elements/Checkbox';
import Button from '../../../elements/Button';
import { toast } from 'react-toastify';
import Toast from '../../../elements/Toast';

const militaryStatusList = [{ id: 1, name: 'Yapıldı' }, { id: 2, name: 'Yapılmadı' }, { id: 3, name: 'Muaf' }];

const jobDepartmentList = [
    { label: 'Mutfak Elemanı', value: 'Mutfak Elemanı' },
    { label: 'Motorcu', value: 'Motorcu' },
    { label: 'Vardiya Müdürü', value: 'Vardiya Müdürü' },
    { label: 'Asistan Müdür', value: 'Asistan Müdür' },
    { label: 'Restoran Müdür', value: 'Restoran Müdür' },
    { label: 'Genel Müdürlük', value: 'Genel Müdürlük' },
    { label: 'Staj', value: 'Staj' }
];

const drivingLicenceTypeList = [
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' }
];

const educationList = [
    { label: 'İlköğretim', value: 'İlköğretim' },
    { label: 'Ortaöğretim', value: 'Ortaöğretim' },
    { label: 'Lise', value: 'Lise' },
    { label: 'Yükseköğrenim', value: 'Yükseköğrenim' },
    { label: 'Lisans', value: 'Lisans' },
    { label: 'Lisans Üstü', value: 'Lisans Üstü' },
    { label: 'Doktora', value: 'Doktora' }
];

class ApplicationForm extends Component {
    validator = getValidator(this);
    // TODO: add auto scroll to first wrong validated field when click submit (as in checkout)

    state = {
        name: '',
        surname: '',
        birthDate: '',
        email: '',
        phone: '',
        militaryStatus: '',
        address: '',
        jobCity: '',
        jobDepartment: '',
        jobCityList: [],
        drivingLicence: 'Var',
        drivingLicenceType: 'B1',
        education: '',
        school: '',
        lastJobCompanyName: '',
        lastJobTitle: '',
        lastJobQuitReason: '',
        lastJobStartDate: '',
        lastJobEndDate: '',
        resumeFile: null,
        referenceName: '',
        referenceRelativity: '',
        referenceJobCompany: '',
        referenceJobTitle: '',
        referencePhone: '',
        agreeWithJobLaws: false,
        city: '',
        cityList: [],
        townList: [],
        town: '',
        isContinueLastJobWork: false,
        isInexperienced: false,
        restaurantList: [],
        restaurantName: ''
    };

    componentDidMount() {
        const { boundGetRestaurants, boundGetAllCities, boundGetCities } = this.props;
        boundGetAllCities().then(response => {
            let cityList = [];
            if (response && response.data && response.data.result && response.data.result.length) {
                for (let i = 0; i < response.data.result.length; i++) {
                    let city = response.data.result[i];
                    cityList.push({
                        label: city.cname,
                        value: city.cid
                    });
                }
                this.setState({
                    cityList: cityList
                });
            }
        });
        boundGetCities().then(response => this.setState({
            jobCityList: response.data.result
        }));
        boundGetRestaurants();
    }

    nameHandler = event => this.setState({ name: event.target.value });

    surnameHandler = event => this.setState({ surname: event.target.value });

    birthDateHandler = event => this.setState({ birthDate: event.target.value.replace(/\./g, '') });

    emailHandler = event => this.setState({ email: event.target.value });

    phoneHandler = phone => this.setState({ phone });

    militaryStatusHandler = militaryStatus => this.setState({ militaryStatus });

    addressHandler = event => this.setState({ address: event.target.value });

    cityHandler = cityId => {
        this.setState({ city: cityId, townList: [] });
        this.props.boundGetAllTownsOfCity(cityId).then(response => {
            const list = response.data.result;
            const townList = [];

            for (var i = 0; i < list.length; i++) {
                townList.push({
                    label: list[i].tname,
                    value: list[i].tid
                });
            }

            this.setState({ townList });
        });
    };

    townHandler = town => this.setState({ town });

    jobCityHandler = jobCity => {
        const { addressData, loading } = this.props;
        const restaurants = addressData.restaurants.filter(c => c.townId === jobCity);
        this.setState({ jobCity, restaurantList: restaurants });
    };

    jobRestaurantNameHandler = restaurantName => this.setState({ restaurantName });

    jobDepartmentHandler = jobDepartment => this.setState({ jobDepartment });

    getToggleDrivingLicence = drivingLicence => () => this.setState({ drivingLicence });

    drivingLicenceTypeHandler = drivingLicenceType => this.setState({ drivingLicenceType });

    educationHandler = education => this.setState({ education });

    schoolHandler = event => this.setState({ school: event.target.value });

    getToggleIsInexperienced = isInexperienced => () => {
      this.validator.purgeFields();
      this.setState(prevState => ({ isInexperienced: !prevState.isInexperienced }));
    };

    lastJobCompanyNameHandler = event => this.setState({ lastJobCompanyName: event.target.value });

    lastJobTitleHandler = event => this.setState({ lastJobTitle: event.target.value });

    lastJobQuitReasonHandler = event => this.setState({ lastJobQuitReason: event.target.value });

    lastJobStartDateHandler = event => this.setState({ lastJobStartDate: event.target.value.replace(/\./g, '') });

    getToggleIsContinueLastJobWork = isContinueLastJobWork => () => {
        this.validator.purgeFields();
        this.setState(prevState => ({ isContinueLastJobWork: !prevState.isContinueLastJobWork }));
    };

    lastJobEndDateHandler = event => this.setState({ lastJobEndDate: event.target.value.replace(/\./g, '') });

    resumeFileHandler = event => event.target.files.length && this.setState({ resumeFile: event.target.files[0] });

    referenceNameHandler = event => this.setState({ referenceName: event.target.value });

    referenceRelativityHandler = event => this.setState({ referenceRelativity: event.target.value });

    referenceJobCompanyHandler = event => this.setState({ referenceJobCompany: event.target.value });

    referenceJobTitleHandler = event => this.setState({ referenceJobTitle: event.target.value });

    referencePhoneHandler = referencePhone => this.setState({ referencePhone });

    toggleAgreeWithJobLaws = () => this.setState({ agreeWithJobLaws: !this.state.agreeWithJobLaws });

    submit = () => {
        const { boundSendJobApplication } = this.props;
        const {
            name, surname, birthDate, email, phone, militaryStatus, address, jobCity, jobDepartment,
            drivingLicence, drivingLicenceType, education, school, lastJobCompanyName, lastJobTitle, lastJobQuitReason,
            lastJobStartDate, lastJobEndDate, resumeFile, referenceName, referenceRelativity, referenceJobCompany,
            referenceJobTitle, referencePhone, city, town, restaurantName
        } = this.state;

        this.validator.showMessages();
        if (this.validator.allValid()) {
            const body = {
                NameSurname: `${name} ${surname}`,
                BirthDate: birthDate,  // date?
                // BirthDate: new Date(birthDate),
                Address: address,
                CityAddress: city,
                TownAddress: town,
                MilitaryStatus: militaryStatus,
                EMail: email,
                Phone: phone,
                DrivingLicence: drivingLicence === 'Var' ? drivingLicenceType : null,
                City: jobCity,
                JobRestaurant: restaurantName,
                Department: jobDepartment,
                Education: education,
                School: school,
                LastJobCompanyName: lastJobCompanyName,
                LastJobTitle: lastJobTitle,
                LastJobStartDate: lastJobStartDate,
                LastJobEndDate: lastJobEndDate,
                LastJobQuitReason: lastJobQuitReason,
                ResumeFile: resumeFile ?? '', // ResumeFile.name?
                ReferenceName: referenceName,
                ReferenceRelativity: referenceRelativity,
                ReferenceJobCompany: referenceJobCompany,
                ReferenceJobTitle: referenceJobTitle,
                ReferencePhone: referencePhone
            };
            const formData = new FormData();
            for (let field in body) {
                if (typeof body[field] === 'object' && body[field].name) {
                    formData.append(field, body[field], body[field].name);
                } else {
                    formData.append(field, body[field]);
                }
            }

            boundSendJobApplication(formData).then(() => toast(
                <Toast text="Başvunuz alınmıştır. Teşekkürler." status="success"/>,
                { autoClose: 3000 }
            ));
        }
    };

    formateDate = date => {
        if (date.length > 2) {
            return [0, 2, 4].map((n, i, a) => date.slice(n, a[i + 1])).join('.');
        } else {
            return date;
        }
    };

    render() {
        const {
            name, surname, birthDate, email, phone, militaryStatus, address, jobCity, jobDepartment, jobCityList,
            drivingLicence, drivingLicenceType, education, school, lastJobCompanyName, lastJobTitle, lastJobQuitReason,
            lastJobStartDate, lastJobEndDate, resumeFile, referenceName, referenceRelativity, referenceJobCompany,
            referenceJobTitle, referencePhone, agreeWithJobLaws, city, cityList, townList, town, isContinueLastJobWork, isInexperienced, restaurantName, restaurantList
        } = this.state;

        return <div className="app-page-container static-page-container">
            <div className="app-content-container static-page">
                <CorporateMenu/>
                <div className="static-page__body">
                    <div className="static-page__top-block">
                        <h1 className="static-page__header">Başvuru Formu</h1>
                        <h1 className="static-page__subheader">Ana sayfa I İnsan Kaynakları I Başvuru Formu</h1>
                        <img className="static-page__image"
                             src="/images/corporate/basvuru-formu.jpg"
                             alt="Little Caesars Türkiye Hakkında"
                        />
                        <div className="static-page__text-header">
                            Little Caesars Türkiye’de Kariyer
                        </div>
                        <div className="static-page__text">
                            1996’dan beri Çelebi Holding yönetiminde hızlı ve sağlam adımlarla büyümesini sürdüren,
                            Amerika’nın en hızlı büyüyen pizza şirketi Little Caesars’daki kariyer fırsatlarını
                            yakalamak ve
                            bizimle büyümek istiyorsanız özgeçmiş gönderebilir ya da açık pozisyonlarımızı inceleyerek
                            başvuru yapabilirsiniz.
                        </div>
                    </div>
                    <div className="application-form">
                        <div className="application-form__header">
                            Başvuru Formu
                        </div>
                        <div className="application-form__subheader">
                            Aşağıdaki formu doldurarak başvuruda bulunabilirsiniz.
                        </div>

                        <div className="application-form__block">
                            <div className="application-form__block-header">
                                Kişisel Bilgiler
                            </div>
                            <TextInput label="İsim"
                                       labelClassName="application-form__input-label"
                                       value={name}
                                       onChange={this.nameHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('İsim', name, 'required')}
                            />
                            <TextInput label="Soyad"
                                       labelClassName="application-form__input-label"
                                       value={surname}
                                       onChange={this.surnameHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('Soyad', surname, 'required')}
                            />
                            <TextInput label="Doğum Tarihi"
                                       labelClassName="application-form__input-label"
                                       value={this.formateDate(birthDate)}
                                       onChange={this.birthDateHandler}
                                       containerClassName="application-form__input"
                                       maxLength={10}
                                       placeholder="01.01.1980"
                                       error={this.validator.message('Doğum Tarihi', birthDate, 'required')}
                            />
                            <TextInput label="E-Posta"
                                       labelClassName="application-form__input-label"
                                       value={email}
                                       onChange={this.emailHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('E-Posta', email, 'required|email')}
                                       placeholder="ornek@gmail.com"
                            />
                            <LcPhoneInput label="Telefon"
                                          labelClassName="application-form__input-label"
                                          value={phone}
                                          onChange={this.phoneHandler}
                                          className="application-form__input"
                                          validator={this.validator}
                            />
                            <DropdownList label="Askerlik"
                                          labelClassName="application-form__input-label"
                                          value={militaryStatus}
                                          onChange={this.militaryStatusHandler}
                                          options={militaryStatusList}
                                          containerClassName="application-form__input application-form__input--dropdown"
                                          optionClassName="application-form__dropdown-option"
                                          error={this.validator.message('Askerlik', militaryStatus, 'required')}
                            />
                            <TextInput label="Adres"
                                       labelClassName="application-form__input-label"
                                       value={address}
                                       onChange={this.addressHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('Adres', address, 'required')}
                            />
                            <DropdownList label="İl"
                                          labelClassName="application-form__input-label"
                                          value={city}
                                          placeholder="İl"
                                          onChange={this.cityHandler}
                                          containerClassName="application-form__input application-form__input--dropdown"
                                          optionClassName="application-form__dropdown-option"
                                          error={this.validator.message('Şehir', city, 'required')}
                                          options={cityList}
                            />
                            <DropdownList label="İlçe"
                                          labelClassName="application-form__input-label"
                                          value={town}
                                          placeholder="İlçe"
                                          onChange={this.townHandler}
                                          containerClassName="application-form__input application-form__input--dropdown"
                                          optionClassName="application-form__dropdown-option"
                                          error={this.validator.message('İlçe', town, 'required')}
                                          options={townList}
                            />
                            <DropdownList label="Çalışmak İstediğin Şehir"
                                          labelClassName="application-form__input-label"
                                          value={jobCity}
                                          onChange={this.jobCityHandler}
                                          options={jobCityList}
                                          containerClassName="application-form__input application-form__input--dropdown"
                                          optionClassName="application-form__dropdown-option"
                                          error={this.validator.message('Çalışmak İstediğin Şehir', jobCity, 'required')}
                            />
                            <DropdownList label="Çalışmak İstediğin Şube"
                                          labelClassName="application-form__input-label"
                                          value={restaurantName}
                                          onChange={this.jobRestaurantNameHandler}
                                          options={restaurantList}
                                          containerClassName="application-form__input application-form__input--dropdown"
                                          optionClassName="application-form__dropdown-option"
                            />
                            <DropdownList label="Çalışmak İstediğin Bölüm / Departman"
                                          labelClassName="application-form__input-label"
                                          value={jobDepartment}
                                          onChange={this.jobDepartmentHandler}
                                          options={jobDepartmentList}
                                          containerClassName="application-form__input application-form__input--dropdown"
                                          optionClassName="application-form__dropdown-option"
                                          error={this.validator.message('Çalışmak İstediğin Bölüm / Departman', jobDepartment, 'required')}
                            />
                        </div>

                        <div className="application-form__block">
                            <div className="application-form__block-header">
                                Ehliyet Bilgileri
                            </div>
                            <div className="application-form__checkboxes-block">
                                <div className="application-form__checkboxes-label">
                                    Ehliyetiniz var mı?
                                </div>
                                <div className="application-form__checkboxes-container">
                                    <div className="application-form__checkbox-radio">
                                        <Checkbox type="radio"
                                                  value={drivingLicence === 'Var'}
                                                  onChange={this.getToggleDrivingLicence('Var')}
                                        />
                                        <div className="application-form__checkbox-radio-label"
                                             onClick={this.getToggleDrivingLicence('Var')}
                                        >
                                            Var
                                        </div>
                                    </div>
                                    <div className="application-form__checkbox-radio">
                                        <Checkbox type="radio"
                                                  value={drivingLicence === 'Yok'}
                                                  onChange={this.getToggleDrivingLicence('Yok')}
                                        />
                                        <div className="application-form__checkbox-radio-label"
                                             onClick={this.getToggleDrivingLicence('Yok')}
                                        >
                                            Yok
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                drivingLicence === 'Var'
                                    ? <DropdownList label="Ehliyet Sınıfı"
                                                    labelClassName="application-form__input-label"
                                                    value={drivingLicenceType}
                                                    onChange={this.drivingLicenceTypeHandler}
                                                    options={drivingLicenceTypeList}
                                                    containerClassName="application-form__input application-form__input--dropdown"
                                                    optionClassName="application-form__dropdown-option"
                                                    error={this.validator.message('Ehliyet Sınıfı', drivingLicenceType, 'required')}
                                    />
                                    : null
                            }
                        </div>

                        <div className="application-form__block">
                            <div className="application-form__block-header">
                                Eğitim Bilgileri
                            </div>
                            <DropdownList label="Eğitim Bilgileri"
                                          labelClassName="application-form__input-label"
                                          value={education}
                                          onChange={this.educationHandler}
                                          options={educationList}
                                          containerClassName="application-form__input application-form__input--dropdown"
                                          optionClassName="application-form__dropdown-option"
                                          error={this.validator.message('Eğitim Bilgileri', education, 'required')}
                            />
                            <TextInput label="En son mezun olduğunuz okulun adı"
                                       labelClassName="application-form__input-label"
                                       value={school}
                                       onChange={this.schoolHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('En son mezun olduğunuz okulun adı', school, 'required')}
                            />
                        </div>

                        <div className="application-form__block">
                            <div className="application-form__block-header">
                                Son İş Deneyimi
                            </div>
                            <div className="application-form__checkboxes-block">
                                <div className="application-form__checkboxes-label">
                                    Deneyimsiz
                                </div>
                                <Checkbox type="radio"
                                          value={isInexperienced}
                                          onChange={this.getToggleIsInexperienced()}
                                />
                            </div>
                            {
                                !isInexperienced
                                    ? <>
                                        <TextInput label="İş Yeri Adı"
                                                   labelClassName="application-form__input-label"
                                                   value={lastJobCompanyName}
                                                   onChange={this.lastJobCompanyNameHandler}
                                                   containerClassName="application-form__input"
                                                   error={this.validator.message('İş Yeri Adı', lastJobCompanyName, 'required')}
                                        />
                                        <TextInput label="Görevi"
                                                   labelClassName="application-form__input-label"
                                                   value={lastJobTitle}
                                                   onChange={this.lastJobTitleHandler}
                                                   containerClassName="application-form__input"
                                                   error={this.validator.message('Görevi', lastJobTitle, 'required')}
                                        />
                                        <div className="application-form__block">
                                            <div className="application-form__checkboxes-block">
                                                <div className="application-form__checkboxes-label">
                                                    Halen Devam Ediyor
                                                </div>
                                                <Checkbox type="radio"
                                                          value={isContinueLastJobWork}
                                                          onChange={this.getToggleIsContinueLastJobWork()}
                                                />
                                            </div>
                                            <div className="application-form__row-input-group">
                                                <TextInput label="Başlama Tarihi"
                                                           labelClassName="application-form__input-label"
                                                           value={this.formateDate(lastJobStartDate)}
                                                           maxLength={10}
                                                           placeholder="01.01.2020"
                                                           onChange={this.lastJobStartDateHandler}
                                                           containerClassName="application-form__input"
                                                           error={this.validator.message('Başlama Tarihi', lastJobStartDate, 'required')}
                                                />
                                                {
                                                    !isContinueLastJobWork
                                                        ? <TextInput label="Ayrılma Tarihi"
                                                                     labelClassName="application-form__input-label"
                                                                     value={this.formateDate(lastJobEndDate)}
                                                                     maxLength={10}
                                                                     placeholder="01.01.2020"
                                                                     onChange={this.lastJobEndDateHandler}
                                                                     containerClassName="application-form__input"
                                                                     error={this.validator.message('Ayrılma Tarihi', lastJobEndDate, 'required')}
                                                        />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            !isContinueLastJobWork
                                                ? <TextInput label="Ayrılma Nedeni"
                                                             labelClassName="application-form__input-label"
                                                             value={lastJobQuitReason}
                                                             onChange={this.lastJobQuitReasonHandler}
                                                             containerClassName="application-form__input"
                                                             error={this.validator.message('Ayrılma Nedeni', lastJobQuitReason, 'required')}
                                                />
                                                : null
                                        }
                                    </>
                                    : null
                            }

                        </div>

                        <div className="application-form__block">
                            <div className="application-form__block-header">
                                Öz Geçmişinizi Yükleyin
                            </div>
                            <input type="file"
                                   className="application-form__upload-button"
                                   onChange={this.resumeFileHandler}
                                   placeholder="Yükle"
                                   id="file-upload"
                            />
                            <label htmlFor="file-upload" className="application-form__upload-button-label">
                                {
                                    resumeFile
                                        ? resumeFile.name
                                        : 'Yükle'
                                }
                            </label>
                        </div>

                        <div className="application-form__block">
                            <div className="application-form__block-header">
                                Referans
                            </div>
                            <TextInput label="Ad Soyad"
                                       labelClassName="application-form__input-label"
                                       value={referenceName}
                                       onChange={this.referenceNameHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('Ad Soyad', referenceName, 'required')}
                            />
                            <TextInput label="Yakınlık Derecesi"
                                       labelClassName="application-form__input-label"
                                       value={referenceRelativity}
                                       onChange={this.referenceRelativityHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('Yakınlık Derecesi', referenceRelativity, 'required')}
                            />
                            <TextInput label="İş Yeri Adı"
                                       labelClassName="application-form__input-label"
                                       value={referenceJobCompany}
                                       onChange={this.referenceJobCompanyHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('İş Yeri Adı', referenceJobCompany, 'required')}
                            />
                            <TextInput label="Görevi"
                                       labelClassName="application-form__input-label"
                                       value={referenceJobTitle}
                                       onChange={this.referenceJobTitleHandler}
                                       containerClassName="application-form__input"
                                       error={this.validator.message('Görevi', referenceJobTitle, 'required')}
                            />
                            <LcPhoneInput label="Telefon"
                                          labelClassName="application-form__input-label"
                                          value={referencePhone}
                                          onChange={this.referencePhoneHandler}
                                          className="application-form__input"
                                          validator={this.validator}
                            />
                            <Checkbox className="application-form__checkbox"
                                      value={agreeWithJobLaws}
                                      label={
                                          <label className="lc-checkbox__label application-form__terms-checkbox-label">
                                              <Link to="/musteri-hizmetleri/kisisel-verilerin-korunmasi">
                                                  Kişisel Verilerin Korunması Kanunu
                                              </Link>
                                              'na ait sözleşmeyi okudum ve onaylıyorum.
                                          </label>
                                      }
                                      labelClassName="application-form__checkbox-label"
                                      onChange={this.toggleAgreeWithJobLaws}
                                      error={this.validator.message('Kişisel Verilerin Korunması Kanununa', agreeWithJobLaws ? true : '', 'required')}
                            />
                        </div>

                        <div className="application-form__submit-button-container">
                            <Button className="application-form__submit-button" color="green" onClick={this.submit}>
                                Gönder
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    boundGetCities: bindActionCreators(getCities, dispatch),
    boundGetAllCities: bindActionCreators(getAllCities, dispatch),
    boundSendJobApplication: bindActionCreators(sendJobApplication, dispatch),
    boundGetAllTownsOfCity: bindActionCreators(getAllTownsOfCity, dispatch),
    boundGetRestaurants: bindActionCreators(getRestaurants, dispatch)
});

ApplicationForm.propTypes = {
    boundGetCities: PropTypes.func.isRequired,
    boundGetAllCities: PropTypes.func.isRequired,
    boundSendJobApplication: PropTypes.func.isRequired,
    boundGetAllTownsOfCity: PropTypes.func.isRequired,
    addressData: PropTypes.object.isRequired,
    boundGetRestaurants: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
