import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextInput from '../../elements/TextInput';
import LcPhoneInput from '../../elements/LcPhoneInput';
import Button from '../../elements/Button';
import getValidator from '../../../getValidator';
import { getUser, updateUser, setIsUpdateUsersData } from '../../../store/actions/authActions';
import DropdownList from '../../elements/DropdownList';
import { formatDate } from '../../../utils';
import { setModal } from '../../../store/actions/modalActions';
import { saveCustomerPhoneId } from '../../../api';

const genderTypeOptions = [
    { value: '0', label: 'Erkek' },
    { value: '1', label: 'Kadın' }
];

class AccountSettingsForm extends Component {
    validator = getValidator(this);

    state = {
        name: '',
        email: '',
        phone: '',
        birthDate: '',
        genderType: ''
    };

    componentDidMount() {
        this.setInitialData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.id !== this.props.user.id) {
            this.setInitialData();
        }
    }

    setInitialData = () => {
        const { user } = this.props;

        if (user.id) {
            this.setState({
                name: user.name,
                email: user.email,
                phone: user.personPhones[0] ? (user.personPhones[0].number.substring(0, 2) !== '90' ? `90${user.personPhones[0].number}` : user.personPhones[0].number) : '',
                birthDate: this.getUserBirthDate(),
                genderType: this.getGenderByValue(user.genderTypeID).value
            });
        }
    };

    getUserBirthDate = () => {
        const { user } = this.props;
        if (user.personSpecialDates) {
            const birthDateData = user.personSpecialDates.find(specialDate => specialDate.specialDateType === 'BirthDay');
            if (birthDateData && birthDateData.specialDate) {
                return formatDate(birthDateData.specialDate);
            }
        }
        return '';
    };

    getGenderByValue = value => genderTypeOptions.find(genderType => +genderType.value === +value);

    nameInputHandler = event => this.setState({ name: event.target.value });

    emailInputHandler = event => this.setState({ email: event.target.value });

    phoneInputHandler = phone => this.setState({ phone: phone });

    birthDateInputHandler = event => this.setState({ birthDate: event.target.value });

    genderTypeInputHandler = genderType => this.setState({ genderType });

    handleSubmitMemberInfo = () => {
        const { boundUpdateUser, boundGetUser, boundSetIsUpdateUsersData, boundSetModal } = this.props;
        const { name, email, phone, approveMailInform, approveSmsInform, birthDate, genderType } = this.state;
        this.validator.showMessages();
        if (this.validator.allValid()) {
            boundUpdateUser(
                name, email, phone.substring(2), birthDate, genderType, this.getGenderByValue(genderType).label,
                approveMailInform, approveSmsInform
            )
            .then(response => {
                    if (response.data.resultCode === '1010') {
                        saveCustomerPhoneId(response.data.result);
                        boundSetIsUpdateUsersData({
                            isUpdateUsersData: true
                        });
                        boundSetModal('phone-confirmation');
                    } else {
                        boundGetUser();
                    }
                }
            )
        }
    };

    render() {
        const { name, email, phone, birthDate, genderType } = this.state;

        return <div className="profile-account__block profile-account__block--inputs">
            <div className="profile__block-header">Bilgilerimi Güncelle</div>
            <div className="profile-account__input-block">
                <TextInput label="Ad Soyad"
                           labelClassName="profile-account__input-label"
                           value={name}
                           onChange={this.nameInputHandler}
                           className="profile-account__input"
                           error={this.validator.message('isim', name, 'required')}
                />
                <TextInput label="E-Posta"
                           labelClassName="profile-account__input-label"
                           value={email}
                           onChange={this.emailInputHandler}
                           className="profile-account__input"
                           error={this.validator.message('e-posta', email, 'required|email')}
                           disabled
                />
                <LcPhoneInput label="Cep Numarası"
                              labelClassName="profile-account__input-label"
                              value={phone}
                              onChange={this.phoneInputHandler}
                              className="profile-account__input profile-account__input--phone"
                              validator={this.validator}
                />
                {/*<TextInput label="Doğum Tarihi"*/}
                {/*           labelClassName="profile-account__input-label"*/}
                {/*           value={birthDate}*/}
                {/*           onChange={this.birthDateInputHandler}*/}
                {/*           className="profile-account__input"*/}
                {/*           error={this.validator.message('birthDate', birthDate, 'required')}*/}
                {/*/>*/}
                <DropdownList label="Cinsiyet"
                              labelClassName="profile-account__input-label"
                              className="profile-account__dropdown"
                              value={genderType}
                              placeholder="cinsiyet seçiniz"
                              options={genderTypeOptions}
                              onChange={this.genderTypeInputHandler}
                />
            </div>
            <Button className="profile-account__submit-button" onClick={this.handleSubmitMemberInfo}>
                Kaydet
            </Button>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user
});

const mapDispatchToProps = dispatch => ({
    boundUpdateUser: bindActionCreators(updateUser, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundSetIsUpdateUsersData: bindActionCreators(setIsUpdateUsersData, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

AccountSettingsForm.propTypes = {
    boundUpdateUser: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundSetIsUpdateUsersData: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    boundSetModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsForm);
