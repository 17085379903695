import React from 'react';

const Orders = props => 
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect width="20" height="20" fill="#383838"/>
<rect x="-108" y="-358" width="1366" height="1341" fill="#F8F8F8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18.8535 16.3015C19.1515 17.0385 18.9885 17.8485 18.4285 18.4135L18.4195 18.4225C17.8605 18.9805 17.0555 19.1495 16.3165 18.8625L11.8595 17.1365C11.8585 17.1365 11.8575 17.1355 11.8565 17.1355L8.3115 15.7625C8.3075 15.7615 8.3035 15.7595 8.2995 15.7585L2.4225 13.4835C1.3635 13.0725 0.7845 11.9375 1.0745 10.8425C1.6755 8.57649 2.8525 6.48349 4.4785 4.78649C6.1425 3.05249 8.2415 1.77549 10.5485 1.09349C11.6585 0.765488 12.8205 1.32449 13.2505 2.39549L18.8535 16.3015Z" stroke="black" strokeWidth="1.5"/>
<path d="M11 7C9.8976 7 9 7.8968 9 9C9 10.1032 9.8976 11 11 11C12.1032 11 13 10.1032 13 9C13 7.8968 12.1032 7 11 7" stroke="black" strokeWidth="1.5"/>
<path d="M10.28 16.53C10.72 16.17 11 15.61 11 15C11 13.9 10.1 13 9 13C7.9 13 7 13.9 7 15C7 15.09 7.01 15.179 7.02 15.259" stroke="black" strokeWidth="1.5"/>
<path d="M16.7195 11.0195C15.7495 11.1605 14.9995 11.9995 14.9995 12.9995C14.9995 14.0995 15.9005 14.9995 16.9995 14.9995C17.4405 14.9995 17.8395 14.8605 18.1695 14.6205" stroke="black" strokeWidth="1.5"/>
<path d="M13.5 3.5C13.5 3.5 5.987 3.701 3.5 13.5" stroke="black" strokeWidth="1.5"/>
</svg>


export default Orders;