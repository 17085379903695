import React from 'react';
import PropTypes from "prop-types";

const Popup = props => {
    const { className } = props;

    const getClassName = () => {
        let resultClassName = 'popup';
        if (className) {
            resultClassName += ` ${className}`;
        }
        return resultClassName;
    };

    return <div className={getClassName()}>
        <div className="popup__body">
            {props.children}
        </div>
    </div>;
};

Popup.propTypes = {
    className: PropTypes.string
};

export default Popup;
