import React, { Component } from 'react';
import Icon from './Icon';

class Alert extends Component {
    render() {
        if (!this.props.message) {
            return null;
        }
        return <div className="alert alert--error" role="alert">
            <div className="alert__info-icon">
                <Icon name="info" className="icon--white"/>
            </div>
            <div className="alert__message">{this.props.message}</div>
        </div>
    }
}

export default Alert;