import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const Franchising = () => {
    sendPageView('/franchise/franchising', 'Franchising - Little Caesars');
    return (

<section class="institutional-structures">
    <div class="container">
        <BreadCrumbs />

        <div class="institutional-structures-detail">
            <div class="institutional-structures-detail-content clearfix">
                 <FranchisingMenu />
                <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>Franchising</h1>
                        <img class="img-responsive" src="/images/corporate/franchising/franchising.jpg" alt="franchising.jpg" />
                        <h2>DEĞERLİ FRANCHISEE ADAYIMIZ;</h2>
                        <p>
                            Öncelikle, dünyanın ve Türkiye’nin en büyük pizza markalarından biri olan Little Caesars’a göstermiş olduğunuz ilgiye teşekkür ederiz. Farkını hizmet ve ürün kalitesiyle ön plana çıkaran markaların gittikçe çoğaldığı günümüz hızlı/paket servis zincir restoran sektöründe, değerli adaylarımızın mevcut rekabet ortamında daha güçlü olabilmeleri için, Little Caesars'ın “birlikten kuvvet doğar” inancından hareketle vereceği gerek pazarlama gerekse operasyonel desteklerle, yapılacak işbirliği ve bütünleşik çalışmalar yardımıyla yüksek gelir ve fayda sağlamak adına tercih edebileceği en kazançlı alternatif, Little Caesars Franchise sistemimizdir.
                        </p>
                        <p>İlk restoranını, 1959 yılında kurucuları Mike ve Marian ILITCH tarafından Amerika’da açan Little Caesars, geçen 50 yıldan fazla süre zarfında edindiği tüm tecrübe ve birikimi siz değerli franchise adaylarına aktarmayı görev edinmiştir. Ülkemizdeki ilk restoranını Çelebi Holding güvencesi ile 1997 yılında açan Little Caesars, 2007 yılından itibaren franchise vermeye başlamış ve özellikle son zamanlarda franchise ailesini genişletmek hedefleriyle önemli yatırımlara imza atmıştır. Amacımız öncelikle , restoranlarımızda üretilen ve kesinlikle dondurulmadan imal edilen günlük hamurlarımızla , sağlıklı ve sezonunda özenle seçilen domateslerden yapılan özel sosu ve dünyaca meşhur hamur mix karışımımızla , öncelikle pizza müşterimiz olmanızı sağlamaktır. Sağlıklı ve gerçek pizzayı tadacaksınız. Bu lezzetten vazgeçemediğinizde de dünya üzerinde 6000’den fazla yatırımcı gibi siz de bu lezzeti satmak ve sevdiğiniz işten para kazanmaya başlayacaksınız. Bugün siz değerli adaylarımız, Little Caesars ailesine katıldığınızda dünya üzerinde gün geçtikçe artan restoranlarımızda edindiğimiz tecrübe ortak olacaksınız.
                        </p>
                        <p>Günümüz ticari koşullarında "tecrübeli" ve “bilgili” olmak sizi her zaman bir adım öne çıkaracaktır. Franchise başvurusu yaptığınız andan itibaren doğru lokasyon seçimi, gün geçtikçe artacak eğitim, kiralama, inşaat (dekorasyon), lojistik, tedarik, insan kaynakları, bilgi-işlem ve diğer operasyonel konularda “tecrübeli” ve “bilgili” profesyonel bir kadronun tam desteği ile faaliyete geçmeden önce tüm süreçler hakkında donanımlı hale gelerek yeni işinize siz de tecrübe ve bilgi sahibi olarak başlayacaksınız. Üstelik Little Caesars; pazarlama ve operasyon ekipleri; desteklerini iş ortaklığı süresince de devam ettirerek kazancınızı daha artırmak için emek verecek.
                        </p>
                        <p>Şu anda Little Caesars franchise sistemi içinde bulunan yatırımcılarımız arasında, kendi restoranlarımızda yetişmiş ve daha sonra Little Caesars sistemine franchise olarak devam eden arkadaşlarımız olduğu gibi, yıllarca farklı sektörler ve firmalarda profesyonel olarak çalışıp kendi işlerine başlamak için ilk olarak Little Caesars fırsatlarını değerlendiren iş ortaklarımız da mevcuttur. Bu franchiselerimizin ortak özellikleri ise işlerinin yakın takipçisi olmaları, hedef odaklı çalışmaları, iyi birer ekip lideri olmaları, bölgelerini iyi tanımaları ve talep analizini doğru yaparak güçlü bir pazarlama stratejisine sahip olmaları, ayrıca rekabet odaklı hareket edebilmeleridir.</p>
                        <p>Kendi kendinizin patronu olmak, aileniz ve geleceğiniz adına karlı bir yatırıma imza atmak, başarmak ve herşeyden önemlisi kazanmak için Little Caesars’ta yeriniz hazır.</p>
                        <p>Şimdi, 2020 yılı için özel olarak ve ülkemiz gerçeklerine göre hazırladığımız Türk Lirası bazlı dükkan maliyetlerini öğrenmek için hemen karar vermelisiniz.</p>
                        <p>HAYALLERİNİZİ ERTELEMEYİN,<br />ARTIK KENDİ İŞİNİZİN PATRONU OLMA VAKTİNİZ GELDİ..<br />Başvuru formumuzu hemen doldurun, hemen irtibata geçelim.<br /><b>Banu ARIDURU</b><br />Genel Müdür</p>
                  </div>
                </div>
            </div>
        </div>
    </div>

</section>

    );
};

export default Franchising;