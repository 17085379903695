import React from 'react';

const Delete = props =>
    <svg width="12" height="12" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <line x1="1" y1="23" 
          x2="23" y2="1" 
          stroke="black" 
          strokeWidth="1"/>
    <line x1="1" y1="1" 
          x2="23" y2="23" 
          stroke="black" 
          strokeWidth="1"/>
        {/* <path fillRule="evenodd" clipRule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM14.0205 8.76755C14.3552 8.43282 14.8979 8.43282 15.2327 8.76755C15.5674 9.10228 15.5674 9.645 15.2327 9.97973L13.2122 12.0002L15.2323 14.0203C15.5671 14.3551 15.5671 14.8978 15.2323 15.2325C14.8976 15.5673 14.3549 15.5673 14.0201 15.2325L12 13.2124L9.97986 15.2325C9.64513 15.5673 9.10241 15.5673 8.76768 15.2325C8.43294 14.8978 8.43294 14.3551 8.76768 14.0203L10.7878 12.0002L8.76735 9.97973C8.43261 9.645 8.43261 9.10228 8.76735 8.76755C9.10208 8.43281 9.64479 8.43282 9.97953 8.76755L12 10.788L14.0205 8.76755Z" /> */}
    </svg>;

export default Delete;
