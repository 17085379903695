import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { addActionEvent } from '../../../googleAnalyticsHelper';

const FranchisingMenu = props => {
    const mobileDropdownMenuHandler = event => props.history.push(event.target.value);

    return (
        <div className="col-md-3 mobil-menu-corporate">
        <div className="row">
            <div className="corporate-menu hidden-xs hidden-sm">
                <ol className="faq">
                    <li className=""><NavLink activeClassName="active" to="/franchise/franchise-basvuru-formu" onClick={addEvent}>Franchising Başvuru Formu</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/franchising">Franchising</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/tarihce">Tarihçe</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/franchisee-olma-sureci">Franchise Olma Süreci</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/franchise-firsat-bolgeleri">Franchise Fırsat Bölgeleri</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/is-gelistirme-ekibi">Franchise İş Geliştirme Ekibi</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/franchiseelerimizden-dinleyin">Franchiseelerimizden Dinleyin</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/destekler">Destekler</NavLink></li>
                    <li className=""><NavLink activeClassName="active" to="/franchise/sss">SSS</NavLink></li>
                </ol>
            </div>
            <div className="corporate-menu-mobil hidden-lg hidden-md">
            <select onChange={mobileDropdownMenuHandler}
                    className="js-states form-control"
                    value={props.location.pathname}
            >
                <option value="/franchise/franchise-basvuru-formu">Franchising Başvuru Formu</option>
                <option value="/franchise/franchising">Franchising</option>
                <option value="/franchise/tarihce">Tarihçe</option>
                <option value="/franchise/franchisee-olma-sureci">Franchise Olma Süreci</option>
                <option value="/franchise/franchise-firsat-bolgeleri">Franchise Fırsat Bölgeleri</option>
                <option value="/franchise/is-gelistirme-ekibi">Franchise İş Geliştirme Ekibi</option>
                <option value="/franchise/franchiseelerimizden-dinleyin">Franchiseelerimizden Dinleyin</option>
                <option value="/franchise/destekler">Destekler</option>
                <option value="/franchise/sss">SSS</option>
            </select>
            </div>
        </div>
    </div>
    );
};

const addEvent = () => {
 addActionEvent('Basvur', 'Franchise Basvuru Formu');
};

export default withRouter(FranchisingMenu);