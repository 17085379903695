import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Icon from '../elements/Icon';
import { logOut } from '../../utils';

const ProfileDesktopSidebar = props => {
    const exit = () => {
        props.setAddressSelection({});
        logOut();
    };

    const getLinkClassName = url => props.location.pathname === url
        ? 'icon-with-label link profile-sidebar-link link--active'
        : 'icon-with-label link profile-sidebar-link';

    return <div className="profile-desktop-sidebar">
        <div className="user-preview">
            {/*<img src="/images/placeholder-image.png" alt="" className="user-preview__image" />*/}
            <div className="user-preview__right">
                <div className="user-preview__name">{props.user.name}</div>
            </div>
        </div>
        <Link to="/sezarla-kazan" className={getLinkClassName('/sezarla-kazan')}>
            <div className="icon-background">
                <Icon name="loyalty-sidebar-icon"/>
            </div>
            <span>Sezarla Kazan</span>
        </Link>
        <Link to="/profil/hesap" className={getLinkClassName('/profil/hesap')}>
            <div className="icon-background">
                <Icon name="user"/>
            </div>
            <span>Profilim</span>
        </Link>
        <Link to="/profil/favoriler" className={getLinkClassName('/profil/favoriler')}>
            <div className="icon-background">
                <Icon name="favorites"/>
            </div>
            <span>Favorilerim</span>
        </Link>
        <Link to="/profil/siparisler" className={getLinkClassName('/profil/siparisler')}>
            <div className="icon-background">
                <Icon name="orders"/>
            </div>
            <span>Siparişlerim</span>
        </Link>
        <Link to="/profil/siparis-takip" className={getLinkClassName('/profil/siparis-takip')}>
            <div className="icon-background">
                <Icon name="order-tracking"/>
            </div>
            <span>Sipariş Takip</span>
        </Link>
        <Link to="/profil/adresler" className={getLinkClassName('/profil/adresler')}>
            <div className="icon-background">
                <Icon name="pin"/>
            </div>
            <span>Adreslerim</span>
        </Link>
        <Link to="/profil/kayıtlı-kartlarım" className={getLinkClassName('/profil/kayıtlı-kartlarım')}>
            <div className="icon-background">
                <Icon name="credit-card"/>
            </div>
            <span>Kayıtlı Kartlarım</span>
        </Link>
        <div onClick={exit} className={getLinkClassName()}>
            <div className="icon-background">
                <Icon name="exit"/>
            </div>
            <span>Çıkış</span>
        </div>
    </div>;
};

ProfileDesktopSidebar.propTypes = {
    user: PropTypes.object.isRequired,
    setAddressSelection: PropTypes.func.isRequired
};

export default withRouter(ProfileDesktopSidebar);
