import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../elements/Icon';

const RemoveItemsFromBasketCheckoutPopup = props => {
    const { text, submit } = props;

    return <div className="remove-items-popup">
        <Icon name="info" className="remove-items-popup__icon"/>
        <div className="remove-items-popup__header">Ürün Silinecek</div>
        <div className="remove-items-popup__detail">
            {text + ' Sepetinizdeki ürünler silinecektir. Emin misiniz?'}
        </div>
        <span className="big-button icon-with-label remove-items-popup__submit-button">
            İptal
        </span>
        <div>
            <span className="link cancel-button" onClick={submit}>
                Evet
            </span>
        </div>
    </div>;
};

RemoveItemsFromBasketCheckoutPopup.propTypes = {
    submit: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export default RemoveItemsFromBasketCheckoutPopup;
