import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    updateUserDeliveryAddress, getCities, getTowns, getDistricts, getStreets, getUserDeliveryAddresses,
    deleteUserDeliveryAddress, setAddressSelection, getAddressTypes, setChangedAddress
} from '../../../store/actions/addressActions';
import AddressForm from './AddressForm';
import { saveCustomerPhoneId } from '../../../api';

class EditAddressModal extends Component {
    state = {
        initialData: null
    };

    componentDidMount() {
        const {
            addressData, addressId, boundGetCities, boundGetTowns, boundGetDistricts, boundGetAddressTypes,
            boundGetStreets
        } = this.props;

        const address = addressData.userDeliveryAddresses.find(address => address.addressId === addressId);
        const initialData = {
            streetId: address.address.streetAddressId,
            name: address.address.name,
            addressTypeList: [],
            addressTypeId: address.address.addressTypeId,
            phone: address.extensionNumber && address.extensionNumber.substring(0, 2) !== '90'
                ? `90${address.extensionNumber}`
                : '',
            directions: address.address.landmark,
            apartmentName: address.address.appartmentName,
            floorNo: address.address.floor,
            apartmentNo: address.address.appartmentNo,
            entrance: address.address.entrance,
            addressNo: address.address.addressNo
        };

        boundGetAddressTypes()
            .then(response => response.data.result.forEach(addressType => {
                initialData.addressTypeList.push({
                    label: addressType.Name,
                    value: addressType.Value
                });
            }));

        if (address.address.streetAddress) {
            let addressString = address.address.streetAddress.split('/');
            addressString.reverse();
            const [cityName, townName, districtName, streetName] = addressString;
            initialData.selectedStreet = streetName;
            initialData.selectedDistrict = districtName;
            initialData.selectedTown = townName;
            initialData.selectedCity = cityName;
            initialData.selectedAddress = address.address.streetAddress;
            boundGetCities()
                .then(response => {
                    initialData.cityList = response.data.result.flat();
                    initialData.cityId = initialData.cityList.find(city => city.name === cityName).id;
                    return initialData.cityList.find(city => city.name === cityName);
                })
                .then(city => {
                    boundGetTowns(city.id)
                        .then(response => {
                            initialData.townList = response.data.result.flat();
                            initialData.townId = initialData.townList.find(town => town.name === townName).id;
                            return initialData.townList.find(town => town.name === townName);
                        })
                        .then(town => {
                            boundGetDistricts(town.id)
                                .then(response => {
                                    initialData.districtList = response.data.result.flat();
                                    initialData.districtId = initialData.districtList.find(district => district.name === districtName).id;
                                    return initialData.districtList.find(district => district.name === districtName);
                                })
                                .then(district => {
                                    boundGetStreets(district.id)
                                        .then(response => {
                                            initialData.streetList = response.data.result.flat();
                                            this.setState({ initialData });
                                        });
                                });
                        });
                });
        }
    }

    editAddress = ({ name, apartmentNo, addressNo, apartmentName, phone, entrance, floorNo, directions, streetId, addressTypeId }) => {
        const {
            boundUpdateUserDeliveryAddress, setModal, boundGetUserDeliveryAddresses, addressId, id, addressData,
            boundSetAddressSelection, boundSetChangedAddress
        } = this.props;

        boundUpdateUserDeliveryAddress({
            id: id,
            extensionNumber: phone ? phone.substring(2) : '',
            address: {
                id: addressId,
                name: name,
                addressTypeId,
                streetAddressId: streetId,
                landmark: directions,
                addressNo: addressNo,
                entrance: entrance,
                floor: floorNo,
                appartmentNo: apartmentNo,
                appartmentName: apartmentName
            }
        })
            .then(response => {
                    if (response.data.resultCode === '1010') {
                        saveCustomerPhoneId(response.data.result);
                        boundSetChangedAddress({
                            changedAddress: {
                                isChangedAddress: true,
                                changedAddressId: addressId,
                                changedAddressName: name
                            }
                        });
                        setModal('phone-confirmation');
                    } else {
                        if (addressData.addressSelection.addressId === addressId) {
                            boundSetAddressSelection({
                                type: 'Adrese Teslim',
                                address: name,
                                addressId: addressId
                            });
                        }
                        boundGetUserDeliveryAddresses().finally(() => setModal(''));
                    }
                }
            );
    };

    deleteAddress = () => {
        const {
            boundDeleteUserDeliveryAddress, addressId, setModal, boundGetUserDeliveryAddresses, addressData,
            boundSetAddressSelection
        } = this.props;
        boundDeleteUserDeliveryAddress(addressId)
            .then(() => {
                if (addressData.addressSelection.addressId === addressId) {
                    boundSetAddressSelection({});
                }
                boundGetUserDeliveryAddresses().finally(() => setModal(''));
            });
    };

    render() {
        const { initialData } = this.state;

        return <div className="modal-content address-modal">
            <div className="modal-content__header">
                Adresini Düzenle
            </div>
            <div>
                <AddressForm initialData={initialData} submit={this.editAddress} deleteAddress={this.deleteAddress}/>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    addressData: store.addressData,
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({
    boundGetAddressTypes: bindActionCreators(getAddressTypes, dispatch),
    boundUpdateUserDeliveryAddress: bindActionCreators(updateUserDeliveryAddress, dispatch),
    boundDeleteUserDeliveryAddress: bindActionCreators(deleteUserDeliveryAddress, dispatch),
    boundGetUserDeliveryAddresses: bindActionCreators(getUserDeliveryAddresses, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundGetCities: bindActionCreators(getCities, dispatch),
    boundGetTowns: bindActionCreators(getTowns, dispatch),
    boundGetDistricts: bindActionCreators(getDistricts, dispatch),
    boundGetStreets: bindActionCreators(getStreets, dispatch),
    boundSetChangedAddress: bindActionCreators(setChangedAddress, dispatch)
});

EditAddressModal.propTypes = {
    addressData: PropTypes.object.isRequired,
    addressId: PropTypes.string.isRequired,
    setModal: PropTypes.func.isRequired,
    boundGetAddressTypes: PropTypes.func.isRequired,
    boundUpdateUserDeliveryAddress: PropTypes.func.isRequired,
    boundDeleteUserDeliveryAddress: PropTypes.func.isRequired,
    boundGetUserDeliveryAddresses: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundGetCities: PropTypes.func.isRequired,
    boundGetTowns: PropTypes.func.isRequired,
    boundGetDistricts: PropTypes.func.isRequired,
    boundGetStreets: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    boundSetChangedAddress: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressModal);
