import React from 'react';
import CustomerServiceMenu from '../customer-service-menu/CustomerServiceMenu';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';
import { sendPageView } from '../../../googleAnalyticsHelper';

const UserAgreement = () => {
    sendPageView('/musteri-hizmetleri/uyelik-sozlesmesi', 'Üyelik Sözleşmesi - Little Caesars'); //for ga

    return  <section class="institutional-structures">
    <div class="container">
        <BreadCrumbs />
        <div class="institutional-structures-detail">
            <div class="institutional-structures-detail-content clearfix">
               <CustomerServiceMenu />
                <div class="col-md-12">
                    <div class="corpareta-detail">
                        <h1>LİTTLE CAESARS WEB SİTESİ ÜYELİK SÖZLEŞMESİ</h1>
                        <h2>1. Taraflar</h2>
                        <p><b>a)</b> “www.littlecaesars.com.tr” internet sitesinin faaliyetlerini yürüten, merkezi “15 Temmuz  Mahallesi, Bahar Caddesi, Polat İş Merkezi, No:6 A. Blok, Kat:1 34212 Bağcılar-Güneşli/İstanbul” adresinde bulunan Çelebi Hizmet Gıda İşletmeleri Turizm Sanayi ve Ticaret Anonim Şirketi (“Little Caesars”) </p>
                        <p><b>b)</b> “www.littlecaesars.com.tr” internet sitesine (“İnternet Sitesi”) üye olan internet kullanıcısı ("Üye")
                            Bundan sonra Little Caesars ve Üye tek başına “Taraf”, birlikte “Taraflar” olarak anılacaktır.</p>
                        <h2> 2. Sözleşme’nin Konusu</h2>
                        <p>İşbu Sözleşme’nin konusu, Üye’nin İnternet Sitesi’nden yararlanma, kullanma ve satış şartlarının belirlenmesidir.</p>
                        <h2>3. Tarafların Hak ve Yükümlülükleri</h2>
                        <p> 3.1. Üye, 18 yaşından büyük olduğunu beyan etmektedir. İşbu Sözleşme tamamen Üye’nin beyanlarına güvenerek akdedilmiş olduğundan Üye’nin 18 yaşından küçük olduğunun anlaşılması halinde sözleşme bildirime gerek olmaksızın derhal fesih edilir ve üyelik iptal edilir. Little Caesars, 18 yaşından büyük olduğunu beyan ederek sözleşme akdetmiş olan kişinin uğrayacağı zarlarladan sorumlu değildir.</p>
                        <p>3.2. Üye, İnternet Sitesi’ne üye olurken verdiği kişisel ve diğer sair bilgilerin kanunlar önünde doğru olduğunu, Little Caesars’ın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları aynen ve derhal tazmin edeceğini kabul, beyan ve taahhüt eder. </p>
                        <p>3.3. Üye’nin Little Caesars İnternet Sitesi’nden aldığı şifreyi kullanma hakkı münhasıran Üye’ye aittir. Üye bu şifreyi herhangi bir üçüncü şahıs ile paylaşmamalıdır ve güvenli şekilde saklamakla sorumludur. Şifrenin Üye’nin kusuruyla kaybından ya da üçüncü kişiler tarafından kullanılmasından kaynaklanan zararlara ilişkin tüm hukuki ve cezai sorumluluk Üye’ye aittir. Üye, bu sebeple Little Caesars’tan herhangi bir talepte bulunamaz ve Little Caesars’un bu gibi durumlara ilişkin olarak herhangi bir sorumluluğu ve/veya Üye’nin zararlarını tazmin etme yükümlülüğü bulunmamaktadır. </p>
                        <p>3.4. Üye, İnternet Sitesi’ni kullanırken tüm yasal mevzuat hükümlerine ve işbu Sözleşme şartlarına uymayı ve söz konusu hükümleri ihlal etmemeyi kabul ve taahhüt eder. Aksi durumda, oluşacak tüm hukuki ve cezai yaptırımlardan tamamen ve münhasıran Üye sorumlu olacaktır. </p>
                        <p>3.5. Üye, İnternet Sitesi’nde başkalarının hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus, vb.) ve işlemlerde bulunamaz. </p>
                        <p>3.6. Üye, İnternet Sitesi’ni hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, başkalarının fikri ve telif haklarına tecavüz edecek şekilde ve yasalara aykırı herhangi bir amaç için kullanamaz. </p>
                        <p>3.7. İnternet sitesinde Üye’ler tarafından beyan edilen, yazılan fikir ve düşünceler ve kullanılan ifadeler münhasıran Üye’lerin kendi kişisel fikir ve yorumlarıdır ve bu fikir ve yorumların sonuçlarından yalnızca Üye sorumludur. Bu görüş ve düşüncelerin Little Caesars’la hiçbir ilgisi ve bağlantısı bulunmamaktadır. Little Caesars'un, Üye’nin beyan edeceği fikir ve görüşler nedeniyle üçüncü kişi veya kurumların uğrayabileceği zararlardan veya üçüncü kişi veya kurumların beyan edeceği fikir ve görüşler nedeniyle Üye’nin uğrayabileceği zararlardan dolayı herhangi bir sorumluluğu bulunmamaktadır. </p>
                        <p>3.8. Little Caesars, kusurlu olduğu durumlar hariç olmak üzere, Üye verilerinin yetkisiz kişilerce ele geçirilmesinden ve Üye yazılım ve verilerine gelebilecek zararlardan dolayı sorumlu tutulmayacaktır. Üye, İnternet Sitesi’nin kullanılmasından dolayı uğrayabileceği herhangi bir zarar yüzünden Little Caesars’dan tazminat talep etmemeyi peşinen kabul etmiştir. </p>
                        <p>3.9. Üye, başka internet kullanıcılarının bilgilerine ve yazılımlarına izinsiz olarak ulaşmamayı veya bunları kullanmamayı kabul etmiştir. Aksi halde, bu nedenle doğacak hukuki ve cezai sorumluluk tamamen Üye’ye aittir. </p>
                        <p> 3.10. İşbu üyelik sözleşmesi içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, Little Caesars’ı bu ihlallerin hukuki ve cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın adli yargı mercilerine intikali halinde, Little Caesars’ın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır. </p>
                        <p>3.11. İnternet Sitesi yazılım ve tasarımı Little Caesars’ın mülkiyetinde olup, bunlara ilişkin telif hakkı ve/veya diğer fikri mülkiyet hakları ilgili kanunlarca korunmakta olup, bunlar üye tarafından izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez. Bu web sitesinde adı geçen başkaca şirketler ve ürünler sahiplerinin ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında korunmaktadır. </p>
                        <p> 3.12. Little Caesars tarafından İnternet Sitesi’nin iyileştirilmesi, geliştirilmesine yönelik olarak ve/veya yasal mevzuat çerçevesinde siteye erişmek için kullanılan internet servis sağlayıcısının adı ve Internet Protokol (IP) adresi, siteye erişilen tarih ve saat, sitede bulunulan sırada erişilen sayfalar ve siteye doğrudan bağlanılmasını sağlayan web sitesinin internet adresi gibi birtakım bilgiler toplanabilir. </p>
                        <p>3.13. Little Caesars, Üye’lerin kişisel bilgilerini, her bir Üye’nin önceden onayını almak kaydıyla, (i) kullanıcılarına/müşterilerini daha iyi hizmet sunmak amacıyla, (ii) hizmetlerini ve ürünlerini iyileştirmek amacıyla, (iii) İnternet Sitesi’nin kullanımını kolaylaştırmak amacıyla ve (iv) hizmetlerini ve İnternet Sitesi’nin kullanımını kullanıcılarının ilgi alanlarına ve tercihlerine yönelik olarak değiştirmek amacıyla yapılacak çalışmalarda kullanabilir. Little Caesars, Üye’nin İnternet Sitesi üzerinde yaptığı hareketlerin kaydını bulundurma hakkını saklı tutar. </p>
                        <p> 3.14. Little Caesars üyenin kişisel bilgilerini yasal bir zorunluluk olarak istendiğinde veya (a) yasal gereklere uygun hareket etmek veya Little Caesars’a tebliğ edilen yasal işlemlere uymak; (b) Little Caesars ve İnternet Sitesi ailesinin haklarını ve mülkiyetini korumak ve savunmak için gerekli olduğuna iyi niyetle kanaat getirdiği hallerde açıklayabilir. </p>
                        <p> 3.15. Little Caesars, İnternet Sitesi’nin içeriğini, tasarımını ve yazılımını dilediği zaman değiştirme, Üye’lere sağlanan herhangi bir hizmeti değiştirme, durdurma ya da sonlandırma hakkını haizdir. Herhangi bir şekilde, yapılacak söz konusu değişikliklerin Üye’nin aleyhine bir düzenleme getirdiği durumlarda, söz konusu değişiklik ile ilgili olarak Üye’nin E-mail Adresi’ne gönderilen bir e-mail ile bilgilendirme yapılır. </p>
                        <p> 3.16. Little Caesars, üyelik sözleşmesinin koşullarını hiçbir şekil ve surette ön ihbara ve/veya ihtara gerek kalmaksızın her zaman değiştirebilir, güncelleyebilir veya iptal edebilir. Değiştirilen, güncellenen ya da yürürlükten kaldırılan her hüküm, yayın tarihinde tüm üyeler bakımından hüküm ifade edecektir. </p>
                        <p> 3.17. Little Caesars İnternet Sitesi’ne üye olurken “Little Caesars kampanyalarından nasıl haberdar olmak istersiniz” sorusuna ‘E-posta, SMS veya Hepsi’ seçeneklerini seçmiş olan Üye’ler söz konusu üyelik formunda vermiş oldukları bilgilerin, aksi belirtilmedikçe Little Caesars, bağlı kuruluşları ve işbirliği içinde olduğu kurumlar tarafından, her türlü pazarlama faaliyetleri, kampanyalar, reklamlar, lansmanlar, anketler, indirimler, promosyonlar, özel fırsatlar, tanıtım, açılış, davet ve etkinlik hatırlatmaları ile iletişim çalışmaları uygulamalarında kullanılmasına, saklanmasına ve paylaşılmasına muvafakat ederler. </p>
                        <h2> 4. Gizlilik</h2>
                        <p>İşbu Sözleşme’nin yürürlükte olduğu süre boyunca ve Sözleşme’nin sona ermesinden sonra Taraflar yazılı, sözlü veya sair surette kendilerine iletilen her türlü bilgi ve belgeleri, Üye’lerin kişisel bilgilerini (“Gizli Bilgiler”), karşı tarafın yazılı onayı olmadan üçüncü kişilere açıklayamaz, başka kişi, kurum ve kuruluşların yararına kullanamaz ve kullandıramaz. Taraflar, yukarıda sayılan Gizli Bilgiler’in gizliliğini sağlamak, bu hususta her önlemi almak, gizlilik esaslarına uygun hareket etmek, bu bilgilerin yetkisiz kişilerce kullanımını önlemek ve her türlü suiistimalden korumak için her türlü önlemi almakla yükümlüdür.</p>
                        <h2>5. Sözleşmenin Feshi</h2>
                        <p>İşbu Sözleşme üyenin üyeliğini iptal etmesi veya Little Caesars tarafından üyeliğinin iptal edilmesine kadar yürürlükte kalacaktır. Little Caesars üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal etmesi durumunda üyenin üyeliğini iptal ederek işbu Sözleşmeyi tek taraflı olarak feshedebilecektir. Üye ise dilediği zaman <a href="mailto:info@littlecaesars.com.tr">info@littlecaesars.com.tr</a> adresine yazılı olarak bildirmek kaydıyla işbu Sözleşme’yi feshedebilir. Yazılı bildirimin Little Caesars’a ulaştığı tarihte Sözleşme sona erer ve Little Caesars Üye’nin kaydını kayıtlarından siler.</p>
                        <h2>6. Uygulanacak Hukuk, Yetkili Mahkeme ve İcra Daireleri</h2>
                        <p>İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir. İşbu Sözleşme ve uygulanmasından doğabilecek her türlü uyuşmazlıkların çözümünde, T.C. Gümrük ve Ticaret Bakanlığı tarafından belirlenen parasal sınırlar dâhilinde Üye’nin yerleşim yerinin bulunduğu veya işlemin yapıldığı yerdeki Tüketici Hakem Heyeti veya Tüketici Mahkemesi yetkili olacaktır.</p>
                        <h2>7. Yürürlük </h2>
                        <p>Üyenin, üyelik kaydı yapması üyenin üyelik sözleşmesinde yer alan tüm maddeleri okuduğu ve üyelik sözleşmesinde yer alan maddeleri kabul ettiği anlamına gelir. İşbu Sözleşme, Üye’nin, üyelik formunu doldurup onayladığı ve “Üyelik ve Satış Sözleşmesi Şartlarını Kabul Ediyorum” seçeneğine tıklayarak Sözleşme şartlarını onayladığı anda yürürlüğe girecektir ve İnternet Sitesi üzerinden yapacağı her bir satışa uygulanacaktır.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
};

export default UserAgreement;