import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../elements/TextInput';
import DropdownList from '../../elements/DropdownList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import getValidator from '../../../getValidator';
import Button from '../../elements/Button';
import { monthList, yearList } from './MonthAndYearLists';
import LcPhoneInput from '../../elements/LcPhoneInput';

class EditCreditCardModal extends Component {
    validator = getValidator(this);
    // Copy new features as mask from AddCreditCardModal. Or reuse cc form somehow
    state = {
        creditCardName: '',
        creditCardNumber: '',
        ccNoPart1: '',
        ccNoPart2: '',
        ccNoPart3: '',
        ccNoPart4: '',
        expirationMonth: '',
        expirationYear: '',
        phone: ''
    };

    creditCardNameInputHandler = event =>
        this.setState({ creditCardName: event.target.value });

    creditCardNumberInputHandler = event => {
        let creditCardNumber = '';

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            const { ccNoPart1, ccNoPart2, ccNoPart3, ccNoPart4 } = this.state;
            creditCardNumber = ccNoPart1 + ccNoPart2 + ccNoPart3 + ccNoPart4;
        });

        this.setState({ creditCardNumber });
    };

    selectMonth = month => {
        this.setState({ expirationMonth: month });
    };

    selectYear = year => {
        this.setState({ expirationYear: year });
    };

    phoneInputHandler = phone => this.setState({ phone });

    submit = () => {

    };

    render() {
        const {
            creditCardName, ccNoPart1, ccNoPart2, ccNoPart3, ccNoPart4, expirationMonth, expirationYear, phone
        } = this.state;
        return (
            <div className="modal-content credit-card-modal">
                <div className="modal-content__header">
                    Yeni Kart Tanımlama
                </div>
                <div>
                    <div className="credit-card-modal__input-block">
                        <TextInput label="Kartınıza İsim Verin"
                                   labelClassName="credit-card-modal__label"
                                   value={creditCardName}
                                   onChange={this.creditCardNameInputHandler}
                                   className="credit-card-modal__input"
                                   error={this.validator.message('Kartınıza İsim ', creditCardName, 'required')}
                        />

                        <div className="credit-card-modal__input-number-row">
                            <TextInput label="Kart Numarası"
                                       labelClassName="credit-card-modal__label"
                                       name="ccNoPart1"
                                       value={ccNoPart1}
                                       onChange={this.creditCardNumberInputHandler}
                                       className="credit-card-modal__input"
                                       error={this.validator.message('Kart Numarası ', ccNoPart1, 'required')}
                            />

                            <TextInput name="ccNoPart2"
                                       value={ccNoPart2}
                                       onChange={this.creditCardNumberInputHandler}
                                       className="credit-card-modal__input"
                                       error={this.validator.message('Kart Numarası  ', ccNoPart2, 'required')}
                            />

                            <TextInput name="ccNoPart3"
                                       value={ccNoPart3}
                                       onChange={this.creditCardNumberInputHandler}
                                       className="credit-card-modal__input"
                                       error={this.validator.message('Kart Numarası  ', ccNoPart3, 'required')}
                            />

                            <TextInput name="ccNoPart4"
                                       value={ccNoPart4}
                                       onChange={this.creditCardNumberInputHandler}
                                       className="credit-card-modal__input"
                                       error={this.validator.message('Kart Numarası  ', ccNoPart4, 'required')}
                            />
                        </div>
                        <div className="credit-card-modal__input-expiration-date-row">
                            <DropdownList label="Son kullanma Tarihi"
                                          labelClassName="credit-card-modal__label"
                                          value={expirationMonth}
                                          placeholder="Ay"
                                          onChange={this.selectMonth}
                                          className="credit-card-modal__input"
                                          error={this.validator.message('Ay', monthList.length ? true : '', 'required')}
                                          options={monthList}
                                          optionClassName="credit-card-modal__dropdown-option"
                            />

                            <DropdownList value={expirationYear}
                                          placeholder="Yıl"
                                          onChange={this.selectYear}
                                          className="credit-card-modal__input"
                                          error={this.validator.message('Yıl', yearList.length ? true : '', 'required')}
                                          options={yearList}
                                          optionClassName="credit-card-modal__dropdown-option"
                            />
                        </div>
                        <LcPhoneInput label="Telefon Numaranız"
                                      labelClassName="credit-card-modal__label"
                                      value={phone}
                                      onChange={this.phoneInputHandler}
                                      className="credit-card-modal__input credit-card-modal__input--phone"
                                      validator={this.validator}
                        />
                    </div>
                    <Button className="credit-card-modal__submit-button" onClick={this.submit}>Kaydet</Button>
                </div>
            </div>
        );
    }
}

export default EditCreditCardModal;