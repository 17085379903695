import React, { Component } from 'react';
import PropTypes from "prop-types";
import Input from "../../elements/Input";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import getValidator from "../../../getValidator";
import { changePassword } from "../../../store/actions/authActions";
import Button from "../../elements/Button";
import { toast } from "react-toastify";
import Toast from "../../elements/Toast";

class ChangePasswordModal extends Component {
    validator = getValidator(this);

    state = {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    };

    oldPasswordInputHandler = event => this.setState({ oldPassword: event.target.value });

    newPasswordInputHandler = event => this.setState({ newPassword: event.target.value });

    repeatNewPasswordInputHandler = event => this.setState({ repeatNewPassword: event.target.value });

    submit = () => {
        const { boundChangePassword, setModal } = this.props;
        const { oldPassword, newPassword } = this.state;

        this.validator.showMessages();
        if (this.validator.allValid()) {
            boundChangePassword(oldPassword, newPassword)
                .then(() => {
                    setModal('');
                    toast(<Toast text="Şifre başarıyla değiştirildi" status="success"/>, { autoClose: 3000 });
                })
                .catch(() => {
                    this.setState({ oldPassword: '' });
                    toast(<Toast text="Yanlış şifre" status="error"/>);
                });
        }
    };

    closeModal = () => this.props.setModal('');

    render() {
        const { oldPassword, newPassword, repeatNewPassword } = this.state;

        return <div className="modal-content change-password-modal">
            <div className="modal-content__header modal-content__header--centered">
                Şifre Değiştir
            </div>
            <div>
                <div className="change-password-modal__input-block">
                    <Input type="password"
                           label="Mevcut Şifre"
                           value={oldPassword}
                           onChange={this.oldPasswordInputHandler}
                           className="change-password-modal__input"
                           error={this.validator.message('mevcut şifre', oldPassword, 'required')}
                    />
                    <Input type="password"
                           label="Yeni Şifre"
                           value={newPassword}
                           onChange={this.newPasswordInputHandler}
                           className="change-password-modal__input"
                           error={this.validator.message('yeni şifre', newPassword, `required|password_comparing:${repeatNewPassword},yeni şifre tekrar`)}
                    />
                    <Input type="password"
                           label="Yeni Şifre Tekrar"
                           value={repeatNewPassword}
                           onChange={this.repeatNewPasswordInputHandler}
                           className="change-password-modal__input"
                           error={this.validator.message('yeni şifre tekrar', repeatNewPassword, `required|password_comparing:${newPassword},yeni şifre`)}
                    />
                </div>
                <Button className="change-password-modal__submit-button" onClick={this.submit}>Güncelle</Button>
                <div className="change-password-modal__cancel-container">
                    <span onClick={this.closeModal} className="link cancel-button">İptal</span>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({
    boundChangePassword: bindActionCreators(changePassword, dispatch)
});

ChangePasswordModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    boundChangePassword: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
