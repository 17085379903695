import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { setModal } from '../../store/actions/modalActions';
import Icon from '../elements/Icon';
import { getFooter } from '../../store/actions/layoutActions';
import { addClickEvent, addActionEvent } from '../../googleAnalyticsHelper';

class Footer extends Component {
    componentDidMount() {
        const { boundGetFooter } = this.props;
        boundGetFooter();
    }

    getAddEvent = link => () => {
        if (link.includes('hakkimizda')) {
            addClickEvent('Hakkinda');
        } else if (link.includes('franchising')) {
            addActionEvent('Basvur', 'Franchise Basvuru Formu');
        }
    };

    //for google analytics
    getAddFollowEvent = socialMedia => () => {
        addActionEvent('Bizi Takip Et', socialMedia);
        if (socialMedia === 'youtube')
            window.open('https://www.youtube.com/c/LittlecaesarsTr');
        else if (socialMedia === 'twitter')
            window.open('https://twitter.com/LittleCaesarsTR');
        else if (socialMedia === 'facebook')
            window.open('https://www.facebook.com/LittleCaesarsTR');
        else if (socialMedia === 'instagram')
            window.open('https://www.instagram.com/littlecaesarstr/');

    };

    callLCP = () => {
        addClickEvent('Ara Gelsin');
    };

    renderFooterLinks = () => {
        const { layout } = this.props;

        return <div className="footer__column">
            {
                layout && layout.footer
                    ? layout.footer.slice(0, 3).map(footerLink =>
                        <div key={footerLink.name} className="footer__column-item">
                            <div className="footer__column-item-header">
                                {footerLink.name}
                            </div>
                            <ul>
                                {
                                    footerLink['subMenus']
                                        ? footerLink['subMenus'].map(footerSubLink =>
                                            <li key={footerSubLink.name} onClick={this.getAddEvent(footerSubLink.link)}>
                                                <Link className="footer__link"
                                                      to={footerSubLink.link}
                                                >
                                                    {footerSubLink.name}
                                                </Link>
                                            </li>
                                        )
                                        : null
                                }
                            </ul>
                        </div>
                    )
                    : null
            }
        </div>;
    };

    goToHomePage = () => {
        const { history, location } = this.props;
        if (location.pathname !== '/') {
            history.push('/');
        }
    };

    goToProductPage = () => {
        const { history, location } = this.props;
        if (!location.pathname.includes('/siparis')) {
            history.push('/siparis/kampanyalar');
        }
    };

    openBasketModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('basket');
    };

    goToHandlingPage = () => {
        const { history, location } = this.props;
        if (location.pathname !== '/') {
            history.push('/');  // TODO: fill the link
        }
    };

    openLiveSupport = () => {
        addClickEvent('Canlı Destek');
        window.psvW5Sow();
    };

    renderFooterMobileMenu = () =>
        <div className="footer-mobile-menu">
            <div className="icon-with-label-vertical footer-mobile-menu__element" onClick={this.goToHomePage}>
                <Icon name="menu-house"/>
                <span>Ana Sayfa</span>
            </div>
            <div className="icon-with-label-vertical footer-mobile-menu__element" onClick={this.goToProductPage}>
                <Icon name="menu-crust"/>
                <span>Sipariş Ver</span>
            </div>
            <div className="footer-mobile-menu__element" onClick={this.openBasketModal}>
                {
                    this.props.basket.totalCount
                        ? <div className="app-header__basket-fullness">
                            {this.props.basket.totalCount}
                        </div>
                        : null
                }
                <div className="icon-with-label-vertical">
                    <Icon name="menu-basket"/>
                    <span>Sepetim</span>
                </div>
            </div>
            <div className="icon-with-label-vertical footer-mobile-menu__element" onClick={this.openLiveSupport}>
                <Icon name="menu-chat"/>
                <span>Canlı Destek</span>
            </div>
        </div>;

    render() {
        const { layout, location } = this.props;
        return (
            <div>
                <footer className="footer">
                    <div className="footer__body container">
                        <div className="footer__top">
                            <div className="footer__left">
                                <div className="footer__left-body">
                                    <div className="footer__left-body-logo">
                                        <a href="http://www.celebi.com/tr/" rel="noopener noreferrer" target="_blank">
                                            <img className="celebi-logo" src="/images/footer/celebi-logo.png"
                                                 alt="celebi-logo.png"/>
                                        </a>
                                    </div>
                                    <div className="footer__left-body-copyright">
                                        Copyright © Çelebi Holding 2020
                                    </div>
                                    <ul>
                                        <li>
                                            <Link className="footer__link"
                                                  to="/musteri-hizmetleri/kisisel-verilerin-korunmasi"
                                            >
                                                Kişisel Verilerin Korunması
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="footer__link"
                                                  to="/musteri-hizmetleri/kisisel-veri-saklama-imha-politikasi"
                                            >
                                                Kişisel Verileri Koruma ve İmha Politikası
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="footer__link" to="/musteri-hizmetleri/islem-rehberi">
                                                İşlem Rehberi
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="footer__link"
                                                  to="/musteri-hizmetleri/sezarla-kazan-kosullari">
                                                Sezarla Kazan Koşulları
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="footer__link" to="/musteri-hizmetleri/cerez-politikasi">
                                                Çerez Politikası
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="footer__left-body__application-icon">
                                        <ul>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=tr.com.littlecaesars"
                                                   rel="noopener noreferrer" target="_blank">
                                                    <img className="img-responsive"
                                                         src="/images/footer/google-play.svg"
                                                         alt="google-play.svg"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://itunes.apple.com/tr/app/little-caesars-t%C3%BCrkiye/id904413305?l=tr&mt=8"
                                                   rel="noopener noreferrer" target="_blank">
                                                    <img className="img-responsive"
                                                         src="/images/footer/ios-appstore.svg"
                                                         alt="ios-appstore.svg"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="footer__center">
                                {
                                    layout.footer && layout.footer.length
                                        ? this.renderFooterLinks()
                                        : null
                                }
                            </div>
                            <div className="footer__right">
                                <div className="footer__contact-us">
                                    <div className="footer__contact-us-header">
                                        BİZE ULAŞIN
                                    </div>
                                    <div className="footer__contact-us-body">
                                        <Link to="/kurumsal/iletisim">İletişim</Link>
                                    </div>
                                </div>

                                <div className="footer__contact-no">
                                    <div className="footer__contact-no-header">
                                        ARA GELSİN
                                    </div>
                                    <div className="footer__contact-no-body" onClick={this.callLCP}>
                                        444 52 44
                                    </div>
                                </div>
                                <div className="footer__media">
                                    <div className="footer__media-header">
                                        Bizi takip et
                                    </div>
                                    <div className="footer__media-body">
                                        <Link to="/" className="footer__icon-link"
                                              onClick={this.getAddFollowEvent('twitter')}>
                                            <Icon name="twitter" className="icon--white"/>
                                        </Link>
                                        <Link to="/" className="footer__icon-link"
                                              onClick={this.getAddFollowEvent('facebook')}>
                                            <Icon name="facebook" className="icon--white"/>
                                        </Link>
                                        <Link to="/" className="footer__icon-link"
                                              onClick={this.getAddFollowEvent('youtube')}>
                                            <Icon name="youtube" className="icon--white"/>
                                        </Link>
                                        <Link to="/" className="footer__icon-link"
                                              onClick={this.getAddFollowEvent('instagram')}>
                                            <Icon name="instagram" className="icon--white"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__bottom">
                        <div className="container">
                            <div className="footer__bottom__wrapper">
                                <div className="footer__bottom-left">
                                    <p>
                                        Little Caesars olarak her şubemizde yoğurduğumuz taze ve günlük pizza hamuru,
                                        sezon mahsulü domates ve gerçek mozzarella peyniriyle hazırladığımız butik pizza
                                        lezzetini tüm şubelerimize yaymış bir pizza zinciriyiz ve ürünlerimizde
                                        kullandığımız tüm malzemeleri yerli üreticilerden tedarik etmekteyiz.
                                        Pizzalarımızda kullanılan sosis, jambon, pepperoni, küp sucuk ve dilim sucuklar
                                        piliç etinden; pastırma ve kavurma dana etinden; döner ise dana ve kuzu etinden
                                        üretilmektedir.
                                    </p>
                                </div>
                                <div className="footer__bottom-right">
                                    <img src="/images/footer/clockwork.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <footer className="footer-mobile">
                    <div className="contact-us-section">
                        <div className="phone-wrapper">
                            <div className="contact-us-title" onClick={this.callLCP}>ARA GELSİN</div>
                            <a href="tel:4445244">444 52 44</a>
                        </div>
                        <div className="social-media-section">
                            <div className="social-media-title">Bizi takip et</div>
                            <div className="social-media-links">
                                <Link to="/" className="footer__icon-link"
                                      onClick={this.getAddFollowEvent('twitter')}
                                >
                                    <Icon name="twitter" className="icon--white"/>
                                </Link>
                                <Link to="/" className="footer__icon-link"
                                      onClick={this.getAddFollowEvent('facebook')}
                                >
                                    <Icon name="facebook" className="icon--white"/>
                                </Link>
                                <Link to="/" className="footer__icon-link"
                                      onClick={this.getAddFollowEvent('youtube')}
                                >
                                    <Icon name="youtube" className="icon--white"/>
                                </Link>
                                <Link to="/" className="footer__icon-link"
                                      onClick={this.getAddFollowEvent('instagram')}
                                >
                                    <Icon name="instagram" className="icon--white"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="company-section">
                        <div className="links-wrapper">
                            <img src="/images/footer/celebi-logo.svg" alt="celebi-logo.svg"/>
                            <div className="copyright-text">Copyright © Çelebi Holding 2020</div>
                            <ul>
                                <li>
                                    <Link className="footer-link" to="/musteri-hizmetleri/kisisel-verilerin-korunmasi">
                                        Kişisel Verilerin Korunması
                                    </Link>
                                </li>
                                <li>
                                    <Link className="footer-link"
                                          to="/musteri-hizmetleri/kisisel-veri-saklama-imha-politikasi">
                                        Kişisel Verileri Koruma ve İmha Politikası
                                    </Link>
                                </li>
                                <li>
                                    <Link className="footer-link" to="/musteri-hizmetleri/onbilgilendirme-formu">
                                        Ön Bilgilendirme Formu
                                    </Link>
                                </li>
                                <li>
                                    <Link className="footer-link" to="/musteri-hizmetleri/sezarla-kazan-kosullari">
                                        Sezarla Kazan Koşulları
                                    </Link>
                                </li>
                                <li>
                                    <Link className="footer-link" to="/musteri-hizmetleri/cerez-politikasi">
                                        Çerez Politikası
                                    </Link>
                                </li>
                            </ul>
                            <img src="/images/footer/clockwork.svg" alt=""/>
                        </div>
                        <p className="bottom-text">
                            Little Caesars olarak her şubemizde yoğurduğumuz taze ve günlük pizza hamuru, sezon mahsulü
                            domates ve gerçek mozzarella peyniriyle hazırladığımız butik pizza lezzetini tüm
                            şubelerimize yaymış bir pizza zinciriyiz ve ürünlerimizde kullandığımız tüm malzemeleri
                            yerli üreticilerden tedarik etmekteyiz. Pizzalarımızda kullanılan sosis, jambon, pepperoni,
                            küp sucuk ve dilim sucuklar piliç etinden; pastırma ve kavurma dana etinden; döner ise dana
                            ve kuzu etinden üretilmektedir.
                        </p>
                    </div>
                    {
                        !location.pathname.includes('/hesap/')
                            ? this.renderFooterMobileMenu()
                            : null
                    }
                </footer>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    layout: store.layout,
    basket: store.basket.basket
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundGetFooter: bindActionCreators(getFooter, dispatch)
});

Footer.propTypes = {
    boundSetModal: PropTypes.func.isRequired,
    boundGetFooter: PropTypes.func.isRequired,
    layout: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
