import { SET_PROMO_IMAGES, SET_MAIN_PAGE_CAMPAIGNS, SET_SPECIAL_SELECTIONS } from '../actions/homePageActions';

const initialState = {
  promoImages: [],
  mainPageCampaigns: [],
  specialSelections: []
};

const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMO_IMAGES:
      return {
        ...state,
        promoImages: action.payload
      }

    case SET_MAIN_PAGE_CAMPAIGNS:
        return {
          ...state,
          mainPageCampaigns: action.payload
        }

    case SET_SPECIAL_SELECTIONS:
      return {
        ...state,
        specialSelections: action.payload
      }

    default:
      return state
  }
}

export default homePageReducer