import React from 'react';

const Menu = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="2" y="4" width="20" height="2" />
        <rect x="2" y="11" width="20" height="2" />
        <rect x="2" y="18" width="20" height="2" />
    </svg>;

export default Menu;
