import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const FranchisingSupport = () => {
   sendPageView('/franchise/destekler', 'Franchise Destekler'); // for ga

    return <section class="institutional-structures">
    <div class="container">
    <BreadCrumbs />

        <div class="institutional-structures-detail">
            <div class="institutional-structures-detail-content clearfix">
                  <FranchisingMenu />
                <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>Destekler</h1>
                        <img class="img-responsive" src="/images/kurumsal/destekler.jpg" alt="destekler.jpg" />
                        <h3>Destekler</h3>
                        <h2>1- Uluslararası Tanınmışlık</h2>
                        <p>Pizza; tüm dünyada tanınan ve tercih edilen favori yiyeceklerden biridir. Little Caesars da dünyanın en önemli pizza şirketlerinden biri olarak yüksek kalitedeki ürünlerini, yüksek servis standartları ile müşterilerine ulaştırır.</p>
                        <h2>2- Eğitim</h2>
                        <p>Yeni işinize başlarken, uzman bir kadronun size sunacağı eğitimlerle, Little Caesars ürün ve hizmet kalitesini kendi restoranınızda uygulamak için hazır olacaksınız. Gerek teorik, gerekse pratik eğitimlerle, sadece yeni işinize başlarken değil, işiniz devam ederken de destek alırsınız.</p>
                        <h2>3- Denetim</h2>
                        <p>Hem müşterilerimizin memnuniyeti hem de franchisee’lerimizin yatırımlarını korumak amacıyla uzman operasyon ekiplerimizce hizmet, bilgi ve ürün kalitesi standartlarını periyodik olarak denetler ve sürekli iyileştirme çalışmaları yaparız.</p>
                        <h2>4- El Kitabı</h2>
                        <p>Eğitim departmanımız tarafından hazırlanan “Franchise El Kitabı”, ailemize katıldığınız anda size teslim edilerek, işletmecilik hayatınız boyunca her zaman danışabileceğiniz bir kılavuz olacaktır.</p>
                        <h2>5- Lokasyon Seçimi</h2>
                        <p>Doğru lokasyon seçimi her zaman sizi bir adım öne taşır. Little Caesars olarak biz de franchisee’lerimizin en doğru lokasyonu seçmeleri için teknolojik ve iş gücü olarak her türlü desteği vermekteyiz.</p>
                        <h2>6- Mimari Proje Desteği</h2>
                        <p>Lokasyon seçiminden sonra gerek projenizin hazırlanması, gerekse firmalar tarafından doğru uygulanması konusunda kontrol, kendi alanında profesyonel kadromuz tarafından sağlanır.</p>
                        <h2>7- Lojistik</h2>
                        <p>Tüm ürünler tarafımızdan veya çalıştığımız onaylı tedarikçilerimiz tarafından kapınıza kadar teslim edilir.</p>
                        <h2>8- İşletme Danışmanlığı</h2>
                        <p>İşinize başladığınız andan itibaren; sizi satış artırıcı faaliyetler, doğru operasyon yönetimi, kalite ve hizmet standartlarının uygulanması, personel yönetimi vb. konularda yönlendirecek bir danışmanınız olur.</p>
                        <h2>9- Pazarlama</h2>
                        <p>Günümüz ticari şartlarında rekabetin içinde fark edilir olabilmek adına kullanacağınız en önemli iletişim şekli pazarlamadır. Hem yerel olarak yapacağınız pazarlama faaliyetlerinde hem de ulusal pazarlama çalışmalarında konusunda uzman deneyimli kadrolar her zaman yanınızda olur.</p>
                        <h2>10- İnsan Kaynakları</h2>
                        <p>Gerek işe başlarken gerekse daha sonraki süreçlerde, çalışan ihtiyaçlarınız için aday havuzumuzdan yararlanabileceğiniz gibi yeni adaylar için anlaşmalı İK sitelerinde sizler için ilanlar vererek destek oluruz.</p>
                        <h2>11- Bilgi İşlem</h2>
                        <p>İşletmenizi doğru yönetmek, analizlerinizi doğru yapmak, maliyet ve stoklarınızı kontrol altında tutmak ve müşteri datalarınızı doğru işleyebilmek için kullanmakta olduğumuz özel bir yazılım programı sizin en büyük yardımcınız olacaktır.</p>
                        <h2>12- Ürün Çeşitliliği</h2>
                        <p>Little Caesars, ürün çeşitliliği ve tamamlayıcı ürünler açısından müşteri ihtiyaçlarını karşılayacak bir lezzet zenginliğine sahiptir.</p>
                        <h2>13- Sürekli Gelişim</h2>
                        <p>Şirketimizde franchisee’lerimize sağladığımız uluslararası işletme deneyimi ve eğitimiyle üstün bir profesyonel gelişim sağlarız. Ayrıca operasyon ekibimiz, franchisee’larımızın sürekli iş performansını takip ederek değerlendirir, gerekli yönlendirmeler ve satış artırıcı faaliyetler ile franchisee’lerimizin uygulamalarına yardımcı oluruz.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
    
};

export default FranchisingSupport;