import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { calculatePrice, getProductDetails } from '../../store/actions/productActions';
import { addToBasket, getBasket } from '../../store/actions/basketActions';
import { setModal } from '../../store/actions/modalActions';
import LcDropdown from '../elements/LcDropdown';
import getValidator from '../../getValidator';
import {
    formatMoney, extraProductOptionType, maxIngredientCount, scrollToWrongField, getRef, isMobile,
    getUserPermissionValue
} from '../../utils';
import ProductOptions from './ProductOptions';
import Button from '../elements/Button';
import {
    addCartOrderItemAction, addIngredientAction, sendPageView, addActionEvent, addProductDetailEvent,
    addClickProductEvent
} from '../../googleAnalyticsHelper';
import { getFromLocalStorage, setLocalStorage } from '../../storage';
import Icon from '../elements/Icon';
import { addFavoriteProduct, deleteFavoriteProduct } from '../../store/actions/memberActions';
import TextInput from '../elements/TextInput';

class ViewPromotionModal extends Component {
    validator = getValidator(this);

    state = {
        selectedPromotion: {},
        price: 0,
        extraIngredientPrice: 0,
        crustSelectionsPrice: 0,
        shouldRecalculate: false,
        openedSectionId: null
    };

    componentDidMount() {
        const { boundGetProductDetails, basket, location, productId, closeModal } = this.props;
        const { position, list, category } = this.getGAParams();

        boundGetProductDetails(productId, basket.id)
            .then(response => {
                const selectedPromotion = response.data.result;
                this.setPromotion(selectedPromotion);
                if (!selectedPromotion.id) {
                    return closeModal();
                }
                selectedPromotion.options.map(productSection => {
                    const defaultProduct = productSection.items.find(product => product.quantity > 0);
                    if (defaultProduct) {
                        this.getSelectProduct(productSection)(defaultProduct);
                    }
                });

                //For google analytics
                if (list === 'Banner') {
                    addClickProductEvent(selectedPromotion, position, list, category);  // We need this here because banner object doesn't contain all needed data
                }
                sendPageView(location ? location.pathname : selectedPromotion.link, selectedPromotion.name);
                addProductDetailEvent(selectedPromotion, position, list, category);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        const { closeModal } = this.props;
        const { selectedPromotion } = this.state;

        if (prevProps.loading === true && this.props.loading === false && this.state.shouldRecalculate) {
            this.calculatePrice(selectedPromotion);
            this.setState({ shouldRecalculate: false });
        }
        if (!this.props.productId && prevProps.productId) {
            closeModal();
        }
    }

    submit = () => {
        const { basket, boundAddToBasket, boundGetBasket, boundSetModal, closeModal } = this.props;
        const { selectedPromotion } = this.state;

        this.validator.showMessages();

        if (this.validator.allValid()) {
            boundAddToBasket(selectedPromotion, basket.id)
                .then(() => boundGetBasket().finally(() => {
                    const position = parseInt(getFromLocalStorage(`prodPos${selectedPromotion.id}`));
                    const category = getFromLocalStorage(`pCategory${selectedPromotion.id}`);
                    const list = getFromLocalStorage(`pList${selectedPromotion.id}`);
                    const orderItem = this.props.basket.orderItems.find(c => c.productId === selectedPromotion.id);

                    if (orderItem) {
                        addCartOrderItemAction(orderItem, position, 'add', category, list);   //For Google Analytics
                    }

                    if (closeModal) {
                        closeModal();
                    }
                    boundSetModal('basket');
                }));
        } else {
            const scrollElementSelector = isMobile() ? '.modal' : '.product-modal__right';
            setTimeout(() => scrollToWrongField(
                this,
                this.validator,
                document.querySelector(scrollElementSelector),
                20,
                'Promosyon Kodu'
            ), 20);
        }
    };

    getGetRef = () => refName => {
        return getRef(this, refName);
    };

    getGAParams = () => {
        const { productId } = this.props;
        let position;
        let list;
        let category;
        if (getFromLocalStorage('prodPos')) {
            position = parseInt(getFromLocalStorage('prodPos'));
            list = getFromLocalStorage('pList');
            category = getFromLocalStorage('pCategory');
            setLocalStorage('prodPos', '');
            setLocalStorage('pList', '');
            setLocalStorage('pCategory', '');
            setLocalStorage(`prodPos${productId}`, position);
            setLocalStorage(`pList${productId}`, list);
            setLocalStorage(`pCategory${productId}`, category);
        } else {
            position = parseInt(getFromLocalStorage(`prodPos${productId}`));
            list = getFromLocalStorage(`pList${productId}`);
            category = getFromLocalStorage(`pCategory${productId}`);
        }
        return { position, list, category };
    };

    calculatePrice = promotion => {
        const { boundCalculatePrice } = this.props;
        boundCalculatePrice(promotion)
            .then(response => this.setState({ price: response.data }));
    };

    setPromotion = promotion => {
        const { loading } = this.props;

        this.setState({ selectedPromotion: promotion });
        if (!loading) {
            this.calculatePrice(promotion);
        } else {
            this.setState({ shouldRecalculate: true });
        }
    };

    setInitialPrice = () => {
        const { selectedPromotion } = this.state;
        return formatMoney(selectedPromotion.options[0].items[0].price.price) + 'TL\'dan başlayan';
    };

    increaseCount = () => {
        const { selectedPromotion } = this.state;
        this.setPromotion({ ...selectedPromotion, quantity: selectedPromotion.quantity + 1 });
    };

    decreaseCount = () => {
        const { selectedPromotion } = this.state;
        if (selectedPromotion.quantity > 1) {
            this.setPromotion({ ...selectedPromotion, quantity: selectedPromotion.quantity - 1 });
        }
    };

    getCancelProduct = productSection => () => this.setSelectedProduct(productSection, null);

    getSelectProduct = productSection => product => {
        const { boundGetProductDetails, basket } = this.props;
        const { selectedPromotion} = this.state;
        const productType = productSection.name.toLowerCase();
        if (
            !productType.includes('pizza')
            && !productType.includes('döner seçimi')
            && (
                productType.includes('içecek')
                || productType.includes('promosyon')
                || productType.includes('dondurma')
                || productType.includes('tatlini')
                || productType.includes('patates')
                || productType.includes('döner')
                || productType.includes('patates')
                || productType.includes('gsa')
            )
        ) {
            this.setSelectedProduct(productSection, product);   // For sides and other
            this.setState({ openedSectionId: productSection.id });
        } else {
            boundGetProductDetails(product.id, basket.id, productSection.id,selectedPromotion.id )  // For pizza
                .then(response => this.setSelectedProduct(productSection, product, response.data.result.options));
        }
    };

    setSelectedProduct = (productSection, product, newOptions) => {
        const { selectedPromotion, openedSectionId } = this.state;

        if (!selectedPromotion.id) {
            return;
        }
        const newSelectedPromotion = { ...selectedPromotion };
        const newSelectedPromotionProducts = newSelectedPromotion.options.find(pS => pS.id === productSection.id).items;
        const prevSelectedProduct = newSelectedPromotionProducts.find(p => p.quantity === 1);
        if (prevSelectedProduct) {
            prevSelectedProduct.quantity = 0;
        }
        if (product) {  // TODO: fix bug. 1) select pizza and add some extra toppings 2) change pizza to another one 3) extra price will not be changed. One have to split counting of extra price to separate counters like extraPrices = { pizzaName: extraPriceForPizzaName }. Same for single pizzas.
            this.setState({ openedSectionId: productSection.id });
            const newSelectedProduct = newSelectedPromotionProducts.find(p => p.id === product.id);
            newSelectedProduct.quantity = 1;
            if (newOptions) {
                newSelectedProduct.options = newOptions;
                newSelectedProduct.options.map(option => {
                    if (option.optionType !== extraProductOptionType && option.items.length === 1) {
                        option.items[0].quantity = 1;
                    }
                    return option;
                });
            }
        } else if (openedSectionId === productSection.id) {
            this.setState({ openedSectionId: null });
        }
        this.setPromotion(newSelectedPromotion);
    };

    getSelectProductOption = productSection => (option, item) => () => {
        const { selectedPromotion } = this.state;
        const newSelectedPromotion = { ...selectedPromotion };
        const products = newSelectedPromotion.options.find(pS => pS.id === productSection.id).items;
        const optionItems = products.find(p => p.quantity === 1).options.find(o => o.id === option.id).items;
        let extraPrice = 0;
        const prevSelectedItem = optionItems.find(i => i.quantity === 1);

        if (prevSelectedItem) {
            prevSelectedItem.quantity = 0;
        }
        optionItems.find(i => i.id === item.id).quantity = 1;
        const crustSelections = optionItems.filter(c => c.quantity === 1);

        for (let i = 0; i < crustSelections.length; i++) {
            extraPrice += crustSelections[i].price.price;
        }

        this.setState({ crustSelectionsPrice: extraPrice });
        this.setPromotion(newSelectedPromotion);
    };

    getExtraIngredient = (productSection, item, newSelectedPromotion) => {
        const selectedProduct = newSelectedPromotion.options.find(pS => pS.id === productSection.id).items.find(p => p.quantity === 1);
        return selectedProduct.options.find(o => o.optionType === extraProductOptionType).items.find(i => i.id === item.id);
    };

    getAddExtraIngredient = productSection => item => {
        const { extraIngredientPrice, selectedPromotion } = this.state;
        const newSelectedPromotion = { ...selectedPromotion };
        const ingredient = this.getExtraIngredient(productSection, item, newSelectedPromotion);
        let extraPrice = extraIngredientPrice;

        if (ingredient.quantity >= maxIngredientCount) {
            return;
        }
        ingredient.quantity++;
        extraPrice += ingredient.price.price;
        this.setState({ extraIngredientPrice: extraPrice });
        this.setPromotion(newSelectedPromotion);

        addIngredientAction('Ekle', ingredient); //for google analytics
    };

    getRemoveExtraIngredient = productSection => item => {
        const { extraIngredientPrice, selectedPromotion } = this.state;
        const newSelectedPromotion = { ...selectedPromotion };
        const ingredient = this.getExtraIngredient(productSection, item, newSelectedPromotion);
        let extraPrice = extraIngredientPrice;

        if (ingredient.quantity <= 0) {
            return;
        }
        ingredient.quantity--;
        extraPrice -= ingredient.price.price;
        this.setState({ extraIngredientPrice: extraPrice });
        this.setPromotion(newSelectedPromotion);

        addIngredientAction('Çıkar', ingredient); //for google analytics
    };

    getCancelExtraIngredient = productSection => item => () => {
        const { extraIngredientPrice, selectedPromotion } = this.state;
        const newSelectedPromotion = { ...selectedPromotion };
        const ingredient = this.getExtraIngredient(productSection, item, newSelectedPromotion);
        let extraPrice = extraIngredientPrice;

        extraPrice -= ingredient.quantity * ingredient.price.price;
        ingredient.quantity = 0;
        this.setState({ extraIngredientPrice: extraPrice });
        this.setPromotion(newSelectedPromotion);

        addIngredientAction('Çıkar', ingredient); //for ga  // TODO: do we need separate ga actions for remove all ingredients(cancel) and remove just one
    };

    getSelectedProductOfSection = productSection => productSection.items.find(p => p.quantity === 1) || {};

    toggleFavorite = () => {
        const { boundAddFavoriteProduct, boundDeleteFavoriteProduct } = this.props;
        const { selectedPromotion } = this.state;

        if (selectedPromotion.isFavorite) {
            boundDeleteFavoriteProduct(selectedPromotion.favoriteId)
                .then(() => {
                    this.setState({ selectedPromotion: { ...selectedPromotion, isFavorite: false } });
                    addActionEvent('Favorilereden Çıkar', selectedPromotion.name); //for ga
                });
        } else {
            boundAddFavoriteProduct(selectedPromotion.id)
                .then(response => {
                    this.setState({
                        selectedPromotion: {
                            ...selectedPromotion,
                            isFavorite: true,
                            favoriteId: response.data.result.id
                        }
                    });
                    addActionEvent('Favorilere Ekle', selectedPromotion.name); //for ga
                });
        }
    };

    sortPromotionOptionItems = items => items.sort((a, b) => {
        if (a.price.price < b.price.price) {
            return -1;
        } else if (a.price.price > b.price.price) {
            return 1;
        } else {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        }
    });

    getOnSectionDropdownClick = productSection => {
        const { openedSectionId } = this.state;
        if (
            openedSectionId && productSection.id !== openedSectionId
            && this.getSelectedProductOfSection(productSection).options
            && this.getSelectedProductOfSection(productSection).options.length
        ) {
            return () => this.setState({ openedSectionId: productSection.id });
        }
    };

    handlePromoCodeInput = event => {
        const { selectedPromotion } = this.state;
        this.setState({ selectedPromotion: { ...selectedPromotion, promotionCode: event.target.value } });
    };

    getProductSectionIconName = productSection => {
        const productType = productSection.name.toLowerCase();
        if (productType.includes('pizza')) {
            return 'pizza';
        }
        if (productType.includes('İçecek'.toLowerCase()) || productType.includes('dondurma')) {
            return 'drink';
        }
        if (productType.includes('tatlini')) {
            return 'desert';
        }
        if (productType.includes('patates')) {
            return 'potato';
        }
        if (productType.includes('promosyon') || productType.includes('döner') || productType.includes('gsa')) {
            return 'potato';    // Add more types if needed
        }
    };

    getProductDropdownPlaceholder = productSection => {
        const productType = productSection.name.toLowerCase();
        if (productType.includes('pizza')) {
            return 'Pizzanı Seç';
        }
        if (productType.includes('içecek')) {
            return 'İçeceğini Seç';
        }
        if (productType.includes('promosyon') || productType.includes('gsa')) {
            return 'Yan Ürün Seç';
        }
        return 'Seçimini Yap';
    };

    getFavoriteButtonClassName = mode => {
        const { loading } = this.props;
        const { selectedPromotion } = this.state;
        let className = `product-modal__favorite-button icon-with-label product-modal__favorite-button--${mode}`;
        if (loading) {
            className += ' product-modal__favorite-button--disabled';
        }
        if (selectedPromotion.isFavorite) {
            className += ' product-modal__favorite-button--active';
        }
        return className;
    };

    getFavoriteButtonIconClassName = () => {
        const { selectedPromotion } = this.state;
        let className = 'product-modal__favorite-button-icon';
        if (selectedPromotion.isFavorite) {
            className += ' product-modal__favorite-button-icon--active';
        }
        return className;
    };

    getSectionDropdownClassName = isSelectedSection =>
        isSelectedSection
            ? 'product-modal__dropdown lc-dropdown--content-as-modal'
            : 'lc-dropdown--content-as-modal';

    getSubmitButtonClassName = () => {
        const { user } = this.props;
        const { selectedPromotion } = this.state;
        let className = 'product-modal__submit-button';
        if (
            selectedPromotion.isGift === 'true'
            && user.id && user.marketingPermission
            && !getUserPermissionValue(user, 'IsLoyaltyApproved')
        ) {
            className += ' product-modal__submit-button--disabled';
        }
        return className;
    };

    renderProductPreview = product =>
        <div className="product-dropdown-preview">
            <div className="product-dropdown-preview__image-container">
                {
                    product.image
                        ? <img src={product.image} alt=""/>
                        : <img src="/images/placeholder-image.png" alt=""/>
                }
            </div>
            <div className="product-dropdown-preview__right">
                <div className="product-dropdown-preview__name">{product.name}</div>
                {
                    product.detail
                        ? <div className="product-dropdown-preview__detail">{product.detail}</div>
                        : null
                }
                {
                    product.price && product.price.price
                        ? <div className="product-dropdown-preview__price">+{formatMoney(product.price.price)} TL</div>
                        : null
                }
                <div className="lc-button lc-button--light">
                    Seç
                </div>
            </div>
        </div>;

    render() {
        const { user } = this.props;
        const { selectedPromotion, price, extraIngredientPrice, crustSelectionsPrice, openedSectionId } = this.state;
        const totalExtraPrice = extraIngredientPrice + crustSelectionsPrice;

        this.validator.purgeFields();
        if (!selectedPromotion.id) {
            return null;
        }
        return <div className="modal-content product-modal">
            <div className="product-modal__left">
                <div className="product-modal__image-container">
                    {
                        selectedPromotion.image
                            ? <img className="product-modal__image" src={selectedPromotion.image} alt=""/>
                            : <img className="product-modal__image" src="/images/promo-placeholder-image.png" alt=""/>
                    }
                    {
                        user.id && selectedPromotion.isGift === 'false'
                            ? <div className={this.getFavoriteButtonClassName('mobile')}
                                   onClick={this.toggleFavorite}
                            >
                                <div className={this.getFavoriteButtonIconClassName()}>
                                    <Icon name="heart"/>
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div className="product-modal__block">
                    <div className="product-modal__name">{selectedPromotion.name}</div>
                    {
                        selectedPromotion.detail
                            ? <div className="product-modal__detail">{selectedPromotion.detail}</div>
                            : null
                    }
                </div>
                {
                    user.id && selectedPromotion.isGift === 'false'
                        ? <div className={this.getFavoriteButtonClassName('desktop')}
                               onClick={this.toggleFavorite}
                        >
                            <div className={this.getFavoriteButtonIconClassName()}>
                                <Icon name="heart"/>
                            </div>
                            <span>Favorilere ekle</span>
                        </div>
                        : null
                }
            </div>
            <div className="product-modal__right">
                <div className="product-modal__promotion-option-dropdown-container">
                    {
                        selectedPromotion.options.map(productSection =>
                            <div key={productSection.id} className="product-modal__block"
                                 ref={getRef(this, productSection.name)}>
                                <LcDropdown value={this.getSelectedProductOfSection(productSection).name || ''}
                                            className={this.getSectionDropdownClassName(openedSectionId === productSection.id)}
                                            suggestions={this.sortPromotionOptionItems(productSection.items)}  //
                                            onSelect={this.getSelectProduct(productSection)}
                                            renderSuggestion={this.renderProductPreview}
                                            firstIconName={this.getProductSectionIconName(productSection)}
                                            disabled={productSection.items.length <= 1}
                                            placeholder={this.getProductDropdownPlaceholder(productSection)}
                                            error={this.validator.message(productSection.name, this.getSelectedProductOfSection(productSection).name, 'required')} // field should be unique
                                            onClick={this.getOnSectionDropdownClick(productSection)}
                                            onFocus={(e) => e.target.blur()}
                                />
                                {
                                    this.getSelectedProductOfSection(productSection).options
                                    && this.getSelectedProductOfSection(productSection).options.length
                                        ? <ProductOptions getSelectOption={this.getSelectProductOption(productSection)}
                                                          cancelProduct={this.getCancelProduct(productSection)}
                                                          selectedProduct={this.getSelectedProductOfSection(productSection)}
                                                          addExtraIngredient={this.getAddExtraIngredient(productSection)}
                                                          removeExtraIngredient={this.getRemoveExtraIngredient(productSection)}
                                                          getCancelExtraIngredient={this.getCancelExtraIngredient(productSection)}
                                                          productSection={productSection}
                                                          validator={this.validator}
                                                          disabled={productSection.items.length <= 1}
                                                          isSectionOpened={openedSectionId === productSection.id}
                                                          getRef={this.getGetRef()}
                                        />
                                        : null
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="product-modal__bottom">
                {
                    selectedPromotion.isGift === 'false'
                        ? <>
                            <div className="product-modal__total-price-container">
                                <div className="product-modal__total-price">
                                    {
                                        price === 0
                                            ? this.setInitialPrice()
                                            : formatMoney(price) + 'TL'
                                    }
                                </div>
                                {
                                    totalExtraPrice > 0
                                        ? <div className="product-modal__total-extra-price">
                                            Ek malzemeler {formatMoney(totalExtraPrice)} TL
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="product-modal__count-buttons-container">
                                <div className="product-modal__count-buttons">
                                    <div className="product-modal__count-button product-modal__count-button--minus"
                                         onClick={this.decreaseCount}
                                    >
                                        -
                                    </div>
                                    <div className="product-modal__count-number">{selectedPromotion.quantity}</div>
                                    <div className="product-modal__count-button product-modal__count-button--plus"
                                         onClick={this.increaseCount}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        </>
                        : null
                }
                {
                    selectedPromotion.codeRequired
                        ? <TextInput type="text"
                                     placeholder="Promosyon Kodu"
                                     value={selectedPromotion.promotionCode}
                                     onChange={this.handlePromoCodeInput}
                                     error={this.validator.message('Promosyon Kodu', selectedPromotion.promotionCode, 'required')}
                                     containerClassName="product-modal__promo-code-input"
                                     rootRef={getRef(this, 'Promosyon Kodu')}
                        />
                        : null
                }
                <Button className={this.getSubmitButtonClassName()} onClick={this.submit}>
                    Sepete Ekle
                </Button>
                {
                    selectedPromotion.isGift === 'true'
                    && user.id && user.marketingPermission
                    && !getUserPermissionValue(user, 'IsLoyaltyApproved')
                        ? <span className="product-modal__submit-disabled-text">Sezarla Kazan aktif değildir</span>
                        : null
                }
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    basket: store.basket.basket,
    user: store.user.user,
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({
    boundAddFavoriteProduct: bindActionCreators(addFavoriteProduct, dispatch),
    boundDeleteFavoriteProduct: bindActionCreators(deleteFavoriteProduct, dispatch),
    boundGetProductDetails: bindActionCreators(getProductDetails, dispatch),
    boundAddToBasket: bindActionCreators(addToBasket, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundCalculatePrice: bindActionCreators(calculatePrice, dispatch)
});

ViewPromotionModal.propTypes = {
    closeModal: PropTypes.func,
    boundAddFavoriteProduct: PropTypes.func.isRequired,
    boundDeleteFavoriteProduct: PropTypes.func.isRequired,
    boundGetProductDetails: PropTypes.func.isRequired,
    boundAddToBasket: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func,
    boundCalculatePrice: PropTypes.func.isRequired,
    productId: PropTypes.string.isRequired,
    basket: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPromotionModal);
