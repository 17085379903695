import React from 'react';
import { sendPageView } from '../../../googleAnalyticsHelper';

const TermsOfUseMasterpass = () => {
    sendPageView('/musteri-hizmetleri/kullanim-kosullari-masterpass', 'Kullanım Koşulları - Little Caesars'); //for ga
    
    return  <section>
            <div class="col-xs-12 free-sezar-top">
                <div class="row">
                    <div class="container">
                        <h1>LC İZİN YAZ, 3848'E, <br />SMS GÖNDER<br />SEZAR ET DÖNER KAZAN</h1>
                     </div>
                </div>
                    </div>
                    <div class="col-xs-12 quality-middle">
                        <div class="row">
                            <div class="container">
                                <div class="col-xs-12 quality-card">
                                    <div class="row">
                                        <div class="col-sm-6 col-xs-12 imga">
                                            <div class="row">
                                                <img src="/images/free-sezar/bedava-sezar-1.png" alt="Bedava Sezar Kampanyası" />
                            </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12 cont">
                                                <div class="inner">
                                                    <p>Ücretsiz Sezar Et Döner menü kazanmak için tek yapmanız gereken İzinli SMS linkimize tıklamak.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 quality-card">
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12 cont hidden-xs">
                                                <div class="inner">
                                                    <p>Açılan linkte yer alan forma; adınızı, soyadınızı, cep telefonu numaranızı ve e-posta bilgilerinizi eksiksiz olarak doldurmalısınız. Formun altında yer alan izin kutucuklarını da seçmeyi unutmamalısınız.</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-xs-12 imga">
                                                <div class="row">
                                                    <img src="/images/free-sezar/bedava-sezar-2.png" alt="Bedava Sezar Kampanyası" />
                            </div>
                                                </div>
                                                <div class="col-sm-6 col-xs-12 cont hidden-lg hidden-md hidden-sm">
                                                    <div class="inner">
                                                        <p>Açılan linkte yer alan forma; adınızı, soyadınızı, cep telefonu numaranızı ve e-posta bilgilerinizi eksiksiz olarak doldurmalısınız. Formun altında yer alan izin kutucuklarını da seçmeyi unutmamalısınız.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 quality-card">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12 imga">
                                                    <div class="row">
                                                        <img src="/images/free-sezar/bedava-sezar-3.png" alt="Bedava Sezar Kampanyası" />
                            </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12 cont">
                                                        <div class="inner">
                                                            <p>Formu eksiksiz doldurduktan ve izin seçeneklerini tıkladıktan sonra size bir doğrulama SMS’i göndereceğiz. Gelen SMS’teki kodu web sitemizde açılan ilgili alana yazmanız gerekiyor.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xs-12 quality-card">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xs-12 cont hidden-xs">
                                                        <div class="inner">
                                                            <p>Tüm adımları tamamladınız. Cep telefonunuza göndereceğimiz kod ile nefis Sezar Et Döner ürünümüzü bir sonraki siparişinize ek olarak ücretsiz alabileceksiniz. Tek yapmanız gereken web sitemizin ana sayfasında yer alan Kupon Kodu Kullan bölümüne kodu girmek…</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12 imga">
                                                        <div class="row">
                                                            <img src="/images/free-sezar/bedava-sezar-4.png" alt="Bedava Sezar Kampanyası" />
                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-xs-12 cont hidden-lg hidden-md hidden-sm">
                                                            <div class="inner">
                                                                <p>Tüm adımları tamamladınız. Cep telefonunuza göndereceğimiz kod ile nefis Sezar Et Döner ürünümüzü bir sonraki siparişinize ek olarak ücretsiz alabileceksiniz. Tek yapmanız gereken web sitemizin ana sayfasında yer alan Kupon Kodu Kullan bölümüne kodu girmek…</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>  
        </section>

};

export default TermsOfUseMasterpass;