import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Validator from 'validator';
import AuthNav from '../navigation/AuthNav';
import TextInput from '../elements/TextInput';
import Checkbox from '../elements/Checkbox';
import Button from '../elements/Button';
import { addActionEvent } from '../../googleAnalyticsHelper';
import LcPhoneInput from '../elements/LcPhoneInput';
import FacebookLogin from 'react-facebook-login';
import { stopPropagation } from '../../utils';
class RegisterForm extends Component {
    state = {
        fields: {
            nameSurname: '',
            email: '',
            phone: '',
            password: '',
            isMailingConfirmed: false,
            isSmsConfirmed: false,
            areTermsAccepted: false,
            isLoyaltyConfirmed: false,
            facebookId: null,
            accessToken: null
        },
        errors: {},
        touched: {},
        formSubmitted: false,
        formIsValid: false
    };

    componentDidUpdate(prevProps, prevState) {
        const { userExistsError } = this.props;

        if (userExistsError && prevProps.userExistsError !== this.props.userExistsError)
            this.resetForm();
    }

    handleInputChange = e =>
        this.setState({ fields: { ...this.state.fields, [e.target.name]: e.target.value } }, this.validateForm);

    handleMailingCheckbox = () => {
        const { fields } = this.state;
        this.setState({ fields: { ...fields, isMailingConfirmed: !fields.isMailingConfirmed } });
        addActionEvent('E-Posta ile haberdar ol', !fields.isMailingConfirmed ? 'ON' : 'OFF');
    };

    handleSmsCheckbox = () => {
        const { fields } = this.state;
        this.setState({ fields: { ...fields, isSmsConfirmed: !fields.isSmsConfirmed } });
        addActionEvent('E-Posta ile haberdar ol', !fields.isSmsConfirmed ? 'ON' : 'OFF');   // change it?
    };

    handleLoyaltyCheckbox = () => {
        const { fields } = this.state;
        this.setState({ fields: { ...fields, isLoyaltyConfirmed: !fields.isLoyaltyConfirmed } });
    };

    handleTermsCheckbox = e => {
        const { fields } = this.state;
        if (e.target.tagName.toLowerCase() === 'a') {
            return;
        }
        this.setState({ fields: { ...fields, areTermsAccepted: !fields.areTermsAccepted } }, this.validateForm);
        addActionEvent('E-Posta ile haberdar ol', !fields.areTermsAccepted ? 'ON' : 'OFF');   // change it?
    };

    handlePhoneInput = phone => this.handleInputChange({ target: { value: phone, name: 'phone' } });

    handleInputBlur = e => {
        const { touched } = this.state;

        if (e.target.name && touched[e.target.name] !== true) {
            touched[e.target.name] = true;
            this.setState({ touched }, this.validateForm);
        }
    };

    handlePhoneBlur = phone => this.handleInputBlur({ target: { value: phone, name: 'phone' } });

    handleFormSubmit = e => {
        const { fields } = this.state;
        const { postRegister } = this.props;
        e.preventDefault();

        if (this.validateForm()) {
            this.setState({ errors: {}, formSubmitted: true });
            postRegister(
                fields.nameSurname,
                fields.email,
                fields.phone.substring(2),
                fields.password,
                fields.isMailingConfirmed,
                fields.isSmsConfirmed,
                fields.isLoyaltyConfirmed
            );
            this.resetForm();
        }
    };

    resetForm = () => {
        let fields = {};
        fields['nameSurname'] = '';
        fields['email'] = '';
        fields['phone'] = '90';
        fields['password'] = '';
        fields['isMailingConfirmed'] = false;
        fields['isSmsConfirmed'] = false;
        fields['isLoyaltyConfirmed'] = false;
        fields['areTermsAccepted'] = false;
        fields['facebookId'] = null;
        fields['accessToken'] = null;
        this.setState({ fields, formSubmitted: false, formIsValid: false });
    };


    signupWithFacebook = response => {
        const { postRegisterWithFacebook } = this.props;
        if (response && response.accessToken) {
            postRegisterWithFacebook(response.accessToken, response.picture.data.url);
        }
    };

    validateForm = () => {
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;
        this.setState({
            errors
        });
        //validate nameSurname
        if (!fields.nameSurname) {
            formIsValid = false;
            errors.nameSurname = 'Lütfen Ad ve Soyad giriniz';
        } else {
            if (fields.nameSurname.length < 3) {
                formIsValid = false;
                errors.nameSurname = 'Ad ve Soyadınız en az 3 karakter olmalıdır';
            }
        }

        //validate email
        if (!fields.email) {
            formIsValid = false;
            errors.email = 'Lütfen email adresi giriniz';
        } else {
            if (!Validator.isEmail(fields.email)) {
                formIsValid = false;
                errors.email = 'Yanlış formatta email adresi girdiniz';
            }
        }

        //validate password
        if (!fields.password) {
            formIsValid = false;
            errors.password = 'Lütfen şifrenizi giriniz';
        } else {
            if (fields.password.length < 3) {
                formIsValid = false;
                errors.password = 'Şifreniz en az 3 karakter olmalıdır';
            }
        }

        //validate telephone
        if (!fields.phone) {
            formIsValid = false;
            errors.phone = 'Lütfen Telefon giriniz';
        } else {
            if (fields.phone.substring(0, 3) !== '905' && !isNaN(fields.phone)) {
                formIsValid = false;
                errors.phone = 'Telefon numarasını 5 ile başlayıp sadece rakamlardan oluşacak şekilde giriniz';
            } else {
                if (fields.phone.length !== 12) {
                    formIsValid = false;
                    errors.phone = 'Telefon Numarası 12 karakter olmalıdır';
                }
            }
        }

        if (!fields.areTermsAccepted) {
            formIsValid = false;
        }

        this.setState({
            errors,
            formIsValid
        });

        return formIsValid;
    };

    render() {
        const { fields, errors, touched, formSubmitted, formIsValid } = this.state;
        return (
            <div className="form-register">
                <div className="form-wrapper">
                    <AuthNav isSignUp={true}/>
                    <div className="form-register__input-block">
                        <TextInput type="text"
                                   className="form-register__input"
                                   placeholder="Ad Soyad"
                                   name="nameSurname"
                                   value={fields.nameSurname}
                                   onChange={this.handleInputChange}
                                   onBlur={this.handleInputBlur}
                                   error={(formSubmitted || touched.nameSurname) && errors.nameSurname ? errors.nameSurname : null}
                        />

                        <TextInput type="email"
                                   className="form-register__input"
                                   placeholder="E-Posta"
                                   name="email"
                                   value={fields.email}
                                   onChange={this.handleInputChange}
                                   onBlur={this.handleInputBlur}
                                   error={(formSubmitted || touched.email) && errors.email ? errors.email : null}
                        />
                        <LcPhoneInput value={fields.phone}
                                      onChange={this.handlePhoneInput}
                                      onBlur={this.handlePhoneBlur}
                                      error={(formSubmitted || touched.phone) && errors.phone ? errors.phone : null}
                        />
                        <TextInput type="password"
                                   className="form-register__input"
                                   placeholder="Şifre"
                                   name="password"
                                   value={fields.password}
                                   minLength="3"
                                   onChange={this.handleInputChange}
                                   onBlur={this.handleInputBlur}
                                   error={(formSubmitted || touched.password) && errors.password ? errors.password : null}
                        />
                    </div>

                    <div className="form-register__checkbox-block">
                    <div className="form-register__checkbox-label">
                                          <span>Little Caesars KVKK Aydınlatma Metni'ne </span>
                                          <Link to="/musteri-hizmetleri/kisisel-verilerin-korunmasi"
                                                target="_blank"
                                          >
                                              buradan
                                          </Link>
                                          <span> ulaşabilirsiniz.</span>
                                      </div>
                   
                        <Checkbox className="form-register__checkbox"
                                  value={fields.isMailingConfirmed}
                                  label="Bana özel hazırlanmış veya benim için geçerli olan kampanyalar, indirimler ve haberler hakkında e-posta almak istiyorum."
                                  labelClassName="form-register__checkbox-label"
                                  onChange={this.handleMailingCheckbox}
                        />
                        <Checkbox className="form-register__checkbox"
                                  value={fields.isSmsConfirmed}
                                  label="Bana özel hazırlanmış veya benim için geçerli olan kampanyalar, indirimler ve haberler hakkında SMS almak istiyorum."
                                  labelClassName="form-register__checkbox-label"
                                  onChange={this.handleSmsCheckbox}
                        />
                        <Checkbox className="form-register__checkbox icon-with-label"
                                  value={fields.areTermsAccepted}
                                  activeColor="yellow"
                                  onChange={this.handleTermsCheckbox}
                                  label={
                                      <div className="form-register__checkbox-label">Little Caesars Web Sitesi 
                                          <Link to="/musteri-hizmetleri/uyelik-sozlesmesi" target="_blank">
                                               *Üyelik ve Satış Sözleşmesini
                                          </Link>
                                                                              
                                          <span>okudum, kabul ediyorum.</span>
                                      </div>
                                  }
                        />
                        <Checkbox className="form-register__checkbox"
                                  value={fields.isLoyaltyConfirmed}
                                  label={
                                      <div className="form-register__checkbox-label">
                                          <span>Hediyeni kazanmak için programa katıl. </span>
                                          <Link to="/musteri-hizmetleri/sezarla-kazan-kosullari"
                                                target="_blank"
                                                onClick={stopPropagation}
                                          >
                                              Sezarla Kazan Koşullarını
                                          </Link>
                                          <span>kabul ediyorum.</span>
                                      </div>
                                  }
                                  labelClassName="form-register__checkbox-label"
                                  onChange={this.handleLoyaltyCheckbox}
                        />
                    </div>
                    <div className="form-register__submit">
                        <Button color="light_green"
                                disabled={!formIsValid}
                                onClick={this.handleFormSubmit}
                                type="submit">
                            Kayıt ol
                        </Button>
                        <div className="form-register__caption">
                        <span>
                            veya
                        </span>
                        </div>
                        <div className="form-register__button">
                        <FacebookLogin
                        appId="295441695023663"
                        autoLoad={false}
                        fields="name,email,picture" 
                        onClick={this.signupWithFacebook}
                        callback={this.signupWithFacebook} 
                        icon="fa-facebook"
                        language="tr_TR"
                        textButton="Facebook İle Üye Ol"
                        cssClass="button button--facebook"/>  
                     
                        </div>
                        <small className="form-register__footer">
                            <span className="mr-2">
                                <svg enableBackground="new 0 0 24 24" height="12px" version="1.1" viewBox="0 0 24 24"
                                     width="12px"
                                >
                                    <path clipRule="evenodd"
                                          d="M20.002,24.001H4.014c-1.104,0-1.998-0.896-1.998-2.001V11.994  c0-1.105,0.895-2.002,1.998-2.002h0.999V6.991c0-3.868,3.132-7.004,6.995-7.004s6.995,3.136,6.995,7.004v3.001h0.999  c1.104,0,1.998,0.896,1.998,2.002V22C22,23.104,21.105,24.001,20.002,24.001z M16.005,6.991c0-2.21-1.79-4.002-3.997-4.002  S8.011,4.781,8.011,6.991v3.001h7.994V6.991z"
                                          fillRule="evenodd"
                                    /></svg>
                            </span>
                            Facebook’ta sizden izinsiz hiçbir şey paylaşmıyoruz.
                        </small>
                    </div>
                </div>
            </div>
        );
    }
}

RegisterForm.propTypes = {
    postRegister: PropTypes.func.isRequired
};

export default RegisterForm;
