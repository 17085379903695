import React from 'react';
import CustomerServiceMenu from '../customer-service-menu/CustomerServiceMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';
const PersonalDataPrivacyRevoke = () => {
    sendPageView('/musteri-hizmetleri/kisisel-verilerin-korunmasi-imha', 'Kişisel Verilerin Korunması Saklama ve İmha Politikası - Little Caesars'); //for ga
    return <section class="institutional-structures">
            <div class="container">
            <BreadCrumbs />
                <div class="institutional-structures-detail  bg-white2 ">
                    <div class="institutional-structures-detail-content clearfix">
                        <CustomerServiceMenu />
                        <div class="col-md-9">
                            <div class="corpareta-detail">
                                <h1>ÇELEBİ HİZMET GIDA İŞLETMELERİ TUR. SAN. ve TİC. A.Ş 
KİŞİSEL VERİ SAKLAMA VE İMHA POLİTİKASI
</h1>
                                <p><strong>İ&Ccedil;İNDEKİLER</strong></p>
<p><a href="#_Toc506124839">1..... AMA&Ccedil; VE KAPSAM</a></p>
<p><a href="#_Toc506124840">2..... TANIMLAR</a></p>
<p><a href="#_Toc506124841">3..... GENEL KAPSAM VE YASAL DAYANAK</a></p>
<p><a href="#_Toc506124842">3.1. Kişisel Verilerin Saklanmasını Gerektiren Hukuki, Teknik ya da Diğer Sebepler</a></p>
<p><a href="#_Toc506124843">3.2. Kişisel Verilerin Hukuka Uygun Olarak İmha Edilmesi i&ccedil;in Alınmış Teknik ve İdari Tedbirler, Kişisel Verilerin İşleme Şartlarının Ortadan Kalkması Durumunda Yapılacaklar</a></p>
<p><a href="#_Toc506124844">3.3. Kişisel Verilerin G&uuml;venli Bir Şekilde Saklanması ile Hukuka Aykırı Olarak İşlenmesinin ve Kişisel Verilere Erişilmesinin &Ouml;nlenmesi i&ccedil;in Alınmış Olan İdari ve Teknik Tedbirler</a></p>
<p><a href="#_Toc506124845">3.4. Kişisel Verileri Saklama ve İmha S&uuml;re&ccedil;lerinde Yer Alanların G&ouml;rev ve Sorumlulukları</a></p>
<p><a href="#_Toc506124846">3.4.1. Kişisel Verileri Koruma Komitesi</a></p>
<p><a href="#_Toc506124847">3.4.2. İrtibat Kişisi</a></p>
<p><a href="#_Toc506124848">4..... KİŞİSEL VERİLERİ SAKLAMA VE İMHA S&Uuml;RELERİ</a></p>
<p><a href="#_Toc506124849">5..... Y&Uuml;R&Uuml;RL&Uuml;K</a></p>
<p>&nbsp;</p>
<table width="633">
<tbody>
<tr>
<td width="633">
<h1>1. AMA&Ccedil; VE KAPSAM</h1>
</td>
</tr>
</tbody>
</table>
<p>Bu Politika, 6698 sayılı Kişisel Verilerin Korunması Kanunu (&ldquo;KVKK&rdquo;) ve bu Kanuna dayalı olarak &ccedil;ıkarılmış olan Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Y&ouml;netmelik gereği kişisel verilerin saklanması ve imhasına ilişkin <strong>&Ccedil;elebi Hizmet Gıda Tur. San. ve Tic A.Ş&rsquo;nin </strong>(Bundan sonra &ldquo;Şirket&rdquo; olarak anılacaktır) y&uuml;k&uuml;ml&uuml;l&uuml;klerinin yerine getirilmesini teminen izlenecek s&uuml;recin temel esaslarının belirlenmesi amacıyla d&uuml;zenlenmiştir.</p>
<p>Bu Politika, Şirket nezdinde tutulan, Kanun ile tanımlanan kişisel verileri ve &ouml;zel nitelikli kişisel verileri kapsamaktadır.</p>
<p>Kanunda belirtildiği şekilde; tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin par&ccedil;ası olmak kaydıyla otomatik olmayan yollarla verilerin işlendiği sistemlerde yer alan kişisel veriler bu Politika kapsamındadır.</p>
<table width="633">
<tbody>
<tr>
<td width="633">
<h1>2. TANIMLAR</h1>
</td>
</tr>
</tbody>
</table>
<p><strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir ger&ccedil;ek kişiye ilişkin her t&uuml;rl&uuml; bilgiyi,</p>
<p><strong>&Ouml;zel Nitelikli Kişisel Veri:</strong> Kişilerin ırkı, etnik k&ouml;keni, siyasi d&uuml;ş&uuml;ncesi, felsefi inancı, dini, mezhebi veya diğer inan&ccedil;ları, kılık ve kıyafeti, dernek, vakıf ya da sendika &uuml;yeliği, sağlığı, cinsel hayatı, ceza mahk&ucirc;miyeti ve g&uuml;venlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri, &ouml;zel nitelikli kişisel veridir.</p>
<p><strong>Kişisel Verilerin İşlenmesi:</strong> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin par&ccedil;ası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden d&uuml;zenlenmesi, a&ccedil;ıklanması, aktarılması, devralınması, elde edilebilir h&acirc;le getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler &uuml;zerinde ger&ccedil;ekleştirilen her t&uuml;rl&uuml; işlemi,</p>
<p><strong>Kurul:</strong> Kişisel Verileri Koruma Kurulu&rsquo;nu,</p>
<p><strong>Kurum:</strong> Kişisel Verileri Koruma Kurumu&rsquo;nu,</p>
<p><strong>Veri Sorumlusu: </strong>Kişisel verilerin işleme ama&ccedil;larını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve y&ouml;netilmesinden sorumlu olan ger&ccedil;ek veya t&uuml;zel kişiyi,</p>
<p><strong>Kişisel Verileri Koruma Komitesi: </strong>Veri Sorumlusunun KVKK ve ilgili Y&ouml;netmelikler, Kişisel Verileri Koruma Kurulu&rsquo;nun kararları ve d&uuml;zenlemeleri ve sair yasal d&uuml;zenlemeler kapsamındaki y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine getiren, Veri Sorumlusu adına gerekli işlemleri y&uuml;r&uuml;ten, Veri Sorumlusunun Veri Sorumluları Siciline kaydını ger&ccedil;ekleştiren, Kişisel Verileri Koruma Kurulu&rsquo;nun d&uuml;zenleyici işlemleri ile kararları, mahkeme kararları, teknik altyapıdaki değişiklikler, mevzuatta meydana gelebilecek değişiklikler gibi durumları takip eden Şirket i&ccedil;inde oluşturulan Komite&rsquo;yi,</p>
<p><strong>İrtibat Kişisi: </strong>Kişisel Verileri Koruma Kurumu ile Veri Sorumlusu arasındaki iletişimin sağlanması ve y&ouml;netilmesi, s&uuml;re&ccedil;lerin takibi ve diğer işlemlerinin y&uuml;r&uuml;t&uuml;lmesinden sorumlu personeli,</p>
<p><strong>İlgili Kullanıcı: </strong>Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da birim hari&ccedil; olmak &uuml;zere, Veri Sorumlusu organizasyonu i&ccedil;erisinde kişisel verileri işleyen personeli,</p>
<p><strong>İlgili Kişi: </strong>Kişisel verisi işlenen ger&ccedil;ek kişiyi,</p>
<p><strong>Veri Kayıt Sistemi: </strong>Kişisel verilerin belirli kriterlere g&ouml;re yapılandırılarak işlendiği kayıt sistemini,</p>
<p><strong>Kayıt Ortamı: </strong>Tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin par&ccedil;ası olmak kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin bulunduğu her t&uuml;rl&uuml; ortamı,</p>
<p><strong>Kişisel Veri İşleme Envanteri: </strong>Şirket&rsquo;in iş s&uuml;re&ccedil;lerine bağlı olarak ger&ccedil;ekleştirmekte olduğu kişisel verileri işleme faaliyetlerini; kişisel verileri işleme ama&ccedil;ları, veri kategorisi, aktarılan alıcı grubu ve veri konusu kişi grubuyla ilişkilendirerek oluşturduğu ve detaylandırdığı envanteri,</p>
<p><strong>İmha: </strong>Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesini,</p>
<p><strong>Kişisel Verilerin Silinmesi: </strong>Tamamen veya kısmen otomatik yollarla işlenen kişisel verilerin ilgili kullanıcılar tarafından hi&ccedil;bir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi işlemini,</p>
<p><strong>Yok Etme: </strong>Bilgilerin saklandığı veri saklamaya elverişli t&uuml;m kayıt ortamlarının tekrar geri getirilemeyecek ve kullanılamayacak hale getirilmesini,</p>
<p><strong>Anonim Hale Getirme: </strong>Kişisel verilerin başka verilerle eşleştirilse dahi kimliği belirli veya belirlenebilir bir ger&ccedil;ek kişiyle ilişkilendirilemeyecek hale getirilmesini,</p>
<p><strong>Periyodik İmha: </strong>Kanunda yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması durumunda işbu Politika&rsquo;da belirtilen ve tekrar eden aralıklarla resen ger&ccedil;ekleştirilecek silme, yok etme veya anonim hale getirme işlemini,</p>
<p>ifade eder.</p>
<p>Bu Politikada, aksi belirtilmedik&ccedil;e kişisel veri ve &ouml;zel nitelikli kişisel veri &ldquo;Kişisel Veri&rdquo; olarak kullanılmaktadır.</p>
<table width="633">
<tbody>
<tr>
<td width="633">
<h1>3. GENEL KAPSAM VE YASAL DAYANAK</h1>
</td>
</tr>
</tbody>
</table>
<h2>3.1. Kişisel Verilerin Saklanmasını Gerektiren Hukuki, Teknik ya da Diğer Sebepler</h2>
<p>6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca, Şirket &ldquo;Veri Sorumlusu&rdquo; sıfatıyla;</p>
<ul>
<li>Şirket&rsquo;in faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi amacı ile Şirket&rsquo;in işlemleri neticesinde doğrudan veya dolaylı olarak edinilen kişisel veriler, aşağıda a&ccedil;ıklandığı &ccedil;er&ccedil;evede; kaydedilebilecek, saklanabilecek, g&uuml;ncellenebilecek veya mevzuatın izin verdiği durumlarda 3. kişilere a&ccedil;ıklanabilecek, devredilebilecek, sınıflandırılabilecek ve KVKK&rsquo;da sayılan şekillerde işlenebilecektir.</li>
</ul>
<p>3.1.2. Şirket tarafından, m&uuml;şterileri, &ccedil;alışanları, &ccedil;alışan adayları, iş ortakları ve tedarik&ccedil;ileri gibi taraflardan, kimlik bilgisi, iletişim bilgisi, m&uuml;şteri bilgisi, m&uuml;şteri işlem bilgisi, işlem g&uuml;venliği bilgisi, hukuki işlem ve uyum bilgisi gibi kategorilerde kişisel veri toplanabilmektedir.</p>
<p>Toplanan kişisel veriler;</p>
<p>- Aydınlatma metninde belirtilen işleme ama&ccedil;larının ger&ccedil;ekleştirilmesi ve hizmetlerin m&uuml;şterilere sunulabilmesi, m&uuml;şterilere karşı olan y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi, kayıt ve belgelerin d&uuml;zenlenebilmesi, yerel ve uluslararası yasal mevzuatın &ouml;ng&ouml;rd&uuml;ğ&uuml; bilgi saklama, raporlama, bilgilendirme, denetim ve sair y&uuml;k&uuml;ml&uuml;l&uuml;klere uymak,</p>
<p>- Bilgi işlem gereksinimleri, sistem altyapısı, alınan bilgi işlem destek hizmetlerinin gerekliliği, bu hizmet ve &uuml;r&uuml;nlere ilişkin olarak veri sahiplerine gerekli bilgilerin aktarılması amacıyla iletişim kurmak,</p>
<p>- M&uuml;şteri memnuniyetinin &ouml;l&ccedil;&uuml;mlenmesi ve artırılması, şik&acirc;yet y&ouml;netimi, hizmetler ile ilgili g&ouml;r&uuml;ş ve &ouml;nerilerinizi almak, sorun-hata bildirimlerini almak, &uuml;r&uuml;n ve hizmetlere, şikayet ve taleplere y&ouml;nelik bilgi vermek,</p>
<p>- &Ouml;deme işlemlerini ger&ccedil;ekleştirmek, 3. kişiler ile lojistik iş birliği sağlayarak bilgi yazılarının ulaşmasını sağlamak,</p>
<p>- Resm&icirc; kurumlarca &ouml;ng&ouml;r&uuml;len bilgi saklama, raporlama, bilgilendirme, denetim y&uuml;k&uuml;ml&uuml;l&uuml;klerine uymak, s&ouml;zleşmelerin gerekliliklerini yerine getirmek ve bu hizmetlerden faydalanılmasına ilişkin olarak Şirket&rsquo;in tabi olduğu yasal y&uuml;k&uuml;ml&uuml;l&uuml;kleri ifa etmek,</p>
<p>- Şirket&rsquo;in stratejilerinin belirlenmesi ve uygulanması amacı doğrultusunda; Şirket tarafından y&uuml;r&uuml;t&uuml;len finans operasyonları, sağlık hizmetleri finansmanı, planlaması ve sağlık hizmetlerinin verilmesi, iletişim, satın alma operasyonları (talep, teklif, değerlendirme, sipariş, b&uuml;t&ccedil;eleme, s&ouml;zleşme), şirket i&ccedil;i sistem ve uygulama y&ouml;netimi operasyonları, hukuki operasyonları y&ouml;netmek</p>
<p>- Resmi makamlardan veya veri sahiplerinden gelen talepleri incelemek, değerlendirmek ve yanıtlamak,</p>
<p>ama&ccedil;larıyla 6698 sayılı Kanun&rsquo;un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve ama&ccedil;ları dahilinde işlenecektir.</p>
<h2>3.2. Kişisel Verilerin Hukuka Uygun Olarak İmha Edilmesi i&ccedil;in Alınmış Teknik ve İdari Tedbirler, Kişisel Verilerin İşleme Şartlarının Ortadan Kalkması Durumunda Yapılacaklar</h2>
<ul>
<li>Kişisel Verileri Koruma Komitesi , Kişisel Veri İşleme Envanteri&rsquo;nde yer alan kişisel verilerin işlenmesi ile ilgili şartların ortadan kalkıp kalkmadığını altışar aylık periyodlar halinde veri ortamlarında g&ouml;zden ge&ccedil;irir. Kurulun veya bir mahkemenin bildirimi &uuml;zerine, periyodik denetleme s&uuml;resine bakılmaksızın Kişisel Verileri Koruma Komitesi tarafından kararların/bildirimlerin gereği yapılır.</li>
</ul>
<ul>
<li>Kişisel Verileri Koruma Komitesi tarafından g&ouml;zden ge&ccedil;irmeler neticesinde veri işleme şartlarının ortadan kalktığı tespit edilen kişisel verilerle ilgili olarak, işbu politikaya g&ouml;re verinin saklanmasına veya silme, yok etme veya anonim hale getirilmesine karar verilir.</li>
</ul>
<ul>
<li>Kişisel Veri Koruma Komitesi, Kurum veya mahkeme tarafından iletilen m&uuml;zekkere veya kararları derhal uygular ya da bu m&uuml;zekkere veya kararlarla ilgili itiraz, temyiz gibi yasal yollara başvurulmasını değerlendirir ve sonucuna g&ouml;re hareket eder.</li>
</ul>
<ul>
<li>Kişisel Verilerin işlenmesi ile ilgili Kanunun 5. ve 6. maddesi ile d&uuml;zenlemiş olan şartların ortadan kalkmış olmasına rağmen, a&ccedil;ılan bir davada verilen bir ihtiyati tedbir kararı veya sair bir karar ile bu t&uuml;r bir karar olmamasına rağmen a&ccedil;ılmış bir davanın olması ve bu davanın Kurumsal M&uuml;şteriye / Şirketimize bildirilmiş olması halinde delillerin yok olmasını &ouml;nlemek amacıyla Kişisel Verileri Koruma Komitesi kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi işlemini yargılama sonuna kadar durdurur.</li>
</ul>
<ul>
<li>Kişisel verilerin silinmesi işlemi, diğer verilere de sistem i&ccedil;erisinde erişilememe ve bu verileri kullanamama sonucunu doğuracak ise; kişisel verilerin ilgili kişiyle ilişkilendirilemeyecek duruma getirilerek arşivlenmesini, başka herhangi bir kurum, kuruluş ve/veya kişinin erişimine kapalı olmasını, kişisel verilere yalnızca erişimin gerektirdiği &ouml;l&ccedil;&uuml;de Kişisel Verileri Koruma Komitesi ve kişisel verilerin saklanmasından sorumlu olan teknik personel tarafından erişilmesini sağlayacak şekilde gerekli teknik ve idari tedbirler alınır ve bu suretle kişisel verilerin silinmesi işlemi yerine getirilir.</li>
</ul>
<ul>
<li>Herhangi bir veri kayıt sisteminin par&ccedil;asını teşkil eden ve otomatik olmayan yollarla işlenen kişisel verilerin silinmesi; Kişisel Verileri Koruma Komitesi ve verinin saklanmasından sorumlu teknik personel dışındaki diğer kullanıcıların erişimine kapatılarak yapılır. Bu işlemlerin yapılması i&ccedil;in Şirket tarafından bu fonksiyonları yerine getirecek olan yazılımlar ve diğer teknik ara&ccedil; ve gere&ccedil;ler kullanılır.</li>
</ul>
<ul>
<li>Kişisel verilerin imha edilmesi ile ilgili bir talep, Kurul&rsquo;un kararı veya bir mahkeme kararı olması durumunda; Kişisel Verileri Koruma Komitesi kişisel veriyi saklandığı fiziksel veya elektronik kayıt ortamlarından kişisel veri işleme şartlarının ortadan kalkması/zamanaşımı s&uuml;resinin dolmasından sonra ilgili kullanıcıların erişimine (kişisel verilerin silinmesi) kapatır.</li>
</ul>
<ul>
<li>Silinen, yok edilen veya anonim hale getirilen verilerle ilgili olarak kayıtlar tutulur ve bu kayıtlar &uuml;&ccedil; yıl boyunca saklanır.</li>
</ul>
<ul>
<li>İlgili kişilerin kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi talepleri, kişisel verileri işleme şartlarının tamamen ortadan kalkmış olması şartıyla, Kurumsal M&uuml;şteriler / Şirketimiz tarafından talebin alınmasını m&uuml;teakip 30 g&uuml;n i&ccedil;inde ger&ccedil;ekleştirilir. Kişisel verileri işleme şartları ortadan kalkmamışsa veya aksine mevzuat, Kurul kararı veya mahkeme kararı yoksa, kişisel verinin silinmesi, yok edilmesi veya anonim hale getirilmesi talebi reddedilebilir. Red kararı gerek&ccedil;esiyle birlikte, İrtibat Kişisi tarafından talep tarihinden itibaren 30 g&uuml;n i&ccedil;inde yazılı olarak veya elektronik ortamda ilgili kişiye bildirilir.</li>
</ul>
<h2>3.3. Kişisel Verilerin G&uuml;venli Bir Şekilde Saklanması ile Hukuka Aykırı Olarak İşlenmesinin ve Kişisel Verilere Erişilmesinin &Ouml;nlenmesi i&ccedil;in Alınmış Olan İdari ve Teknik Tedbirler</h2>
<ul>
<li>Kişisel verilerin g&uuml;venli bir şekilde saklanması ve bu verilere g&uuml;venli bir şekilde erişilmesi konusunda alınmış idari ve teknik tedbirler kapsamında; bilgi g&uuml;venliği politikası, idari metinler ve politika, prosed&uuml;r ve işlem talimatlarıyla kişisel veriler g&uuml;vence altına alınır.</li>
</ul>
<ul>
<li>Bu Politika t&uuml;m &ccedil;alışanlara duyurulur ve d&uuml;zenli bir şekilde duyurulmaya devam edilir. Ayrıca, KVKK&rsquo;ya uyum &ccedil;alışmaları kapsamında t&uuml;m Şirket personeli bilgilendirilir, gerekli eğitimler verilir ve &ouml;nemli gelişmeler t&uuml;m Şirket nezdinde duyurulur. Bu Politika t&uuml;m personelin erişimine a&ccedil;ık olan Şirket dijital arşiv alanlarında</li>
</ul>
<ul>
<li>Politikanın gereklerinin yerine getirilip getirilmediğinin takibi Kişisel Verileri Koruma Komitesi tarafından yapılır. Politika&rsquo;ya aykırı davranış tespit edildiğinde konu derhal ilgili &ccedil;alışanın Y&ouml;neticisine bildirilir ve aykırılığın giderilmesi i&ccedil;in ilgili Y&ouml;neticisi gerekli tedbirleri alır. Politika&rsquo;ya aykırı davranan &ccedil;alışan hakkında yapılacak işlem i&ccedil;in Şirket i&ccedil;erisinde uygun g&ouml;r&uuml;lecek disiplin işlemi uygulanabileceği gibi ihlalin ağırlığına g&ouml;re iş akdinin ge&ccedil;erli veya haklı nedenle feshi de ger&ccedil;ekleştirilebilir.</li>
</ul>
<ul>
<li>Politika gereklerinin yerine getirilmesi i&ccedil;in Şirket tarafından gerekli yazılımlar/ sistemler/uygulamalar devreye alınır ve mevzuattaki değişiklikler, Şirket&rsquo;e tebliğ edilen Kurul veya mahkeme kararları nedeniyle oluşabilecek değişiklikler İrtibat Kişisi tarafından takip edilir. Gerekli değişiklikler, değişikliğin meydana gelmesinden itibaren en hızlı şekilde ger&ccedil;ekleştirilir.</li>
</ul>
<h2>3.4. Kişisel Verileri Saklama ve İmha S&uuml;re&ccedil;lerinde Yer Alanların G&ouml;rev ve Sorumlulukları</h2>
<h2>3.4.1. Kişisel Verileri Koruma Komitesi:</h2>
<p>Kişisel Verileri Koruma Komitesi aşağıda yer verilen g&ouml;revleri yerine getirmekle sorumludur:</p>
<ul>
<li>Bu Politika&rsquo;nın uygulanmasının temin ve takip edilmesi,</li>
</ul>
<ul>
<li>Mevzuatta meydana gelebilecek değişiklikleri, Kurul&rsquo;un d&uuml;zenleyici işlemleri ile kararları, mahkeme kararları, teknik altyapıdaki değişiklikler gibi durumların takip edilmesi,</li>
</ul>
<ul>
<li>Veri saklama ve imha ile ilgili olarak yapılan iş programlarının takip edilmesi, g&ouml;zden ge&ccedil;irilmesi ve iş programları &ccedil;er&ccedil;evesinde yapılanların bu Politika ile uyumlu olup olmadığının denetlenmesi, uyumsuzlukların tespit edilmesi durumunda bunların bu Politika ile uyumlu hale getirilmesinin sağlanması,</li>
</ul>
<ul>
<li>Kurum tarafından yapılan tebligat veya yazışmaların veri sorumlusu adına tebell&uuml;ğ veya kabul edilmesi,</li>
</ul>
<ul>
<li>Kurum tarafından veri sorumlusuna y&ouml;neltilen taleplerin veri sorumlusu adına alınması ve cevaplarının Kurum&rsquo;a iletilmesi,</li>
</ul>
<ul>
<li>Kurul tarafından başkaca bir esasın belirlenmemiş olması halinde; ilgili kişilerin Kanun&rsquo;un 13&rsquo;&uuml;nc&uuml; maddesinin birinci fıkrası uyarınca veri sorumlusuna y&ouml;nelteceği başvuruların veri sorumlusu adına alınması,</li>
</ul>
<ul>
<li>Kurul tarafından başkaca bir esasın belirlenmemiş olması halinde; ilgili kişilere Kanunun 13&rsquo;&uuml;nc&uuml; maddesinin &uuml;&ccedil;&uuml;nc&uuml; fıkrası uyarınca veri sorumlusunun cevaplarının iletilmesi,</li>
</ul>
<ul>
<li>Veri sorumlusu adına Sicile ilişkin iş ve işlemlerin yapılması,</li>
</ul>
<ul>
<li>Veri sorumlusu adına sicile kayıt sırasında irtibat kişisi bilgilerinin sicile işlenmesi,</li>
</ul>
<ul>
<li>İşbu Politika&rsquo;da yer verilen &ccedil;eşitli g&ouml;revleri yerine getirilmesi.</li>
</ul>
<h2>3.4.2. İrtibat Kişisi</h2>
<p>İrtibat kişisi, ilgili kişilerin veri sorumlusuna y&ouml;nelteceği taleplerin cevaplandırılması konusunda iletişimi sağlar. KVKK kapsamındaki yetkileri ve sorumlulukları aşağıda yer almaktadır:</p>
<ul>
<li>Kurul tarafından başkaca bir esas belirlenmediği koşulda; ilgili kişilerin Veri Sorumlusuna y&ouml;nelteceği başvuruları Veri Sorumlusu adına alma, Kişisel Verileri Koruma Komitesi&rsquo;ne iletmek,</li>
</ul>
<ul>
<li>Kurul tarafından başkaca bir esas belirlenmediği koşulda; ilgili kişilerin yaptığı başvurulara ilişkin Veri Sorumlusunun cevabını ilgi kişilere iletmek.</li>
</ul>
<table width="624">
<tbody>
<tr>
<td width="624">
<h1>4. KİŞİSEL VERİLERİ SAKLAMA VE İMHA S&Uuml;RELERİ</h1>
</td>
</tr>
</tbody>
</table>
<p>Şirket, Ticaret Kanunu ve d&uuml;zenlemeleri &ccedil;er&ccedil;evesinde denetimlerde istenecek her t&uuml;rl&uuml; bilgi ve belgeyi vermek, defter ve belgeleri ibraz etmek ve incelemeye hazır tutmak zorunda olup, Şirket işlemleri ile ilgili t&uuml;m bilgi ve belgeyi 10 yıl boyunca tutmak zorundadır.</p>
<p>Bununla birlikte, 6098 sayılı Bor&ccedil;lar Kanunu 146. maddeye istinaden alacakların 10 yıllık zamanaşımına tabi olmasındanolması ve 6102 sayılı T&uuml;rk Ticaret Kanunu 82. maddeye istinaden belgelerin 10 yıl saklama zorunluluğunun bulunmasından dolayı, Veri Sorumlusunun hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirebilmesi, meşru menfaatlerinin korunması ve ihtiya&ccedil; halinde belgelerin adli mercilere verilebilmesini m&uuml;mk&uuml;n kılmak i&ccedil;in kişisel veriler son işlem tarihinden itibaren 10 yıl s&uuml;re ile saklanır.</p>
<p>Şirket personeline ait sağlık ve g&uuml;venlik kayıtları, İş Sağlığı ve G&uuml;venliği Hizmetleri Y&ouml;netmeliği m.7/1-b h&uuml;km&uuml;ne g&ouml;re 15 yıl boyunca saklanmak durumundadır. Bununla birlikte 5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanununun ilgili maddeleri gereği, Şirket personeline ait sağlık ve g&uuml;venlik kayıtları dışındaki diğer kişisel veriler 10 yıl boyunca saklanır.</p>
<p>Destek hizmeti firmaları, kurumsal m&uuml;şteriler ve tedarik&ccedil;i firmalardan temin edilen kişisel veriler; 6098 sayılı Bor&ccedil;lar Kanunu 146. maddeye istinaden alacakların 10 yıllık zamanaşımına tabi olması ve 6102 sayılı T&uuml;rk Ticaret Kanunu 82. maddeye istinaden belgelerin 10 yıl saklama zorunluluğunun bulunmasından dolayı, Veri Sorumlusu olan Kurumsal M&uuml;şterinin / Şirketin hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine getirebilmesi, meşru menfaatlerinin korunması ve ihtiya&ccedil; halinde belgelerin adli mercilere sunulabilmesini m&uuml;mk&uuml;n kılmak i&ccedil;in son işlem tarihinden itibaren 10 yıl s&uuml;re ile saklanır.</p>
<p><strong>&nbsp;Kişisel Verilerin Saklama ve İmha S&uuml;releri Tablosu</strong></p>
<table width="616">
<tbody>
<tr>
<td width="312">
<p><strong>İlgili Kişi</strong></p>
</td>
<td width="161">
<p><strong>A&ccedil;ıklama</strong></p>
</td>
<td width="144">
<p><strong>Saklama/İmha s&uuml;resi</strong></p>
</td>
</tr>
<tr>
<td width="312">
<p>Şirket işlemlerinin doğrudan ya da dolaylı tarafları</p>
</td>
<td width="161">
<p>Kişisel Veri</p>
</td>
<td width="144">
<p>10 yıl</p>
</td>
</tr>
<tr>
<td width="312">
<p>Personel</p>
</td>
<td width="161">
<p>Kişisel Veri</p>
</td>
<td width="144">
<p>10 yıl</p>
</td>
</tr>
<tr>
<td width="312">
<p>Personel</p>
</td>
<td width="161">
<p>Sağlık ve G&uuml;venlik Kayıtları</p>
</td>
<td width="144">
<p>15 yıl</p>
</td>
</tr>
<tr>
<td width="312">
<p>Destek hizmeti sunucusu/Tedarik&ccedil;i</p>
</td>
<td width="161">
<p>Kişisel Veri</p>
</td>
<td width="144">
<p>10 yıl</p>
</td>
</tr>
</tbody>
</table>
<p>S&ouml;z konusu s&uuml;relerin bitiminden itibaren 6 aylık periyodik imha zaman aralıklarında kişisel veriler ilgili kullanıcıların erişimine kapatılarak silinir. İlgili kullanıcıların erişimine kapatılan kişisel veriler sadece erişimin gerektirdiği &ouml;l&ccedil;&uuml;de Kişisel Verileri Koruma Komitesi&rsquo;nin ve verileri saklamakla g&uuml;revli olan teknik personelin erişimine a&ccedil;ık olacak şekilde arşivlenir.</p>
<table>
<tbody>
<tr>
<td width="614">
<h1>5. Y&Uuml;R&Uuml;RL&Uuml;K</h1>
</td>
</tr>
</tbody>
</table>
<ul>
<li>Bu Politika .../.../2020 tarih ve &hellip;/... sayılı Y&ouml;netim Kurulu Kararı ile kabul edilmiştir.</li>
</ul>
<ul>
<li>Bu Politika Y&ouml;netim Kurulu tarafından kabul edildiği tarihte y&uuml;r&uuml;rl&uuml;ğe girer.</li>
</ul>
<ul>
<li>Bu Politika h&uuml;k&uuml;mlerini Kişisel Verileri Koruma Komitesi y&uuml;r&uuml;t&uuml;r.</li>
</ul>



                                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
}

export default PersonalDataPrivacyRevoke;