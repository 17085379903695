import React from 'react';

const Info = props =>
    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M7 14C8.38447 14 9.73785 13.5895 10.889 12.8203C12.0401 12.0511 12.9373 10.9579 13.4672 9.67881C13.997 8.39972 14.1356 6.99225 13.8655 5.63438C13.5954 4.27651 12.9287 3.02923 11.9497 2.05026C10.9708 1.07129 9.7235 0.404604 8.36563 0.134506C7.00776 -0.135591 5.6003 0.00303306 4.32122 0.532848C3.04213 1.06266 1.94888 1.95987 1.17971 3.11102C0.410543 4.26216 0 5.61554 0 7.00002C0 8.85654 0.737498 10.637 2.05025 11.9498C3.36301 13.2625 5.14348 14 7 14ZM7 2.62267C7.18459 2.62267 7.36505 2.67741 7.51853 2.77997C7.67202 2.88253 7.79164 3.02829 7.86229 3.19884C7.93293 3.36938 7.95141 3.55705 7.9154 3.73809C7.87939 3.91914 7.79049 4.08545 7.65996 4.21598C7.52944 4.34651 7.36313 4.4354 7.18208 4.47141C7.00103 4.50742 6.81337 4.48894 6.64283 4.4183C6.47228 4.34766 6.32652 4.22803 6.22396 4.07454C6.1214 3.92106 6.06667 3.74061 6.06667 3.55601C6.06667 3.30847 6.165 3.07108 6.34003 2.89604C6.51507 2.72101 6.75246 2.62267 7 2.62267ZM6.06667 7.00002C6.06667 6.75248 6.165 6.51508 6.34003 6.34005C6.51507 6.16501 6.75246 6.06668 7 6.06668C7.24753 6.06668 7.48493 6.16501 7.65996 6.34005C7.835 6.51508 7.93333 6.75248 7.93333 7.00002V10.7334C7.93333 10.9809 7.835 11.2183 7.65996 11.3933C7.48493 11.5684 7.24753 11.6667 7 11.6667C6.75246 11.6667 6.51507 11.5684 6.34003 11.3933C6.165 11.2183 6.06667 10.9809 6.06667 10.7334V7.00002Z" />
    </svg>


export default Info;


