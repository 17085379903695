import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setModal } from '../../store/actions/modalActions';
import { connect } from 'react-redux';
import Icon from '../elements/Icon';
import DeliveryButton from '../address-selection/DeliveryButton';
import { getMenuTop } from '../../store/actions/layoutActions';
import { getProductDepartments } from '../../store/actions/productActions';
import { setAddressSelection } from '../../store/actions/addressActions';
import AppSidebar from './AppSidebar';
import ProfileSidebar from '../profile/ProfileSidebar';
import { formatLink } from '../../utils';
import DeliveryButtonBlock from '../address-selection/DeliveryButtonBlock';

class AppHeader extends Component {
    componentDidMount() {
        const { boundGetMenuTop, boundGetProductDepartments } = this.props;

        boundGetMenuTop();
        boundGetProductDepartments();
    }

    componentDidUpdate(prevProps) {
        const prevLocationSplitted = prevProps.location.pathname.split('/');
        const nextLocationSplitted = this.props.location.pathname.split('/');
        if (
            prevLocationSplitted[1] !== nextLocationSplitted[1]
            || prevLocationSplitted[2] !== nextLocationSplitted[2]
            || (prevLocationSplitted[1] === 'siparis' && nextLocationSplitted[1] === 'siparis' && prevLocationSplitted[3] !== nextLocationSplitted[3])
        ) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    openDeliveryTypeModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('delivery-type');
    };

    showBasketModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('basket');
    };

    openProfileMenu = () => {
        const { boundSetModal } = this.props;
        boundSetModal('profile-menu');
    };

    renderDepartmentLinks = () => {
        const { productData } = this.props;

        return <nav className="app-header__nav">
            {
                productData.productDepartments.sort((a, b) =>
                    a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0).map(department =>
                    department.name !== 'Pizzalar'
                        ? <Link key={department.name} className="app-header__link"
                                to={department.name.toLowerCase() === 'gel al'
                                    ? '/musteri-destegi/harita'
                                    : formatLink(department.link)}
                        >
                            {department.name.toLowerCase() === 'gel al' ? 'Restoranlar' : department.name}
                        </Link>
                        : null
                )
            }
        </nav>;
    };

    showMobileAddressSelection = () => {
        const { location } = this.props;
        return !location.pathname.includes('/siparis/');
    };

    getAppHeaderContainerClassName = () => {
        let className = 'app-header-section';
        if (this.showMobileAddressSelection()) {
            className += ' app-header-section--with-address-header';
        }
        return className;
    };

    render() {
        const { basket, addressData, productData, user, boundSetAddressSelection } = this.props;

        return (
            <section className={this.getAppHeaderContainerClassName()}>
                <div className="app-header">
                    <AppSidebar openDeliveryModal={this.openDeliveryTypeModal}
                                address={addressData.addressSelection.address}
                                deliveryType={addressData.addressSelection.type}
                                menuTop={productData.productDepartments}
                                isAuthorizedUser={!!user.id}
                    />
                    <Link to="/" className="app-header__mobile-logo">
                        <img src="/images/menu/lc-logo-mobile.svg" alt="lc-logo-mobile.svg"/>
                    </Link>
                    <div className="app-content-container app-header__container">
                        <Link to="/" className="app-header__logo">
                            <img src="/images/menu/lc-logo.svg" alt="lc-logo.svg"/>
                        </Link>
                        {
                            productData.productDepartments && productData.productDepartments.length
                                ? this.renderDepartmentLinks()
                                : null
                        }
                        {
                            <div className="app-header__account-links">
                                <DeliveryButton className="app-header__delivery-button"
                                                openDeliveryModal={this.openDeliveryTypeModal}
                                                address={addressData.addressSelection.address}
                                                deliveryType={addressData.addressSelection.type}
                                />
                                {
                                    user.id
                                        ? <ProfileSidebar user={user} setAddressSelection={boundSetAddressSelection}/>
                                        : null
                                }
                                {
                                    user.id
                                        ? <div onClick={this.openProfileMenu}
                                               className="app-header__icon-button app-header__icon-button--mobile-hidden icon-with-label-vertical"
                                        >
                                            <Icon name="user"/>
                                            <div className="app-header__icon-button-text">Hesabım</div>
                                        </div>
                                        : <Link to="/hesap/giris"
                                              className="app-header__icon-button icon-with-label-vertical"
                                        >
                                            <Icon name="user"/>
                                            <div className="app-header__icon-button-text">Giriş Yap</div>
                                        </Link>
                                }
                                {
                                    <div className="app-header__basket" onClick={this.showBasketModal}>
                                        {
                                            basket.totalCount
                                                ? <div className="app-header__basket-fullness">{basket.totalCount}</div>
                                                : null
                                        }
                                        <div className="app-header__icon-button icon-with-label-vertical">
                                            <Icon name="menu-basket"/>
                                            <div className="app-header__icon-button-text">Sepetim</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        this.showMobileAddressSelection()
                            ? <div className="delivery-button-block-container">
                                <DeliveryButtonBlock openDeliveryModal={this.openDeliveryTypeModal}
                                                     address={addressData.addressSelection.address}
                                                     deliveryType={addressData.addressSelection.type}
                                />
                            </div>
                            : null
                    }
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => ({
    basket: store.basket.basket,
    productData: store.productData,
    addressData: store.addressData,
    user: store.user.user
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundGetMenuTop: bindActionCreators(getMenuTop, dispatch),
    boundGetProductDepartments: bindActionCreators(getProductDepartments, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch)
});

AppHeader.propTypes = {
    boundSetModal: PropTypes.func.isRequired,
    boundGetMenuTop: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    basket: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    productData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppHeader));
