import React from 'react';

const Edit = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15.3534 10.7344C15.3534 10.346 15.042 10.0312 14.6577 10.0312H7.49253C7.10832 10.0312 6.79688 10.346 6.79688 10.7344C6.79688 11.1227 7.10832 11.4375 7.49253 11.4375H14.6577C15.042 11.4375 15.3534 11.1227 15.3534 10.7344Z" fill="black"/>
        <path d="M7.49253 12.8438C7.10832 12.8438 6.79688 13.1585 6.79688 13.5469C6.79688 13.9352 7.10832 14.25 7.49253 14.25H11.8442C12.2284 14.25 12.5399 13.9352 12.5399 13.5469C12.5399 13.1585 12.2284 12.8438 11.8442 12.8438H7.49253Z" fill="black"/>
        <path d="M9.12871 19.5938H6.80214C6.03497 19.5938 5.41084 18.9629 5.41084 18.1875V5.8125C5.41084 5.03709 6.03497 4.40625 6.80214 4.40625H15.3548C16.122 4.40625 16.7461 5.03709 16.7461 5.8125V10.1367C16.7461 10.5251 17.0575 10.8398 17.4418 10.8398C17.826 10.8398 18.1374 10.5251 18.1374 10.1367V5.8125C18.1374 4.26169 16.8891 3 15.3548 3H6.80214C5.26781 3 4.01953 4.26169 4.01953 5.8125V18.1875C4.01953 19.7383 5.26781 21 6.80214 21H9.12871C9.51292 21 9.82437 20.6852 9.82437 20.2969C9.82437 19.9085 9.51292 19.5938 9.12871 19.5938Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3687 13.1794C18.555 12.357 17.231 12.3569 16.4178 13.1789L12.5986 17.0306C12.5175 17.1124 12.4576 17.2131 12.4243 17.324L11.5926 20.0916C11.5188 20.337 11.5831 20.6035 11.7602 20.787C11.893 20.9245 12.0733 20.9992 12.2582 20.9992C12.3201 20.9992 12.3826 20.9908 12.4439 20.9736L15.2517 20.1875C15.3673 20.1551 15.4726 20.0931 15.5575 20.0075L19.3687 16.1626C20.1824 15.3401 20.1824 14.0019 19.3687 13.1794ZM14.704 18.8816L13.2914 19.2771L13.7048 17.9015L16.2818 15.3026L17.2658 16.2971L14.704 18.8816ZM18.2506 15.3037L18.3854 15.1678C18.6561 14.8941 18.6561 14.448 18.3849 14.1738C18.1137 13.8997 17.6723 13.8997 17.4011 14.1738L17.2668 14.3093L18.2506 15.3037Z" fill="black"/>
        <path d="M14.6577 7.21875H7.49253C7.10832 7.21875 6.79688 7.53354 6.79688 7.92188C6.79688 8.31021 7.10832 8.625 7.49253 8.625H14.6577C15.042 8.625 15.3534 8.31021 15.3534 7.92188C15.3534 7.53354 15.042 7.21875 14.6577 7.21875Z" fill="black"/>
    </svg>;

export default Edit;
