import React from 'react';
import CorporateMenu from '../../corporate/corporate-menu/CorporateMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const QualityPolicy = () => {
    sendPageView('/kurumsal/kalite-ve-gida-guvenligi-politikasi', 'kalite ve gıda güvenliği politikasi - Little Caesars');
   
   return <section class="institutional-structures">
            <div class="container">
                {/* <app-breadcrumbs></app-breadcrumbs> */}
                <div class="institutional-structures-detail">
                    <div class="institutional-structures-detail-content clearfix">

                        <CorporateMenu menuType='quality' />

                        <div class="col-md-9">
                            <div class="corpareta-detail quality-policy clearfix">
                                <h1>Kalite ve Gıda Güvenliği Politikası </h1>

                                <img src="/images/kurumsal/kalite/kalite.jpg" alt="Kalite" />

                                <h5>Çelebi Hizmet Gıda İşletmeleri Turizm San. ve Tic. A.Ş - Little Caesars Pizza Türkiye olarak pizza sektöründe en yeni ve modern teknolojiler ile üretim gerçekleştirirken;</h5>

                                <p>- 	Tüketicilerimizin ihtiyaç ve beklentilerini karşılayacak, standartlara uygun kaliteli ve güvenli ürünler sağlamayı, ISO 9001:2015 Kalite Yönetim Sistemi ve Gıda Güvenliği Yönetim Sistemleri politikalarında yer alan hedef ve yönetim taahhütlerine tüm çalışanların katılımı ile ulaşmayı;</p>

                                <p>- 		Faaliyetlerde yaşanabilecek standartlardan sapmayı öngörerek önlemeyi; temel ve destekleyici iş faaliyetlerinin en az kesinti ile devam etmesini sağlamayı, hatalara zamanında ve sistemli müdahale etmek suretiyle, bir daha tekrar etmemesi için her kademede düzeltici ve önleyici faaliyetler yapılmasını;</p>

                                <p>- 		Kalite yönetim sisteminin şartlarına uyumlu bir şekilde, sürekli iyileştirme yaparak müşteri memnuniyetini sağlamayı, ürün kalitesini sürekli geliştirmeyi verimli ve rekabetçi bir çalışma ortamı yaratmak için sürekli eğitimi desteklemeyi,</p>

                                <p>- 		Çalışan sağlığı ve iş güvenliği tehlikeleri ile ilgili yükümlü olduğumuz yasal mevzuata ve diğer şartlara üst düzeyde uyarak; emniyetli bir iş ortamı sağlamayı, sürekli iyileştirme ile riskleri azaltarak çalışanlarımızın sağlığını korumayı, gelişimci bir anlayış ile tüm çalışanlar ve ziyaretçiler için sağlıklı, güvenli bir iş ortamı sağlamayı,</p>

                                <p>- 		Bilgilerin gizlilik, bütünlük ve erişilebilirlik unsurları açısından korunmasını, bu sistemin gereklerini yerine getirecek şekilde belgelemeyi, riskleri sistematik bir şekilde yönetmeyi, tüm yasal mevzuata ve sözleşmelere uymayı, bilgi güvenliği farkındalığı sağlamayı ve bu sayede güvenlik olaylarından doğabilecek her türlü gelir ve fırsat kaybını en aza indirerek kurumun itibarını ve güvenilirliğini korumayı,</p>

                                <p>- 		Doğal kaynak tüketimini azaltıcı önlemler almayı, kirlenmenin önlenmesi ve sınırlandırılması ilkesi ile çevreyi koruyacak tedbirleri tespit ederek uygulamayı,</p>

                                <p>- 		Yönetim sistemlerimizin ve bu sistemleri oluşturan hedef ve programların düzenli incelemelerle etkinliğinin değerlendirilmesini ve sürekli iyileştirme için sürdürülebilirlik ilkesi doğrultusunda gereken çalışmaları yapmayı taahhüt ederiz.</p>


                                <div class="item">
                                    <h5>Hijyen Politikası</h5>
                                    <p>Müşterilerimize sunduğumuz ürünlerin her aşamasında fiziksel, kimyasal ve biyolojik gıda güvenliği tehlikelerini belirlemek, kontrol altına almak, sağlık ve hijyen kurallarına uygun olarak üretilmesini ve kullanımını sağlamayı görev biliriz.</p>
                                </div>

                                <div class="item">
                                    <h5>Fiziksel Görünüş ve Takı Politikası</h5>
                                    <p>Restoran alanlarımıza koruyucu önlüksüz, şapkasız (bonesiz) girilemez. Üretim alanlarında çalışırken taşsız alyansın dışında hiçbir takı kullanılamaz. Önlükler temiz, şapkalar (boneler) kulak ve saçları tamamen kapatacak şekilde kullanılır.</p>
                                </div>

                                <div class="item">
                                    <h5>Tütün Kullanımı ve Yemek Politikası</h5>
                                    <p>Soyunma odalarımızda, üretim ve depolama alanlarında tüm tütün mamullerinin ve gıdaların tüketimi yasaktır. Uyarı levhaları görülebilecek yerlere asılmıştır. Çalışanların ve ziyaretçilerin bu kurala uymaları sağlanır.</p>
                                </div>

                                <div class="item">
                                    <h5>Cam, Kesici Alet ve Yabancı Madde Politikası</h5>
                                    <p>Merkez depo ve restoran alanlarımızda, fiziksel bulaşmalara sebep olabilecek yabancı maddelerin önlenmesi için; cam, ahşap ve tel malzemeler kullanılmamaktadır. Mevcut olan kırılabilir plastik malzemeler, metal ekipmanlar koruma altına alınmıştır.</p>
                                </div>

                                <div class="item">
                                    <h5>Personelin Sağlık Kontrolleri Politikası</h5>
                                    <p>Bir çalışanımız bulaşıcı hastalık, üst solunum yolları enfeksiyonu, ishal, bulantı, öksürme, hapşırma gibi rahatsızlık belirtileri gösterirse müdürü tarafından çalışma alanından uzaklaştırılarak, sağlık kontrolünün yapılması sağlanarak gerekli tetkik ve tedavi süreci başlatılır.</p>
                                </div>

                                <div class="item">
                                    <span>Kamuoyuna saygıyla duyurulur, <br />
                                    Çelebi Hizmet Gıda İşletmeleri Turizm San. ve Tic. A. Ş.</span>
                                </div>
                                <div class="item"><span class="fr bold">KP.001 REV-01</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
};

export default QualityPolicy;