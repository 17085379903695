import React from 'react';
import PropTypes from 'prop-types';

const OrderItemDetails = ({ item }) => {
    return (
        <div className="order-item-detail">
            <span className="order-item-detail__product-name">{item.product} </span>
            {
                item.orderItems && item.orderItems.length
                    ? item.orderItems.map((optionItem, index) =>
                        <span key={optionItem.id}>
                            <span className={optionItem.quantity > 0 ? '' : 'order-item-detail__cancelled-option'}>
                                {optionItem.product}
                            </span>
                            {
                                index === item.orderItems.length - 1
                                    ? '.'
                                    : ', '
                            }
                        </span>
                    )
                    : <span>{item.detail}</span>
            }
        </div>
    );
};

OrderItemDetails.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.string.isRequired,
        detail: PropTypes.string.isRequired
    }).isRequired
};

export default OrderItemDetails;