import React from 'react';
import Icon from '../elements/Icon';

const DeliveryTypeOption = ({title, details, imageUrl, imageAlt,
                             secondImageUrl, secondImageAlt, onOptionClick}) => {
    return (
      <div className="delivery-type-modal__option" onClick={onOptionClick}>
      <div className="delivery-type-modal__option-image">
          <img src={imageUrl} alt={imageAlt} />
          {secondImageUrl ? <img src={secondImageUrl} alt={secondImageAlt} /> : null}
      </div>
      <div className="delivery-type-modal__option-info">
          <div className="delivery-type-modal__option-info-title">{title}</div>
          <div className="delivery-type-modal__option-info-details">{details}</div>
      </div>

      <div className="delivery-type-modal__option-icon">
          <Icon name="chevron-right" />
      </div>
  </div>
    );
};

export default DeliveryTypeOption;
