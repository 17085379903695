import React from 'react';

const Drink = props =>
    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M2.86136 23.0184L1.80744 8.75H13.1926L12.1386 23.0184C12.129 23.149 12.0202 23.25 11.8893 23.25H3.11068C2.97975 23.25 2.871 23.149 2.86136 23.0184Z" fill="#F37021" stroke="#F33A21" strokeWidth="1.5"/>
            <path d="M1.99997 6.25C1.30958 6.25 0.749918 6.8097 0.749966 7.50009C0.750013 8.19041 1.30964 8.75 1.99997 8.75H13.0001C13.6904 8.75 14.2501 8.19036 14.2501 7.5C14.2501 6.80964 13.6904 6.25 13.0001 6.25H1.99997Z" fill="#F8F8F8" stroke="#F33A21" strokeWidth="1.5"/>
            <path d="M7.3615 3.61128L7.3615 6.62069H6L6 2.90125L7.3615 3.61128Z" fill="#F33A21"/>
            <path d="M12.6207 1.05465L6.65646 3.9562L6 2.90125L11.9643 0L12.6207 1.05465Z" fill="#F33A21"/>
    </svg>;

export default Drink;
