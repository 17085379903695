import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const _GuestRoute = ({isAuthenticated, component: Component, ...rest}) =>  (
        <Route  {...rest}  
        render= {props => <Component {...props} /> }  />
    );

const mapStateToProps = state => ({
        isAuthenticated: !!state.user 
})

_GuestRoute.propTypes = {
    isAuthenticated: PropTypes.bool,
    component: PropTypes.func.isRequired
}

export const GuestRoute =  connect(mapStateToProps, null)(_GuestRoute);