import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const _UserRoute = ({isAuthenticated, component: Component, ...rest}) =>  (
        <Route {...rest}  render= {props => isAuthenticated? <Component {...props} />: <Redirect to="/" /> }  />
    );

const mapStateToProps = (state) => ({
        isAuthenticated: !!state.user
})

_UserRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}
export const UserRoute = connect(mapStateToProps, {})(_UserRoute);