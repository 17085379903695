import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addFavoriteOrder, deleteFavoriteOrder } from '../../../store/actions/memberActions';
import { getRestaurantDetails } from '../../../store/actions/addressActions';
import getValidator from '../../../getValidator';
import { formatMoney, formatString, renderOrderItemDetailString } from '../../../utils';
import OrderDetail from '../../order/OrderDetail';
import { addActionEvent } from '../../../googleAnalyticsHelper';
import { getOrder } from '../../../store/actions/orderActions';

class OrderDetailsModalContent extends Component {
    validator = getValidator(this);

    state = {
        myOrderClicked: true,
        orderDetailsClicked: false,
        addedToFavorite: false,
        order: null,
        restaurant: {}
    };

    getOrder = () => {
        const { orderId, orders, order } = this.props;
        if (order) {
            return order;
        }
        return orders.find(order => order.id === orderId);
    };

    componentDidMount() {
        const { orderId, boundGetOrder, boundGetRestaurantDetails } = this.props;

        if (orderId) {
            boundGetOrder(orderId).then(response => this.setState({ order: response.data.result[0] }, () => {
                const { order } = this.state;
                boundGetRestaurantDetails(order.storeId).then(res => this.setState({ restaurant: res.data.result }));
            }));
        }
    }

    getTabClassName = clicked => {
        const { myOrderClicked, orderDetailsClicked } = this.state;

        return clicked === 'my-order' ?
            myOrderClicked ? 'order-detail-modal__header-tab order-detail-modal__header-tab--active'
                : 'order-detail-modal__header-tab'
            :
            clicked === 'order-details' ?
                orderDetailsClicked ? 'order-detail-modal__header-tab order-detail-modal__header-tab--active'
                    : 'order-detail-modal__header-tab'
                :
                null;
    };

    getToggleMyOrderDetailsTabs = clicked => () => {
        const { myOrderClicked, orderDetailsClicked } = this.state;

        if (clicked === 'my-order') {
            if (!myOrderClicked && orderDetailsClicked) {
                this.setState({
                    myOrderClicked: true,
                    orderDetailsClicked: false
                });
            }
        } else if (clicked === 'order-details') {
            if (myOrderClicked && !orderDetailsClicked) {
                this.setState({
                    myOrderClicked: false,
                    orderDetailsClicked: true
                });
            }
        }
    };

    getAddToFavorite = order => () => {
        const { boundAddFavoriteOrder } = this.props;

        boundAddFavoriteOrder(order.id).then(() => {
            addActionEvent('Favorilere Ekle', order.orderItems[0].product);
            this.setState({ addedToFavorite: true });
        });
    };

    getRemoveFromFavorite = order => () => {
        const { boundDeleteFavoriteOrder } = this.props;

        boundDeleteFavoriteOrder(order.orderId).then(() => {
            this.setState({ addedToFavorite: false });
        });
    };

    getButtonClassName = () => {
        const { loading } = this.props;

        let result = 'button button--white button--smaller order-detail-modal__item-product-bottom-button';
        if (loading) {
            result += ' button--disabled';
        }
        return result;
    };

    render() {
        const { children } = this.props;
        const { order, restaurant, myOrderClicked, orderDetailsClicked, addedToFavorite } = this.state;
        // const order = this.state.order && orderDetailsClicked ? this.state.order : this.getOrder();
        // const restaurant = order ? this.getRestaurant(order) : '';
        if (!order) {
            return null;
        } else
            return <div className="modal-content order-detail-modal">
                {children}
                <div className="order-detail-modal__top-block">
                    <div className="profile__block-header">Sipariş Detayı</div>
                    <div className="order-detail-modal__header-tab-container">
                        <div className={this.getTabClassName('my-order')}
                             onClick={this.getToggleMyOrderDetailsTabs('my-order')}
                        >
                            siparişlerim
                        </div>
                        <div className={this.getTabClassName('order-details')}
                             onClick={this.getToggleMyOrderDetailsTabs('order-details')}
                        >
                            sipariş bilgileri
                        </div>
                    </div>
                </div>
                <div className="order-detail-modal__order-preview">
                    <div className="order-preview__body">
                        {
                            myOrderClicked
                                ? <div className="profile-order-tracking__items-container">
                                    {
                                        order.orderItems.map(orderItem => {
                                            return <div className="order-detail-modal__item" key={orderItem.id}>
                                                <div className="order-detail-modal__item-left">
                                                    <div className="order-detail-modal__item-name">
                                                        {orderItem.product}
                                                    </div>
                                                    <div className="order-detail-modal__product-detail">
                                                        {renderOrderItemDetailString(orderItem, false)}
                                                    </div>
                                                </div>
                                                <div className="order-detail-modal__item-right">
                                                    <div className="order-detail-modal__item-product-quantity">
                                                        {orderItem.quantity}
                                                    </div>
                                                    <div className="order-detail-modal__item-product-price">
                                                        {formatMoney(orderItem.amount)} TL
                                                    </div>
                                                </div>
                                            </div>;
                                        })
                                    }

                                    <div className="order-detail-modal__item-product-bottom-row">
                                        {
                                            !addedToFavorite
                                                ? <div className={this.getButtonClassName()}
                                                       onClick={this.getAddToFavorite(order)}
                                                >
                                                    Favorilerime Ekle +
                                                </div>
                                                : <div className={this.getButtonClassName()}
                                                       onClick={this.getRemoveFromFavorite(order)}
                                                >
                                                    Favorilerimden Sil -
                                                </div>
                                        }
                                        <div className="order-detail-modal__item-product-bottom-price">
                                            <div className="order-detail-modal__item-product-bottom-price-title">
                                                <span>Toplam Tuttar</span>
                                            </div>
                                            <div className="order-detail-modal__item-product-bottom-price-total">
                                            <span>
                                                {formatMoney(order.amount)} TL
                                          </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : orderDetailsClicked
                                ? <OrderDetail
                                    className="order-detail-modal__block order-detail-modal__detail-container"
                                    order={order} restaurant={restaurant}
                                />
                                : null
                        }
                    </div>
                </div>
            </div>;
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    orders: store.orderData.orders
});

const mapDispatchToProps = dispatch => ({
    boundAddFavoriteOrder: bindActionCreators(addFavoriteOrder, dispatch),
    boundDeleteFavoriteOrder: bindActionCreators(deleteFavoriteOrder, dispatch),
    boundGetOrder: bindActionCreators(getOrder, dispatch),
    boundGetRestaurantDetails: bindActionCreators(getRestaurantDetails, dispatch)
});

OrderDetailsModalContent.propTypes = {
    boundAddFavoriteOrder: PropTypes.func.isRequired,
    boundDeleteFavoriteOrder: PropTypes.func.isRequired,
    boundGetOrder: PropTypes.func.isRequired,
    boundGetRestaurantDetails: PropTypes.func.isRequired,
    orderId: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    orders: PropTypes.array.isRequired,
    order: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsModalContent);
