import React from 'react';
import Icon from '../elements/Icon';
import Input from '../elements/Input';
import PropTypes from 'prop-types';
import { formatMoney, selectInputText } from '../../utils';

const getAddedExtraToppings = item => {
    let addedExtraToppings = [];

    item && item.orderItems && item.orderItems.map(orderItem => {
        if (orderItem.productOption === 'Malzeme Seçimi' && orderItem.quantity >= 1) {
            let orderItemName = orderItem.quantity > 1
                ? `+ ${orderItem.product} x${orderItem.quantity}`
                : `+ ${orderItem.product}`;
            addedExtraToppings.push(orderItemName);
        }
    });

    return addedExtraToppings.join(', ');
};

const getRemovedToppings = item => {
    let removedToppings = [];

    item && item.orderItems && item.orderItems.map(orderItem => {
        if (orderItem.productOption === 'Malzeme Seçimi' && orderItem.quantity == -1) {
            removedToppings.push(orderItem.product);
        }
    });

    return removedToppings.join(', ');
};

const getCrustSelection = item => {
    let crustSelection = [];

    item && item.orderItems && item.orderItems.map(orderItem => {
        if (orderItem.productOption === 'Hamur Seçimi') {
            crustSelection.push(orderItem.product);
        }
    });

    return crustSelection.join(', ');
};

const BasketOrderItem = ({
                             orderItem, onDecrementCountClick, onIncrementCountClick, onChangeCount,
                             onDeleteOrderItemClick, onGetDeleteButtonClassName
                         }) => {
    return <div className="order-detail-wrapper">
        <div className="product-title-wrapper">
            <div className="product-header">
                {orderItem.product}
            </div>
            {
                orderItem.detail
                    ? <span> ({orderItem.detail})</span>
                    : null
            }
            {
                orderItem.orderItems
                    ? orderItem.orderItems.map(item => {
                        const addedExtraToppings = getAddedExtraToppings(item);
                        const removedToppings = getRemovedToppings(item);
                        const crustSelection = getCrustSelection(item);

                        return <div key={item.id} className="product-title">
                            <span><b>{item.product}</b></span>
                            {
                                item.orderItems && item.orderItems.length
                                    ? item.orderItems.map((orderItemDetail, index) =>
                                        <span key={orderItemDetail.id}>
                                            {index === 0 ? ' (' : ''}
                                            {
                                                removedToppings && removedToppings !== '' && index === 0
                                                    ? <span className="product-title__item--removed">{`${removedToppings}`}, </span>
                                                    : ''
                                            }
                                            {
                                                addedExtraToppings && addedExtraToppings !== '' && index === 0
                                                    ? `Ekstra "${addedExtraToppings}" , `
                                                    : ''
                                            }
                                            {
                                                crustSelection && crustSelection !== '' && index === 0
                                                    ? crustSelection
                                                    : ''
                                            }
                                            {index === item.orderItems.length - 1 ? ')' : ''}
                                        </span>
                                    )
                                    : ''
                            }
                        </div>;
                    })
                    : null
            }
        </div>
        <div className="product-price">
            {formatMoney(orderItem.amount / orderItem.quantity)} TL
        </div>
        <div className="product-counter">
            <div className="product-counter-buttons">
                {
                    orderItem.isGift === 'false'
                        ? <>
                            <div className="product-counter-button minus"
                                 onClick={onDecrementCountClick}
                            >
                                -
                            </div>
                            <div className="product-counter-count">
                                {orderItem.quantity}
                            </div>
                            <div className="product-counter-button plus"
                                 onClick={onIncrementCountClick}
                            >
                                +
                            </div>
                        </>
                        : <div className="product-counter-count">
                            {orderItem.quantity}
                        </div>
                }
            </div>
        </div>
        <div className="product-total-price">
            {formatMoney(orderItem.amount)} TL
            <div className="close-button"/>
        </div>
        <Icon name="cancel" onClick={onDeleteOrderItemClick} className={onGetDeleteButtonClassName}/>
    </div>;
};

BasketOrderItem.propTypes = {
    orderItem: PropTypes.shape({
        product: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired
    }).isRequired,
    onDecrementCountClick: PropTypes.func.isRequired,
    onIncrementCountClick: PropTypes.func.isRequired,
    onChangeCount: PropTypes.func.isRequired,
    onDeleteOrderItemClick: PropTypes.func.isRequired,
    onGetDeleteButtonClassName: PropTypes.string.isRequired
};

export default BasketOrderItem;