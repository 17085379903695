import React from 'react';
import PropTypes from "prop-types";

// TODO: optimize it
import AmericanExpress from "./icons/AmericanExpress";
import Basket from "./icons/Basket";
import Cancel from "./icons/Cancel";
import CheckEmpty from "./icons/CheckEmpty";
import CheckFilled from "./icons/CheckFilled";
import Checked from "./icons/Checked";
import ChevronDown from "./icons/ChevronDown";
import ChevronLeft from "./icons/ChevronLeft";
import ChevronRight from "./icons/ChevronRight";
import ChevronUp from "./icons/ChevronUp";
import Delivery from "./icons/Delivery";
import Facebook from "./icons/Facebook";
import Google from "./icons/Google";
import Apple from "./icons/Apple";
import Instagram from "./icons/Instagram";
import Mastercard from "./icons/Mastercard";
import Menu from "./icons/Menu";
import MyLocation from "./icons/MyLocation";
import Phone from "./icons/Phone";
import Pin from "./icons/Pin";
import Receipt from "./icons/Receipt";
import Refresh from "./icons/Refresh";
import Rewards from "./icons/Rewards";
import Search from "./icons/Search";
import Store from "./icons/Store";
import Troy from "./icons/Troy";
import Twitter from "./icons/Twitter";
import User from "./icons/User";
import Youtube from "./icons/Youtube";
import Turkey from "./icons/flags/Turkey";
import Info from "./icons/Info";
import Delete from "./icons/Delete";
import EasyOrder from "./icons/EasyOrder";
import EasyOrderAlt from "./icons/EasyOrderAlt";
import Exit from "./icons/Exit";
import Profile from "./icons/Profile";
import Globe from "./icons/Globe";
import Edit from "./icons/Edit";
import Trash from "./icons/Trash";
import Pizza from "./icons/Pizza";
import Drink from "./icons/Drink";
import Sides from "./icons/Sides";
import Dough from "./icons/Dough";
import Crust from "./icons/Crust";
import Schedule from "./icons/Schedule";
import CreditCard from "./icons/CreditCard";
import Orders from './icons/Orders';
import OrderTracking from './icons/OrderTracking';
import Favorites from './icons/Favorites';
import OrangeHeart from './icons/OrangeHeart';
import BigHeart from './icons/BigHeart';
import HorizontalLine from './icons/HorizontalLine';
import CheckFilledRound from "./icons/CheckFilledRound";
import MenuHouse from "./icons/MenuHouse";
import MenuCrust from "./icons/MenuCrust";
import MenuBasket from './icons/MenuBasket';
import MenuChat from './icons/MenuChat';
import Heart from './icons/Heart';
import CancelCircle from './icons/CancelCircle';
import Potato from './icons/Potato';
import Desert from './icons/Desert';
import Plus from './icons/Plus';
import Minus from './icons/Minus';
import PinAlt from './icons/PinAlt';
import Worst from './icons/rating/Worst';
import Bad from './icons/rating/Bad';
import Normal from './icons/rating/Normal';
import Good from './icons/rating/Good';
import Excellent from './icons/rating/Excellent';
import PizzaSlice from './icons/PizzaSlice';
import PizzaSliceInactive from './icons/PizzaSliceInactive';
import LoyaltySidebarIcon from './icons/LoyaltySidebarIcon';
import Visa from "./icons/Visa";

const Icon = props => {
    const getClassName = () => `icon${props.className ? ' ' + props.className : ''}`;

    switch (props.name) {
        case 'american-express': return <AmericanExpress {...props} className={getClassName()} />;
        case 'basket': return <Basket {...props} className={getClassName()} />;
        case 'big-heart': return <BigHeart {...props} className={getClassName()} />;
        case 'heart': return <Heart {...props} className={getClassName()} />;
        case 'cancel': return <Cancel {...props} className={getClassName()} />;
        case 'cancel-circle': return <CancelCircle {...props} className={getClassName()} />;
        case 'checked': return <Checked {...props} className={getClassName()} />;
        case 'check-empty': return <CheckEmpty {...props} className={getClassName()} />;
        case 'check-filled': return <CheckFilled {...props} className={getClassName()} />;
        case 'check-filled-round': return <CheckFilledRound {...props} className={getClassName()} />;
        case 'chevron-down': return <ChevronDown {...props} className={getClassName()} />;
        case 'chevron-left': return <ChevronLeft {...props} className={getClassName()} />;
        case 'chevron-right': return <ChevronRight {...props} className={getClassName()} />;
        case 'chevron-up': return <ChevronUp {...props} className={getClassName()} />;
        case 'credit-card': return <CreditCard {...props} className={getClassName()} />;
        case 'crust': return <Crust {...props} className={getClassName()} />;
        case 'delete': return <Delete {...props} className={getClassName()} />;
        case 'delivery': return <Delivery {...props} className={getClassName()} />;
        case 'dough': return <Dough {...props} className={getClassName()} />;
        case 'drink': return <Drink {...props} className={getClassName()} />;
        case 'easy-order': return <EasyOrder {...props} className={getClassName()} />;
        case 'easy-order-alt': return <EasyOrderAlt {...props} className={getClassName()} />;
        case 'edit': return <Edit {...props} className={getClassName()} />;
        case 'exit': return <Exit {...props} className={getClassName()} />;
        case 'facebook': return <Facebook {...props} className={getClassName()} />;
        case 'favorites': return <Favorites {...props} className={getClassName()} />;
        case 'globe': return <Globe {...props} className={getClassName()} />;
        case 'google': return <Google {...props} className={getClassName()} />;
        case 'horizontal-line': return <HorizontalLine {...props} className={getClassName()} />;
        case 'apple': return <Apple {...props} className={getClassName()} />;
        case 'info': return <Info {...props} className={getClassName()} />;
        case 'instagram': return <Instagram {...props} className={getClassName()} />;
        case 'mastercard': return <Mastercard {...props} className={getClassName()} />;
        case 'menu': return <Menu {...props} className={getClassName()} />;
        case 'my-location': return <MyLocation {...props} className={getClassName()} />;
        case 'orange-heart': return <OrangeHeart {...props} className={getClassName()} />;
        case 'orders': return <Orders {...props} className={getClassName()} />;
        case 'order-tracking': return <OrderTracking {...props} className={getClassName()} />;
        case 'phone': return <Phone {...props} className={getClassName()} />;
        case 'pin': return <Pin {...props} className={getClassName()} />;
        case 'pizza': return <Pizza {...props} className={getClassName()} />;
        case 'profile': return <Profile {...props} className={getClassName()} />;
        case 'receipt': return <Receipt {...props} className={getClassName()} />;
        case 'refresh': return <Refresh {...props} className={getClassName()} />;
        case 'rewards': return <Rewards {...props} className={getClassName()} />;
        case 'schedule': return <Schedule {...props} className={getClassName()} />;
        case 'search': return <Search {...props} className={getClassName()} />;
        case 'sides': return <Sides {...props} className={getClassName()} />;
        case 'store': return <Store {...props} className={getClassName()} />;
        case 'trash': return <Trash {...props} className={getClassName()} />;
        case 'troy': return <Troy {...props} className={getClassName()} />;
        case 'twitter': return <Twitter {...props} className={getClassName()} />;
        case 'user': return <User {...props} className={getClassName()} />;
        case 'youtube': return <Youtube {...props} className={getClassName()} />;
        case 'menu-house': return <MenuHouse {...props} className={getClassName()} />;
        case 'menu-crust': return <MenuCrust {...props} className={getClassName()} />;
        case 'menu-basket': return <MenuBasket {...props} className={getClassName()} />;
        case 'menu-chat': return <MenuChat {...props} className={getClassName()} />;
        case 'desert': return <Desert {...props} className={getClassName()} />;
        case 'potato': return <Potato {...props} className={getClassName()} />;
        case 'plus': return <Plus {...props} className={getClassName()} />;
        case 'minus': return <Minus {...props} className={getClassName()} />;
        case 'pin-alt': return <PinAlt {...props} className={getClassName()} />;
        case 'pizza-slice': return <PizzaSlice {...props} className={getClassName()} />;
        case 'pizza-slice-inactive': return <PizzaSliceInactive {...props} className={getClassName()} />;
        case 'loyalty-sidebar-icon': return <LoyaltySidebarIcon {...props} className={getClassName()} />;
        case 'visa': return <Visa {...props} className={getClassName()} />;
        
        case 'flag-turkey': return <Turkey {...props} className={getClassName()} />;

        // Iconst rating
        case 'worst': return <Worst {...props} className={getClassName()} />;
        case 'bad': return <Bad {...props} className={getClassName()} />;
        case 'normal': return <Normal {...props} className={getClassName()} />;
        case 'good': return <Good {...props} className={getClassName()} />;
        case 'excellent': return <Excellent {...props} className={getClassName()} />;

        default: return null;
    }
};

Icon.propTypes = {
    className: PropTypes.string
};

export default Icon;
