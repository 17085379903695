import React from 'react';

const Dough = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15.6818 5.00284C14.3017 3.62269 12.1744 3.43333 10.5891 4.43507C10.4786 4.45658 10.3721 4.51044 10.2863 4.5962L10.2645 4.61802C8.65897 3.41547 6.40433 3.50474 4.94529 4.96378C3.47739 6.43183 3.39576 8.70539 4.62165 10.3124C3.39576 11.9196 3.5163 14.2323 4.98435 15.7003C5.21338 15.9294 5.58417 15.9294 5.81321 15.7003L15.6818 5.83185C15.9109 5.60267 15.9109 5.23203 15.6818 5.00284Z" fill="#FAECD8"/>
        <path d="M19.36 13.7061C20.5859 12.0989 20.4653 9.78631 18.9974 8.31826C18.7682 8.08923 18.3974 8.08923 18.1684 8.31826L8.29995 18.1869C8.07092 18.4159 8.07092 18.7867 8.29995 19.0157C9.68011 20.3959 11.8073 20.5853 13.3927 19.5835C13.5032 19.5622 13.6097 19.5083 13.6955 19.4225L13.7173 19.4007C15.3228 20.6031 17.5774 20.5138 19.0365 19.0548C20.5044 17.5869 20.586 15.3133 19.36 13.7061Z" fill="#F4D7AF"/>
        <path d="M21.4838 5.00258L18.9972 7.48931C18.7651 7.72139 18.4004 7.72139 18.1684 7.48931L16.5105 5.83159C16.2784 5.59936 16.2784 5.23467 16.5105 5.00258L18.9972 2.51601C19.6852 1.828 20.7959 1.828 21.4839 2.51601C22.172 3.20388 22.1718 4.31472 21.4838 5.00258Z" fill="#FF9B00"/>
        <path d="M7.471 19.0153L5.00258 21.4837C4.31472 22.1716 3.20403 22.1716 2.51601 21.4837C1.828 20.7957 1.828 19.6848 2.51601 18.997L4.98427 16.5286C5.21636 16.2965 5.58104 16.2965 5.81313 16.5286L7.471 18.1864C7.70308 18.4185 7.70308 18.7832 7.471 19.0153Z" fill="#FF9B00"/>
        <path d="M18.1685 7.48892L17.3396 6.66006L21.484 2.51562C22.1721 3.20349 22.1721 4.31418 21.484 5.0022L18.9975 7.48892C18.7654 7.721 18.4007 7.721 18.1685 7.48892Z" fill="#FF8900"/>
        <path d="M7.47085 19.0161L5.00244 21.4845C4.31457 22.1724 3.20389 22.1724 2.51587 21.4845L6.64184 17.3584L7.47085 18.1873C7.70294 18.4193 7.70294 18.784 7.47085 19.0161Z" fill="#FF8900"/>
        <path d="M18.9973 9.14779L18.5828 9.56222H16.925L14.4382 7.0755V5.41779L14.8528 5.0032C15.5407 4.31534 16.6514 4.31534 17.3394 5.0032L18.9973 6.66107C19.6853 7.34909 19.6853 8.45978 18.9973 9.14779Z" fill="#FFB751"/>
        <path d="M9.54314 18.6015L9.12871 19.0159C8.44085 19.7039 7.33001 19.7039 6.64199 19.0159L4.98428 17.3582C4.29626 16.6702 4.29626 15.5595 4.98428 14.8715L5.3987 14.457H7.05657L9.54314 16.9438V18.6015Z" fill="#FFB751"/>
        <path d="M18.9975 9.14663L18.583 9.56106H16.9252L15.6819 8.31763L18.1686 5.83105L18.9975 6.65991C19.6855 7.34793 19.6855 8.45862 18.9975 9.14663Z" fill="#FF9B00"/>
        <path d="M9.543 16.9436V18.6013L9.12857 19.0158C8.4407 19.7038 7.32986 19.7038 6.64185 19.0158L5.81299 18.1869L8.29971 15.7002L9.543 16.9436Z" fill="#FF9B00"/>
        <path d="M5.39893 14.4566L14.4384 5.41699L18.5828 9.56143L9.54336 18.6009L5.39893 14.4566Z" fill="#FFB751"/>
        <path d="M7.4707 16.5289L16.5103 7.48926L18.5825 9.56155L9.543 18.6012L7.4707 16.5289Z" fill="#FF9B00"/>
    </svg>;

export default Dough;
