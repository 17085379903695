import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { formatErrorMessage } from '../../utils';

const DropdownList = props => {
    const defaultRef = React.createRef();

    const {
        value, options, className, containerClassName, onClick, label, labelClassName, optionClassName, onChange,
        placeholder, error, rootRef, autocomplete
    } = props;

    const handleChange = event => {
        const { value } = event.target;
        onChange(value);
    };

    const getContainerClassName = () => {
        let resultClassName = 'dropdownlist';
        if (containerClassName) {
            resultClassName += ` ${containerClassName}`;
        }
        return resultClassName;
    };

    const getClassName = () => {
        let resultClassName = 'dropdownlist__body';
        if (className) {
            resultClassName += ` ${className}`;
        }
        return resultClassName;
    };

    return (
        <div className={getContainerClassName()} ref={rootRef || defaultRef}>
            {
                label
                    ? <label className={labelClassName}>{label}</label>
                    : null
            }
            <div className="dropdownlist__block">
                <select value={value}
                        className={getClassName()}
                        onClick={onClick}
                        onChange={handleChange}
                        autoComplete={autocomplete}
                >
                    <option className={optionClassName} value="" hidden>{placeholder}</option>
                    {
                        options.map(item => (
                            <option className={optionClassName}
                                    key={item.id ? item.id : item.value}
                                    value={item.id ? item.id : item.value}
                            >
                                {item.name ? item.name : item.label}
                            </option>
                        ))
                    }
                </select>
                <div className="dropdownlist__icon-wrapper">
                    <Icon className="dropdownlist__icon" name="chevron-down"/>
                </div>
            </div>
            {
                error
                    ? <div className="help-text help-text--error">{formatErrorMessage(error)}</div>
                    : null
            }
        </div>
    );
};

DropdownList.defaultProps = {
    value: '',
    options: [],
    className: '',
    containerClassName: '',
    labelClassName: '',
    optionClassName: '',
    placeholder: ''
};

DropdownList.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    className: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    rootRef: PropTypes.object
};

export default DropdownList;
