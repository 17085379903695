import api, { handleRequest, saveCustomerPhoneId } from '../../api';
import { setLocalStorage, removeFromLocalStorage } from '../../storage';

export const SET_RESTAURANTS = 'SET_RESTAURANTS';
export const SET_ADDRESS_SELECTION = 'SET_ADDRESS_SELECTION';
export const SET_USER_DELIVERY_ADDRESSES = 'SET_USER_DELIVERY_ADDRESSES';
export const SET_IS_NEW_ADDRESS = 'SET_IS_NEW_ADDRESS';
export const SET_CHANGED_ADDRESS = 'SET_CHANGED_ADDRESS';

export const setAddressSelection = addressSelection => {
    setLocalStorage('addressSelection', JSON.stringify(addressSelection));
    return {
        type: SET_ADDRESS_SELECTION,
        payload: addressSelection
    };
};

export const deleteAddressSelection = () => {
    removeFromLocalStorage('addressSelection');
    return {
        type: SET_ADDRESS_SELECTION,
        payload: ''
    };
};

export const setRestaurants = response => ({
    type: SET_RESTAURANTS,
    payload: response
});

export const setUserDeliveryAddresses = response => ({
    type: SET_USER_DELIVERY_ADDRESSES,
    payload: response
});

export const setIsNewAddress = response => ({
    type: SET_IS_NEW_ADDRESS,
    payload: response
});

export const setChangedAddress = response => ({
    type: SET_CHANGED_ADDRESS,
    payload: response
});

export const getRestaurants = (latitude, longitude) => dispatch => {
    const query = latitude && longitude ? `?latitude=${latitude}&longitude=${longitude}` : '';
    return api.get(`web/Restaurants/GetRestaurants${query}`, dispatch)
        .then(response => handleRequest(response, () => dispatch(setRestaurants(response.data.result))));
};

export const getRestaurantDetails = restaurantId => dispatch => {
    return api.get(`web/Restaurants/Detail?storeId=${restaurantId}`, dispatch)
        .then(response => handleRequest(response));
};

export const getRestaurantIdByUserCoordinates = (coordinates, city) => dispatch => {
    const query = city ? `?cityName=${city}` : '';
    return api.post(`web/Address/GetStreetID${query}`, { coordinates }, dispatch)    // : '28.999534666986293, 41.05325520393336'
        .then(response => handleRequest(response));
};

export const changeDeliveryType = (orderId, orderType, storeId) => dispatch =>
    api.post('web/order/ChangeType', { orderId, orderType, storeId }, dispatch)
        .then(response => handleRequest(response));

export const changeDeliveryAddress = (basketId, addressId) => dispatch =>
    api.put('web/order/ChangeAddress', { Id: basketId, AddressId: addressId }, dispatch)
        .then(response => {
            if (response.data.resultCode === '404')
                return response;
            else
                handleRequest(response);
        });

export const getStreetId = (city, town, district, street, coordinates) => dispatch =>   // Pass here some bool param
    api.post(`web/Address/GetStreetID?cityName=${city}&townName=${town}&quarterName=${district}&streetName=${street}`, coordinates, dispatch)
        .then(response => handleRequest(response));

export const getUserDeliveryAddresses = () => dispatch =>
    api.get('web/address/GetMemberDeliveryAddress', dispatch)
        .then(response => handleRequest(response, () => dispatch(setUserDeliveryAddresses(response.data.result))));

export const createUserDeliveryAddress = data => dispatch =>
    api.post('web/Address/AddMemberDeliveryAddress', data, dispatch)
        .then(response => handleRequest(response));

export const updateUserDeliveryAddress = data => dispatch =>
    api.put('web/Address/UpdateMemberDeliveryAddress', data, dispatch)
        .then(response => handleRequest(response));

export const deleteUserDeliveryAddress = addressId => dispatch =>
    api.delete(`web/Address/DeleteMemberDeliveryAddress?addressId=${addressId}`, dispatch)
        .then(response => handleRequest(response));

export const getAddressTypes = () => dispatch =>
    api.get('web/Address/GetAddressTypes', dispatch)
        .then(response => handleRequest(response));

export const getCities = () => dispatch =>
    api.get('web/Address/GetTowns', dispatch)
        .then(response => handleRequest(response));

export const getAllCities = () => dispatch =>
    api.get('web/Address/GetAllCities', dispatch)
        .then(response => handleRequest(response));

export const getAllCitiesSecondList = () => dispatch =>
    api.get('web/Address/GetAllCitiesSecondList', dispatch)
        .then(response => handleRequest(response));


export const getAllTownsOfCity = cityId => dispatch =>
    api.get(`web/Address/GetCityTowns?cityId=${cityId}`, dispatch)
        .then(response => handleRequest(response));

export const getTowns = cityId => dispatch =>
    api.get(`web/Address/GetSubCities?townId=${cityId}`, dispatch)
        .then(response => handleRequest(response));

export const getDistricts = townId => dispatch =>
    api.get(`web/Address/GetQuarters?subCityId=${townId}`, dispatch)
        .then(response => handleRequest(response));

export const getStreets = districtId => dispatch =>
    api.get(`web/Address/GetStreetAddresses?quarterId=${districtId}`, dispatch)
        .then(response => handleRequest(response));
