import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbs';
import CorporateMenu from '../corporate-menu/CorporateMenu';

const CorporateHistory = () => {
    return (
        <section className="institutional-structures">
            <div className="container">
                <BreadCrumbs />
                <div className="institutional-structures-detail">
                    <div className="institutional-structures-detail-content clearfix">
                        <CorporateMenu />
                        <div className="col-md-9">
                            <div className="corpareta-detail">
                                <h1>Tarihçe</h1>
                                <img className="img-responsive" src="/images/corporate/tarihce.jpg" alt="tarihce.jpg" />
                                <div className="row">
                                    <div className="col-md-4 hidden-sm hidden-xs">
                                        <img className="img-responsive" src="/images/corporate/tarihce2.jpg" alt="tarihce2.jpg" />
                                    </div>
                                    <div className="col-md-8">
                                        <h2>Little Caesars Tarihçe</h2>
                                        <p>1959 yılında Mike ve Marion Ilitch, Michigan Garden City'de ilk Little Caesars Pizza restoranını açtı. Ilitchlerin yönetiminde Little Caesars, üç uluslararası pizza zincirinden biri olma başarısını gösterdi. Beş kıtada birçok restoranı bulunan Little Caesars Pizza zincirinin işletmesinin başında halen Ilitch ailesi bulunmaktadır.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2>İsmini Nereden Alıyor?</h2>
                                        <p>Bilindiği gibi Roma İmparatoru Büyük Sezar, büyük başarılar elde ettiği için "Büyük" Sezar olarak anılırdı. Marian Ilitch, eşi Michael Ilitch'in böyle bir başarısı olmadığı için eşine (Little Caesars) "Küçük Sezar" lakabını uygun bulur. İlk açılan restoranlarına da Little Caesars ismini verirler.</p>
                                    </div>
                                    <div className="col-md-4 hidden-sm hidden-xs">
                                        <img className="img-responsive" src="/images/corporate/tarihce3.jpg" alt="tarihce3.jpg" />
                                    </div>
                                </div>
                                <h2>Little Caesars'ın Hizmet Anlayışı?</h2>
                                <p>50 yıldan uzun süredir tüm dünyada pizzada yeniliklerin öncüsü olan Little Caesars, kaliteli pizzasını uygun fiyatlarla buluşturduğu müşterilerine seçkin bir hizmet ve değer sunuyor. Çünkü Little Caesars'ın hizmet anlayışının temelinde "kalite" ve "değer" yer alıyor. Marka, ayrıca sergilediği tutkulu, eğlenceli, sadık ve aileye odaklı profilini yansıtan "Little Caesars Karakteri" ile bu ruhu gerçek hayata yansıtmıştır. Little Caesars'ın bu özellikleri, müşterilerin yıllarca hafızalarında kalan reklam ve pazarlama kampanyalarına yansıyarak ilerleme sağlamaktadır.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default CorporateHistory;