import { createReducer } from '../storeUtils';
import { SET_MODAL, SET_REDIRECT } from '../actions/modalActions';

const initialState = {
  modal: '',
  redirect: {
    type: '',
    value: ''
  }
};

function setModal(state, action) {
  return {
    ...state,
    modal: action.payload
  };
}

function setRedirect(state, action) {
  return {
    ...state,
    redirect: action.payload
  };
}

export default createReducer(initialState, {
  [SET_MODAL]: setModal,
  [SET_REDIRECT]: setRedirect
});
