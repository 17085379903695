import React, { Component } from 'react';
import ResetPasswordForm from '../../account/ResetPasswordForm';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Toast from '../../elements/Toast';
import { bindActionCreators } from 'redux';
import { resetPassword } from '../../../store/actions/authActions';

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: false,
            hashCode: '',
            messages: ''
        };
    }

    componentDidMount() {
        const { params } = this.props.match;
        this.setState({ hashCode: params['hashcode'] });
    }

    submitNewPassword = (newPassword) => {
        const { user, boundResetPassword, match } = this.props;

        boundResetPassword(user.forgotPasswordEmail, match.params.token, newPassword)
            // TODO: First param "user.forgotPasswordEmail" is seems to be redundant.
            // That's because forgotPasswordEmail is saved in redux but reset page is opened in a new tab.
            // It's always empty but resetting working fine, maybe hash is enough for backend to identify user?
            // One can remove that param and remove redux action which sets forgotPasswordEmail.
            // Or if we need this param, save it to localStorage instead of redux.
            .then(() => toast(this.renderSuccessToast()))
            .catch(() => toast(this.renderErrorToast()));
    };

    renderSuccessToast = () => <Toast text="Şifreniz değiştirilmiştir."/>;

    renderErrorToast = () => <Toast text="Bir hata oluştu, şifre yenileme postası gönderilemedi." status="error"/>;

    render() {
        return (
            <div className="app-page-container auth-page">
                <div className="app-content-container auth-page__content">
                    <ResetPasswordForm submitNewPassword={this.submitNewPassword}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    user: store.user
});

const mapDispatchToProps = dispatch => ({
    boundResetPassword: bindActionCreators(resetPassword, dispatch)
});

ResetPasswordPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    user: PropTypes.object.isRequired,
    boundResetPassword: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
