import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser, login, loginWithFacebook } from '../../../store/actions/authActions';
import { setModal } from '../../../store/actions/modalActions';
import LoginForm from '../../account/LoginForm';
import { bindActionCreators } from 'redux';
import { getBasket } from '../../../store/actions/basketActions';
import { setLocalStorage } from '../../../storage';
import {
    changeDeliveryAddress, getUserDeliveryAddresses, setAddressSelection
} from '../../../store/actions/addressActions';
import { addLoginAction, sendPageView } from '../../../googleAnalyticsHelper';
import CryptoJS from 'crypto-js';
import { hashKey } from '../../../utils';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            returnUrl: '',
            facebookLoginToken: ''
        };
    }

    componentDidMount() {
        const { location } = this.props;

        sendPageView(location.pathname, 'Login Sayfa');
    }

    postLogin = (email, password, rememberMe) => {
        const {
            boundLogin, boundGetBasket, boundSetAddressSelection, basket, boundGetUserDeliveryAddresses, history,
            addressData, boundGetUser, boundSetModal
        } = this.props;

        boundLogin(email, password).then(() => {
            if (rememberMe) {
                setLocalStorage('rememberMeEmail', email);
                setLocalStorage('rememberMePassword', CryptoJS.AES.encrypt(password, hashKey).toString());
            } else {
                setLocalStorage('rememberMeEmail', '');
                setLocalStorage('rememberMePassword', '');
            }
            boundGetUser();
            if (
                addressData.addressSelection.type === 'Adrese Teslim'
                && addressData.addressSelection.isUserAddress === false
            ) {
                boundGetUserDeliveryAddresses().then(response => {
                    if (response.payload && response.payload.length) {
                        this.changeDeliveryAddress(response.payload[0]).finally(boundGetBasket);
                    } else {
                        boundSetAddressSelection({});
                    }
                });
            } else {
                boundGetBasket();
            }

            //For google analytics
            this.setCurrentUser();
            addLoginAction('Gmail');

            const itemCount = basket.totalCount;
            if (itemCount && itemCount > 0) {
                boundGetUserDeliveryAddresses()
                    .then(() => {
                        if (this.props.addressData.userDeliveryAddresses.length) {
                            history.push('/sepet/sepetim');
                        } else {
                            boundSetModal('add-address');
                        }
                    });
            } else {
                setLocalStorage('joker_ShowNotification', true);
                history.push('/');
            }
        })
            .catch(error => console.log('error:', error));
    };

    changeDeliveryAddress = address => {
        const { basket, boundSetAddressSelection, boundChangeDeliveryAddress } = this.props;

        return boundChangeDeliveryAddress(basket.id, address.addressId)
            .then(() => boundSetAddressSelection({
                type: 'Adrese Teslim',
                address: address.address.name,
                addressId: address.addressId,
                isUserAddress: true
            }))
            .catch(error => {
                boundSetAddressSelection({});
            });
    };

    postLoginWithFacebook = (accesstoken, avatar) => {
        const { history, boundLoginWithFacebook, boundGetBasket } = this.props;
        const { returnUrl, facebookLoginToken } = this.state;

        const options = {
            scope: 'email',
            return_scopes: true,
            enable_profile_selector: true
        };

        // fbLogin(options).then(res => {
        //     clearAllData()
        //     const facebookLoginToken = res.authResponse.accesstoken
        //     this.setState({
        //         facebookLoginToken
        //     });

        boundLoginWithFacebook(accesstoken).then(res => {
            boundGetBasket();

            //For google analytics
            this.setCurrentUser();
            addLoginAction('Facebook');
            setLocalStorage('fbavatar', avatar);
            if (returnUrl) {
                history.push(returnUrl);
            } else {
                history.push('/');
            }
        });
    };

    setCurrentUser = () => {
        const { user } = this.props;

        if (user && user.id) {
            setLocalStorage('userId', user.personId);
            setLocalStorage('isLoggedIn', 'True');
        }
    };

    render() {

        return (
            <div className="login-form-section">
                <div className="form-auth-container">
                    <div className="app-content-container">
                        <LoginForm postLogin={this.postLogin} postLoginWithFacebook={this.postLoginWithFacebook}/>
                    </div>
                </div>
                <div className="social-media only-mobile">
                    <div className="social-media__container">
                        <div className="social-media__application-icon-header">
                            <p>Her yerden kolay sipariş için uygulamamızı indirin</p>
                        </div>
                        <div className="social-media__application-icon-body">
                            <ul>
                                <li>
                                    <a href="https://play.google.com/store/apps/details?id=tr.com.littlecaesars"
                                       rel="noopener noreferrer" target="_blank">
                                        <img className="img-responsive" src="/images/footer/google-play.svg"
                                             alt="google-play.svg"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://itunes.apple.com/tr/app/little-caesars-t%C3%BCrkiye/id904413305?l=tr&mt=8"
                                       rel="noopener noreferrer" target="_blank">
                                        <img className="img-responsive" src="/images/footer/ios-appstore.svg"
                                             alt="ios-appstore.svg"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="social-media__application-image">
                            <img src="" alt=""/>
                        </div>
                    </div>
                    <div className="social-media__phones">
                        <img src="/images/footer/mobile-bg.svg" alt="mobile-bg.svg"/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    addressData: store.addressData,
    basket: store.basket.basket,
    user: store.user.user
});

const mapDispatchToProps = (dispatch) => ({
    boundLogin: (email, password) => dispatch(login(email, password)),
    boundLoginWithFacebook: loginToken => dispatch(loginWithFacebook(loginToken)),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundGetUserDeliveryAddresses: bindActionCreators(getUserDeliveryAddresses, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundChangeDeliveryAddress: bindActionCreators(changeDeliveryAddress, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

LoginPage.propTypes = {
    boundLogin: PropTypes.func.isRequired,
    boundLoginWithFacebook: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundGetUserDeliveryAddresses: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundChangeDeliveryAddress: PropTypes.func.isRequired,
    addressData: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    boundSetModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
