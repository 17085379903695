import React, { Component } from 'react';
import PropTypes from "prop-types";
import Modal from "../elements/Modal";
import { CSSTransition } from 'react-transition-group';

class ModalWithAnimation extends Component {
    componentDidMount() {
        // document.addEventListener('keydown', this.onKeyPress);   // Fix bug with multiple actions first
    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.onKeyPress);
    }


    onKeyPress = event => {
        const { closeModal } = this.props;
        if (event.key === 'Escape') {
            closeModal();
        }
    };

    closeModal = event => {
        const { closeModal } = this.props;
        if (event.target.dataset.closeModal) {
            closeModal(event);
        }
    };

    render () {
        const { children, showModalContent, onEnter, onExited, className, withCloseIcon } = this.props;

        return <CSSTransition in={showModalContent}
                              timeout={300}
                              classNames="modal-animation-container"
                              unmountOnExit
                              onEnter={onEnter}
                              onExited={onExited}
        >
            <Modal closeModal={this.closeModal} className={className} withCloseIcon={withCloseIcon}>
                {children}
            </Modal>
        </CSSTransition>;
    }
}

ModalWithAnimation.propTypes = {
    closeModal: PropTypes.func.isRequired,
    showModalContent: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    className: PropTypes.string,
    withCloseIcon: PropTypes.bool
};

export default ModalWithAnimation;
