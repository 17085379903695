import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Icon from '../elements/Icon';
import DeliveryTypeOption from './DeliveryTypeOption';
import { TAKE_AWAY_ORDER_TYPE, DELIVERY_ORDER_TYPE, VEHICLE_DELIVERY_ORDER_TYPE } from './../../utils';
import { bindActionCreators } from 'redux';
import { setModal } from '../../store/actions/modalActions';
import { connect } from 'react-redux';
import { changeDeliveryType, deleteAddressSelection } from '../../store/actions/addressActions';
import { getBasket } from '../../store/actions/basketActions';

const DeliveryTypeOptions = [
    {
        label: 'Adrese Teslim',
        value: DELIVERY_ORDER_TYPE
    },
    {
        label: 'gel al',
        value: TAKE_AWAY_ORDER_TYPE
    },
    {
        label: 'Araca Teslim',
        value: VEHICLE_DELIVERY_ORDER_TYPE
    }
];

class DeliveryTypeModal extends Component {
    showUserAddressesModal = () => {
        const { user, boundSetModal, boundDeliveryChangeType, basket, location, boundDeleteAddressSelection } = this.props;

        if (user && user.id) {
            boundSetModal('delivery-address');
        } else {
            if (location.pathname === '/sepet/sepetim') {
                this.props.history.push('/hesap/giris');
                boundSetModal('');
            } else {
                boundDeliveryChangeType(basket.id, '2', '').then(() => {
                    boundSetModal('');
                    boundDeleteAddressSelection();
                    this.getBasket().then(() => {
                        boundSetModal('');
                        if (!location.pathname.includes('/siparis/kampanyalar')) {
                            this.props.history.push('/siparis/kampanyalar');
                        }
                    });
                });    // Select admin store
            }
        }
    };
    getBasket = () => {
        const { boundGetBasket } = this.props;

        return boundGetBasket()
            .then(() => this.setState({ triedToGetBasket: true }))
            .catch(() => {
    
                this.setState({ triedToGetBasket: true });
            });
    };
    showTakeAwayMapModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('takeaway-map');
    };

    showVehicleDeliveryMapModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('vehicle-delivery-map');
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        return (
           
            <div className="modal-content delivery-type-modal">
                <div className="modal-content__header delivery-type-modal__header">
                    Pizzanı Nasıl Sipariş Etmek İstersin?
                    <Icon name="delete" onClick={this.closeModal}/>
                </div>
                <DeliveryTypeOption title="adrese teslim"
                                    details="Pizzanızı en kısa sürede kapınıza getirelim."
                                    imageUrl="/images/order/delivery.svg"
                                    imageAlt="delivery.svg"
                                    deliveryType={DeliveryTypeOptions[0]}
                                    onOptionClick={this.showUserAddressesModal}
                />
                <DeliveryTypeOption title="gel -al"
                                    details="Pizzanızı size en yakın şubemizden gelip alın."
                                    imageUrl="/images/order/takeaway.svg"
                                    imageAlt="takeaway.svg"
                                    deliveryType={DeliveryTypeOptions[1]}
                                    onOptionClick={this.showTakeAwayMapModal}
                />
                <DeliveryTypeOption title="araca teslim"
                                    details="Pizzanızı aracınıza teslim edelim."
                                    imageUrl="/images/order/takeaway-car.svg"
                                    imageAlt="takeaway-car.svg"
                                    deliveryType={DeliveryTypeOptions[2]}
                                    onOptionClick={this.showVehicleDeliveryMapModal}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    addressData: store.addressData,
    basket: store.basket.basket
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundDeliveryChangeType: bindActionCreators(changeDeliveryType, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundDeleteAddressSelection: bindActionCreators(deleteAddressSelection, dispatch)
});

DeliveryTypeModal.propTypes = {
    boundDeliveryChangeType: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    basket: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundDeleteAddressSelection: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeliveryTypeModal));
