import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../elements/Icon';
import Button from '../elements/Button';
import Popup from '../elements/Popup';

const ChangeDeliveryTypePopup = props => {
    const { close, submit } = props;

    return <Popup>
        <Icon name="info" className="popup__icon"/>
        <div className="popup__header">Sepetin Silinecek</div>
        <div className="popup__detail">
            Sepetinizdeki ürünler silinecektir, devam etmek istiyor musunuz?
        </div>
        <Button className="popup__submit-button" onClick={close} dontDisable>
            İptal
        </Button>
        <div>
            <span className="link cancel-button" onClick={submit}>
                Evet
            </span>
        </div>
    </Popup>;
};

ChangeDeliveryTypePopup.propTypes = {
    submit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
};

export default ChangeDeliveryTypePopup;
