import React, { Component } from 'react';
import TextInput from '../../elements/TextInput';
import Toast from '../../elements/Toast';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sendReadyForPickup } from '../../../store/actions/orderActions';
import { setModal } from '../../../store/actions/modalActions';
import { toast } from 'react-toastify';
import { getRestaurantDetails } from '../../../store/actions/addressActions';

class VehicleDeliveryModal extends Component {
    state = {
        customerNote: '',
        vehiclePlateNo: '',
        vehicleDetail: '',
        restaurant: null
    };

    componentDidMount() {
        const { boundGetRestaurantDetails, lastOrder } = this.props;
        boundGetRestaurantDetails(lastOrder.storeId)
            .then(response => this.setState({ restaurant: response.data.result }));
    }

    handleVehiclePlateNumberInput = event => this.setState({ vehiclePlateNo: event.target.value });

    handleVehicleDetailInput = event => this.setState({ vehicleDetail: event.target.value });

    getPhoneLink = () => {
        const { restaurant } = this.state;
        let phone = 'tel:';
        if (restaurant) {
            phone += restaurant.phone;
        }
        return phone;
    };

    deliverMyOrderToVehicle = () => {
        const { orderId, boundSendReadyForPickup, boundSetModal } = this.props;
        const { vehiclePlateNo, vehicleDetail } = this.state;

        if (vehiclePlateNo) {
            boundSendReadyForPickup(orderId, vehiclePlateNo, vehicleDetail).then(response => {
                const result = response.data.result;

                if (result && result.pickupStateId === 1) {
                    toast(
                        <Toast text="Geldiğiniz Restorana Bildirildi, Az Sonra Pizzanız Aracınızda." status="success"/>,
                        { autoClose: 3000 }
                    );
                    boundSetModal('');
                } else {
                    toast(<Toast text="Hata." status="error"/>);
                }
            });
        } else {
            toast(<Toast text="Araç Plaka Numarası Girin lütfen." status="error"/>);
        }
    };

    render() {
        const { lastOrder } = this.props;
        const { vehiclePlateNo, vehicleDetail } = this.state;

        if (!lastOrder.id) {
            return null;
        }

        return (
            <div className="vehicle-delivery-modal">
                <div className="vehicle-delivery-modal__title">
                    Sipariş Takip
                </div>
                <div className="vehicle-delivery-modal__status-container">
                    <div className="vehicle-delivery-modal__status-image">
                        <img src="/images/order-tracking/ready.png" alt="hazır.png"/>
                    </div>
                    <div className="vehicle-delivery-modal__status-title">
                        <p>Siparişiniz Hazır!</p>
                    </div>
                    <div className="vehicle-delivery-modal__status-desc">
                        <p>Araç'a Teslim siparişiniz aracınız geldiğinde teslim edilecektir</p>
                    </div>
                </div>

                <div className="vehicle-delivery-modal__body">
                    <div className="vehicle-delivery-modal__body-title">
                        SezarJet Araca Teslim
                    </div>
                    <div className="vehicle-delivery-modal__body-desc">
                        Şubeye ulaştığınızda, aşağıda bulunan "Not"/"Notlar" alanına araç ve plaka bilgilerinizi
                        yazdıktan sonra, "Siparişimi Getir" butonuna tıklayıp, siparişinizin size ulaşmasını
                        bekleyebilirsiniz.
                    </div>
                    <TextInput label="Araç Plakanızı Yazın"
                               labeClassName="vehicle-delivery-modal__body-input-label"
                               value={vehiclePlateNo}
                               className="vehicle-delivery-modal__body-input"
                               onChange={this.handleVehiclePlateNumberInput}
                               placeholder="34 LC 34"
                    />
                    <TextInput label="Notunuzu Yazın"
                               labelClassName="vehicle-delivery-modal__body-input-label"
                               value={vehicleDetail}
                               className="vehicle-delivery-modal__body-input"
                               onChange={this.handleVehicleDetailInput}
                               placeholder="Araç model ve rengini varsa sipariş notunuzu yazınız."
                    />
                </div>

                <div className="vehicle-delivery-modal__bottom">
                    <a href={this.getPhoneLink()} className="vehicle-delivery-modal__bottom-call-button">
                        şubeyi ara
                    </a>
                    <div className="vehicle-delivery-modal__bottom-deliver-button"
                         onClick={this.deliverMyOrderToVehicle}
                    >
                        siparişimi getir
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    lastOrder: store.orderData.lastOrder
});

const mapDispatchToProps = dispatch => ({
    boundSendReadyForPickup: bindActionCreators(sendReadyForPickup, dispatch),
    boundGetRestaurantDetails: bindActionCreators(getRestaurantDetails, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDeliveryModal);
