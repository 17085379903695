import React from 'react';

const PizzaSlice = props => {
    return <svg width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M31.4525 32.417C32.3269 32.417 33.0397 31.7078 32.997 30.8345C32.8154 27.1132 31.994 23.4469 30.5653 19.9975C28.9343 16.0601 26.5438 12.4824 23.5302 9.46884C20.5166 6.45524 16.9389 4.06472 13.0015 2.43378C9.55212 1.005 5.8858 0.183664 2.16453 0.0019955C1.29124 -0.040634 0.58204 0.672171 0.582039 1.54649L0.582033 30.8339C0.582033 31.7082 1.29081 32.417 2.16514 32.417L31.4525 32.417Z" fill="#FFC900"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.997 30.8343C33.0396 31.7076 32.3268 32.4168 31.4525 32.4168H24.3008C23.6995 19.591 13.4078 9.29938 0.582031 8.6981L0.582033 1.54632C0.582033 0.671993 1.29124 -0.0408146 2.16452 0.00181802C5.88579 0.183486 9.55211 1.00482 13.0015 2.4336C16.9389 4.06455 20.5166 6.45506 23.5302 9.46866C26.5438 12.4823 28.9343 16.0599 30.5652 19.9974C31.994 23.4467 32.8154 27.1131 32.997 30.8343Z" fill="#FB8B00"/>
        <path d="M16.4131 24.6684C16.4131 27.1993 14.3284 29.251 11.7569 29.251C9.18533 29.251 7.10069 27.1993 7.10069 24.6684C7.10069 22.1374 9.18533 20.0857 11.7569 20.0857C14.3284 20.0857 16.4131 22.1374 16.4131 24.6684Z" fill="#E64C3C"/>
        <path d="M5.23822 16.4196C5.23822 13.8886 3.15357 11.8369 0.582032 11.8369L0.582031 21.0022C3.15357 21.0022 5.23821 18.9505 5.23822 16.4196Z" fill="#E64C3C"/>
    </svg>;
};

export default PizzaSlice;
