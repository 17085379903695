import {
    SET_USER,
    SET_FORGOT_PASSWORD_EMAIL,
    SET_RESET_PASSWORD_HASH,
    SET_IS_UPDATE_USERS_DATA,
    SET_IS_NEW_USER,
    SET_IS_CHECKOUT
} from '../actions/authActions';

const initialState = {
    user: {},
    forgotPasswordEmail: '',
    resetPasswordHash: '',
    isUpdateUsersData: false,
    isNewUser: false,
    isCheckout: false
};

const authReducer = (state=  initialState, action) => {
    switch (action.type){
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }

        case SET_FORGOT_PASSWORD_EMAIL:
            return {
                ...state,
                forgotPasswordEmail: action.payload
            }

        case SET_RESET_PASSWORD_HASH:
            return {
                ...state,
                resetPasswordHash: action.payload
            }

        case SET_IS_UPDATE_USERS_DATA:
            return {
                ...state,
                isUpdateUsersData: action.payload
            };

        case SET_IS_NEW_USER:
            return {
                ...state,
                isNewUser: action.payload
            };

        case SET_IS_CHECKOUT:
            return {
                ...state,
                isCheckout: action.payload
            };

        default:
            return state;
    }
}

export default authReducer
