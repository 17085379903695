import React from 'react';
import { Link } from 'react-router-dom'

const BreadCrumbscustomerservices = () => {
    return (
        <div className="bread-crumbs hidden-sm hidden-xs">
            <ul>
                <li><Link to="/">Anasayfa / </Link></li>
                <li><Link to="/musteri-hizmetleri">Müşteri Hizmetleri</Link></li>
            </ul>
        </div>
    );
};

export default BreadCrumbscustomerservices;