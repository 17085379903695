import api, { handleRequest } from "../../api"

export const SET_PROMO_IMAGES = 'SET_PROMO_IMAGES'
export const SET_MAIN_PAGE_CAMPAIGNS = 'SET_MAIN_PAGE_CAMPAIGNS'
export const SET_SPECIAL_SELECTIONS = 'SET_SPECIAL_SELECTIONS'

export const setPromoImages = response => ({
    type: SET_PROMO_IMAGES,
    payload: response
});

export const setMainPageCampaigns = response => ({
    type: SET_MAIN_PAGE_CAMPAIGNS,
    payload: response
});

export const setSpecialSelections = response => ({
    type: SET_SPECIAL_SELECTIONS,
    payload: response
});


export const getPromoImages = (param) => dispatch =>
    api.get(`web/CMS/Banner?Name=${param}`, dispatch)
        .then(response => handleRequest(response, () => dispatch(setPromoImages(response.data.result))));

export const getMainPageCampaigns = () => dispatch =>
    api.get('web/Product/GetMainPageCampaigns', dispatch)
        .then(response => handleRequest(response, () => dispatch(setMainPageCampaigns(response.data.result))));

export const getSpecialSelections = () => dispatch =>
    api.get('web/Product/GetMainPageForYou', dispatch)
        .then(response => handleRequest(response, () => dispatch(setSpecialSelections(response.data.result))));
