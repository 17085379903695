import { combineReducers } from 'redux';
import modal from './modalReducer';
import homePage from "./homePageReducer";
import user from "./authReducer";
import member from "./memberReducer";
import pages from "./pagesReducer";
import layout from "./layoutReducer";
import basket from "./basketReducer";
import addressData from "./addressReducer";
import productData from "./productReducer";
import loader from "./loaderReducer";
import orderData from "./orderReducer";

const rootReducer = combineReducers({
  modal,
  homePage,
  user,
  member,
  pages,
  layout,
  basket,
  addressData,
  productData,
  loader,
  orderData
});

export default rootReducer