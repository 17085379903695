import React from 'react';

const BigHeart = () => 
<svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="164" height="164" fill="#383838"/>
<rect x="-751" y="-312" width="1366" height="1142" fill="#F8F8F8"/>
<path d="M-352.5 -174C-352.5 -178.142 -349.142 -181.5 -345 -181.5H509C513.142 -181.5 516.5 -178.142 516.5 -174V368C516.5 372.142 513.142 375.5 509 375.5H-345C-349.142 375.5 -352.5 372.142 -352.5 368V-174Z" fill="white" stroke="#E5E5E5"/>
<circle cx="82" cy="82" r="82" fill="#F8F8F8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M82 113.354C80.9938 113.354 80.0231 112.992 79.2645 112.331C76.4072 109.839 73.6521 107.502 71.2192 105.439L71.2074 105.427C64.0818 99.3759 57.927 94.1524 53.643 89.0045C48.8559 83.2475 46.6274 77.7891 46.6274 71.8291C46.6274 66.0363 48.6201 60.6933 52.2399 56.7837C55.9029 52.8302 60.9258 50.6484 66.3889 50.6484C70.4725 50.6484 74.2141 51.9344 77.5037 54.4705C79.1663 55.7525 82 59.1486 82 59.1486C82 59.1486 84.8337 55.7525 86.4962 54.4705C89.7859 51.9344 93.5275 50.6484 97.6111 50.6484C103.07 50.6484 108.101 52.8302 111.76 56.7837C115.38 60.6933 117.373 66.0363 117.373 71.8291C117.373 77.7891 115.144 83.2475 110.357 89.0005C106.073 94.1524 99.9221 99.3719 92.7925 105.427C90.3597 107.49 87.6006 109.835 84.7355 112.331C83.9769 112.992 83.0061 113.354 82 113.354Z" stroke="#E5E5E5" strokeWidth="4"/>
</svg>

export default BigHeart;