import { SET_FAVORITE_PRODUCTS,
         SET_FAVORITE_ORDERS,
         SET_CREDIT_CARDS,
         SET_MASTERPASS_RESPONSE,
         SET_CARDS_LIST_WAS_CHANGED
       } from '../actions/memberActions';

const initialState = {
  favoriteProducts: [],
  favoriteOrders: [],
  creditCards: [],
  masterpassResponse: '',
  cardsListWasChanged: false
};

const memberReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_FAVORITE_PRODUCTS:
      return {
        ...state,
        favoriteProducts: action.payload
      }

    case SET_FAVORITE_ORDERS:
      return {
        ...state,
        favoriteOrders: action.payload
      }

    case SET_CREDIT_CARDS:
      return {
        ...state,
        creditCards: action.payload
      }

    case SET_MASTERPASS_RESPONSE:
      return {
        ...state,
        masterpassResponse: action.payload
      }

    case SET_CARDS_LIST_WAS_CHANGED:
      return {
        ...state,
        cardsListWasChanged: action.payload
      }

    default:
      return state
  }
}

export default memberReducer
