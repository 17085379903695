import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../elements/Icon';

const LoyaltySlices = props => {
    const getSliceIconName = order => {
        const { user } = props;
        const remainPunch = +user.loyalty.punchCard.availablePunch % +user.loyalty.punchCard.requiredToGetGift;
        if (remainPunch >= order) {
            return 'pizza-slice';
        }
        return 'pizza-slice-inactive';
    };

    const getSliceClassName = order => {
        const { sliceClassName } = props;
        let className = `loyalty-slices__pizza-slice-icon loyalty-slices__pizza-slice-icon--${order}`;
        if (sliceClassName) {
            className += ` ${sliceClassName}`;
        }
        return className;
    };
//TODO: @KirillioClockwork sizes should be 66px for slices only in loyalty page, not in preview
    return <div className="loyalty-slices__slices-container">
        <div className="loyalty-slices__slices-row">
            <Icon name={getSliceIconName(4)} className={getSliceClassName(1)}/>
            <Icon name={getSliceIconName(1)} className={getSliceClassName(2)}/>
        </div>
        <div className="loyalty-slices__slices-row">
            <Icon name={getSliceIconName(3)} className={getSliceClassName(3)}/>
            <Icon name={getSliceIconName(2)} className={getSliceClassName(4)}/>
        </div>
    </div>;
};

LoyaltySlices.propTypes = {
    user: PropTypes.object.isRequired,
    sliceClassName: PropTypes.string
};

export default LoyaltySlices;
