import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getLastOrder } from '../../../store/actions/orderActions';
import { orderStateIds } from '../../../utils';
import Icon from '../../elements/Icon';
import OrderStatusInfo from './OrderStatusInfo';
import OrderDetailsModalContent from '../orders/OrderDetailsModalContent';
import { sendPageView } from '../../../googleAnalyticsHelper';
import { setModal } from '../../../store/actions/modalActions';

class OrderTracking extends Component { // TODO: remove unused css about profile-order-tracking
    componentDidMount() {
        const { location, boundGetLastOrder } = this.props;

        sendPageView(location.pathname, 'Sipariş Takibi'); // for google analytics

        boundGetLastOrder().then(() => {
            const { lastOrder } = this.props;

            if (lastOrder && lastOrder.orderTypeId === '8') {
                const orderStatusId = lastOrder.orderStateId;

                if (orderStatusId === '5') {
                    return this.openVehicleDeliveryModal(lastOrder.id);
                }
            }
        });
    }

    openVehicleDeliveryModal = orderId => {
        const { boundSetModal } = this.props;

        boundSetModal(`vehicle-delivery/${orderId}`);
    };

    renderOrderStatus = (orderId, orderStateId, orderTypeId) => {

        return orderStateIds.orderTaken.includes(+orderStateId)
            ? <OrderStatusInfo orderId={orderId}
                               orderTypeId={orderTypeId}
                               imageUrl="/images/order-tracking/order-taken.png"
                               imageAlt="alındı"
                               title="Siparişin Alındı"
                               description="Siparişiniz restoranımıza iletiliyor."
                               spinnerLoading="taken"
            />
            : orderStateIds.preparing.includes(+orderStateId)
                ? <OrderStatusInfo orderId={orderId}
                                   orderTypeId={orderTypeId}
                                   imageUrl="/images/order-tracking/preparing.png"
                                   imageAlt="hazırlanıyor"
                                   title="Hazırlanıyor"
                                   description="Siparişiniz yola cıkmak için hazırlanıyor."
                                   spinnerLoading="preparing"
                />
                : orderStateIds.ready.includes(+orderStateId)
                    ? <OrderStatusInfo orderId={orderId}
                                       orderTypeId={orderTypeId}
                                       imageUrl="/images/order-tracking/ready.png"
                                       imageAlt={+orderTypeId === 8 ? 'fırından-çıktı' : 'hazır'}
                                       title={+orderTypeId === 8 ? 'Fırından Çıktı' : 'Siparişin Hazır'}
                                       description={+orderTypeId === 8 ? 'Araç\'a Teslim siparişiniz aracınız geidiğinde teslim edilecektir.' : 'siparişiniz size ulaştırmak için yola cıktı'}
                                       spinnerLoading="ready"
                    />
                    : orderStateIds.inDelivery.includes(+orderStateId)
                        ? <OrderStatusInfo orderId={orderId}
                                           orderTypeId={orderTypeId}
                                           imageUrl="/images/order-tracking/in-delivery.png"
                                           imageAlt="yolda"
                                           title="Siparişin Yolda"
                                           description="siparişiniz size ulaştırmak için yola cıktı"
                                           spinnerLoading="inDelivery"
                        />
                        : orderStateIds.delivered.includes(+orderStateId)
                            ? <OrderStatusInfo orderId={orderId}
                                               orderTypeId={orderTypeId}
                                               imageUrl="/images/order-tracking/delivered.png"
                                               imageAlt="teslim-edildi"
                                               title="Teslim Edildi"
                                               description="siparişiniz teslim edildi"
                                               spinnerLoading="delivered"
                            />
                            : orderStateIds.laterDeliveryDate.includes(+orderStateId)
                                ? <OrderStatusInfo orderId={orderId}
                                                   orderTypeId={orderTypeId}
                                                   imageUrl="/images/order-tracking/order-taken.png"
                                                   imageAlt="ileri-tarihli"
                                                   title="İleri Tarihli"
                                                   description="Siparişiniz restoranımıza iletiliyor."
                                                   spinnerLoading="taken"
                                />
                                : orderStateIds.cancelled.includes(+orderStateId)
                                    ? <OrderStatusInfo orderId={orderId}
                                                       orderTypeId={orderTypeId}
                                                       imageUrl="/images/order-tracking/cancelled.png"
                                                       imageAlt="iptal-edildi"
                                                       title="Siparişiniz İptal Edildi"
                                                       description="siparişiniz iptal ettik"
                                                       spinnerLoading="cancelled"
                                    />
                                    : orderStateIds.customerArrived.includes(+orderStateId)
                                        ? <OrderStatusInfo orderId={orderId}
                                                           orderTypeId={orderTypeId}
                                                           imageUrl="/images/order-tracking/delivered.png"
                                                           imageAlt="teslim-edildi"
                                                           title="Teslim Edildi"
                                                           description="siparişiniz teslim edildi"
                                                           spinnerLoading="customer Arrived"
                                        />
                                        : null; // TODO: check the code numbers to handle all cases?
    };

    render() {
        const { loading, lastOrder } = this.props;

        if (loading && !lastOrder.id) {
            return null;
        }
        if (!loading && !lastOrder.id) {
            return <div className="profile__page">
                <div className="profile-orders__body">
                    <div className="profile-orders__body-icon">
                        <div className="big-icon-background">
                            <Icon name="big-heart"/>
                        </div>
                    </div>
                    <div className="profile-orders__body-text">
                        Kayıtlı siparişiniz bulunmamaktadır.
                    </div>
                    <div className="product-preview__buttons-container product-preview__block">
                        <div
                            className="button button--light link product-preview__button"
                            onClick={this.startOrder}
                        >
                            <span className="button__text">sipariş ver</span>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return <OrderDetailsModalContent orderId={lastOrder.id}>
            {this.renderOrderStatus(lastOrder.id, lastOrder.orderStateId, lastOrder.orderTypeId)}
        </OrderDetailsModalContent>;
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    lastOrder: store.orderData.lastOrder
});

const mapDispatchToProps = dispatch => ({
    boundGetLastOrder: bindActionCreators(getLastOrder, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

OrderTracking.propTypes = {
    boundGetLastOrder: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    lastOrder: PropTypes.object.isRequired,
    loading: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderTracking));
