import React from 'react';
import CorporateMenu from '../../corporate-menu/CorporateMenu';
import BreadCrumbs from '../../../elements/BreadCrumbs';
import { sendPageView } from '../../../../googleAnalyticsHelper';

const NutritionValues = () => {
    sendPageView('/kurumsal/besin-degerleri', 'Besin Değerleri - Little Caesars'); //for ga
    
    return <section class="institutional-structures">
  <div class="container">
       <BreadCrumbs />
       <div class="institutional-structures-detail">
          <div class="institutional-structures-detail-content clearfix">
              <CorporateMenu menuType='quality' />

              <div class="col-md-9">
                  <div class="corpareta-detail">
                      <h1>Besin Değerleri</h1>
                      <div class="row">

                          <div class="col-sm-12 col-xs-12">
                              <a class="certificate" target="_blank" href="/images/kurumsal/nutritionfacts/LittleCaesars_BesinDegerleri.pdf">
                                  <img class="img-responsive" src="/images/kurumsal/nutritionfacts/LittleCaesars_BesinDegerleri.jpg" alt="LittleCaesars_BesinDegerleri.jpg" />
                              </a>
                          </div>
                          
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
};

export default NutritionValues;