import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getOrders, setOrders, copyOrderAndGetBasket } from '../../../store/actions/orderActions';
import { addFavoriteOrder, deleteFavoriteOrder } from '../../../store/actions/memberActions';
import { orderStateIds } from '../../../utils';
import { getBasket } from '../../../store/actions/basketActions';
import { setModal } from '../../../store/actions/modalActions';
import Icon from '../../elements/Icon';
import { sendPageView } from '../../../googleAnalyticsHelper';
import Order from './Order';

class ProfileOrders extends Component {
    state = {
        orders: [],
        ordersClicked: true,
        cancelledClicked: false
    };

    componentDidMount() {
        const { user, orders, location, boundGetOrders } = this.props;

        if (user.id) {
            boundGetOrders();
        }

        sendPageView(location.pathname, 'Siparişlerim'); // for google analytics
    }

    showOrderDetailsModal = orderId => () => {
        const { boundSetModal } = this.props;

        boundSetModal(`order-details/${orderId}`);
    };

    getShowOrderCommentModal = orderId => () => {
        const { boundSetModal } = this.props;
        
        boundSetModal(`order-comment/${orderId}`);
    };

    getTabClassName = clicked => {
        const { ordersClicked, cancelledClicked } = this.state;

        return clicked === 'orders'
            ? ordersClicked
                ? 'profile-orders__header-tab profile-orders__header-tab--active'
                : 'profile-orders__header-tab'
            : clicked === 'cancelled'
                ? cancelledClicked
                    ? 'profile-orders__header-tab profile-orders__header-tab--active'
                    : 'profile-orders__header-tab'
                : null;
    };

    getToggleMyOrdersTabs = clicked => () => {
        const { ordersClicked, cancelledClicked } = this.state;

        if (clicked === 'orders') {
            if (!ordersClicked && cancelledClicked) {
                this.setState({
                    ordersClicked: true,
                    cancelledClicked: false
                });
            }
        } else if (clicked === 'cancelled') {
            if (ordersClicked && !cancelledClicked) {
                this.setState({
                    ordersClicked: false,
                    cancelledClicked: true
                });
            }
        }
    };

    startOrder = () => {
        const { history, addressData, boundSetModal, boundSetRedirect } = this.props;
        if (addressData.addressSelection.address) {
            history.push('/siparis/kampanyalar');
        } else {
            boundSetModal('delivery-type');
            boundSetRedirect({ type: 'url', value: '/siparis/kampanyalar' });
        }
    };

    render() {
        const { loading, orders } = this.props;
        const { ordersClicked, cancelledClicked } = this.state;
        let orderCounter = 0;

        return <div className="profile-orders profile__page">
            <div className="profile-orders__header">
                <div className="profile__block-header">
                    Siparişlerim
                </div>
                <div className="profile-orders__header-tab-container">
                    <div className={this.getTabClassName('orders')}
                         onClick={this.getToggleMyOrdersTabs('orders')}
                    >
                        Siparişlerim
                    </div>
                    <div className={this.getTabClassName('cancelled')}
                         onClick={this.getToggleMyOrdersTabs('cancelled')}>
                        İptallerim
                    </div>
                </div>
            </div>
            {
                ordersClicked ?
                    orders.length
                        ? orders.map(order =>
                            !orderStateIds.cancelled.includes(order.id)
                            ?
                              <Order key={order.id} order={order}
                                     onShowOrderCommentModalClick={this.getShowOrderCommentModal(order.id)}
                                     onShowOrderDetailsModalClick = {this.showOrderDetailsModal(order.id)}
                                />
                                : null
                        )
                        : !loading
                        ? <div className="profile-orders__body">
                            <div className="profile-orders__body-icon">
                                <div className="big-icon-background">
                                    <Icon name="big-heart"/>
                                </div>
                            </div>
                            <div className="profile-orders__body-text">Kayıtlı siparişiniz bulunmamaktadır.</div>
                            <div className="product-preview__buttons-container product-preview__block">
                                <div className="button button--light link product-preview__button"
                                     onClick={this.startOrder}>
                                    <span className="button__text">sipariş ver</span>
                                </div>
                            </div>
                        </div>
                        : null
                    : cancelledClicked
                    ? orders.length
                        ? orders.map(order => {
                            return orderStateIds.cancelled.includes(order.id)
                                ? 
                                <Order key={order.id} order={order}
                                    onShowOrderCommentModalClick={this.getShowOrderCommentModal(order.id)}
                                    onShowOrderDetailsModalClick={this.showOrderDetailsModal(order.id)}
                                />
                                : !loading
                                    ? orderCounter < 1
                                        ? <div className="profile-orders__body">
                                            <div className="profile-orders__body-icon">
                                                <div className="big-icon-background">
                                                    <Icon name="big-heart"/>
                                                </div>
                                            </div>
                                            <div className="profile-orders__body-text">
                                                Kayıtlı siparişiniz bulunmamaktadır.
                                            </div>
                                            <div className="product-preview__buttons-container product-preview__block">
                                                <div className="button button--light link product-preview__button"
                                                     onClick={this.startOrder}>
                                                    <span className="button__text">sipariş ver</span>
                                                </div>
                                            </div>
                                            <div className="profile-orders__body-none">
                                                {orderCounter = 1}
                                            </div>
                                        </div>
                                        : null
                                    : null;
                        })
                        : !loading
                            ? <div className="profile-orders__body">
                                <div className="profile-orders__body-icon">
                                    <div className="big-icon-background">
                                        <Icon name="big-heart"/>
                                    </div>
                                </div>
                                <div className="profile-orders__body-text">
                                    Kayıtlı siparişiniz bulunmamaktadır.
                                </div>
                                <div className="product-preview__buttons-container product-preview__block">
                                    <div className="button button--light link product-preview__button"
                                         onClick={this.startOrder}>
                                        <span className="button__text">sipariş ver</span>
                                    </div>
                                </div>
                            </div>
                            : null
                    : null
            }
        </div>;
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    user: store.user.user,
    basket: store.basket.basket,
    addressData: store.addressData,
    orders: store.orderData.orders
});

const mapDispatchToProps = dispatch => ({
    boundGetOrders: bindActionCreators(getOrders, dispatch),
    boundSetOrders: bindActionCreators(setOrders, dispatch),
    boundCopyOrderAndGetBasket: bindActionCreators(copyOrderAndGetBasket, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundAddFavoriteOrder: bindActionCreators(addFavoriteOrder, dispatch),
    boundDeleteFavoriteOrder: bindActionCreators(deleteFavoriteOrder, dispatch)
});

ProfileOrders.propTypes = {
    boundGetOrders: PropTypes.func.isRequired,
    boundSetOrders: PropTypes.func.isRequired,
    boundCopyOrderAndGetBasket: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundAddFavoriteOrder: PropTypes.func.isRequired,
    boundDeleteFavoriteOrder: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    addressData: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileOrders));
