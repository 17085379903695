import {
    SET_RESTAURANTS,
    SET_ADDRESS_SELECTION,
    SET_USER_DELIVERY_ADDRESSES,
    SET_IS_NEW_ADDRESS,
    SET_CHANGED_ADDRESS
} from '../actions/addressActions';

const initialAddressSelection = {
    type: 'Adrese Teslim',
    address: '',
    addressId: '',
    isUserAddress: false,
    coordinates: {
        latitude: null,
        longitude: null
    },
    addressName: '',
    addressDetails: '',
    addressDirections: ''
};

const initialState = {
    restaurants: [],
    addressSelection: initialAddressSelection,
    userDeliveryAddresses: null,
    restaurantDetails: {},
    isNewAddress: false,
    changedAddress: {
        isChangedAddress: false,
        changedAddressId: '',
        changedAddressName: ''
    }
};

const addressReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ADDRESS_SELECTION:
            return {
                ...state,
                addressSelection: {
                    ...initialAddressSelection,
                    ...action.payload
                }
            };

        case SET_RESTAURANTS:
            return {
                ...state,
                restaurants: action.payload
            };

        case SET_USER_DELIVERY_ADDRESSES:
            return {
                ...state,
                userDeliveryAddresses: action.payload
            };

        case SET_IS_NEW_ADDRESS:
            return {
                ...state,
                isNewAddress: action.payload
            };

        case SET_CHANGED_ADDRESS:
            return {
                ...state,
                changedAddress: action.payload
            };

        default:
            return state;
    }
};

export default addressReducer;
