import React from 'react';
import CorporateMenu from '../../corporate-menu/CorporateMenu';
import { sendPageView } from '../../../../googleAnalyticsHelper';

const AllergyList = () => {
    sendPageView('/kurumsal/alerjen-listesi', 'Alerjen Listesi - Little Caesars'); // for ga
    
    return <section class="institutional-structures">
  <div class="container">
      {/* <app-breadcrumbs></app-breadcrumbs> */}
      <div class="institutional-structures-detail">
          <div class="institutional-structures-detail-content clearfix">
            
              <CorporateMenu menuType='quality' />

              <div class="col-md-9">
                  <div class="corpareta-detail">
                      <h1>Alerjen Listesi</h1>
                      <div class="row">
                          <div class="col-sm-12 col-xs-12">
                              <a class="certificate" target="_blank" href="/images/kurumsal/allergyinfo/alerjenlistesi.pdf">
                                  <img class="img-responsive" src="/images/kurumsal/allergyinfo/alerjenlistesi-thumb.jpg" alt="alerjenlistesi-thumb.jpg" />
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
};

export default AllergyList;