import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    changeDeliveryAddress, getUserDeliveryAddresses, setAddressSelection, changeDeliveryType
} from '../../store/actions/addressActions';
import { getBasket } from '../../store/actions/basketActions';
import { removeAllOrders } from '../../store/actions/orderActions';
import { DELIVERY_ORDER_TYPE, doRedirect } from '../../utils';
import Button from '../elements/Button';
import DeliveryAddressOption from './DeliveryAddressOption';
import Icon from '../elements/Icon';
import ChangeDeliveryTypePopup from './ChangeDeliveryTypePopup';
import { toast } from 'react-toastify';
import Toast from '../elements/Toast';
import RemoveItemsFromBasketCheckoutPopup from '../checkout/RemoveItemsFromBasketCheckoutPopup';

class DeliveryAddressModal extends Component {
    state = {
        addressToChangeDeliveryType: null
    };

    componentDidMount() {
        const { addressData, boundGetUserDeliveryAddresses } = this.props;
        if (!addressData.userDeliveryAddresses) {
            boundGetUserDeliveryAddresses();
        }
    }

    componentWillUnmount() {
        const { modal, setRedirect } = this.props;
        if (modal !== 'add-address') {
            setRedirect({});
        }
    }

    removeAllOrders = () => {
        const { boundRemoveAllOrders, history, basket, boundGetBasket } = this.props;
        boundRemoveAllOrders(basket.id)
            .then(() => {
                boundGetBasket();
                history.push('/siparis/kampanyalar');
            });
    };
    getSelectAddress = address => () => {
        const { addressData, basket } = this.props;

        if (addressData.addressSelection.type !== 'Adrese Teslim') {
            if (basket.orderItems.length) {
                this.setState({ addressToChangeDeliveryType: address });
            } else {
                this.getChangeDeliveryType(address)();
            }
        } else {
            this.changeDeliveryAddress(address);
        }
    };

    getChangeDeliveryType = address => () => {
        const { basket, boundChangeDeliveryType } = this.props;

        this.closeChangeDeliveryTypePopup();
        boundChangeDeliveryType(basket.id, DELIVERY_ORDER_TYPE, address.addressId)
            .then(() => this.changeDeliveryAddress(address));
    };

    closeChangeDeliveryTypePopup = () => this.setState({ addressToChangeDeliveryType: null });

    cancelChangeDeliveryType = () => {
        const { setModal } = this.props;
        this.closeChangeDeliveryTypePopup();
        setModal('');
    };

    changeDeliveryAddress = address => {
        const {
            basket, boundSetAddressSelection, boundChangeDeliveryAddress, boundGetBasket, location, history, redirect,
            setModal, setRedirect
        } = this.props;

        boundChangeDeliveryAddress(basket.id, address.addressId)
            .then((response) => {
                if (response && response.data.resultCode == '404') {
                    console.log("1")
                    toast(<RemoveItemsFromBasketCheckoutPopup
                        text={response.data.message}
                        submit={this.removeAllOrders}
                    />, { closeOnClick: true });
                    return;
                }
                boundSetAddressSelection({
                    type: 'Adrese Teslim',
                    address: address.address.name,
                    addressId: address.addressId,
                    isUserAddress: true
                })
            })
            .catch(() => boundSetAddressSelection({})
            )
            .then(() => boundGetBasket()
                .then(() => {
                    if (redirect) {
                        doRedirect(redirect, history, setModal);
                        setRedirect({});    // Not clearing redirect in componentWillUnmpunt may lead to bug
                    } else if (!location.pathname.includes('/siparis') && !location.pathname.includes('/sepet/sepetim')) {
                        history.push('/siparis/kampanyalar');
                    }
                }))
            .finally(this.closeModal);
    };

    showAddAddressModal = () => {
        const { setModal } = this.props;
        setModal('add-address');
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    getNoAddressText = () => {
        const { redirect } = this.props;
        if (redirect.value === '/sepet/sepetim') {
            return 'Üyelik işleminizin tamamlanması için lütfen adresinizi ekleyiniz. Adresinizi ekledikten sonra siparişinize kaldığınız yerden devam edebilirsiniz.';
        }
        return 'Üyelik işleminizin tamamlanması için lütfen adresinizi ekleyiniz. ';
    };

    render() {
        const { addressData, user } = this.props;
        const { addressToChangeDeliveryType } = this.state;

        return (
            <div className="modal-content delivery-address-modal">
                {
                    addressToChangeDeliveryType
                        ? <ChangeDeliveryTypePopup submit={this.getChangeDeliveryType(addressToChangeDeliveryType)}
                            close={this.cancelChangeDeliveryType}
                        />
                        : null
                }
                <div className="modal-content__header delivery-address-modal__header">
                    Adrese Teslim
                    <Icon name="delete" onClick={this.closeModal} />
                </div>
                <div className="delivery-address-modal__body">
                    {
                        user && user.id && addressData.userDeliveryAddresses && addressData.userDeliveryAddresses.length
                            ? addressData.userDeliveryAddresses.map(address =>
                                <DeliveryAddressOption key={address.addressId}
                                                       address={address}
                                                       onSelectAddressClick={this.getSelectAddress(address)}
                                />
                            )
                            : <div className="delivery-address-modal__no-address-text">
                                {this.getNoAddressText()}
                            </div>
                    }
                </div>
                <div className="delivery-address-modal__add-button-container">
                    <Button color="orange"
                        className="delivery-address-modal__add-button"
                        onClick={this.showAddAddressModal}
                    >
                        yeni adres ekle +
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    basket: store.basket.basket,
    addressData: store.addressData,
    redirect: store.modal.redirect,
    modal: store.modal.modal
});

const mapDispatchToProps = dispatch => ({
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundChangeDeliveryType: bindActionCreators(changeDeliveryType, dispatch),
    boundGetUserDeliveryAddresses: bindActionCreators(getUserDeliveryAddresses, dispatch),
    boundChangeDeliveryAddress: bindActionCreators(changeDeliveryAddress, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundRemoveAllOrders: bindActionCreators(removeAllOrders, dispatch),
});

DeliveryAddressModal.propTypes = {
    user: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    redirect: PropTypes.object.isRequired,
    setModal: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundChangeDeliveryType: PropTypes.func.isRequired,
    boundGetUserDeliveryAddresses: PropTypes.func.isRequired,
    boundChangeDeliveryAddress: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundRemoveAllOrders: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeliveryAddressModal));
