import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../elements/TextInput';
import { connect } from 'react-redux';
import getValidator from '../../getValidator';
import Button from '../elements/Button';
import { getCookie, setLocalStorage } from '../../storage';
import {
    getUser,
    checkPhoneСonfirmedSmsCode,
    setIsUpdateUsersData,
    setIsNewUser,
    setIsCheckout
} from '../../store/actions/authActions';
import {
    createUserDeliveryAddress,
    getUserDeliveryAddresses,
    changeDeliveryType,
    setAddressSelection,
    changeDeliveryAddress,
    setIsNewAddress,
    setChangedAddress
} from '../../store/actions/addressActions';
import { getBasket } from '../../store/actions/basketActions';
import Icon from '../elements/Icon';
import { bindActionCreators } from 'redux';
import { addAddAddressAction, addRegisterAction } from '../../googleAnalyticsHelper';
import { withRouter } from 'react-router-dom';
import { DELIVERY_ORDER_TYPE, doRedirect } from '../../utils';
import { toast } from 'react-toastify';
import Toast from '../elements/Toast';

class SmsCodeConfirmationPhoneModal extends Component {

    validator = getValidator(this);

    state = {
        smsCode: ''
    };
    smsCodeHandler = event => {
        const smsCode = event.target.value;
        this.setState({ smsCode });
    };

    selectAddress = address => {
        const { addressData, basket } = this.props;
        if (addressData.addressSelection.type !== 'Adrese Teslim') {
            if (!basket.orderItems.length) {
                this.getChangeDeliveryType(address)();
            }
        } else {
            this.changeDeliveryAddress(address);
        }
    };

    getChangeDeliveryType = address => () => {
        const { basket, boundChangeDeliveryType } = this.props;

        boundChangeDeliveryType(basket.id, DELIVERY_ORDER_TYPE, address.addressId)
            .then(() => this.changeDeliveryAddress(address));
    };

    changeDeliveryAddress = address => {
        const {
            basket, boundSetAddressSelection, boundChangeDeliveryAddress, boundGetBasket, setModal, redirect, history, location
        } = this.props;

        boundChangeDeliveryAddress(basket.id, address.addressId)
            .then(() => boundSetAddressSelection({
                type: 'Adrese Teslim',
                address: address.address.name,
                addressId: address.addressId,
                isUserAddress: true
            }))
            .catch(() =>
                boundSetAddressSelection({})
            )
            .then(() => boundGetBasket()
                .then(() => {
                    if (redirect) {
                        doRedirect(redirect, history, setModal);
                    } else if (!location.pathname.includes('/siparis') && !location.pathname.includes('/sepet/sepetim')) {
                        history.push('/siparis/kampanyalar');
                    }
                }))
            .finally(() => setModal(''));
    };

    setCurrentUser = () => {
        const { user } = this.props;

        if (user && user.id) {
            setLocalStorage('userId', user.personId);
            setLocalStorage('isLoggedIn', 'True');
        }
    };

    submit = () => {
        const {
            boundCheckPhoneСonfirmedSmsCode, setModal, boundGetUser, boundCreateUserDeliveryAddress,
            boundGetUserDeliveryAddresses, isNewAddress, changedAddress, addressData, boundSetAddressSelection,
            isUpdateUsersData, isNewUser, boundGetBasket, isCheckout, user, history, location, redirect
        } = this.props;
        const { smsCode } = this.state;
        const phoneId = getCookie('customerPhoneId');

        this.validator.showMessages();

        if (this.validator.allValid()) {
            if (isUpdateUsersData) {                                                //  When user change phone number
                boundCheckPhoneСonfirmedSmsCode(phoneId, smsCode)
                    .then(result => {
                        console.log(result);
                        if (result.data.status) {
                            boundGetUser();
                            this.closeModal();
                        }
                    })
                    .catch(() => toast(<Toast text={'Doğrulama kodunuz yanlıştır.'}
                                              status="error"/>));

            } else if (isNewAddress) {                                             //  When user set new address
                boundCheckPhoneСonfirmedSmsCode(phoneId, smsCode)
                    .then(result => {
                        if (result.data.status) {
                            boundGetUserDeliveryAddresses()
                                .then(response => {
                                    if (response.payload && response.payload.length) {
                                        const address = response.payload[response.payload.length - 1];
                                        this.selectAddress(address);
                                    }
                                })
                                .catch(() => this.closeModal())
                                .finally(addAddAddressAction);
                            if (redirect) {
                                doRedirect(redirect, history, setModal);
                            }
                            this.closeModal();
                        }
                    })
                    .catch(() => toast(<Toast text={'Doğrulama kodunuz yanlıştır.'}
                                              status="error"/>));
            } else if (changedAddress.isChangedAddress) {                          //  When user changed address
                boundCheckPhoneСonfirmedSmsCode(phoneId, smsCode)
                    .then(result => {
                        if (result.data.status) {
                            if (addressData.addressSelection.addressId === changedAddress.changedAddressId) {
                                boundSetAddressSelection({
                                    type: 'Adrese Teslim',
                                    address: changedAddress.changedAddressName,
                                    addressId: changedAddress.changedAddressId
                                });
                            }
                            boundGetUserDeliveryAddresses().finally(() => this.closeModal());
                        }
                    })
                    .catch(() => toast(<Toast text={'Doğrulama kodunuz yanlıştır.'}
                                              status="error"/>));
            } else if (isNewUser) {                                                 //  When user registered
                boundCheckPhoneСonfirmedSmsCode(phoneId, smsCode)
                    .then(result => {
                        if (result.data.status) {
                            boundGetUser();
                            boundGetBasket();

                            // For google analytics
                            this.setCurrentUser();
                            addRegisterAction('Gmail');

                            history.push('/');
                            this.closeModal();

                            if (addressData.addressSelection.type === "Adrese Teslim") {
                              setModal('add-address');
                            } else {
                              setModal('delivery-type');
                            }
                        } else {
                            return toast(<Toast text={'Doğrulama kodunuz yanlıştır.'}
                                                status="error"/>);
                        }
                    });
            } else if (isCheckout) {                                                //  When user went to checkout page
                boundCheckPhoneСonfirmedSmsCode(phoneId, smsCode)
                    .then(result => {
                        if (result.data.status) {
                            if (location.pathname !== '/sepet/sepetim') {
                                history.push('/sepet/sepetim');
                            }
                            this.closeModal();
                        } else {
                            return toast(<Toast text={'Doğrulama kodunuz yanlıştır.'}
                                                status="error"/>);
                        }
                    });
            }
        }
    };

    closeModal = () => {
        const { setModal, boundSetIsUpdateUsersData, boundSetIsNewAddress, boundSetChangedAddress, boundSetIsNewUser, boundSetIsCheckout } = this.props;
        boundSetIsCheckout({
            isCheckout: false
        });
        boundSetIsNewUser({
            isNewUser: false
        });
        boundSetIsUpdateUsersData({
            isUpdateUsersData: false
        });
        boundSetIsNewAddress({
            isNewAddress: false
        });
        boundSetChangedAddress({
            changedAddress: {
                isChangedAddress: false,
                changedAddressId: '',
                changedAddressName: ''
            }
        });
        setModal('');
    };

    render() {
        const { smsCode } = this.state;
        const { messageText, modalHeading } = this.props;

        return (
            <div className="modal-content phone-confirmation-modal">
                <div className="modal-content__header phone-confirmation-modal__header">
                    Telefon Doğrulaması
                    <Icon name="delete" onClick={this.closeModal}
                          className="phone-confirmation-modal__close-modal-icon"/>
                </div>

                <div className="phone-confirmation-modal__input-block">
                    <div className="phone-confirmation-modal__input-row">
                        <div className="phone-confirmation-modal__label">
                            Little Caesars tarafından gönderilen SMS onay kodunu giriniz.
                        </div>
                        <TextInput
                            value={smsCode}
                            onChange={this.smsCodeHandler}
                            className="phone-confirmation-modal__input"
                            maxLength={6}
                            error={this.validator.message('sms kodunu', smsCode, 'required')}
                        />
                    </div>
                </div>
                <div className="phone-confirmation-modal__buttons-block">
                    <Button color="light_green"
                            className="phone-confirmation-modal__button"
                            onClick={this.submit}
                    >
                        Onayla
                    </Button>
                    <Button color="white"
                            className="phone-confirmation-modal__button"
                            onClick={this.closeModal}
                    >
                        İptal
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isUpdateUsersData: store.user.isUpdateUsersData,
    isNewUser: store.user.isNewUser,
    isCheckout: store.user.isCheckout,
    user: store.user.user,
    isNewAddress: store.addressData.isNewAddress,
    changedAddress: store.addressData.changedAddress,
    basket: store.basket.basket,
    redirect: store.modal.redirect,
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    boundCheckPhoneСonfirmedSmsCode: bindActionCreators(checkPhoneСonfirmedSmsCode, dispatch),
    boundSetIsUpdateUsersData: bindActionCreators(setIsUpdateUsersData, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundCreateUserDeliveryAddress: bindActionCreators(createUserDeliveryAddress, dispatch),
    boundGetUserDeliveryAddresses: bindActionCreators(getUserDeliveryAddresses, dispatch),
    boundChangeDeliveryType: bindActionCreators(changeDeliveryType, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundChangeDeliveryAddress: bindActionCreators(changeDeliveryAddress, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundSetIsNewAddress: bindActionCreators(setIsNewAddress, dispatch),
    boundSetChangedAddress: bindActionCreators(setChangedAddress, dispatch),
    boundSetIsNewUser: bindActionCreators(setIsNewUser, dispatch),
    boundSetIsCheckout: bindActionCreators(setIsCheckout, dispatch)
});

SmsCodeConfirmationPhoneModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    boundCheckPhoneСonfirmedSmsCode: PropTypes.func.isRequired,
    boundSetIsUpdateUsersData: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundCreateUserDeliveryAddress: PropTypes.func.isRequired,
    boundGetUserDeliveryAddresses: PropTypes.func.isRequired,
    basket: PropTypes.object.isRequired,
    redirect: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    boundChangeDeliveryType: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundChangeDeliveryAddress: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetIsNewAddress: PropTypes.func.isRequired,
    boundSetChangedAddress: PropTypes.func.isRequired,
    boundSetIsNewUser: PropTypes.func.isRequired,
    boundSetIsCheckout: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmsCodeConfirmationPhoneModal));
