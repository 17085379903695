import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../elements/Icon';
import Button from '../../elements/Button';
import Popup from '../../elements/Popup';

const DeleteCreditCardPopup = props => {
    const { close, submit } = props;

    return <Popup>
        <Icon name="info" className="popup__icon"/>
        <div className="popup__header">Kartınızı silmek istediğinizden emin misiniz?</div>
        <Button className="popup__submit-button" onClick={submit} dontDisable>
            Evet
        </Button>
        <div>
            <span className="link cancel-button" onClick={close}>
                İptal
            </span>
        </div>
    </Popup>;
};

DeleteCreditCardPopup.propTypes = {
    submit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
};

export default DeleteCreditCardPopup;
