import React from 'react';
import CustomerServiceMenu from '../customer-service-menu/CustomerServiceMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';

const PersonalDataPrivacy = () => {
    sendPageView('/musteri-hizmetleri/kisisel-verilerin-korunmasi', 'Kişisel Verilerin Korunması - Little Caesars'); //for ga
    return <section className="institutional-structures">
        <div className="container">
            <BreadCrumbs/>
            <div className="institutional-structures-detail  bg-white2 ">
                <div className="institutional-structures-detail-content clearfix">
                    <CustomerServiceMenu/>
                    <div className="col-md-9">
                        <div className="corpareta-detail">
                            <h1>ÇELEBİ HİZMET GIDA İŞLETMELERİ TURİZM SAN. ve TİC. A.Ş KİŞİSEL VERİLERİN KORUNMASI
                                KANUNU AYDINLATMA METNİ</h1>
                            <p>Kişisel verilerinizin hukuka uygun olarak toplanması, saklanması ve paylaşılmasını
                                sağlamak ve gizliliğinizi korumak amacıyla m&uuml;mk&uuml;n olan en &uuml;st seviyede
                                g&uuml;venlik tedbirlerini almaktadır.</p>
                            <p>Amacımız;&nbsp;6698 sayılı Kişisel Verilerin Korunması Kanununun 10. maddesi gereğince ve
                                sizlerin memnuniyeti doğrultusunda, kişisel verilerinizin alınma şekilleri, işlenme
                                ama&ccedil;ları, paylaşılan kişiler, hukuki nedenleri ve haklarınız konularında sizi en
                                şeffaf şekilde bilgilendirmektir.</p>
                            <ol>
                                <li><strong>a) Veri Sorumlusu </strong></li>
                            </ol>
                            <p>6698 sayılı Kişisel Verilerin Korunması Kanunu (&ldquo;6698 sayılı Kanun&rdquo;)
                                uyarınca, kişisel verileriniz; veri sorumlusu olarak <strong>&Ccedil;elebi Hizmet Gıda
                                    İşletmeleri Turizm San. ve Tic. A.Ş&nbsp;</strong> (&ldquo;ŞİRKET&rdquo;) tarafından
                                aşağıda a&ccedil;ıklanan kapsamda toplanmakta ve işlenmektedir.</p>
                            <ol>
                                <li><strong>b) Kişisel Verilerin Hangi Ama&ccedil;la İşleneceği</strong></li>
                            </ol>
                            <p>ŞİRKET tarafından, m&uuml;şterileri, &ccedil;alışanları, potansiyel
                                m&uuml;şterileri, &ccedil;alışan adayları, resm&icirc; kurum temsilcileri
                                ve &ccedil;alışanları, iş ortakları ve tedarik&ccedil;ilerinden;</p>
                            <p>Kimlik bilgisi, iletişim bilgisi, m&uuml;şteri bilgisi, m&uuml;şteri işlem bilgisi,
                                sağlık bilgisi, performans bilgisi, işlem g&uuml;venliği bilgisi, hukuki işlem ve uyum
                                bilgisi ile pazarlama satış bilgisi kategorilerinde &ouml;zel nitelikli kişisel veri ve
                                kişisel veri (İkisi birlikte &ldquo;Kişisel Veri&rdquo; olarak anılacaktır.)
                                toplanmaktadır.</p>
                            <p>Toplanan kişisel verileriniz;</p>
                            <p>- ŞİRKET &uuml;r&uuml;n ve hizmetlerinin sizlere sunulabilmesi, sizlere karşı olan
                                y&uuml;k&uuml;ml&uuml;l&uuml;klerimizin yerine getirilmesi, kayıt ve belgelerin
                                d&uuml;zenlenebilmesi, yerel ve uluslararası yasal
                                mevzuatın &ouml;ng&ouml;rd&uuml;ğ&uuml; bilgi saklama, raporlama, bilgilendirme, vergi
                                ve sair y&uuml;k&uuml;ml&uuml;l&uuml;klere uymak,</p>
                            <p>- Hizmet ve &uuml;r&uuml;nlerin kalitesinin artırılmasına y&ouml;nelik yapılacak olan
                                satış ve pazarlama faaliyetleri i&ccedil;in yapılacak size &ouml;zel reklam, kampanya,
                                avantajlar ve faydalar sunmak,</p>
                            <p>- Bilgi işlem gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek hizmetlerinin
                                gerekliliği, bu hizmet ve &uuml;r&uuml;nlere ilişkin olarak sizlere gerekli bilgilerin
                                aktarılması amacıyla iletişim kurmak,</p>
                            <p>- Satış ve pazarlama faaliyetleri i&ccedil;in yapılacak
                                trafik &ouml;l&ccedil;&uuml;mleme, istatistiki analizler, Segmentasyon/profilleme ve
                                CRM &ccedil;alışmalarını y&uuml;r&uuml;tmek,</p>
                            <p>- M&uuml;şteri memnuniyetinin &ouml;l&ccedil;&uuml;mlenmesi ve artırılması, şik&acirc;yet
                                y&ouml;netimi, yeni hizmet ve &uuml;r&uuml;nler ile ilgili g&ouml;r&uuml;ş
                                ve &ouml;nerilerinizi almak, sorun-hata bildirimlerinizi almak, &uuml;r&uuml;n ve
                                hizmetlere, şikayet ve taleplerinize y&ouml;nelik tarafınıza bilgi vermek,</p>
                            <p>- Tarafınızla iletişime ge&ccedil;ilmesi, siparişlerinizi almak, &ouml;deme işlemlerinizi
                                ger&ccedil;ekleştirmek, 3. Kişiler ile lojistik iş birliği sağlayıp &uuml;r&uuml;n
                                g&ouml;nderimini sağlamak, ilginizi &ccedil;ekebilecek &uuml;r&uuml;n ve hizmetleri
                                tespit edip bu doğrultuda size &ouml;neriler yapmak, promosyon ve hediye g&ouml;nderimi
                                yapmak, &ccedil;evrimi&ccedil;i davranışlarınızı analiz ederek size &ouml;zel reklamlar
                                g&ouml;stermek, size sağladığımız hizmetin kalitesinin &ouml;l&ccedil;&uuml;lmesi ve
                                geliştirilmesi, tarafımızca belirlenen segmentlere g&ouml;re profilleme ve skorlama
                                yapılacak m&uuml;şteri portf&ouml;y&uuml;m&uuml;z&uuml;n y&ouml;netilmesi,</p>
                            <p>- Karşılaştırmalı &uuml;r&uuml;n ve/veya hizmet teklifi, modelleme, mevcut veya
                                yeni &uuml;r&uuml;n &ccedil;alışmaları ve/veya geliştirmeleri, kişisel verilerinizi
                                ŞİRKET&rsquo;e a&ccedil;ıklamanıza konu olan ŞİRKET ana s&ouml;zleşmesinde yazılı olan
                                işleri d&uuml;zenleyen kanun ve ilgili mevzuat kapsamında sizlere sunulacak her
                                t&uuml;rl&uuml; &uuml;r&uuml;n ve hizmetlerde kullanılmak,</p>
                            <p>- Resm&icirc; kurumlarca &ouml;ng&ouml;r&uuml;len bilgi saklama, raporlama, bilgilendirme
                                y&uuml;k&uuml;ml&uuml;l&uuml;klerine uymak, s&ouml;zleşmelerin gerekliliklerini yerine
                                getirmek ve bu hizmetlerden faydalanılmasına ilişkin olarak ŞİRKET&rsquo;in tabi olduğu
                                yasal y&uuml;k&uuml;ml&uuml;l&uuml;kleri ifa etmek,</p>
                            <p>- ŞİRKET&rsquo;in ticari ve iş stratejilerinin belirlenmesi ve uygulanması amacı
                                doğrultusunda; ŞİRKET tarafından y&uuml;r&uuml;t&uuml;len finans operasyonları,
                                iletişim, pazar araştırması ve sosyal sorumluluk aktiviteleri, satın alma operasyonları
                                (talep, teklif, değerlendirme, sipariş, b&uuml;t&ccedil;elendirme, s&ouml;zleşme),
                                şirket i&ccedil;i sistem ve uygulama y&ouml;netimi operasyonları, hukuki operasyonları
                                y&ouml;netmek</p>
                            <p>- Resmi makamlardan veya sizlerden gelen talepleri incelemek, değerlendirmek ve
                                yanıtlamak,</p>
                            <p>ama&ccedil;larıyla işlenmektedir.</p>
                            <ol>
                                <li><strong>c) İşlenen Kişisel Verilerin Kimlere ve Hangi Ama&ccedil;la
                                    Aktarılabileceği</strong></li>
                            </ol>
                            <p>Toplanan kişisel verileriniz; yukarıda yer alan b) b&ouml;l&uuml;m&uuml;nde belirtilen
                                işleme ama&ccedil;larının ger&ccedil;ekleştirilmesi ile sınırlı ve tamamı i&ccedil;in
                                ge&ccedil;erli olmak &uuml;zere;</p>
                            <p>- ŞİRKET&rsquo;in iş ortaklarına, hissedarlarına, iştiraklerine,</p>
                            <p>- Vergi Usul Kanunu, Sosyal G&uuml;venlik Kurumu mevzuatı, Sayıştay,
                                Su&ccedil; Gelirlerinin Aklanmasının &Ouml;nlenmesi Hakkında Kanun, Karaparanın
                                Aklanmasının &Ouml;nlenmesine Dair Kanun, T&uuml;rk Ticaret Kanunu, Bor&ccedil;lar
                                Kanunu ve diğer mevzuat h&uuml;k&uuml;mlerinin izin verdiği kişi veya kuruluşlara,</p>
                            <p>- Kanunen yetkili kamu kurum ve kuruluşları, idari merciler ve yasal mercilere,</p>
                            <p>- Yurt dışı şirketlerine ve iştiraklerine,</p>
                            <p>- &Uuml;r&uuml;n/hizmet karşılaştırma, analiz, değerlendirme, reklam ve yukarıda
                                belirtilen ama&ccedil;ların ger&ccedil;ekleştirilmesinde hizmet aldığımız, işbirliği
                                yaptığımız ger&ccedil;ek veya t&uuml;zel kişilere, program ortağı kurum ve kuruluşlara,
                                m&uuml;şterilerimize g&ouml;nderdiğimiz iletilerin g&ouml;nderilmesi konusunda anlaşmalı
                                olduğumuz kurumlara, verilen siparişlerin size teslimini ger&ccedil;ekleştiren kargo
                                şirketlerine aktarılmaktadır.</p>
                            <p><strong>&ccedil;) Kişisel Veri Toplamanın Y&ouml;ntemi ve Hukuki Sebebi</strong></p>
                            <p>Kişisel Verileriniz, hem tamamen veya kısmen otomatik yollarla hem de veri kayıt
                                sisteminin par&ccedil;ası olan ancak otomatik olmayan y&ouml;ntemlerle;</p>
                            <p>Doğrudan sizin tarafınızdan veya sizin adınıza hareket etmeye yetkili kişiler tarafından;
                                anlaşmalı internet siteleri &uuml;zerinden yapılmış olan başvurular, mağaza ve ŞİRKET
                                genel m&uuml;d&uuml;rl&uuml;k personeli, destek hizmeti verdiğimiz / aldığımız kurumlar
                                ile her t&uuml;rl&uuml; mevzuat veya s&ouml;zleşme dahilinde işlem yapılan ger&ccedil;ek
                                ve / veya t&uuml;zel kişiler ve doğrudan otomatik sistemler (İnternet sitemiz ve mobil
                                uygulamamız, &ccedil;ağrı merkezlerimiz, sosyal medya hesaplarımız) ile s&ouml;zl&uuml;,
                                yazılı veya elektronik ortamlarda;</p>
                            <p>Kanun&rsquo;un 5. ve 6. maddesinde belirtilmiş olan;</p>
                            <ul>
                                <li>Kanunlarda a&ccedil;ık&ccedil;a &ouml;ng&ouml;r&uuml;lm&uuml;ş olması,</li>
                                <li>Şirketimizin hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; yerine getirebilmek
                                    i&ccedil;in zorunlu olması,
                                </li>
                                <li>S&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olup işlemenin
                                    gerekli olması,
                                </li>
                                <li>Temel hak ve &ouml;zg&uuml;rl&uuml;klerinize zarar vermemek kaydıyla, Şirketimizin
                                    meşru menfaati i&ccedil;in işlemenin zorunlu olması,
                                </li>
                                <li>Tarafınızca alenileştirilmiş olması,</li>
                                <li>Bir hakkın tesisi, kullanılması veya korunması i&ccedil;in işlemenin zorunlu
                                    olması,
                                </li>
                                <li>A&ccedil;ık rızanızın bulunması</li>
                            </ul>
                            <p>Şeklindeki hukuki sebeplere dayanarak toplamaktadır.</p>
                            <ol start="11">
                                <li><strong>d) Kişisel Veri Sahibinin 6698 sayılı Kanun&rsquo;un 11. Maddesinde Sayılan
                                    Hakları</strong></li>
                            </ol>
                            <p>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi aşağıda d&uuml;zenlenen
                                y&ouml;ntemlerle iletmeniz durumunda ŞİRKET talebin niteliğine g&ouml;re talebi en kısa
                                s&uuml;rede ve en ge&ccedil; otuz g&uuml;n i&ccedil;inde sonu&ccedil;landıracaktır.
                                Verilecek cevapta on sayfaya kadar &uuml;cret alınmayacaktır. On
                                sayfanın &uuml;zerindeki her sayfa i&ccedil;in 1 T&uuml;rk Lirası işlem &uuml;creti
                                alınacaktır. Başvuruya cevabın CD,&nbsp;flash&nbsp;bellek gibi bir kayıt ortamında
                                verilmesi halinde şirketimiz tarafından talep edilebilecek &uuml;cret, kayıt ortamının
                                maliyetini ge&ccedil;meyecektir.</p>
                            <p>Bu kapsamda kişisel veri sahipleri;</p>
                            <ul>
                                <li>Kişisel veri işlenip işlenmediğini &ouml;ğrenme,</li>
                                <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                                <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
                                    kullanılmadığını &ouml;ğrenme,
                                </li>
                                <li>Yurt i&ccedil;inde veya yurt dışında kişisel verilerin
                                    aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,
                                </li>
                                <li>Kişisel verilerin eksik veya yanlış işlenmiş olması h&acirc;linde bunların
                                    d&uuml;zeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin
                                    aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,
                                </li>
                                <li>6698 sayılı Kanun ve ilgili diğer kanun h&uuml;k&uuml;mlerine uygun olarak işlenmiş
                                    olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel
                                    verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin
                                    kişisel verilerin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini
                                    isteme,
                                </li>
                                <li>İşlenen verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi
                                    suretiyle kişinin kendisi aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz etme,
                                </li>
                                <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde
                                    zararın giderilmesini talep etme haklarına sahiptir.
                                </li>
                            </ul>
                            <p>Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, 6698 sayılı
                                Kanunu&rsquo;nun 13. maddesinin 1. fıkrası ve 30356 sayılı ve 10.03.2018 tarihli Veri
                                Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ gereğince T&uuml;rk&ccedil;e ve
                                yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, g&uuml;venli elektronik imza,
                                mobil imza ya da ŞİRKET&rsquo;e daha &ouml;nce bildirilen ve sistemimizde kayıtlı
                                bulunan elektronik posta adresini kullanmak suretiyle iletebilirsiniz. Başvurularda
                                sadece başvuru sahibi kişi hakkında bilgi verilecek olup diğer aile fertleri
                                ve &uuml;&ccedil;&uuml;nc&uuml; kişiler hakkında bilgi alınması m&uuml;mk&uuml;n
                                olmayacaktır. ŞİRKET&rsquo;in cevap vermeden &ouml;nce kimliğinizi doğrulama hakkı
                                saklıdır.</p>
                            <p>Başvurunuzda;</p>
                            <ol>
                                <li>Adınızın,&nbsp;soyadınızın&nbsp;ve başvuru yazılı ise imzanızın,</li>
                            </ol>
                            <ol>
                                <li>T&uuml;rkiye Cumhuriyeti vatandaşları i&ccedil;in T.C. kimlik numaranızın, yabancı
                                    iseniz uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,
                                </li>
                            </ol>
                            <ol>
                                <li>c) Tebligata esas yerleşim yeri veya iş yeri adresinizin,</li>
                            </ol>
                            <p>&ccedil;) Varsa bildirime esas elektronik posta adresi, telefon ve faks numaranızın,</p>
                            <ol>
                                <li>d) Talep konunuzun,</li>
                            </ol>
                            <p>bulunması&nbsp;zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de başvuruya
                                eklenmesi gerekmektedir.</p>
                            <p>Yazılı olarak yapmak istediğiniz başvurularınızı, gerekli belgeleri ekleyerek veri
                                sorumlusu olarak Şirketimizin Genel M&uuml;d&uuml;rl&uuml;k adresine verebilirsiniz.
                                Başvuru formuna <a
                                    href="https://www.littlecaesars.com.tr/images/kurumsal/KVKK_VERI_SAHIBI_BASVURU_FORMU.DOCX"
                                    target="_blank">buradan</a> erişebilirsiniz.</p>
                            <p>E-posta yoluyla yapmak istediğiniz başvurularınızı <a
                                href="mailto:lc.kvkk@celebi.com">lc.kvkk@celebi.com</a> e-posta adresine yapabilirsiniz.
                                KEP yoluyla yapmak istediğiniz başvurularınızı <a
                                    href="mailto:celebigida@hs02.kep.tr">celebigida@hs02.kep.tr</a> KEP adresimize
                                yapabilirsiniz.</p>
                            <p>Talebinizin niteliğine g&ouml;re kimlik tespitine olanak sağlayacak bilgi ve belgelerin
                                eksiksiz ve doğru olarak tarafımıza sağlanması gerekmektedir. İstenilen bilgi ve
                                belgelerin gereği gibi sağlanmaması durumunda, ŞİRKET tarafından talebinize istinaden
                                yapılacak araştırmaların tam ve nitelikli şekilde y&uuml;r&uuml;t&uuml;lmesinde
                                aksaklıklar yaşanabilecektir. Bu durumda, ŞİRKET kanuni haklarını saklı tuttuğunu beyan
                                eder. Bu nedenle, başvurunuzun talebinizin niteliğine g&ouml;re eksiksiz ve istenilen
                                bilgileri ve belgeleri i&ccedil;erecek şekilde g&ouml;nderilmesi gerekmektedir.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default PersonalDataPrivacy;
