import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    createUserDeliveryAddress, getAddressTypes, getCities, getTowns, getDistricts, getStreets, getUserDeliveryAddresses,
    setAddressSelection, changeDeliveryAddress, changeDeliveryType, setIsNewAddress
} from '../../../store/actions/addressActions';
import { addAddAddressAction } from '../../../googleAnalyticsHelper';
import { DELIVERY_ORDER_TYPE, doRedirect } from '../../../utils';
import { withRouter } from 'react-router-dom';
import { getBasket } from '../../../store/actions/basketActions';
import ChangeDeliveryTypePopup from '../../address-selection/ChangeDeliveryTypePopup';
import AddressForm from './AddressForm';
import { getCustomerPhoneId } from '../../../store/actions/authActions';
import { setModal } from '../../../store/actions/modalActions';
import { saveCustomerPhoneId } from '../../../api';

class AddAddressModal extends Component {
    state = {
        initialData: null,
        addressToChangeDeliveryType: null
    };

    componentDidMount() {
        const { boundGetAddressTypes, boundGetCities, newAddressData, boundCreateUserDeliveryAddress } = this.props;
        const initialData = {
            addressTypeList: [],
            cityList: [],
            addressTypeId: ''
        };

        boundGetAddressTypes()
            .then(response => {
                response.data.result.forEach((addressType, index) => {
                    initialData.addressTypeList.push({
                        label: addressType.Name,
                        value: addressType.Value
                    });
                    if (index === 1) {
                        initialData.addressTypeId = addressType.Value;
                    }
                });
                this.setState({ initialData: { ...initialData } });
            });

        boundGetCities()
            .then(response => {
                response.data.result.forEach(addressType => {
                    initialData.cityList.push({
                        label: addressType.name,
                        value: addressType.id
                    });
                });
                this.setState({ initialData: { ...initialData } });
            });
    }

    componentWillUnmount() {
        const { setRedirect } = this.props;
        setRedirect({});
    }

    addAddress = ({ name, addressTypeId, addressNo, apartmentNo, apartmentName, entrance, floorNo, directions, streetId, phone }) => {
        const { boundCreateUserDeliveryAddress, setModal, boundGetUserDeliveryAddresses, boundSetIsNewAddress, history, redirect } = this.props;

        boundCreateUserDeliveryAddress({
            extensionNumber: phone ? phone.substring(2) : '',
            address: {
                name: name,
                addressTypeId,
                streetAddressId: streetId,
                landmark: directions,
                addressNo: addressNo,
                entrance: entrance,
                floor: floorNo,
                appartmentNo: apartmentNo,
                appartmentName: apartmentName
            }
        })
            .then(response => {
                    if (response.data.resultCode === '1010') {
                        saveCustomerPhoneId(response.data.result);
                        boundSetIsNewAddress({
                            isNewAddress: true
                        });
                        setModal('phone-confirmation');
                    } else {
                        boundGetUserDeliveryAddresses()
                            .then(response => {
                                if (response.payload && response.payload.length) {
                                    const address = response.payload[response.payload.length - 1];
                                    this.selectAddress(address);
                                }
                            });
                        if (redirect) {
                            doRedirect(redirect, history, setModal);
                        }
                    }
                }
            )
            .catch(() => setModal(''))
            .finally(addAddAddressAction);
    };

    selectAddress = address => {
        const { addressData, basket } = this.props;
        if (addressData.addressSelection.type !== 'Adrese Teslim') {
            if (basket.orderItems.length) {
                this.setState({ addressToChangeDeliveryType: address });
            } else {
                this.getChangeDeliveryType(address)();
            }
        } else {
            this.changeDeliveryAddress(address);
        }
    };

    getChangeDeliveryType = address => () => {
        const { basket, boundChangeDeliveryType } = this.props;

        this.closeChangeDeliveryTypePopup();
        boundChangeDeliveryType(basket.id, DELIVERY_ORDER_TYPE, address.addressId)
            .then(() => this.changeDeliveryAddress(address));
    };

    closeChangeDeliveryTypePopup = () => this.setState({ addressToChangeDeliveryType: null });

    cancelChangeDeliveryType = () => {
        const { setModal } = this.props;
        this.closeChangeDeliveryTypePopup();
        setModal('');
    };

    changeDeliveryAddress = address => {
        const {
            basket, boundSetAddressSelection, boundChangeDeliveryAddress, boundGetBasket, location,
            history, setModal, redirect
        } = this.props;

        boundChangeDeliveryAddress(basket.id, address.addressId)
            .then(() => boundSetAddressSelection({
                type: 'Adrese Teslim',
                address: address.address.name,
                addressId: address.addressId,
                isUserAddress: true
            }))
            .catch(() =>
                boundSetAddressSelection({})
            )
            .then(() => boundGetBasket()
                .then(() => {
                    if (redirect) {
                        doRedirect(redirect, history, setModal);
                    } else if (!location.pathname.includes('/siparis') && !location.pathname.includes('/sepet/sepetim')) {
                        history.push('/siparis/kampanyalar');
                    }
                }))
            .finally(() => setModal(''));
    };

    render() {
        const { addressToChangeDeliveryType, initialData } = this.state;
        return <div className="modal-content address-modal">
            {
                addressToChangeDeliveryType
                    ? <ChangeDeliveryTypePopup submit={this.getChangeDeliveryType(addressToChangeDeliveryType)}
                                               close={this.cancelChangeDeliveryType}
                    />
                    : null
            }
            <div className="modal-content__header">
                Yeni Adres Tanımlama
            </div>
            <AddressForm initialData={initialData} submit={this.addAddress}/>
        </div>;
    }
}

const mapStateToProps = store => ({
    addressData: store.addressData,
    newAddressData: store.addressData.newAddressData,
    basket: store.basket.basket,
    redirect: store.modal.redirect
});

const mapDispatchToProps = dispatch => ({
    boundCreateUserDeliveryAddress: bindActionCreators(createUserDeliveryAddress, dispatch),
    boundGetUserDeliveryAddresses: bindActionCreators(getUserDeliveryAddresses, dispatch),
    boundGetAddressTypes: bindActionCreators(getAddressTypes, dispatch),
    boundGetCities: bindActionCreators(getCities, dispatch),
    boundGetTowns: bindActionCreators(getTowns, dispatch),
    boundGetDistricts: bindActionCreators(getDistricts, dispatch),
    boundGetStreets: bindActionCreators(getStreets, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundChangeDeliveryAddress: bindActionCreators(changeDeliveryAddress, dispatch),
    boundChangeDeliveryType: bindActionCreators(changeDeliveryType, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundGetCustomerPhoneId: bindActionCreators(getCustomerPhoneId, dispatch),
    boundSetIsNewAddress: bindActionCreators(setIsNewAddress, dispatch)
});

AddAddressModal.propTypes = {
    setRedirect: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired,
    boundCreateUserDeliveryAddress: PropTypes.func.isRequired,
    boundGetUserDeliveryAddresses: PropTypes.func.isRequired,
    boundGetAddressTypes: PropTypes.func.isRequired,
    boundGetCities: PropTypes.func.isRequired,
    boundGetTowns: PropTypes.func.isRequired,
    boundGetDistricts: PropTypes.func.isRequired,
    boundGetStreets: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundChangeDeliveryAddress: PropTypes.func.isRequired,
    boundChangeDeliveryType: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    redirect: PropTypes.object.isRequired,
    boundGetCustomerPhoneId: PropTypes.func.isRequired,
    boundSetIsNewAddress: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddAddressModal));
