import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoyaltySlices from './LoyaltySlices';
import { bindActionCreators } from 'redux';
import { getProductDetails } from '../../store/actions/productActions';
import { Link } from 'react-router-dom';
import { setModal } from '../../store/actions/modalActions';

class LoyaltyPreview extends Component {
    state = {
        bonusProduct: {}
    };

    componentDidMount() {
        this.getProductDetails();
    }

    getProductDetails = () => {
        const { boundGetProductDetails, user, basket } = this.props;
        if (
            user.loyalty && user.loyalty.punchCard
            && user.loyalty.punchCard.canGetGift === 'true'
            && user.loyalty.punchCard.canOrderGiftProductID
            && user.loyalty.punchCard.canOrderGiftProductID !== '0'
        ) {
            boundGetProductDetails(user.loyalty.punchCard.canOrderGiftProductID, basket.id)
                .then(response => this.setState({ bonusProduct: response.data.result }));
        }
    };

    closeModal = () => this.props.boundSetModal('');

    renderSubheaderText = () => {
        const { user } = this.props;
        const { bonusProduct } = this.state;
        if (user.loyalty.punchCard.canGetGift === 'true') {
            return <>Tebrikler! <span className="loyalty-preview__subheader-orange-text">{bonusProduct.name || 'yükleniyor...'}</span> kazandın.</>;
        }
        return <><span className="loyalty-preview__subheader-orange-text">{+user.loyalty.punchCard.requiredToGetNewGift} Dilim</span> {user.loyalty.punchCard.requiredToGetNewGift != user.loyalty.punchCard.requiredToGetGift?'daha ':null} topla ücretsiz orta boy klasik pizza kazan.</>;
    };

    render() {
        const { user } = this.props;
        return <Link to="/sezarla-kazan" className="loyalty-preview" onClick={this.closeModal}>
            <div className="loyalty-preview__remain-slices-block">
                <LoyaltySlices user={user}/>
                <div>
                    <img src="/images/loyalty-header.png" alt="loyalty-header" className="loyalty-preview__header"/>
                    <div className="loyalty-preview__subheader">
                        {this.renderSubheaderText()}
                    </div>
                </div>
            </div>
        </Link>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    basket: store.basket.basket
});

const mapDispatchToProps = dispatch => ({
    boundGetProductDetails: bindActionCreators(getProductDetails, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

LoyaltyPreview.propTypes = {
    user: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    boundGetProductDetails: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyPreview);
