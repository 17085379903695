import Validator from "simple-react-validator";

export default (component, options = {}) => {
    Validator.addLocale('tr', {
        accepted             : ':attribute onaylanmalıdır.',
        after                : ':attribute :date tarihinden sonra olmalıdır.',
        after_or_equal       : ':attribute :date tarihinde veya sonra olmalıdır.',
        alpha                : ':attribute sadece harf içerebilir.',
        alpha_space          : ':attribute sadece harf, ve boşluk karakteri içerebilir.',
        alpha_num            : ':attribute sadece harf, ve rakam içerebilir.',
        alpha_num_space      : ':attribute sadece harf, rakam, ve boşluk karakteri içerebilir.',
        alpha_num_dash       : ':attribute sadece harf, rakam, ve tire karakteri içerebilir.',
        alpha_num_dash_space : ':attribute sadece harf, rakam, tire, ve boşluk karakteri içerebilir.',
        array                : ':attribute dizi tipinde olmalıdır.',
        before               : ':attribute :date tarihinden önce olmalıdır.',
        before_or_equal      : ':attribute :date tarihinde veya daha önce olmalıdır.',
        between              : ':attribute  :min - :max:type aralığında olmalıdır.',
        boolean              : ':attribute geçerli bir mantıksal değer olmalıdır.',
        card_exp             : ':attribute geçerli bir sonlanma tarihi olmalıdır.',
        card_num             : ':attribute geçerli bir kredi kartı numarası olmalıdır.',
        currency             : ':attribute geçerli bir para tipi olmalıdır.',
        date                 : ':attribute tarih formatında olmalıdır.',
        date_equals          : ':attribute :date tarihli olmalıdır.',
        email                : 'Lütfen geçerli bir :attribute girin.',
        in                   : 'Seçilen :attribute :values olmalıdır.',
        integer              : ':attribute sayı olmalıdır.',
        max                  : ':attribute :max:type dan büyük olamaz.',
        min                  : ':attribute en az :min:type olmalıdır.',
        not_in               : 'Seçilen :attribute :values olamaz.',
        not_regex            : ':attribute gerekli formattan farklı olmalıdır.',
        numeric              : ':attribute sayı olmalıdır.',
        phone                : ':attribute geçerli bir telefon numarası olmalıdır.',
        regex                : ':attribute gerekli formatla uyumlu olmalıdır.',
        required             : ':attribute alanı zorunludur.',
        size                 : ':attribute :size:type tipinde olmalıdır.',
        string               : ':attribute karakter dizisi olmalıdır.',
        typeof               : ':attribute :type tipinde olmalıdır.',
        url                  : ':attribute url olmalıdır.',
    });

    const validators = {
        turkish_phone_number: {
            message: ':attribute 12 karakter içermelidir.',
            rule: value => value.length === 12 // Because of mask
        },
        password_comparing: {
            message: ':attribute :comparing_field ile eşleşmelidir',
            rule: (value, params) => value === params[0],
            messageReplace: (message, params) => {
                message = message.replace(':value', params[0]);
                message = message.replace(':comparing_field', params[1]);
                return message;
            }
        },
        terms_of_use: {
            message: ':attribute alanı zorunludur.',
            rule: value => value
        },
        apartment_number: {
            message: ':attribute ALANI ZORUNLUDUR. YOK İSE 0 GİRİNİZ.',
            rule: value => !!value,
            required: true
        },
    };

    return new Validator({ autoForceUpdate: component, locale: 'tr', validators, ...options });
}
