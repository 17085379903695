import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../elements/TextInput';
import DropdownList from '../../elements/DropdownList';
import { connect } from 'react-redux';
import getValidator from '../../../getValidator';
import Button from '../../elements/Button';
import { monthList, yearList } from './MonthAndYearLists';
import { bindActionCreators } from 'redux';
import { addCreditCard } from '../../../store/actions/memberActions';
import { setModal } from '../../../store/actions/modalActions';
import { maskCreditCardNumber, numberRegExp, removeAllSpaces } from '../../../utils';
import { Link } from 'react-router-dom';
import Checkbox from '../../elements/Checkbox';

class AddCreditCardModal extends Component {
    validator = getValidator(this);

    state = {
        creditCardName: '',
        creditCardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        CVC: '',
        termsOfUseMasterpass: false
    };

    creditCardNameInputHandler = event =>
        this.setState({ creditCardName: event.target.value });

    cvcInputHandler = event => {
        const CVC = event.target.value;
        if (CVC === '' || numberRegExp.test(CVC)) {
            this.setState({ CVC });
        }
    };

    creditCardNumberInputHandler = event => {
        const creditCardNumber = removeAllSpaces(event.target.value);
        if (creditCardNumber === '' || numberRegExp.test(creditCardNumber)) {
            this.setState({ creditCardNumber });
        }
    };

    selectMonth = month => {
        this.setState({ expirationMonth: month });
    };

    selectYear = year => {
        this.setState({ expirationYear: year });
    };

    termsOfUseMasterpassHandler = () => {
        this.setState(prevState => ({
            termsOfUseMasterpass: !prevState.termsOfUseMasterpass
        }));
    };

    submit = () => {
        const { boundAddCreditCard, boundSetModal, phoneNumber } = this.props;
        const { creditCardName, creditCardNumber, expirationMonth, expirationYear, CVC } = this.state;

        this.validator.showMessages();
        if (this.validator.allValid()) {
            const creditCardNo = creditCardNumber;
            const expirationDate = expirationYear + (expirationMonth < 10 ? `0${expirationMonth}` : expirationMonth);
            boundAddCreditCard(creditCardName, creditCardNo, expirationDate, CVC, phoneNumber, boundSetModal);
        }
    };

    render() {
        const { creditCardName, creditCardNumber, expirationMonth, expirationYear, CVC, termsOfUseMasterpass } = this.state;
        return (
            <div className="modal-content credit-card-modal">
                <div className="modal-content__header">
                    Yeni Kart Ekle
                </div>
                <div className="credit-card-modal__input-block">

                    <div className="credit-card-modal__input-row">
                        <TextInput
                            label="Kart Adı"
                            labelClassName="credit-card-modal__label"
                            value={creditCardName}
                            onChange={this.creditCardNameInputHandler}
                            className="credit-card-modal__input"
                            error={this.validator.message('Kartınıza İsim ', creditCardName, 'required')}
                        />
                    </div>

                    <div className="credit-card-modal__input-row">
                        <TextInput
                            label="Kredi Kartı Numarası"
                            labelClassName="credit-card-modal__label"
                            pattern="\d*"
                            value={maskCreditCardNumber(creditCardNumber)}
                            onChange={this.creditCardNumberInputHandler}
                            className="credit-card-modal__input"
                            maxLength={19}  // 16 + 3 because of mask
                            error={this.validator.message('Kart Numarası ', creditCardNumber, 'required')}
                            autocomplete="cc-number"
                        />
                    </div>

                    <div className="credit-card-modal__input-row-container">
                        <div className="credit-card-modal__date-inputs-container">
                            <label className="credit-card-modal__label">
                                Son Kullanma Tarihi
                            </label>
                            <div className="credit-card-modal__input-row-container">
                                <DropdownList
                                    value={expirationMonth}
                                    placeholder="Ay"
                                    onChange={this.selectMonth}
                                    className="credit-card-modal__dropdown-body"
                                    containerClassName="credit-card-modal__dropdown"
                                    error={this.validator.message('Ay', expirationMonth, 'required')}
                                    options={monthList}
                                    optionClassName="credit-card-modal__dropdown-option"
                                    autocomplete="cc-exp-month"
                                />

                                <DropdownList
                                    value={expirationYear}
                                    placeholder="Yıl"
                                    onChange={this.selectYear}
                                    className="credit-card-modal__dropdown-body"
                                    containerClassName="credit-card-modal__dropdown"
                                    error={this.validator.message('Yıl', expirationYear, 'required')}
                                    options={yearList}
                                    optionClassName="credit-card-modal__dropdown-option"
                                    autocomplete="cc-exp-year"
                                />
                            </div>
                        </div>
                        <div className="credit-card-modal__cvc">
                            <TextInput
                                label="CVC"
                                labelClassName="credit-card-modal__label"
                                type="number"
                                value={CVC}
                                onChange={this.cvcInputHandler}
                                className="credit-card-modal__input"
                                maxLength={3}
                                error={this.validator.message('cvc', CVC, 'required')}
                                autocomplete="cc-csc"
                            />
                        </div>
                    </div>
                </div>

                <div className="credit-card-modal__terms-of-use">
                    <Checkbox value={termsOfUseMasterpass}
                              onChange={this.termsOfUseMasterpassHandler}
                              label={
                                  <div>
                                      <a

                                          href="https://www.masterpassturkiye.com/TermsAndConditions.aspx"
                                          target="_blank">
                                          Kullanım koşullarını
                                      </a>
                                      okudum, kabul ediyorum
                                  </div>
                              }
                              activeColor="yellow"
                              type="radio"
                              error={this.validator.message('Kullanım koşullarını', termsOfUseMasterpass, 'terms_of_use')}
                    />
                </div>

                <div className="credit-card-modal__masterpass">
                    <span className="credit-card-modal__masterpass-information">
                        Kredi kartı bilgilerniz MasterCard altyapısında tutulmaktadır.
                    </span>
                    <div className="credit-card-modal__masterpass-information">
                        <img src="/images/bank-cards/by-masterpass-logo.png" alt="by-masterpass-logo.png"/>
                    </div>
                </div>

                <Button color="light_green" className="credit-card-modal__submit-button"
                        onClick={this.submit}>
                    Kaydet
                </Button>

            </div>
        );
    }
}

const mapStateToProps = store => ({
    phoneNumber: `90${store.user.user.personPhones[0].number}`
});

const mapDispatchToProps = dispatch => ({
    boundAddCreditCard: bindActionCreators(addCreditCard, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

AddCreditCardModal.propTypes = {
    boundAddCreditCard: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditCardModal);