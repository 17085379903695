import React from 'react';
import PropTypes from 'prop-types';
import { formatErrorMessage } from '../../utils';

const Checkbox = props => {
    const {
        onChange, name, value, error, disabled, label, className, activeColor, labelClassName, keyValue, type
    } = props;

    const getClassName = () => {
        let resultClassName = 'icon-with-label lc-checkbox';
        if (error) {
            resultClassName += ' lc-checkbox--error';
        }
        if (disabled) {
            resultClassName += ' lc-checkbox--disabled';
        }
        if (value) {
            resultClassName += ' lc-checkbox--active';
        }
        if (activeColor) {
            resultClassName += ` lc-checkbox--${activeColor}`;
        }
        if (className) {
            resultClassName += ` ${className}`;
        }
        if (type) {
            resultClassName += ` lc-checkbox--${type}`;
        }
        return resultClassName;
    };

    const getLabelClassName = () => {
        let resultLabelClassName = 'lc-checkbox__label';

        if (labelClassName) {
            resultLabelClassName += ` ${labelClassName}`;
        }

        return resultLabelClassName;
    };

    return (
        <div className={getClassName()} onClick={onChange} key={keyValue}>
            <input type="checkbox"
                   className="lc-checkbox__body"
                   name={name}
                   value={value}
                   checked={value}
                   readOnly
            />
            {
                type === 'switch'
                    ? <div className="lc-checkbox__circle"/>
                    : null
            }
            {
                typeof label === 'object'
                    ? label
                    : <label className={getLabelClassName()}>{label}</label>
            }
            {
                error
                    ? <div className="help-text help-text--error">{formatErrorMessage(error)}</div>
                    : null
            }
        </div>
    );
};

Checkbox.defaultProps = {
    keyValue: null
};

Checkbox.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool.isRequired,
    error: PropTypes.any,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    activeColor: PropTypes.string,
    labelClassName: PropTypes.string,
    keyValue: PropTypes.any
};

export default Checkbox;
