import React from 'react';

const CreditCard = props =>
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="20" height="16" fill="#383838" />
        <rect x="-108" y="-504" width="1366" height="1341" fill="#F8F8F8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17 15H3C1.896 15 1 14.104 1 13V3C1 1.896 1.896 1 3 1H17C18.104 1 19 1.896 19 3V13C19 14.104 18.104 15 17 15Z" stroke="black" strokeWidth="1.5" />
        <path d="M1 5H19" stroke="black" strokeWidth="1.5" />
        <path d="M4 11H8" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14 9C12.896 9 12 9.896 12 11C12 12.104 12.896 13 14 13C15.104 13 16 12.104 16 11C16 9.896 15.104 9 14 9ZM14 10C14.552 10 15 10.449 15 11C15 11.552 14.552 12 14 12C13.448 12 13 11.552 13 11C13 10.449 13.448 10 14 10Z" fill="black" />
    </svg>;

export default CreditCard;
