import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    changeDeliveryType,
    getCities,
    getRestaurants,
    setAddressSelection,
    setRestaurants
} from '../../store/actions/addressActions';
import {
    DEFAULT_CENTER, GOOGLE_MAPS_API_KEY, VEHICLE_DELIVERY_ORDER_TYPE, getRestaurantWorkingTimes, DEFAULT_ZOOM
} from '../../utils';
import { getBasket } from '../../store/actions/basketActions';
import TextInput from '../elements/TextInput';
import LcDropdown from '../elements/LcDropdown';
import Icon from '../elements/Icon';
import Restaurant from '../restaurants/Restaurant';
import { setLocalStorage } from '../../storage';
import MarkerClusterer from '@google/markerclusterer';
import ChangeDeliveryTypePopup from './ChangeDeliveryTypePopup';

class VehicleDeliveryMapModal extends Component {
    state = {
        addressToShowOnMap: '',
        selectedCity: '',
        cityList: [],
        restaurantToChangeDeliveryType: null
    };

    componentDidMount() {
        const { boundGetCities, boundSetRestaurants } = this.props;

        boundSetRestaurants([]);
        boundGetCities().then(response => this.setState({ cityList: response.data.result.flat() }));
    }

    initializeMap = ({ map, maps }) => {
        const { boundGetRestaurants } = this.props;
        this.map = map;
        this.maps = maps;
        map.controls[maps.ControlPosition.RIGHT].push(this.renderCurrentLocationButton());
        if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {  // getCurrentPosition doesn't work with http (only https!)
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude: lat, longitude: lng } }) => {
                    boundGetRestaurants(lat, lng).then(this.showMarkers);
                },
                () => {
                    boundGetRestaurants().then(this.showMarkers);
                }
            );
        } else {
            boundGetRestaurants().then(this.showMarkers);
        }
    };

    handleAddressChange = event => {
        this.setState({ addressToShowOnMap: event.target.value });
    };

    setCity = selectedCity => this.setState({ selectedCity, selectedDistrict: '' });

    selectCity = city => this.setCity(city.name);

    renderCityPreview = city => city.name;

    renderCurrentLocationButton = () => {
        const button = document.createElement('button');
        button.style.backgroundImage = 'url("/icons/find-my-location-blue.svg")';
        button.style.backgroundSize = '25px 25px';
        button.style.backgroundPosition = 'center';
        button.style.backgroundRepeat = 'no-repeat';
        button.style.width = '40px';
        button.style.height = '40px';
        button.style.marginRight = '10px';
        button.style.borderRadius = '2px';
        button.style.borderWidth = '0px';
        button.style.boxShadow = '0px 2px 12px rgba(0, 0, 0, 0.15)';
        button.className = 'find-my-location';
        button.onclick = this.showCurrentLocationMarker;
        return button;
    };

    getSelectRestaurant = restaurant => () => {
        const { addressData, basket } = this.props;

        if (addressData.addressSelection.type !== 'Araca Teslim' && basket.orderItems.length) {
            this.setState({ restaurantToChangeDeliveryType: restaurant });
        } else {
            this.getChangeDeliveryType(restaurant)();
        }
    };

    closeChangeDeliveryTypePopup = () => this.setState({ restaurantToChangeDeliveryType: null });

    cancelChangeDeliveryType = () => {
        const { setModal } = this.props;
        this.closeChangeDeliveryTypePopup();
        setModal('');
    };

    getChangeDeliveryType = restaurant => () => {
        const {
            basket, boundGetBasket, setModal, boundChangeDeliveryType, boundSetAddressSelection, location, history
        } = this.props;

        boundChangeDeliveryType(basket.id, VEHICLE_DELIVERY_ORDER_TYPE, restaurant.id)
            .then(() =>
                boundSetAddressSelection({
                    type: 'Araca Teslim',
                    address: restaurant.name,
                    addressId: restaurant.id,
                    coordinates: { latitude: restaurant.latitude, longitude: restaurant.longitude }
                })
            )
            .then(() => boundGetBasket().finally(() => setModal(''))
                .then(() => {
                    setLocalStorage('branchCity', restaurant.townName);
                    if (!location.pathname.includes('/siparis') && !location.pathname.includes('/sepet/sepetim')) {
                        history.push('/siparis/kampanyalar');
                    }
                }));
    };

    showMarkers = () => {
        const { addressData } = this.props;
        this.showRestaurantMarkers();

        if (addressData.addressSelection.coordinates.latitude
            && addressData.addressSelection.coordinates.longitude
            && addressData.addressSelection.type === 'Gel Al') {
            this.showCurrentLocationMarker(false);
            this.showLocation({
                lat: +addressData.addressSelection.coordinates.latitude,
                lng: +addressData.addressSelection.coordinates.longitude
            });
        } else {
            this.showCurrentLocationMarker();
        }
    };

    showLocation = latLng => {
        this.map.setCenter(latLng);
        this.map.setZoom(DEFAULT_ZOOM);
    };

    showRestaurantMarkers = () => {
        const { addressData } = this.props;
        let markers = [];

        addressData.restaurants.map(restaurant => {
            const location = this.getRestaurantLocation(restaurant);

            if (location) {
                const marker = new this.maps.Marker({
                    position: location,
                    map: this.map,
                    icon: '/icons/restaurant.svg'
                });
                const infoWindow = new this.maps.InfoWindow({ content: this.renderRestaurantInfoWindow(restaurant) });

                marker.addListener('click', () => {
                    if (this.openedInfoWindow) {
                        this.openedInfoWindow.close();
                    }
                    this.openedInfoWindow = infoWindow;
                    infoWindow.open(this.map, marker);
                });
                markers.push(marker);
            }
        });

        let markerCluster = new MarkerClusterer(this.map, markers, {
            styles: [{
                url: '/icons/cluster.svg',
                height: 105,
                width: 58,
                anchor: [40, 0],
                textColor: '#231f20',
                textSize: 16,
                iconAnchor: [30, 70],
                lineHeight: 50
            }]
        });
    };

    renderRestaurantInfoWindow = restaurant => {
        const button = document.createElement('button');
        button.classList.add('big-button');
        button.innerText = 'Siparişe Başla';
        button.onclick = () => {
            this.getSelectRestaurant(restaurant)();
            this.openedInfoWindow.close();
        };

        const root = document.createElement('div');
        root.classList.add('info-window');
        root.innerHTML = `
            <div class="info-window__header">
                 <span class="info-window__name">${restaurant.name}</span>
                 <span class="info-window__time">${getRestaurantWorkingTimes(restaurant)}</span>
             </div>
             <div class="info-window__address">${restaurant.address}</div>
        `;
        root.appendChild(button);
        return root;
    };

    getRestaurantLocation = restaurant => {
        if (restaurant.latitude && restaurant.longitude) {
            return { lat: +restaurant.latitude, lng: +restaurant.longitude };
        }
        return null;
    };

    showCurrentLocationMarker = (setAsCenter = true) =>
        navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const latLng = { lat, lng };
                if (setAsCenter) {
                    this.map.setCenter(latLng);
                    this.map.setZoom(DEFAULT_ZOOM);
                }
            }
        );

    renderRestaurantsBlock = () => {
        const { addressToShowOnMap } = this.state;

        return <div className="restaurant-preview-container">
            <div className="restaurant-preview-container__header">
                <div className="restaurant-preview-container__header-title">
                    Araca Teslim Şubesi
                </div>
                <TextInput leftIconName="search"
                           placeholder="Restoran Ara"
                           name="addressToShowOnMap"
                           value={addressToShowOnMap}
                           className="restaurant-preview-container__header-input"
                           onChange={this.handleAddressChange}
                />
            </div>

            <div className="restaurant-preview-container__items">
                {this.renderRestaurants()}
            </div>
        </div>;
    };

    renderRestaurants = () => {
        const { addressData } = this.props;
        const { addressToShowOnMap, selectedCity } = this.state;
        const inputAddress = addressToShowOnMap.toLowerCase();

        let restaurants = addressData.restaurants;

        if (restaurants && restaurants.length) {
            restaurants = restaurants.filter(restaurant => restaurant.storeOrderTypes.find(c => c.orderTypeID === 8));
        }

        if (restaurants && inputAddress) {
            restaurants = restaurants.filter(restaurant => {
                let flag = true;

                // if address or name includes every word of input
                inputAddress.split(' ').map(word => {
                    if (
                        !restaurant.address.toLowerCase().includes(word.toLowerCase())
                        && !restaurant.name.toLowerCase().includes(inputAddress.toLowerCase())
                    ) {
                        flag = false;
                    }
                });
                return flag;
            });
        }

        if (restaurants && selectedCity) {
            restaurants = restaurants.filter(restaurant => {
                return (restaurant.address && restaurant.address.toLowerCase().includes(selectedCity.toLowerCase()))
                    || (restaurant.townName && restaurant.townName.toLowerCase().includes(selectedCity.toLowerCase()));
            });
        }

        return restaurants && restaurants.length
            ? restaurants.map(restaurant =>
                <Restaurant key={restaurant.id}
                            restaurant={restaurant}
                            onSelectRestaurant={this.getSelectRestaurant(restaurant)}
                />
            )
            : <div className="restaurant-preview-info-container">
                <Icon name="info" color="#EE6112"/>
                <div className="restaurant-preview-info-container__message">
                    Seçilen bölgede hiçbir araç teslimi hizmeti vermiyor.
                </div>
            </div>;
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        const { addressData } = this.props;
        const { selectedCity, cityList, restaurantToChangeDeliveryType } = this.state;

        return (
            <div className="modal-content map">
                {
                    restaurantToChangeDeliveryType
                        ? <ChangeDeliveryTypePopup submit={this.getChangeDeliveryType(restaurantToChangeDeliveryType)}
                                                   close={this.cancelChangeDeliveryType}
                        />
                        : null
                }
                <Icon name="delete" className="close-modal-button" onClick={this.closeModal}/>
                <div className="google-map">
                    <GoogleMapReact
                        bootstrapURLKeys={GOOGLE_MAPS_API_KEY}
                        defaultZoom={12}
                        center={DEFAULT_CENTER}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={this.initializeMap}
                    />
                </div>

                <LcDropdown label="İl Seçin"
                            value={selectedCity || ''}
                            onChange={this.setCity}
                            className="map__search-block lc-dropdown--alt"
                            suggestions={cityList}
                            onSelect={this.selectCity}
                            renderSuggestion={this.renderCityPreview}
                />

                {
                    addressData.restaurants && addressData.restaurants.length
                        ? this.renderRestaurantsBlock()
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = store => ({
    addressData: store.addressData,
    basket: store.basket.basket
});

const mapDispatchToProps = dispatch => ({
    boundGetCities: bindActionCreators(getCities, dispatch),
    boundGetRestaurants: bindActionCreators(getRestaurants, dispatch),
    boundSetRestaurants: bindActionCreators(setRestaurants, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundChangeDeliveryType: bindActionCreators(changeDeliveryType, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch)
});

VehicleDeliveryMapModal.propTypes = {
    addressData: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    setModal: PropTypes.func.isRequired,
    boundGetCities: PropTypes.func.isRequired,
    boundGetRestaurants: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundChangeDeliveryType: PropTypes.func.isRequired,
    boundSetRestaurants: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VehicleDeliveryMapModal));