import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';

const FranchiseComment = () => {
    return (
<section class="institutional-structures">
    <div class="container">
    <BreadCrumbs />
        <div class="institutional-structures-detail franchising-comment">
            <div class="institutional-structures-detail-content clearfix">
                 <FranchisingMenu />
                 <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>Franchiseelerimizden Dinleyin</h1>
                        <div class="row">
                            <div class="col-md-3">
                                <img class="img-responsive" src="/Images/kurumsal/franchising/comment2.jpg" />
                            </div>
                            <div class="col-md-9">
                                <div class="team-text comment-franchising">
                                    <h6>Banu Makasçı</h6>
                                    <span>Feneryolu Little Ceasers</span>
                                </div>
                            </div>
                        </div>
                        <p>Uludağ Üniversitesi İşletme Fakültesi mezunu Banu Makasçı, Londra’da yaptığı yüksek lisans öğrenimini ve ardından gelen özel sektör tecrübesini Little Caesars’a yaptığı yatırımla taçlandıran isimlerden.</p>
                        <p>Özel sektörde uzun yıllar boyunca edindiği bilgi birikimini kendi sahibi olduğu bir işletmede kullanmaya karar verdiğini anlatan Makasçı, sıfırdan bir iş kurmak yerine işleyen bir düzen içerisinde yer almayı tercih ettiğini söylüyor. Franchisee olmadan çok önce Little Caesars’ın pizzalarını ailecek çok sevdiklerini anlatan Makasçı, Little Caesars’ı tercih etmesi konusunda “Little Caesars benim için en başta güvenilir bir kurum. Kurumsal yapıyı incelediğimizde büyüme potansiyeli de çok yüksek. Ayrıca bu denli lezzetli ve sevilen pizzalara sahip bir zincirin kazanç konusunda da bir risk oluşturmadığını gördüm. Aileye dahil olduğum andan itibaren de sistemin işleyişine katkıda bulunmak için elimden geldiğince çok çaba gösteriyorum” diyor. </p>
                        <p>Franchisee adayı olması ile birlikte 8 hafta boyunca uygulamalı ve teorik eğitimler gördüğünü ifade eden Banu Makasçı, yatırımının geri dönüşünü 2 ila 3 yıl arasında aldığını belirterek; “Bu süreci başarı ile geçirdikten sonra karlılığı daha iyi değerlendirebildim. Kurumsal yapıların karlılık güvencesi olsa da, kazancınızın büyüklüğü müşterinize iyi hizmet vermeye, ekip çalışmasına, işinize sahip çıkmanıza ve elbette lezzette en iyisini yakalamanıza bağlı. Bu anlamda ben kazancımdan memnunum” diyor. </p>
                        <p>Little Caesars’taki başarısının sırrını yaptığı işi sevmesi, çok çalışması, işinin başında durarak sıkı takip etmesi, ekibiyle iyi bir iletişim yakalaması, müşteri odaklı çalışma anlayışı, tek bir müşteriyi bile kaybetmemeyi hedeflemesi ve pizza kalitesinden ve standartlarından kesinlikle ödün vermemesi olarak özetleyen Makasçı, Little Caesars ailesine dahil olmak isteyenlere “Öncelikle severek çalışacağınız ve sizi mutlu eden bir iş olmalı. İşini severek yapan bir insan, her türlü zorluğun üstesinden gelebiliyor. Zorluklar aşıldıktan sonra başarı ve kazanç geliyor. Ayrıca meslektaşlarıma şubelerinin başında olarak işleyişi düzenli takip etmelerini öneriyorum” diyor.</p>
                        <p>&nbsp;</p>
                        <div class="row">
                            <div class="col-md-3">
                                <img class="img-responsive" src="/Images/kurumsal/franchising/comment.jpg" />
                            </div>
                            <div class="col-md-9">
                                <div class="team-text comment-franchising">
                                    <h6>Gökhan Serez</h6>
                                    <span>Ümraniye (Çakmak) Little Ceasers</span>
                                </div>
                            </div>
                        </div>
                        <p>Özel sektörde geçirdikleri uzun yılların ardından kendi işini yapmaya karar veren Atilla Özdemir ve Gökhan Serez şu anda Little Caesars’ın en başarılı isimlerinden.</p>
                        <p>Çalışma hayatı boyunca erken emeklilik hayali kurduğunu anlatan Gökhan Serez: “Özel sektörde geçirdiğim yıllar boyunca erken emekliliği hedeflemiştim. 50 yaşına geldiğimde aktif çalışma hayatımı bitirmeyi düşünüyordum. Little Caesars ile tanışınca bu planımı erken uygulamaya koydum” diyor.</p>
                        <p>Şu an aktif çalışmaya devam etmesine karşın çok daha planlı ve kazançlı bir iş yaşamına sahip olduğunu dile getiren Serez, “Little Caesars’ı seçmemdeki en önemli faktör, müşterisiyken kalitesini ve lezzetini beğendiğim bir markaya dahil olma arzumdu. Sadık müşterisi olduğum Little Caesars’ın ne mutlu ki şu anda işletmecisi konumundayım” diyor. Amaçlarının aldıkları hizmet kalitesini kendi şubelerinde de devam ettirmek olduğunu anlatan Özdemir ve Serez, en önemli hedeflerinin lezzetli Little Caesars pizzalarını müşterilerine tam zamanında ulaştırmak olduğunu dile getiriyor.</p>
                        <p>Little Caesars sistemine dahil olmak isteyen kişilere önce bu lezzeti tanımlarını tavsiye eden Serez, “İşimin belli mesaileri olmasına rağmen kendi zamanımı kendim planlayabiliyorum. Profesyonel hayatta pek çok kişinin takılıp kaldığı kalıpların olmaması da çalışma hayatını çok daha zevkli bir hale getiriyor. Kendimi, kaybetiğim yılları geri kazanmış gibi hissediyorum” diyor.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>


    );
};

export default FranchiseComment;