import React, { Component } from 'react';
import CorporateMenu from '../corporate-menu/CorporateMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getPageResult } from '../../../store/actions/pagesActions';

class AboutUs extends Component {
    state = {
        content: null
    };

    componentDidMount() {
        const { boundGetPageResult } = this.props;
        boundGetPageResult('basvuru-formu').then(response => this.setState({ content: response.payload.content }));
        sendPageView('/kurumsal/hakkimizda', 'Hakkımızda - Little Caesars');
    }

    render () {
        return (
            <section className="institutional-structures">
                {/*<div dangerouslySetInnerHTML={{__html: this.state.content}} />*/}
                <div className="container">
                    <div className="institutional-structures-detail">
                        <div className="institutional-structures-detail-content clearfix">
                            <CorporateMenu />
                            <div className="col-md-9">
                                <div className="corpareta-detail">
                                    <h1>Little Caesars Türkiye</h1>
                                    <img className="img-responsive" src="/images/corporate/hakkimizda.jpg" alt="Little Caesars Türkiye Hakkında" />
                                    <p>1996'dan beri Çelebi yönetiminde hızlı ve sağlam adımlarla büyümesini sürdüren Little Caesars, ilk akla gelen pizza markalarından biri olarak pazar payını arttırmaktadır.</p>
                                    <p>Çelebi'nin gıda sektöründeki önemli atılımlarından biri olan Little Caesars, 1959'da Amerika Birleşik Devletleri, Detroit'te kurulmuştur. Günümüzde <b>6000’e</b> yakın restoranı ile hizmet veren Little Caesars, uluslararası deneyimi sayesinde 1996 yılından bu yana da Türk tüketicisine hizmet vermektedir. Little Caesars tüm faaliyetlerini, müşterilerinin güvenini kazanmak ve bu güveni sürdürmek için kaliteden ödün verilmemesi gerektiği inancıyla yürütmektedir.</p>
                                    <p>Türkiye'nin ilk nefis kenar pizzasının mucidi Little Caesars, her tür damak tadına hitap edebilecek çeşitlilikte olan pizzalarının yanı sıra müşterilerine günlük olarak hazırlanan patates, tavuk kanadı ve sufle gibi özel yan ürünler de sunmaktadır. </p>
                                    <div className="detail-box">
                                        <i className="icon-Tiyris-1"></i>
                                        <i className="icon-Tiyris-2"></i>
                                        <p>Türk fast-food sektöründe birçok yeniliğe imza atan Little Caesars, nefis kenarın yanı sıra, "bir alana bir bedava" konseptini de  sektörle ilk kez tanıştıran markadır.</p>
                                    </div>
                                    <p>Vizyonu ve uygulamaları ile sektöründe ilklere imza atan bir marka olan Little Caesars, İstanbul, Adana, Ankara, Balıkesir, Bursa, Çanakkale, Eskişehir, Hatay, Kocaeli, Mersin, Sakarya, Samsun, Tekirdağ ve Zonguldak'ta sürekli artan restoran sayısı ile hizmetlerini sürdürmektedir. Yüksek kalitede ürünü rakiplerinden daha ekonomik şartlarda ve yüzde yüz müşteri memnuniyetini hedef alan servis anlayışıyla sunan Little Caesars, kalite standartlarından taviz vermeden gerçekleştirdiği çalışmalarını, restoran yatırımlarını ve franchisee restoran sayılarını artırarak sürdürecektir.</p>

                                    <div className="text-center">
                                        <img style={{ maxWidth: '100%' }} src="/images/corporate/celebi-holding-logo.png" alt="Çelebi Holding" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = store => ({
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    boundGetPageResult: bindActionCreators(getPageResult, dispatch)
});

AboutUs.propTypes = {
    setRedirect: PropTypes.func.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AboutUs));
