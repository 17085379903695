import api, { handleRequest } from "../../api";

export const SET_MENU_TOP = 'SET_MENU_TOP';
export const SET_FOOTER = 'SET_FOOTER';
export const SET_SHOULD_CHECKING_VEHICLE_DELIVERY = 'SET_SHOULD_CHECKING_VEHICLE_DELIVERY';

export const setMenuTop = response => ({
    type: SET_MENU_TOP,
    payload: response
});

export const setFooter = response => ({
    type: SET_FOOTER,
    payload: response
});

export const setShouldCheckingVehicleDelivery = response => ({
    type: SET_SHOULD_CHECKING_VEHICLE_DELIVERY,
    payload: response
});

export const getMenuTop = () => dispatch =>
    api.get('web/CMS/Menu?Name=Top', dispatch, { ignoreStatus: true })
        .then(response => handleRequest(response, () => dispatch(setMenuTop(response.data.result)), true));

export const getFooter = () => dispatch =>
    api.get('web/CMS/Menu?Name=Footer', dispatch, { ignoreStatus: true })
        .then(response => handleRequest(response, () => dispatch(setFooter(response.data.result)), true));
