import React, { useRef } from 'react';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import { isMobile } from '../../utils';

const LcCarousel = props => {
    const swiperRef = useRef(null);

    const defaultParams = {
        pagination: props.showPagination ? {
            el: '.swiper-pagination',
            clickable: true
        } : false,
        loop: !isMobile()
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return <div className="swiper-custom-wrapper">
        <Swiper {...defaultParams} {...props} ref={swiperRef}>
            {props.children}
        </Swiper>
        {
            props.showNavigation
                ? <>
                    <div className="swiper-button-next" onClick={goNext}/>
                    <div className="swiper-button-prev" onClick={goPrev}/>
                </>
                : null
        }
    </div>;
};

LcCarousel.defaultProps = {
    slidesPerView: 1,
    spaceBetween: 0,
    showPagination: false,
    showNavigation: false
};

LcCarousel.propTypes = {
    slidesPerView: PropTypes.number,
    showPagination: PropTypes.bool,
    showNavigation: PropTypes.bool
};

export default LcCarousel;
