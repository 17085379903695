import React from 'react';
import PropTypes from 'prop-types';

const LoyaltyBlockItem = ({ imageUrl, altTitle, title, details, triangleClassName, triangleContentText }) =>
    <div className="loyalty-block__item">
        <div className={`loyalty-block__triangle ${triangleClassName}`}>
            <div className="loyalty-block__triangle-body">
                <img src="/images/homepage/triangle.svg" alt="/images/homepage/triangle.svg"/>
                <div className="loyalty-block__triangle-content-text">{triangleContentText}</div>
            </div>
        </div>
        <div className="loyalty-block__item-image-wrapper">
            <div className="loyalty-block__item-image">
                <img src={imageUrl} alt={altTitle}/>
            </div>
        </div>
        <div className="loyalty-block__item-info">
            <div className="loyalty-block__item-title">{title}</div>
            <div className="loyalty-block__item-details">{details}</div>
        </div>
    </div>;

LoyaltyBlockItem.propTypes = {
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    altTitle: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    triangleClassName: PropTypes.string.isRequired,
    triangleContentText: PropTypes.string.isRequired
};

export default LoyaltyBlockItem;
