import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Validator from 'validator';
import AuthNav from '../navigation/AuthNav';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import FacebookLogin from 'react-facebook-login';
import { getFromLocalStorage } from '../../storage';
import CryptoJS from 'crypto-js';
import { hashKey } from '../../utils';

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                email: '',
                password: '',
                rememberMe: false
            },
            errors: {},
            touched: {},
            formIsValid: false,
            formSubmitted: false
        };
    }

    componentDidMount() {
        const rememberMeEmail = getFromLocalStorage('rememberMeEmail');
        const rememberMePasswordHash = getFromLocalStorage('rememberMePassword');

        if (rememberMeEmail && rememberMePasswordHash) {
            const rememberMePassword = CryptoJS.AES.decrypt(rememberMePasswordHash, hashKey).toString(CryptoJS.enc.Utf8);
            this.setState(
                { fields: { ...this.state.fields, password: rememberMePassword, email: rememberMeEmail } },
                this.validateForm
            );
        }
    }

    handleInputChange = e =>
        this.setState({ fields: { ...this.state.fields, [e.target.name]: e.target.value } }, this.validateForm);

    handleRememberMeCheckboxChange = () =>
        this.setState({ fields: { ...this.state.fields, rememberMe: !this.state.fields.rememberMe } });

    handleInputBlur = e => {
        const { touched } = this.state;

        if (e.target.name && touched[e.target.name] !== true) {
            touched[e.target.name] = true;
            this.setState({
                touched
            }, () => this.validateForm());
        }
    };

    submitLoginForm = e => {
        const { email, password, rememberMe } = this.state.fields;
        e.preventDefault();

        if (this.validateForm()) {
            this.setState({
                errors: {},
                formSubmitted: true
            });

            this.props.postLogin(email, password, rememberMe);
            this.resetForm();
        }
    };

    resetForm = () => {
        let fields = {};
        fields['email'] = '';
        fields['password'] = '';
        fields['rememberMe'] = false;
        this.setState({
            fields,
            formIsValid: false,
            formSubmitted: false
        });
    };

    loginWithFacebook = response => {
        const { postLoginWithFacebook } = this.props;
        console.log(response);
        if (response && response.accessToken) {
            console.log(response.accessToken)
            postLoginWithFacebook(response.accessToken, response.picture.data.url);

        }
    };


    goToForgotPassword = () => {
        const { history } = this.props;
        history.push('/hesap/sifre-unuttum');
    };

    validateForm = () => {
        const { fields } = this.state;
        const errors = {};
        let formIsValid = true;
        this.setState({
            errors
        });
        //validate email
        if (!fields.email) {
            formIsValid = false;
            errors.email = 'Lütfen email adresi giriniz';
        } else {
            if (!Validator.isEmail(fields.email)) {
                formIsValid = false;
                errors.email = 'Yanlış formatta email adresi girdiniz';
            }
        }

        //validate password
        if (!fields.password) {
            formIsValid = false;
            errors.password = 'Lütfen şifrenizi giriniz';
        } else {
            if (fields.password.length < 1) {
                formIsValid = false;
                errors.password = 'Şifreniz en az 1 karakter olmalıdır';
            }
        }

        this.setState({
            errors,
            formIsValid
        });

        return formIsValid;
    };

    getClassName = () => {
        const { error } = this.state;

        let resultClassName = 'form-group';

        if (error) {
            resultClassName += ' alert alert-danger';
        }

        return resultClassName;
    };

    render() {
        const { fields, errors, touched, formSubmitted, formIsValid } = this.state;
        return (
            <div className="form-login ">
                <div className="form-wrapper">
                    <AuthNav isSignUp={false} />
                    <div className="form-login__input-block">
                        <Input type="email"
                            className="form-login__input"
                            placeholder="E-Posta"
                            name="email"
                            value={fields.email}
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            error={(formSubmitted || touched.email) && errors.email ? errors.email : null}
                        />

                        <Input type="password"
                            className="form-login__input"
                            placeholder="Şifre"
                            name="password"
                            value={fields.password}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            error={(formSubmitted || touched.password) && errors.password ? errors.password : null}
                        />
                    </div>
                    <div className="form-login__forgot-password-block">
                        <Checkbox className="form-login__checkbox"
                            value={fields.rememberMe}
                            label="Beni hatırla"
                            labelClassName="form-login__checkbox-label"
                            onChange={this.handleRememberMeCheckboxChange}
                        />
                        <div className="form-login__forgot-password link" onClick={this.goToForgotPassword}>
                            Şifremi Unuttum
                        </div>
                    </div>
                    <div className="form-login__submit">
                        <Button className="form-login__button-text" disabled={!formIsValid}
                            onClick={this.submitLoginForm}
                        >
                            giriş yap
                        </Button>
                    </div>

                    <div className="form-login__caption">
                        <span>
                            veya
                    </span>
                    </div>
                    <div className="form-login__button">
                        <FacebookLogin
                            appId="295441695023663"
                            autoLoad={false}
                            fields="name,email,picture"
                            onClick={this.loginWithFacebook}
                            callback={this.loginWithFacebook}
                            icon="fa-facebook"
                            language="tr_TR"
                            textButton="Facebook İle Giriş Yap"
                            cssClass="button button--facebook" />
                    </div>




                    <small className="form-login__footer">
                        <span className="mr-3">
                            <svg
                                enableBackground="new 0 0 24 24" height="12px" version="1.1" viewBox="0 0 24 24"
                                width="12px"
                            // xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            >
                                <path clipRule="evenodd"
                                    d="M20.002,24.001H4.014c-1.104,0-1.998-0.896-1.998-2.001V11.994  c0-1.105,0.895-2.002,1.998-2.002h0.999V6.991c0-3.868,3.132-7.004,6.995-7.004s6.995,3.136,6.995,7.004v3.001h0.999  c1.104,0,1.998,0.896,1.998,2.002V22C22,23.104,21.105,24.001,20.002,24.001z M16.005,6.991c0-2.21-1.79-4.002-3.997-4.002  S8.011,4.781,8.011,6.991v3.001h7.994V6.991z"
                                    fillRule="evenodd"
                                /></svg>
                        </span>
                        Facebook’ta sizden izinsiz hiçbir şey paylaşmıyoruz.
                    </small>
                </div>
            </div>
        );
    }
}

export default withRouter(LoginForm);