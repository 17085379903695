import React from 'react';
import CustomerServiceMenu from '../customer-service-menu/CustomerServiceMenu';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';
import { sendPageView } from '../../../googleAnalyticsHelper';

const FastDelivery = () => {
    sendPageView('/musteri-hizmetleri/30-dakika-garantisi', '30 dakika garantisi - Little Caesars'); //for ga
    return <section className="institutional-structures">
    <div className="container">
         <BreadCrumbs />
        <div className="institutional-structures-detail">
            <div className="institutional-structures-detail-content clearfix">
                <CustomerServiceMenu />
                <div className="col-md-9">
                    <div className="corpareta-detail">
                        <h1>30 Dakika Garantisi</h1>
                        <img className="img-responsive" src="/images/kurumsal/30-dakika-garantisi.jpg" alt="30-dakika-garantisi.jpg" />

                        <div className="institutional-box">
                            <p className="where-my-order">Little Caesars Pizza, “30 Dakikada Teslim Garantisi” verir.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">
                                Siparişinizin 30 dakikadan geç teslim edilmesi durumunda, Little Caesars Pizza’nın sipariş başına iade edeceği miktar, Little Caesars Pizza menüsünün en yüksek fiyatlı ürünü (ekstra malzemeler hariç, sadece ana
                                satış fiyatı, örneğin; Büyük boy Gurme Pizza 49,90 TL gibi) kadardır. Fiş tutarından bu miktarda indirim
                                yapılacaktır.
                            </p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">Siparişin bedeli bu fiyattan daha az ise müşteriden hiç bedel alınmayacak, bu fiyatın üzerindeyse müşteriye de aradaki fark verilmeyecektir.“30 Dakikada Teslim Garantisi”, sipariş verilen şubenin servis sınırları
içinde geçerlidir.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">Toplu siparişlerde “30 Dakikada Teslim Garantisi” yoktur.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">4 pizza ve üzerindeki siparişler, toplu sipariş sayılır.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">Little Caesars Pizza, 30 dakikayı geçen teslimatlardan dolayı servis elemanlarına ceza vermez veya maaşlarından kesinti yapmaz.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">Little Caesars Pizza, servis garantisini önceden haber vermeksizin geri çekme hakkını saklı tutar.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">“30 Dakikada Teslim Garantisi”, promosyon günlerinde, özel günlerde, kötü hava koşullarında ve saat 23:30’dan sonra geçerli değildir.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">“30 Dakikada Teslim Garantisi”, hastane, okul, iş merkezi ve site gibi alanlarda, sipariş edilen ürünlerin, güvenlik veya danışma birimlerine ulaşması itibariyle sona erer.</p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">Sipariş sayfasında belirtilen süre, 30 dakikadan yüksekse, “30 Dakikada Teslimat Garantisi” geçersizdir. Siparişinizin farklı bir şubeye yönlendirilmesi halinde “30 Dakikada Teslimat Garantisi”, yönlendirme
yapılan şubeye iletildiği anda başlayacaktır.</p>
                        </div>
                        <div className="institutional-box">
                            <p className="where-my-order">Adres bildirimlerindeki yazım hatalarında (cadde/sokak adı veya bina numarası hatalı yazıldığında) “30Dakikada Teslimat Garantisi” verilmemektedir.  </p>
                        </div>

                        <div className="institutional-box">
                            <p className="where-my-order">Elemanların, sürüş güvenliği için servis alanları sınırlıdır. Little Caesars, elemanlarının güvenliği için tehdit oluşturan bölgelere servis yapmama hakkına sahiptir.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
};

export default FastDelivery;