import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getFlavorsSuggestions } from '../../store/actions/productActions';
import Button from '../elements/Button';
import OrderItemList from '../order/OrderItemList';
import { sendPageView } from '../../googleAnalyticsHelper';
import { getUser, setIsCheckout, getCustomerPhoneId } from '../../store/actions/authActions';

class BasketModal extends Component {
    componentDidMount() {
        sendPageView('/sepetim', 'Sepetim Açılır Sayfa');
    }

    goToSideProducts = () => {
        const { history, location, setModal } = this.props;
        if (location.pathname !== '/siparis/yan-urunler') {
            history.push('/siparis/yan-urunler');
        }
        setModal('');
    };

    submit = () => {
        const { history, location, setModal, user, addressData, setRedirect, boundSetIsCheckout, boundGetUser, boundGetCustomerPhoneId } = this.props;
        if (!this.props.user.id) {
            history.push('/hesap/giris');
            setRedirect({ type: 'url', value: '/sepet/sepetim' });
            setModal('');
        } else {
            if (!addressData.addressSelection.address) {
                setRedirect({ type: 'url', value: '/sepet/sepetim' });
                setModal('delivery-address');
            } else {
              boundGetUser()
                  .then(result => {
                      console.log('res', result)
                          if (!result.payload.personPhones[0].confirmed) {
                              boundGetCustomerPhoneId(this.props.user.personPhones[0].number);
                              boundSetIsCheckout({
                                  isCheckout: true
                              });
                              setModal('phone-confirmation');
                          } else {
                            if (location.pathname !== '/sepet/sepetim') {
                                history.push('/sepet/sepetim');
                            }
                            setModal('');
                          }
                        })
            }
        }
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        const { basket, user } = this.props;

        if (!basket.id) {
            return null;
        }
        return <div>
            <div className="modal-content basket-modal modal-box-remove">
                <div className="modal-content__header basket-modal__header">
                    Sepetim
                    <div className="basket-modal__close-button" onClick={this.closeModal}>KAPAT</div>
                </div>
                <OrderItemList/>
            </div>
            <div className="basket-modal__bottom">
                <div className="basket-modal__bottom-buttons">
                    <div className="basket-modal__bottom-button-container">
                        <Button color="white" onClick={this.goToSideProducts}>
                            <span>Yan Ürün Seç</span>
                        </Button>
                    </div>
                    <div className="basket-modal__bottom-button-container">
                        <Button color="light_green" onClick={this.submit} disabled={basket.totalCount === 0}>
                            <span>Sepetimi Onayla</span>
                        </Button>
                    </div>
                </div>
                {
                    user.id && basket.orderLoyalityData && basket.orderLoyalityData.punchCardStats.earnedPunch
                        ? <div className="basket-modal__footer">
                            <div>Bu
                                siparişinizden <span>{+basket.orderLoyalityData.punchCardStats.earnedPunch} Dilim </span>kazanacaksınız
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    basket: store.basket.basket,
    loading: !!store.loader.loadingCount,
    user: store.user.user,
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    getFlavorsSuggestions: bindActionCreators(getFlavorsSuggestions, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundSetIsCheckout: bindActionCreators(setIsCheckout, dispatch),
    boundGetCustomerPhoneId: bindActionCreators(getCustomerPhoneId, dispatch)
});

BasketModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    getFlavorsSuggestions: PropTypes.func.isRequired,
    basket: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundSetIsCheckout: PropTypes.func.isRequired,
    boundGetCustomerPhoneId: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BasketModal));
