import React from 'react';
import Icon from '../../elements/Icon';

const CreditCard = ({ creditCardName, creditCardNo, onDeleteCreditCardClick }) => {
    const getCreditCardIcon = creditCardNumber => {
        const creditCardFirstNumber = +(creditCardNumber).toString()[0];
        if (creditCardFirstNumber === 3) {
            return <Icon name="american-express" />;
        } else if (creditCardFirstNumber === 5 || creditCardFirstNumber === 6) {
            return <Icon name="mastercard" />;
        } else if (creditCardFirstNumber === 4) {
            return <Icon name="visa" />;
        } else if (creditCardFirstNumber === 9) {
            return <Icon name="troy" />;
        }
    };

    return (
        <div className="profile-credit-card__item" >
            <div className="profile-credit-card__icon">
                {getCreditCardIcon(creditCardNo)}
            </div>
            <div className="profile-credit-card__item-body">
                <div className="profile-credit-card__item-name">
                    {creditCardName}
                </div>
                <div className="profile-credit-card__item-number">
                    {creditCardNo}
                </div>
            </div>
            <div className="profile-credit-card__button-delete"
                 onClick={onDeleteCreditCardClick}
            >
                <span className="button__text">SİL</span>
            </div>
        </div>
    );
};



export default CreditCard;
