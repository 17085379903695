import React from 'react';
import { calculateDateTimeBefore, formatMoney } from '../../../utils';
import Icon from '../../elements/Icon';

const MyFavoriteOrder = ({ order, onDeleteClick, onRepeatOrderClick }) => {
    return <div className="order-preview profile-favorite-orders__item">
        <div className="order-preview__items-container">
            <div className="order-preview__header">
                <div className="order-preview__header-title">
                    Siparişim
                        </div>
                <div className="order-preview__header-date">
                    {calculateDateTimeBefore(order.orderDate)}
                </div>
            </div>
            <div className="order-preview__block">
                <div className=""></div>
                <div className="order-preview__body">
                    <div className="order-preview__body-info-container">
                        {order.orderItems &&
                            order.orderItems.map(orderItem => {
                                let i = 0;
                                return <div className="order-preview__body-description" key={orderItem.id}>
                                    <div className="order-preview__body-title">
                                        <span>{orderItem.quantity > 1 ? `${orderItem.quantity} x` : ''} {orderItem.product}, </span>
                                        {orderItem.orderItems && orderItem.orderItems.map(item => {
                                            i++;
                                            return `${item.product} ${i !== orderItem.orderItems.length ? '+ ' : ''}`
                                        }
                                        )}
                                    </div>
                                </div>
                            }
                            )}
                        <div className="order-preview__body-price">{formatMoney(order.amount)} TL</div>
                    </div>
                </div>
            </div>

        </div>
        <div className="order-preview__buttons-container order-preview__block">
            <div className="order-preview__button-delete"
                onClick={onDeleteClick}>
                sil
            </div>
            <div className="button button--light link order-preview__button order-preview__button-details"
                onClick={onRepeatOrderClick} >
                <Icon name="refresh" color="white"  />
                <span className="button__text">Tekrarla</span>
            </div>
        </div>
    </div>
}

export default MyFavoriteOrder;