import '../src/bootstrap.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'moment/locale/tr';
import 'swiper/swiper.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './style/main.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter, Link, Redirect, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import LoadingBar from 'react-top-loading-bar';
import Swiper, { Navigation, Pagination } from 'swiper';
import CookieConsent from 'react-cookie-consent';

import { getAuthToken, getNewAuthToken, hasValidToken } from './api';
import { bindActionCreators } from 'redux';
import { setAddressSelection } from './store/actions/addressActions';
import { checkIfVehiclePickupIsReady, getLastOrder } from './store/actions/orderActions';
import { setShouldCheckingVehicleDelivery } from './store/actions/layoutActions';
import { getBasket } from './store/actions/basketActions';
import { setLoading } from './store/actions/loaderActions';
import { setModal } from './store/actions/modalActions';

import Toast from './components/elements/Toast';
import AppHeader from './components/layout/AppHeader';
import Footer from './components/layout/Footer';
import ModalLayout from './components/layout/ModalLayout';
import HomeManager from './components/home/HomeManager';
import RestaurantManager from './components/restaurants/RestaurantManager';
import RestaurantDetails from './components/restaurants/RestaurantDetails';
import ProfileManager from './components/profile/ProfileManager';
import ProductManager from './components/product/ProductManager';

import CheckoutManager from './components/checkout/CheckoutManager';
import CompletedOrderManager from './components/completed-order/CompletedOrderManager';
import JokerHeader from './components/layout/JokerHeader';
import LoginPage from './components/pages/account/LoginPage';
import RegisterPage from './components/pages/account/RegisterPage';
import ForgotPasswordPage from './components/pages/account/ForgotPasswordPage';
import ResetPasswordPage from './components/pages/account/ResetPasswordPage';

import AboutUs from './components/corporate/aboutUs/AboutUs';
import ManagerMessage from './components/corporate/manager-message/ManagerMessage';
import CorporateHistory from './components/corporate/corporate-history/CorporateHistory';
import HumanResourcesPolicy from './components/corporate/human-resources/policies/HumanResourcesPolicy';
import OurSuppliers from './components/corporate/our-suppliers/OurSuppliers';
import HRTraining from './components/corporate/human-resources/training/HRTraining';
import CareerPlanning from './components/corporate/career-planning/CareerPlanning';
import ApplicationForm from './components/corporate/human-resources/application-form/ApplicationForm';
import Contact from './components/corporate/contact/contact-form/ContactForm';
import Quality from './components/corporate/quality/Quality';
import QualityPolicy from './components/corporate/quality-policy/QualityPolicy';
import ProductPolicy from './components/corporate/product-policy/ProductPolicy';
import Iso9001 from './components/corporate/quality/iso9001/Iso9001';
import AllergyList from './components/corporate/quality/allergy-list/AllergyList';
import NutritionValues from './components/corporate/quality/nutrition-values/NutritionValues';
import Delivery30Min from './components/corporate/delivery-30-min/Delivery30Min';
import Franchising from './components/corporate/franchising/Franchising';
import FranchiseHistory from './components/corporate/franchise-history/FranchiseHistory';
import FranchiseComment from './components/corporate/franchise-comment/FranchiseComment';
import FranchisingProcess from './components/corporate/franchising-process/FranchisingProcess';
import FranchisingSupport from './components/corporate/franchising-support/FranchisingSupport';
import FranchisingApplicationForm from './components/corporate/franchising-application-form/FranchisingApplicationForm';
import FranchisingOpportunityAreas
    from './components/corporate/franchising-opportunity-areas/FranchisingOpportunityAreas';
import Sss from './components/corporate/sss/sss';
import FastDelivery from './components/customer-service/fast-delivery/FastDelivery';
import OrderInformation from './components/customer-service/order-information/OrderInformation';
import PrivacyPolicy from './components/customer-service/privacy-policy/PrivacyPolicy';
import TermsOfUse from './components/customer-service/terms-of-use/TermsOfUse';
import TermsOfUseMasterpass from './components/customer-service/terms-of-use-masterpass/TermsOfUseMasterpass';
import PersonalDataPrivacy from './components/customer-service/personal-data-privacy/PersonalDataPrivacy';
import PersonalDataPrivacyRevoke from './components/customer-service/personal-data-privacy/PersonalDataPrivacyRevoke';
import CookiePolicy from './components/customer-service/cookie-policy/CookiePolicy';
import TransactionGuide from './components/customer-service/transaction-guide/TransactionGuide';
import UserAgreement from './components/customer-service/user-agreement/UserAgreement';
import Promotion from './components/customer-service/promotion/Promotion';

import Covid19Proclamation from './components/customer-service/covid19-proclamation/Covid19Proclamation';
import BusinessDevelopmentTeam from './components/corporate/business-development-team/BusinessDevelopmentTeam';


import { GuestRoute } from './components/routes/GuestRoute';
import { UserRoute } from './components/routes/UserRoute';
import metaData from './meta';
import { getFromLocalStorage, setLocalStorage } from './storage';
import LoyaltyManager from './components/loyalty/LoyaltyManager';
import { getUser } from './store/actions/authActions';
import { checkMasterPass } from './store/actions/memberActions';
import LoyaltyTerms from './components/customer-service/loyalty-terms/LoyaltyTerms';

Swiper.use([Navigation, Pagination]);

toast.configure({
    autoClose: false,
    position: 'top-center',
    hideProgressBar: true,
    closeOnClick: false,
    draggable: false,
    className: 'custom-toast-class',
    bodyClassName: 'custom-toast-class__body'
});

moment.locale('tr');

class App extends Component {
    vehicleCheckingIntervalId = 0;

    state = {
        hasValidToken: false,
        triedToGetBasket: false
    };

    componentDidMount() {
        const { boundSetAddressSelection, boundSetLoading, boundSetModal, location } = this.props;

        if (!hasValidToken()) {
            boundSetLoading(true);
            getNewAuthToken()
                .then(() => {
                    this.setState({ hasValidToken: true });
                    this.getUser();
                    this.getBasket().then(() => {
                        if (!(addressSelection && typeof addressSelection === 'object')) {
                            if (
                                location.pathname.indexOf('kurumsal') > 0
                                || location.pathname.indexOf('franchise') > 0
                                || location.pathname.indexOf('musteri-destegi') > 0
                            ) {
                                //do nothing
                            } else if (location.pathname === '/' || location.pathname.includes('kampanyalar') || location.pathname.includes('yan-urunler')) {
                                boundSetModal('delivery-type');
                            }
                        }
                    });
                })
                .finally(() => boundSetLoading(false));
        } else {
            this.setState({ hasValidToken: true });
            this.getUser();
            this.getBasket();
        }
        const addressSelection = JSON.parse(getFromLocalStorage('addressSelection'));
        if (addressSelection && typeof addressSelection === 'object') {
            boundSetAddressSelection(addressSelection);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { boundSetModal } = this.props;

        if (
            prevProps.user.id !== this.props.user.id && this.props.user.id
            || (prevProps.shouldCheckingVehicleDelivery !== this.props.shouldCheckingVehicleDelivery && this.props.shouldCheckingVehicleDelivery)
        ) {
            this.getLastOrderAndStartCheckingVehicleDelivery();
        }
        if (this.props.user.loyalty
            && this.props.user.loyalty.punchCard) {
            setLocalStorage('availablePunch', this.props.user.loyalty.punchCard.availablePunch);
        }
        if (
            !JSON.parse(getFromLocalStorage('giftWonModalShown'))
            && (prevProps.user.id !== this.props.user.id || prevProps.user.loyalty !== this.props.user.loyalty)
            && this.props.user.loyalty
            && this.props.user.loyalty.punchCard
            && this.props.user.loyalty.punchCard.canGetGift === 'true'
            && this.props.user.loyalty.punchCard.canOrderGiftProductID
            && this.props.user.loyalty.punchCard.canOrderGiftProductID !== '0'
        ) {
            boundSetModal('gift-won');
            setLocalStorage('giftWonModalShown', true);
        }
    }

    getUser = () => {
        const { boundGetUser, boundCheckMasterPass, boundSetModal, location } = this.props;
        const token = getAuthToken();
        if (token && !token.isDummyLogin) {
            boundGetUser().then(response => {
                if (location.pathname === '/sepet/sepetim') {
                    const phoneNumber = `90${response.payload.personPhones[0].number}`;
                    boundCheckMasterPass(phoneNumber, boundSetModal);
                }
            });
        }
    };

    getLastOrderAndStartCheckingVehicleDelivery = () => {
        const { boundGetLastOrder } = this.props;

        boundGetLastOrder().then(response => {
            const lastOrder = response.payload;

            if (lastOrder && lastOrder.orderTypeId === '8') {
                const orderStatusId = lastOrder.orderStateId;

                if (orderStatusId === '5') {
                    return this.openVehicleDeliveryModal(lastOrder.id); // Order is completed already
                } else if (
                    orderStatusId === '0'
                    || orderStatusId === '1'
                    || orderStatusId === '13'
                    || orderStatusId === '19'
                    || orderStatusId === '4'
                    || orderStatusId === '21'
                ) {
                    this.vehicleCheckingIntervalId = setInterval(
                        () => this.checkForSignalForVehicleDelivery(lastOrder.id, '5'),
                        5000
                    );
                    this.checkForSignalForVehicleDelivery(lastOrder.id, '5');
                }
            }
        }).catch(() => {
        });
    };

    openVehicleDeliveryModal = orderId => this.props.boundSetModal(`vehicle-delivery/${orderId}`);

    checkForSignalForVehicleDelivery = (orderId, orderStatusId) => {
        const { boundCheckIfVehiclePickupIsReady, lastOrder, boundSetShouldCheckingVehicleDelivery } = this.props;

        boundCheckIfVehiclePickupIsReady(orderId, orderStatusId).then(response => {
            if (response.data.resultValue === '5') {
                this.openVehicleDeliveryModal(lastOrder.id);
                clearInterval(this.vehicleCheckingIntervalId);
                boundSetShouldCheckingVehicleDelivery(false);
            }
        }).catch(() => {
        });
    };

    getBasket = () => {
        const { boundGetBasket } = this.props;

        return boundGetBasket()
            .then(() => this.setState({ triedToGetBasket: true }))
            .catch(() => {
                // toast(<Toast text="Error during getting basket. Some functions may not work without basket."
                //              status="error"
                // />);
                this.setState({ triedToGetBasket: true });
            });
    };

    getLoadingProgress = () => {
        const { loader } = this.props;
        if (!loader.loadingCount) {
            return 100;
        }
        const percent = 100 * loader.loadedCount / (loader.loadingCount + loader.loadedCount);
        if (percent === 0) {
            return 2;
        }
        return percent;
    };

    renderMetaTag = () => {
        if (metaData[window.location.pathname]) {
            return <meta name={metaData[window.location.pathname].title}
                         content={metaData[window.location.pathname].desc}
            />;
        }
        return null;
    };

    render() {
        const { location } = this.props;
        const { hasValidToken, triedToGetBasket } = this.state;

        return (
            <div className="app">
                <Helmet defaultTitle="Little Caesars Pizza"
                        titleTemplate="Little Caesars Pizza - %s"
                >
                    {this.renderMetaTag()}
                    <link rel="canonical" href={window.location.href}/>
                </Helmet>
                {
                    // <LoadingBar progress={this.getLoadingProgress()} height={3} color="orange"/>
                }
                {
                    hasValidToken && triedToGetBasket
                        ? <BrowserRouter>
                            <CookieConsent buttonText="Onaylıyorum"
                                           containerClasses="cookie"
                                           buttonClasses="cookie__button"
                                           buttonWrapperClasses="cookie__button-container"
                                           contentClasses="cookie__content"
                                           location="bottom"
                                           sameSite="lax"
                            >
                                <div className="cookie__text">
                                    Alışveriş deneyiminizi iyileştirmek için yasal düzenlemelere uygun çerezler (cookies)
                                    kullanıyoruz. Detaylı bilgiye Gizlilik ve Çerez Politikası sayfamızdan erişebilirsiniz.
                                </div>
                                <Link to="/musteri-hizmetleri/cerez-politikasi" className="cookie__link">Detaylı
                                    bilgi</Link>
                            </CookieConsent>
                            <ModalLayout/>
                            <JokerHeader/>
                            <AppHeader/>
                            <Switch>
                                {/* Home route */}
                                <GuestRoute location={location} path="/" exact component={HomeManager}/>

                                {/* Restaurants route */}
                                <GuestRoute path="/musteri-destegi/harita" component={RestaurantManager}/>
                                <GuestRoute path="/sube/:townName?/:restaurantName?/:id?" component={RestaurantDetails}/>

                                {/* Account & Auth routes */}
                                <GuestRoute location={location} path="/hesap/giris" component={LoginPage}/>
                                <GuestRoute location={location} path="/hesap/uye-ol" component={RegisterPage}/>
                                <GuestRoute location={location} path="/hesap/sifre-unuttum" component={ForgotPasswordPage}/>
                                <GuestRoute location={location} path="/hesap/sifre-yenileme/:token"
                                            component={ResetPasswordPage}/>

                                {/* Profile routes */}
                                <UserRoute location={location} path="/profil" component={ProfileManager}/>

                                {/* Loyalty routes */}
                                <UserRoute location={location} path="/sezarla-kazan" component={LoyaltyManager}/>

                                {/* Products routes */}
                                <GuestRoute location={location} path="/siparis/yan-urunler/:subCategory?"
                                            component={ProductManager}/>
                                <GuestRoute location={location} path="/siparis/pizzalar/:subCategory?"
                                            component={ProductManager}/>
                                <GuestRoute location={location} path="/siparis/kampanyalar/:subCategory?"
                                            component={ProductManager}/>
                                <GuestRoute location={location} path="/siparis/:category" component={ProductManager}/>

                                {/* Checkout routes */}
                                <UserRoute location={location} path="/sepet/sepetim" component={CheckoutManager}/>

                                {/* complete order routes */}
                                <UserRoute location={location} path="/completed-order/:id?"
                                           component={CompletedOrderManager}/>

                                {/* corporate routes */}

                                <GuestRoute location={location} path='/kurumsal/hakkimizda' component={AboutUs}/>
                                <GuestRoute location={location} path='/kurumsal/genel-mudur-mesaj'
                                            component={ManagerMessage}/>
                                <GuestRoute location={location} path='/kurumsal/tarihce' component={CorporateHistory}/>
                                <GuestRoute location={location} path='/kurumsal/insan-kaynaklari-politikasi'
                                            component={HumanResourcesPolicy}/>
                                <GuestRoute location={location} path='/kurumsal/tedarikcilerimiz' component={OurSuppliers}/>
                                <GuestRoute location={location} path='/kurumsal/egitim' component={HRTraining}/>
                                <GuestRoute location={location} path='/kurumsal/kariyer-planplama'
                                            component={CareerPlanning}/>
                                <GuestRoute location={location} path='/kurumsal/basvuru-formu' component={ApplicationForm}/>
                                <GuestRoute location={location} path='/kurumsal/iletisim' component={Contact}/>
                                <GuestRoute location={location} path='/kurumsal/kalite' component={Quality}/>
                                <GuestRoute location={location} path='/kurumsal/kalite-ve-gida-guvenligi-politikasi'
                                            component={QualityPolicy}/>
                                <GuestRoute location={location} path='/kurumsal/helal-gida-politikası'
                                            component={ProductPolicy}/>
                                <GuestRoute location={location} path='/kurumsal/iso-9001-2015-kalite-belgesi'
                                            component={Iso9001}/>
                                <GuestRoute location={location} path='/kurumsal/alerjen-listesi' component={AllergyList}/>
                                <GuestRoute location={location} path='/kurumsal/besin-degerleri'
                                            component={NutritionValues}/>
                                <GuestRoute location={location} path='/kurumsal/30-dkk-teslim' component={Delivery30Min}/>
                                <GuestRoute location={location} path='/kurumsal' component={AboutUs}/>
                                {/* customer service routes */}

                                <GuestRoute location={location} path='/musteri-hizmetleri/30-dakika-garantisi'
                                            component={FastDelivery}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/siparisim-nerde'
                                            component={OrderInformation}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/gizlilik-politikasi'
                                            component={PrivacyPolicy}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/kullanim-kosullari'
                                            component={TermsOfUse}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/kullanim-kosullari-masterpass'
                                            component={TermsOfUseMasterpass}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/kisisel-verilerin-korunmasi'
                                            component={PersonalDataPrivacy}/>
                                <GuestRoute location={location}
                                            path='/musteri-hizmetleri/kisisel-veri-saklama-imha-politikasi'
                                            component={PersonalDataPrivacyRevoke}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/islem-rehberi'
                                            component={TransactionGuide}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/sezarla-kazan-kosullari'
                                            component={LoyaltyTerms}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/cerez-politikasi'
                                            component={CookiePolicy}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/uyelik-sozlesmesi'
                                            component={UserAgreement}/>
                                <GuestRoute location={location} path='/musteri-hizmetleri/bedava-sezar-kampanyasi'
                                            component={Promotion}/>

                                <GuestRoute location={location} path='/musteri-hizmetleri/covid-19-onlemleri-bildirisi'
                                            component={Covid19Proclamation}/>
                
                                <GuestRoute location={location} path='/musteri-hizmetleri'
                                            component={PersonalDataPrivacy}/>

                                {/* franchising routes */}

                                <GuestRoute location={location} path='/franchise/franchising' component={Franchising}/>
                                <GuestRoute location={location} path='/franchise/franchisee-olma-sureci'
                                            component={FranchisingProcess}/>
                                <GuestRoute location={location} path='/franchise/destekler' component={FranchisingSupport}/>
                                <GuestRoute location={location} path='/franchise/franchise-firsat-bolgeleri'
                                            component={FranchisingOpportunityAreas}/>
                                <GuestRoute location={location} path='/franchise/tarihce' component={FranchiseHistory}/>
                                <GuestRoute location={location} path='/franchise/is-gelistirme-ekibi'
                                            component={BusinessDevelopmentTeam}/>
                                <GuestRoute location={location} path='/franchise/franchiseelerimizden-dinleyin'
                                            component={FranchiseComment}/>
                                <GuestRoute location={location} path='/franchise/franchise-basvuru-formu'
                                            component={FranchisingApplicationForm}/>
                                <GuestRoute location={location} path='/franchise/sss' component={Sss}/>
                                <GuestRoute location={location} path='/franchise' component={Franchising}/>
                                <Redirect from="/" to="/"/>
                            </Switch>
                            <Footer/>
                        </BrowserRouter>
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = store => ({
    loader: store.loader,
    user: store.user.user,
    lastOrder: store.orderData.lastOrder,
    shouldCheckingVehicleDelivery: store.layout.shouldCheckingVehicleDelivery
});

const mapDispatchToProps = dispatch => ({
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundSetLoading: bindActionCreators(setLoading, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundGetLastOrder: bindActionCreators(getLastOrder, dispatch),
    boundCheckIfVehiclePickupIsReady: bindActionCreators(checkIfVehiclePickupIsReady, dispatch),
    boundSetShouldCheckingVehicleDelivery: bindActionCreators(setShouldCheckingVehicleDelivery, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundCheckMasterPass: bindActionCreators(checkMasterPass, dispatch)
});

App.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
    user: PropTypes.object.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetLoading: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundGetLastOrder: PropTypes.func.isRequired,
    boundCheckIfVehiclePickupIsReady: PropTypes.func.isRequired,
    boundSetShouldCheckingVehicleDelivery: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundCheckMasterPass: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
