import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser } from '../../../store/actions/authActions';
import { checkMasterPass, getCreditCards, deleteCreditCard } from '../../../store/actions/memberActions';
import { setModal } from '../../../store/actions/modalActions';
import Button from '../../elements/Button';
import CreditCard from './CreditCard';
import DeleteCreditCardPopup from './DeleteCreditCardPopup';

class PaymentSettings extends Component {
    state = {
        creditCardNameToDelete: null
    };

    componentDidMount() {
        const { boundCheckMasterPass, boundSetModal, phoneNumber, creditCards } = this.props;
        if (creditCards.length === 0) {
            boundCheckMasterPass(phoneNumber, boundSetModal);
        }
    }

    componentDidUpdate(prevProps) {
        const { boundGetCreditCards, phoneNumber, cardsListWasChanged } = this.props;
        if (prevProps.cardsListWasChanged !== cardsListWasChanged && cardsListWasChanged) {
            boundGetCreditCards(phoneNumber);
        }
    }

    showAddCreditCardModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('add-credit-card');
    };

    getOpenDeleteCreditCardPopup = creditCardNameToDelete => () => this.setState({ creditCardNameToDelete });

    closeDeleteCreditCardPopup = () => this.setState({ creditCardNameToDelete: null });

    deleteCreditCard = () => {
        const { boundDeleteCreditCard, phoneNumber, boundSetModal } = this.props;
        const { creditCardNameToDelete } = this.state;
        boundDeleteCreditCard(creditCardNameToDelete, phoneNumber, boundSetModal);
        this.closeDeleteCreditCardPopup();
    };

    render() {
        const { creditCards } = this.props;
        const { creditCardNameToDelete } = this.state;

        return <div className="profile-credit-card">
            {
                creditCardNameToDelete
                    ? <DeleteCreditCardPopup submit={this.deleteCreditCard} close={this.closeDeleteCreditCardPopup}/>
                    : null
            }
            <div className="profile-credit-card__header">
                <div className="profile__header">Masterpass'e Kayıtlı Kartlarım</div>
                <div className="profile-credit-card__header-button-container">
                    <Button color="orange" className="profile-credit-card__header-button"
                            onClick={this.showAddCreditCardModal}
                    >
                        kart ekle +
                    </Button>
                </div>
            </div>

            <div className="profile-credit-card__items-container">
                {
                    creditCards.length
                        ? creditCards.map(creditCard => (
                            <CreditCard key={creditCard.Value1}
                                        creditCardName={creditCard.Name}
                                        creditCardNo={creditCards.length && creditCard.Value1}
                                        onDeleteCreditCardClick={this.getOpenDeleteCreditCardPopup(creditCard.Name)}
                            />
                        ))
                        : <div className="profile-credit-card__empty-list">
                            <div className="profile-credit-card__empty-list-item">
                                <img src="/images/bank-cards/empty-card-list-logo.png" alt="empty-card-list-logo.png"/>
                            </div>
                            <span className="profile-credit-card__empty-list-item">
                                Kayıtlı kartınız bulunmamaktadır.
                            </span>
                        </div>
                }
                <div className="profile-credit-card__empty-list">
                    <span className="profile-credit-card__empty-list-item">
                    Kart bilgileriniz Mastercard'ın ödeme altyapısı olan Masterpass'te tutulmaktadır.
                    </span>
                    <div className="profile-credit-card__empty-list-item">
                        <img src="/images/masterpass.svg" alt="masterpass.svg"/>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    phoneNumber: `90${store.user.user.personPhones[0].number}`,
    creditCards: store.member.creditCards,
    cardsListWasChanged: store.member.cardsListWasChanged
});

const mapDispatchToProps = dispatch => ({
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundCheckMasterPass: bindActionCreators(checkMasterPass, dispatch),
    boundGetCreditCards: bindActionCreators(getCreditCards, dispatch),
    boundDeleteCreditCard: bindActionCreators(deleteCreditCard, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

PaymentSettings.propTypes = {
    creditCards: PropTypes.array.isRequired,
    cardsListWasChanged: PropTypes.bool.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundCheckMasterPass: PropTypes.func.isRequired,
    boundGetCreditCards: PropTypes.func.isRequired,
    boundDeleteCreditCard: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings);
