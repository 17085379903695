import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import LcDropdown from '../../elements/LcDropdown'
import  Select  from 'react-select';
import { addActionEvent } from '../../../googleAnalyticsHelper';

const CustomerServiceMenu  = props => {
    const mobileDropdownMenuHandler = event => props.history.push(event.target.value);
    return <div className="col-md-3 mobil-menu-corporate">
            <div className="row">
                <div className="corporate-menu hidden-xs hidden-sm">
                    <ol className="faq">
                        <li className=""><NavLink activeClassName="active" to="/kurumsal/30-dkk-teslim">30 Dakikada Teslim</NavLink></li>
                        <li className=""><NavLink activeClassName="active" to="/musteri-hizmetleri/islem-rehberi">İşlem Rehberi</NavLink></li>
                        <li className=""><NavLink activeClassName="active" to="/musteri-hizmetleri/sezarla-kazan-kosullari">Sezarla Kazan Koşulları</NavLink></li>
                        <li className=""><NavLink activeClassName="active" to="/musteri-hizmetleri/kisisel-verilerin-korunmasi">Kişisel Verilerin Korunması</NavLink></li>
                        <li className=""><NavLink activeClassName="active" to="/musteri-hizmetleri/kisisel-veri-saklama-imha-politikasi">Kişisel Veri Saklama ve İmha Politikası</NavLink></li>
                    </ol>
                </div>
                <div className="corporate-menu-mobil hidden-lg hidden-md">
                    <select  onChange={mobileDropdownMenuHandler}
                    className="js-states form-control"
                    value={props.location.pathname}>
                        <option value="/kurumsal/30-dkk-teslim">30 Dakikada Teslim</option>
                        <option value="/musteri-hizmetleri/islem-rehberi">İşlem Rehberi</option>
                        <option value="/musteri-hizmetleri/sezarla-kazan-kosullari">Sezarla Kazan Koşulları</option>
                        <option value="/musteri-hizmetleri/kisisel-verilerin-korunmasi">Kişisel Verilerin Korunması</option>
                        <option value="/musteri-hizmetleri/kisisel-veri-saklama-imha-politikasi">Kişisel Veri Saklama ve İmha Politikası</option>
                    </select>

                </div>
            </div>
        </div>
};

export default withRouter(CustomerServiceMenu);