import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const CorporateMenu = props => {
    const mobileDropdownMenuHandler = event => props.history.push(event.target.value);

    return (
        <div className="col-md-3 mobil-menu-corporate">
            {
                <div className="row">
                    <div className="corporate-menu hidden-xs hidden-sm">
                        <ol className="faq">
                            <li className="'active'">
                                <NavLink className="corporate-link" to="/kurumsal/hakkimizda">
                                    Little Caesars Türkiye
                                    <i className="icon-drop-down-arrow" />
                                </NavLink>
                                <ul className="'active'" style={{ display: 'block' }}>
                                    <li className="">
                                        <NavLink to="/kurumsal/hakkimizda">
                                            Hakkımızda
                                        </NavLink>
                                    </li>
                                    <li className="">
                                        <NavLink to="/kurumsal/genel-mudur-mesaj">
                                            Genel Müdür’ün Mesajı
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active" to="/kurumsal/tarihce">
                                    Tarihçe
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to="/kurumsal/tedarikcilerimiz">
                                    Tedarikçilerimiz
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink className="corporate-link" activeClassName="active"
                                         to="/kurumsal/insan-kaynaklari-politikasi"
                                >
                                    İnsan Kaynakları
                                    <i className="icon-drop-down-arrow" />
                                </NavLink>
                                <ul className="" style={{ display: 'block' }}>
                                    <li className="">
                                        <NavLink activeClassName="active"
                                                 to="/kurumsal/insan-kaynaklari-politikasi"
                                        >
                                            İnsan Kaynakları Politikamız
                                        </NavLink>
                                    </li>
                                    <li className="">
                                        <NavLink activeClassName="active"
                                                 to="/kurumsal/egitim"
                                        >
                                            Eğitim
                                        </NavLink>
                                    </li>
                                    <li className="">
                                        <NavLink activeClassName="active" to="/kurumsal/basvuru-formu">
                                            Başvuru Formu
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active" to="/kurumsal/iletisim">
                                    İletişim
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active" to="/kurumsal/kalite">
                                    Little Caesars Neden Bu Kadar Lezzetli?
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active"
                                         to="/kurumsal/kalite-ve-gida-guvenligi-politikasi"
                                >
                                    Kalite ve Gıda Güvenliği Politikası
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active"
                                         to="/kurumsal/helal-gida-politikası"
                                >
                                    Helal Gıda Politikası
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active" to="/kurumsal/iso-9001-2015-kalite-belgesi">
                                    ISO 9001 : 2015 Kalite Belgesi
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active" to="/kurumsal/alerjen-listesi">
                                    Alerjen Listesi
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink activeClassName="active" to="/kurumsal/besin-degerleri">
                                    Besin Değerleri
                                </NavLink>
                            </li>
                        </ol>
                    </div>
                    <div className="corporate-menu-mobil hidden-lg hidden-md">
                        <select onChange={mobileDropdownMenuHandler}
                                className="js-states form-control"
                                value={props.location.pathname}
                        >
                            <option value="/kurumsal/hakkimizda">Little Caesars Türkiye</option>
                            <option value="/kurumsal/hakkimizda">Hakkımızda</option>
                            <option value="/kurumsal/genel-mudur-mesaj">Genel Müdür’ün Mesajı</option>
                            <option value="/kurumsal/tarihce">Tarihçe</option>
                            <option value="/kurumsal/tedarikcilerimiz">Tedarikçilerimiz</option>
                            <option value="/kurumsal/insan-kaynaklari-politikasi">İnsan Kaynakları</option>
                            <option value="/kurumsal/insan-kaynaklari-politikasi">İnsan Kaynakları Politikamız</option>
                            <option value="/kurumsal/egitim">Eğitim</option>
                            <option value="/kurumsal/basvuru-formu">Başvuru Formu</option>
                            <option value="/kurumsal/iletisim">İletişim</option>
                            <option value="/kurumsal/kalite">Little Caesars Neden Bu Kadar Lezzetli?</option>
                            <option value="/kurumsal/kalite-ve-gida-guvenligi-politikasi">
                                Kalite ve Gıda Güvenliği Politikası
                            </option>
                            <option value="/kurumsal/helal-gida-politikası">
                                Helal Gıda Politikası
                            </option>
                            <option value="/kurumsal/iso-9001-2015-kalite-belgesi">
                                ISO 9001 : 2015 Kalite Belgesi
                            </option>
                            <option value="/kurumsal/alerjen-listesi">Alerjen Listesi</option>
                            <option value="/kurumsal/besin-degerleri">Besin Değerleri</option>
                        </select>
                    </div>
                </div>
            }
        </div>
    );
};

export default withRouter(CorporateMenu);
