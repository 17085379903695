import React from 'react';

const Potato = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M13 1H11V13H13V1ZM10 2H8V4H5V11H8V13H10V2ZM16 4H19V11H16V13H14V2H16V4Z" fill="white"/>
        <path d="M4 9C4 9 5.5 9 6.5 9C7.5 9 8.5 13.5 12 13.5C15.5 13.5 17 9 17 9H20L19 23H5L4 9Z" fill="#F68427"/>
        <path d="M19.7335 8.4375V2.8125H16.921V1.40625H14.1085V0H9.88972V1.40625H7.07722V2.8125H4.26472V8.4375H2.78516L4.33105 24H19.667L21.2129 8.4375H19.7335ZM18.3272 4.21875V8.4375H16.921V4.21875H18.3272ZM15.5147 2.8125V9.14062C15.5147 10.2883 14.9618 11.3092 14.1085 11.9512V2.8125H15.5147ZM12.7022 1.40625V12.5855C12.475 12.6318 12.2398 12.6562 11.9991 12.6562C11.7583 12.6562 11.5232 12.6318 11.296 12.5855V1.40625H12.7022ZM8.48347 2.8125H9.88972V11.9512C9.03641 11.3092 8.48347 10.2883 8.48347 9.14062V2.8125ZM5.67097 4.21875H7.07722V8.4375H5.67097V4.21875ZM5.60459 22.5938L5.25069 19.0312H9.22742C9.54158 20.269 10.665 21.1875 11.9991 21.1875C13.3332 21.1875 14.4566 20.269 14.7708 19.0312H18.7475L18.3936 22.5938H5.60459ZM10.546 18.3281C10.546 17.5269 11.1978 16.875 11.9991 16.875C12.8004 16.875 13.4522 17.5269 13.4522 18.3281C13.4522 19.1294 12.8004 19.7812 11.9991 19.7812C11.1978 19.7812 10.546 19.1294 10.546 18.3281ZM18.8872 17.625H14.7708C14.4566 16.3872 13.3332 15.4688 11.9991 15.4688C10.665 15.4688 9.54158 16.3872 9.22742 17.625H5.111L4.33803 9.84375H7.12733C7.46956 12.2258 9.52391 14.0625 11.9991 14.0625C14.4744 14.0625 16.5287 12.2258 16.871 9.84375H19.6602L18.8872 17.625Z" fill="#F33A21"/>
    </svg>;

export default Potato;
