import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatErrorMessage, hideMobileMenu, showMobileMenu } from '../../utils';

class TextInput extends Component {
    defaultRef = React.createRef();

    getClassName = () => {
        const { type, error, disabled, label, containerClassName } = this.props;

        let resultClassName = 'text-input';
        if (type) {
            resultClassName += ` text-input--type--${type}`;
        }
        if (error) {
            resultClassName += ' text-input--error';
        }
        if (disabled) {
            resultClassName += ' text-input--disabled';
        }
        if (label) {
            resultClassName += ' text-input--with-label';
        }
        if (containerClassName) {
            resultClassName += ` ${containerClassName}`;
        }

        return resultClassName;
    };

    getInputClassName = () => {
        const { className } = this.props;
        let resultClassName = 'text-input__body';
        if (className) {
            resultClassName += ` ${className}`;
        }
        return resultClassName;
    };

    checkValue = value => {
        if (value === null || value === undefined) {
            return '';
        }
        return value;
    };

    onBlur = event => {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur(event);
        }
        showMobileMenu();
    };

    onFocus = event => {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus(event);
        }
        hideMobileMenu();
    };

    render() {
        const {
            type, value, name, error, label, labelClassName, placeholder, onChange, rootRef, maxLength,
            autocomplete, pattern
        } = this.props;

        return (
            <div className={this.getClassName()}>
                {
                    label
                        ? <label className={labelClassName}>{label}</label>
                        : null
                }
                <input type={type}
                       name={name}
                       className={this.getInputClassName()}
                       value={this.checkValue(value)}
                       placeholder={placeholder}
                       onChange={onChange}
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       error={error}
                       ref={rootRef || this.defaultRef}
                       maxLength={maxLength}
                       autoComplete={autocomplete}
                       pattern={pattern}
                />
                {
                    error
                        ? <div className="help-text help-text--error">{formatErrorMessage(error)}</div>
                        : null
                }
            </div>
        );
    }
}

TextInput.propTypes = {
    type: PropTypes.string,
    pattern: PropTypes.string,
    containerClassName: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.any,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    inputComponent: PropTypes.node
};

export default TextInput;
