import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "../elements/Button";
import Input from "../elements/Input";
import getValidator from "../../getValidator";

class PromoCodeModal extends Component {
    validator = getValidator(this);

    state = {
        promoCode: ''
    };

    promoCodeInputHandler = event => this.setState({ promoCode: event.target.value });

    closeModal = () => this.props.setModal('');

    submit = () => {
        this.validator.showMessages();
        if (this.validator.allValid()) {
        }
    };

    render() {
        const { promoCode } = this.state;

        return <div className="modal-content promo-code-modal">
            <div className="modal-content__header modal-content__header--centered">
                Promosyon Ekle
            </div>
            <div>
                <Input value={promoCode}
                       onChange={this.promoCodeInputHandler}
                       placeholder="Kodunuzu Girin"
                       error={this.validator.message('promo code', promoCode, 'required')}
                       className="promo-code-modal__input"
                />
                <Button className="promo-code-modal__submit" onClick={this.submit}>
                    Uygula
                </Button>
                <div className="promo-code-modal__cancel-container">
                    <span onClick={this.closeModal} className="link cancel-button">İptal</span>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({});

PromoCodeModal.propTypes = {
    setModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeModal);
