import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../elements/TextInput';
import { connect } from 'react-redux';
import getValidator from '../../../getValidator';
import Button from '../../elements/Button';
import { resendOtp } from '../../../store/actions/memberActions';

class SmsCodeConfirmationCardModal extends Component {

    validator = getValidator(this);

    state = {
        smsCode: ''
    };
    smsCodeHandler = event => {
        const smsCode = event.target.value;
        this.setState({ smsCode });
    };

    submit = () => {
        const { confirmTransaction, setModal } = this.props;
        const { smsCode } = this.state;

        this.validator.showMessages();

        if (this.validator.allValid()) {
            confirmTransaction(smsCode, setModal);

            setModal('');
        }
    };

    resendOtpSubmit = () => {
        resendOtp();
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        const { smsCode } = this.state;
        const { masterpassResponse, messageText, modalHeading } = this.props;

        return (
            <div className="modal-content credit-card-modal">
                <div className="modal-content__header">
                    {modalHeading}
                </div>

                <div className="credit-card-modal__input-block">
                    <div className="credit-card-modal__input-row">
                        <div className="credit-card-modal__label">
                            {messageText} {masterpassResponse ? masterpassResponse : '6 haneli SMS kodunu giriniz.'}
                        </div>
                        <TextInput
                            value={smsCode}
                            onChange={this.smsCodeHandler}
                            className="credit-card-modal__input"
                            maxLength={6}
                            error={this.validator.message('sms kodunu', smsCode, 'required')}
                        />
                    </div>
                </div>
                <div className="credit-card-modal__buttons-container">
                    <Button color="light_green"
                            className="credit-card-modal__button"
                            onClick={this.submit}
                    >
                        Onayla
                    </Button>
                    <Button color="white"
                            className="credit-card-modal__button"
                            onClick={this.resendOtpSubmit}
                    >
                        Tekrar SMS Yolla
                    </Button>
                    <Button color="white"
                            className="credit-card-modal__button"
                            onClick={this.closeModal}
                    >
                        İptal
                    </Button>
                </div>
                <div className="masterpass">
                    <img src="/images/masterpass.svg" alt="masterpass.svg"/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    masterpassResponse: store.member.masterpassResponse
});

const mapDispatchToProps = dispatch => ({});

SmsCodeConfirmationCardModal.propTypes = {
    masterpassResponse: PropTypes.string,
    setModal: PropTypes.func.isRequired,
    confirmTransaction: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsCodeConfirmationCardModal);