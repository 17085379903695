import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatMoney, extraProductOptionType, maxIngredientCount } from '../../utils';
import Icon from '../elements/Icon';
import Alert from '../elements/Alert';
import ModalWithAnimation from '../elements/ModalWithAnimation';
import ExtraOptionsModalContent from './ExtraOptionsModalContent';

class ProductOptions extends Component {
    state = {
        showExtraOptionsModal: false
    };

    getProductOptions = () => {
        const { selectedProduct } = this.props;
        return selectedProduct.options.filter(option => option.optionType !== extraProductOptionType);
    };

    getExtraOption = () => {
        const { selectedProduct } = this.props;
        return selectedProduct.options.find(option => option.optionType === extraProductOptionType);
    };

    openExtraOptionsModal = () => this.setState({ showExtraOptionsModal: true });

    closeExtraOptionsModal = event => {
        event.stopPropagation();
        this.setState({ showExtraOptionsModal: false });
    };

    getSelectedItemName = option => {
        const item = option.items.find(item => item.quantity === 1);
        if (item && item.name) {
            return item.name;
        }
        return '';
    };

    getFieldNameForValidator = option => {
        const { productSection } = this.props;
        if (productSection) {
            return `${productSection.name} ${option.name}`; // validator field should be unique, that's why we should use productSection.name for campaign
        }
        return option.name;
    };

    isItemSelected = (option, item) => this.getSelectedItemName(option) === item.name;

    getClassName = () => {
        const { isSingleProduct } = this.props;
        let className = 'product-options';
        if (isSingleProduct) {
            className += ' product-options--single-product';
        }
        return className;
    };

    getProductOptionClassName = active => {
        let className = 'product-modal__sub-block-item';
        if (active) {
            className += ' product-modal__sub-block-item--active';
        }
        return className;
    };

    getValidatorContainerClassName = option => {
        const { validator } = this.props;
        return validator.messagesShown && !validator.fieldValid(this.getFieldNameForValidator(option))
            ? 'product-modal__sub-block-option-caption'
            : null;
    };

    renderExtraOptionsModalContent = extraOption => {
        const { addExtraIngredient, removeExtraIngredient, getCancelExtraIngredient } = this.props;
        const { showExtraOptionsModal } = this.state;
        if (!showExtraOptionsModal) {
            return null;
        }
        return <ExtraOptionsModalContent extraOption={extraOption}
                                         addExtraIngredient={addExtraIngredient}
                                         removeExtraIngredient={removeExtraIngredient}
                                         getCancelExtraIngredient={getCancelExtraIngredient}
                                         closeModal={this.closeExtraOptionsModal}
        />;
    };

    renderExtraOptionsModal = extraOption => {
        const { showExtraOptionsModal } = this.state;

        return <ModalWithAnimation closeModal={this.closeExtraOptionsModal}
                                   showModalContent={showExtraOptionsModal}
                                   withCloseIcon={false}
                                   className="extra-options-modal-container"
        >
            {this.renderExtraOptionsModalContent(extraOption)}
        </ModalWithAnimation>;
    };

    render() {
        const {
            getSelectOption, validator, selectedProduct, isSingleProduct, cancelProduct, disabled, isSectionOpened,
            getRef
        } = this.props;
        const extraOption = this.getExtraOption();

        if (!isSectionOpened) {
            return this.getProductOptions().map(option =>
                <div className={this.getValidatorContainerClassName(option)} ref={getRef(this.getFieldNameForValidator(option))}>
                    {validator.message(this.getFieldNameForValidator(option), this.getSelectedItemName(option), 'required')}
                </div>
            );
        }

        return <div className={this.getClassName()}>
            {
                !isSingleProduct
                    ? <>
                        <div className="product-options__product-name">
                            <span>{selectedProduct.name}</span>
                            {
                                !disabled
                                    ? <Icon name="cancel" className="product-options__cancel-button" onClick={cancelProduct}/>
                                    : null
                            }
                        </div>
                        <div className="product-options__product-detail">
                            {selectedProduct.detail}
                        </div>
                    </>
                    : null
            }
            <div className="product-modal__sub-block">
                <div>
                    {
                        this.getProductOptions().map(option =>
                            <div className="product-modal__sub-block-option" key={option.id}>
                                <div className="product-modal__sub-block-header">
                                    {
                                        option.name === 'Hamur Seçimi' && 'HAMURUN NASIL OLSUN?'
                                        || option.name === 'Pizza Taban Sos Seçimi' && 'TABAN SOSUN NASIL OLSUN?'
                                    }
                                </div>
                                <div className="product-modal__sub-block-items-container">
                                    {
                                        option.items.map(item =>
                                            <div key={item.id}
                                                 className={this.getProductOptionClassName(this.isItemSelected(option, item))}
                                                 onClick={getSelectOption(option, item)}
                                            >
                                                {
                                                    this.isItemSelected(option, item)
                                                        ? <Icon name="check-filled"
                                                                className="product-modal__sub-block-item-icon product-modal__sub-block-item-icon--active"
                                                        />
                                                        : <div className="product-modal__sub-block-item-icon"/>
                                                }
                                                <span>{item.name}</span>
                                                {
                                                    item.price && item.price.price
                                                        ? <span className="product-modal__dough-extra-price">
                                                        +{formatMoney(item.price.price)} TL
                                                    </span>
                                                        : null
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={this.getValidatorContainerClassName(option)}
                                     ref={getRef(this.getFieldNameForValidator(option))}
                                >
                                    <Alert message={validator.message(
                                        this.getFieldNameForValidator(option),
                                        this.getSelectedItemName(option),
                                        'required'
                                    )}/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                extraOption
                    ? <div className="product-modal__extra-options-container">
                        <div className="product-modal__extra-options">
                            <div className="product-modal__open-extra-options-button"
                                 onClick={this.openExtraOptionsModal}
                            >
                                <span>İstediğiniz malzemeyi seçin</span>
                                <Icon name="chevron-right"/>
                            </div>
                            {this.renderExtraOptionsModal(extraOption)}
                        </div>
                    </div>
                    : null
            }
        </div>;
    }
}

ProductOptions.defaultProps = {
    isSectionOpened: true
};

ProductOptions.propTypes = {
    selectedProduct: PropTypes.object.isRequired,
    getSelectOption: PropTypes.func.isRequired,
    cancelProduct: PropTypes.func,
    addExtraIngredient: PropTypes.func.isRequired,
    removeExtraIngredient: PropTypes.func.isRequired,
    getCancelExtraIngredient: PropTypes.func.isRequired,
    getRef: PropTypes.func.isRequired,
    validator: PropTypes.object.isRequired,
    productSection: PropTypes.object,
    isSingleProduct: PropTypes.bool,
    disabled: PropTypes.bool,
    isSectionOpened: PropTypes.bool
};

export default ProductOptions;
