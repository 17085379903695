import React from 'react';
import PropTypes from "prop-types";
import Icon from "./Icon";

const Toast = props => {
    const { text, status, className } = props;

    const getClassName = () => {
        let result = `icon-with-label toast toast--${status}`;
        if (className) {
            result += ` ${className}`;
        }
        if (!text || text.message === null || text.message === 'null') {
            result += ' toast--invisible';
        }
        return result;
    };

    const getIconClassName = () => status === 'success' ? 'toast__icon icon--green' : 'toast__icon icon--yellow';

    const getIconName = () => status === 'success' ? 'check-filled-round' : 'info';

    const getToastText = () => (text && text.message) || text + '';

    return (
        <div className={getClassName()}>
            <Icon name={getIconName()} className={getIconClassName()} />
            <span>{getToastText()}</span>
        </div>
    );
};

Toast.defaultProps = {
    status: 'success'
};

Toast.propTypes = {
    text: PropTypes.any,
    status: PropTypes.string,
    className: PropTypes.string
};

export default Toast;
