import { isClient } from './utils';

export const getFromLocalStorage = key => {
    if (isClient()) {
        return localStorage.getItem(key);
    }
    return null;
};

export const setLocalStorage = (key, value) => {
    if (isClient()) {
        return localStorage.setItem(key, value);
    }
    return null;
};

export const removeFromLocalStorage = key => {
    if (isClient()) {
        return localStorage.removeItem(key);
    }
    return null;
};

export const getAuthToken = () => getCookie('authToken');

export const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return JSON.parse(decodeURIComponent(parts.pop().split(';').shift()));
    }
};

export const setCookie = (name, value) => {
    document.cookie = `${name}=${encodeURIComponent(JSON.stringify(value))}; path=/;`;
};

export const removeCookie = name => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
};
