import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { setModal, setRedirect } from '../../store/actions/modalActions';
import {
    confirmAddingCard, confirmPurchase, confirmPurchaseAndRegister, confirmLinkCustomer, resendOtp
} from '../../store/actions/memberActions';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Modal from '../elements/Modal';
import AddAddressModal from '../profile/address/AddAddressModal';
import EditAddressModal from '../profile/address/EditAddressModal';
import ViewPromotionModal from '../product/ViewPromotionModal';
import ViewProductModal from '../product/ViewProductModal';
import BasketModal from '../basket/BasketModal';
import PromoCodeModal from '../checkout/PromoCodeModal';
import ChangePasswordModal from '../profile/account/ChangePasswordModal';
import OrderDetailsModal from '../profile/orders/OrderDetailsModalContent';
import OrderCommentModal from '../profile/orders/OrderCommentModal';
import ProfileFavoritesModal from '../profile/favorites/ProfileFavoritesModal';
import AddCreditCardModal from '../profile/payment-settings/AddCreditCardModal';
import SmsCodeConfirmationCardModal from '../profile/payment-settings/SmsCodeConfirmationCardModal';
import SuccessfullyAddedCardModal from '../profile/payment-settings/SuccessfullyAddedCardModal';
import SuccessfullyDeletedCardModal from '../profile/payment-settings/SuccessfullyDeletedCardModal';
import SuccessfullyLinkedCustomer from '../profile/payment-settings/SuccessfullyLinkedCustomer';
import ShowCreditCardsModal from '../profile/payment-settings/ShowCreditCardsModal';
import EditCreditCardModal from '../profile/payment-settings/EditCreditCardModal';
import DeliveryTypeModal from '../address-selection/DeliveryTypeModal';
import DeliveryAddressModal from '../address-selection/DeliveryAddressModal';
import VehicleDeliveryModal from '../profile/orders/VehicleDeliveryModal';
import TakeAwayMapModal from '../address-selection/TakeAwayMapModal';
import VehicleDeliveryMapModal from '../address-selection/VehicleDeliveryMapModal';
import AddCouponModal from '../home/AddCouponModal';
import SecurityIframeModal from '../profile/payment-settings/SecurityIframeModal';
import ProfileMenuModal from '../profile/ProfileMenuModal';
import GiftWonModal from '../loyalty/GiftWonModal';
import LoyaltyActivatedNotificationModal from '../loyalty/LoyaltyActivatedNotificationModal';
import SmsCodeConfirmationPhoneModal from '../account/SmsCodeConfirmationPhoneModal';
import ConfirmationPhoneSuggestionModal from '../account/ConfirmationPhoneSuggestionModal';

const popupList = [
    'promo-code', 'change-password', 'gift-won', 'successfully-added-credit-card',
    'successfully-deleted-credit-card', 'loyalty-activated-notification'
];

class ModalLayout extends Component {
    state = {
        modalContent: null,
        showModalContent: false,
        modalInfo: {
            name: '',
            params: []
        },
        isCloseModalOnClickOutside: true
    };

    componentDidMount() {
        const { modalInfoString } = this.props;
        document.addEventListener('keydown', this.onKeyPress);
        const modalInfo = this.parseModalString(modalInfoString);
        this.setState({ modalInfo, showModalContent: !!modalInfo.name }, this.setModalContent);
    }

    componentDidUpdate(prevProps, prevState) {
        const prevModalName = this.parseModalString(prevProps.modalInfoString).name;
        const modalInfo = this.parseModalString(this.props.modalInfoString);
        if (prevModalName !== modalInfo.name) {
            if (prevModalName) {
                this.setState({ showModalContent: false });
            } else {
                this.setState({ showModalContent: true, modalInfo });
                this.setModalContent();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPress);
    }

    setModalContent = () => this.setState({ modalContent: this.getModalContent() });

    setNewModal = () => {
        const { modalInfoString } = this.props;
        const modalInfo = this.parseModalString(modalInfoString);
        this.setState({ modalInfo });
        if (modalInfo.name) {
            this.setState({ showModalContent: true });
        }
    };

    onKeyPress = event => {
        const { boundSetModal } = this.props;
        if (event.key === 'Escape') {
            boundSetModal('');
        }
    };

    closeModal = event => {
        const { boundSetModal } = this.props;
        const { isCloseModalOnClickOutside } = this.state;

        if (event.target.dataset.closeModal && isCloseModalOnClickOutside) {
            boundSetModal('');
        }
    };

    getClassName = () => {
        const { modalInfo } = this.state;
        if (modalInfo.name === 'basket') {
            return 'modal--basket';
        }
        if (modalInfo.name === 'profile-menu') {
            return 'modal--profile-menu';
        }
        if (popupList.includes(modalInfo.name)) {
            return 'modal--popup';
        }
        return '';
    };

    parseModalString = modalString => {
        const splittedModalString = modalString.split('/');
        return {
            name: splittedModalString[0],
            params: splittedModalString.slice(1)
        };
    };

    getIsWithCloseIcon = () =>
        this.props.modalInfoString !== 'basket'
        && this.props.modalInfoString !== 'gift-won'
        && this.props.modalInfoString !== 'successfully-added-credit-card'
        && this.props.modalInfoString !== 'successfully-deleted-credit-card'
        && this.props.modalInfoString !== 'loyalty-activated-notification';

    getModalContent = () => {
        const {
            boundSetModal, boundSetRedirect, boundConfirmAddingCard, boundConfirmLinkCustomer, boundConfirmPurchase,
            boundConfirmPurchaseAndRegister, boundResendOtp
        } = this.props;
        const { modalInfo } = this.state;

        switch (modalInfo.name) {
            case 'delivery-type':
                this.setState({ isCloseModalOnClickOutside: true });
                return <DeliveryTypeModal setModal={boundSetModal}/>;
            case 'delivery-address':
                this.setState({ isCloseModalOnClickOutside: true });
                return <DeliveryAddressModal setModal={boundSetModal} setRedirect={boundSetRedirect}/>;
            case 'takeaway-map':
                this.setState({ isCloseModalOnClickOutside: true });
                return <TakeAwayMapModal setModal={boundSetModal}/>;
            case 'vehicle-delivery-map':
                this.setState({ isCloseModalOnClickOutside: true });
                return <VehicleDeliveryMapModal setModal={boundSetModal}/>;
            case 'add-address':
                this.setState({ isCloseModalOnClickOutside: true });
                return <AddAddressModal setModal={boundSetModal} setRedirect={boundSetRedirect}/>;
            case 'edit-address':
                this.setState({ isCloseModalOnClickOutside: true });
                return <EditAddressModal setModal={boundSetModal} addressId={modalInfo.params[0]}
                                         id={modalInfo.params[1]}/>;
            case 'view-promotion':
                this.setState({ isCloseModalOnClickOutside: true });
                return <ViewPromotionModal setModal={boundSetModal} productId={modalInfo.params[0]}/>;
            case 'view-product':
                this.setState({ isCloseModalOnClickOutside: true });
                return <ViewProductModal setModal={boundSetModal} productId={modalInfo.params[0]}/>;
            case 'basket':
                this.setState({ isCloseModalOnClickOutside: true });
                return <BasketModal setModal={boundSetModal} setRedirect={boundSetRedirect}/>;
            case 'promo-code':
                this.setState({ isCloseModalOnClickOutside: true });
                return <PromoCodeModal setModal={boundSetModal}/>;
            case 'change-password':
                this.setState({ isCloseModalOnClickOutside: true });
                return <ChangePasswordModal setModal={boundSetModal}/>;
            case 'order-details':
                this.setState({ isCloseModalOnClickOutside: true });
                return <OrderDetailsModal setModal={boundSetModal} orderId={modalInfo.params[0]}/>;
            case 'order-comment':
                this.setState({ isCloseModalOnClickOutside: true });
                return <OrderCommentModal setModal={boundSetModal} orderId={modalInfo.params[0]}/>;
            case 'profile-favorites':
                this.setState({ isCloseModalOnClickOutside: true });
                return <ProfileFavoritesModal setModal={boundSetModal} orderId={modalInfo.params[0]}/>;
            case 'vehicle-delivery':
                this.setState({ isCloseModalOnClickOutside: true });
                return <VehicleDeliveryModal setModal={boundSetModal} orderId={modalInfo.params[0]}/>;
            case 'add-credit-card':
                this.setState({ isCloseModalOnClickOutside: true });
                return <AddCreditCardModal setModal={boundSetModal}/>;
            case 'sms-code-confirmation-credit-card':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmAddingCard}
                    resendOtp={boundResendOtp}
                    messageText="Bankanız tarafından gönderilen"
                    modalHeading="Banka Doğrulaması"
                />;
            case 'sms-code-confirmation-credit-card5001':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmAddingCard}
                    resendOtp={boundResendOtp}
                    messageText="Bankanız tarafından gönderilen"
                    modalHeading="Banka Doğrulaması"
                />;
            case 'sms-code-confirmation-credit-card5008':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmAddingCard}
                    resendOtp={boundResendOtp}
                    messageText=""
                    modalHeading="Telefon Doğrulaması"
                />;
            case 'sms-code-confirmation-link-customer':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmLinkCustomer}
                    messageText="MasterPass hesabınızı Little Caesars ile ilişkilendirmek için bankanız tarafından gönderilen"
                    modalHeading="Telefon Doğrulaması"
                    resendOtp={boundResendOtp}
                />;
            case 'sms-code-confirmation-link-customer5001':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmLinkCustomer}
                    messageText="MasterPass hesabınızı Little Caesars ile ilişkilendirmek için bankanız tarafından gönderilen"
                    modalHeading="Banka Doğrulaması"
                    resendOtp={boundResendOtp}
                />;
            case 'sms-code-confirmation-link-customer5008':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmLinkCustomer}
                    messageText="MasterPass hesabınızı Little Caesars ile ilişkilendirmek için Masterpass tarafından gönderilen"
                    modalHeading="Telefon Doğrulaması"
                    resendOtp={boundResendOtp}
                />;
            case 'sms-code-confirmation-purchase':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmPurchase}
                    messageText="Sipariş tamamlama adımı "
                    modalHeading="Banka Doğrulaması"
                    resendOtp={boundResendOtp}
                />;
            case 'sms-code-confirmation-purchase-and-register':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmPurchaseAndRegister}
                    messageText="Sipariş tamamlama ve kartınızı Masterpass'e kaydetme adımı. "
                    modalHeading="Telefon Doğrulaması"
                    resendOtp={boundResendOtp}
                />;
            case 'sms-code-confirmation-purchase-and-register5001':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmPurchaseAndRegister}
                    messageText="Sipariş tamamlama ve kartınızı Masterpass'e kaydetme adımı. "
                    modalHeading="Banka Doğrulaması"
                    resendOtp={boundResendOtp}
                />;
            case 'sms-code-confirmation-purchase-and-register5008':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SmsCodeConfirmationCardModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmPurchaseAndRegister}
                    messageText="Sipariş tamamlama ve kartınızı Masterpass'e kaydetme adımı. "
                    modalHeading="Telefon Doğrulaması"
                    resendOtp={boundResendOtp}
                />;
            case '3d-security-iframe':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SecurityIframeModal setModal={boundSetModal}/>;
            case 'show-cards':
                this.setState({ isCloseModalOnClickOutside: true });
                return <ShowCreditCardsModal setModal={boundSetModal} setRedirect={boundSetRedirect}/>;
            case 'successfully-added-credit-card':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SuccessfullyAddedCardModal setModal={boundSetModal}/>;
            case 'successfully-deleted-credit-card':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SuccessfullyDeletedCardModal setModal={boundSetModal}/>;
            case 'successfully-linked-customer':
                this.setState({ isCloseModalOnClickOutside: true });
                return <SuccessfullyLinkedCustomer setModal={boundSetModal}/>;
            case 'edit-credit-card':
                this.setState({ isCloseModalOnClickOutside: true });
                return <EditCreditCardModal setModal={boundSetModal} creditCardId={modalInfo.params[0]}/>;
            case 'add-coupon':
                this.setState({ isCloseModalOnClickOutside: true });
                return <AddCouponModal setModal={boundSetModal}/>;
            case 'profile-menu':
                this.setState({ isCloseModalOnClickOutside: true });
                return <ProfileMenuModal setModal={boundSetModal}/>;
            case 'gift-won':
                this.setState({ isCloseModalOnClickOutside: true });
                return <GiftWonModal setModal={boundSetModal}/>;
            case 'loyalty-activated-notification':
                this.setState({ isCloseModalOnClickOutside: true });
                return <LoyaltyActivatedNotificationModal setModal={boundSetModal}/>;
            case 'phone-confirmation':
                this.setState({ isCloseModalOnClickOutside: false });
                return <SmsCodeConfirmationPhoneModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmAddingCard}
                />;
            case 'phone-confirmation-suggestion':
                this.setState({ isCloseModalOnClickOutside: false });
                return <ConfirmationPhoneSuggestionModal
                    setModal={boundSetModal}
                    confirmTransaction={boundConfirmAddingCard}
                />;
            default:
                return null;
        }
    };

    render() {
        const { modalContent, showModalContent } = this.state;

        return <CSSTransition in={showModalContent}
                              timeout={300}
                              classNames="modal-animation-container"
                              unmountOnExit
                              onEnter={this.setModalContent}
                              onExited={this.setNewModal}
        >
            <Modal closeModal={this.closeModal}
                   className={this.getClassName()}
                   withCloseIcon={this.getIsWithCloseIcon()}
                   >
                {modalContent}
            </Modal>
        </CSSTransition>;
    }
}

const mapStateToProps = store => ({
    modalInfoString: store.modal.modal
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundSetRedirect: bindActionCreators(setRedirect, dispatch),
    boundConfirmAddingCard: bindActionCreators(confirmAddingCard, dispatch),
    boundResendOtp: bindActionCreators(resendOtp, dispatch),
    boundConfirmLinkCustomer: bindActionCreators(confirmLinkCustomer, dispatch),
    boundConfirmPurchase: bindActionCreators(confirmPurchase, dispatch),
    boundConfirmPurchaseAndRegister: bindActionCreators(confirmPurchaseAndRegister, dispatch)
});

ModalLayout.propTypes = {
    boundSetModal: PropTypes.func.isRequired,
    boundSetRedirect: PropTypes.func.isRequired,
    modalInfoString: PropTypes.string.isRequired,
    boundConfirmAddingCard: PropTypes.func.isRequired,
    boundResendOtp: PropTypes.func.isRequired,
    boundConfirmPurchase: PropTypes.func.isRequired,
    boundConfirmPurchaseAndRegister: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalLayout);
