import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Button extends Component {

    getClassName = () => {
        const { loading, className, color, dontDisable, disabled } = this.props;

        let resultClassName = 'big-button icon-with-label';
        if (loading && !dontDisable) {
            resultClassName += ' big-button--disabled';
        }
        if (disabled) {
            resultClassName += ' big-button--disabled';
        }
        if (color) {
            resultClassName += ` big-button--${color}`;
        }
        if (className) {
            resultClassName += ` ${className}`;
        }
        return resultClassName;
    };

    render() {
        const { children, onClick} = this.props;

        return <div className={this.getClassName()} onClick={onClick}>
            {children}
        </div>;
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({});

Button.propTypes = {
    color: 'green',
    dontDisable: false
};

Button.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    loading: PropTypes.bool,
    dontDisable: PropTypes.bool,
    onClick: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Button);
