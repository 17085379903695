import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../elements/TextInput';
import DropdownList from '../../elements/DropdownList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import getValidator from '../../../getValidator';
import { getAddressTypes, getCities, getTowns, getDistricts, getStreets } from '../../../store/actions/addressActions';
import LcPhoneInput from '../../elements/LcPhoneInput';
import Button from '../../elements/Button';
import Icon from '../../elements/Icon';

class AddressForm extends Component {
    validator = getValidator(this);

    state = {
        name: '',
        phone: '',
        addressTypeId: '',
        cityId: '',
        townId: '',
        districtId: '',
        streetId: '',
        directions: '',
        addressTypeList: [],
        cityList: [],
        townList: [],
        districtList: [],
        streetList: [],
        apartmentName: '',
        entrance: '',
        floorNo: '',
        apartmentNo: '',
        streetAddressTypeId: ''
    };

    componentDidMount() {
        const { user } = this.props;

        if (Object.keys(user).length !== 0) {
          if (user.personPhones[0]) {
              this.phoneInputHandler(`90${user.personPhones[0].number}`);
          }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialData !== this.props.initialData) {
            this.setState(this.props.initialData);
        }
    }

    nameInputHandler = event => this.setState({ name: event.target.value });

    addressTypeChangeHandler = addressTypeId => this.setState({ addressTypeId });

    apartmentNameInputHandler = event => this.setState({ apartmentName: event.target.value });

    addressNoInputHandler = event => this.setState({ addressNo: event.target.value });

    floorNoInputHandler = event => this.setState({ floorNo: event.target.value });

    apartmentNoInputHandler = event => this.setState({ apartmentNo: event.target.value });

    directionsInputHandler = event => this.setState({ directions: event.target.value });

    phoneInputHandler = phone => this.setState({ phone });

    setCity = cityId => this.setState({
        cityId,
        townId: '',
        districtId: '',
        streetId: '',
        townList: [],
        districtList: [],
        streetList: []
    });

    selectCity = cityId => {
        const { boundGetTowns } = this.props;

        this.setCity(cityId);

        boundGetTowns(cityId).then(response => {
            const list = response.data.result;
            const townList = [];

            for (var i = 0; i < list.length; i++) {
                townList.push({
                    label: list[i].name,
                    value: list[i].id
                });
            }

            this.setState({ townList });
        });
    };

    setTown = townId => this.setState({ townId, districtId: '', streetId: '', districtList: [], streetList: [] });

    selectTown = townId => {
        const { boundGetDistricts } = this.props;

        this.setTown(townId);
        boundGetDistricts(townId)
            .then(response => {
                const list = response.data.result;
                const districtList = [];

                for (var i = 0; i < list.length; i++) {
                    if(list[i].name == '0')
                    continue;
                    districtList.push({
                        label: list[i].name,
                        value: list[i].id
                    });
                }

                this.setState({ districtList });
            });
    };

    setDistrict = districtId => this.setState({ districtId, streetId: '', streetList: [] });

    selectDistrict = districtId => {
        const { boundGetStreets } = this.props;

        this.setDistrict(districtId);
        boundGetStreets(districtId)
            .then(response => {
                const list = response.data.result;
                const streetList = [];

                for (var i = 0; i < list.length; i++) {
                    streetList.push({
                        label: list[i].name,
                        value: list[i].id
                    });
                }

                this.setState({ streetList });
            });
    };

    setStreet = streetId => this.setState({ streetId });

    selectStreet = streetId => {
        this.setStreet(streetId);
        this.setState({ streetId });
    };

    submit = () => {
        const { submit } = this.props;

        this.validator.showMessages();
        if (this.validator.allValid()) {
            submit({ ...this.state });
        }
    };

    getDeleteButtonClassName = () => {
        const { loading } = this.props;
        let className = 'icon-with-label address-modal__delete-button';
        if (loading) {
            className += ' disabled';
        }
        return className;
    };

    render() {
        const { deleteAddress } = this.props;
        const {
            name, addressTypeId, addressTypeList, cityId, cityList, townId, townList,
            districtId, districtList, streetId, streetList, phone,
            apartmentName, addressNo, floorNo, apartmentNo, directions
        } = this.state;
        return <>
            <div className="address-modal__input-block">
                <DropdownList label="Adres Türü"
                              labelClassName="address-modal__label"
                              value={addressTypeId.toString()}
                              onChange={this.addressTypeChangeHandler}
                              containerClassName="address-modal__input"
                              error={this.validator.message('Adres Türü', addressTypeId, 'required')}
                              options={addressTypeList}
                              optionClassName="address-modal__dropdown-option"
                />
                <TextInput label="Adres Adı"
                           labelClassName="address-modal__label"
                           value={name}
                           onChange={this.nameInputHandler}
                           error={this.validator.message('adres adı', name, 'required')}
                />

                <DropdownList label="İl Seçiniz"
                              labelClassName="address-modal__label"
                              value={cityId}
                              placeholder="İl"
                              onChange={this.selectCity}
                              containerClassName="address-modal__input"
                              error={this.validator.message('Şehir', townList.length ? true : '', 'required')}
                              options={cityList}
                              optionClassName="address-modal__dropdown-option"
                />
                <DropdownList label="İlçe Seçiniz"
                              labelClassName="address-modal__label"
                              value={townId}
                              placeholder="İlçe"
                              onChange={this.selectTown}
                              containerClassName="address-modal__input"
                              error={this.validator.message('İlçe', districtList.length ? true : '', 'required')}
                              options={townList}
                              optionClassName="address-modal__dropdown-option"
                />
                <DropdownList label="Mahalle Seçiniz"
                              labelClassName="address-modal__label"
                              value={districtId}
                              placeholder="Mahalle"
                              onChange={this.selectDistrict}
                              containerClassName="address-modal__input"
                              error={this.validator.message('Mahalle', streetList.length ? true : '', 'required')}
                              options={districtList}
                              optionClassName="address-modal__dropdown-option"
                />
                <DropdownList label="Sokak Seçiniz"
                              labelClassName="address-modal__label"
                              value={streetId}
                              placeholder="Sokak"
                              onChange={this.selectStreet}
                              containerClassName="address-modal__input"
                              error={this.validator.message('Sokak', streetId ? true : '', 'required')}
                              options={streetList}
                              optionClassName="address-modal__dropdown-option"
                />
                <TextInput label="Apartman / Site Adı"
                           labelClassName="address-modal__label"
                           value={apartmentName}
                           onChange={this.apartmentNameInputHandler}
                />
                <div className="address-modal__input-block-row">
                    <TextInput type="number"
                               label="Dış Kapı No"
                               labelClassName="address-modal__label"
                               value={addressNo}
                               onChange={this.addressNoInputHandler}
                               className="address-modal__input-row"
                               error={this.validator.message('Dış Kapı No', addressNo, 'required|integer')}
                    />
                    <TextInput label="Giriş / Blok"
                               labelClassName="address-modal__label"
                               value={floorNo}
                               onChange={this.floorNoInputHandler}
                               className="address-modal__input-row"
                    />
                    <TextInput type="number"
                               label="Daire No"
                               labelClassName="address-modal__label"
                               value={apartmentNo}
                               onChange={this.apartmentNoInputHandler}
                               className="address-modal__input-row"
                               error={this.validator.message('Daire No', apartmentNo, 'apartment_number')}
                    />
                </div>
                <TextInput label="Adres Tarifi"
                           labelClassName="address-modal__label"
                           value={directions}
                           onChange={this.directionsInputHandler}
                />
                <LcPhoneInput label="Telefon Numarası"
                              labelClassName="address-modal__label"
                              value={phone}
                              onChange={this.phoneInputHandler}
                              className="address-modal__input"
                              validator={this.validator}
                />
            </div>
            <div>
                <Button className="address-modal__submit-button" onClick={this.submit}>Kaydet</Button>
                {
                    deleteAddress
                        ? <div className="address-modal__delete-button-container">
                            <div onClick={deleteAddress} className={this.getDeleteButtonClassName()}>
                                <Icon name="trash"/>
                                <span>Adresi Sil</span>
                            </div>
                        </div>
                        : null
                }
            </div>
        </>;
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    user: store.user.user
});

const mapDispatchToProps = dispatch => ({
    boundGetAddressTypes: bindActionCreators(getAddressTypes, dispatch),
    boundGetCities: bindActionCreators(getCities, dispatch),
    boundGetTowns: bindActionCreators(getTowns, dispatch),
    boundGetDistricts: bindActionCreators(getDistricts, dispatch),
    boundGetStreets: bindActionCreators(getStreets, dispatch)
});

AddressForm.propTypes = {
    boundGetAddressTypes: PropTypes.func.isRequired,
    boundGetCities: PropTypes.func.isRequired,
    boundGetTowns: PropTypes.func.isRequired,
    boundGetDistricts: PropTypes.func.isRequired,
    boundGetStreets: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
