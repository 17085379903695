import React from 'react';

const Pizza = props =>
<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M19.6997 20.1537C16.1254 24.5351 9.66863 25.1693 5.34488 21.595C0.963473 18.0783 0.329323 11.6215 3.90363 7.2401C6.55553 3.95405 10.8216 2.80105 14.6265 3.8964L15.0877 2.33985C10.7063 1.07155 5.74843 2.45515 2.63532 6.2024C-1.45783 11.2756 -0.708378 18.7125 4.36483 22.8633C9.43803 27.0141 16.8749 26.207 20.968 21.1914C24.0235 17.3865 24.427 12.2557 22.294 8.22016L20.8527 8.96961C22.6975 12.4863 22.3516 16.8677 19.6997 20.1537Z" fill="#F37021"/>
<path d="M16.332 1.5557C17.6003 1.9016 18.8686 2.53575 19.964 3.45815C21.0593 4.38055 21.9241 5.47591 22.5582 6.62891L23.9995 5.87946C23.3077 4.49585 22.27 3.22755 21.0017 2.18985C19.7334 1.15215 18.2921 0.402702 16.7932 -0.000847816L16.332 1.5557Z" fill="#F33A21"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.86213 20.9599C1.88428 17.6739 1.25013 11.7936 4.53618 7.75805C6.95748 4.76025 10.8777 3.6649 14.3367 4.7026L11.6271 13.869L20.1017 9.37226C21.7735 12.6007 21.4853 16.6362 19.064 19.634C15.7779 23.6118 9.89763 24.246 5.86213 20.9599ZM6.77651 8.81083C7.52704 8.81083 8.13547 9.41926 8.13547 10.1698C8.13547 10.9203 7.52704 11.5287 6.77651 11.5287C6.02598 11.5287 5.41755 10.9203 5.41755 10.1698C5.41755 9.41926 6.02598 8.81083 6.77651 8.81083ZM18.1717 17.2284C18.1717 16.4778 17.5632 15.8694 16.8127 15.8694C16.0622 15.8694 15.4538 16.4778 15.4538 17.2284C15.4538 17.9789 16.0622 18.5873 16.8127 18.5873C17.5632 18.5873 18.1717 17.9789 18.1717 17.2284ZM8.4331 16.4504C9.18363 16.4504 9.79206 17.0588 9.79206 17.8093C9.79206 18.5599 9.18363 19.1683 8.4331 19.1683C7.68257 19.1683 7.07414 18.5599 7.07414 17.8093C7.07414 17.0588 7.68257 16.4504 8.4331 16.4504Z" fill="#F37021"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.1002 2.36294C17.2532 2.70884 18.4062 3.28534 19.4439 4.09244C20.4816 4.89954 21.2887 5.93724 21.8652 7.03259L13.3906 11.5293L16.1002 2.36294ZM17.6897 4.99819C18.4402 4.99819 19.0487 5.60661 19.0487 6.35714C19.0487 7.10767 18.4402 7.7161 17.6897 7.7161C16.9392 7.7161 16.3308 7.10767 16.3308 6.35714C16.3308 5.60661 16.9392 4.99819 17.6897 4.99819Z" fill="#F33A21"/>
</svg>
;

export default Pizza;
