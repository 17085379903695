import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    addToBasketByProductId, getBasket, removeFromBasket, setBasket, updateBasketCount
} from '../../store/actions/basketActions';
import OrderItem from './OrderItem';
import { addCartOrderItemAction } from '../../googleAnalyticsHelper';
import { formatMoney, getOrderLoyaltyBlockText, shouldShowGiftProducts } from '../../utils';
import { getFromLocalStorage } from '../../storage';
import Button from '../elements/Button';
import { withRouter } from 'react-router-dom';
import { setModal } from '../../store/actions/modalActions';
import RemoveItemFromBasketPopup from './RemoveItemFromBasketPopup';

class OrderItemList extends Component {
    state = {
        selectedBasket: {},
        deletingItemIds: [],
        shouldUpdateBasket: false,
        itemToRemove: null
    };

    componentDidMount() {
        const { basket } = this.props;
        this.setState({ selectedBasket: basket });
    }

    componentDidUpdate(prevProps, prevState) {
        const { basket } = this.props;
        const { selectedBasket } = this.state;
        if (basket !== selectedBasket) {
            this.setState({ selectedBasket: basket });
        }
        if (prevProps.loading === true && this.props.loading === false && this.state.shouldUpdateBasket) {
            this.getBasket();
            this.setState({ shouldUpdateBasket: false });
        }
    }

    updateBasket = (item, action) => {
        const { loading } = this.props;
        if (!loading) {
            this.getBasket(item, action);
        } else {
            this.setState({ shouldUpdateBasket: true });
        }
    };

    setBasket = (selectedBasket) => {
        const { boundSetBasket } = this.props;
        boundSetBasket(selectedBasket);
    };

    getBasket = (item, action) => {
        const { boundGetBasket } = this.props;

        boundGetBasket().then(response => {
            this.setBasket(response.payload);

            if (item) {
                const orderItem = this.props.basket.orderItems.find(c => c.productId === item.productId);

                //Check if orderItem is found, then it is obtained from basket, if not then it means it's removed
                if (orderItem)
                    this.addCartOrderItemAction(orderItem, action);
                else
                    this.addCartOrderItemAction(item, action);
            }
        });
    };

    addCartOrderItemAction = (orderItem, action) => {
        const position = parseInt(getFromLocalStorage(`prodPos${orderItem.productId}`));
        const category = getFromLocalStorage(`pCategory${orderItem.productId}`);
        const list = getFromLocalStorage(`pList${orderItem.productId}`);

        addCartOrderItemAction(orderItem, position, action, category, list);
    };

    getIncrementCount = orderItem => () => {
        const { boundUpdateBasketCount } = this.props;
        const { selectedBasket } = this.state;
        const newSelectedBasket = { ...selectedBasket };
        const newQuantity = orderItem.quantity + 1;

        if (newQuantity > 9) {
            return;
        }
        newSelectedBasket.orderItems.find(oI => oI.id === orderItem.id).quantity = orderItem.quantity + 1;
        this.setBasket(newSelectedBasket);
        boundUpdateBasketCount(orderItem.id, newQuantity).then(() => this.updateBasket(orderItem, 'increment'));
    };

    getDecrementCount = orderItem => () => {
        const { boundUpdateBasketCount } = this.props;
        const { selectedBasket } = this.state;
        const newSelectedBasket = { ...selectedBasket };

        const newQuantity = orderItem.quantity - 1;
        if (newQuantity >= 1) {
            newSelectedBasket.orderItems.find(oI => oI.id === orderItem.id).quantity = orderItem.quantity - 1;
            this.setBasket(newSelectedBasket);
            boundUpdateBasketCount(orderItem.id, newQuantity).then(() => this.updateBasket(orderItem, 'decrement'));
        } else if (newQuantity === 0) {
            this.getOpenRemoveItemPopup(orderItem)();
        }
    };

    removeOrderItem = () => {
        const { boundRemoveFromBasket } = this.props;
        const { itemToRemove } = this.state;
        const { selectedBasket, deletingItemIds } = this.state;
        const newDeletingItemIds = [...deletingItemIds];
        newDeletingItemIds.push(itemToRemove.id);
        this.setState({ deletingItemIds: newDeletingItemIds });
        this.closeRemoveItemPopup();
        boundRemoveFromBasket(itemToRemove.id, selectedBasket.id)
            .then(() => this.updateBasket(itemToRemove, 'remove'))
            .catch(() => {
                newDeletingItemIds.splice(newDeletingItemIds.indexOf(itemToRemove.id));
                this.setState({ deletingItemIds: newDeletingItemIds });
            });
    };

    getOpenRemoveItemPopup = item => () => this.setState({ itemToRemove: item });

    closeRemoveItemPopup = () => this.setState({ itemToRemove: null });

    getAddFlavorToBasket = flavor => () => {
        const { boundAddToBasketByProductId } = this.props;
        const { selectedBasket } = this.state;
        boundAddToBasketByProductId(flavor.id, selectedBasket.id).then(this.updateBasket);
    };

    getDeleteButtonClassName = orderItem => {
        const { deletingItemIds } = this.state;
        let className = 'basket-modal__delete-icon';
        if (deletingItemIds.includes(orderItem.id)) {
            className += ' disabled';
        }
        return className;
    };

    goToProductsPage = () => {
        const { history, location, boundSetModal } = this.props;
        if (
            !location.pathname.includes('/siparis/kampanyalar')
            && !location.pathname.includes('/siparis/pizzalar')
            && !location.pathname.includes('/siparis/yan-urunler')
        ) {
            history.push('/siparis/kampanyalar');
        }
        boundSetModal('');
    };

    goToLoyaltyPage = () => {
        const { history, location, boundSetModal } = this.props;
        if (location.pathname !== '/sezarla-kazan') {
            history.push('/sezarla-kazan');
        }
        boundSetModal('');
    };

    renderLoyaltyBlock = () => {
        const { basket, user } = this.props;

        const loyaltyBlockText = getOrderLoyaltyBlockText(basket, user);
        if (!loyaltyBlockText) {
            return null;
        }
        return <div className="basket-modal__loyalty"
                    onClick={shouldShowGiftProducts(basket, user) ? this.goToLoyaltyPage : null}
        >
            <img src="/images/loyalty-small-logo.png"
                 alt="loyalty-small-logo.png"
                 className="basket-modal__loyalty-image"
            />
            <div className="basket-modal__loyalty-text">
                {loyaltyBlockText}
            </div>
        </div>
    };

    render() {
        const { selectedBasket, itemToRemove } = this.state;
        let orderItemsAmount = 0;
        let promotions = [];

        if (!selectedBasket.id) {
            return null;
        }

        if (!selectedBasket.orderItems.length) {
            return <div className="basket-modal__empty-basket-container">
                <div>
                    <img src="/images/basket/empty-basket.png" alt="empty-basket.png"/>
                </div>
                <div className="basket-modal__empty-basket-text">
                    Sepetinizde henüz ürün bulunmamaktadır.
                </div>
                <div className="basket-modal__empty-basket-button">
                    <Button color="dark_orange" onClick={this.goToProductsPage}>
                        <span>Ürün Ekle</span>
                    </Button>
                </div>
            </div>;
        }

        return <div>
            {
                itemToRemove
                    ? <RemoveItemFromBasketPopup submit={this.removeOrderItem}
                                                 close={this.closeRemoveItemPopup}
                    />
                    : null
            }
            <div className="basket-modal__order-items-container">
                {
                    selectedBasket.orderItems.map(orderItem => {
                        orderItemsAmount += +orderItem.amount;

                        return <OrderItem key={orderItem.id}
                                          orderItem={orderItem}
                                          onDecrementClick={this.getDecrementCount(orderItem)}
                                          onIncrementClick={this.getIncrementCount(orderItem)}
                                          onDeleteOrderItemClick={this.getOpenRemoveItemPopup(orderItem)}
                                          deleteClassName={this.getDeleteButtonClassName(orderItem)}
                        />;
                    })
                }
                {
                    selectedBasket.promotions && selectedBasket.promotions.forEach(promotion => {
                        for (let i = 0; i < selectedBasket.promotions.length; i++) {
                            promotions[i] = promotion;
                        }
                    })
                }
                {this.renderLoyaltyBlock()}
            </div>

            <div className="basket-modal__price-block">
                {/*{*/}
                {/*    selectedBasket.promotions.length || selectedBasket.orderPromotion*/}
                {/*        ? <div className="basket-modal__total-price-container">*/}
                {/*            <div className="basket-modal__total-price-text">*/}
                {/*                toplam tutar*/}
                {/*            </div>*/}
                {/*            <div className="basket-modal__total-price-promotion-container">*/}
                {/*                <div lang="tr" className="basket-modal__total-price-promotion-name">*/}
                {/*                    {*/}
                {/*                        promotions.length > 0*/}
                {/*                            ? promotions.map(promotion => promotion.promotion.name)*/}
                {/*                            : null*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*                <div className="basket-modal__total-price-amount">*/}
                {/*                    {formatMoney(orderItemsAmount)} TL*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        : null*/}
                {/*}*/}
                {
                    selectedBasket.promotions.length
                        ? <div className="basket-modal__price-container basket-modal__price-container--extra">
                            <div className="basket-modal__extra-price-text">
                                {selectedBasket.promotions[0].promotion.name}
                            </div>
                            <div className="basket-modal__extra-price-amount">
                                {formatMoney(selectedBasket.promotions[0].amount)} TL
                            </div>
                        </div>
                        : null
                }
                <div className="basket-modal__price-container">
                    <div className="basket-modal__price-text">
                        {
                            selectedBasket.promotions.length || selectedBasket.orderPromotion
                                ? <span>indirimli tutar</span>
                                : <span>toplam tutar</span>
                        }
                    </div>
                    <div className="basket-modal__price-amount">
                        {formatMoney(selectedBasket.amount)} TL
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    basket: store.basket.basket,
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({
    boundUpdateBasketCount: bindActionCreators(updateBasketCount, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundRemoveFromBasket: bindActionCreators(removeFromBasket, dispatch),
    boundAddToBasketByProductId: bindActionCreators(addToBasketByProductId, dispatch),
    boundSetBasket: bindActionCreators(setBasket, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

OrderItemList.defaultProps = {
    sideProductList: []
};

OrderItemList.propTypes = {
    boundUpdateBasketCount: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundRemoveFromBasket: PropTypes.func.isRequired,
    boundAddToBasketByProductId: PropTypes.func.isRequired,
    boundSetBasket: PropTypes.func.isRequired,
    basket: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderItemList));
