import React from 'react';
import CorporateMenu from '../../corporate/corporate-menu/CorporateMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const ProductPolicy = () => {
    sendPageView('/kurumsal/helal-gida-politikası', 'helal gıda politikası - Little Caesars');

    return <section className="institutional-structures">
        <div className="container">
            <div className="institutional-structures-detail">
                <div className="institutional-structures-detail-content clearfix">

                    <CorporateMenu menuType='quality'/>

                    <div className="col-md-9">
                        <div className="corpareta-detail product-policy clearfix">
                            <h1>Helal Gıda Politikası</h1>
                            <img src="/images/kurumsal/kalite/kalite.jpg" alt="Kalite"/>
                            <h5>
                            Çelebi Hizmet Gıda İşletmeleri Turizm San. ve Tic. A.Ş - Little Caesars Pizza Türkiye olarak Helal 22
                            </h5>
                            <p>
                            kapsamında belirttiğimiz ürün ve hizmetlerde, sorumlu olduğumuz Türk Gıda Kodeksi gibi yasal
                            </p>
                            <p>
                            şartlar ve İslami kurallar çerçevesinde Helal 22 ve ISO 9001 : 2015 standardına uygun şekilde
                            </p>
                            <p>
                            üretim yapmak ve hizmet sağlamak, yönetim sistemimizi sürekli geliştirmek, çalışanlarımızın Helal
                            </p>
                            <p>
                            gıda eğitim ve bilincine sahip olmasını sağlamak, Helal gıda olma şartlarını yerine getirmeyen
                            </p>
                            <p>
                            gıdaları HelalDer'e bildirmek sorumluluğumuzdadır.
                            </p>
                            <div className="item"><span className="fr bold">HG.PL.001</span></div>
                            <div className="item"><span className="fl bold">REV.01</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default ProductPolicy;
