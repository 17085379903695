import api, { handleRequest } from '../../api';
import { setBasket } from './basketActions';

export const SET_ORDERS = 'SET_ORDERS';
export const SET_EASY_ORDERS = 'SET_EASY_ORDERS';
export const SET_LAST_ORDER = 'SET_LAST_ORDER';

export const setOrders = response => ({
    type: SET_ORDERS,
    payload: response
});

export const setLastOrder = response => ({
    type: SET_LAST_ORDER,
    payload: response
});

export const getPaymentTypes = orderId => dispatch => {
    const query = orderId ? `?existingOrderId=${orderId}` : '';
    return api.get(`web/Order/GetPaymentTypes${query}`, dispatch)
        .then(response => handleRequest(response));
};

export const removeAllOrders = (basketID) => dispatch => {
    return api.post('web/Order/EmptyBasket', {
        OrderId: basketID
    }, dispatch)
        .then(response => handleRequest(response));
};

export const completeOrder = (orderId, paymentTypeId, orderNote, deliveryDate, addressId, orderTypeId, webPaymentToken) => dispatch => {
    return api.put('web/Order/CompleteOrder', {
        Id: orderId,
        PaymentTypeId: paymentTypeId,
        OrderNote: orderNote,
        DeliveryDate: deliveryDate,
        AddressId: addressId,
        OrderTypeId: orderTypeId,
        token: webPaymentToken
    }, dispatch)
        .then(response => handleRequest(response));
};

export const getOrders = () => dispatch => {
    const query = 'PageNo=1&PageSize=5';
    return api.get(`web/Order/GetOrders?${query}`, dispatch)
        .then(response => handleRequest(response, () => dispatch(setOrders(response.data.result))));
};

export const getOrder = id => dispatch => {
    const query = id ? `?Id=${id}` : '';
    return api.get(`web/Order/GetOrders${query}`, dispatch)
        .then(response => handleRequest(response));
};

export const getLastOrder = () => dispatch =>
    api.get('web/Order/GetLastOrder', dispatch)
        .then(response => handleRequest(response, () => dispatch(
            setLastOrder(
                Array.isArray(response.data.result) && response.data.result.length
                    ? response.data.result[0]
                    : {}
            )
        )));

export const copyOrderAndGetBasket = (previousOrderId, currentOrderId) => dispatch => {
    const query = `?previousOrderId=${previousOrderId}&currentOrderId=${currentOrderId}`;
    return api.get(`web/Order/CopyOrderAndGenerateNewBasket${query}`, dispatch)
        .then(response => handleRequest(response, () => dispatch(setBasket(response.data.result))));
};

export const getOrderNotes = () => dispatch =>
    api.get('web/Order/GetOrderNotes', dispatch)
        .then(response => handleRequest(response));

export const addOrderNote = (personId, note) => dispatch =>
    api.post('web/Order/AddOrderNote', {
        PersonId: personId,
        Note: note
    }, dispatch)
        .then(response => handleRequest(response));

export const checkIfVehiclePickupIsReady = (orderId, orderStatusId) => dispatch => {
    const query = `?orderId=${orderId}&orderStatusId=${orderStatusId}`;
    return api.get(`web/ExternalServices/IsVehiclePickupReady${query}`, dispatch)
        .then(response => handleRequest(response));
};

export const sendReadyForPickup = (orderId, vehiclePlateNumber, vehicleDetail) => dispatch => {
    const query = `?orderId=${orderId}&plateNumber=${vehiclePlateNumber}&message=${vehicleDetail}`;
    return api.get(`web/ExternalServices/ReadyForPickup${query}`, dispatch)
        .then(response => handleRequest(response));
};
