import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { getProductDetails } from '../../store/actions/productActions';
import { connect } from 'react-redux';
import Icon from '../elements/Icon';
import { withRouter } from 'react-router-dom';

class GiftWonModal extends Component {
    state = {
        bonusProduct: {}
    };

    componentDidMount() {
        this.getProductDetails();
    }

    getProductDetails = () => {
        const { boundGetProductDetails, user, basket } = this.props;
        if (
            user.loyalty && user.loyalty.punchCard
            && user.loyalty.punchCard.canGetGift === 'true'
            && user.loyalty.punchCard.canOrderGiftProductID
            && user.loyalty.punchCard.canOrderGiftProductID !== '0'
        ) {
            boundGetProductDetails(user.loyalty.punchCard.canOrderGiftProductID, basket.id)
                .then(response => this.setState({ bonusProduct: response.data.result }));
        }
    };

    goToLoyaltyPage = () => {
        const { history, location } = this.props;
        if (location.pathname !== '/sezarla-kazan') {
            history.push('/sezarla-kazan');
        }
        this.closeModal();
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        const { bonusProduct } = this.state;

        return <div className="modal-content gift-modal">
            <Icon name="cancel" className="modal-alt-close-icon gift-modal__close-icon" onClick={this.closeModal}/>
            <div className="gift-modal__header">Tebrikler</div>
            <img src="/images/loyalty-logo-with-stars.png"
                 alt="loyalty-logo-with-stars.png"
                 className="gift-modal__loyalty-header-image"
            />
            <div className="gift-modal__detail">
                <span className="gift-modal__detail-orange-text">{bonusProduct.name || 'yükleniyor...'}</span> kazandın.
            </div>
            <div className="button loyalty-preview-button button--green-light gift-modal__button"
                 onClick={this.goToLoyaltyPage}
            >
                Hediyeni Al
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    basket: store.basket.basket
});

const mapDispatchToProps = dispatch => ({
    boundGetProductDetails: bindActionCreators(getProductDetails, dispatch)
});

GiftWonModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    boundGetProductDetails: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GiftWonModal));
