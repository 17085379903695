import React from 'react';
import CustomerServiceMenu from '../customer-service-menu/CustomerServiceMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const OrderInformation = () => {
  sendPageView('/musteri-hizmetleri/siparisim-nerde', 'Siparişim Nerede Uygulaması - Little Caesars'); //for ga
   
   return <section class="institutional-structures">
  <div class="container">
    {/* <app-breadcrumbs></app-breadcrumbs> */}

    <div class="institutional-structures-detail">
      <div class="institutional-structures-detail-content clearfix">
        <CustomerServiceMenu />
        <div class="col-md-9">
          <div class="corpareta-detail">
            <h1>Siparişim Nerede Uygulaması</h1>
            <h2>Türkiye'de ilk "eş zamanlı sipariş takibi" Little Caesars'ta!</h2>
            <p>
              Little Caesars’tan sipariş verdiniz ve enfes pizzanız size ne zaman ulaşacak merak ediyorsunuz… Merak etmenize hiç gerek
              yok! Sadece bir tık’la Little Caesars’ın müthiş lezzetlerine ne zaman kavuşacağınızı öğrenebilirsiniz. Little
              Caesars Pizza, Türkiye’de ilk “eş zamanlı sipariş takibi” özelliği ile müşterilerine pizzalarının, patateslerinin
              ya da tatlılarının macerasını takip etme imkanı sunuyor. Little Caesars Pizza internet sitesinden veya mobil
              uygulamasından sipariş verdikten sonra, “Siparişim Nerede” sayfasından siparişlerinizin hangi aşamada olduğunu
              kolaylıkla takip edebililirsiniz! Siparişinizin durumunu anı anına takip ediyor, bilgisini de eş zamanlı olarak
              bu bölümde veriyoruz. Afiyet olsun!
            </p>
            <p>Ayrıca Türkiye'nin en lezzetli pizzası Little Caesars Pizza'nın online sipariş uygulamasını (Android ve IOS)
              cebinize indirerek, size özel kampanya ve fırsatlardan yararlanabilir, sipariş verebilirsiniz.</p>
            <h2>Sipariş takip aşamaları</h2>
            <div class="row order-tracking-description">
              <div class="col-sm-6">
                <img class="img-responsive" src="/images/musteriHizmetleri/takip1.png" alt="takip1.png" />
              </div>
              <div class="col-sm-6">
                <img class="img-responsive" src="/images/musteriHizmetleri/takip2.png" alt="takip2.png" />
              </div>
              <div class="col-sm-6">
                <img class="img-responsive" src="/images/musteriHizmetleri/takip3.png" alt="takip3.png" />
              </div>
              <div class="col-sm-6">
                <img class="img-responsive" src="/images/musteriHizmetleri/takip4.png" alt="takip4.png" />
              </div>
              <div class="col-sm-6">
                <img class="img-responsive" src="/images/musteriHizmetleri/takip5.png" alt="takip5.png" />
              </div>
              <div class="col-sm-6">
                <img class="img-responsive" src="/images/musteriHizmetleri/takip6.png" alt="takip6.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
}

export default OrderInformation;