import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProductDetails, calculatePrice } from '../../store/actions/productActions';
import Icon from '../elements/Icon';
import {
    formatMoney, extraProductOptionType, maxIngredientCount, scrollToWrongField, getRef, isMobile
} from '../../utils';
import ProductOptions from './ProductOptions';
import { addToBasket, getBasket } from '../../store/actions/basketActions';
import { setModal } from '../../store/actions/modalActions';
import Button from '../elements/Button';
import getValidator from '../../getValidator';
import {
    addCartOrderItemAction, addIngredientAction, addProductDetailEvent, sendPageView
} from '../../googleAnalyticsHelper';
import { getFromLocalStorage } from '../../storage';
import { addFavoriteProduct, deleteFavoriteProduct } from '../../store/actions/memberActions';

class ViewProductModal extends Component {
    validator = getValidator(this);

    state = {
        selectedProduct: {},
        price: 0,
        extraIngredientPrice: 0,
        crustSelectionsPrice: 0,
        shouldRecalculate: false
    };

    componentDidMount() {
        const { boundGetProductDetails, basket, location, productId } = this.props;

        boundGetProductDetails(productId, basket.id)
            .then(response => {
                const selectedProduct = response.data.result;
                if (selectedProduct.options) {
                    selectedProduct.options = selectedProduct.options.map(option => {
                        if (option.optionType !== extraProductOptionType && option.items.length === 1) {
                            option.items[0].quantity = 1;
                        }
                        return option;
                    });
                }
                this.setProduct(selectedProduct);

                //For google analytics
                sendPageView(location ? location.pathname : selectedProduct.link, selectedProduct.name);
                const position = parseInt(getFromLocalStorage(`prodPos${selectedProduct.id}`));
                const category = getFromLocalStorage(`pCategory${selectedProduct.id}`);
                const list = getFromLocalStorage(`pList${selectedProduct.id}`);
                addProductDetailEvent(selectedProduct, position, list, category);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedProduct } = this.state;

        if (prevProps.loading === true && this.props.loading === false && this.state.shouldRecalculate) {
            this.calculatePrice(selectedProduct);
            this.setState({ shouldRecalculate: false });
        }
    }

    submit = () => {
        const { basket, closeModal, boundAddToBasket, boundGetBasket, boundSetModal } = this.props;
        const { selectedProduct } = this.state;

        this.validator.showMessages();
        if (this.validator.allValid()) {
            boundAddToBasket(selectedProduct, basket.id)
                .then(() => boundGetBasket().finally(() => {
                    const position = parseInt(getFromLocalStorage(`prodPos${selectedProduct.id}`));
                    const category = getFromLocalStorage(`pCategory${selectedProduct.id}`);
                    const list = getFromLocalStorage(`pList${selectedProduct.id}`);
                    const orderItem = this.props.basket.orderItems.find(c => c.productId === selectedProduct.id);

                    if (orderItem) {
                        addCartOrderItemAction(orderItem, position, 'add', category, list);   //For Google Analytics
                    }

                    if (closeModal) {
                        closeModal();
                    }
                    boundSetModal('basket');
                }));
        } else {
            const scrollElementSelector = isMobile() ? '.modal' : '.product-modal__right';
            setTimeout(() => scrollToWrongField(
                this,
                this.validator,
                document.querySelector(scrollElementSelector),
                20,
                'Promosyon Kodu'
            ), 20);
        }
    };

    getGetRef = () => refName => {
        return getRef(this, refName);
    };

    calculatePrice = product => {
        const { boundCalculatePrice } = this.props;
        boundCalculatePrice(product)
            .then(response => this.setState({ price: response.data }));
    };

    setProduct = product => {
        const { loading } = this.props;

        this.setState({ selectedProduct: product });
        if (!loading) {
            this.calculatePrice(product);
        } else {
            this.setState({ shouldRecalculate: true });
        }
    };

    increaseCount = () => {
        const { selectedProduct } = this.state;
        this.setProduct({ ...selectedProduct, quantity: selectedProduct.quantity + 1 });
    };

    decreaseCount = () => {
        const { selectedProduct } = this.state;
        if (selectedProduct.quantity > 1) {
            this.setProduct({ ...selectedProduct, quantity: selectedProduct.quantity - 1 });
        }
    };

    getSelectOption = (option, item) => () => {
        const { selectedProduct } = this.state;
        const newSelectedProduct = { ...selectedProduct };
        const optionItems = newSelectedProduct.options.find(o => o.id === option.id).items;
        const prevSelectedItem = optionItems.find(i => i.quantity === 1);
        let extraPrice = 0;

        if (prevSelectedItem) {
            prevSelectedItem.quantity = 0;
        }
        optionItems.find(i => i.id === item.id).quantity = 1;
        const crustSelections = optionItems.filter(c => c.quantity === 1);
        for (let i = 0; i < crustSelections.length; i++) {
            extraPrice += crustSelections[i].price.price;
        }
        this.setState({ crustSelectionsPrice: extraPrice });
        this.setProduct(newSelectedProduct);
    };

    getExtraIngredient = (newSelectedProduct, item) =>
        newSelectedProduct.options
            .find(option => option.optionType === extraProductOptionType).items.find(i => i.id === item.id);

    addExtraIngredient = item => {
        const { selectedProduct, extraIngredientPrice } = this.state;
        let extraPrice = extraIngredientPrice;
        const newSelectedProduct = { ...selectedProduct };
        const ingredient = this.getExtraIngredient(newSelectedProduct, item);

        if (ingredient.quantity >= maxIngredientCount) {
            return;
        }
        ingredient.quantity++;
        extraPrice += ingredient.price.price;
        this.setState({ extraIngredientPrice: extraPrice });
        this.setProduct(newSelectedProduct);

        addIngredientAction('Ekle', ingredient); //for google analytics
    };

    removeExtraIngredient = item => {
        const { selectedProduct, extraIngredientPrice } = this.state;
        let extraPrice = extraIngredientPrice;
        const newSelectedProduct = { ...selectedProduct };
        const ingredient = this.getExtraIngredient(newSelectedProduct, item);

        if (ingredient.quantity <= 0) {
            return;
        }
        ingredient.quantity--;
        extraPrice -= ingredient.price.price;
        this.setState({ extraIngredientPrice: extraPrice });
        this.setProduct(newSelectedProduct);

        addIngredientAction('Çıkar', ingredient); //for google analytics
    };

    getCancelExtraIngredient = item => () => {
        const { selectedProduct, extraIngredientPrice } = this.state;
        let extraPrice = extraIngredientPrice;
        const newSelectedProduct = { ...selectedProduct };
        const ingredient = this.getExtraIngredient(newSelectedProduct, item);

        extraPrice -= ingredient.quantity * ingredient.price.price;
        ingredient.quantity = 0;
        this.setState({ extraIngredientPrice: extraPrice });
        this.setProduct(newSelectedProduct);

        addIngredientAction('Çıkar', ingredient); //for google analytics
    };

    toggleFavorite = () => {
        const { boundAddFavoriteProduct, boundDeleteFavoriteProduct } = this.props;
        const { selectedProduct } = this.state;
        if (selectedProduct.isFavorite) {
            boundDeleteFavoriteProduct(selectedProduct.favoriteId)
                .then(() => this.setState({ selectedProduct: { ...selectedProduct, isFavorite: false } }));
        } else {
            boundAddFavoriteProduct(selectedProduct.id)
                .then(response => this.setState({
                    selectedProduct: { ...selectedProduct, isFavorite: true, favoriteId: response.data.result.id }
                }));
        }
    };

    getFavoriteButtonClassName = mode => {
        const { loading } = this.props;
        const { selectedProduct } = this.state;
        let className = `product-modal__favorite-button icon-with-label product-modal__favorite-button--${mode}`;
        if (loading) {
            className += ' product-modal__favorite-button--disabled';
        }
        if (selectedProduct.isFavorite) {
            className += ' product-modal__favorite-button--active';
        }
        return className;
    };

    getFavoriteButtonIconClassName = () => {
        const { selectedProduct } = this.state;
        let className = 'product-modal__favorite-button-icon';
        if (selectedProduct.isFavorite) {
            className += ' product-modal__favorite-button-icon--active';
        }
        return className;
    };

    render() {
        const { user } = this.props;
        const { selectedProduct, price, extraIngredientPrice, crustSelectionsPrice } = this.state;
        const totalExtraPrice = extraIngredientPrice + crustSelectionsPrice;

        this.validator.purgeFields();
        if (!selectedProduct.id) {
            return null;
        }
        let productModalLeftClassName = '';
        if (selectedProduct.options && selectedProduct.options.length) {
            productModalLeftClassName = 'product-modal__left';
        } else {
            const modalBodyEl = document.querySelector('.modal__body');
            modalBodyEl && modalBodyEl.classList.add('modal__body--small');
            productModalLeftClassName = 'product-modal__central';
        }

        return <div className="modal-content product-modal">
            <div className={productModalLeftClassName}>
                <div className="product-modal__image-container">
                    {
                        selectedProduct.image
                            ? <img className="product-modal__image" src={selectedProduct.image} alt=""/>
                            : <img className="product-modal__image" src="/images/placeholder-image.png" alt=""/>
                    }
                    {
                        user.id
                            ? <div className={this.getFavoriteButtonClassName('mobile')}
                                   onClick={this.toggleFavorite}
                            >
                                <div className={this.getFavoriteButtonIconClassName()}>
                                    <Icon name="heart"/>
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div className="product-modal__block">
                    <div className="product-modal__name">{selectedProduct.name}</div>
                    {
                        selectedProduct.detail
                            ? <div className="product-modal__detail">{selectedProduct.detail}</div>
                            : null
                    }
                </div>
                {
                    user.id
                        ? <div className={this.getFavoriteButtonClassName('desktop')}
                               onClick={this.toggleFavorite}
                        >
                            <div className={this.getFavoriteButtonIconClassName()}>
                                <Icon name="heart"/>
                            </div>
                            <span>Favorilere ekle</span>
                        </div>
                        : null
                }
            </div>
            {
                selectedProduct.options && selectedProduct.options.length
                    ? <div className="product-modal__right">
                        <div className="product-modal__name">{selectedProduct.options[0].name}</div>
                        <div className="product-modal__block">
                            <ProductOptions getSelectOption={this.getSelectOption}
                                            selectedProduct={selectedProduct}
                                            addExtraIngredient={this.addExtraIngredient}
                                            removeExtraIngredient={this.removeExtraIngredient}
                                            getCancelExtraIngredient={this.getCancelExtraIngredient}
                                            validator={this.validator}
                                            isSingleProduct
                                            getRef={this.getGetRef()}
                            />
                        </div>
                    </div>
                    : null
            }
            <div className="product-modal__bottom">
                <div className="product-modal__total-price-container">
                    <div className="product-modal__total-price">{formatMoney(price)} TL</div>
                    {totalExtraPrice > 0
                    && <div className="product-modal__total-extra-price">
                        Ek malzemeler {formatMoney(totalExtraPrice)} TL
                    </div>
                    }
                </div>
                <div className="product-modal__count-buttons-container">
                    <div className="product-modal__count-buttons">
                        <div className="product-modal__count-button product-modal__count-button--minus"
                             onClick={this.decreaseCount}
                        >
                            -
                        </div>
                        <div className="product-modal__count-number">{selectedProduct.quantity}</div>
                        <div className="product-modal__count-button product-modal__count-button--plus"
                             onClick={this.increaseCount}
                        >
                            +
                        </div>
                    </div>
                </div>
                <Button className="product-modal__submit-button" onClick={this.submit}>
                    Sepete Ekle
                </Button>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    basket: store.basket.basket,
    loading: !!store.loader.loadingCount,
    user: store.user.user,
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    boundAddFavoriteProduct: bindActionCreators(addFavoriteProduct, dispatch),
    boundDeleteFavoriteProduct: bindActionCreators(deleteFavoriteProduct, dispatch),
    boundGetProductDetails: bindActionCreators(getProductDetails, dispatch),
    boundAddToBasket: bindActionCreators(addToBasket, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundCalculatePrice: bindActionCreators(calculatePrice, dispatch)
});

ViewProductModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    boundAddFavoriteProduct: PropTypes.func.isRequired,
    boundDeleteFavoriteProduct: PropTypes.func.isRequired,
    boundGetProductDetails: PropTypes.func.isRequired,
    boundAddToBasket: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundCalculatePrice: PropTypes.func.isRequired,
    productId: PropTypes.string.isRequired,
    basket: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProductModal);
