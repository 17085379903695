import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Validator from 'validator';
import Input from '../elements/Input';
import Button from '../elements/Button';

export default class ForgotPasswordForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                email: ''
            },
            errors: {},
            touched: {},
            formIsValid: false,
            formSubmitted: false
        };
    }

    handleInputChange = e => {
        this.setState({
            fields: {
                ...this.state.fields, [e.target.name]: e.target.value
            }
        }, () => this.validateForm());
    };

    handleInputBlur = e => {
        const { touched } = this.state;

        if (e.target.name && touched[e.target.name] !== true) {
            touched[e.target.name] = true;
            this.setState({
                touched
            }, () => this.validateForm());
        }
    };

    handleFormSubmit = e => {
        const { fields } = this.state;
        e.preventDefault();
        this.setState({
            errors: {},
            formSubmitted: true
        });

        if (this.validateForm()) {
            this.props.submit(fields.email);
            this.resetForm();
        }
    };

    resetForm = () => {
        let fields = {};
        fields['email'] = '';
        this.setState({
            fields,
            formSubmitted: false,
            formIsValid: false
        });
    };

    validateForm = () => {
        const { fields } = this.state;
        const errors = {};
        let formIsValid = true;
        this.setState({
            errors
        });

        //validate email
        if (!fields.email) {
            formIsValid = false;
            errors.email = 'Lütfen email adresi giriniz';
        } else {
            if (!Validator.isEmail(fields.email)) {
                formIsValid = false;
                errors.email = 'Yanlış formatta email adresi girdiniz';
            }
        }

        this.setState({
            errors, formIsValid
        });

        return formIsValid;
    };

    render() {
        const { fields, errors, touched, formIsValid, formSubmitted } = this.state;
        return (
            <div className="">
                <div className="auth-page__header">
                    Şifremi Unuttum
                </div>
                <div className="auth-page__subheader">
                    Lütfen kayıtlı olduğunuz e-posta adresini giriniz. Şifre işlemleri e-posta adresinize
                    gönderilecektir.
                </div>
                <div className="form-forgot-password__input-block" onSubmit={this.handleFormSubmit} autoComplete="off">
                    <Input type="email"
                               className="auth-page__input"
                               name="email"
                               value={fields.email}
                               placeholder="E-Posta"
                               onChange={this.handleInputChange}
                               onBlur={this.handleInputBlur}
                               error={(formSubmitted || touched.email) && errors.email ? errors.email : null}/>
                </div>
                <Button className="auth-page__submit-button" disabled={!formIsValid}
                        onClick={this.handleFormSubmit}
                        type="submit"
                >
                    Gönder
                </Button>
                <Link className="auth-page__cancel-button" to="/hesap/giris">
                    Geri Dön
                </Link>
            </div>
        );
    }
}
