import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';
import latinize from 'latinize';
import Input from './Input';
import { formatErrorMessage } from '../../utils';
import Icon from './Icon';

class LcDropdown extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.disabled !== this.props.disabled) {
            this.hideDropdown();
        }
    }

    getOnSelect = suggestion => () => {
        const { onSelect } = this.props;

        this.hideDropdown();
        onSelect(suggestion);
    };

    hideDropdown = () => {
        this.refs.dropdown.hide();
    };

    getDropdownOptions = () => {
        const { value, suggestions, searchFieldNames, onChange } = this.props;
        const lowerCaseInputValue = value.toLowerCase();

        if (!onChange) {
            return suggestions;
        }
        return suggestions.filter(suggestion => lowerCaseInputValue.split(' ')
            .some(word => searchFieldNames.some(fieldName =>
                suggestion[fieldName].toLowerCase().includes(word)
                || latinize(suggestion[fieldName]).toLowerCase().includes(latinize(word))
            )));
    };

    cancel = () => this.props.onChange('');

    onChange = event => {
        const { onChange } = this.props;
        onChange && onChange(event.target.value);
    };

    getLastIconName = () => {
        const { value, onChange } = this.props;
        if (value && onChange) {
            return 'delete';
        }
        return 'chevron-right';
    };

    onLastIconClick = () => {
        const { value, onChange } = this.props;
        if (value && onChange) {
            this.cancel();
        }
    };

    getClassName = () => {
        const { error, disabled, className, onChange } = this.props;

        let resultClassName = 'lc-dropdown';
        if (error) {
            resultClassName += ' lc-dropdown--error';
        }
        if (disabled) {
            resultClassName += ' lc-dropdown--disabled';
        }
        if (!onChange) {
            resultClassName += ' lc-dropdown--no-search';
        }
        if (className) {
            resultClassName += ` ${className}`;
        }
        return resultClassName;
    };

    getInputClassName = () => {
        const { onChange } = this.props;

        let className = 'dropdown__input';
        if (!onChange) {
            className += ' dropdown__input--no-text';
        }
        return className;
    };

    getOnInputClick = event => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
            event.stopPropagation();
        }
    };

    renderDropdownTrigger = dropdownOptions => {
        const { onChange, value, renderSelectedOption } = this.props;

        let selectedOption = null;
        if (!onChange && value) {
            selectedOption = dropdownOptions.find(option => option.name === value);
        }
        if (selectedOption && renderSelectedOption) {
            return <div className={`${this.getInputClassName()} dropdown__selected-option`}>
                {renderSelectedOption(selectedOption)}
                <div className="dropdown__selected-option-icon">
                    <Icon name="chevron-right"/>
                </div>
            </div>;
        }
        return <Input {...this.props}
                      className={this.getInputClassName()}
                      lastIconName={this.getLastIconName()}
                      onLastIconClick={this.onLastIconClick}
                      onChange={this.onChange}
                      error={null}
                      onClick={this.getOnInputClick}
        />;
    };

    render() {
        const {
            renderSuggestion, key, error, noResultsText, placeholder, showOptionsHeader, renderLastOption
        } = this.props;

        const dropdownOptions = this.getDropdownOptions();
        return <div className={this.getClassName()}>
            <Dropdown ref="dropdown" removeElement={true}>
                <DropdownTrigger>
                    {this.renderDropdownTrigger(dropdownOptions)}
                </DropdownTrigger>
                <DropdownContent>
                    {
                        placeholder && showOptionsHeader
                            ? <div className="lc-dropdown__content-header">
                                <span>{placeholder}</span>
                                <Icon name="cancel" onClick={this.hideDropdown} className="lc-dropdown__close-button"/>
                            </div>
                            : null
                    }
                    {
                        !dropdownOptions.length
                            ? <div className="no-results" onClick={this.hideDropdown}>
                                <Icon className="no-results__icon dropdown__no-results-icon" name="info"/>
                                <span className="no-results__text">
                                    {
                                        noResultsText
                                            ? noResultsText
                                            : 'No results'
                                    }
                                </span>
                            </div>
                            : dropdownOptions.map(suggestion =>
                                <div className="dropdown__item"
                                     onClick={this.getOnSelect(suggestion)}
                                     key={suggestion.id || key || suggestion.key}
                                >
                                    {renderSuggestion(suggestion)}
                                </div>
                            )
                    }
                    {
                        renderLastOption
                            ? renderLastOption()
                            : null
                    }
                </DropdownContent>
            </Dropdown>
            {
                error
                    ? <div className="help-text help-text--error">{formatErrorMessage(error)}</div>
                    : null
            }
        </div>;
    }
}

LcDropdown.defaultProps = {
    searchFieldNames: ['name'],
    showOptionsHeader: true
};

LcDropdown.propTypes = {
    suggestions: PropTypes.array.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    renderSelectedOption: PropTypes.func,
    renderLastOption: PropTypes.func,
    placeholder: PropTypes.string,
    showOptionsHeader: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    key: PropTypes.any,
    value: PropTypes.string.isRequired,
    searchFieldNames: PropTypes.array,
    onChange: PropTypes.func,
    noResultsText: PropTypes.string,
    error: PropTypes.any,
    disabled: PropTypes.bool,
    showSelectedOption: PropTypes.bool,
    onClick: PropTypes.func
};

export default LcDropdown;
