import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModal } from '../../../store/actions/modalActions';
import Icon from '../../elements/Icon';
import { getOrderDeliveryType } from '../../../utils';

class OrderStatusInfo extends Component {
    COLOR_GREY = '#CCCCCC';
    COLOR_RED = '#EE6112';
    COLOR_ORANGE = '#F89422';
    COLOR_YELLOW = '#FBB318';
    COLOR_LIGHT_GREEN = '#B9C232';
    COLOR_DARK_GREEN = '#68CA53';

    getIconColor = (stateId, spinnerLoading, color, orderTypeId = 0) => {

        switch (spinnerLoading) {
            case 'taken':
                return stateId === 1
                    ? color
                    : this.COLOR_GREY;

            case 'preparing':
                return stateId === 1 || stateId === 2
                    ? color
                    : this.COLOR_GREY;

            case 'ready':
                return stateId === 1 || stateId === 2 || stateId === 3 || (+orderTypeId === 8 && stateId === 4)
                    ? color
                    : this.COLOR_GREY;

            case 'inDelivery':
                return stateId === 1 || stateId === 2 || stateId === 3 || stateId === 4
                    ? color
                    : this.COLOR_GREY;

            case 'delivered':
                return stateId === 1 || stateId === 2 || stateId === 3 || stateId === 4 || stateId === 5
                    ? color
                    : this.COLOR_GREY;

            default:
                return this.COLOR_GREY;
        }
    };

    getHorizontalLineColor = (stateId, spinnerLoading, color) => {
        switch (spinnerLoading) {
            case 'taken':
                return stateId === 11
                    ? color
                    : this.COLOR_GREY;

            case 'preparing':
                return stateId === 11 || stateId === 12 || stateId === 21
                    ? color
                    : this.COLOR_GREY;

            case 'ready':
                return stateId === 11 || stateId === 12 || stateId === 21 ||
                stateId === 22 || stateId === 31 || stateId === 32
                    ? color
                    : this.COLOR_GREY;

            case 'inDelivery':
                return stateId === 11 || stateId === 12 || stateId === 21 ||
                stateId === 22 || stateId === 31 || stateId === 32 || stateId === 41
                    ? color
                    : this.COLOR_GREY;

            case 'delivered':
                return stateId === 11 || stateId === 12 || stateId === 21 ||
                stateId === 22 || stateId === 31 || stateId === 32 ||
                stateId === 41 || stateId === 42
                    ? color
                    : this.COLOR_GREY;

            default:
                return this.COLOR_GREY;
        }
    };

    getOpenVehicleDeliveryModal = orderId => () => {
        const { boundSetModal } = this.props;

        boundSetModal(`vehicle-delivery/${orderId}`);
    };

    render() {
        const {
            orderId, orderTypeId, imageUrl, imageAlt,
            title, description, spinnerLoading
        } = this.props;

        return (
            <div className="profile-order-tracking__status-container">
                <div className="profile-order-tracking__status-image">
                    <img src={imageUrl} alt={`${imageAlt}.png`}/>
                </div>
                <div className="profile-order-tracking__status-title">
                    <p>{title}</p>
                </div>
                <div className="profile-order-tracking__status-desc">
                    <p>{description}</p>
                </div>
                {
                    spinnerLoading !== 'delivery'
                        ? <div className="profile-order-tracking__status-spinner">
                            <div className="profile-order-tracking__status-spinner-step">
                                <Icon name="checked"
                                      color={this.getIconColor(1, spinnerLoading, this.COLOR_RED)}
                                />
                                <Icon name="horizontal-line"
                                      color={this.getHorizontalLineColor(11, spinnerLoading, this.COLOR_RED)}
                                />
                                <Icon name="horizontal-line"
                                      color={this.getHorizontalLineColor(12, spinnerLoading, this.COLOR_RED)}
                                />
                            </div>
                            <div className="profile-order-tracking__status-spinner-step">
                                <Icon name="checked"
                                      color={this.getIconColor(2, spinnerLoading, this.COLOR_ORANGE)}
                                />
                                <Icon name="horizontal-line"
                                      color={this.getHorizontalLineColor(21, spinnerLoading, this.COLOR_ORANGE)}
                                />
                                <Icon name="horizontal-line"
                                      color={this.getHorizontalLineColor(22, spinnerLoading, this.COLOR_ORANGE)}
                                />
                            </div>
                            <div className="profile-order-tracking__status-spinner-step">
                                <Icon name="checked"
                                      color={this.getIconColor(3, spinnerLoading, this.COLOR_YELLOW)}
                                />
                                <Icon name="horizontal-line"
                                      color={this.getHorizontalLineColor(31, spinnerLoading, this.COLOR_YELLOW)}
                                />
                                <Icon name="horizontal-line"
                                      color={this.getHorizontalLineColor(32, spinnerLoading, this.COLOR_YELLOW)}
                                />
                            </div>
                            {
                                getOrderDeliveryType(orderTypeId) === 'Adrese Teslim' || getOrderDeliveryType(orderTypeId) === 'Araca Teslim'
                                    ?
                                    <div className="profile-order-tracking__status-spinner-step">
                                        <Icon name="checked"
                                              color={this.getIconColor(4, spinnerLoading, this.COLOR_LIGHT_GREEN, orderTypeId)}
                                        />
                                        <Icon name="horizontal-line"
                                              color={this.getHorizontalLineColor(41, spinnerLoading, this.COLOR_LIGHT_GREEN)}
                                        />
                                        <Icon name="horizontal-line"
                                              color={this.getHorizontalLineColor(42, spinnerLoading, this.COLOR_LIGHT_GREEN)}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                getOrderDeliveryType(orderTypeId) === 'Araca Teslim' && spinnerLoading === 'ready'
                                    ? <div className="profile-order-tracking__status-spinner-vehicle-details-button-container">
                                        <div className="profile-order-tracking__status-spinner-vehicle-details-triangle"/>
                                        <div onClick={this.getOpenVehicleDeliveryModal(orderId)}
                                             className="profile-order-tracking__status-spinner-vehicle-details-button icon-with-label"
                                        >
                                            <Icon name="info" color="white"/>
                                            <span>Detay</span>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="profile-order-tracking__status-spinner-step">
                                <Icon name="checked"
                                      color={this.getIconColor(5, spinnerLoading, this.COLOR_DARK_GREEN)}
                                />
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = store => ({
    user: store.user.user
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusInfo);
