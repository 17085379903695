import React from 'react';
import CorporateMenu from '../../corporate/corporate-menu/CorporateMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const Quality = () => {
    sendPageView('/kurumsal/kalite', 'kalite - Little Caesars'); //for ga
    
    return <section class="institutional-structures">
        <div class="container">
          {/* <app-breadcrumbs></app-breadcrumbs> */}
          <div class="institutional-structures-detail">
            <div class="institutional-structures-detail-content clearfix">
      
              <CorporateMenu menuType='quality' />
      
              <div class="col-md-9">
                <div class="corpareta-detail quality clearfix">
                  <h1>Little Caesars Neden Bu Kadar Lezzetli?</h1>
      
                  <div class="item">
                      <img src="/images/kurumsal/kalite/img01.jpg" alt="Günlük Taze Hamur" />
                      <h5>Günlük Taze Hamur</h5>
                      <p>Lezetine doyulmaz pizzamızın sırrı , haftalık değil, günlük ve taze hamurla yapılmasından kaynaklanır.</p>
                  </div>
      
                  <div class="item">
                      <img src="/images/kurumsal/kalite/img02.jpg" alt="Sezon Mahsülü Domates Sos" />
                      <h5>Sezon Mahsülü Domates Sos</h5>
                      <p>Eşsiz lezzetli pizzalarımızda, taze domateslerle ve özel baharatlarla hazırladığımız leziz sosumuzu kullanıyoruz.</p>
                  </div>
      
                  <div class="item">
                      <img src="/images/kurumsal/kalite/img03.jpg" alt="Gerçek Mozzarella" />
                      <h5>Gerçek Mozzarella</h5>
                      <p>Mükemmel lezzet için sadece bize özel üretilmiş %100 gerçek mozzarella peyniri kullanıyoruz.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
};

export default Quality;