import React, { Component } from 'react';
import { getCities, getRestaurants } from '../../store/actions/addressActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import GoogleMapReact from 'google-map-react';
import { bindActionCreators } from 'redux';
import DropdownList from '../elements/DropdownList';
import {
    DEFAULT_CENTER, GOOGLE_MAPS_API_KEY, getRestaurantLocation, getRestaurantWorkingTimes, isRestaurantOpenAtNight,
    replaceTurkishChars
} from '../../utils';
import { sendPageView, addClickEvent } from '../../googleAnalyticsHelper';
import Checkbox from '../elements/Checkbox';
import getValidator from '../../getValidator';
import MarkerClusterer from '@google/markerclusterer';
import Icon from '../elements/Icon';

class RestaurantManager extends Component {
    validator = getValidator(this);

    state = {
        cities: [],
        selectedCityId: '',
        selectedRestaurant: {},
        showOnlyNightOpen: false
    };

    componentDidMount() {
        const { location, boundGetCities } = this.props;

        boundGetCities().then(response => this.setState({ cities: response.data.result }));
        sendPageView(location.pathname, 'Restoranlar sayfası'); //for ga
    }

    setUpMap = ({ map, maps }) => {
        const { boundGetRestaurants } = this.props;
        this.map = map;
        this.maps = maps;
        if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {  // getCurrentPosition doesn't work with http (only https!)
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude: lat, longitude: lng } }) => {
                    this.map.setCenter({ lat, lng });
                    boundGetRestaurants(lat, lng).then(this.showRestaurantMarkers);
                },
                () => {
                    boundGetRestaurants().then(this.showRestaurantMarkers);
                }
            );
        } else {
            boundGetRestaurants().then(this.showRestaurantMarkers);
        }
    };

    showRestaurantMarkers = () => {
        const { addressData } = this.props;
        let restaurants = addressData.restaurants;

        let markers = [];
        restaurants.map(restaurant => {
            const location = getRestaurantLocation(restaurant);

            if (location) {
                const marker = new this.maps.Marker({
                    position: location,
                    map: this.map,
                    icon: '/icons/restaurant.svg'
                });
                const infoWindow = new this.maps.InfoWindow({ content: this.renderRestaurantInfoWindow(restaurant) });
                marker.addListener('click', () => {
                    if (this.openedInfoWindow) {
                        this.openedInfoWindow.close();
                    }
                    this.openedInfoWindow = infoWindow;
                    infoWindow.open(this.map, marker);
                });
                markers.push(marker);
            }
        });

        let markerCluster = new MarkerClusterer(this.map, markers, {
            styles: [{
                url: '/icons/cluster.svg',
                height: 105,
                width: 58,
                anchor: [40, 0],
                textColor: '#231f20',
                textSize: 16,
                iconAnchor: [30, 70],
                lineHeight: 50
            }]
        });
    };

    renderRestaurantInfoWindow = restaurant => {
        const root = document.createElement('div');
        root.classList.add('info-window');
        root.innerHTML = `
            <div class="info-window__header">
                <span class="info-window__name">${restaurant.name}</span>
            </div>
            <div class="info-window__time">
                <span class="info-window__time__left"> Açılış ${getRestaurantWorkingTimes(restaurant).substring(0, getRestaurantWorkingTimes(restaurant).indexOf('-'))} </span>
                | <span class="info-window__time__right"> Kapanış ${getRestaurantWorkingTimes(restaurant).substring(getRestaurantWorkingTimes(restaurant).indexOf('-') + 2)}</span>
            </div>
            <div class="info-window__address"><span>Adres: </span>${restaurant.address}</div>
            <div class="info-window__bottom">
               <div class="info-window__button">
                   <a href="tel:0${restaurant.phone}">
                      0${restaurant.phone}
                   </a>
                </div>
               <div class="info-window__button detail_button"> Detay </div>
            </div>
        `;

        root.onclick = event => {
            if (event.target.classList.contains('detail_button')) {
                return this.getGoToDetails(restaurant)();
            }
        };

        return root;
    };

    getGoToDetails = restaurant => () => {
        const { history } = this.props;

        //Format the url for restaurant details
        const townName = replaceTurkishChars(restaurant.townName).replace(/[^a-zA-Z0-9-_-]/g, '');
        const restaurantName = replaceTurkishChars(restaurant.name).split(' ').join('-')
            .replace(/[^a-zA-Z0-9-_-]/g, '');
        const restaurantId = restaurant.id;

        history.push(`/sube/${townName.toLowerCase()}/${restaurantName.toLowerCase()}/${restaurantId}`);
    };

    getShowOnMap = restaurant => () => {
        this.setState({ selectedRestaurant: restaurant });
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        addClickEvent('En yakın restoranları göster'); //for gooogle analytics
    };

    handleChange = () => this.setState({ showOnlyNightOpen: !this.state.showOnlyNightOpen });

    getSetCity = selectedCityId => () => this.setCity(selectedCityId);

    setCity = selectedCityId =>
        this.setState({ selectedCityId, selectedRestaurant: {} });

    isOpenAtNight = restaurant => {
        let isOpen = false;

        if (isRestaurantOpenAtNight(restaurant))
            isOpen = true;

        return isOpen;
    };

    renderCities = () => {
        const { loading } = this.props;
        const { cities } = this.state;

        return <div className="container">
            <div className="col-md-8 col-md-offset-2">
                <div className="restaurant-cities-block">
                    <div className="restaurant-city-block-title">Restoranlarımız</div>
                    <div className="restaurant-city-block-desc">
                        Hangi İlde olduğunuzu seçerek, size en yakın restoranlarımızı görebilirsiniz.
                    </div>
                    <div className="restaurant-city-wrapper">
                        {
                            !loading && cities && cities.length
                                ? cities.map(city =>
                                    <div key={city.id} className="restaurant-city"
                                         onClick={this.getSetCity(city.id)}
                                    >
                                        {city.name}
                                    </div>
                                )
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>;
    };

    renderRestaurants = () => {
        const { addressData, loading } = this.props;
        const { selectedCityId, showOnlyNightOpen } = this.state;
        let restaurants = addressData.restaurants.filter(c => c.townId === selectedCityId);
        let nightOpenRestaurants = [];

        if (showOnlyNightOpen) {
            if (restaurants && restaurants.length) {
                restaurants.forEach(restaurant => {
                    if (this.isOpenAtNight(restaurant)) {
                        nightOpenRestaurants.push(restaurant);
                    }
                });
            }
            restaurants = nightOpenRestaurants;
        }

        return <div className="restaurant-block">
            <div className="restaurant-block-title">
                {restaurants && restaurants.length ? restaurants[0].townName : ''} Şubelerimiz
            </div>
            <div className="restaurant-block-desc">
                Toplam {restaurants && restaurants.length ? restaurants.length : '0'} şubemiz listeleniyor
            </div>
            <div className="restaurant-card-wrapper">
                {
                    !loading && restaurants && restaurants.length
                        ? restaurants.map(restaurant =>
                            <div key={restaurant.id} className="restaurant-card">
                                <div className="restaurant-card-header">
                                    <div className="restaurant-name">
                                        {restaurant.name} Şubesi
                                    </div>
                                    <div className="restaurant-work-time">
                                        <span className="restaurant-work-time-caption">AÇIK</span>
                                        {getRestaurantWorkingTimes(restaurant)}
                                    </div>
                                </div>
                                <div className="restaurant-card-body">
                                    <div className="restaurant-card-details">
                                        <div className="restaurant-address">{restaurant.address}</div>
                                        <div className="restaurant-phone"> Tel: {restaurant.phone}</div>
                                    </div>
                                    <div className="restaurant-card-buttons">
                                        <div className="restaurant-card-button"
                                             onClick={this.getGoToDetails(restaurant)}
                                        >
                                            Detay
                                        </div>
                                        <div className="restaurant-card-button icon-with-label"
                                             onClick={this.getShowOnMap(restaurant)}
                                        >
                                            <Icon name="pin-alt"/>
                                            <span>Haritada Göster</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        </div>;
    };

    render() {
        const { cities, selectedCityId, selectedRestaurant, showOnlyNightOpen } = this.state;
        const location = selectedRestaurant && selectedRestaurant.id ? getRestaurantLocation(selectedRestaurant) : DEFAULT_CENTER;

        return <div>
            <div className="restaurant-map">
                <Helmet title="Restoranlar"/>
                <GoogleMapReact
                    bootstrapURLKeys={GOOGLE_MAPS_API_KEY}
                    defaultZoom={12}
                    center={location}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={this.setUpMap}
                />
            </div>
            <div className="restaurant-search-block">
                <div className="search-bar-section">
                    <div className="container search-bar-and-restaurants-wrapper">
                        <div className="col-md-8 col-md-offset-2 search-bar-wrapper">
                            <DropdownList placeholder="İl Seçin"
                                          value={selectedCityId}
                                          onChange={this.setCity}
                                          className="restaurant-search-block__dropdown"
                                          error={this.validator.message('Şehir', cities.length ? true : '', 'required')}
                                          options={cities}
                                          optionClassName="restaurant-search-block__option"
                                          onSelect={this.setCity}
                            />
                            <div className="restaurant-search-block__open-night">
                                <Checkbox value={showOnlyNightOpen}
                                          className="restaurant-search-block__open-night__checkbox"
                                          onChange={this.handleChange}
                                          label="Sadece Gece Açık Restoranlar"
                                          labelClassName="restaurant-search-block__open-night__label"
                                          type="switch"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    selectedCityId
                        ? this.renderRestaurants()
                        : this.renderCities()
                }
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    boundGetCities: bindActionCreators(getCities, dispatch),
    boundGetRestaurants: bindActionCreators(getRestaurants, dispatch)
});

RestaurantManager.propTypes = {
    boundGetCities: PropTypes.func.isRequired,
    boundGetRestaurants: PropTypes.func.isRequired,
    addressData: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantManager);