import React from 'react';

const ChevronUp = props =>
    <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <path d="M-4.92788e-08 4.87263L1.12737 6L4.83158 2.29579L8.53579 6L9.66316 4.87263L4.83158 0.0410526L-4.92788e-08 4.87263Z" />
        </g>
        <defs>
            <clipPath>
                <path d="M0 6L-2.62268e-07 0L10 -4.37114e-07L10 6L0 6Z" />
            </clipPath>
        </defs>
    </svg>;

export default ChevronUp;
