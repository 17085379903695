import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextInput from '../../elements/TextInput';
import Button from '../../elements/Button';
import getValidator from '../../../getValidator';
import { changePassword } from '../../../store/actions/authActions';
import { toast } from 'react-toastify';
import Toast from '../../elements/Toast';
import { addChangePasswordAction } from '../../../googleAnalyticsHelper';

class ChangePasswordForm extends Component {
    validator = getValidator(this);

    state = {
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    };

    currentPasswordHandler = event =>
        this.setState({ currentPassword: event.target.value });

    newPasswordHandler = event =>
        this.setState({ newPassword: event.target.value });

    repeatNewPasswordHandler = event =>
        this.setState({ repeatNewPassword: event.target.value });

    resetForm = () => this.setState({ currentPassword: '', newPassword: '', repeatNewPassword: '' });

    handleSubmitNewPassword = () => {
        const { boundChangePassword } = this.props;
        const { currentPassword, newPassword } = this.state;

        addChangePasswordAction(); //for google analytics

        this.validator.showMessages();
        if (this.validator.allValid()) {
            boundChangePassword(currentPassword, newPassword)
                .then(() => {
                    this.validator.hideMessages();
                    this.resetForm();
                    toast(<Toast text="Şifre başarıyla değiştirildi" status="success"/>, { autoClose: 3000 })
                })
                .catch(() => toast(<Toast text="Yanlış şifre" status="error"/>));
        }
    };

    render() {
        const { currentPassword, newPassword, repeatNewPassword } = this.state;

        return <div className="profile-account__block">
            <div className="profile__block-header">Şifre Değiştir</div>
            <div className="profile-account__block profile-account__block--inputs">
                <div className="profile-account__input-block">
                    <TextInput type="password"
                               label="Mevcut Şifren"
                               labelClassName="profile-account__input-label"
                               value={currentPassword}
                               onChange={this.currentPasswordHandler}
                               className="profile-account__input"
                               error={this.validator.message('mevcut şifre', currentPassword, 'required')}
                    />
                    <TextInput type="password"
                               label="Yeni Şifre"
                               labelClassName="profile-account__input-label"
                               value={newPassword}
                               onChange={this.newPasswordHandler}
                               className="profile-account__input"
                               error={this.validator.message('yeni şifre', newPassword, `required|password_comparing:${repeatNewPassword},yeni şifre tekrar`)}
                    />
                    <TextInput type="password"
                               label="Yeni Şifre Tekrar"
                               labelClassName="profile-account__input-label"
                               value={repeatNewPassword}
                               onChange={this.repeatNewPasswordHandler}
                               className="profile-account__input"
                               error={this.validator.message('yeni şifre tekrar', repeatNewPassword, `required|password_comparing:${newPassword},yeni şifre`)}
                    />
                </div>
                <Button color="light_green" className="profile-account__submit-button"
                        onClick={this.handleSubmitNewPassword}
                >
                    Kaydet
                </Button>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({
    boundChangePassword: bindActionCreators(changePassword, dispatch)
});

ChangePasswordForm.propTypes = {
    boundChangePassword: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
