import React from 'react';
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import { formatLink } from "../../utils"

const ProductCategoryLink = ({ subCategory, linkUrl, imageUrl, altTitle, linkTitle,
                               subCategoryLinkClassName, linkImageClassName }) => {
    return <div className="subcategories-header__link-container">
        <Link className={subCategoryLinkClassName} to={subCategory ? formatLink(subCategory.link) : linkUrl ? linkUrl : ''}>
            {
                imageUrl
                    ? <div className="subcategories-header__link-image-container">
                        <img className={linkImageClassName} src={imageUrl} alt={subCategory ? subCategory.name : altTitle} />
                    </div>
                    : null
            }
            <div className="subcategories-header__link-caption link">
                <span>{subCategory ? subCategory.name : linkTitle}</span>
            </div>
        </Link>
    </div>
};

ProductCategoryLink.propTypes = {
    subCategory: PropTypes.object,
    linkUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    altTitle: PropTypes.string,
    linkTitle: PropTypes.string,
    subCategoryLinkClassName: PropTypes.string.isRequired,
    linkImageClassName: PropTypes.string
};

export default ProductCategoryLink;
