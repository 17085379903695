import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllCities, getAllTownsOfCity } from '../../../store/actions/addressActions';
import { postFranchisingForm } from '../../../store/actions/memberActions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Validator from 'validator';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';
import TextInputField from '../../elements/TextInput';
import Checkbox from '../../elements/Checkbox';
import { sendPageView } from '../../../googleAnalyticsHelper';
import { toast } from 'react-toastify';
import Toast from '../../elements/Toast';

class FranchisingApplicationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                nameSurname: '',
                email: '',
                invCity: {},
                invDistrict: {},
                invAmount: 0,
                isAgreementAccepted: false
            },
            cityList: [],
            districtList: [],
            formSubmitted: false,
            formIsvalid: false,
            touched: {},
            errors: {}
        };

        this.invAmountOptions = [
            { label: '700.000 TL - 800.000 TL', value: '700.000 TL - 800.000 TL' },
            { label: '800.000 TL - 900.000 TL', value: '800.000 TL - 900.000 TL' }
        ];
    }

    componentDidMount = () => {
        const { location } = this.props;

        sendPageView(location.pathname, 'Franchise Basvuru Formu - Little Caesars'); // for google analytics
        this.getCities();
    };

    getCities = () => {
        const { boundGetAllCities } = this.props;
        boundGetAllCities().then(response => {
            let cityList = [];
            if (response && response.data && response.data.result && response.data.result.length) {
                for (var i = 0; i < response.data.result.length; i++) {
                    var city = response.data.result[i];
                    cityList.push({
                        label: city.cname,
                        value: city.cid
                    });
                }
                this.setState({ cityList });
            }
        });
    };

    getCityTowns = cityId => {
        const { boundGetAllTownsOfCity } = this.props;
        boundGetAllTownsOfCity(cityId).then(response => {
            this.setState({ districtList: [] }, () => {
                let districtList = [];
                if (response && response.data && response.data.result && response.data.result.length) {
                    for (var i = 0; i < response.data.result.length; i++) {
                        var district = response.data.result[i];
                        districtList.push({
                            label: district.tname,
                            value: district.tid
                        });
                    }
                    this.setState({
                        districtList
                    }, () => this.setState({
                        fields: {
                            ...this.state.fields,
                            invDistrict: districtList[0]
                        }
                    }));
                }
            });
        });
    };

    handleInputChange = e => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            fields: {
                ...this.state.fields, [e.target.name]: value
            }
        }, () => this.validateForm());
    };

    handleInputBlur = e => {
        const { touched } = this.state;

        if (e.target.name && touched[e.target.name] !== true) {
            touched[e.target.name] = true;
            this.setState({
                touched
            }, () => this.validateForm());
        }
    };

    handleSelectBlur = e => {
        if (!e.target.closest('.css-g1d714-ValueContainer').querySelector('.css-1uccc91-singleValue').innerHTML) {
            this.validateForm();
        }
    };

    handleCityChange = selectedOption => {
        this.setState({
                fields: {
                    ...this.state.fields,
                    invCity: selectedOption
                }
            }, () =>
                this.getCityTowns(selectedOption.value)
        );
    };

    handleDistrictChange = selectedOption => {
        this.setState({
            fields: {
                ...this.state.fields,
                invDistrict: selectedOption
            }
        });
    };

    handleInvAmountChange = selectedOption => {
        this.setState({
            fields: {
                ...this.state.fields,
                invAmount: selectedOption
            }
        });
    };


    handleFormSubmit = e => {
        const { fields } = this.state;
        const { boundPostFranchisingForm } = this.props;
        e.preventDefault();

        if (this.validateForm()) {
            this.setState({
                errors: {},
                formSubmitted: true
            });

            boundPostFranchisingForm(fields.nameSurname, fields.email, fields.phone,
                fields.invCity, fields.invDistrict, fields.invAmount, fields.isAgreementAccepted)
                .then(() => {
                    this.resetForm();
                    toast(<Toast text="Başvurunuz alınmıştır, sizinle en yakın zamanda iletişime geçilecektir."
                                 status="success"/>, { autoClose: 3000 });
                })
                .catch(() => {
                    toast(<Toast text="Başvurunuz alınırken bir sorun oluştu, bir süre sonra tekrar deneyiniz."
                                 status="error"/>);
                });
        }
    };

    resetForm = () => {
        let fields = {};
        fields['nameSurname'] = '';
        fields['email'] = '';
        fields['phone'] = '';
        fields['invCity'] = false;
        fields['invDistrict'] = false;
        fields['invAmount'] = 0;
        fields['isAgreementAccepted'] = false;
        let touched = {};
        this.setState({
            fields,
            touched,
            isAgreementAccepted: false,
            formSubmitted: false,
            formIsValid: false
        });
    };

    validateForm = () => {
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;
        this.setState({ errors });

        //validate nameSurname
        if (!fields.nameSurname) {
            formIsValid = false;
            errors.nameSurname = 'Lütfen Ad ve Soyad giriniz';
        }

        //validate email
        if (!fields.email) {
            formIsValid = false;
            errors.email = 'Lütfen email adresi giriniz';
        } else {
            if (!Validator.isEmail(fields.email)) {
                formIsValid = false;
                errors.email = 'Yanlış formatta email adresi girdiniz';
            }
        }

        //validate telephone
        if (!fields.phone) {
            formIsValid = false;
            errors.phone = 'Lütfen Telefon giriniz';
        } else {
            if (!fields.phone.match(/^[5]{1}[0-9]{9}/)) {
                formIsValid = false;
                errors.phone = 'Telefon numarasını 5 ile başlayıp sadece rakamlardan oluşacak şekilde giriniz';
            } else {
                if (!fields.phone.length === 10) {
                    formIsValid = false;
                    errors.phone = 'Telefon Numarası 10 karakter olmalıdır';
                }
            }
        }

        //validate city
        if (!fields.invCity || Object.keys(fields.invCity).length === 0) {
            formIsValid = false;
            errors.invCity = 'Lütfen il seçiniz';
        }

        //validate district
        if (!fields.invDistrict) {
            formIsValid = false;
            errors.invDistrict = 'Lütfen ilçe seçiniz';
        }

        //validate investment amount
        if (!fields.invAmount) {
            formIsValid = false;
            errors.invAmount = 'Lütfen yatırım bütçesi seçiniz';
        }

        if (!fields.isAgreementAccepted) {
            formIsValid = false;
        }

        this.setState({
            errors,
            formIsValid
        });

        return formIsValid;
    };

    render() {
        const {
            fields, formSubmitted, formIsValid, cityList, districtList,
            touched, errors
        } = this.state;
        return (
            <section className="institutional-structures">
                <div className="container">
                    <BreadCrumbs/>
                    <div className="institutional-structures-detail">
                        <div className="institutional-structures-detail-content clearfix">
                            <FranchisingMenu/>
                            <div className="col-md-9">
                                <div className="corpareta-detail clearfix">
                                    <h1>Franchise Başvuru Formu</h1>
                                    <img className="img-responsive" src="/images/corporate/franchise-basvuru-formu.jpg"
                                         alt="franchise-basvuru-formu.jpg"/>
                                    <form className="basic-form" onSubmit={this.handleFormSubmit}>
                                        <div className="row">
                                            <div className="col-xs-12 col-md-9">
                                                <TextInputField type="text"
                                                                placeholder="*Ad Soyad"
                                                                name="nameSurname"
                                                                value={fields.nameSurname}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.nameSurname) ? errors.nameSurname : null}/>

                                                <TextInputField type="email"
                                                                placeholder="*E-Mail"
                                                                name="email"
                                                                value={fields.email}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.email) ? errors.email : null}/>

                                                <TextInputField type="text"
                                                                placeholder="*Telefon"
                                                                name="phone"
                                                                value={fields.phone}
                                                                minlength="10"
                                                                maxlength="10"
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.phone) ? errors.phone : null}/>

                                                <h2>Yatırım Yapılacak Lokasyon</h2>
                                                <div className="form-group">
                                                    <Select options={cityList}
                                                            name="invCity"
                                                            value={fields.invCity}
                                                            placeholder="İl Seçin"
                                                            instanceId="City"
                                                            components={{ FranchisingApplicationForm }}
                                                            onChange={this.handleCityChange}
                                                            onBlur={this.handleSelectBlur}/>
                                                    {
                                                        errors.invCity
                                                            ? <div
                                                                className="help-text help-text--error">{errors.invCity}</div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <Select options={districtList}
                                                            name="invDistrict"
                                                            value={fields.invDistrict}
                                                            placeholder="İlçe Seçin"
                                                            instanceId="District"
                                                            components={{ FranchisingApplicationForm }}
                                                            onChange={this.handleDistrictChange}
                                                            error={(formSubmitted || touched.invDistrict) ? errors.invDistrict : null}/>
                                                </div>
                                                <div className="form-group">
                                                    <Select options={this.invAmountOptions}
                                                            name="invAmount"
                                                            value={fields.invAmount}
                                                            placeholder="Yatırım bütçesi"
                                                            instanceId="Amount"
                                                            components={{ FranchisingApplicationForm }}
                                                            onChange={this.handleInvAmountChange}
                                                            error={(formSubmitted || touched.invAmount) ? errors.invAmount : null}/>
                                                </div>

                                                <Checkbox className="lc-chekbox"
                                                          activeColor="black"
                                                          label="Kişisel Verilerin Korunması Kanumu'na ait"
                                                          name="isAgreementAccepted"
                                                          value={fields.isAgreementAccepted}
                                                          onChange={this.handleInputChange}/>
                                                <Link to="/musteri-hizmetleri/kisisel-verilerin-korunmasi"
                                                      target="blanc">sözleşmeyi</Link> okudum ve onaylıyorum.
                                                {/* <input type="text" placeholder="*Adres" formControlName="cAddress" /> */}

                                                <button type="submit"
                                                        className={`big-button big-button--orange ${!formIsValid ? ' big-button--disabled' : ''}`}>Başvur
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

const mapDispatchToProps = dispatch => ({
    boundGetAllCities: bindActionCreators(getAllCities, dispatch),
    boundGetAllTownsOfCity: bindActionCreators(getAllTownsOfCity, dispatch),
    boundPostFranchisingForm: bindActionCreators(postFranchisingForm, dispatch)
});

FranchisingApplicationForm.propTypes = {
    boundGetAllCities: PropTypes.func.isRequired,
    boundGetAllTownsOfCity: PropTypes.func.isRequired,
    boundPostFranchisingForm: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(FranchisingApplicationForm);
