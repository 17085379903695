import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import ForgotPasswordForm from '../../account/ForgotPasswordForm';
import { requestPasswordReset } from '../../../store/actions/authActions';
import { toast } from 'react-toastify';
import Toast from '../../elements/Toast';

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: false
        };
    }

    submit = email => {
        const { boundRequestPasswordReset } = this.props;
        boundRequestPasswordReset(email).then(() => {
            toast(this.renderSuccessToast('Eğer sistemimizde e-posta adresiniz kayıtlı ise, şifre yenileme e-postanız gönderilmiştir.'));
        }).catch(() => toast(this.renderErrorToast()));
    };


    renderSuccessToast = text => <Toast text={text}/>;

    renderErrorToast = () =>
        <Toast text="Bir hata oluştu, lütfen e-posta adresinizin doğru olduğundan emin olunuz." status="error"/>;

    render() {
        return (
            <div className="app-page-container auth-page">
                <div className="app-content-container auth-page__content">
                    <ForgotPasswordForm submit={this.submit}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    boundRequestPasswordReset: bindActionCreators(requestPasswordReset, dispatch)
});

ForgotPasswordPage.propTypes = {
    boundRequestPasswordReset: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);