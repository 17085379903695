export const SET_MODAL = 'SET_MODAL';
export const SET_REDIRECT = 'SET_REDIRECT';

export const setModal = modal => ({
    type: SET_MODAL,
    payload: modal
});

export const setRedirect = redirect => ({
    type: SET_REDIRECT,
    payload: redirect
});
