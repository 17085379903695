import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../utils';

const ProductCard = ({ product, onClick }) => {
    const setInitialPrice = () => {
        const items = product.options[0].items;
        items.sort((a, b) => {
            if (a.price.price > b.price.price) {
                return 1;
            }
            if (a.price.price < b.price.price) {
                return -1;
            }
            return 0;
        });
        return formatMoney(items[0].price.price) + 'TL\'dan başlayan';
    };
    return (
        <div className="product__item" onClick={onClick}>
            <div className="product__item-top">
                <div className="product__item-image">
                    {
                        product.image
                            ? <img src={product.image} alt={`${product.name}.png`}/>
                            : <img src="/images/placeholder-image.png" alt={`${product.name}.png`}/>
                    }
                </div>
                <div className="product__info">
                    <div className="product__info-top">
                        <div className="product__name">{product.name}</div>
                        {
                            product.detail
                                ? <div className="product__details">{product.detail}</div>
                                : null
                        }
                    </div>
                    <div className="product__info-bottom">
                        <div className="product__price" style={{ fontSize: product.price.price === 0 ? '15px' : null }}>
                            {
                                product.price.price === 0
                                    ? setInitialPrice()
                                    : formatMoney(product.price.price) + 'TL'
                            }
                        </div>
                        <div className="product__link-button-container">
                            <div className="product__link-button">Sipariş ver</div>
                        </div>
                    </div>
                </div>
            </div>
            {
                product.detail
                    ? <div className="product__details product__details--mobile">{product.detail}</div>
                    : null
            }
        </div>
    );
};

ProductCard.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        detail: PropTypes.string,
        image: PropTypes.string,
        price: PropTypes.shape({
            price: PropTypes.number
        })
    }).isRequired,
    onClick: PropTypes.func.isRequired
};

export default ProductCard;
