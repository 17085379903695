import { SET_ORDERS, SET_LAST_ORDER } from '../actions/orderActions';

const initialState = {
    orders: [],
    lastOrder: {}
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload
            };

        case SET_LAST_ORDER:
            return {
                ...state,
                lastOrder: action.payload
            };

        default:
            return state;
    }
};

export default orderReducer;