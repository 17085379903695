import React, { Component } from 'react';
import Icon from '../../elements/Icon';
import PropTypes from 'prop-types';

class SuccessfullyAddedCardModal extends Component {
    componentDidMount() {
        const { setModal } = this.props;
        setTimeout(() => setModal(''), 5000);
    }

    closeModal = () => this.props.setModal('');

    render() {
        return <div className="modal-content successfully-added-card-modal">
            <Icon name="cancel" className="modal-alt-close-icon" onClick={this.closeModal}/>
            <div className="successfully-added-card-modal__container">
                <div>
                    <img src="/images/bank-cards/success.png" alt="by-masterpass-logo.png"/>
                </div>
                <div className="successfully-added-card-modal__text-container">
                <span className="successfully-added-card-modal__header">
                    Yeni Kart Eklendi
                </span>
                    <span>
                    Kartınız başarıya eklendi.
                </span>
                </div>
            </div>
            <div className="masterpass">
                <img src="/images/masterpass.svg" alt="masterpass.svg"/>
            </div>
        </div>;
    }
}

SuccessfullyAddedCardModal.propsTypes = {
    setModal: PropTypes.func.isRequired
};

export default SuccessfullyAddedCardModal;
