import api, { handleRequest } from '../../api';

export const SET_PAGE_RESULT = 'SET_PAGE_RESULT';

export const setPageResult = response => ({
    type: SET_PAGE_RESULT,
    payload: response
});

export const getPageResult = pageName => dispatch =>
    api.get(`web/Cms/Page?Name=${pageName}`, dispatch, { ignoreStatus: true })
        .then(response => handleRequest(response, () => dispatch(setPageResult(response.data.result)), true));
