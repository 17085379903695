import React from "react";
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from "moment";
import { toast } from "react-toastify";
import Toast from "./components/elements/Toast";
import { setLoading } from "./store/actions/loaderActions";
import { getDeliveryTypeId, TAKE_AWAY_ORDER_TYPE, logOut } from "./utils";
import {AUTH_URL, API_URL} from './environment'
import { getFromLocalStorage, setLocalStorage, setCookie } from "./storage";


export const getAuthToken = () => {
    try {
        return JSON.parse(getFromLocalStorage('authToken'));
    }
    catch (error) {
        toast(<Toast text={'Bir hata oluştu, lütfen kısa bir süre sonra tekrar deneyiniz: ' + error} status="error" />);
    }
};

export const getAuthorizationHeader = () => {
    const token = getAuthToken();
    if (token) {
        return `${token.type} ${token.value}`;   // Bearer ...
    }
};

export const saveAuthToken = (token, isDummyLogin) => {
    const authToken = {
        type: token.token_type,
        value: token.access_token,
        expirationTime: jwtDecode(token.access_token).exp,
        login: token.login,
        isDummyLogin
    };
    setLocalStorage('authToken', JSON.stringify(authToken));
};

export const parseAuthToken = (token, isDummyLogin) => ({
    type: token.token_type,
    value: token.access_token,
    expirationTime: jwtDecode(token.access_token).exp,
    login: token.login,
    isDummyLogin
});

export const saveCustomerPhoneId = id => {
    setCookie('customerPhoneId', id);
    return id;
};

// Here could be a bug: When the token is almost expired, we check it, it's ok.
// But while request is sending, token become expired and request is failed.
// One can fix it adding 10 seconds to current moment, but maybe it's not best solution.
export const isTokenExpired = token => moment().isAfter(moment.unix(token.expirationTime));

export const getNewAuthToken = () =>
    axios.post(`${AUTH_URL}auth/AuthService`, {
        Username: 'robuser@clockwork.com.tr',
        Password: '7656BAF3F15A6504BBF3CEE829092DFA'
    })
        .then(response => {
            return axios.post(
                `${API_URL}web/Member/DefaultLogin`,
                {},
                { headers: { Authorization: `${response.data.token_type} ${response.data.access_token}` } }
            );
        })
        .then(response => {
            if (response.data.status === false) {
                toast(<Toast text={response.data.message || response.data.result} status="error" />);
            }
            return saveAuthToken(response.data.result.token, true);
        })
        .catch(error => toast(<Toast text={error} status="error" />));

export const hasValidToken = () => {
    const authToken = getAuthToken();
    return authToken && typeof authToken === 'object' && !isTokenExpired(authToken);
};

export const getHeaders = () => {
    const headers = {};
    headers.Authorization = getAuthorizationHeader();
    const addressSelection = JSON.parse(getFromLocalStorage('addressSelection'));

    if (addressSelection && addressSelection.type) {
        const deliveryTypeId = getDeliveryTypeId(addressSelection.type);
        if (deliveryTypeId) {
            headers.MemberDeliveryMethod = deliveryTypeId;
        }
        if (addressSelection.addressId) {
            if (deliveryTypeId === TAKE_AWAY_ORDER_TYPE) {
                headers.MemberPickupStoreId = addressSelection.addressId;
            } else if (addressSelection.isUserAddress) {
                headers.AuthMemberAddressId = addressSelection.addressId;
            }
        }
    }
    return headers;
};


const getErrorMessage = (error, url) => error ? error : null;

export const handleRequest = (response, callback, ignoreStatus = false) => {
    if (response && response.data !== null && response.data !== undefined && (response.data.status !== false || ignoreStatus)) {
        if (callback) {
            return callback();
        }
        return response;
    } else {
        return Promise.reject();
    }
};

const sendToastOnBackendError = error => {
    if (document.querySelector('.custom-toast-class__body')) {
        toast(error, { autoClose: 3000 });
    } else {
        toast(error);
    }
};

const getHandleRequestSuccess = (url, dispatch, ignoreStatus) => response => {
    dispatch(setLoading(false));
    if (response.data.status === false && !ignoreStatus) {
        if (response.data.message) {
            if (response.data.resultCode !== '404') {
                sendToastOnBackendError(<Toast text={getErrorMessage(response.data.message, url)} status="error"/>);
            }
        } else if (response.data.result && typeof response.data.result !== 'object') {
            sendToastOnBackendError(<Toast text={getErrorMessage(response.data.result, url)} status="error"/>);
        }
    }
    return response;
};

const getHandleRequestError = (url, dispatch) => error => {
    dispatch(setLoading(false));
    if(!error.status){
        sendToastOnBackendError(<Toast text="Bağlantı Hatası" status="error"/>);
        console.log(getErrorMessage(error, url))
    }else
    sendToastOnBackendError(<Toast text={getErrorMessage(error, url)} status="error"/>);
};

const doGet = (url, dispatch, options) =>
    axios.get(API_URL + url, { headers: getHeaders() })
        .then(getHandleRequestSuccess(url, dispatch, options.ignoreStatus))
        .catch(getHandleRequestError(url, dispatch));

const doPost = (url, data, dispatch, options) =>
    axios.post(API_URL + url, data, { headers: getHeaders() })
        .then(getHandleRequestSuccess(url, dispatch, options.ignoreStatus))
        .catch(getHandleRequestError(url, dispatch));

const doPut = (url, data, dispatch, options) =>
    axios.put(API_URL + url, data, { headers: getHeaders() })
        .then(getHandleRequestSuccess(url, dispatch, options.ignoreStatus))
        .catch(getHandleRequestError(url, dispatch));

const doDelete = (url, dispatch, options) =>
    axios.delete(API_URL + url, { headers: getHeaders() })
        .then(getHandleRequestSuccess(url, dispatch, options.ignoreStatus))
        .catch(getHandleRequestError(url, dispatch));

const dispatchRequest = (doRequest, dispatch) => {
    dispatch(setLoading(true));
    if (!hasValidToken()) {
        localStorage.setItem('authToken', null);
        localStorage.setItem('userId', null);
        localStorage.setItem('basketId', null);
        
        toast(<Toast text="Oturumunuz sonlanmıştır, lütfen tekrar giriş yapınız." status="error"/>)
        logOut();
        return getNewAuthToken().then(() => doRequest());
    }
    return doRequest();
};

const defaultOptions = { ignoreStatus: false };

const get = (url, dispatch, options = {}) =>
       dispatchRequest(() => doGet(url, dispatch, { ...defaultOptions, ...options }), dispatch);

const post = (url, data, dispatch, options = {}) =>
       dispatchRequest(() => doPost(url, data, dispatch, { ...defaultOptions, ...options }), dispatch);

const put = (url, data, dispatch, options = {}) =>
       dispatchRequest(() => doPut(url, data, dispatch, { ...defaultOptions, ...options }), dispatch);

const del = (url, dispatch, options = {}) =>
       dispatchRequest(() => doDelete(url, dispatch, { ...defaultOptions, ...options }), dispatch);    // "delete" word is reserved by JS.

export default { get, post, put, delete: del };
