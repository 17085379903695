import React from 'react';

const Google = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M22.8385 11.7644C22.8385 10.9827 22.7761 10.1968 22.6432 9.42773H11.9771V13.8561H18.085C17.8316 15.2843 17.0172 16.5477 15.8247 17.3506V20.2239H19.4687C21.6086 18.221 22.8385 15.2632 22.8385 11.7644Z" fill="#4285F4"/>
        <path d="M11.9772 23.0002C15.0271 23.0002 17.5991 21.9819 19.473 20.2241L15.829 17.3507C14.8152 18.0521 13.5063 18.4493 11.9814 18.4493C9.03129 18.4493 6.52992 16.4253 5.63243 13.7041H1.87207V16.6662C3.79172 20.5494 7.70166 23.0002 11.9772 23.0002Z" fill="#34A853"/>
        <path d="M5.62853 13.7039C5.15485 12.2757 5.15485 10.7292 5.62853 9.30095V6.33887H1.87233C0.268467 9.58828 0.268467 13.4166 1.87233 16.666L5.62853 13.7039Z" fill="#FBBC04"/>
        <path d="M11.9772 4.55164C13.5894 4.52629 15.1476 5.14322 16.3152 6.27565L19.5437 2.99243C17.4994 1.04025 14.7861 -0.0330291 11.9772 0.000774952C7.70166 0.000774952 3.79172 2.45157 1.87207 6.33903L5.62827 9.30111C6.52161 6.57566 9.02713 4.55164 11.9772 4.55164Z" fill="#EA4335"/>
    </svg>;

export default Google;
