import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrder } from '../../../store/actions/orderActions';
import { addOrderComment } from '../../../store/actions/memberActions';
import { setModal } from '../../../store/actions/modalActions';
import getValidator from '../../../getValidator';
import { toast } from 'react-toastify';
import Toast from '../../elements/Toast';
import TextArea from '../../elements/TextArea';
import Button from '../../elements/Button';
import Icon from '../../elements/Icon';

class OrderCommentModal extends Component {
    selectedIcon = '';
    validator = getValidator(this);

    state = {
        comment: '',
        rating: ''
    }

    getOrder = () => {
        const { orderId, order, orders } = this.props;

        if (order)
            return order;

        return orders.find(order => order.id === orderId)
    }

    handleCommentChange = e => {
        this.setState({ comment: e.target.value });
    }

    handleRating = e => {
        const element = e.target.parentNode;
        if (element.nodeName === 'svg') {            
        const iconParentElement = element.parentNode;
            if (this.selectedIcon) {
                this.selectedIcon.classList.remove("rating-icon--active");
            }
            this.selectedIcon = iconParentElement;
            iconParentElement.classList.add("rating-icon--active");

            const rating = +element.attributes.rating.value;
            this.setState({ rating: rating });
        }
    }

    handleSubmit = () => {
        const { comment, rating } = this.state;
        const { orderId, boundAddOrderComment, boundSetModal } = this.props;

        const data = async () => await boundAddOrderComment(orderId, comment, rating);
        data().then(() => {
            boundSetModal('');
            toast(<Toast text="Yorumunuz başarılı eklendi." status="success" />, { autoClose: 3000 });

        })
            .catch(err => toast(<Toast text={err.message} status="error" />));
    }

    render() {
        const { comment } = this.props;

        const order = this.getOrder();

        if (!order)
            return null

        return (
            <div className="order-comment-modal">
                <div className="order-comment-modal__body">
                    <div className="order-comment-modal__content">
                        <div className="order-comment-modal__content__caption">Siparişi Yorumla & Puanla</div>
                        <div className="order-comment-modal__content__caption-label">
                            Görüşlerinize önem veriyoruz. Size daha iyi hizmet verebilmemiz için lütfen siparişinizi oylayın.
                        </div>
                        <div className="order-comment-modal__content__rating">
                            <div className="order-comment-modal__content__rating-group" onClick={this.handleRating}>
                                <div className="order-comment-modal__content__rating-icon">
                                    <Icon name="worst" rating="1" />
                                </div>
                                <div className="order-comment-modal__content__rating-icon">
                                    <Icon name="bad" rating="2" />
                                </div>
                                <div className="order-comment-modal__content__rating-icon">
                                    <Icon name="normal" rating="3" />
                                </div>
                                <div className="order-comment-modal__content__rating-icon">
                                    <Icon name="good" rating="4" />
                                </div>
                                <div className="order-comment-modal__content__rating-icon">
                                    <Icon name="excellent" rating="5" />
                                </div>
                            </div>
                            {/* <div className="order-comment-modal__content__rating-text">Mükemmel</div> */}
                        </div>
                        <div className="order-comment-modal__content__comment">
                            <TextArea
                                name="textarea"
                                className="order-comment-modal__content__textarea"
                                maxlength="200"
                                minLength="3"
                                required={true}
                                disabled={false}
                                onBlur={this.handleCommentChange}
                                value={comment}
                            />
                        </div>
                    </div>

                </div>
                <div className="order-comment-modal__bottom">
                    <Button color="white" onClick={this.cancel} className="order-comment-modal__bottom-button order-comment-modal__bottom-button--borderNoneTextUnderline">
                        <span>VAZGEÇ</span>
                    </Button>
                    <Button color="green" onClick={this.handleSubmit} className="order-comment-modal__bottom-button">
                        <span>Kaydet</span>
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    orders: store.orderData.orders
});

const mapDispatchToProps = dispatch => ({
    boundGetOrder: bindActionCreators(getOrder, dispatch),
    boundAddOrderComment: bindActionCreators(addOrderComment, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

OrderCommentModal.propTypes = {
    boundGetOrder: PropTypes.func.isRequired,
    boundAddOrderComment: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCommentModal);