import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RegisterForm from '../../account/RegisterForm';
import { connect } from 'react-redux';
import { getUser, register, registerWithFacebook, setIsNewUser } from '../../../store/actions/authActions';
import { getBasket } from '../../../store/actions/basketActions';
import { bindActionCreators } from 'redux';
import { addRegisterAction, sendPageView } from '../../../googleAnalyticsHelper';
import { setLocalStorage } from '../../../storage';
import { setModal } from '../../../store/actions/modalActions';
import { toast } from 'react-toastify';
import Toast from '../../elements/Toast';
import { saveCustomerPhoneId } from '../../../api';

class RegisterPage extends Component {
    state = {
        userExists: false
    };

    componentDidMount() {
        const { location } = this.props;

        sendPageView(location.pathname, 'Signin Sayfa');
    }

    postRegister = (nameSurname, email, phone, password, isMailingConfirmed, isSmsConfirmed, isLoyaltyConfirmed) => {
        const { boundRegister, boundGetBasket, boundSetModal, boundGetUser, boundSetIsNewUser } = this.props;

        boundRegister(nameSurname, email, phone, password, isMailingConfirmed, isSmsConfirmed, isLoyaltyConfirmed)
            .then(response => {
                if (response.data.resultCode === '1010') {
                    saveCustomerPhoneId(response.data.resultValue);
                    boundSetIsNewUser({
                        isNewUser: true
                    });
                    boundSetModal('phone-confirmation');
                } else {
                    boundGetUser();
                    boundGetBasket();

                    // For google analytics
                    this.setCurrentUser();
                    addRegisterAction('Gmail');

                    this.props.history.push('/');
                    boundSetModal('delivery-type');
                }
            })
            .catch(() => this.setState({ userExists: true }));
    };

    postRegisterWithFacebook = (accessToken, avatar) => {
        const { boundRegisterWithFacebook, boundGetBasket } = this.props;
        boundRegisterWithFacebook(accessToken)
            .then(() => {
                boundGetBasket();

                // For google analytics
                this.setCurrentUser();
                addRegisterAction('Facebook');


                setLocalStorage('fbavatar', avatar);

                toast(<Toast text="Kaydınızı tamamlamak için telefon numaranızı giriniz."
                             status="warning"/>, { autoClose: 3000 });

                this.props.history.push('/profil/hesap');
            });
    };


    setCurrentUser = () => {
        const { user } = this.props;

        if (user && user.id) {
            setLocalStorage('userId', user.personId);
            setLocalStorage('isLoggedIn', 'True');
        }
    };

    render() {
        const { userExists } = this.state;

        return <div className="register-form-section">
            <div className="form-auth-container">
                <RegisterForm postRegister={this.postRegister}
                              postRegisterWithFacebook={this.postRegisterWithFacebook}
                              userExistsError={userExists}
                />
            </div>
            <div className="social-media only-mobile">
                <div className="social-media__container">
                    <div className="social-media__application-icon-header">
                        <p>Her yerden kolay sipariş için uygulamamızı indirin</p>
                    </div>
                    <div className="social-media__application-icon-body">
                        <ul>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=tr.com.littlecaesars"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                >
                                    <img className="img-responsive"
                                         src="/images/footer/google-play.svg"
                                         alt="google-play.svg"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://itunes.apple.com/tr/app/little-caesars-t%C3%BCrkiye/id904413305?l=tr&mt=8"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                >
                                    <img className="img-responsive" src="/images/footer/ios-appstore.svg"
                                         alt="ios-appstore.svg"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="social-media__application-image">
                        <img src="" alt=""/>
                    </div>
                </div>
                <div className="social-media__phones">
                    <img src="/images/footer/mobile-bg.svg" alt="mobile-bg.svg"/>
                </div>
            </div>
        </div>;
    }
}


const mapDispatchToProps = dispatch => ({
    boundRegister: bindActionCreators(register, dispatch),
    boundRegisterWithFacebook: bindActionCreators(registerWithFacebook, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundSetIsNewUser: bindActionCreators(setIsNewUser, dispatch)
});

RegisterPage.propTypes = {
    addRegisterAction: PropTypes.func,
    boundRegister: PropTypes.func.isRequired,
    boundRegisterWithFacebook: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundSetIsNewUser: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(RegisterPage);
