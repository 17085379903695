import React from 'react';
import { NavLink } from 'react-router-dom';

const AuthNav = ({ isSignUp }) => {

    return (
        <div className="auth-navigation">
            <NavLink to="/hesap/giris" className="btn btn-login btn-auth"
                     activeClassName={!isSignUp ? 'btn btn-login btn-auth--active' : ''}
                     role="button"
            >
                Üye Girişi
            </NavLink>
            <NavLink to="/hesap/uye-ol"
                     className="btn btn-sign btn-auth"
                     activeClassName={isSignUp ? 'btn btn-sign btn-auth--active' : ''}
                     role="button"
            >
                Hemen Kayıt Ol
            </NavLink>
        </div>
    );
};

export default AuthNav;