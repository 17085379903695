import React from 'react';

const User = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.0001 3.9082C14.2555 3.9082 16.091 5.74366 16.091 7.99911C16.091 10.2546 14.2555 12.09 12.0001 12.09C9.74463 12.09 7.90918 10.2546 7.90918 7.99911C7.90918 5.74366 9.74463 3.9082 12.0001 3.9082" stroke="black" strokeWidth="1.5" />
        <path d="M3 21V20.4545C3 17.4415 5.37071 15 8.29412 15H15.7059C18.6304 15 21 17.4415 21 20.4545V21" stroke="#231F20" strokeWidth="1.5" strokeLinecap="round" />
    </svg>


export default User;
