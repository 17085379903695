import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { DEFAULT_CENTER, GOOGLE_MAPS_API_KEY, getRestaurantLocation, getRestaurantWorkingTimes } from '../../utils';
import { getRestaurants, getRestaurantDetails } from '../../store/actions/addressActions';
import { getProducts, getProductDepartments } from '../../store/actions/productActions';
import { setModal } from '../../store/actions/modalActions';
import { Helmet } from 'react-helmet';
import GoogleMapReact from 'google-map-react';
import { sendPageView, addClickProductEvent, addImpressionEvent } from '../../googleAnalyticsHelper';
import ProductCard from '../product/ProductCard';
import { setLocalStorage, getFromLocalStorage } from '../../storage';
import Icon from '../elements/Icon';

class RestaurantDetails extends Component {

    state = {
        restaurant: '',
        campaigns: []
    };

    componentDidMount() {
        const { match, location, productData,
                boundSetModal, boundGetRestaurantDetails, boundGetProductDepartments } = this.props;
        
        boundSetModal('');
        boundGetRestaurantDetails(match.params.id).then(response => this.setState({ restaurant: response.data.result }));

        if (!productData.productDepartments.length) {
            boundGetProductDepartments().then(response => this.getCampaigns(response.payload));
        } else
            this.getCampaigns(productData.productDepartments);
        
        this.initializeLocalStorage();
        sendPageView(location.pathname, 'Restoran Detayı Sayfası'); //For google analytics
    }

    initializeLocalStorage = () => {
        setLocalStorage('restCampaigns', '');
    }

    getCampaigns = categories => {
        const { basket, boundGetProducts } = this.props;
        const { campaigns } = this.state;

        const category = categories.find(c => c.name.toLowerCase() === 'kampanyalar');

        if (category && campaigns.length < 1) {
            boundGetProducts(category.id, basket.id).then(response =>
                this.setState({ campaigns: response.data.result.slice(0, 3) }));
        }
    };

    setUpMap = ({ map, maps }) => {
        const { boundGetRestaurants } = this.props;
        const { restaurant } = this.state;
        this.map = map;
        this.maps = maps;

        if (restaurant != null) {
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude: lat, longitude: lng } }) =>
                    boundGetRestaurants(lat, lng).then(this.showRestaurantMarker)
            );
        }
    };

    goToMap = () => {
        const { history } = this.props;

        history.push('/musteri-destegi/harita');
    };

    goToCampaigns = () => {
        const { history } = this.props;

        history.push('/siparis/kampanyalar');
    };

    getDirections = () => {
        const { restaurant } = this.state;

        if (restaurant) {
            // open google maps and get directions to the restaurant
            // const url = `https://maps.google.com?saddr=Current+Location&daddr=${restaurant.latitude},${restaurant.longitude}`;

            // open google maps with restaurant coordinates
            const url = `https://www.google.com/maps/search/?api=1&query=${restaurant.latitude},${restaurant.longitude}`;
            window.open(url, '_blank');
        }
    };

    showRestaurantMarker = () => {
        const { restaurant } = this.state;

        const location = getRestaurantLocation(restaurant);

        if (location) {
            const marker = new this.maps.Marker({ position: location, map: this.map, icon: '/icons/restaurant.svg' });
        }
    };

    renderRestaurantDetails = () => {
        const { restaurant } = this.state;

        return <div className="restaurant-details">
            <div className="restaurant-details__block">
                <div className="restaurant-details__title">Çalışma Saatleri</div>
                <div className="restaurant-details__content">{getRestaurantWorkingTimes(restaurant)} </div>
            </div>
            <div className="restaurant-details__block">
                <div className="restaurant-details__title">Gönderim Süresi</div>
                <div className="restaurant-details__content">{restaurant.serviceTime} Dakika (Gel-Al 22 Dakika)
</div>
            </div>
            <div className="restaurant-details__block">
                <div className="restaurant-details__title">Adres</div>
                <div className="restaurant-details__content">{restaurant.address}</div>
            </div>
            <div className="restaurant-details__block">
                <div className="restaurant-details__title">Hizmetler</div>
                <div className="restaurant-details__content">{restaurant.orderTypesListString}</div>
            </div>
            <div className="restaurant-details__block">
                <div className="restaurant-details__title">Minimum Paket Tutarı</div>
                <div className="restaurant-details__content">Paket Servis : 14.90 TL, Gel Al : 1.00 TL, Araca Teslim : 1.00 TL</div>
            </div>
            <div className="restaurant-details__block">
                <div className="restaurant-details__title">Ödeme Tipi</div>
                <div className="restaurant-details__content">{restaurant.paymentTypesListString}</div>
            </div>
        </div>
    };

    getViewCampaignModal = campaign => () => {
        const { boundSetModal } = this.props;
        const { campaigns } = this.state;

        const position = campaigns.findIndex(c => c.id === campaign.id);
        
        //for google analytics
        setLocalStorage(`prodPos${campaign.id}`, position + 1);
        setLocalStorage(`pList${campaign.id}`, 'Restoran-Kampanyalar');
        setLocalStorage(`pCategory${campaign.id}`, 'Tüm Kampanyalar');

        addClickProductEvent(campaign, position + 1, 'Restoran-Kampanyalar', 'Tüm Kampanyalar');
        boundSetModal(`view-promotion/${campaign.id}`);
    };

    renderCampaigns = () => {
        const { campaigns } = this.state;
        
         //add impression event for Google Analytics
         if (campaigns && campaigns.length && getFromLocalStorage('restCampaigns') !== campaigns[0].name) {
            setLocalStorage('restCampaigns', campaigns[0].name);
            addImpressionEvent(campaigns, 'Restoran-Kampanyalar', 'Tüm Kampanyalar');
        }

        return campaigns && campaigns.length &&
            campaigns.map(campaign =>
                <ProductCard key={campaign.id} product={campaign} onClick={this.getViewCampaignModal(campaign)} item={'item'}/>
            );
    };

    render() {
        const { restaurant, campaigns } = this.state;
        const location = {};

        if (restaurant) {
            location.Latitude = parseFloat(restaurant.latitude);
            location.Longitude = parseFloat(restaurant.longitude);
        }

        return <div className="restaurant-details-container">
            <div className="app-content-container app-content-container--mobile-width">
                <div className="restaurant-details-caption icon-with-label" onClick={this.goToMap}>
                     <Icon name="chevron-left"/>
                     <span> Haritaya Dön </span>
                </div>
                {restaurant ?
                    <div className="restaurant-details-section">
                        <div className="restaurant-details-wrapper">
                            <div className="restaurant-details-title">
                                {restaurant ?
                                    `Little Caesars ${restaurant.name} Şubesi`
                                    :
                                    null
                                }
                            </div>
                            <div className="restaurant-details-body">
                                <div className="restaurant-details-map">
                                    {restaurant ?
                                        <Helmet title={restaurant.name}/>
                                        :
                                        null
                                    }
                                    <GoogleMapReact
                                        bootstrapURLKeys={GOOGLE_MAPS_API_KEY}
                                        defaultZoom={12}
                                        center={restaurant ? [location.Latitude, location.Longitude] : DEFAULT_CENTER}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={this.setUpMap}/>

                                    <div className="restaurant-details-map__button" onClick={this.getDirections}>
                                        Yol Tarifi Al
                                    </div>
                                </div>
                                {restaurant ?
                                    this.renderRestaurantDetails()
                                    :
                                    null}
                            </div>
                        </div>
                        <div className="restaurant-details-campaigns">
                            <div className="restaurant-details-campaigns__header">
                                <div className="restaurant-details-campaigns__header__left">Kampanyalar</div>
                                <div className="restaurant-details-campaigns__header__right"
                                     onClick={this.goToCampaigns}
                                >
                                    Tüm Kampanyalar
                                </div>
                            </div>
                            <div className="restaurant-details-campaigns__block">
                                {
                                    campaigns && campaigns.length
                                        ? this.renderCampaigns()
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    basket: store.basket.basket,
    user: store.user.user,
    addressData: store.addressData,
    productData: store.productData,
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({
    boundGetRestaurants: bindActionCreators(getRestaurants, dispatch),
    boundGetRestaurantDetails: bindActionCreators(getRestaurantDetails, dispatch),
    boundGetProducts: bindActionCreators(getProducts, dispatch),
    boundGetProductDepartments: bindActionCreators(getProductDepartments, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

RestaurantDetails.propTypes = {
    addressData: PropTypes.object.isRequired,
    boundGetRestaurants: PropTypes.func.isRequired,
    boundGetRestaurantDetails: PropTypes.func.isRequired,
    boundGetProducts: PropTypes.func.isRequired,
    boundGetProductDepartments: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantDetails);