import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import ProfileOrders from './orders/ProfileOrders';
import ProfileDesktopSidebar from './ProfileDesktopSidebar';
import ProfileAddresses from './address/ProfileAddresses';
import ProfileAccount from './account/ProfileAccount';
import OrderTracking from './order-tracking/OrderTracking';
import ProfileFavorites from './favorites/ProfileFavorites';
import { bindActionCreators } from 'redux';
import { setAddressSelection } from '../../store/actions/addressActions';
import PaymentSettings from './payment-settings/PaymentSettings';
import ProfileDropdownMenu from './ProfileDropdownMenu';

class ProfileManager extends Component {
    render() {
        const { user, boundSetAddressSelection } = this.props;

        if (!user.id) {
            return null;
        }

        return <div className="profile">
            <div className="app-content-container profile__container">
                <ProfileDesktopSidebar user={user} setAddressSelection={boundSetAddressSelection}/>

                <ProfileDropdownMenu/>

                <Route path="/profil/hesap" component={ProfileAccount}/>
                <Route path="/profil/favoriler" component={ProfileFavorites}/>
                <Route path="/profil/siparisler" component={ProfileOrders}/>
                <Route path="/profil/siparis-takip" component={OrderTracking}/>
                <Route path="/profil/adresler" component={ProfileAddresses}/>
                <Route path="/profil/kayıtlı-kartlarım" component={PaymentSettings}/>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user
});

const mapDispatchToProps = dispatch => ({
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch)
});

ProfileManager.propTypes = {
    user: PropTypes.object.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileManager);
