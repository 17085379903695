import { createReducer } from '../storeUtils';
import { SET_MENU_TOP, SET_FOOTER, SET_SHOULD_CHECKING_VEHICLE_DELIVERY } from '../actions/layoutActions';

const initialState = {
  menuTop: [],
  footer: [],
  shouldCheckingVehicleDelivery: false
};

function setMenuTop(state, action) {
  return {
    ...state,
    menuTop: action.payload
  };
}

function setFooter(state, action) {
  return {
    ...state,
    footer: action.payload
  };
}

function setShouldCheckingVehicleDelivery(state, action) {
  return {
    ...state,
    shouldCheckingVehicleDelivery: action.payload
  };
}

export default createReducer(initialState, {
  [SET_MENU_TOP]: setMenuTop,
  [SET_FOOTER]: setFooter,
  [SET_SHOULD_CHECKING_VEHICLE_DELIVERY]: setShouldCheckingVehicleDelivery
});
