import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';
import CorporateMenu from '../corporate-menu/CorporateMenu';

const CareerPlanning = () => {
    return (
      
<section class="institutional-structures">
    <div class="container">
        <BreadCrumbs />

        <div class="institutional-structures-detail">
            <div class="institutional-structures-detail-content clearfix">
                  <CorporateMenu />
                <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>Franchise İş Geliştirme Ekibi</h1>
                       
                        <div class="row business-development">
                            <div class="col-md-4">
                                <img class="img-responsive" src="/images/kurumsal/franchising/cem.jpg" alt="cem.jpg" />
                                <div class="team-text">
                                    <h6>Cem Gökdoğan</h6>
                                    <span>Operasyon Direktörü</span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <p>1969 Ankara doğumludur. Eskişehir Anadolu Üniversitesi İşletme Bölümü’nden mezun oldu. Kurumsal hayatına 1990 yılında, Koç Grubu’nun Altınyunus Oteli ile turizm sektöründe başladı. 1996 yılında, Telsim’de önce satış yöneticisi, sonra satış şefi ve bölge müdürü görevlerinde bulundu. 2008 yılına kadar bölge müdürü olarak Türkiye’nin hemen her yerinde görev aldıktan sonra, aynı yıl Türk Telekom grubunda TTNET’e satış direktörü olarak geçti. 2,5 yıl burada devam ettikten sonra Turkcell Superonline’da satış direktörlüğü görevinde bulundu. Kurumsal yaşamına 2014 yılında Çelebi Holding bünyesinde olan Little Caesars’ta operasyon direktörü olarak devam etme kararı aldı. Halen büyük bir mutluluk ve azimle bu görevi devam ettirmekte olan Cem Gökdoğan, evli ve 1 çocuk babasıdır.</p>
                            </div>
                        </div>
                        <div class="row business-development">
                            <div class="col-md-4">
                                <img class="img-responsive" src="/images/kurumsal/franchising/fatih.jpg" alt="fatih.jpg" />
                                <div class="team-text">
                                    <h6>Fatih Eren</h6>
                                    <span>İş Geliştirme Müdürü</span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <p>1978 yılında İstanbul’da doğdu. Eskişehir Anadolu Üniversitesi İşletme Bölümü’nden mezun oldu. Kurumsal hayatına 2000 yılında Teksu Grubu’nun satış departmanında gıda sektöründe başladı. 2005 yılında, Lezzo’da önce distribütör yöneticiliği, sonra bölge müdürlüğü görevlerinde bulundu. 2008 yılında Sabancı Grubu’nda DiaSa bünyesine katılarak franchise yöneticiliği ve franchise süpervizörlüğü görevlerini yürüttü. 2013 yılına kadar 100’ün üzerinde şube açılışı gerçekleştirdikten sonra iş hayatına As Tavukçuluk’ta bayi geliştirme koordinatörü olarak devam etti. 2015 yılına kadar Türkiye’nin her bölgesinde ve yurtdışında görevler aldıktan sonra aynı yıl Little Caesars ailesine iş geliştirme müdürü olarak katılmıştır.</p>
                            </div>
                        </div>
                        <div class="row business-development">
                            <div class="col-md-4">
                                <img class="img-responsive" src="/images/kurumsal/franchising/sukru.jpg" alt="sukru.jpg" />
                                <div class="team-text">
                                    <h6>Şükrü Ünal</h6>
                                    <span>Marmara Operasyon Müdürü</span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <p>1977 İstanbul doğumludur. İş hayatına 1994 yılında uluslararası bir fast food zincirinde başlamış ve çeşitli pozisyonlarda görev almıştır. 2001 yılında Little Caesars’ta restoran müdürü olarak başlamış olduğu kariyerinde, 2006 yılında bölge müdürlüğü görevine yükselmiş, 2013 yılında da operasyon müdürü olarak atanmıştır. Halen görevine İstanbul-Marmara operasyon müdürü olarak devam etmektedir. Evli ve 1 çocuk babasıdır.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    );
};

export default CareerPlanning;