import { SET_PAGE_RESULT } from '../actions/pagesActions';

const initialState = {
    pageResult: {}
};

const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE_RESULT:
            return {
                ...state,
                pageResult: action.payload
            };
        default:
            return state;
    }
};

export default pagesReducer;