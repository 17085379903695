import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setModal } from '../../store/actions/modalActions';
import getValidator from '../../getValidator';
import DropdownList from '../elements/DropdownList';
import Input from '../elements/Input';
import {
    formatMoney,
    getGiftProductsAmount,
    getOrderLoyaltyBlockText,
    isJson,
    maskCreditCardNumber,
    numberRegExp,
    removeAllSpaces,
    shouldShowGiftProducts
} from '../../utils';
import {
    getRestaurants, getUserDeliveryAddresses, setAddressSelection, changeDeliveryAddress
} from '../../store/actions/addressActions';
import {
    getDeliveryTypeId, formatDate, MIN_DESKTOP_WIDTH, isMobile, MIN_ORDER_PRICE_DELIVERY, MIN_ORDER_PRICE_OTHER,
    scrollToWrongField, appHeaderHeight, getRef
} from '../../utils';
import Button from '../elements/Button';
import { getFlavorsSuggestions, getPromotionCode, getPromotionList } from '../../store/actions/productActions';
import {
    completeOrder, getPaymentTypes, addOrderNote, getOrderNotes, removeAllOrders
} from '../../store/actions/orderActions';
import {
    getBasket,getBasketWOPromotion, setBasket, addToBasket, updateBasketCount, removeFromBasket, applyPromotionCode, selectPromotionCode,
    removePromotionCode
} from '../../store/actions/basketActions';
import {
    addCartOrderItemAction, addCartTransaction, addCouponAction, sendPageView, addImpressionEvent
} from '../../googleAnalyticsHelper';
import { hourList, minuteList, dateList } from './DeliveryDateTimeLists';
import moment from 'moment';
import { toast } from 'react-toastify';
import Toast from '../elements/Toast';
import { setLocalStorage, getFromLocalStorage } from '../../storage';
import BasketOrderItem from './BasketOrderItem';
import FlavorSuggestion from './FlavorSuggestion';
import Promotion from './Promotion';
import Icon from '../elements/Icon';
import Checkbox from '../elements/Checkbox';
import LcCarousel from '../elements/LcCarousel';
import { API_URL, paymentIframeUrl } from '../../environment';
import LcDropdown from '../elements/LcDropdown';
import TextInput from '../elements/TextInput';
import RemoveItemFromBasketPopup from '../order/RemoveItemFromBasketPopup';
import { checkMasterPass, commitPurchase, commitPurchaseAndRegister, commitDirectPurchase, getCreditCards } from '../../store/actions/memberActions';
import RemoveItemsFromBasketCheckoutPopup from './RemoveItemsFromBasketCheckoutPopup';
import { setShouldCheckingVehicleDelivery } from '../../store/actions/layoutActions';
import { monthList, yearList } from '../profile/payment-settings/MonthAndYearLists';
import { getUser } from '../../store/actions/authActions';

const carouselBreakPoints = {
    320: {
        slidesPerView: 'auto',
        spaceBetween: 10
    },
    [MIN_DESKTOP_WIDTH]: {
        slidesPerView: 3,
        spaceBetween: 20
    },
    1300: {
        slidesPerView: 4,
        spaceBetween: 20
    }
};

class CheckoutManager extends Component {
    validator = getValidator(this);
    cacheKillerNumber = null;

    state = {
        selectedBasket: {},
        name: '',
        email: '',
        phone: '',
        address: '',
        selectedAddressId: '',
        showApplyCodeText: false,
        showLaterDeliveryDateTime: false,
        showPaymentCards: false,
        deliveryDate: '',
        deliveryTime: '',
        deliveryHour: '',
        deliveryMinute: '',
        selectedPromotion: {},
        newPromotionCoupon: '',
        note: '',
        paymentType: {
            id: '',
            name: 'Nakit'
        },
        deliveryDateTime: '',
        time: '',
        flavorsSuggestions: [],
        paymentTypes: [],
        deletedItemsIds: [],
        promotions: [],
        noteList: [],
        shouldUpdateBasket: false,
        vehiclePlateNo: '',
        promotionList: [],
        triedToGetPromotions: false,
        itemToRemove: null,
        cardForPayment: "newCard",
        newCard: {
            creditCardName: '',
            creditCardNumber: '',
            expirationMonth: '',
            expirationYear: '',
            CVC: '',
        },
        isTouchless: false,
        termsOfUseMasterpass: false
    };

    //Google Analytics extra data
    gaExtraData = {
        orderType: '',
        orderTime: '',
        paymentMethod: ''
    };

    textInput = React.createRef();

    componentDidMount() {
        const {history,setRedirect, basket, location, paymentTypes, boundCheckMasterPass, creditCards, user, boundSetModal, boundGetCreditCards } = this.props;
        if (!this.props.user.id) {
            history.push('/hesap/giris');
        } 
        this.getDataForCheckout();
        this.setState({ selectedBasket: basket });

        sendPageView(location.pathname, 'Sepetim - Little Caesars'); // For ga
        this.initializeLocalStorage();
        this.addCartTransaction('checkout', 1);    //For ga

        if (basket.promotions && basket.promotions.length) {
            this.setState({ selectedPromotion: basket.promotions[0] });
        }

        if (user.personPhones /*&& creditCards.length === 0*/) {
             const phoneNumber = `90${user.personPhones[0].number}`;
             boundCheckMasterPass(phoneNumber, boundSetModal)
         }

       
    }

    onIframeMessage = event => {
        const { boundSetModal } = this.props;

        if (event.data && isJson(event.data)) {
            const data = JSON.parse(event.data);
            if (data.mdStatus === '1') {
                console.log('event came from MasterPass and payment passed ok. cheers, token: ', data.token);
                toast(<Toast text="Ödemeniz alınmıştır, lütfen bekleyiniz." status="success" />, { autoClose: 3000 });
                boundSetModal('');
                window.removeEventListener('message', this.addEventListenerHandler, true);
                console.log('Event listener stopped')
                this.completeOrder(data.token);

            } else if (data && data.mdErrorMsg) {
                toast(<Toast text={data.mdErrorMsg} status="error" />);
            }
        }

        // if (event.data && event.data.type === 'payment' && event.data.status) {
        //     console.log('event came from payment iframe and payment passed ok. cheers');
        //     toast(<Toast text="Ödemeniz alınmıştır, lütfen bekleyiniz." status="success" />, { autoClose: 3000 });
        //     this.getHandleSubmit()();
        // } else if (event.data && event.data.type === 'payment') {
        //     toast(<Toast text={event.data.mdErrorMsg} status="error" />);
        // }
    };

    componentDidUpdate(prevProps, prevState) {
        const { basket, boundGetPromotionList } = this.props;
        const { selectedBasket, triedToGetPromotions, paymentTypes } = this.state;
        const selectedAddress = this.getUserSelectedAddress();



        if (!triedToGetPromotions && this.props.user.email) {
            this.setState({ triedToGetPromotions: true });
            boundGetPromotionList(this.props.user.email)
                .then(response => {
                    this.setState({ promotionList: response.data.result });
                    if (response.data.result.length && basket.promotions.length) {
                        const promotionToSelect = response.data.result
                            .find(promotion => basket.promotions[0].promotion.id === promotion.id);
                        if (promotionToSelect) {
                            this.setState({ selectedPromotion: promotionToSelect.id });
                        }
                    }
                });
        }
        if (basket !== selectedBasket) {
            this.setState({ selectedBasket: basket });
        }
        if (prevState.selectedBasket.id !== this.state.selectedBasket.id) {
            this.cacheKillerNumber = (new Date()).getTime();
        }
        if (selectedAddress !== undefined && prevState.address === '') {
            this.setState({ address: this.formatDeliveryAddress(selectedAddress.address) });
        }
        if (prevProps.loading === true && this.props.loading === false && this.state.shouldUpdateBasket) {
            this.getBasket();
            this.setState({ shouldUpdateBasket: false });
        }
        if (this.props.addressData.addressSelection.address !== prevProps.addressData.addressSelection.address) {
            this.setState({ address: this.props.addressData.addressSelection.address }, () =>
                this.getPaymentTypes());
        }
        if (prevState.paymentType.id !== this.state.paymentType.id && this.state.paymentType.name !== 'Web (Online)') {
            this.setState({ isTouchless: false });
        }
        if (prevState.paymentTypes.length !== this.state.paymentTypes.length) {
            this.setDefaultPaymentMethod(this.state.paymentTypes);
        }

    }

    getDataForCheckout = () => {
        const {
            basket, addressData, boundGetBasket, boundGetUserDeliveryAddresses, boundGetRestaurants
        } = this.props;

        if (!this.hasAccessToPage()) {
            return;
        }
        if (!addressData.userDeliveryAddresses) {
            boundGetUserDeliveryAddresses();
        }
        if (!addressData.restaurants.length) {
            boundGetRestaurants();
        }
        if (!basket) {
            boundGetBasket();
        }

        this.getOrderNotes();
        this.getFlavorsSuggestions();
        this.getPaymentTypes();
    };

    hasAccessToPage = () => {
        const { basket } = this.props;
        return basket.totalCount;
    };

    initializeLocalStorage = () => {
        setLocalStorage('flavorsSuggestions', '');
    };

    getOrderNotes = () => {
        const { boundGetOrderNotes } = this.props;

        boundGetOrderNotes().then(response => this.setState({ noteList: response.data.result }));
    };

    getFlavorsSuggestions = () => {
        const { boundGetFlavorsSuggestions } = this.props;

        boundGetFlavorsSuggestions().then(response => this.setState({ flavorsSuggestions: response.data.result }));
    };

    getPaymentTypes = () => {
        const { basket, boundGetPaymentTypes } = this.props;

        boundGetPaymentTypes(basket.id)
            .then(response => this.setState({ paymentTypes: response.data.result }));
    };

    getBasket = (item, action) => {
        const { boundGetBasket } = this.props;

        boundGetBasket().then(response => {
            this.setBasket(response.payload);

            if (item) {
                const orderItem = this.props.basket.orderItems.find(c => c.productId === item.productId);

                //Check if orderItem is found, then it is obtained from basket, if not then it means it's removed
                if (orderItem)
                    this.addCartOrderItemAction(orderItem, action);
                else
                    this.addCartOrderItemAction(item, action);
            }
        });
    };

    setBasket = (selectedBasket) => {
        const { boundSetBasket } = this.props;

        boundSetBasket(selectedBasket);
    };

    getAddToBasket = product => () => {
        const { basket, boundGetBasket, boundAddToBasket } = this.props;
        const { flavorsSuggestions } = this.state;
        const position = flavorsSuggestions.findIndex(p => p.id === product.id) + 1;

        boundAddToBasket(product, basket.id).then(() =>
            boundGetBasket().finally(() => {
                const orderItem = this.props.basket.orderItems.find(c => c.productId === product.id);

                //For google analytics
                setLocalStorage(`pList${product.id}`, 'Yanında İyi Gider');
                setLocalStorage(`prodPos${product.id}`, position);

                if (orderItem)
                    addCartOrderItemAction(orderItem, position, 'add', '', 'Yanında İyi Gider');

                toast(<Toast text="Seçiminiz Sepete Eklendi." status="success" />, { autoClose: 3000 });
            }
            ));
    };

    updateBasket = (item, action) => {
        const { loading } = this.props;
        if (!loading) {
            this.getBasket(item, action);
        } else {
            this.setState({ shouldUpdateBasket: true });
        }
    };

    addCartOrderItemAction = (orderItem, action) => {
        const position = parseInt(getFromLocalStorage(`prodPos${orderItem.productId}`));
        const category = getFromLocalStorage(`pCategory${orderItem.productId}`);
        const list = getFromLocalStorage(`pList${orderItem.productId}`);

        //for google analytics
        addCartOrderItemAction(orderItem, position, action, category, list);
    };

    getChangeCount = orderItem => event => {
        const { boundUpdateBasketCount } = this.props;
        const { selectedBasket } = this.state;

        const value = +event.target.value;
        if (!value || !Number.isInteger(value) || value > 9 || value < 1) {
            return;
        }
        const newSelectedBasket = { ...selectedBasket };
        newSelectedBasket.orderItems.find(oI => oI.id === orderItem.id).quantity = value;
        this.setBasket(newSelectedBasket);
        boundUpdateBasketCount(orderItem.id, value).then(this.updateBasket);
    };

    getIncrementCount = orderItem => () => {
        const { boundUpdateBasketCount } = this.props;
        const { selectedBasket } = this.state;
        const newSelectedBasket = { ...selectedBasket };
        const newQuantity = orderItem.quantity + 1;

        if (newQuantity > 9) {
            return;
        }
        newSelectedBasket.orderItems.find(oI => oI.id === orderItem.id).quantity = orderItem.quantity + 1;
        this.setBasket(newSelectedBasket);
        boundUpdateBasketCount(orderItem.id, newQuantity).then(() => this.updateBasket(orderItem, 'increment'));
    };

    getDecrementCount = orderItem => () => {
        const { boundUpdateBasketCount } = this.props;
        const { selectedBasket } = this.state;
        const newSelectedBasket = { ...selectedBasket };

        const newQuantity = orderItem.quantity - 1;
        if (newQuantity >= 1) {
            newSelectedBasket.orderItems.find(oI => oI.id === orderItem.id).quantity = orderItem.quantity - 1;
            this.setBasket(newSelectedBasket);
            boundUpdateBasketCount(orderItem.id, newQuantity).then(() => this.updateBasket(orderItem, 'decrement'));
        } else if (newQuantity === 0) {
            this.getOpenRemoveItemPopup(orderItem)();
        }
    };

    removeOrderItem = () => {
        const { boundRemoveFromBasket } = this.props;
        const { itemToRemove } = this.state;
        const { selectedBasket, deletedItemsIds } = this.state;
        const newdeletedItemsIds = [...deletedItemsIds];
        newdeletedItemsIds.push(itemToRemove.id);
        this.setState({ deletedItemsIds: newdeletedItemsIds });
        this.closeRemoveItemPopup();
        boundRemoveFromBasket(itemToRemove.id, selectedBasket.id)
            .then(() => this.updateBasket(itemToRemove, 'remove'))
            .catch(() => {
                newdeletedItemsIds.splice(newdeletedItemsIds.indexOf(itemToRemove.id));
                this.setState({ deletedItemsIds: newdeletedItemsIds });
            });
    };

    removeAllOrders = () => {
        const { boundRemoveAllOrders, history } = this.props;
        const { selectedBasket, itemToRemove } = this.state;

        boundRemoveAllOrders(selectedBasket.id)
            .then(() => {
                this.updateBasket(itemToRemove, 'remove');
                history.push('/siparis/kampanyalar');
            });

    };

    getOpenRemoveItemPopup = item => () => this.setState({ itemToRemove: item });

    closeRemoveItemPopup = () => this.setState({ itemToRemove: null });

    getOpenEditAddressModal = (addressId, id) => () => {
        const { boundSetModal } = this.props;
        boundSetModal(`edit-address/${addressId}/${id}`);
    };

    goToLoyaltyPage = () => {
        const { history, location, boundSetModal } = this.props;
        if (location.pathname !== '/sezarla-kazan') {
            history.push('/sezarla-kazan');
        }
        boundSetModal('');
    };

    openAddAddressModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('add-address');
    };

    openAddressSelectionModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('delivery-type');
    };

    addOrderNote = () => {
        const { user, boundAddOrderNote, boundGetOrderNotes } = this.props;
        const { note } = this.state;

        if (note.length !== 0) {
            boundAddOrderNote(user.personId, note)
                .then(() => boundGetOrderNotes().then(response =>
                    this.setState({
                        noteList: response.data.result,
                        note: ''
                    })));
        }
    };

    completeOrder = (webPaymentToken = null) => {
        const {
            basket, addressData, history, boundCompleteOrder, boundGetBasket, boundSetShouldCheckingVehicleDelivery,
            boundGetUser, user
        } = this.props;
        const { paymentType, note, deliveryDateTime, isTouchless } = this.state;

        //Get order delivery type
        const orderTypeId = getDeliveryTypeId(addressData.addressSelection.type);

        let completeNote = note;
        if (isTouchless) {
            completeNote = '!!!TEMASSIZ TESLİMAT!!! ' + note;
        }



            boundCompleteOrder(
                basket.id, paymentType.id, completeNote, deliveryDateTime, addressData.addressSelection.addressId,
                orderTypeId, webPaymentToken
            )
            .then(response => {
                if (response && response.data.resultCode == '404') {
                    toast(
                        <RemoveItemsFromBasketCheckoutPopup
                            text={response.data.message}
                            submit={this.removeAllOrders}
                        />,
                        { closeOnClick: true }
                    );
                    return;
                }
                const orderedGifts = basket.orderItems.filter(product => product.isGift === 'true');
                if (user.loyalty && user.loyalty.punchCard && getGiftProductsAmount(user) === orderedGifts.length) {
                    setLocalStorage('giftWonModalShown', false);
                }
                boundGetBasket(true);
                if (user.id) {
                    boundGetUser(); // For loyalty
                }
                this.addCartTransaction('purchase', 1);   //for Google Analytics data
                history.push(`/completed-order/${response.data.result.id}`);
                boundSetShouldCheckingVehicleDelivery(true);
            })
            .catch(() => {
                toast(<Toast text="Siparişiniz verilemedi." status="error"/>);
                this.addCartTransaction('error', ''); //for Google Analytics data
            });
    };

    getHandleSubmit = () => {
        const { basket, addressData, boundCommitPurchase, boundCommitPurchaseAndRegister, boundCommitDirectPurchase, user, boundSetModal } = this.props;
        const phoneNumber = `90${user.personPhones[0].number}`;
        const { paymentType, showLaterDeliveryDateTime, cardForPayment, termsOfUseMasterpass } = this.state;
        const { creditCardName, creditCardNumber, expirationMonth, expirationYear, CVC } = this.state.newCard;

        this.validator.showMessages();
        if (this.validator.allValid()) {
            if (!addressData.addressSelection.address) {
                return toast(<Toast text="Lütfen bir gönderim adresi seçiniz." status="error" />);
            }
            const minOrderPrice = addressData.addressSelection.type === 'Adrese Teslim'
                ? MIN_ORDER_PRICE_DELIVERY
                : MIN_ORDER_PRICE_OTHER;
            if (+basket.amount < minOrderPrice) {
                const message = 'Sepet tutarınız en az ' + minOrderPrice + ' TL olmalıdır.';
                return toast(<Toast text={message} status="error" />);
            }

            //Define extra data for Google Analytics Hype integration
            this.gaExtraData.orderType = addressData.addressSelection.type;
            this.gaExtraData.orderTime = !showLaterDeliveryDateTime ? 'Hemen' : 'Daha Sonra';
            this.gaExtraData.paymentMethod = paymentType.name;

            if(this.isOnlinePaymentType(paymentType)) {
                if (window.addEventListener) {
                    window.removeEventListener('message', this.addEventListenerHandler, true);
                    console.log('Event Listeners Cleared: ', window.addEventListener)
                    this.addEventListenerHandler = this.onIframeMessage.bind(this);
                    window.addEventListener("message", this.addEventListenerHandler, true);
                    console.log('Event Lisneter started');
                }
                console.log("approved: " + termsOfUseMasterpass)
                console.log("cardforpayment" + cardForPayment)
                if (cardForPayment == "newCard") {
                    if (termsOfUseMasterpass) {
                        const expirationDate = expirationYear + (expirationMonth < 10 ? `0${expirationMonth}` : expirationMonth);
                        boundCommitPurchaseAndRegister(
                            basket.amount, creditCardName, creditCardNumber, expirationDate, CVC, phoneNumber,
                            basket.id, boundSetModal, this.completeOrder
                        );
                    }
                    else {
                        const expirationDate = expirationYear + (expirationMonth < 10 ? `0${expirationMonth}` : expirationMonth);
                        //(amount, rtaPan, expiryDate, cvc, msisdn, basketId, setModal, callback)
                        boundCommitDirectPurchase(
                            basket.amount, creditCardNumber, expirationDate, CVC, phoneNumber, basket.id, boundSetModal,
                            this.completeOrder
                        );
                    }
                } else {
                    boundCommitPurchase(basket.amount, cardForPayment, phoneNumber, basket.id, boundSetModal, this.completeOrder);
                }
            } else {
                this.completeOrder();
            }

        } else {
            scrollToWrongField(this, this.validator, window, appHeaderHeight);
        }
    };

    handlePromotionChange = promotion => {
        this.setState({ selectedPromotion: promotion });
        this.applyPromotionCode(promotion);
    };

    getHandleAddressChange = addressId => () => {
        const {
            basket, boundGetBasket, boundChangeDeliveryAddress,
            boundSetAddressSelection, boundGetPaymentTypes
        } = this.props;
        const selectedAddress = this.setUserSelectedAddress(addressId);

        return boundChangeDeliveryAddress(basket.id, addressId)
            .then(response => {
                if (response && response.data.resultCode == '404') {
                    toast(<RemoveItemsFromBasketCheckoutPopup
                        text={response.data.message}
                        submit={this.removeAllOrders}
                    />, { closeOnClick: true });
                    return;
                }
                boundSetAddressSelection({
                    type: this.getDeliveryType(),
                    address: selectedAddress.address.name,
                    addressId: selectedAddress.addressId
                })
            })
            .then(() => boundGetBasket().finally(() => {
                this.setState({ address: selectedAddress.address.name });
                boundGetPaymentTypes(basket.id);
            }));
    };

    noteInputHandler = event => this.setState({ note: event.target.value });

    focus = () => this.textInput.current.focus();

    applyPromotionCode = (promotion) => {
        const { basket, boundSelectPromotion } = this.props;
        const { showApplyCodeText } = this.state;

        if (!showApplyCodeText) {
            boundSelectPromotion(basket.id, promotion.id).then(() => {
                this.getBasket();
                addCouponAction('Click', 'Basarili');
            }
            )
                .catch(err => {
                    addCouponAction('Click', 'Basarisiz');
                    toast(<Toast text="hata oluştu" status="error" />);
                });
        }
    };

    getDate = selectedDate => {
        let date;

        if (+selectedDate === 1)
            date = moment();
        else if (+selectedDate === 2)
            date = moment().add(1, 'days');

        return date;
    };

    handleDateChange = selectedDate => {
        const { deliveryHour, deliveryMinute } = this.state;

        const date = this.getDate(selectedDate);
        const hour = deliveryHour;
        const minute = deliveryMinute;
        const deliveryDateTime = formatDate(date) + ' ' + hour + ':' + minute;

        this.setState({ deliveryDateTime, deliveryDate: selectedDate });
    };

    handleHourChange = selectedHour => {
        const { deliveryDate, deliveryMinute } = this.state;

        const date = this.getDate(deliveryDate);
        const hour = selectedHour;
        const minute = deliveryMinute;
        const deliveryDateTime = formatDate(date) + ' ' + hour + ':' + minute;

        this.setState({ deliveryDateTime, deliveryHour: hour });
    };

    handleMinuteChange = selectedMinute => {
        const { deliveryDate, deliveryHour } = this.state;

        const date = this.getDate(deliveryDate);
        const hour = deliveryHour;
        const minute = selectedMinute;
        const deliveryDateTime = formatDate(date) + ' ' + hour + ':' + minute;

        this.setState({ deliveryDateTime, deliveryMinute: minute });
    };

    getToggleShowLaterDeliveryDateTime = deliveryDateType => () => {
        const { showLaterDeliveryDateTime } = this.state;

        return deliveryDateType === 'later'
            ? showLaterDeliveryDateTime === false
                ? this.setState({ showLaterDeliveryDateTime: true })
                : null
            : deliveryDateType === 'now'
                ? showLaterDeliveryDateTime === true
                    ? this.setState({ showLaterDeliveryDateTime: false })
                    : null
                : null;
    };

    setNote = orderNoteId => {
        this.setState({ note: this.getOrderNoteById(orderNoteId) }, () => this.focus());
    };

    getOrderNoteById = id => {
        const { noteList } = this.state;
        if (noteList) {
            const orderNote = noteList.find(n => n.id === +id);
            return orderNote.Note;
        }
    };

    toggleTouchlessDelivery = () => this.setState({ isTouchless: !this.state.isTouchless });

    getHandlePaymentTypeChange = id => () =>
        this.setState({ paymentType: { id, name: this.getPaymentTypeName(id) } });

    onChangeCardForPayment = name => () => {
        this.setState({ cardForPayment: name})
    }

    toggleShowPaymentCards = () => {
        this.setState({ showPaymentCards: !this.state.showPaymentCards });
    };

    getPaymentTypeId = paymentTypeName => {
        const { paymentTypes } = this.state;

        if (paymentTypes.length) {
            const paymentType = paymentTypes.find(pt => pt.name === paymentTypeName);

            return paymentType ? paymentType.id : this.setPaymentType(paymentTypes[0]);
        }
    };

    getPaymentTypeName = id => {
        const { paymentTypes } = this.state;

        if (paymentTypes) {
            const paymentType = paymentTypes.find(pt => pt.id === id);
            return paymentType.name;
        }
    };

    setPaymentType = paymentType => this.setState({ paymentType });

    getDeleteButtonClassName = orderItem => {
        const { deletedItemsIds } = this.state;
        let className = 'product-delete-icon product-button-delete';
        if (deletedItemsIds.includes(orderItem.id)) {
            className += ' disabled';
        }
        return className;
    };

    getDeliveryType = () => {
        const { addressData } = this.props;
        return addressData.addressSelection.type;
    };

    getDeliveryTypeName = () => {
        const { addressData } = this.props;

        return addressData.addressSelection.type === 'Adrese Teslim' ? 'Teslimat' : 'Gel Al';
    };

    getRestaurant = () => {
        const { addressData } = this.props;

        return addressData.restaurants.find(restaurant => restaurant.id === addressData.addressSelection.addressId);
    };

    getUserSelectedAddress = () => {
        const { addressData } = this.props;
        if (addressData.userDeliveryAddresses) {
            return addressData.userDeliveryAddresses
                .find(address => addressData.addressSelection.addressId === address.addressId);
        }
    };

    setUserSelectedAddress = selectedAddressId => {
        const { addressData } = this.props;

        if (addressData.userDeliveryAddresses) {
            return addressData.userDeliveryAddresses.find(address => address.addressId === selectedAddressId);
        } else if (addressData.restaurants) {
            return addressData.restaurants.find(address => address.addressId === selectedAddressId);
        }
    };

    getAreaFromAddress = address => {
        const splittedAddress = address.split('/');
        splittedAddress.pop();

        return splittedAddress.pop();
    };

    formatDeliveryAddress = address => {
        return address.streetAddress.split('/')[0] + ' ' +
            (address.appartmentName !== null ? 'Bina Adı: ' + address.appartmentName + ' ' : '') +
            ' No: ' + (address.addressNo === '0' ? '' : address.addressNo + '/' + address.appartmentNo) + ' ' +
            address.streetAddress.replace(address.streetAddress.split('/')[0] + '/', '');
    };

    renderFlavorsSuggestions = () => {
        const { flavorsSuggestions } = this.state;

        if (flavorsSuggestions && flavorsSuggestions.length &&
            getFromLocalStorage('flavorsSuggestions', flavorsSuggestions[0].name) !== flavorsSuggestions[0].name) {

            //for google analytics
            setLocalStorage('flavorsSuggestions', flavorsSuggestions[0].name);
            addImpressionEvent(flavorsSuggestions, 'Yanında İyi Gider', 'Tümü');
        }

        return flavorsSuggestions.map(flavor =>
            <FlavorSuggestion key={flavor.id} flavor={flavor}
                onAddToBasketClick={this.getAddToBasket(flavor)}
            />
        );
    };

    formatOrderNotes = noteList => {
        const formattedNoteList = [];
        for (let i = 0; i < noteList.length; i++) {
            formattedNoteList.push({
                label: noteList[i].Note,
                value: noteList[i].id
            });
        }

        return formattedNoteList;
    };

    addCartTransaction = (action, step = 1) => {
        const { basket } = this.props;
        const { newPromotionCoupon } = this.state;

        if (basket == null || basket.orderItems == null || basket.orderItems.length === 0) {
            return false;
        }

        addCartTransaction(basket, action, step, this.gaExtraData, newPromotionCoupon); //For Enhanced Ecommerce
    };

    getIframeUrl = () => {
        const { basket } = this.props;
        return `${paymentIframeUrl}/${basket.id}?v=${this.cacheKillerNumber}`;
    };

    isMainPaymentType = paymentType => !this.isOnlinePaymentType(paymentType)
        && (paymentType.name.toLowerCase() === 'nakit' || paymentType.name.toLowerCase() === 'kredi kartı');

    isSubPaymentType = paymentType => !this.isOnlinePaymentType(paymentType) && !this.isMainPaymentType(paymentType);

    isOnlinePaymentType = paymentType => paymentType.name.toLowerCase() === 'web (online)';

    renderOnlinePaymentCheckbox = () => {
        const { paymentTypes, paymentType } = this.state;
        const onlinePaymentType = paymentTypes.find(item => this.isOnlinePaymentType(item));
        if (!onlinePaymentType) {
            return null;
        }
        return <>
                    <Checkbox value={onlinePaymentType.id === paymentType.id}
                              onChange={this.getHandlePaymentTypeChange(onlinePaymentType.id)}
                              label={
                                      <>
                                          {onlinePaymentType.name}
                                          <img src="/images/secure-shopping.svg" alt="secure-shopping.svg" className="secure-shopping"/>
                                      </>
                                    }
                              className="payment-type-item"
                              activeColor="yellow"
                              type="radio"
                    />
        </>
    };

    getCreditCardIcon = creditCardNumber => {
        const creditCardFirstNumber = +(creditCardNumber).toString()[0];
        if (creditCardFirstNumber === 3) {
            return <Icon name="american-express" className="saved-card__icon" />;
        } else if (creditCardFirstNumber === 5 || creditCardFirstNumber === 6) {
            return <Icon name="mastercard" className="saved-card__icon" />;
        } else if (creditCardFirstNumber === 4) {
            return <Icon name="visa" className="saved-card__icon" />;
        } else if (creditCardFirstNumber === 9) {
            return <Icon name="troy" className="saved-card__icon" />;
        }
    };

    addNewPromotionByCoupon = () => {
        const { boundApplyPromotionCode, basket, boundGetBasket } = this.props;
        const { newPromotionCoupon } = this.state;
        boundApplyPromotionCode(basket.id, newPromotionCoupon).then(() => {
            this.setState({ newPromotionCoupon: '' });
            boundGetBasket();
        });
    };

    removePromotionDiscountFromBasket = () => {
        const { boundRemovePromotionCode, basket, boundGetBasketWOPromotion } = this.props;
        boundRemovePromotionCode(basket.promotions[0].id).then(() => {
            this.setState({ selectedPromotion: '', newPromotionCoupon: '' });
            boundGetBasketWOPromotion();
        });
    };

    newPromotionCouponInputHandler = event => this.setState({ newPromotionCoupon: event.target.value });

    renderPromotionInput = () => {
        const { newPromotionCoupon } = this.state;

        return <div className="dropdown__item promotion-input">
            <TextInput placeholder="Promosyon Kodu"
                value={newPromotionCoupon}
                onChange={this.newPromotionCouponInputHandler}
                containerClassName="promotion-input__body"
            />
            <div className="promotion-input__button"
                onClick={this.addNewPromotionByCoupon}
            >
                Uygula
            </div>
        </div>;
    };

    renderSelectedPromotionPreview = promotion => promotion.name;

    creditCardNumberInputHandler = event => {
        const { newCard } = this.state;
        const creditCardNumber = removeAllSpaces(event.target.value);
        if (creditCardNumber === '' || numberRegExp.test(creditCardNumber)) {
            this.setState({ newCard: { ...newCard, creditCardNumber } });
        }
    };

    selectMonth = expirationMonth => {
        this.setState(prevState => {
            return {
                newCard: {
                    ...prevState.newCard,
                    expirationMonth
                }
        }});
    };

    selectYear = expirationYear => {
        this.setState(prevState => {
            return {
                newCard: {
                    ...prevState.newCard,
                    expirationYear
                }
        }});
    };

    creditCardNameInputHandler = event => {
        const creditCardName = event.target.value;
        this.setState(prevState => {
            return {
                newCard: {
                    ...prevState.newCard,
                    creditCardName
                }
        }});
    };

    cvcInputHandler = event => {
        const CVC = event.target.value;
        if (CVC === '' || numberRegExp.test(CVC)) {
            this.setState(prevState => {
                return {
                    newCard: {
                        ...prevState.newCard,
                        CVC
                    }
            }});
        }
    };

    termsOfUseMasterpassHandler = () => {
        this.setState(prevState => ({
            termsOfUseMasterpass: !prevState.termsOfUseMasterpass
        }));
    };

    setDefaultPaymentMethod = (paymentTypes) => {
        const payment = paymentTypes.filter((payment) => payment.name === "Nakit");
        if (!!payment.length) this.getHandlePaymentTypeChange(payment[0].id)();
    };

    getDeliveryHour = () => {
        const { deliveryDate } = this.state;
        if (+deliveryDate === 1) {
            return hourList.filter((item) => +item.value > new Date().getHours());
        }
        return hourList.filter((item) => +item.value < 2);
    };

    renderLoyaltyBlock = () => {
        const { basket, user } = this.props;

        const loyaltyBlockText = getOrderLoyaltyBlockText(basket, user);
        if (!loyaltyBlockText) {
            return null;
        }
        return <div className="checkout-loyalty-block">
            <div className="checkout-loyalty-block__body">
                <img src="/images/loyalty-small-logo.png"
                     alt="loyalty-small-logo.png"
                     className="checkout-loyalty-block__image"
                />
                <div className="checkout-loyalty-block__text">
                    {loyaltyBlockText}
                </div>
            </div>
            {
                shouldShowGiftProducts(basket, user)
                    ? <div className="button button--green checkout-loyalty-block__button"
                           onClick={this.goToLoyaltyPage}
                    >
                        Hediyeni Al
                    </div>
                    : null
            }
        </div>;
    };

    render() {
        const { addressData, basket, user, history, creditCards } = this.props;
        const {
            showLaterDeliveryDateTime, deliveryDate, deliveryHour, deliveryMinute, note, paymentType, selectedPromotion,
            flavorsSuggestions, paymentTypes, noteList, showPaymentCards, vehiclePlateNo, promotionList, itemToRemove,
            isTouchless, cardForPayment, termsOfUseMasterpass
        } = this.state;
        const { creditCardName, creditCardNumber, expirationMonth, expirationYear, CVC } = this.state.newCard;

        const userDeliveryAddress = this.getUserSelectedAddress();
        const restaurant = this.getRestaurant();
        let orderItemsTotalPrice = 0;

        if (!this.hasAccessToPage()) {
            history.push('/siparis/kampanyalar');
        }
        this.validator.purgeFields();

        return <div className="checkout app-page-container">
            {
                itemToRemove
                    ? <RemoveItemFromBasketPopup submit={this.removeOrderItem}
                        close={this.closeRemoveItemPopup}
                    />
                    : null
            }
            <div className="checkout__body app-content-container">
                <div className="checkout__block">
                    <div className="checkout__my-basket">Sepetim</div>
                    <div className="product-section">
                        <div className="product-section-header">
                            <div className="product-section-header-item">ÜRÜN</div>
                            <div className="product-section-header-item">FİYAT</div>
                            <div className="product-section-header-item">ADET</div>
                            <div className="product-section-header-item">TUTAR</div>
                        </div>

                        {/* Order details should be added dynamically according to order. Texts in html tags are written for style reference */}
                        {
                            basket && basket.orderItems
                                ? basket.orderItems.map(orderItem => {
                                    orderItemsTotalPrice += orderItem.amount;
                                    return <BasketOrderItem key={orderItem.id} orderItem={orderItem}
                                        onChangeCount={this.getChangeCount(orderItem)}
                                        onDecrementCountClick={this.getDecrementCount(orderItem)}
                                        onIncrementCountClick={this.getIncrementCount(orderItem)}
                                        onDeleteOrderItemClick={this.getOpenRemoveItemPopup(orderItem)}
                                        onGetDeleteButtonClassName={this.getDeleteButtonClassName(orderItem)}
                                    />;
                                })
                                : null
                        }
                        {this.renderLoyaltyBlock()}
                        <div className="coupon-and-grand-total-wrapper">
                            <div className="coupon-wrapper">
                                <div className="coupon-title">
                                    <img src="/images/basket/gift.svg" alt="gift.svg" />
                                    Promosyon kodum var
                                </div>
                                <div className="promotion-input-container">
                                    <LcDropdown placeholder="Promosyon Adı"
                                        showOptionsHeader={false}
                                        value={selectedPromotion.name || ''}
                                        suggestions={promotionList}
                                        onSelect={this.handlePromotionChange}
                                        renderSuggestion={this.renderSelectedPromotionPreview}
                                        renderLastOption={this.renderPromotionInput}
                                        disabled={basket.promotions != null && basket.promotions.length > 0}
                                        className="promotion-dropdown lc-dropdown--small"
                                    />
                                    {
                                        basket.promotions && basket.promotions.length
                                            ? <div className="promotion-input__button"
                                                onClick={this.removePromotionDiscountFromBasket}
                                            >
                                                Kuponu Kaldır
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="grand-total-wrapper">
                                {
                                    basket.promotions != null && basket.promotions.length > 0
                                        ? basket.promotions.map(promotion =>
                                            <Promotion key={promotion.id}
                                                promotion={promotion}
                                            />
                                        )
                                        : null
                                }
                                <div className="total-wrapper">
                                    <div className="grand-total-title">
                                        {
                                            basket.promotions && basket.promotions.length
                                                ? <span>İNDİRİMLİ TUTAR</span>
                                                : <span>TOPLAM TUTAR</span>
                                        }
                                    </div>
                                    <div className="grand-total-price">{formatMoney(basket.amount)} TL</div>
                                </div>
                                {
                    user.id && basket.orderLoyalityData && basket.orderLoyalityData.punchCardStats.earnedPunch
                        ? <div className="basket-modal__footer">
                            <div>Bu siparişinizden <span>{+basket.orderLoyalityData.punchCardStats.earnedPunch} Dilim </span>kazanacaksınız</div>
                        </div>
                        : null
                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkout__block">
                    <div className="extra-products-section">
                        <div className="extra-products-section-header">
                            YANINDA İYİ GİDER
                        </div>
                        <div className="checkout-carousel-wrapper">
                            {
                                flavorsSuggestions.length
                                    ? <LcCarousel breakpoints={carouselBreakPoints}
                                        centeredSlides={isMobile()}
                                        showNavigation={!isMobile()}
                                    >
                                        {this.renderFlavorsSuggestions()}
                                    </LcCarousel>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className="checkout__block">
                    <div className="checkout__delivery-information">
                        <div className="delivery-information-title">{this.getDeliveryTypeName()} Bilgileri</div>
                        {
                            this.getDeliveryTypeName() === 'Teslimat'
                                ? <div className="add-address-button icon-with-label"
                                    onClick={this.openAddAddressModal}
                                >
                                    <Icon name="plus" />
                                    <span>Yeni Adres Ekle</span>
                                </div>
                                : null
                        }
                    </div>
                    <div className="select-address-section">
                        <div className="select-address-header" ref={getRef(this, 'adres')}>
                            {
                                addressData.addressSelection.type === 'Adrese Teslim'
                                    ? 'ADRES SEÇİN'
                                    : 'ŞUBE ADI ve ADRESİ'
                            }
                        </div>
                        {
                            (addressData.userDeliveryAddresses && addressData.userDeliveryAddresses.length) || restaurant
                                ? null
                                : <Button className="open-address-type-button" onClick={this.openAddressSelectionModal}>
                                    + Adres
                                </Button>
                        }
                        {
                            addressData.addressSelection.type === 'Adrese Teslim'
                                ? addressData.userDeliveryAddresses && addressData.userDeliveryAddresses.length
                                    ? addressData.userDeliveryAddresses.map(deliveryAddress =>
                                        <div key={deliveryAddress.addressId} className="select-address-wrapper">
                                            <div className="input-and-detail-wrapper">
                                                <div className="address-input">
                                                    <Checkbox className="address-input"
                                                        type="radio"
                                                        value={userDeliveryAddress && userDeliveryAddress.addressId === deliveryAddress.addressId}
                                                        onChange={this.getHandleAddressChange(deliveryAddress.addressId)}
                                                    />
                                                </div>
                                                <div className="address-detail-wrapper">
                                                    <div className="address-title">
                                                        {deliveryAddress.address.name}
                                                        {
                                                            deliveryAddress.address.streetAddress.includes('/')
                                                                ? `(${this.getAreaFromAddress(deliveryAddress.address.streetAddress)})`
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="address-detail">
                                                        {this.formatDeliveryAddress(deliveryAddress.address)}
                                                        {
                                                            deliveryAddress.extensionNumber
                                                                ? ` GSM: ${deliveryAddress.extensionNumber}`
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <Icon name="edit"
                                                onClick={this.getOpenEditAddressModal(deliveryAddress.addressId, deliveryAddress.addressId)}
                                                className="edit-icon"
                                            />
                                        </div>
                                    )
                                    : null
                                : (addressData.addressSelection.type === 'Gel Al' || addressData.addressSelection.type === 'Araca Teslim') && restaurant
                                    ? <div key={restaurant.id} className="select-address-wrapper">
                                        <div className="input-and-detail-wrapper">
                                            <div className="address-input">
                                                <Checkbox className="address-input"
                                                    type="radio"
                                                    value={true}
                                                />
                                            </div>
                                            <div className="address-detail-wrapper">
                                                <div className="address-title">
                                                    {restaurant.name}
                                                </div>
                                                <div className="address-detail">
                                                    {restaurant.address}
                                                    {
                                                        restaurant.extensionNumber
                                                            ? ` GSM: ${restaurant.extensionNumber}`
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                        }
                        <div className="checkout__address-selection-validation-error">
                            {
                                this.validator.message('adres', addressData.addressSelection.address || restaurant, 'required')
                                    ? <div>Sipariş verebilmek için adres eklemelisiniz.</div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className="checkout__block">
                    <div className="delivery-time-and-order-note-section">
                        <div className="delivery-time-section">
                            <div className="delivery-time-header">GÖNDERİM ZAMANI</div>
                            <div className="delivery-time-wrapper delivery-now">
                                <Checkbox type="radio"
                                    value={!showLaterDeliveryDateTime}
                                    onChange={this.getToggleShowLaterDeliveryDateTime('now')}
                                />
                                <div className="delivery-title">
                                    <span className="delivery-title__header">Hemen</span>
                                    <span className="delivery-title__text">(Yaklaşık 30 dakika)</span>
                                </div>
                            </div>
                            <div className="delivery-time-wrapper delivery-later">
                                <div className="input-and-title-wrapper">
                                    <Checkbox type="radio"
                                        value={showLaterDeliveryDateTime}
                                        onChange={this.getToggleShowLaterDeliveryDateTime('later')}
                                    />
                                    <div className="delivery-title">İleri Zamanlı</div>
                                </div>
                                {
                                    showLaterDeliveryDateTime
                                        ? <div className="delivery-date-row">
                                            <DropdownList placeholder="Tarih Seçin"
                                                className="delivery-date"
                                                value={deliveryDate}
                                                options={dateList}
                                                error={this.validator.message('Sipariş Tarihi ', deliveryDate, 'required')}
                                                onChange={this.handleDateChange}
                                                rootRef={getRef(this, 'Sipariş Tarihi ')}
                                            />

                                            <DropdownList placeholder="Saat"
                                                className="delivery-hour"
                                                value={deliveryHour}
                                                options={this.getDeliveryHour()}
                                                error={this.validator.message('Sipariş Saatı ', deliveryHour, 'required')}
                                                onChange={this.handleHourChange}
                                                rootRef={getRef(this, 'Sipariş Saatı ')}
                                            />

                                            <DropdownList placeholder="Dakika"
                                                className="delivery-minute"
                                                name="deliveryMinute"
                                                value={deliveryMinute}
                                                options={minuteList}
                                                error={this.validator.message('Sipariş Dakikası ', deliveryMinute, 'required')}
                                                onChange={this.handleMinuteChange}
                                                rootRef={getRef(this, 'Sipariş Dakikası ')}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="order-note-section">
                            <div className="order-note-header">SİPARİŞ NOTUNUZ</div>
                            <div className="order-note-wrapper">
                                <div className="dropdown-and-button">
                                    <div className="dropdown-wrapper">
                                        <DropdownList placeholder="Kayıtlı Notlarım"
                                            options={this.formatOrderNotes(noteList)}
                                            className="dropdown"
                                            onChange={this.setNote}
                                        />
                                    </div>
                                    <div className="save-note" onClick={this.addOrderNote}>
                                        <img src="/icons/download-button.svg" alt="download-button.svg" />
                                        Notu Kaydet
                                    </div>
                                </div>
                                <div className="note-area">
                                    <textarea placeholder="Siparişle ilgili notunuzu buraya yazabilirsiniz."
                                        value={note}
                                        ref={this.textInput}
                                        onChange={this.noteInputHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkout__block checkout__touchless-block">
                    <div className="checkout__block-header">
                        TEMASSIZ TESLİMAT
                    </div>
                    <div className="checkout__block-body checkout__touchless-container">
                        <div className="icon-with-label">
                            <span className="checkout__touchless-header">Temassız Teslimat </span>
                            <span className="checkout__touchless-subheader">(Sadece online ödemelerde geçerlidir)</span>
                        </div>
                        <Checkbox onChange={this.toggleTouchlessDelivery}
                            value={isTouchless}
                            type="switch"
                            disabled={paymentType.name !== 'Web (Online)'}
                        />
                    </div>
                </div>
                <div className="checkout__block">
                    <div className="payment-type">Ödeme Yöntemi</div>
                    <div className="payment-section">
                        <div className="payment-header">ONLINE ÖDEME</div>
                        {this.renderOnlinePaymentCheckbox()}
                        {
                            this.isOnlinePaymentType(paymentType)
                                // ? <iframe className="credircard-iframe" src={this.getIframeUrl()}
                                //           width="100%"
                                //           height="360"
                                //           id="iframe"
                                //           style={{ border: 'none' }}
                                // />
                                // : null
                                ? <div>
                                    {
                                      creditCards.length > 0
                                      ? <>
                                          {
                                            creditCards.map(card => (
                                                <div className="saved-card" key={card.UniqueId}>
                                                    <Checkbox value={cardForPayment === card.Name}
                                                              onChange={this.onChangeCardForPayment(card.Name)}
                                                              label={
                                                                      <>
                                                                          {this.getCreditCardIcon(card.Value1)}
                                                                          <label class="lc-checkbox__label">{`${card.Name} ${card.Value1}`}</label>
                                                                      </>
                                                                  }
                                                              activeColor="yellow"
                                                              type="radio"
                                                    />
                                                    <div className="masterpass">
                                                        <img src="/images/masterpass.svg" alt="masterpass.svg"/>
                                                    </div>
                                                </div>
                                            ))
                                          }
                                      </>
                                      : null
                                    }
                                    <div className="new-card ">
                                        <Checkbox value={cardForPayment === "newCard"}
                                                  onChange={this.onChangeCardForPayment("newCard")}
                                                  label={`Başka bir kartla devam etmek istiyorum`}
                                                  activeColor="yellow"
                                                  type="radio"
                                        />
                                    </div>
                                    {
                                        cardForPayment === "newCard"
                                        ? <div className="new-card-form ">
                                            <div className="card-form">
                                                <div className="card-form__row-container">
                                                    <span className="card-form__row-container--first-column">Kart Numarası:</span>
                                                    <div className="card-form__row-container--second-column">
                                                        <TextInput
                                                            pattern="\d*"
                                                            value={maskCreditCardNumber(creditCardNumber)}
                                                            onChange={this.creditCardNumberInputHandler}
                                                            className="credit-card-modal__input"
                                                            maxLength={19}  // 16 + 3 because of mask
                                                            error={this.validator.message('Kart Numarası ', creditCardNumber, 'required')}
                                                            autocomplete="cc-number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="card-form__row-container">
                                                    <span className="card-form__row-container--first-column">Geçerlilik tarihi:</span>
                                                    <div className="card-form__row-container--second-column">
                                                        <DropdownList
                                                            value={expirationMonth}
                                                            placeholder="Ay"
                                                            onChange={this.selectMonth}
                                                            className="credit-card-modal__dropdown-body"
                                                            error={ this.isOnlinePaymentType(paymentType) && cardForPayment === "newCard"
                                                                    ? this.validator.message('Ay', expirationMonth, 'required')
                                                                    : null
                                                            }
                                                            options={monthList}
                                                            optionClassName="credit-card-modal__dropdown-option"
                                                            autocomplete="cc-exp-month"
                                                        />
                                                        <DropdownList
                                                            value={expirationYear}
                                                            placeholder="Yıl"
                                                            onChange={this.selectYear}
                                                            className="credit-card-modal__dropdown-body"
                                                            error={ this.isOnlinePaymentType(paymentType) && cardForPayment === "newCard"
                                                                    ? this.validator.message('Yıl', expirationYear, 'required')
                                                                    : null
                                                            }
                                                            options={yearList}
                                                            optionClassName="credit-card-modal__dropdown-option"
                                                            autocomplete="cc-exp-year"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="card-form__row-container">
                                                    <span className="card-form__row-container--first-column">CVC:</span>
                                                    <div className="card-form__row-container--second-column">
                                                        <TextInput
                                                            type="number"
                                                            value={CVC}
                                                            onChange={this.cvcInputHandler}
                                                            className="credit-card-modal__input"
                                                            containerClassName="card-form__input--cvc"
                                                            maxLength={3}
                                                            error={ this.isOnlinePaymentType(paymentType) && cardForPayment === "newCard"
                                                                    ? this.validator.message('cvc', CVC, 'required')
                                                                    : null
                                                            }
                                                            autocomplete="cc-csc"
                                                         />
                                                    </div>
                                                </div>

                                                <div className="agree-terms-wrapper">
                                                    <Checkbox value={termsOfUseMasterpass}
                                                              onChange={this.termsOfUseMasterpassHandler}
                                                              label={
                                                                  <div>
                                                                      Kart bilgilerimi MasterPass altyapısında saklamak ve bir sonraki alışverişimde tekrar kullanmak istiyorum.
                                                                  </div>
                                                              }
                                                              activeColor="yellow"
                                                              type="radio"

                                                    />
                                                </div>
                                                {
                                                    termsOfUseMasterpass
                                                        ? <div className="card-form__row-container">
                                                            <span className="card-form__row-container--first-column">Kartınıza isim verin:</span>
                                                            <div className="card-form__row-container--second-column">
                                                                <TextInput
                                                                    value={creditCardName}
                                                                    onChange={this.creditCardNameInputHandler}
                                                                    className="credit-card-modal__input"
                                                                    containerClassName="card-form__input"
                                                                    error={ this.isOnlinePaymentType(paymentType) && this.termsOfUseMasterpassHandler && cardForPayment === "newCard"
                                                                        ? this.validator.message('Kartınıza İsim ', creditCardName, 'required')
                                                                        : null
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                            <div className="payment-information">
                                                <div className="payment-information-text">
                                                    Kredi kartı bilgileriniz Little Caesars tarafından saklanmamaktadır.
                                                    Ödeme altyapısı Masterpass tarafından sağlanmaktadır. <a
                                                        href="https://masterpass.com/tr-tr.html#tr-tr"
                                                        target="_blank">Masterpass Nedir? </a><a
                                                        href="https://www.masterpassturkiye.com/TermsAndConditions.aspx"
                                                        target="_blank">
                                                          Kullanım koşulları için tıklayınız.
                                                    </a>
                                                </div>
                                                <div className="masterpass">
                                                    <img src="/images/masterpass.svg" alt="masterpass.svg"/>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                        }
                        {
                            paymentTypes.filter(paymentType => this.isSubPaymentType(paymentType)).length
                                ? <div className="payment-header">KAPIDA ÖDEME</div>
                                : null
                        }
                        {
                            paymentTypes ?
                                paymentTypes
                                    .sort((a, b) => a.index < b.index ? -1 : a.index > b.index ? 1 : 0)
                                    .map(item => {
                                      return  this.isMainPaymentType(item)
                                            ? <Checkbox onChange={this.getHandlePaymentTypeChange(item.id)}
                                                key={item.id}
                                                value={item.id === paymentType.id}
                                                label={item.name}
                                                className="payment-type-item"
                                                activeColor="yellow"
                                                keyValue={item.id}
                                                type="radio"
                                            />
                                            : null
                                    }
                                    )
                                : null
                        }
                        {
                            paymentTypes.filter(paymentType => this.isSubPaymentType(paymentType)).length
                                ? <div className="icon-with-label payment-type-item payment-type-item--section-button"
                                    onClick={this.toggleShowPaymentCards}
                                >
                                    <span className="payment-type-item__icon">
                                        <Icon name={showPaymentCards ? 'minus' : 'plus'} />
                                    </span>
                                    <span>Diğer Seçenekler (Yemek Kartları ve Ticketlar)</span>
                                </div>
                                : null
                        }
                        {
                            showPaymentCards
                                ? <div className="payment-cards">
                                    {
                                        paymentTypes
                                            .sort((a, b) => a.index < b.index ? -1 : a.index > b.index ? 1 : 0)
                                            .map(paymentCard =>
                                                this.isSubPaymentType(paymentCard)
                                                    ? <Checkbox onChange={this.getHandlePaymentTypeChange(paymentCard.id)}
                                                        value={paymentCard.id === paymentType.id}
                                                        label={paymentCard.name}
                                                        className="payment-type-item payment-type-item--left-gap"
                                                        activeColor="yellow"
                                                        keyValue={paymentCard.id}
                                                        type="radio"
                                                    />
                                                    : null
                                            )
                                    }
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className="checkout__block">
                    <div className="complete-order-section">
                        {
                            userDeliveryAddress
                                ? <div className="order-to">
                                    Siparişiniz <span>{addressData.addressSelection.address}</span> adresinize
                                    gönderilecektir.
                                </div>
                                : restaurant
                                    ? <div className="order-to">
                                        Siparişiniz <span>{restaurant.name}</span> restoranına gönderilecektir.
                                </div>
                                    : vehiclePlateNo
                                        ? <div className="order-to">
                                            Siparişiniz <span>{restaurant.name}</span> restoranına gönderilecektir.
                                    </div>
                                        : null
                        }
                        <Button className="complete-order" onClick={this.getHandleSubmit} /*disabled={paymentType.name === 'Web (Online)'}*/>
                            Siparişi Tamamla
                        </Button>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    basket: store.basket.basket,
    loading: !!store.loader.loadingCount,
    addressData: store.addressData,
    promotionCode: store.productData.promotionCode,
    user: store.user.user,
    creditCards: store.member.creditCards,
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundGetUserDeliveryAddresses: bindActionCreators(getUserDeliveryAddresses, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundChangeDeliveryAddress: bindActionCreators(changeDeliveryAddress, dispatch),
    boundGetRestaurants: bindActionCreators(getRestaurants, dispatch),
    boundGetFlavorsSuggestions: bindActionCreators(getFlavorsSuggestions, dispatch),
    boundGetPaymentTypes: bindActionCreators(getPaymentTypes, dispatch),
    boundCompleteOrder: bindActionCreators(completeOrder, dispatch),
    boundGetPromotionCode: bindActionCreators(getPromotionCode, dispatch),
    boundAddOrderNote: bindActionCreators(addOrderNote, dispatch),
    boundGetOrderNotes: bindActionCreators(getOrderNotes, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundGetBasketWOPromotion: bindActionCreators(getBasketWOPromotion, dispatch),
    boundSetBasket: bindActionCreators(setBasket, dispatch),
    boundAddToBasket: bindActionCreators(addToBasket, dispatch),
    boundUpdateBasketCount: bindActionCreators(updateBasketCount, dispatch),
    boundRemoveFromBasket: bindActionCreators(removeFromBasket, dispatch),
    boundRemoveAllOrders: bindActionCreators(removeAllOrders, dispatch),
    boundApplyPromotionCode: bindActionCreators(applyPromotionCode, dispatch),
    boundRemovePromotionCode: bindActionCreators(removePromotionCode, dispatch),
    boundSelectPromotion: bindActionCreators(selectPromotionCode, dispatch),
    boundGetPromotionList: bindActionCreators(getPromotionList, dispatch),
    boundSetShouldCheckingVehicleDelivery: bindActionCreators(setShouldCheckingVehicleDelivery, dispatch),
    boundCheckMasterPass: bindActionCreators(checkMasterPass, dispatch),
    boundCommitPurchase: bindActionCreators(commitPurchase, dispatch),
    boundCommitPurchaseAndRegister: bindActionCreators(commitPurchaseAndRegister, dispatch),
    boundCommitDirectPurchase: bindActionCreators(commitDirectPurchase, dispatch),
    boundGetCreditCards: bindActionCreators(getCreditCards, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch)
});

CheckoutManager.propTypes = {
    boundSetModal: PropTypes.func.isRequired,
    boundGetUserDeliveryAddresses: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundChangeDeliveryAddress: PropTypes.func.isRequired,
    boundGetRestaurants: PropTypes.func.isRequired,
    boundGetFlavorsSuggestions: PropTypes.func.isRequired,
    boundGetPaymentTypes: PropTypes.func.isRequired,
    boundAddOrderNote: PropTypes.func.isRequired,
    boundRemovePromotionCode: PropTypes.func.isRequired,
    boundGetOrderNotes: PropTypes.func.isRequired,
    boundCompleteOrder: PropTypes.func.isRequired,
    boundGetPromotionCode: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundGetBasketWOPromotion: PropTypes.func.isRequired,
    boundSetBasket: PropTypes.func.isRequired,
    boundAddToBasket: PropTypes.func.isRequired,
    boundUpdateBasketCount: PropTypes.func.isRequired,
    boundRemoveFromBasket: PropTypes.func.isRequired,
    boundRemoveAllOrders: PropTypes.func.isRequired,
    boundApplyPromotionCode: PropTypes.func.isRequired,
    boundSelectPromotion: PropTypes.func.isRequired,
    boundGetPromotionList: PropTypes.func.isRequired,
    boundSetShouldCheckingVehicleDelivery: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    basket: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    addressData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    boundCheckMasterPass: PropTypes.func.isRequired,
    boundCommitPurchase: PropTypes.func.isRequired,
    boundCommitDirectPurchase: PropTypes.func.isRequired,
    boundCommitPurchaseAndRegister: PropTypes.func.isRequired,
    creditCards: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutManager));
