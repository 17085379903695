import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const FranchisingOpportunityAreas = () => {
     sendPageView('/franchise/franchise-firsat-bolgeleri', 'Franchise Fırsat Bölgeleri - Little Caesars');  //for ga
    
     return (
        <section class="institutional-structures">
            <div class="container">
                <BreadCrumbs />
                <div class="institutional-structures-detail">
                    <div class="institutional-structures-detail-content clearfix">
                        <FranchisingMenu />
                        <div class="col-md-9">
                  <div class="corpareta-detail">
                    <h1>Franchise Fırsat Bölgeleri</h1>
                    <img class="img-responsive" src="/Images/kurumsal/franchise-firsat-bolgeleri.jpg" />

                    <div class="institutional-box">
                      <p><b>İstanbul Avrupa:</b> Bakırköy - Alibeyköy - Arnavutköy - Sefaköy</p>
                    </div>
                    <div class="institutional-box">
                      <p><b>İstanbul Anadolu:</b> Batı Ataşehir - Kaynarca - Beykoz - Küplüce</p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Ankara:</b> Eryaman - Gölbaşı - Bilkent - Etimesgut</p>
                    </div>
                    <div class="institutional-box">
                      <p><b>İzmir:</b> Bornova - Çiğli - Karşıyaka</p>
                    </div>
                   
                    <div class="institutional-box">
                      <p><b>Bursa:</b> Erikli - Nilüfer - Bademli - Gemlik - Mudanya</p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Konya:</b> Meram - Selçuklu</p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Manisa:</b> Merkez - Akhisar</p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Tekirdağ:</b> Merkez - Çerkezköy</p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Kocaeli</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Giresun</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Ordu</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Trabzon</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Düzce</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Yalova</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Sivas</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Karabük</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Aydın</b></p>
                    </div>
                    <div class="institutional-box">
                      <p><b>Muğla</b></p>
                    </div>
                  </div>

                </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default FranchisingOpportunityAreas;