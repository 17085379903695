import React from 'react';
import CorporateMenu from '../../corporate-menu/CorporateMenu';
import BreadCrumbs from '../../../elements/BreadCrumbs';
import { sendPageView } from '../../../../googleAnalyticsHelper';

const HRTraining = () => {
    sendPageView('/kurumsal/egitim', 'Eğitim - Little Caesars'); //For ga

    return <section class="institutional-structures">
        <div class="container">
            <BreadCrumbs />
            <div class="institutional-structures-detail">
                <div class="institutional-structures-detail-content clearfix">
                    <CorporateMenu />
                    <div class="col-md-9">
                        <div class="corpareta-detail">
                            <h1>Eğitim</h1>
                            <img class="img-responsive" src="/images/kurumsal/egitim.jpg" alt="egitim.jpg" />
                            <p>İnsan Kaynakları birimi olarak katılımcılığı, takım çalışması, girişimciliği ve yaratıcılığı destekleyerek Little Caesars Türkiye’yi çalışmak için tercih edilen, mutlu  ve bağlı çalışanlarıyla büyük bir aile haline getirmek ve başarıyı sürekli kılmak İnsan Kaynakları Politikamızın temelini oluşturur.</p>
                            <p><i>Verilen Eğitimler 3 çeşittir.</i><br /><b>1. İşbaşı &nbsp; &nbsp; 2. Sınıf İçi &nbsp; &nbsp; 3. E-Öğrenme</b></p>
                            <h2>İşbaşı Eğitimleri;</h2>
                            <p>İnsan Kaynakları ve Organizasyonel Gelişim Müdürlüğüne bağlı olan İş Başı Eğitim Uzmanları tarafından sahada, uygulamalı olarak verilen eğitimlerdir.</p>
                            <h2>Sınıf İçi Eğitimler;</h2>
                            <p>Belirli dönemlerde Eğitim Departmanı tarafından düzenlenen operasyonel ve yönetsel becerileri geliştirmeye yönelik eğitimlerdir. Mesleki Eğitimler ve Kişisel Gelişim Eğitimlerini de kapsayan eğitimler,  her yıl ihtiyaçlara göre planlanmaktadır. Şirket içinde alanlarında uzman eğitmenlerimiz ve kurum dışında profesyonel iş ve eğitim dünyasından güçlü iş ortaklarımız (eğitim ve danışmanlık firmaları, üniversiteler, akademisyenler, dernekler, vb.) ile birlikte eğitim modülleri hazırlanmakta ve verilen eğitimlerin hayata geçirilmesi sağlanmaktadır.</p>
                            <h2>E- Öğrenme;</h2>
                            <p>Online olarak ulaşılabilen, yer ve zaman sınırlamasını ortadan kaldıran zengin içeriğe sahip etkileşimli eğitimlerdir.<i>“Sezar Akademi E-Öğrenme Platformu”</i>   sayesinde  tüm çalışanlar işe ilk başladıkları andan itibaren tüm kariyer süreçlerinde merkezi sistemle, zaman ve mekan kısıtlaması olmadan eğitimlerini takip ederler.
E-Öğrenme platformunda her şubeye bir kullanıcı hesabı tanımlanmıştır. Eğitim Departmanı tarafından hazırlanan yönerge doğrultusunda Ekip Üyeleri’nin eğitimi Restoran Müdürleri’nin rehberliğinde takip edilmektedir.  Little Caesars Eğitim Sistemi, her bir yöneticinin aynı zamanda “iyi bir eğitimci” olmasını sağlayacak şekilde kurgulanmıştır.</p>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
};

export default HRTraining;