import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TopInfoBlockItem = ({ title, imageUrl, imageName, details, linkUrl, linkTitle, color }) =>
    <Link className={`top-info-block__item top-info-block__item--${color}`} to={linkUrl}>
        <div className="top-info-block__image-container">
            <img className="top-info-block__image" src={imageUrl} alt={imageName}/>
        </div>
        <div>
            <div className="top-info-block__title">{title}</div>
            <div className="top-info-block__details">{details}</div>
            <div className="top-info-block__link-container">
                <div className="top-info-block__link">{linkTitle}</div>
            </div>
        </div>
    </Link>;

TopInfoBlockItem.propTypes = {
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageName: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export default TopInfoBlockItem;



