import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../elements/Icon';

const LoyaltyActivatedNotificationModal = props => {
    const closeModal = () => {
        const { setModal } = props;
        setModal('');
    };

    return <div className="modal-content loyalty-activated-notification-modal">
        <Icon name="cancel" className="modal-alt-close-icon" onClick={closeModal}/>
        <img src="/images/loyalty-activated-notification-modal-header.png"
             alt="loyalty-activated-notification-modal-header.png"
             className="loyalty-activated-notification-modal__header"
        />
        <div className="loyalty-activated-notification-modal__text">
            Sezarla Kazan’a Hoşgeldiniz, profilimden ayarlarınızı değiştirebilirsiniz.
        </div>
    </div>;
};

LoyaltyActivatedNotificationModal.propTypes = {
    setModal: PropTypes.func.isRequired
};

export default LoyaltyActivatedNotificationModal;
