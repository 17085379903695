import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatMoney, getRef, maxIngredientCount } from '../../utils';
import Icon from '../elements/Icon';
import Button from '../elements/Button';

class ExtraOptionsModalContent extends Component {
    state = {
        showExtraProductsDropdown: false
    };

    getExtraIngredients = () => this.props.extraOption.items.filter(item => +item.defaultQuantity === 0);

    getDefaultIngredients = () => this.props.extraOption.items.filter(item => +item.defaultQuantity > 0);

    getDefaultIngredientClassName = item => item.quantity
        ? 'extra-options-modal__extra-option-name'
        : 'extra-options-modal__extra-option-name extra-options-modal__extra-option-name--cancelled';

    getExtraProductNameClassName = item => {
        let className = 'extra-product-dropdown-preview__extra-name';
        if (item.quantity > 0) {
            className += ' extra-product-dropdown-preview__extra-name--active';
        }
        return className;
    };

    getPlusCountButtonClassName = (classNameBase, item) => {
        let className = classNameBase;
        if (item.quantity >= maxIngredientCount) {
            className += ` ${classNameBase}--disabled`;
        }
        return className;
    };

    getMinusCountButtonClassName = (classNameBase, item) => {
        let className = classNameBase;
        if (item.quantity <= 0) {
            className += ` ${classNameBase}--disabled`;
        }
        return className;
    };

    getAddExtraIngredient = item => () => this.props.addExtraIngredient(item);

    getRemoveExtraIngredient = item => () => this.props.removeExtraIngredient(item);

    renderExtraItemPreview = item =>
        <div className="extra-product-dropdown-preview" key={item.id}>
            <div className={this.getExtraProductNameClassName(item)}>{item.name}</div>
            <div className="extra-product-dropdown-preview__right">
                <div className="extra-product-dropdown-preview__count-container">
                    <div onClick={this.getRemoveExtraIngredient(item)}
                        className={this.getMinusCountButtonClassName('extra-product-dropdown-preview__count-button', item)}
                    >
                        -
                    </div>
                    <div className="extra-product-dropdown-preview__count">
                        {item.quantity}
                    </div>
                    <div onClick={this.getAddExtraIngredient(item)}
                        className={this.getPlusCountButtonClassName('extra-product-dropdown-preview__count-button', item)}
                    >
                        +
                    </div>
                </div>
                <div className="extra-product-dropdown-preview__price">{formatMoney(item.price.price)} tl</div>
            </div>
        </div>;

    getDefaultIngredientName = item => {
        if (item.quantity) {
            return `${item.quantity} x ${item.name}`;
        }
        return item.name;
    };

    openExtraProductsDropdown = () => {
        this.setState({ showExtraProductsDropdown: true });
    };

    closeExtraProductsDropdown = () => {
        this.setState({ showExtraProductsDropdown: false });
    };

    closeExtraProductsDropdownOnClickOutside = event => {
        if (
            this.extraProductsDropdown && this.extraProductsDropdownContent
            && this.extraProductsDropdown.current && this.extraProductsDropdownContent.current
            && !this.extraProductsDropdown.current.contains(event.target)
            && !this.extraProductsDropdownContent.current.contains(event.target)
        ) {
            this.closeExtraProductsDropdown();
        }
    };

    getDropdownClassName = () => {
        const { showExtraProductsDropdown } = this.state;
        let className = 'extra-options-modal__dropdown';
        if (showExtraProductsDropdown) {
            className += ' extra-options-modal__dropdown--active';
        }
        return className;
    };

    render() {
        const { getCancelExtraIngredient, extraOption, closeModal } = this.props;
        const { showExtraProductsDropdown } = this.state;

        let defaultIngredients = [];
        let extraIngredients = [];
        let extraCheese = null;
        if (extraOption) {
            defaultIngredients = this.getDefaultIngredients();
            extraIngredients = this.getExtraIngredients();
            extraCheese = extraIngredients.find(ingredient => ingredient.name === 'Ekstra Peynir');
        }

        return <div className="modal-content extra-options-modal"
            onClick={this.closeExtraProductsDropdownOnClickOutside}
        >
            <Icon onClick={closeModal} name="cancel" className="extra-options-modal__close-icon" />
            <div>
                {
                    defaultIngredients
                        ? <>
                            <div className="extra-options-modal__header">
                                Malzeme Ekle / Çıkar
                            </div>
                            <div className="extra-options-modal__block-header">
                                Pizzamdaki Malzemeler
                            </div>
                            <div className="extra-options-modal__block-subheader">
                                Çıkarmak istediğiniz malzemeyi seçin.
                            </div>
                            <div className="extra-options-modal__extra-options-body">
                                {
                                    defaultIngredients.map(item =>
                                        <div className="extra-options-modal__extra-option"
                                            key={item.id}
                                        >
                                            <span className={this.getDefaultIngredientClassName(item)}>
                                                {this.getDefaultIngredientName(item)}
                                            </span>
                                            <div onClick={this.getRemoveExtraIngredient(item)}
                                                className={this.getMinusCountButtonClassName('extra-options-modal__count-button', item)}
                                            >
                                                -
                                            </div>
                                            <div onClick={this.getAddExtraIngredient(item)}
                                                className={this.getPlusCountButtonClassName('extra-options-modal__count-button', item)}
                                            >
                                                +
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                        : null
                }
                {
                    extraCheese
                        ? <div className="extra-options-modal__extra-cheese-container">
                            <span className="extra-options-modal__extra-cheese"
                                onClick={this.getAddExtraIngredient(extraCheese)}
                            >
                                Ekstra Peynir Ekle +{formatMoney(extraCheese.price.price)} TL
                            </span>
                        </div>
                        : null
                }
                {
                    !!this.getExtraIngredients().map(this.renderExtraItemPreview).length
                        ? <div className="extra-options-modal__block-header">
                            Ek Malzemeler
                          </div> : null
                }
                {
                    !!this.getExtraIngredients().map(this.renderExtraItemPreview).length
                    ? <div
                    className="extra-options-modal__block-subheader extra-options-modal__block-subheader--small-stack-space">
                    Pizzana Ekstra eklemek istediğin malzemeleri aşağıdaki bölümden seçebilirsin.
                    </div> : null
                }
                
                <div
                    className="extra-options-modal__extra-options-body extra-options-modal__extra-options-body--small-stack-space">
                    {
                        extraIngredients.filter(ingredient => ingredient.quantity).map(item =>
                            <div key={item.id}
                                className="extra-options-modal__extra-option extra-options-modal__extra-option--extra icon-with-label"
                            >
                                {
                                    item.quantity > 1
                                        ? <span>{item.quantity} x</span>
                                        : null
                                }
                                <span>{item.name}</span>
                                <span>+{formatMoney(item.price.price * item.quantity)} TL</span>
                                <Icon name="cancel-circle"
                                    onClick={getCancelExtraIngredient(item)}
                                    className="extra-options-modal__extra-option-cancel-button"
                                />
                            </div>
                        )
                    }
                </div>
                {
                    !!this.getExtraIngredients().map(this.renderExtraItemPreview).length
                        ?
                        (
                            <div className={this.getDropdownClassName()}
                                onClick={this.openExtraProductsDropdown}
                                ref={getRef(this, 'extraProductsDropdown')}
                            >
                                <span>İstediğiniz malzemeyi seçin</span>
                                <Icon name="chevron-right" />
                            </div>
                        )

                        : null
                }

                {
                    showExtraProductsDropdown
                        ? <div className="extra-options-modal__dropdown-content"
                            ref={getRef(this, 'extraProductsDropdownContent')}
                        >
                            {this.getExtraIngredients().map(this.renderExtraItemPreview)}
                        </div>
                        : null
                }

            </div>
            <div className="extra-options-modal__bottom">
                <Button onClick={closeModal}>Uygula</Button>
            </div>
        </div>;
    }
}

ExtraOptionsModalContent.propTypes = {
    extraOption: PropTypes.object.isRequired,
    addExtraIngredient: PropTypes.func.isRequired,
    removeExtraIngredient: PropTypes.func.isRequired,
    getCancelExtraIngredient: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default ExtraOptionsModalContent;
