import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icon from "./Icon";
import { formatErrorMessage, hideMobileMenu, showMobileMenu } from '../../utils';

const smallIcons = ['chevron-up', 'chevron-down', 'chevron-left', 'chevron-right'];

class Input extends Component {
    defaultRef = React.createRef();

    getClassName = () => {
        const { type, firstIconName, lastIconName, error, disabled, label } = this.props;

        let resultClassName = 'input';
        if (type) {
            resultClassName += ` input--type--${type}`;
        }
        if (error) {
            resultClassName += ' input--error';
        }
        if (disabled) {
            resultClassName += ' input--disabled';
        }
        if (label) {
            resultClassName += ' input--with-label';
        }
        if (firstIconName) {
            if (smallIcons.includes(firstIconName)) {
                resultClassName += ' input--with-icon--left-small';
            } else {
                resultClassName += ' input--with-icon--left';
            }
        }
        if (lastIconName) {
            if (smallIcons.includes(lastIconName)) {
                resultClassName += ' input--with-icon--right-small';
            } else {
                resultClassName += ' input--with-icon--right';
            }
        }

        return resultClassName;
    };

    checkValue = value => {
        if (value === null || value === undefined) {
            return '';
        }
        return value;
    };

    onBlur = event => {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur(event);
        }
        showMobileMenu();
    };

    onFocus = event => {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus(event);
        }
        hideMobileMenu();
    };

    render() {
        const {
            type, name, className, onChange, value, placeholder, error, firstIconName, lastIconName, label,
            onLastIconClick, inputComponent, onClick, rootRef
        } = this.props;

        return <div className={this.getClassName()} onClick={onClick} ref={rootRef || this.defaultRef}>
            {
                firstIconName
                    ? <Icon name={firstIconName} className="input__icon input__icon--left" />
                    : null
            }
            {
                inputComponent
                    ? inputComponent
                    : <input type={type}
                             name={name}
                             className={`input__body ${className}`}
                             onChange={onChange}
                             value={this.checkValue(value)}
                             placeholder={placeholder}
                             onFocus={this.onFocus}
                             onBlur={this.onBlur}
                             error={error}
                             ref={rootRef || this.defaultRef}
                    />
            }
            <div className="input__label">{label}</div>
            {
                lastIconName
                    ? <Icon name={lastIconName}
                            onClick={onLastIconClick}
                            className={
                                onLastIconClick
                                    ? 'input__icon input__icon--right input__icon--clickable'
                                    : 'input__icon input__icon--right'
                            }
                    />
                    : null
            }
            {
                error
                    ? <div className="help-text help-text--error">{formatErrorMessage(error)}</div>
                    : null
            }
        </div>;
    }
}

Input.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    firstIconName: PropTypes.string,
    lastIconName: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    error: PropTypes.any,
    disabled: PropTypes.bool,
    onLastIconClick: PropTypes.func,
    onClick: PropTypes.func,
    label: PropTypes.string,
    inputComponent: PropTypes.node,
    rootRef: PropTypes.object
};

export default Input;
