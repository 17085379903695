import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const FranchiseHistory = () => {

    sendPageView('/franchise/tarihce', 'Franchise Tarihçe - Little Caesars');
    return (
        <section className="institutional-structures">
            <div className="container">
                <BreadCrumbs />
                <div className="institutional-structures-detail">
                    <div className="institutional-structures-detail-content clearfix">
                        <FranchisingMenu />
                        <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>Tarihçe</h1>
                        <img class="img-responsive" src="/images/kurumsal/fr_tahrice.jpg" />
                        <div class="history-date clearfix">
                            <span >1959 : </span>
                            <p>
                                Mike and Marian Ilitch, Michigan, Garden City’de, ilk Little Caesars Pizza restoranını açtı. Ilitchlerin yönetiminde, üç uluslararası pizza zincirinden biri olma başarısını gösterdi ve beş
                                kıtada pek çok restoran sahibi oldu. Mike ve Marian Ilitch halen pizza zincirinin işletmesinde birebir yer almaktadır.
                            </p>
                        </div>
                        <div class="history-date clearfix">
                            <span>1962 : </span>
                            <p>
                                İlk franchise hakkını sattı.
                            </p>
                        </div>
                        <div class="history-date clearfix">
                            <span>1969 : </span>
                            <p>
                                Kanada’da ilk uluslararası restoranını açtı ve 65 restorana ulaştı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>1974 : </span>
                            <p>
                                Orta boy pizzalar için “bir alana bir bedava” uygulaması başlattı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>1979 : </span>
                            <p>
                                Pizza!Pizza!® sloganıyla ve her boy pizza için bir alana bir bedava konseptiyle pizza sektörünü yönlendirdi.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>1985 : </span>
                            <p>
                                Love Kitchen çalışmasını başlattı; aç, evsiz ve felaket mağduru kişilere yardım etti. 1995’de 1 milyon, 2005’de 2 milyon kişiyi doyurdu.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>1993 : </span>
                            <p>
                                Çek Cumhuriyeti’nde açılan ilk restoranla kıtalararası bir şirket oldu.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>1996 : </span>
                            <p>
                                Türkiye’de franchise hakkını Çelebi Holding’e verdi ve Türkiye’deki ilk restoranını Mayıs 1997’de Bakırköy’de açtı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>1998 : </span>
                            <p>
                                Mısır-Kahire’deki restoranıyla beşinci kıtayla tanıştı. Aynı yıl bir günde, bir şirketin tam 13.386 çalışanına pizza ulaştırarak en büyük pizza siparişi rekorunu kırdı ve Guinness Rekorlar kitabına girdi.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2001 : </span>
                            <p>
                                Pizzada dondurulmuş değil, taze peynir uygulamasını hayata geçiren ilk ve tek pizza zinciri oldu.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2005 : </span>
                            <p>
                                Her yıl büyüyen satış oranında üst üste beşinci yılına imza attı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2007 : </span>
                            <p>
                                Türkiye’de Çelebi Holding içerisinde Franchise dükkanları açmaya başladı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2010 : </span>
                            <p>
                                LC Türkiye, Dünya’da en iyi operasyon ödülünü aldı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2011 : </span>
                            <p>
                                LC Türkiye, Dünya’da en iyi operasyon ödülünü ikinci kez aldı.
                            </p>
                        </div>
                        <div class="history-date clearfix">
                            <span>2012 : </span>
                            <p>
                                23 ülkede 5.000’i aşan restoran sayısına ulaştı
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2013 : </span>
                            <p>
                                Franchisee restoran sayısı 2 katına çıktı. %21 satış artışı yaptı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2014 : </span>
                            <p>
                                Tüm Şubelerinde günlük taze hamur üretimi için yatırım yaptı. Böylece Anadolu’ya açılma becerisini kazandı. %32 satış artışı yaptı.
                            </p>
                        </div>

                        <div class="history-date clearfix">
                            <span>2015 : </span>
                            <p>
                                İç Anadolu Bölgesi’nde Ankara dışındaki ilk restoranı Eskişehir’de açtı.
                            </p>
                        </div>


                        <div class="history-date clearfix">
                            <span>2016 : </span>
                            <p>
                                Bandırma restoranımız açıldı.
                            </p>
                        </div>
                           <div class="history-date clearfix">
                            <span>2017 : </span>
                            <p>
                                Çanakkale ve Zonguldak restoranlarımız açıldı.
                            </p>
                        </div>
                        <div class="history-date clearfix">
                            <span>2018 : </span>
                            <p>
                                İskenderun restoranımız açıldı.
                            </p>
                        </div>
                        <div class="history-date clearfix">
                            <span>2019 : </span>
                            <p>
                                Samsun Atakum restoranımız açıldı.
                            </p>
                        </div>
                        <div class="history-date clearfix">
                            <span>2020 : </span>
                            <p>
                                Muğla Bodrum ve Adana restoranlarımız açıldı.
                            </p>
                        </div>


                    </div>
                </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FranchiseHistory;