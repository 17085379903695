import React from 'react';

const MenuCrust = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.8209 19.7018C23.1852 20.6026 22.9859 21.5926 22.3015 22.2832L22.2905 22.2942C21.6073 22.9762 20.6234 23.1827 19.7202 22.8319L14.2727 20.7224C14.2715 20.7224 14.2703 20.7212 14.2691 20.7212L9.93628 19.043C9.93139 19.0418 9.9265 19.0394 9.92161 19.0382L2.73861 16.2576C1.44428 15.7553 0.736611 14.368 1.09106 13.0297C1.82561 10.2602 3.26417 7.70204 5.2515 5.62793C7.28528 3.5086 9.85072 1.94782 12.6704 1.11426C14.0271 0.713375 15.4473 1.3966 15.9728 2.7056L22.8209 19.7018Z" stroke="white" strokeWidth="1.32" />
            <path d="M13.2218 8.33301C11.8744 8.33301 10.7773 9.4291 10.7773 10.7775C10.7773 12.1258 11.8744 13.2219 13.2218 13.2219C14.5701 13.2219 15.6662 12.1258 15.6662 10.7775C15.6662 9.4291 14.5701 8.33301 13.2218 8.33301" stroke="white" strokeWidth="1.32" />
            <path d="M12.3409 19.9814C12.8787 19.5414 13.2209 18.857 13.2209 18.1114C13.2209 16.767 12.1209 15.667 10.7765 15.667C9.43203 15.667 8.33203 16.767 8.33203 18.1114C8.33203 18.2214 8.34425 18.3302 8.35648 18.428" stroke="white" strokeWidth="1.32" />
            <path d="M20.2116 13.2461C19.026 13.4184 18.1094 14.4439 18.1094 15.6661C18.1094 17.0105 19.2106 18.1105 20.5538 18.1105C21.0928 18.1105 21.5805 17.9406 21.9838 17.6473" stroke="white" strokeWidth="1.32" />
            <path d="M16.2769 4.05566C16.2769 4.05566 7.09435 4.30133 4.05469 16.2779" stroke="white" strokeWidth="1.32" />
    </svg>;

export default MenuCrust;
