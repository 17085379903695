import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { formatDateTime, formatMoney, formatTownName, formatRestaurantName } from "../../utils";


const OrderDetail = props => {
    const { className, order, restaurant, abstractOrder, children } = props;

return <div className={className}>
        <div className="order-detail">
            <div className="order-detail__label">Sipariş No</div>
            <div className="order-detail__value">{order.orderNumber}</div>
        </div>
        {
            !abstractOrder
                ? <div className="order-detail">
                    <div className="order-detail__label">Sipariş Tarihi</div>
                    <div className="order-detail__value">{formatDateTime(order.orderDate)}</div>
                </div>
                : null
        }
        {
            !abstractOrder
                ? <div className="order-detail">
                    <div className="order-detail__label">Sipariş Durumu</div>
                    <div className="order-detail__value">{order.orderState}</div>
                </div>
                : null
        }
        <div className="order-detail">
            <div className="order-detail__label">Ödeme Tipi</div>
            <div className="order-detail__value">{order.paymentType}</div>
        </div>
        <div className="order-detail">
            <div className="order-detail__label">Tutar</div>
            <div className="order-detail__value">{formatMoney(order.amount)} TL</div>
        </div>
        <div className="order-detail">
            <div className="order-detail__label">Restoran</div>
        <div className="order-detail__value">
            <Link className="order-detail__detail-row-link" 
                to={`/sube/${formatTownName(restaurant)}/${formatRestaurantName(restaurant)}/${order.storeId}`} >
                Haritada Gör
                </Link>
            {order.store}
        </div>
        </div>
        <div className="order-detail">
            <div className="order-detail__label">Gönderim Adresi</div>
            <div className="order-detail__value">{order.address}</div>
        </div>
        {
            !abstractOrder
                ? <div className="order-detail">
                    <div className="order-detail__label">Sipariş Notu</div>
                    <div className="order-detail__value">{order.orderNote !== 'undefined' ? order.orderNote : ''}</div>
                </div>
                : null
        }
        {children}
    </div>
};

OrderDetail.propTypes = {
    abstractOrder: false
};

OrderDetail.propTypes = {
    order: PropTypes.object.isRequired,
    abstractOrder: PropTypes.bool,
    className: PropTypes.string
};

export default OrderDetail;
