import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../elements/Icon';
import Sidebar from '../elements/Sidebar';
import DeliveryButton from '../address-selection/DeliveryButton';
import { formatLink } from '../../utils';
import { addClickEvent, addActionEvent } from '../../googleAnalyticsHelper';

const AppSidebar = props => {
    const { address, deliveryType, menuTop, openDeliveryModal, isAuthorizedUser } = props;

    const getSidebarLinks = () => {
        let links = [];
        // let links = [
        //     <DeliveryButton className="app-header__sidebar-delivery-button"
        //         openDeliveryModal={openDeliveryModal}
        //         address={address}
        //         deliveryType={deliveryType}
        //         keyValue='delivery-button'
        //     />
        // ];
        if (menuTop && menuTop.length) {
            menuTop.map(menuItem => {
                if (menuItem.name !== 'Gel Al' && menuItem.name !== 'Pizzalar') {
                    return links.push(
                        <Link key={menuItem.name} className="sidebar__link link" to={formatLink(menuItem.link)}>
                            {menuItem.name}
                        </Link>
                    );
                }
            });
        }

        const sideBarLinks = [
            <Link className="sidebar__link link" to="/musteri-destegi/harita" key="restoranlar">
                Restoranlar
            </Link>,
            <Link className="sidebar__link link" to="/kurumsal/hakkimizda" onClick={addToClickEvents} key="hakkimizda">
                Kurumsal
            </Link>,
            <Link className="sidebar__link link" to="/franchise/franchise-basvuru-formu" onClick={addToActionEvents}
                  key="franchising">
                Franchising
            </Link>,
            <Link className="sidebar__link link" to="/kurumsal/iletisim" key="iletisim">
                Bize Ulaşın
            </Link>
        ];

        links = [
            ...links,
            ...sideBarLinks
        ];

        return links;
    };

    const addToClickEvents = () => {
        addClickEvent('Hakkinda');
    };

    const addToActionEvents = () => {
        addActionEvent('Basvur', 'Franchise Basvuru Formu');
    };

    const renderSidebarTrigger = () => <Icon name="menu" className="app-header__burger-button"/>;

    return <Sidebar links={getSidebarLinks()} trigger={renderSidebarTrigger()} isAuthorizedUser={isAuthorizedUser}/>;
};

AppSidebar.propTypes = {
    address: PropTypes.string,
    deliveryType: PropTypes.string,
    menuTop: PropTypes.array.isRequired,
    openDeliveryModal: PropTypes.func.isRequired,
    isAuthorizedUser: PropTypes.bool.isRequired
};

export default AppSidebar;
