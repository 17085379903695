import React from 'react';

const Pin = props =>
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
        <rect width="16" height="21"  />
        <rect x="-110" y="-454" width="1366" height="1341"  />
        <path d="M8 1C11.859 1 15 4.315 15 8.391C15 11.134 11.379 15.671 8.011 19.002C4.622 15.671 1 11.134 1 8.391C1 4.315 4.14 1 8 1" stroke="black" strokeWidth="1.5" />
        <path  d="M11 8C11 9.657 9.657 11 8 11C6.343 11 5 9.657 5 8C5 6.344 6.343 5 8 5C9.657 5 11 6.344 11 8Z" stroke="black" strokeWidth="1.5" />
    </svg>
    ;

export default Pin;
