import React from 'react';
import PropTypes from "prop-types";

const ProductSizeLink = ({size, sizeLinkClassName, imageUrl, linkImageClassName, altTitle, onClick }) => {
    return (
        <div key={size.id} className={sizeLinkClassName} onClick={onClick}>
            <img src={imageUrl} alt={altTitle} className={ linkImageClassName } />
            <span>
                {size.name}
            </span>
        </div>
    );
};

ProductSizeLink.propTypes = {
    size: PropTypes.object.isRequired,
    imageUrl: PropTypes.string.isRequired, 
    altTitle: PropTypes.string.isRequired,
    sizeLinkClassName: PropTypes.string.isRequired,
    linkImageClassName: PropTypes.string.isRequired
};

export default ProductSizeLink;