import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { copyOrderAndGetBasket, getOrder } from "../../../store/actions/orderActions";
import { formatMoney } from "../../../utils";
import OrderDetail from "../../order/OrderDetail";
import Icon from "../../elements/Icon";
import Button from "../../elements/Button";

class ProfileFavoritesModal extends Component {
    state = {
        order: {}
    };

    componentDidMount() {
        const { orderId, boundGetOrder } = this.props;
       
        boundGetOrder(orderId).then(response => this.setState({ order: response.data.result[0] }));
    }

    openEditEasyOrderModal = () => {
    };

    submit = () => {
        const { boundRepeatOrder, history, setModal } = this.props;
        const { order } = this.state;

        boundRepeatOrder(
            order.id,
            order.paymentTypeId,
            order.orderNote,
            '',
            order.addressId,
            order.orderTypeId
        )
            .then(() => {
                history.push(`/completed-order/${order.id}`);
                setModal('');
            });
    };

    render() {
        const { order } = this.state;

        if (!order) {
            return null;
        }
        return <div className="modal-content easy-order-modal">
            {
                order.orderItems.map(item =>
                    <div className="easy-order-modal__block" key={item.id}>
                        <div className="easy-order-modal__item-name">{item.product}</div>
                        <div className="easy-order-modal__item-detail">{item.detail}</div>
                        <div className="easy-order-modal__item-price">{formatMoney(item.amount)} TL</div>
                    </div>
                )
            }
            <OrderDetail className="easy-order-modal__block" order={order} abstractOrder>
                {/*<div className="easy-order-modal__edit-button">*/}
                {/*    <span className="icon-with-label add-button link" onClick={this.openEditEasyOrderModal}>*/}
                {/*        <Icon name="edit" />*/}
                {/*        <span>Düzenle</span>*/}
                {/*    </span>*/}
                {/*</div>*/}
            </OrderDetail>
            {/*<div className="easy-order-modal__reward-label">*/}
            {/*    <span>Bu siparişten</span>*/}
            {/*    <span className="easy-order-modal__reward-label-amount"> {formatMoney(3.90)} </span>*/}
            {/*    <span>TL değerinde Pizza Puan kazanacaksınız.</span>*/}
            {/*</div>*/}
            <div className="easy-order-modal__bottom-button">
                <Button color="green" onClick={this.submit}>
                    <span>Siparişi Tamamla {formatMoney(order.amount)} TL</span>
                </Button>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({
    boundGetOrder: bindActionCreators(getOrder, dispatch),
    boundCopyOrderAndGetBasket: bindActionCreators(copyOrderAndGetBasket, dispatch)
});

ProfileFavoritesModal.propTypes = {
    boundGetOrder: PropTypes.func.isRequired,
    boundRepeatOrder: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileFavoritesModal));
