import React from 'react';
import { formatMoney } from '../../../utils';
import PropTypes from 'prop-types';
import Icon from '../../elements/Icon';

const MyFavoriteProduct = ({ product, onAddToBasketClick, onToggleDeleteClick, iconName }) => {
   
   return <div className="product-preview profile-favorite-products__item">
        <div className="product-preview__container">
            <div className="product-preview__block-container">
                <div className="product-preview__image">
                    <img src={product.image} alt={`${product.name} .png`} />
                </div>
                <div className="product-preview__block">
                    <div className="product-preview__info">
                        <div className="product-preview__price">
                            {formatMoney(product.price.price)} TL
                    </div>
                        <div className="product-preview__name">
                            {product.name}
                        </div>
                        <div className="product-preview__detail">
                            {product.detail}
                        </div>
                    </div>
                    <div className="product-preview__buttons-container product-preview__block">
                        <div className="button button--light link product-preview__button"
                            onClick={onAddToBasketClick} >
                            <span className="button__text">sepete ekle</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="product-preview__icon">
            <div className="icon-background" onClick={onToggleDeleteClick}>
                <Icon name={iconName} />
            </div>
        </div>
    </div>
}

MyFavoriteProduct.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        detail: PropTypes.string,
        image: PropTypes.string,
        price: PropTypes.shape({
            price: PropTypes.number
        })
    }).isRequired,
    iconName: PropTypes.string.isRequired,
    onAddToBasketClick: PropTypes.func.isRequired,
    onToggleDeleteClick: PropTypes.func.isRequired 
}

export default MyFavoriteProduct;