import React from 'react';

const Crust = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M16.0105 12.193C15.9211 12.1462 15.8131 12.1531 15.7303 12.2106L14.3689 13.1576C14.2961 13.2082 14.2527 13.2914 14.2527 13.3801V17.4449C14.2527 17.9698 14.6797 18.3966 15.2044 18.3966C15.7291 18.3966 16.1561 17.9698 16.1561 17.4449V12.4331C16.1561 12.3323 16.0999 12.2396 16.0105 12.193Z" fill="#FFEBA1"/>
        <path d="M12.9866 14.2965C12.8971 14.2497 12.7892 14.2566 12.7063 14.3141L11.345 15.2611C11.2721 15.3118 11.2288 15.395 11.2288 15.4837V16.8702C11.2288 17.3949 11.6556 17.8219 12.1804 17.8219C12.7051 17.8219 13.1321 17.3949 13.1321 16.8702V14.5366C13.1321 14.4357 13.076 14.3432 12.9866 14.2965Z" fill="#FFEBA1"/>
        <path d="M7.93314 17.8111C7.8436 17.7644 7.73572 17.7712 7.65281 17.8288L6.29149 18.7757C6.21872 18.8265 6.17529 18.9095 6.17529 18.9982V20.5302C6.17529 21.0549 6.60213 21.4819 7.12698 21.4819C7.65168 21.4819 8.07866 21.0549 8.07866 20.5302V18.0513C8.07866 17.9504 8.02254 17.8579 7.93314 17.8111Z" fill="#FFEBA1"/>
        <path d="M16.0104 12.192C15.921 12.1452 15.813 12.1521 15.7303 12.2096L15.2043 12.5755V17.444C15.2043 17.7955 15.0127 18.1029 14.7285 18.2676C14.8685 18.349 15.031 18.3956 15.2043 18.3956C15.729 18.3956 16.156 17.9688 16.156 17.444V12.4321C16.156 12.3313 16.0998 12.2387 16.0104 12.192Z" fill="#F7D36F"/>
        <path d="M12.9867 14.2965C12.8971 14.2497 12.7892 14.2566 12.7063 14.3141L12.1805 14.68V16.8702C12.1805 17.2218 11.9887 17.529 11.7046 17.6939C11.8448 17.7751 12.0072 17.8219 12.1805 17.8219C12.7052 17.8219 13.1322 17.3951 13.1322 16.8702V14.5366C13.1322 14.4357 13.0761 14.3432 12.9867 14.2965Z" fill="#F7D36F"/>
        <path d="M7.9332 17.812C7.84365 17.7654 7.73578 17.7721 7.65287 17.8298L7.12704 18.1956V20.5312C7.12704 20.8826 6.93526 21.19 6.65112 21.3548C6.79129 21.436 6.95373 21.4827 7.12704 21.4827C7.65174 21.4827 8.07872 21.0559 8.07872 20.5312V18.0522C8.07872 17.9514 8.0226 17.8589 7.9332 17.812Z" fill="#F7D36F"/>
        <path d="M17.4178 11.3673C17.3822 11.3161 17.3554 11.2606 17.3385 11.2026C17.3363 11.1958 17.1305 10.5093 16.4188 9.53247C15.7453 8.60829 14.4872 7.26741 12.3183 6.12832C10.1493 4.98923 8.33136 4.71454 7.18819 4.68479C5.98071 4.65292 5.29807 4.87332 5.2913 4.87558C5.23448 4.89419 5.17455 4.90364 5.11293 4.90364C5.11166 4.90364 5.11025 4.90364 5.10926 4.90364C5.03777 4.90364 4.96909 4.93184 4.91847 4.98218C4.86728 5.03281 4.83852 5.10162 4.83824 5.17354L4.77661 19.971C4.77619 20.0721 4.83203 20.165 4.92157 20.2121C4.9612 20.2328 5.00449 20.2431 5.0475 20.2431C5.10193 20.2431 5.15607 20.2268 5.20233 20.1946L17.35 11.7445C17.4728 11.6591 17.5033 11.4901 17.4178 11.3673Z" fill="#FFDB57"/>
        <path d="M4.92157 20.2115C4.9612 20.2324 5.00435 20.2426 5.0475 20.2426C5.10193 20.2426 5.15607 20.2262 5.20233 20.1941L17.35 11.744C17.4728 11.6585 17.5032 11.4896 17.4177 11.3668C17.3822 11.3156 17.3554 11.26 17.3383 11.2021C17.337 11.1975 17.2416 10.8801 16.9589 10.3721L4.78141 18.8151L4.77661 19.9706C4.77619 20.0715 4.83203 20.1646 4.92157 20.2115Z" fill="#F6C134"/>
        <path d="M9.44448 8.77051C8.50732 8.77051 7.74487 9.53296 7.74487 10.47C7.74487 11.4071 8.50732 12.1694 9.44448 12.1694C10.3816 12.1694 11.1439 11.4071 11.1439 10.47C11.1439 9.53281 10.3816 8.77051 9.44448 8.77051Z" fill="#F2433B"/>
        <path d="M9.44448 10.9755C8.71743 10.9755 8.09571 10.5163 7.85345 9.87305C7.78351 10.0589 7.74487 10.26 7.74487 10.4701C7.74487 11.4072 8.50732 12.1696 9.44434 12.1696C10.3815 12.1696 11.1439 11.4072 11.1439 10.4701C11.1439 10.2601 11.1053 10.0589 11.0354 9.87305C10.7931 10.5163 10.1715 10.9755 9.44448 10.9755Z" fill="#D83636"/>
        <path d="M6.26346 11.5463C5.9521 11.2278 5.53556 11.0462 5.09053 11.0352C5.01791 11.0342 4.94726 11.061 4.89494 11.1116C4.84277 11.1624 4.81316 11.232 4.81287 11.3049L4.80103 14.1604C4.80061 14.2333 4.82966 14.3031 4.88155 14.3543C4.93231 14.4045 5.0007 14.4326 5.07191 14.4326H5.07642C5.99779 14.4172 6.74727 13.655 6.74727 12.7337C6.74727 12.2872 6.57537 11.8655 6.26346 11.5463Z" fill="#F2433B"/>
        <path d="M5.07656 13.2218C5.07501 13.2219 5.0736 13.2219 5.07205 13.2219C5.00084 13.2219 4.93245 13.1939 4.88154 13.1437C4.8422 13.1047 4.81597 13.055 4.80582 13.0014L4.80103 14.1608C4.80075 14.2337 4.82979 14.3035 4.88154 14.3547C4.93231 14.4049 5.0007 14.4329 5.07205 14.4329H5.07656C5.99779 14.4175 6.74726 13.6554 6.74726 12.734C6.74726 12.5229 6.70849 12.3176 6.63502 12.126C6.39318 12.7552 5.78599 13.2101 5.07656 13.2218Z" fill="#D83636"/>
        <path d="M13.3989 11.5225C12.4618 11.5225 11.6995 12.2849 11.6995 13.2219C11.6995 13.9118 12.1114 14.5281 12.7487 14.7924C12.7821 14.8062 12.8174 14.813 12.8525 14.813C12.9071 14.813 12.9611 14.7966 13.0072 14.7644L14.9809 13.3916C15.0565 13.3388 15.1004 13.2514 15.097 13.1592C15.0636 12.2413 14.3176 11.5225 13.3989 11.5225V11.5225Z" fill="#F2433B"/>
        <path d="M15.6273 8.58482C15.0968 8.02458 14.4832 7.50044 13.8031 7.02707C13.7379 6.9818 13.6565 6.96742 13.58 6.98716C12.8318 7.18176 12.3093 7.85819 12.3093 8.63192C12.3093 9.56908 13.0718 10.3315 14.0088 10.3315C14.8886 10.3315 15.6158 9.67187 15.7002 8.79718C15.7077 8.71906 15.6812 8.64165 15.6273 8.58482Z" fill="#F2433B"/>
        <path d="M15.4049 8.35683C15.0975 8.79932 14.5861 9.08317 14.0088 9.08317C13.2916 9.08317 12.6772 8.63645 12.4281 8.00684C12.3512 8.20143 12.3093 8.41253 12.3093 8.63137C12.3093 9.56853 13.0718 10.3308 14.0088 10.3308C14.8886 10.3308 15.6158 9.67133 15.7002 8.7965C15.7077 8.71852 15.6812 8.6411 15.6273 8.58414C15.5548 8.50771 15.4805 8.43198 15.4049 8.35683Z" fill="#D83636"/>
        <path d="M9.87047 5.26232C9.84537 5.16982 9.77345 5.09762 9.68109 5.07196C8.82671 4.83562 7.98811 4.70519 7.1883 4.68432C7.13076 4.68277 7.07478 4.68206 7.01979 4.68164C7.01908 4.68164 7.01838 4.68164 7.01767 4.68164C6.92432 4.68164 6.83732 4.72973 6.78783 4.80911C6.6196 5.07859 6.53076 5.38938 6.53076 5.70764C6.53076 6.6448 7.29321 7.4071 8.23023 7.4071C9.16739 7.4071 9.92984 6.6448 9.92984 5.70764C9.92984 5.55746 9.90981 5.40771 9.87047 5.26232Z" fill="#F2433B"/>
        <path d="M9.79193 5.13459C9.53148 5.73952 8.92964 6.16425 8.23023 6.16425C7.51234 6.16425 6.89739 5.71654 6.64879 5.08594C6.57123 5.28307 6.53076 5.49374 6.53076 5.70808C6.53076 6.64524 7.29321 7.40769 8.23023 7.40769C9.16739 7.40769 9.9297 6.64524 9.9297 5.70808C9.9297 5.55804 9.90981 5.40815 9.87033 5.26277C9.85679 5.21257 9.82901 5.16857 9.79193 5.13459Z" fill="#D83636"/>
        <path d="M10.5806 16.1928C10.3167 15.5548 9.70016 15.1426 9.00991 15.1426C8.07275 15.1426 7.3103 15.9049 7.3103 16.842C7.3103 17.3134 7.50899 17.7679 7.85517 18.0887C7.90692 18.1365 7.97291 18.1609 8.03947 18.1609C8.09334 18.1609 8.14735 18.145 8.19416 18.1124L10.485 16.5188C10.5895 16.4462 10.6293 16.3106 10.5806 16.1928Z" fill="#F2433B"/>
        <path d="M18.9544 10.7275C18.9116 10.5819 17.8529 7.13388 13.1048 4.63882C13.103 4.63784 13.1013 4.63685 13.0994 4.636C8.34948 2.14236 4.90937 3.22815 4.76526 3.27553C4.18232 3.4673 3.86406 4.09776 4.05583 4.6807C4.14876 4.96315 4.34603 5.19243 4.61142 5.32639C4.8768 5.4605 5.17856 5.48306 5.45777 5.39112C5.46397 5.38914 6.09373 5.19822 7.1743 5.22628C8.25994 5.25462 9.98987 5.51732 12.0665 6.6079C14.1432 7.69848 15.3414 8.97365 15.9809 9.8513C16.6174 10.7249 16.8175 11.3519 16.8185 11.3549C16.9024 11.6402 17.0922 11.8757 17.3532 12.0181C17.5192 12.1088 17.7011 12.1547 17.8849 12.1547C17.9903 12.1547 18.0963 12.1396 18.2002 12.109C18.4855 12.0253 18.721 11.8353 18.8636 11.5743C19.0058 11.3134 19.0381 11.0127 18.9544 10.7275Z" fill="#FF9F34"/>
        <path d="M18.3056 11.2774C18.3044 11.2738 18.0658 10.5266 17.3075 9.48562C16.5453 8.43974 15.1174 6.9202 12.6428 5.6205C10.1681 4.32094 8.10665 4.0079 6.81301 3.97405C5.52516 3.94063 4.77484 4.16823 4.76736 4.17062C4.51989 4.25199 4.25761 4.25791 4.00943 4.1898C3.98842 4.34999 4.00195 4.51723 4.05582 4.68066C4.14875 4.96311 4.34602 5.19239 4.6114 5.32635C4.87679 5.46045 5.17855 5.48301 5.45775 5.39107C5.46396 5.3891 6.09371 5.19817 7.17428 5.22623C8.25993 5.25458 9.98985 5.51728 12.0665 6.60786C14.1432 7.69844 15.3414 8.9736 15.9809 9.85126C16.6174 10.7248 16.8175 11.3519 16.8185 11.3549C16.9024 11.6401 17.0922 11.8756 17.3532 12.018C17.5191 12.1087 17.701 12.1547 17.8849 12.1547C17.9903 12.1547 18.0963 12.1396 18.2002 12.109C18.3711 12.0588 18.5241 11.9702 18.6501 11.8512C18.4894 11.6946 18.3706 11.4987 18.3056 11.2774Z" fill="#FB8F2D"/>
        <path d="M12.7487 14.793C12.7821 14.8068 12.8174 14.8136 12.8525 14.8136C12.907 14.8136 12.9611 14.7973 13.0072 14.7651L14.9809 13.3922C15.0565 13.3395 15.1003 13.2521 15.0969 13.1598C15.0805 12.7083 14.8914 12.3052 14.5942 12.0117L11.8469 13.9166C12.0204 14.3054 12.3375 14.6225 12.7487 14.793Z" fill="#D83636"/>
        <path d="M7.85555 18.0889C7.90716 18.1367 7.97329 18.1611 8.03971 18.1611C8.09358 18.1611 8.14772 18.1452 8.1944 18.1126L10.4853 16.519C10.5898 16.4464 10.6295 16.3106 10.5809 16.193C10.4246 15.8152 10.1447 15.5167 9.79911 15.3359L7.32422 17.0519C7.37371 17.4463 7.56098 17.816 7.85555 18.0889Z" fill="#D83636"/>
    </svg>;

export default Crust;
