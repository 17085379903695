import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Icon from '../elements/Icon';
import Sidebar from '../elements/Sidebar';
import { logOut } from '../../utils';
import LoyaltyPreview from '../loyalty/LoyaltyPreview';
import LoyaltyPreviewButton from '../loyalty/LoyaltyPreviewButton';

const ProfileSidebar = props => {
    const { setAddressSelection, user } = props;

    const exit = () => {
        setAddressSelection({});
        logOut();
    };

    const getLinkClassName = url => props.location.pathname === url
        ? 'icon-with-label link profile-sidebar-link__body link--active'
        : 'icon-with-label link profile-sidebar-link__body';

    const getSidebarLinks = () => {
        let links = [
            <Link key="sezarla-kazan" to="/sezarla-kazan" className={getLinkClassName('/sezarla-lazan')}>
                <Icon className="profile-sidebar-link__icon" name="loyalty-sidebar-icon"/>
                <div>Sezarla Kazan</div>
            </Link>,
            <Link key="hesap" to="/profil/hesap" className={getLinkClassName('/profil/hesap')}>
                <Icon className="profile-sidebar-link__icon" name="user"/>
                <div>Profilim</div>
            </Link>,
            <Link key="favoriler" to="/profil/favoriler" className={getLinkClassName('/profil/favoriler')}>
                <Icon className="profile-sidebar-link__icon" name="favorites"/>
                <span>Favori Ürünlerim</span>
            </Link>,
            <Link key="siparisler" to="/profil/siparisler" className={getLinkClassName('/profil/siparisler')}>
                <Icon className="profile-sidebar-link__icon" name="orders"/>
                <span>Siparişlerim</span>
            </Link>,
            <Link key="siparis-takip" to="/profil/siparis-takip" className={getLinkClassName('/profil/siparis-takip')}>
                <Icon className="profile-sidebar-link__icon" name="order-tracking"/>
                <span>Sipariş Takip</span>
            </Link>,
            <Link key="adresler" to="/profil/adresler" className={getLinkClassName('/profil/adresler')}>
                <Icon className="profile-sidebar-link__icon" name="pin"/>
                <span>Adreslerim</span>
            </Link>,
            <Link key="kayıtlı-kartlarım"
                  to="/profil/kayıtlı-kartlarım"
                  className={getLinkClassName('/profil/kayıtlı-kartlarım')}
            >
                <Icon className="profile-sidebar-link__icon" name="credit-card"/>
                <span>Kayıtlı Kartlarım</span>
            </Link>,
            <div key="exit" onClick={exit} className={getLinkClassName()}>
                <Icon className="profile-sidebar-link__icon" name="exit"/>
                <span>Çıkış</span>
            </div>
        ];
        if (user.loyalty && user.loyalty.punchCard) {
            links = [<LoyaltyPreview key="loyalty-preview"/>, <LoyaltyPreviewButton key="loyalty-preview-button"/>, ...links];
        }

        return links;
    };

    const renderSidebarTrigger = () => {
        return <div className="icon-with-label-vertical app-header__icon-button">
            <Icon name="user"/>
            <div className="app-header__icon-button-text">Hesabım</div>
        </div>;
    };

    return <Sidebar links={getSidebarLinks()} trigger={renderSidebarTrigger()} rightSide>
        <div key="user-preview" className="user-preview">
            {/*<img src="/images/placeholder-image.png" alt="" className="user-preview__image" />*/}
            <div className="user-preview__right">
                <div className="user-preview__name">
                    <span className="user-preview__hello-text">Merhaba </span>{user.name}
                </div>
            </div>
        </div>
    </Sidebar>;
};

ProfileSidebar.propTypes = {
    user: PropTypes.object.isRequired,
    setAddressSelection: PropTypes.func.isRequired
};

export default withRouter(ProfileSidebar);
