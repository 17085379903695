import React from 'react';

const Checked = props => 
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" fill="#383838"/>
<rect width="1366" height="1421" transform="translate(-567 -399)" fill="#F8F8F8"/>
<path d="M-168.5 -261C-168.5 -265.142 -165.142 -268.5 -161 -268.5H393C397.142 -268.5 400.5 -265.142 400.5 -261V555C400.5 559.142 397.142 562.5 393 562.5H-161C-165.142 562.5 -168.5 559.142 -168.5 555V-261Z" fill="white" stroke="#E5E5E5"/>
<rect x="-138.5" y="-13.5" width="509" height="51" rx="3.5" fill="#F8F8F8" stroke="#E5E5E5"/>
<rect x="-81" y="11" width="393" height="2" fill="url(#paint0_linear)"/>
<path d="M12 0C5.38312 0 0 5.38312 0 12C0 18.6169 5.38312 24 12 24C18.6169 24 24 18.6169 24 12C24 5.38312 18.6169 0 12 0Z" fill="white"/>
<path d="M12 0C5.38312 0 0 5.38312 0 12C0 18.6169 5.38312 24 12 24C18.6169 24 24 18.6169 24 12C24 5.38312 18.6169 0 12 0ZM12 22.0541C6.45633 22.0541 1.94592 17.544 1.94592 12C1.94592 6.45633 6.45628 1.94592 12 1.94592C17.544 1.94592 22.0541 6.45628 22.0541 12C22.0541 17.544 17.544 22.0541 12 22.0541Z" fill={props.color}/>
<path d="M17.9929 8.30348C17.6235 7.91394 17.0076 7.89679 16.6175 8.26715L10.406 14.1579L7.44336 11.116C7.06812 10.731 6.45289 10.7226 6.06758 11.0979C5.68259 11.4728 5.67448 12.0887 6.04939 12.4737L9.68182 16.2031C9.87218 16.3986 10.1252 16.4972 10.3788 16.4972C10.6195 16.4972 10.8601 16.4083 11.0486 16.2303L17.9567 9.67897C18.3465 9.30922 18.363 8.69333 17.9929 8.30348Z" fill={props.color}/>
<defs>
<linearGradient id="paint0_linear" x1="-107.554" y1="13.0019" x2="324.489" y2="10.8381" gradientUnits="userSpaceOnUse">
<stop stopColor="#F37021"/>
<stop offset="0.291212" stopColor="#F68427"/>
<stop offset="0.522084" stopColor="#FDBC16"/>
<stop offset="0.762994" stopColor="#ABC438"/>
<stop offset="1" stopColor="#5BCC59"/>
</linearGradient>
</defs>
</svg>



export default Checked;