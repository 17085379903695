import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class SecurityIframeModal extends Component {

    render() {
        const { masterpassResponse: url } = this.props;

        return (
            <div className="modal-content">
                <iframe className="credircard-iframe" src={url}
                        width="100%"
                        height="500"
                        id="iframe"
                        style={{ border: 'none' }}
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    masterpassResponse: store.member.masterpassResponse,
});

const mapDispatchToProps = dispatch => ({});

SecurityIframeModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    masterpassResponse: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityIframeModal);