import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbs';
import CorporateMenu from '../../corporate/corporate-menu/CorporateMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const OurSuppliers = () => {
    sendPageView('/kurumsal/tedarikcilerimiz', 'Tedarikcilerimiz - Little Caesars'); // for ga

    return <section class="institutional-structures">
        <div class="container">
        <BreadCrumbs />
            <div class="institutional-structures-detail">
                <div class="institutional-structures-detail-content clearfix">
                    <CorporateMenu />
                    <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>Tedarikçilerimiz</h1>
                        <p>Little Caesars Pizza, her zaman taze ve kaliteli ürünleri ile müşterilerine hizmet verme ilkesi doğrultusunda hareket etmektedir ve üretim konusunda piyasada lider firmalarla iş ortaklığı yapmaktadır.</p>
                        <h3>Üretici Firmalar</h3>
                        <div class="row manufacturer">
                            <div class="col-md-4 col-sm-6 text-center">
                                <img src="/images/kurumsal/tedarik/lezita.jpg"/>
                                <h5>Lezita</h5>
                                <span></span>
                            </div>
                            <div class="col-md-4 col-sm-6 text-center">
                                <img src="/images/kurumsal/tedarik/namet.jpg"/>
                                <h5>Namet</h5>
                                <span></span>
                            </div>
                            <div class="col-md-4 col-sm-6 text-center">
                                <img src="/images/kurumsal/tedarik/oguz-ambalaj.jpg"/>
                                <h5>Oğuz Ambalaj</h5>
                                <span></span>
                            </div>
                            <div class="col-md-4 col-sm-6 text-center">
                                <img src="/images/kurumsal/tedarik/pacovis.jpg"/>
                                <h5>Pacovis Türkiye</h5>
                                <span></span>
                            </div>
                            <div class="col-md-4 col-sm-6 text-center">
                                <img src="/images/kurumsal/tedarik/rella.jpg"/>
                                <h5>Rella</h5>
                                <span></span>
                            </div>
                            <div class="col-md-4 col-sm-6 text-center">
                                <img src="/images/kurumsal/tedarik/toru-un.jpg"/>
                                <h5>Torun Un</h5>
                                <span></span>
                            </div>
                        </div>
                        <div class="row">
                            <h3>Sertifikalar</h3>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-TSE-979_1_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-TSE-979_1.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-TSE-669_1_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-TSE-669_1.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-TSE-SUCUK_1_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-TSE-SUCUK_1.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-TSE-667_1_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-TSE-667_1.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-TSE-980_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-TSE-980.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/ADN-helal-belgesi-2020_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/ADN-helal-belgesi-2020.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/kosher_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/kosher.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/antgida_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/antgida.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/kascert_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/kascert.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-IFS_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-IFS.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/namet_brc_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/namet_brc.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/kascert_ek_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/kascert_ek.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/tse_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/tse.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-HELALGIDA_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-HELALGIDA.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-ISO-9001_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-ISO-9001.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/NAMET-FSS_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/NAMET-FSS.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/helal_1_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/helal_1.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/kaanlar_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/kaanlar.jpg"/>
                                </a>
                            </div>
                            <div class="col-sm-3 col-xs-6">
                                <a class="certificate" target="_blank" href="/images/kurumsal/sertifikalar/big/helal_2_b.jpg">
                                    <img class="img-responsive" src="/images/kurumsal/sertifikalar/helal_2.jpg"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
};

export default OurSuppliers;