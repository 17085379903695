export const dateList = [
    { value: '1', label: 'Bugün' },
    { value: '2', label: 'Yarın' }
]

export const hourList = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' }
]

export const minuteList = [
    { value: '00', label: '00' },
    { value: '15', label: '15' },
    { value: '30' , label: '30' },
    { value: '45', label: '45' }
]