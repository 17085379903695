import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getValidator from "../../getValidator";
import Icon from "../elements/Icon";
import Button from "../elements/Button";
import {formatDateTime, formatMoney, formatTownName, formatRestaurantName, orderStateIds, 
       DELIVERY_ORDER_TYPE, TAKE_AWAY_ORDER_TYPE, VEHICLE_DELIVERY_ORDER_TYPE} from "../../utils";
import { getOrder } from "../../store/actions/orderActions";
import { addFavoriteOrder } from "../../store/actions/memberActions";
import { getRestaurantDetails } from '../../store/actions/addressActions';
import { setModal } from "../../store/actions/modalActions";
import { addActionEvent, sendPageView } from "../../googleAnalyticsHelper";

class CompletedOrderManager extends Component {
    validator = getValidator(this);

    state = {
        password: '',
        order: null,
        restaurant: '',
        addedToFavorite: false
    };

    componentDidMount() {
        const { match, location, boundGetOrder, boundGetRestaurantDetails } = this.props;

        boundGetOrder(match.params.id).then(response => this.setState({ order: response.data.result[0] }, 
            () => boundGetRestaurantDetails(this.state.order.storeId).then(response =>
                    this.setState({ restaurant: response.data.result}))
            ));

        sendPageView(location.pathname, 'Sipariş Özeti - Little Caesars');
    }

    getGoToProduct = product => () => {
        const { history } = this.props;
        history.push(product.link);
    };

    goToOrderListPage = () => {
        const { history } = this.props;
        history.push('/profil/siparisler');
    };

    goToOrderTracking = () => {
        const { boundSetModal, location, history } = this.props;
        const splittedUrl = location.pathname.split('/');
        const orderId = splittedUrl[splittedUrl.length - 1];
        history.push('/profil/siparis-takip');
    };

    addToFavourite = () => {
        const { boundAddFavoriteOrder } = this.props;
        const { order } = this.state;

        boundAddFavoriteOrder(order.id).then(() => {
            addActionEvent('Favorilere Ekle', order.orderItems[0].product);
            this.setState({ addedToFavorite: true });
        });
    };

    getDeliveryTimeText = () => {
        const { order } = this.state;
       
        if (order && +order.orderTypeId === DELIVERY_ORDER_TYPE && orderStateIds.orderTaken.includes(+order.orderStateId)) { 
            return 'Siparişiniz 30 dakika sonra kapında.';
        }
        else if (order && +order.orderTypeId === TAKE_AWAY_ORDER_TYPE && orderStateIds.orderTaken.includes(+order.orderStateId)){
            return 'Siparişiniz 20 dakikaya hazır olacaktır.';
        }
        else if (order && +order.orderTypeId === VEHICLE_DELIVERY_ORDER_TYPE && orderStateIds.orderTaken.includes(+order.orderStateId)){
            return 'Siparişinizin durumunu görmek ve restorana geldiğinizi belirtmek için sipariş takip sayfasına gidiniz.';
        }
        else if (order && +orderStateIds.laterDeliveryDate.includes(+order.orderStateId)){
            return 'Siparişiniz seçilen tarih ve saatte hazır olacaktır.';
        }

        return null;
    };

 
    render() {
        const { match, loading, user } = this.props;
        const { order, restaurant, addedToFavorite } = this.state;

        if (!order && loading) {
            return null;
        }

        if (!order) {
            return <div className="completed-order">
                <div className="completed-order__body">
                    <div className="completed-order__block completed-order__header">
                        <Icon name="info" className="completed-order__header-icon completed-order__header-icon--error" />
                        <div className="completed-order__header-text">No such order {match.params.id}</div>
                    </div>
                </div>
            </div>;
        }

        return <div className="completed-order">

            <div className="completed-order__header">
                <div className="completed-order__header-left">
                    <div className="completed-order__header-main-text icon-with-label">
                        <Icon className="icon--black completed-order__header-icon" name="check-filled-round" />
                        <span>
                            Teşekkürler, {user.name}.
                        </span>
                    </div>
                    <div className="completed-order__header-delivery-time">
                        {this.getDeliveryTimeText()}
                    </div>
                    <div className="completed-order__header-text">
                        Siparişin alındı. Hazırlık aşamalarını ve teslimat sürecini, sipariş takip sayfasından yapabilirsin.
                    </div>
                </div>
                <div className="completed-order__header-right">
                    <Button color="white" onClick={this.goToOrderListPage} className="completed-order__header-button">
                        Siparişlerim
                    </Button>
                    <Button color="orange" onClick={this.goToOrderTracking} className="completed-order__header-button">
                        Sipariş Takip
                    </Button>
                </div>
            </div>

            <div className="completed-order__subheader">
                Sipariş Detayı
            </div>

            <div className="completed-order__block">
                <div className="completed-order__block-header">
                    Sipariş Bilgileri
                </div>
                <div className="completed-order__detail-columns-container">

                    <div className="completed-order__detail-column">
                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Sipariş No:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {order.orderNumber}
                            </div>
                        </div>

                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Sipariş Tarihi:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {formatDateTime(order.orderDate)}
                            </div>
                        </div>

                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Sipariş Durumu:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {order.orderState}
                            </div>
                        </div>

                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Ödeme Tipi:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {order.paymentType}
                            </div>
                        </div>
                    </div>

                    <div className="completed-order__detail-column">
                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Tutar:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {formatMoney(order.amount)} TL
                            </div>
                        </div>

                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Restoran:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {
                                    restaurant ?
                                        <Link className="completed-order__detail-row-link" 
                                              to={`/sube/${formatTownName(restaurant)}/${formatRestaurantName(restaurant)}/${order.storeId}`} >
                                            Haritada Gör
                                  </Link>
                                        :
                                        null
                                }
                                {
                                    order.store
                                        ? <span>{order.store}</span>
                                        : null
                                }
                            </div>
                        </div>

                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Gönderim Adresi:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {order.address}
                            </div>
                        </div>

                        <div className="completed-order__detail-row">
                            <div className="completed-order__detail-row-label">
                                Sipariş Notu:
                            </div>
                            <div className="completed-order__detail-row-value">
                                {order.orderNote !== 'undefined' ? order.orderNote : ''}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="completed-order__block completed-order__block--bottom-radius">
                <div className="completed-order__block-header">
                    <span>Siparişlerim</span>
                    <div className="completed-order__table-row-right">
                        <div className="completed-order__table-small-cell">
                            FİYAT
                        </div>
                        <div className="completed-order__table-small-cell">
                            ADET
                        </div>
                        <div className="completed-order__table-small-cell">
                            TUTAR
                        </div>
                    </div>
                </div>
                <div>
                    {
                        order.orderItems.map(product =>
                            <div key={product.id} className="completed-order__table-row">
                                <div className="completed-order__table-row-left">
                                    <div className="completed-order__product-name link" onClick={this.getGoToProduct(product)}>
                                        {product.product}
                                    </div>
                                    <div className="completed-order__product-detail">
                                        {product.detail}
                                    </div>
                                </div>
                                <div className="completed-order__table-row-right">
                                    <div className="completed-order__product-price completed-order__table-small-cell">
                                        {product.productPrice} TL
                                    </div>
                                    <div className="completed-order__product-amount completed-order__table-small-cell">
                                        {product.quantity}
                                    </div>
                                    <div className="completed-order__product-total-price completed-order__table-small-cell">
                                        {formatMoney(product.amount)} TL
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="completed-order__footer">
                    {
                        addedToFavorite || order.favoriteId
                            ? <div />
                            : <div className="button button--white completed-order__footer-button" onClick={this.addToFavourite}>
                                Favorilerime Ekle  +
                            </div>
                    }
                    <div className="completed-order__footer-right">
                        <div className="completed-order__footer-price-header">Toplam Tutar</div>
                        <div className="completed-order__footer-price">{formatMoney(order.amount)} TL</div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({
    boundGetOrder: bindActionCreators(getOrder, dispatch),
    boundGetRestaurantDetails: bindActionCreators(getRestaurantDetails, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundAddFavoriteOrder: bindActionCreators(addFavoriteOrder, dispatch)
});

CompletedOrderManager.propTypes = {
    user: PropTypes.object.isRequired,
    boundGetOrder: PropTypes.func.isRequired,
    boundGetRestaurantDetails: PropTypes.func.isRequired,
    boundAddFavoriteOrder: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompletedOrderManager));
