import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../utils';

const Promotion = ({ promotion, key1 }) => {
    return <div className="promotion-tag" key={promotion.id}>
        <span>{promotion.promotion.name}</span>
        <span> {formatMoney(promotion.amount)} TL</span>
    </div>;
};

Promotion.propTypes = {
    promotion: PropTypes.shape({
        promotion: PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    }).isRequired
};

export default Promotion;
