import React from 'react';
import PropTypes from 'prop-types';
import { getRestaurantWorkingTimes } from '../../utils';

const Restaurant = ({ restaurant, onSelectRestaurant }) => {
    return <div className="restaurant-preview" onClick={onSelectRestaurant}>
        <div className="restaurant-preview__left">
            <div className="restaurant-preview__name">
                {restaurant.name}
            </div>
            <div className="restaurant-preview__address">
                {restaurant.address}
            </div>
        </div>

        <div className="restaurant-preview__right">
            <div className="restaurant-preview__time">
                <span className="restaurant-preview__label"> AÇIK</span>
                {getRestaurantWorkingTimes(restaurant)}
            </div>
            <div className="restaurant-preview__button">
                <div className="button-text"><span>Seç</span></div>
            </div>
        </div>
    </div>;
};

Restaurant.propTypes = {
    restaurant: PropTypes.shape({
        name: PropTypes.string.isRequired,
        address: PropTypes.string
    }).isRequired,
    onSelectRestaurant: PropTypes.func.isRequired
};

export default Restaurant;