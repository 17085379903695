import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProductDetails } from '../../store/actions/productActions';
import { setModal } from '../../store/actions/modalActions';
import Checkbox from '../elements/Checkbox';
import Button from '../elements/Button';
import {
    appHeaderHeight, getGiftProductsAmount, getRef, getUserPermissionValue, isGiftAddedInBasket, shouldShowGiftProducts
} from '../../utils';
import { addActionEvent } from '../../googleAnalyticsHelper';
import { getUser, updateUserPermissions } from '../../store/actions/authActions';
import LoyaltySlices from './LoyaltySlices';
class LoyaltyManager extends Component {
    state = {
        bonusProduct: {},
        isTermsAccepted: false
    };

    componentDidMount() {
        const { user, location } = this.props;
        if (user.id) {
            this.getProductDetails();
        }
        if (location.hash === '#rules') {
            this.scrollToRules();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user.id !== this.props.user.id && this.props.user.id) {
            this.getProductDetails();
        }
        if (this.props.location.hash !== prevProps.location.hash && this.props.location.hash === '#rules') {
            this.scrollToRules();
        }
    }

    scrollToRules = () => {
        window.scrollTo({
            top: this.rules.current.offsetHeight + window.innerHeight - appHeaderHeight,
            behavior: 'smooth'
        });
    };

    onClickRulesLink = event => {
        this.scrollToRules();
        event.stopPropagation();
    };

    getProductDetails = () => {
        const { boundGetProductDetails, user, basket } = this.props;
        if (
            user.loyalty && user.loyalty.punchCard
            && user.loyalty.punchCard.canGetGift === 'true'
            && user.loyalty.punchCard.canOrderGiftProductID
            && user.loyalty.punchCard.canOrderGiftProductID !== '0'
        ) {
            boundGetProductDetails(user.loyalty.punchCard.canOrderGiftProductID, basket.id)
                .then(response => this.setState({ bonusProduct: response.data.result }));
        }
    };

    onButtonClick = () => {
        const { history } = this.props;
        history.push('/siparis/kampanyalar');
    };

    getOnProductClick = product => () => {
        const { boundSetModal } = this.props;
        boundSetModal(`view-promotion/${product.id}`);
    };

    getProductClassName = () => {
        const { basket, user } = this.props;
        let className = 'loyalty__product';
        if (isGiftAddedInBasket(basket, user)) {
            className += ' loyalty__product--disabled';
        }
        return className;
    };

    renderProducts = () => {
        const { basket, user } = this.props;
        const { bonusProduct } = this.state;
        const productsAmount = getGiftProductsAmount(user);
        const products = [];
        for (let i = 0; i < productsAmount; i++) {
            products.push({ ...bonusProduct, reactKey: i });
        }
        if (isGiftAddedInBasket(basket, user)) {
            products.pop();
        }
        return products.map(product =>
            <div className={this.getProductClassName()}
                 onClick={this.getOnProductClick(product)}
                 key={product.reactKey}
            >
                <div className="loyalty__product-top">
                    <img src={product.image} alt={product.image} className="loyalty__product-image"/>
                    <div className="loyalty__product-right">
                        <div className="loyalty__product-text-block">
                            <div className="loyalty__product-name">
                                {product.name}
                            </div>
                            {
                                product.detail
                                    ? <div className="loyalty__product-detail loyalty__product-detail--mobile-hidden">
                                        {product.detail}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="button button--green-light loyalty__product-button">Hediyeni Al</div>
                    </div>
                </div>
                {
                    product.detail
                        ? <div className="loyalty__product-detail loyalty__product-detail--desktop-hidden">
                            {product.detail}
                        </div>
                        : null
                }
            </div>
        );
    };

    handleApproveLoyaltyChange = () => {
        const { boundUpdateUserPermissions, boundGetUser, user, boundSetModal } = this.props;
        boundUpdateUserPermissions(user, { IsLoyaltyApproved: !getUserPermissionValue(user, 'IsLoyaltyApproved') })
            .then(() => {
                boundGetUser();
                if (!getUserPermissionValue(user, 'IsLoyaltyApproved')) {
                    boundSetModal('loyalty-activated-notification');
                }
                addActionEvent('Loyalty ile haberdar ol', !getUserPermissionValue(user, 'IsLoyaltyApproved') === true ? 'ON' : 'OFF');
            });
    };

    toggleLoyaltyTerms = () =>
        this.setState(prevState => ({ isTermsAccepted: !prevState.isTermsAccepted }));

    render() {
        const { basket, user, loading } = this.props;
        const { isTermsAccepted } = this.state;

        if (!user.id || !user.loyalty || !user.loyalty.punchCard) {
            return null;
        }

        return <div className="loyalty">
            <div className="loyalty__top">
            <img src="/images/loyalty-header.png" alt="loyalty-header" className="loyalty-preview__header"/>
                <LoyaltySlices user={user} sliceClassName="loyalty__slice"/>
                <div className="loyalty__subheader">
                    <span className="loyalty__subheader-orange-text">
                        {+user.loyalty.punchCard.requiredToGetNewGift} Dilim
                    </span>  {user.loyalty.punchCard.requiredToGetNewGift != user.loyalty.punchCard.requiredToGetGift?'daha ':null} topla ücretsiz hediyeni kazan.
                </div>

                <div className="button button--green-light loyalty__button" onClick={this.onButtonClick}>
                    Siparişe Başla
                </div>
            </div>
            <div className="loyalty__bottom">
                <div className="loyalty__body">
                  {
                      !getUserPermissionValue(user, 'IsLoyaltyApproved')
                          ? <Checkbox className="loyalty__checkbox"
                                      value={isTermsAccepted}
                                      label={
                                          <div className="loyalty__checkbox-container">
                                              <div className="loyalty__checkbox-label">
                                                  <span>Hediyeni almak için hemen onay ver.</span>
                                                  <Link to="#rules"
                                                        onClick={this.onClickRulesLink}
                                                  >
                                                      Sezarla Kazan Koşullarını
                                                  </Link>
                                                  <span>kabul ediyorum.</span>
                                              </div>
                                              <div className="loyalty__checkbox-button">
                                                  <Button color="orange"
                                                          onClick={this.handleApproveLoyaltyChange}
                                                          disabled={isTermsAccepted ? false : true}
                                                  >
                                                      Sezarla Kazan’a Katıl
                                                  </Button>
                                              </div>
                                          </div>
                                      }
                                      labelClassName="loyalty__checkbox-label"
                                      onChange={this.toggleLoyaltyTerms}
                                      disabled={loading}
                          />
                          : null
                  }
                    <div className="loyalty__products-header">
                        Kazandıklarım
                    </div>
                    {
                        shouldShowGiftProducts(basket, user)
                            ? <div className="loyalty__products-container">
                                {this.renderProducts()}
                            </div>
                            : <div className="loyalty__no-gifts">
                                <img src="/images/loyalty-no-gifts.png"
                                     alt="loyalty-no-gifts"
                                     className="loyalty__no-gifts-image"
                                />
                                <div className="loyalty__no-gifts-text">Henüz hediye ürünün yok.</div>
                            </div>
                    }
                    <div className="loyalty__paragraphs-header" ref={getRef(this, 'rules')}>
                        Sezarla Kazan Kullanım Koşulları
                    </div>
                    <div className="loyalty__paragraphs-container">
                        <div className="loyalty__paragraph">
                        Sezarla Kazan sadakat program Little Caesars Pizza bünyesinde yapılan müşteri memnuniyeti odaklı bir sadakat programıdır. Little Caesars Pizza online kanallarından (web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) vereceğin siparişlerinden kazanacağın her 4 dilime 1 orta boy klasik pizzayı Sezarla Kazan programı kapsamında kazanmış olursun. Kazandığın dilimler ve hediyeler 24 saat içinde hesabına yüklenir. Kazandığın pizzayı kullanman için bir sonraki siparişinde minimum sepet değerini geçmen gerekmektedir. Sezarla Kazan Programından faydalanmak için hemen web/mobil sitemiz veya uygulamalarımız üzerinden giriş yapabilir ya da anında üye olabilirsin. 
                        </div>
                        <div className="loyalty__paragraph">
                        Little Caesars Pizza Sezar Kazan Sadakat Programı’nda yapacağı tüm değişiklik haklarını saklı tutar. Sezarla Kazan Sadakat Programı şartlarını veya adını değiştirebilir, programı durdurabilir, üyeliği iptal edebilir. Üyeliğin Little Caesars Pizza tarafından sona erdirilmesi durumunda üyenin hesabında biriken sanal dilim, ek sanal dilim ve Hediye Pizzalar Little Caesars Pizza tarafından silinecek ve geri alınacaktır.
                        </div>
                        <div className="loyalty__paragraph">
                        Little Caesars Pizza Sezar Kazan Sadakat Programı koşullarını kabul eden kişiler verdiği bilgilerin doğru ve güncel olduğunu kabul ve taahhüt eder. Aksi takdirde, her türlü sorumluluk üyeye aittir.
                        </div>
                        <div className="loyalty__paragraph">
                        <p><strong>Koşullar ve Sık&ccedil;a Sorulan Sorular</strong></p>
<p><strong>Little Caesars Sezarla Kazan Programı Nedir?</strong></p>
<p>Sezarla Kazan programı Little Ceaesars Pizza&rsquo;nın sadakat programıdır. Bu program ile &uuml;yeler sipariş verdik&ccedil;e daha &ccedil;ok pizza kazanırlar.</p>
<p><strong>Little Caesars Online Kanalları Hangi Kanalları Kapsamaktadır?</strong></p>
<p>Little Caesars internet sitesi, mobil internet sitesi, IOS uygulaması ve android uygulamasını kapsamaktadır. Sezarla Kazan programı sadece bu kanallardan verilen siparişlerde ge&ccedil;erli olmaktadır.</p>
<p><strong>Nasıl Hediye Pizza Kazanırım?</strong></p>
<p>40 TL ve &uuml;zeri online siparişlerinde 1 dilim kazanırsın, kazandığın dilimler 4 adet olduğunda,bir sonraki siparişinde kullanabileceğin orta boy klasik pizza grubundaki pizzzalarından birini kazanmaya hak kazanırsın. Ayrıca Little Caesars Pizza tarafından belirlenen d&ouml;nemlerde, belirli &uuml;r&uuml;n ve/veya kuponlara &ouml;zel ek dilimler kazanabilirsin, b&ouml;ylece ge&ccedil;erlilik tarihinden &ouml;nce dilimlerini 4 dilime tamamladığında, 4 siparişten &ouml;nce de Klasik pizza grubundan birini elde etme hakkı kazanırsın.</p>
<p><strong>Sezarla Kazan Sadakat Programına Nasıl &Uuml;ye Olurum?</strong></p>
<p>Little Caesars online kanallarından (web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) birine &uuml;ye olurken Sezarla Kazan&rsquo;a katıl diyerek ya da profilinde bulunan Sezarla Kazan sekmesinden Sezarla Kazan&rsquo;a Katıl diyerek sadakat programına katılabilirsin. Programdan yararlanman için uygulama versiyonunun IOS için 5.0.0, Anroid için 5.0.0 ve üzeri olması gerekmektedir. Uygulamanın güncel olduğundan emin olup, güncel değil ise gerekli güncellemeleri yapmalısın.</p>
<p><strong>Sezarla Kazan Programına Katılmadan Hediye Kazanabilir miyim?</strong></p>
<p>Sezarla Kazan programına katılım ger&ccedil;ekleştirmemiş kullanıcılar da dilim biriktirme hakkına sahiptir fakat birikecek 4 sanal dilim sonrası kazanacakları hediye orta boy klasik pizzayı kullanabilmeleri i&ccedil;in Sezarla Kazan programına katılmaları gerekmektedir.</p>
<p><strong>Her Siparişimden Dilim Kazanabilecek miyim?</strong></p>
<p>Little Caesars web sitesi ve mobil uygulama &uuml;zerinden vereceğin 40 TL ve &uuml;zeri her online siparişinde dilim kazanırsın. 1 g&uuml;n i&ccedil;erisinde verilen siparişlerinden sadece 1 kez dilim kazanabilirsin.</p>
<p><strong>Ge&ccedil;mişte Verdiğim Siparişlerden Dolayı Dilimlerim Birikecek mi?<br /> <br /> </strong>Hayır, programın lansman tarihi olan ( 07.12.2020 ) itibariyle verilen siparişlerden farklı g&uuml;nlerde sipariş verilerek kazandığın 4 dilim ile orta boy klasik pizza grubundan bir pizza almaya hak kazanabilirsin.</p>
<p><strong>Hediye Orta Boy Pizzamı İstediğim Şekilde Se&ccedil;ebilir miyim?</strong></p>
<p>Orta boy klasik grubundan bulunan pizzalardan istediğini se&ccedil;ebilirsin. Hediye pizzanı kullanırken minimum sepet tutarını ge&ccedil;men gerekmektedir.</p>
<p><strong>Hediye Pizza Kullanırken Yapacağım Eklemeler &Uuml;cretlendirilir mi?</strong></p>
<p>Evet, hediye pizzana ek yapılacak, kenar, sos, ek malzeme, i&ccedil;ecek vb &uuml;r&uuml;n eklemeleri ayrıca &uuml;cretlendirilmektedir.</p>
<p><strong>Hediye Pizzalarımın Kullanım S&uuml;resi Olacak mı?</strong></p>
<p>Evet, hediye pizzalarının kullanım s&uuml;resi, hediye pizza almaya hak kazandığın tarihten itibaren 180 g&uuml;nd&uuml;r.</p>
<p><strong>Dilim Toplarken Dilim hakkı Kazandırmayan Siparişler Olacak mı?</strong></p>
<p>Evet, &ouml;ncelikle sepet tutarın 40 TL ve &uuml;zerinde olmalıdır. Bu tutarın altında vereceğin siparişlerde dilim kazanımı yoktur. &nbsp;Ayrıca, Little Caesars şubelerini arayacak, m&uuml;şteri hizmetlerini arayarak ya da Yemek Sepeti, Getir , Zomato gibi kanallardan verilen siparişlerde dilim kazanımı olmayacaktır.</p>
<p><strong>Hediye Pizza Kazandım Fakat Kullanmadım, Dilim Kazanmaya Devam Edebilir miyim?</strong></p>
<p>Evet, kazandığın hediye pizza kaybolmaz ve yeni pizza kazanman i&ccedil;in dilim sayacı tekrardan başlar.</p>
<p><strong>Aynı Siparişimde 2 hediye Pizza Sepete ekleyebilir miyim?</strong></p>
<p>Hayır, Siparişinde sepetine sadece 1 adet hediye &uuml;r&uuml;n ekleyebilirsin.</p>
<p><strong>Kazandığım Dilimler ile 4 Adedi Tamamlayamazsam, Dilimlerim Silinir mi? </strong></p>
<p>Sezarla kazan dilim kazanma koşullarına uyarak kazandığın her dilim, kazandığın tarih itibariyle 180 g&uuml;n s&uuml;reyle ge&ccedil;erli olacaktır. Eğer bu s&uuml;re aşıldıysa dilimlerin silinir. Farklı kampanya ve kurallara g&ouml;re kazandığın dilimler var ise ilgili kampanya kurallarına g&ouml;re bu kural değişiklik g&ouml;stermektedir. Sezarla Kazan sekmemizden bu bilgileri takip edebilirsin.</p>
<p><strong>Kazandığım Hediye Pizza T&uuml;m Little Caesars Şubelerinde Ge&ccedil;erli Olacak mıdır?</strong></p>
<p>Evet, Kazandığın hediye pizzayı Little Caesars online kanallarından (web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) vereceğin online siparişlerde t&uuml;m Little Caesars şubelerinde kullanabilirsin.</p>
<p><strong>Hangi Sipariş Kanallarından Sipariş Verdiğimde Dilim Hakkı Kazanırım?</strong></p>
<p>Sadece Little Caesars Pizza&rsquo;ya ait online kanallardan ((web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) &uuml;ye girişi yaparak verdiğin siparişlerden dilim hakkı kazanırsın.</p>
<p><strong>Siparişim İptal Olduğunda, iptal olan siparişimden Dilim Kazanabilir miyim?</strong></p>
<p>Hayır, iptal olan ya da iptal ettiğin siparişlerinden dilim hakkı kazanamazsın. Ancak tamamlanan ve teslim edilen siparişlerden dilim hakkı kazanırsın.</p>
<p><br /> <strong>Farklı &Uuml;yeliklerimde Bulunan Dilimleri Birleştirebilir miyim?<br /> </strong>&Uuml;yelerin birden fazla e-posta adresi veya telefon numarası ile ilişkilendirilmiş Sezarla Kazan &uuml;yelikleri varsa, bu farklı &uuml;yeliklerden kazandıkları sanal dilimler farklı hesaplarda birikmektedir ve birleştirilerek kullanılamamaktadır.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    basket: store.basket.basket,
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({
    boundGetProductDetails: bindActionCreators(getProductDetails, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundUpdateUserPermissions: bindActionCreators(updateUserPermissions, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch)
});

LoyaltyManager.propTypes = {
    user: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    boundGetProductDetails: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundUpdateUserPermissions: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoyaltyManager));
