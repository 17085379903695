import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextInput from '../elements/TextInput';
import Button from '../elements/Button';

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                token: this.props.token,
                password: '',
                confirmPassword: ''
            },
            errors: {},
            touched: {},
            formIsvalid: false,
            formSubmitted: false
        };
    }

    handleInputChange = (e) => {
        this.setState({ fields: { ...this.state.fields, [e.target.name]: e.target.value } }, this.validateForm);
    };

    handleInputBlur = (e) => {
        const { touched } = this.state;

        if (e.target.name && touched[e.target.name] !== true) {
            touched[e.target.name] = true;
            this.setState({ touched }, this.validateForm);
        }
    };

    handleFormSubmit = (e) => {
        const { password } = this.state.fields;
        const { submitNewPassword } = this.props;

        e.preventDefault();
        if (this.validateForm()) {
            this.setState({ formSubmitted: true });
            submitNewPassword(password);
            this.resetForm();
        }
    };

    resetForm = () => {
        let fields = {};
        fields['password'] = '';
        fields['confirmPassword'] = '';
        this.setState({
            fields,
            formIsValid: false,
            formSubmitted: false
        });
    };

    validateForm = () => {
        const { fields } = this.state;
        const errors = {};
        let formIsValid = true;
        this.setState({
            errors
        });

        //validate password
        if (!fields.password) {
            formIsValid = false;
            errors.password = 'Lütfen şifrenizi giriniz';
        } else {
            if (fields.password.length < 3) {
                formIsValid = false;
                errors.password = 'Şifreniz en az 3 karakter olmalıdır';
            }
        }

        //validate confirm password
        if (fields.password !== fields.confirmPassword) {
            formIsValid = false;
            errors.confirmPassword = 'Şifreler aynı değil';
        }

        this.setState({
            errors,
            formIsValid
        });

        return formIsValid;
    };

    render() {
        const { fields, errors, touched, formIsValid, formSubmitted } = this.state;
        return (
            <div className="login-form">
                <div className="login-form-send member-login clearfix">
                    <div className="auth-page__header">Şifre Değiştir</div>
                    <div className="auth-page__subheader">Şİfrenizi yeniden oluşturun.</div>
                    <TextInput type="password"
                               className="auth-page__input"
                               placeholder="Yeni Şifre"
                               name="password"
                               value={fields.password}
                               onChange={this.handleInputChange}
                               onBlur={this.handleInputBlur}
                               error={(formSubmitted || touched.password) && errors.password ? errors.password : null}
                    />
                    <TextInput type="password"
                               className="auth-page__input"
                               placeholder="Yeni Şifre Tekrar"
                               name="confirmPassword"
                               value={fields.confirmPassword}
                               onChange={this.handleInputChange}
                               onBlur={this.handleInputBlur}
                               error={(formSubmitted || touched.confirmPassword) && errors.confirmPassword ? errors.confirmPassword : null}
                    />
                    <Button className="auth-page__submit-button"
                            disabled={!formIsValid}
                            onClick={this.handleFormSubmit}
                    >
                        Kaydet
                    </Button>
                    <Link className="auth-page__cancel-button" to="/hesap/giris">
                        Geri Dön
                    </Link>
                </div>
            </div>
        );
    }
}

ResetPasswordForm.propTypes = {
    submitNewPassword: PropTypes.func.isRequired
};

export default ResetPasswordForm;
