import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPageResult } from '../../../store/actions/pagesActions';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';
import { sendPageView } from '../../../googleAnalyticsHelper';

class Covid19Proclamation extends Component {
    state = {
        pageResult: null
    };

    componentDidMount = () => {
        const { boundGetPageResult } = this.props;

        sendPageView('/musteri-hizmetleri/covid-19-onlemleri-bildirisi', 'Covid-19 Önlemleri Bildirisi - Little Caesars'); //For ga
        boundGetPageResult('covid-19-onlemleri-bildirisi')
            .then(response => this.setState({ pageResult: response.payload }));
    };

    renderPageResult = () => {
        const { pageResult } = this.state;
        return <section class="institutional-structures">
            <div class="container">
                <BreadCrumbs/>

                <div class="institutional-structures-detail  bg-white2 ">
                    <div class="institutional-structures-detail-content clearfix">
                        <div class="col-md-12" dangerouslySetInnerHTML={{__html: pageResult.content}}>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    };

    render() {
        const { pageResult } = this.state;
        return (
            <div>
                {
                    pageResult ?
                        this.renderPageResult()
                        : null
                }
            </div>
        );
    }
}

const masStateToProps = store => ({});

const mapDispatchToProps = (dispatch) => ({
    boundGetPageResult: bindActionCreators(getPageResult, dispatch)
});

Covid19Proclamation.propTypes = {
    boundGetPageResult: PropTypes.func.isRequired
};

export default connect(masStateToProps, mapDispatchToProps)(Covid19Proclamation);
