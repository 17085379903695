import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getValidator from '../../../getValidator';
import { deactivateUser, getUser, updateUserPermissions } from '../../../store/actions/authActions';
import Icon from '../../elements/Icon';
import Checkbox from '../../elements/Checkbox';
import { getUserPermissionValue, logOut, stopPropagation } from '../../../utils';
import { setAddressSelection } from '../../../store/actions/addressActions';
import DeactivateAccountPopup from './DeactivateAccountPopup';
import { addClickEvent, addActionEvent, sendPageView } from '../../../googleAnalyticsHelper';
import AccountSettingsForm from './AccountSettingsForm';
import ChangePasswordForm from './ChangePasswordForm';
import { getFromLocalStorage } from '../../../storage';

class ProfileAccount extends Component {
    validator = getValidator(this);

    state = {
        showDeactivateAccountPopup: false
    };

    componentDidMount() {
        sendPageView('/profil/hesap', 'Profil Hesabı');
    }

    handleApproveMailChange = () => {
        const { boundUpdateUserPermissions, boundGetUser, user } = this.props;
        boundUpdateUserPermissions(user, { CampaignInform: !getUserPermissionValue(user, 'CampaignInform') })
            .then(() => {
                boundGetUser();
                addActionEvent('E-Posta ile haberdar ol', !getUserPermissionValue(user, 'CampaignInform') === true ? 'ON' : 'OFF'); //for google analytics
            });
    };

    handleApproveSmsChange = () => {
        const { boundUpdateUserPermissions, boundGetUser, user } = this.props;
        boundUpdateUserPermissions(user, { SmsInform: !getUserPermissionValue(user, 'SmsInform') })
            .then(() => {
                boundGetUser();
                addActionEvent('Sms ile haberdar ol', !getUserPermissionValue(user, 'SmsInform') === true ? 'ON' : 'OFF');
            });
    };

    handleApproveLoyaltyChange = () => {
        const { boundUpdateUserPermissions, boundGetUser, user } = this.props;
        boundUpdateUserPermissions(user, { IsLoyaltyApproved: !getUserPermissionValue(user, 'IsLoyaltyApproved') })
            .then(() => {
                boundGetUser();
                addActionEvent('Loyalty ile haberdar ol', !getUserPermissionValue(user, 'IsLoyaltyApproved') === true ? 'ON' : 'OFF');
            });
    };

    getRemoveLink = id => () => {
    };

    deactivateAccount = () => {
        const { boundDeactivateUser, boundSetAddressSelection } = this.props;
        addClickEvent('Hesabimi Sil'); //for google analytics

        boundDeactivateUser()
            .then(() => {
                boundSetAddressSelection({});
                logOut();
            });
    };

    openDeactivateAccountPopup = () => this.setState({ showDeactivateAccountPopup: true });

    closeDeactivateAccountPopup = () => this.setState({ showDeactivateAccountPopup: false });

    render() {
        const { user, loading } = this.props;
        const { showDeactivateAccountPopup } = this.state;

        return <div className="profile-account profile__page">
            {
                showDeactivateAccountPopup
                    ? <DeactivateAccountPopup submit={this.deactivateAccount}
                                              close={this.closeDeactivateAccountPopup}
                    />
                    : null
            }
            <AccountSettingsForm/>
            <ChangePasswordForm/>
            {
                user.marketingPermission
                    ? <div className="profile-account__block">
                        <div className="profile__block-header">Bildirim Ayarları</div>
                        <Checkbox onChange={this.handleApproveMailChange}
                                  value={getUserPermissionValue(user, 'CampaignInform')}
                                  label="Bana özel hazırlanmış veya benim için geçerli olan kampanyalar, indirimler ve haberler hakkında e-posta almak istiyorum."
                                  className="profile-account__checkbox"
                                  activeColor="yellow"
                                  type="switch"
                                  disabled={loading}
                        />
                        <Checkbox onChange={this.handleApproveSmsChange}
                                  value={getUserPermissionValue(user, 'SmsInform')}
                                  label="Bana özel hazırlanmış veya benim için geçerli olan kampanyalar, indirimler ve haberler hakkında SMS almak istiyorum."
                                  className="profile-account__checkbox"
                                  activeColor="yellow"
                                  type="switch"
                                  disabled={loading}
                        />
                        
                        <Checkbox onChange={this.handleApproveLoyaltyChange}
                                  value={getUserPermissionValue(user, 'IsLoyaltyApproved')}
                                  label={
                                      <div className="loyalty__checkbox-label">
                                          <span>Hediyeni kazanmak için programa katıl.</span>
                                          <Link to="/musteri-hizmetleri/sezarla-kazan-kosullari"
                                                onClick={stopPropagation}
                                          >
                                              Sezarla Kazan Koşullarını
                                          </Link>
                                          <span>kabul ediyorum.</span>
                                      </div>
                                  }
                                  className="profile-account__checkbox"
                                  activeColor="yellow"
                                  type="switch"
                                  disabled={loading}
                        />
                    </div>
                    : null
            }
            {
                getFromLocalStorage('fbavatar')
                    ? <div className="profile-account__block">
                        <div className="profile__block-header">Bağlı Hesaplarım</div>
                        <div className="profile-account__user-preview">
                            <img src={getFromLocalStorage('fbavatar')} alt={`${user.name}.png`}
                                 className="profile-account__user-image"
                            />
                            <div className="profile-account__user-right">
                                {
                                    user
                                        ? <div className="profile-account__user-name icon-with-label">
                                            <Icon name="facebook" className="icon--blue profile-account__user-icon"/>
                                            <span>{user.name}</span>
                                        </div>
                                        : null
                                }
                                <div className="profile-account__remove-link-button link dn"
                                     onClick={this.getRemoveLink()}
                                >
                                    Bağlantıyı Kaldır
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className="profile-account__block" onClick={this.openDeactivateAccountPopup}>
                <span className="profile-account__deactivate-account-button link">
                    Hesabımı Sil
                </span>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    loading: !!store.loader.loadingCount
});

const mapDispatchToProps = dispatch => ({
    boundDeactivateUser: bindActionCreators(deactivateUser, dispatch),
    boundUpdateUserPermissions: bindActionCreators(updateUserPermissions, dispatch),
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch)
});

ProfileAccount.propTypes = {
    boundDeactivateUser: PropTypes.func.isRequired,
    boundUpdateUserPermissions: PropTypes.func.isRequired,
    boundGetUser: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccount);
