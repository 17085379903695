import React from 'react';
import CorporateMenu from '../../corporate-menu/CorporateMenu';
import BreadCrumbs from '../../../elements/BreadCrumbs';
import { sendPageView } from '../../../../googleAnalyticsHelper';

const HumanResourcesPolicy = () => {
    sendPageView('/kurumsal/insan-kaynaklari-politikasi', 'İnsan Kaynaklari Politikasi - Little Caesars');
   
   return <section class="institutional-structures">
    <div class="container">
        <BreadCrumbs />

        <div class="institutional-structures-detail">
            <div class="institutional-structures-detail-content clearfix">
                   <CorporateMenu />
                <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>İnsan Kaynakları  Politikamız</h1>
                        <img class="img-responsive" src="/images/kurumsal/insan-kaynaklari-politikasi.jpg" alt="" />
                        <p>İnsan Kaynakları birimi olarak katılımcılığı, takım çalışmasını, girişimciliği ve yaratıcılığı destekleyerek Little Caesars Türkiye’yi çalışmak için tercih edilen, mutlu ve bağlı çalışanlarıyla büyük bir aile haline getirerek başarıyı sürekli kılmak İnsan Kaynakları politikamızın temelini oluşturur.</p>
                        <h2>İşe Alım Süreçlerimiz</h2>
                        <p>Little Caesars ailesinin bir parçası olmaktan her zaman mutlu olan, başarma arzusu yüksek, içinde bulduğu çevreye ve topluma karşı duyarlı kişilerin şirketimizi ve bizi geliştirdiğine;  yenilikçi, verimli ve kaliteli çalışmayı esas alan, dürüst ve yüksek performanslı kişilerden oluşan bir organizasyonun başarıyı da beraberinde getirdiğine inanırız. Bunun için de Yetkinlik Bazlı Mülakatlar ve yardımcı işe alım araçları kullanırız. Mülakata ek olarak pozisyona bağlı, zihinsel beceri ve mesleki testler, yabancı dil seviye tespit sınavı ve kişilik envanterleri ile işe alım süreçlerimizi profesyonelleştiririz. Little Caesars ailesinin hızla artan restoran sayısı ile birlikte personel ve yönetici ihtiyacı da sürekli artmaktadır. Takımımızın güçlü parçalarından biri olmak, çalışırken eğlenmek ve en önemlisi bizler sektörün öncü markası olmak için koşarken aramıza katılmak isteyen herkesin özgeçmişini <b><a href="mailto:lc.basvuru@celebi.com.t">lc.basvuru@celebi.com.tr</a></b> ‘a bekliyoruz.</p>
                        <h2>Little Caesars’ta Çalışma Deneyimi</h2>
                        <p> Tüm çalışma alanlarımızda çalışırken eğlenmeyi ve Little Caesars değerlerimize bağlı kalmayı ilke edindik. Motivasyonumuzu ve bağlılığımızı güçlendirecek etkinlikler, projeler geliştirdik. Terfi ve kariyer olanaklarını her pozisyon için mümkün kılınacak sistemler kurduk. Bunlara ek olarak pozisyon seviyelerine göre farklı sosyal ve yan haklar sunduk.</p>
                        <h2>Oryantasyon Sürecimiz</h2>
                        <p>Pozisyona uygun adayın kanuni giriş işlemleri ve İnsan Kaynakları Oryantasyon Eğitimi tamamlandıktan sonra yeni çalışma arkadaşımız hangi pozisyonda olursa olsun önce Restoran Eğitimi alır. Amacımız çalışanlarımızın, işimizin en önemli parçası olan restoranlarımızın atmosferini ve lezzetli pizza yapımının inceliklerini özümseyerek işlerine başlamalarını sağlamaktır. </p>
                        <p>Pozisyonun uzmanlık alanları ve seviyeleri doğrultusunda oryantasyon süreci işe başladıktan sonra da ihtiyaca göre devam edebilmektedir. </p>
                        <p>Hedefimiz şirketimizin dinamiklerini ve işin gerekliliklerini tam olarak benimsenmesini sağlamaktır.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

};

export default HumanResourcesPolicy;