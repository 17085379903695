import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const BusinessDevelopmentTeam = () => {
    sendPageView('/kurumsal/is-gelistirme-ekibi', 'İş Geliştirme Ekibi - Little Caesars')
    return (
        <section className="institutional-structures">
        <div className="container">
            <BreadCrumbs />
            <div className="institutional-structures-detail">
                <div className="institutional-structures-detail-content clearfix">
                    <FranchisingMenu />
                    <div className="col-md-9">
                        <div className="corpareta-detail">
                            <h1>Franchise İş Geliştirme Ekibi</h1>           
                           
                            <div className="row business-development">
                                <div className="col-md-4">
                                    <img className="img-responsive" src="/images/kurumsal/franchising/fatih-eren.jpg" alt="fatih-eren.jpg"/>
                                    <div className="team-text">
                                        <h6>Fatih Eren</h6>
                                        <span>İş Geliştirme Müdürü</span>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <p>1978 yılında İstanbul’da doğmuştur. Eskişehir Anadolu Üniversitesi İşletme Bölümü’nden mezun oldu. Profesyonel iş hayatına 2000 yılında Teksu Grubu’nun Satış departmanında Gıda sektöründe başladı. 2005 yılında, Lezzo’da önce Distribütör Yöneticiliği akabinde Bölge Müdürlüğü görevlerinde bulundu. 2008 yılında Sabancı Grubu’nda DiaSa bünyesine katılarak Franchise Yöneticiliği ve Franchise Süpervizörlüğü görevlerini yürüttü. 2013 yılına kadar 100 ’ün üzerinde şube açılışı gerçekleştirdikten sonra kariyerine As Tavukçuluk’ta Bayi Geliştirme Koordinatörü olarak devam etti. 2015 yılına kadar Türkiye’nin her bölgesinde ve yurtdışında görevler aldıktan sonra kariyerini 2015 yılı itibari ile Çelebi Holding yönetimindeki Little Caesars Türkiye’de İş Geliştirme Müdürü olarak sürdürme kararı aldı.</p>
                                </div>
                            </div>
                        
                            <div className="row business-development">
                                <div className="col-md-4">
                                    <img className="img-responsive" src="/images/kurumsal/franchising/umit-caliskan.jpg" alt="umit-caliskan.jpg" />
                                    <div className="team-text">
                                        <h6>Ümit Çalışkan</h6>
                                        <span>İş Geliştirme Müdürü</span>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <p>1982 doğumlu Ümit Çalışkan, Sakarya Üniversitesi İşletme Bölümünde lisans eğitimini tamamlamış, ardından Nişantaşı Üniversitesi İşletme Yönetimi Yüksek Lisans Bölümü’nden mezun olmuştur. Profesyonel iş hayatına 2008 yılında Alkollü İçecek sektöründe faaliyet gösteren Anadolu Efes firmasının Satış Departmanı’nda Satış Temsilcisi olarak başlamış, 2011 yılında Sanset Gıda Turizm firmasında Ev Dışı Tüketim Kanalı Satış Geliştirme Yöneticisi olarak devam etmiştir. Daha sonra 2013 yılında Yudum Gıda bünyesinde Ev Dışı Tüketim Kanalı Türkiye Satış Yöneticisi olarak çalışmış. Ardından 2016 yılında Domino’s Pizza Türkiye İş Geliştirme Departmanı’nda Satış Uzmanı olarak işe başlamış ve Yönetici pozisyonuna geçmiştir. Bulunduğu görev itibariyle Türkiye’nin birçok şehrinde şube açılışı gerçekleştirmiş ve 2019 yılı itibariyle Little Caesars Türkiye ekibinde İş Geliştirme Müdürü olarak aramıza katılmıştır.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default BusinessDevelopmentTeam;