import React from 'react';
import api, { handleRequest } from '../../api';
import { MFS, CLIENT_ID, MACRO_MERCHANT_ID, setAmountforMasterPass } from '../../utils';
import { toast } from 'react-toastify';
import Toast from '../../components/elements/Toast';
import SuccessfullyLinkedCustomer from '../../components/profile/payment-settings/SuccessfullyLinkedCustomer';

export const SET_FAVORITE_PRODUCTS = 'SET_FAVORITE_PRODUCTS';
export const SET_FAVORITE_ORDERS = 'SET_FAVORITE_ORDERS';
export const SET_CREDIT_CARDS = 'SET_CREDIT_CARDS';
export const SET_MASTERPASS_RESPONSE = 'SET_MASTERPASS_RESPONSE';
export const SET_CARDS_LIST_WAS_CHANGED = 'SET_CARDS_LIST_WAS_CHANGED';

export const setFavoriteProducts = response => ({
    type: SET_FAVORITE_PRODUCTS,
    payload: response
});

export const setFavoriteOrders = response => ({
    type: SET_FAVORITE_ORDERS,
    payload: response
});

export const setCreditCards = response => ({
    type: SET_CREDIT_CARDS,
    payload: response
});

export const setMasterpassResponse = response => ({
    type: SET_MASTERPASS_RESPONSE,
    payload: response
});

export const setCardListWasChanged = payload => ({
    type: SET_CARDS_LIST_WAS_CHANGED,
    payload
});

export const getFavoriteProducts = () => dispatch =>
    api.get('web/Member/GetFavoriteProducts', dispatch)
        .then(response => handleRequest(response, () => dispatch(setFavoriteProducts(response.data.result))));

export const getFavoriteOrders = () => dispatch =>
    api.get('web/Member/GetFavoriteOrders', dispatch)
        .then(response => handleRequest(response, () => dispatch(setFavoriteOrders(response.data.result))));

export const addFavoriteProduct = productId => dispatch =>
    api.post('web/Member/AddFavoriteProduct', { ProductId: productId }, dispatch)
        .then(response => handleRequest(response));

export const addFavoriteOrder = orderId => dispatch =>
    api.post('web/Member/AddFavoriteOrder', { OrderId: orderId }, dispatch)
        .then(response => handleRequest(response));

export const deleteFavoriteProduct = id => dispatch => {
    const query = id ? `?id=${id}` : '';
    return api.delete(`web/Member/DeleteFavoriteProduct${query}`, dispatch)
        .then(response => handleRequest(response));
};

export const deleteFavoriteOrder = id => dispatch => {
    const query = id ? `?id=${id}` : '';
    return api.delete(`web/Member/DeleteFavoriteOrder${query}`, dispatch)
        .then(response => handleRequest(response));
};

export const addOrderComment = (orderId, commentText, starsCount) => dispatch => {
    api.post('web/Member/AddPersonRateOrder', {
        OrderId: orderId,
        Notes: commentText,
        Common: starsCount
    }, dispatch).then(response => handleRequest(response));
};

export const postFranchisingForm = (nameSurname, email, phone, invCity, invDistrict,
                                    invAmount, agreement) => dispatch =>
    api.post('web/Member/Franchise', {
        NameSurname: nameSurname,
        Mail: email,
        Phone: phone,
        Agreement: agreement,
        InvCity: invCity.label,
        InvDistrict: invDistrict.label,
        InvAmount: invAmount.label,
        FormType: 'Franchise'
    }, dispatch)
        .then(response => handleRequest(response));

export const postContactForm = (_Subject, _NameSurname, _Mail, _Phone, _Address,
                                _Message) => dispatch =>
    api.post('web/Member/Contact', {
        Subject: _Subject,
        NameSurname: _NameSurname,
        Mail: _Mail,
        Phone: _Phone,
        Address: _Address,
        Message: _Message
    }, dispatch)
        .then(response => handleRequest(response));

const getPaymentToken = (dispatch, callback) =>
    api.get('web/Payment/GetToken', dispatch)
        .then(response => handleRequest(response, callback(response)));

export const checkMasterPass = (userId, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);

    getPaymentToken(dispatch, (response) => {

        const checkMPForm = window.$(`
            <form>
                <input name="userId" value="${userId}" />
                <input name="token" value="${response.data.result}" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="sendSms" value="N" />
            </form>
        `);

        MFS.checkMasterPass(checkMPForm, (statusCode, response) => {
            if (response.responseCode == '0000' || response.responseCode == '') {
                if (response.accountStatus.substring(1, 5) == '1100') {
                    console.log('hesap bağlı değil');
                    setModal('show-cards');
                } else if (response.accountStatus.substring(1, 5) == '1110') {
                    console.log('hesap bağlı');
                    return dispatch(getCreditCards(userId));

                }

                return;
            } else {
                console.log('222: ' + response.accountStatus);
                if (response.responseCode == '5001') { // Ask OTP
                    setModal('sms-code-confirmation-credit-card' + response.responseCode);
                } else if (response.responseCode == '5008') { // Ask MasterPass OTP
                    setModal('sms-code-confirmation-credit-card' + response.responseCode);
                } else {
                    alert(response.responseDescription);
                }
            }
        });
    });

};

export const getCreditCards = (msisdn) => dispatch => {
    MFS.setClientId(CLIENT_ID);
    getPaymentToken(dispatch, (response) => {

        MFS.listCards(msisdn, response.data.result, (statusCode, response) => {
            if (response.responseCode != '0000' && response.responseCode != '') {
                if (response.responseCode = '1078') {
                    toast(<Toast
                        text={'Masterpass ile kullandığınız telefon numaranız başka Little Caesars hesabına bağlıdır.'}
                        status="error"/>);
                    return;
                } else {
                    toast(<Toast text={response.responseDescription} status="error"/>);
                    return;
                }

            }
            dispatch(setCardListWasChanged(true));
            dispatch(setCreditCards(response.cards));
        });
    });
};

export const addCreditCard = (creditCardName, creditCardNo, expirationDate, CVC, msisdn, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);
    getPaymentToken(dispatch, (response) => {

        const registerForm = window.$(`
            <form>
                <input name="accountAliasName" value="${creditCardName}" />
                <input name="rtaPan" value="${creditCardNo}" />
                <input name="expiryDate" value="${expirationDate}" />
                <input name="cvc" value="${CVC}" />
                <input name="token" value="${response.data.result}" />
                <input name="msisdn" value="${msisdn}" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="sendSms" value="N" />
                <input name="actionType" value="A" />
                <input name="clientIp" value="" />
                <input name="delinkReason" value="" />
                <input name="eActionType" value="A" />
                <input name="cardTypeFlag" value="05" />
                <input name="cpinFlag" value="Y" />
                <input name="defaultAccount" value="Y" />
                <input name="mmrpConfig" value="110010" />
                <input name="identityVerificationFlag" value="Y" />
                <input name="mobileAccountConfig" value="MWA" />
                <input name="timeZone" value="+01" />
                <input name="uiChannelType" value="6" />
            </form>
        `);

        MFS.register(registerForm, (statusCode, response) => {
            if (response.responseCode == '5001' || response.responseCode == '5008') {
                dispatch(setMasterpassResponse(response.responseDescription));
                setModal('sms-code-confirmation-credit-card' + response.responseCode);
                return;
            } else {
                toast(<Toast text={response.responseDescription} status="error"/>);
                setModal('');
                return;
            }
        });
    });
};

export const confirmAddingCard = (smsCode, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);

    const otpForm = window.$(`
            <form>
                <input name="validationCode" value="${smsCode}" />
                <input name="sendSms" value="N" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="pinType" value="otp" />
            </form>
    `);

    MFS.validateTransaction(otpForm, (statusCode, response) => {
        if (response.responseCode == '5001' || response.responseCode == '5008') {
            dispatch(setMasterpassResponse(response.responseDescription));
            setModal('sms-code-confirmation-credit-card' + response.responseCode);
            return;
        } else if (response.responseCode != '0000' && response.responseCode != '') {
            toast(<Toast text={response.responseDescription} status="error"/>);
            setModal('');
            return;
        } else {
            console.log('cardadded');
            setModal('successfully-added-credit-card');
            window.location.reload();
            return dispatch(setCardListWasChanged(true));
        }
    });
};
export const linkCustomer = (msisdn, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);


    getPaymentToken(dispatch, (response) => {
        const customerLinkForm = window.$(`
    <form>
        <input name="msisdn" value="${msisdn}" />
        <input name="token" value="${response.data.result}" />
        <input name="sendSmsLanguage" value="tur" />
        <input name="sendSms" value="N" />
    </form>
`);

        return MFS.linkCardToClient(customerLinkForm, (statusCode, response) => {
            if (response.responseCode == '5001' || response.responseCode == '5008') {
                dispatch(setMasterpassResponse(response.responseDescription));
                setModal('sms-code-confirmation-link-customer' + response.responseCode);
                return statusCode;
            } else {
                toast(<Toast text={response.responseDescription} status="error"/>);
                setModal('');
                return statusCode;
            }
        });
    });
};
export const confirmLinkCustomer = (smsCode, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);
    getPaymentToken(dispatch, (response) => {
        const otpForm = window.$(`
            <form>
                <input name="validationCode" value="${smsCode}" />
                <input name="sendSms" value="N" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="pinType" value="otp" />
            </form>
    `);

        MFS.validateTransaction(otpForm, (statusCode, response) => {
            if (response.responseCode != '0000' && response.responseCode != '') {
                toast(<Toast text={response.responseDescription} status="error"/>);
                setModal('');
                return;
            } else {
                console.log('SuccessfullyLinkedCustomer');
                setModal('successfully-linked-customer');
                return dispatch(setCardListWasChanged(true));
            }
        });
    });
};
export const deleteCreditCard = (accountAliasName, msisdn, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);
    getPaymentToken(dispatch, (response) => {

        const deleteForm = window.$(`
            <form>
                <input name="accountAliasName" value="${accountAliasName}" />
                <input name="token" value="${response.data.result}" />
                <input name="msisdn" value="${msisdn}" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="sendSms" value="N" />
            </form>
        `);

        MFS.deleteCard(deleteForm, (statusCode, response) => {
            if (response.responseCode != '0000' && response.responseCode != '') {
                toast(<Toast text={response.responseDescription} status="error"/>);
                console.log('carddeletederror');
                return;
            } else {
                console.log('carddeletedOK');
                setModal('successfully-deleted-credit-card');
                window.location.reload();
                return dispatch(setCardListWasChanged(false));
            }

        });
    });
};

let completeOrderCallback = null;

export const commitPurchase = (amount, creditCardName, msisdn, basketId, setModal, callback) => dispatch => {
    MFS.setClientId(CLIENT_ID);
    getPaymentToken(dispatch, (response) => {

        const purchaseForm = window.$(`
            <form>
                <input name="amount" value="${setAmountforMasterPass(amount)}" />
                <input name="listAccountName" value="${creditCardName}" />
                <input name="token" value="${response.data.result}" />
                <input name="msisdn" value="${msisdn}" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="macroMerchantId" value="${MACRO_MERCHANT_ID}" />
                <input name="orderNo" value="${basketId}" />
                <input name="sendSms" value="N" />
                <input name="aav" value="aav" />
                <input name="clientIp" value="${CLIENT_ID}" />
                <input name="encCPin" value="0" />
                <input name="encPassword" value="" />
                <input name="sendSmsMerchant" value="Y" />
                <input name="password" value="" />
            </form>
        `);

        MFS.purchase(purchaseForm, (statusCode, response) => {
            if (response.responseCode == '0000' || response.responseCode == '') {
                if (response.token) {
                    callback(response.token);
                }
            } else if (response.responseCode == '5001') {
                completeOrderCallback = callback;
                dispatch(setMasterpassResponse(response.responseDescription));
                setModal('sms-code-confirmation-purchase');
            } else if (response.responseCode == '5010') {
                dispatch(setMasterpassResponse(response.url3D + '&returnUrl=https://www.sezarpizza.com/securepay/MasterPass/SubmitAction'));
                setModal(`3d-security-iframe`);
            } else {
                toast(<Toast text={response.responseDescription} status="error"/>);
                return;
            }
        });
    });
};
export const commitDirectPurchase = (amount, rtaPan, expiryDate, cvc, msisdn, basketId, setModal, callback) => dispatch => {
    MFS.setClientId(CLIENT_ID);
    getPaymentToken(dispatch, (response) => {

        const purchaseForm = window.$(`
            <form>
                <input name="amount" value="${setAmountforMasterPass(amount)}" />
                <input name="rtaPan" value="${rtaPan}" />
                <input name="expiryDate" value="${expiryDate}" />
                <input name="cvc" value="${cvc}" />
                <input name="token" value="${response.data.result}" />
                <input name="msisdn" value="${msisdn}" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="macroMerchantId" value="${MACRO_MERCHANT_ID}" />
                <input name="orderNo" value="${basketId}" />
                <input name="sendSms" value="N" />
                <input name="aav" value="aav" />
                <input name="clientIp" value="${CLIENT_ID}" />
                <input name="encCPin" value="0" />
                <input name="encPassword" value="" />
                <input name="sendSmsMerchant" value="Y" />
                <input name="password" value="" />
            </form>
        `);

        MFS.directPurchase(purchaseForm, (statusCode, response) => {
            if (response.responseCode == '0000' || response.responseCode == '') {
                if (response.token) {
                    callback(response.token);
                }
            } else if (response.responseCode == '5001') {
                completeOrderCallback = callback;
                dispatch(setMasterpassResponse(response.responseDescription));
                setModal('sms-code-confirmation-purchase');
            } else if (response.responseCode == '5010') {
                dispatch(setMasterpassResponse(response.url3D + '&returnUrl=https://www.sezarpizza.com/securepay/MasterPass/SubmitAction'));
                setModal(`3d-security-iframe`);
            } else {
                toast(<Toast text={response.responseDescription} status="error"/>);
                return;
            }
        });
    });
};
export const commitPurchaseAndRegister = (amount, creditCardName, creditCardNo, expirationDate, CVC,
                                          msisdn, basketId, setModal, callback) => dispatch => {
    MFS.setClientId(CLIENT_ID);
    getPaymentToken(dispatch, (response) => {

        const purchaseAndRegisterForm = window.$(`
            <form>
                <input name="accountAliasName" value="${creditCardName}" />
                <input name="rtaPan" value="${creditCardNo}" />
                <input name="expiryDate" value="${expirationDate}" />
                <input name="cvc" value="${CVC}" />
                <input name="amount" value="${setAmountforMasterPass(amount)}" />
                <input name="msisdn" value="${msisdn}" />
                <input name="token" value="${response.data.result}" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="macroMerchantId" value="${MACRO_MERCHANT_ID}" />
                <input name="orderNo" value="${basketId}" />
                <input name="actionType" value="A" />
            </form>
        `);

        MFS.purchaseAndRegister(purchaseAndRegisterForm, (statusCode, response) => {
            if (response.responseCode == '5001' || response.responseCode == '5008') {
                completeOrderCallback = callback;
                dispatch(setMasterpassResponse(response.responseDescription));
                setModal('sms-code-confirmation-purchase-and-register' + response.responseCode);
            } else if (response.responseCode == '5010') {
                dispatch(setMasterpassResponse(response.url3D + '&returnUrl=https://www.sezarpizza.com/securepay/MasterPass/SubmitAction'));
                setModal(`3d-security-iframe`);
            } else {
                toast(<Toast text={response.responseDescription} status="error"/>);
                return;
            }
        });
    });
};

export const confirmPurchaseAndRegister = (smsCode, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);

    const otpForm = window.$(`
            <form>
                <input name="validationCode" value="${smsCode}" />
                <input name="sendSms" value="N" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="pinType" value="otp" />
            </form>
    `);

    MFS.validateTransaction(otpForm, (statusCode, response) => {
        if (response.responseCode == '0000' || response.responseCode == '') {
            if (response.token) {
                completeOrderCallback(response.token);
            }
            setModal('');
            return dispatch(setCardListWasChanged(true));
        } else if (response.responseCode == '5001' || response.responseCode == '5008') {
            dispatch(setMasterpassResponse(response.responseDescription));
            setModal('sms-code-confirmation-purchase-and-register' + response.responseCode);
        } else if (response.responseCode == '5010') {
            dispatch(setMasterpassResponse(response.url3D + '&returnUrl=https://www.sezarpizza.com/securepay/MasterPass/SubmitAction'));
            setModal(`3d-security-iframe`);
        } else {
            toast(<Toast text={response.responseDescription} status="error"/>);
            setModal('');
            return;
        }
    });
};

export const confirmPurchase = (smsCode, setModal) => dispatch => {
    MFS.setClientId(CLIENT_ID);

    const otpForm = window.$(`
            <form>
                <input name="validationCode" value="${smsCode}" />
                <input name="sendSms" value="N" />
                <input name="sendSmsLanguage" value="tur" />
                <input name="pinType" value="otp" />
            </form>
    `);

    MFS.validateTransaction(otpForm, (statusCode, response) => {
        if (response.responseCode == '0000' || response.responseCode == '') {
            if (response.token) {
                completeOrderCallback(response.token);
            }
            setModal('');
            return;
        } else {
            toast(<Toast text={response.responseDescription} status="error"/>);
            setModal('');
            return;
        }
    });
};

export const resendOtp = () => {
    var token = MFS.getLastToken();
    var lang = 'tur';
    MFS.resendOtp(token, lang, (status, response) => {

    });
};
