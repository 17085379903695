import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Modal extends Component {
    modalRef = React.createRef();
    modalElement = null;

    componentDidMount() {
        this.modalElement = this.modalRef.current;
        disableBodyScroll(this.modalElement);
    }

    componentWillUnmount() {
        enableBodyScroll(this.modalElement);
    }

    getClassName = () => {
        const { className } = this.props;
        let resultClassName = 'modal';
        if (className) {
            resultClassName += ` ${className}`;
        }
        return resultClassName;
    };

    // This is special case when we need to pass data-close-modal attribute to path tag. Refactor if needed.
    renderCloseButton = () => (
        <div className="icon modal__close-icon">
            <svg width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                data-close-modal={true}
                onClick={this.props.closeModal}
            >
                <path fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.414 5.41421L18.9998 4L11.9998 11L5.15054 4.15076L3.73633 5.56497L10.5856 12.4142L4.15054 18.8492L5.56476 20.2635L11.9998 13.8284L18.5856 20.4142L19.9998 19L13.414 12.4142L20.414 5.41421Z"
                    data-close-modal={true}
                />
            </svg>
        </div>

    );

    render() {
        const { withCloseIcon } = this.props;

        return <div className={this.getClassName()}
            onClick={this.props.closeModal}
            data-close-modal={true}
            ref={this.modalRef}
        >
            <div className="modal__body">
                {this.props.children}
                {
                    withCloseIcon
                        ? this.renderCloseButton()
                        : null
                }
            </div>
        </div>;
    };
}

Modal.defaultProps = {
    withCloseIcon: true
};

Modal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    className: PropTypes.string,
    withCloseIcon: PropTypes.bool
};

export default Modal;
