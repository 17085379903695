import api, { handleRequest } from "../../api";
import { getDeliveryTypeId } from "../../utils";
import { getFromLocalStorage } from "../../storage";

export const SET_PRODUCT_DEPARTMENTS = 'SET_PRODUCT_DEPARTMENTS';
export const SET_PRODUCT_SIZES = 'SET_PRODUCT_SIZES';
export const SET_PROMOTION_CODE = 'SET_PROMOTION_CODE'

export const setProductDepartments = response => ({
    type: SET_PRODUCT_DEPARTMENTS,
    payload: response
});

export const setProductSizes = response => ({
    type: SET_PRODUCT_SIZES,
    payload: response
});

export const setPromotionCode = response => ({
    type: SET_PROMOTION_CODE,
    payload: response
});

export const getProductDepartments = () => dispatch =>
    api.get('web/Product/GetDepartments', dispatch)
        .then(response => handleRequest(response, () => dispatch(setProductDepartments(response.data.result))));

export const getProducts = (categoryId, orderId, addressorstore, size) => dispatch => {
    const addressSelection = JSON.parse(getFromLocalStorage('addressSelection'));
    const deliveryTypeId = addressSelection && addressSelection.type && getDeliveryTypeId(addressSelection.type);
    const headers = deliveryTypeId ? { MemberDeliveryMethod: deliveryTypeId } : {};
    return api.post('web/Product/GetProducts', 
       { CategoryId: categoryId, Size: size, ExistingOrderId: orderId, AddressorStore: addressorstore },
        dispatch, { headers })
        .then(response => handleRequest(response));
};

export const getProductSizes = () => dispatch =>
    api.get('web/Product/GetProductSizes', dispatch)
        .then(response => handleRequest(response, () => dispatch(setProductSizes(response.data.result))));

export const getProductDetails = (productId, orderId, optionId, parentId) => dispatch => {
    const addressSelection = JSON.parse(getFromLocalStorage('addressSelection'));
    const deliveryTypeId = addressSelection && addressSelection.type && getDeliveryTypeId(addressSelection.type);
    const headers = deliveryTypeId ? { MemberDeliveryMethod: deliveryTypeId } : {};
    if (optionId && parentId) {
        return api.get(
            `web/Product/GetProductDetails?ProductId=${productId}&existingOrderId=${orderId}&optionId=${optionId}&parentId=${parentId}`,
            dispatch,
            { headers }
        )
            .then(response => handleRequest(response));
    } else {
        return api.get(
            `web/Product/GetProductDetails?ProductId=${productId}&existingOrderId=${orderId}`,
            dispatch,
            { headers }
        )
            .then(response => handleRequest(response));
    }
};

export const calculatePrice = product => dispatch =>
    api.post('web/Product/CalculatePrice', { basketItem: product, IncludeItemPrice: true }, dispatch)
        .then(response => handleRequest(response));

export const getPromotionCode = code => dispatch =>
    api.get('web/Product/GetPromotionCode?code=' + code, dispatch)
        .then(response => handleRequest(response, () => dispatch(setPromotionCode(response.data.result))));

export const getPromotionList = email => dispatch =>
    api.post('web/Basket/ListPromotions?email='+ email +'&maxPromotionCount=10',{}, dispatch)
        .then(response => handleRequest(response));

export const getFlavorsSuggestions = () => dispatch =>
    api.get('web/Product/GetPaymentPageTryFlavors', dispatch)
        .then(response => handleRequest(response));
