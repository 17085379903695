import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbsfranchising';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';
const FranchisingProcess = () => {
    sendPageView('/franchise/franchisee-olma-sureci', 'Franchisee Olma Süreci - Little Caesars');
    return (
        <section className="institutional-structures">
        <div className="container">
            <BreadCrumbs />
            <div className="institutional-structures-detail">
                <div className="institutional-structures-detail-content clearfix">
                    <FranchisingMenu />
                    <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>Franchisee Olma Süreci</h1>
                        <img class="img-responsive" src="/Images/kurumsal/franchising-olma-suresi.jpg" alt="Franchising olma süreci - Little Caesars"/>
                        <p>
                            Franchisee Sistemimiz<br />
                            “Her zaman tam destek…”<br />
                            Siz de aşağıdaki süreçleri takip ederek ailemizin parçası olabilir ve dünyada kabul görmüş bir markaya katılabilirsiniz…
                        </p>
                        <h2>1- Yüz Yüze Görüşme</h2>
                        <p>Little Caesars Genel Müdürlüğü’nde yapacağımız bu görüşmede siz değerli franchisee adaylarımızı yakından tanımayı, beklentilerini anlayabilmeyi ve 50 yılı aşkın süredir uygulanan muhteşem Little Caesars sistemi hakkında size detaylı bilgi vermeyi hedefliyoruz.</p>
                        <h2>2- Lokasyon Belirleme</h2>
                        <p>Little Caesars’ın büyüme stratejileri doğrultusunda hedeflenen bölgelerden seçim yapabileceğiniz gibi bunların dışında şube açmak istediğiniz alanları da bizimle paylaşabilirsiniz.</p>
                        <h2>3- İş Planı Hazırlama</h2>
                        <p>Hangi bölgede restoran açmak istediğinize karar verdikten sonra Little Caesars olarak sizden bölgeyi detaylı analiz etmenizi ve bu analizinizi bir iş planı olarak bizimle paylaşmanızı bekleyeceğiz. Bu aşama Little Caesars açısından oldukça önemlidir. Çünkü ailemize yeni katılacak üyelerimizi seçmek için kararımızı sizin iş planınıza ve sunumunuza göre vereceğiz. Başarılı bulunan adaylarımızdan 10.000 Dolar protokol bedeli alıyoruz ve eğitim aşamasına geçiyoruz.</p>
                        <h2>4- Eğitim</h2>
                        <p>Sunumda başarılı olan franchisee adaylarımız için diğer bir önemli aşama, eğitim sürecidir. Eğitim aşamasında 50 yıldan fazladır dünyada uygulanan kusursuz Little Caesars sistemini öğrenmenizi, yapılacak  uygulamalarda başarılı olmanızı ve tüm bunları kendi restoranınızda uygulamanızı bekliyor olacağız. Eğitim periyodumuz eğitim departmanımız önderliğinde 4 hafta sürmekte ve belirlenen program dahilinde pratik ve teorik olarak uygulanmaktadır. Tüm franchisee adaylarımızın eğitim programlarına tam ve zamanında katılımları gereklidir.</p>
                        <h2>5- Dükkan Kiralama</h2>
                        <p>Eğitimini başarıyla tamamlayan adaylarımız iş geliştirme departmanı önderliğinde dükkân kiralama sürecine geçecektir. Dükkânın kiralanması aşamasında ticari, hukuki ve lokasyon analizi de dahil tüm danışmanlık hizmetleri iş geliştirme departmanımız tarafından verilecektir.</p>
                        <h2>6- Açılış Pazarlama Planı</h2>
                        <p>Dükkânınızın dekorasyonu başladıktan sonra pazarlama departmanımız tarafından “Açılış Pazarlama Planı”nınızı paylaşmak üzere toplantıya davet edileceksiniz. Bu planda restoranınız açıldıktan sonraki sekiz hafta boyunca haftalık bazda ve bölgenize özel hazırlanmış pazarlama planı detaylı bir şekilde size anlatılacak ve gerekli pazarlama materyallerinin siparişleri verilecektir.</p>
                        <h2>7- Açılış</h2>
                        <p>Restoranlarımızdaki tüm ekipmanlar açılış için hazır olduğunda çevredeki potansiyel müşterilerimize dağıtacağımız “İkram Pizza” kuponları vasıtasıyla öncelikle test satışı yapacağız. Bölge sorumlunuzun test satışındaki performansınızı onaylamasıyla satışa başlayacaksınız.</p>
                        <h2>8- Yatırım Maliyeti</h2>
                        <p>Anahtar teslim bir restoranımızın maliyeti ortalama 700.000 TL + KDV’dir</p>
                    </div>


                </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default FranchisingProcess;