import React from 'react';
import PropTypes from 'prop-types';

const BottomInfoBlockItem = ({ title, imageUrl, altTitle, imageClassName, details, linkTitle, onClick }) => {
    return (
        <div className="bottom-info-block__item">
            <div className={`bottom-info-block__item-image ${imageClassName}`}>
                <img className={`${imageClassName}`} src={imageUrl} alt={altTitle}/>
            </div>
            <div className="bottom-info-block__item-info">
                <div className="bottom-info-block__item-title">{title}</div>
                <div className="bottom-info-block__item-details">{details}</div>
                <div className="bottom-info-block__item-link-container" onClick={onClick}>
                    <div className="bottom-info-block__item-link"> {linkTitle} </div>
                </div>
            </div>
        </div>
    );
};

BottomInfoBlockItem.propTypes = {
    title: PropTypes.string.isRequired,
    imageClassName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    altTitle: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    linkTitle: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default BottomInfoBlockItem;
