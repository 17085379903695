import React from 'react';
import FranchisingMenu from '../franchising-menu/FranchisingMenu';

const Sss = () => {
    return (

        <section class="institutional-structures">
            <div class="container">
                {/* <app-breadcrumbs></app-breadcrumbs> */}

                <div class="institutional-structures-detail">
                    <div class="institutional-structures-detail-content clearfix">
                        <FranchisingMenu />
                        <div class="col-md-9">
                            <div class="corpareta-detail">
                                <h1>SSS</h1>
                                <img class="img-responsive" src="/images/kurumsal/sss.jpg" alt="sss.jpg" />
                                <h2>1- Toplam maliyet nedir?</h2>
                                <p>Ortalama 700 bin TL + KDV toplam anahtar teslim bir yatırım maliyeti vardır.</p>
                                <h2>2- Maliyete dahil olmayan kalemler neler? Açılıştan sonra çıkacak ekstra harcamalar var mı?</h2>
                                <p>Bu maliyet anahtar teslimi maliyet olup tüm açılış masraflarını içermektedir. Açılıştan sonra ise rutin ürün, royalty, reklam ve tanıtım harcamaları haricinde bir ödemeniz olmayacaktır.</p>
                                <h2>3- Yatırımın geri dönüş hızı nedir?</h2>
                                <p>Açılan tüm restorantlarımız için hedefimiz 30-36 aydır. Geri dönüş hızını franchiseemizin etkin işletmecilik becerileri de pozitif etkilemektedir.</p>
                                <h2>4- Mimari konsept bilgisi ve standartlara uygun inşaat faaliyetleri hakkında bilgi ve destek veriyor musunuz?</h2>
                                <p>Evet, hem bilgi hem de yoğun destek vermekteyiz.</p>
                                <h2>5- Ben ve çalışanlarım herhangi bir eğitim alacak mıyız?</h2>
                                <p>Siz ve müdürleriniz 4 haftalık temel eğitime tabi tutulacaksınız. Diğer çalışanlarınız ise restaurant açılmadan 15 gün öncesinden eğitime başlayacak ve açılışa hazırlanacaklardır.</p>
                                <h2>6- Kaç metrekare alana ve kaç personele ihtiyacım olacak?</h2>
                                <p>Operasyonel anlamda rahat hareket alanı sağlamak adına 100-150 m2 arasında bir restoran ve sizin haricinizde 12 çalışan gerekmektedir.</p>
                                <h2>7- Tutulacak dükkânın iç ve dış özellikleri neler olmalı?</h2>
                                <p>Baca kullanılabilen, önünde motor park edebilecek yer olmalı, yaya veya araç trafiğinin yoğun olduğu ve görünebilir bir noktada olmalı, min 100 m2 ve üzeri olmalı, doğalgaz ve elektrik tesisatı yeterli olmalı, cephesinin 6 metre civarında olmasında fayda var, kolay ulaşılabilir bir noktada olmalı, resmi makamlarca ruhsat verilebilmelidir.</p>
                                <h2>8- Kiralanacak dükkânı siz mi buluyorsunuz?</h2>
                                <p>İş Geliştirme departmanımız hem danışmanlık hem de onay konusunda size yardımcı olacaktır.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default Sss;