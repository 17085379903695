import ReactGA from 'react-ga';
import { formatMoney, orderStateIds } from './utils';
import { getFromLocalStorage, setLocalStorage } from './storage';

let pandaSep = '-_-';

export const getCurrentUser = () => {
    const user = {
        currentUserId: getFromLocalStorage('userId'),
        isLoggedIn: getFromLocalStorage('isLoggedIn') != null ? getFromLocalStorage('isLoggedIn') : 'False',
        availablePunch: getFromLocalStorage('availablePunch') != null ? getFromLocalStorage('availablePunch') : '0.0'
    }  

    return user
}

export const addImpressionEvent = (products, pList, pCategory, productPager) => {
     const impressions = [];
     const user = getCurrentUser();
    if(!productPager) productPager = 0; 

    //For google analytics
    for(let i = 0; i < products.length; i++){
        const product = products[i];

        const impression = {
            'id': product.id,
            'name': product.name,
            'price': product.price ? product.price.price : product.amount,
            'quantity': 1, // Her ürün 1 kere görüntüleniyor
            'brand': 'Little Caesars',
            'category': pCategory !== '' ? pCategory : product.productType,
            'variant': '',
            'list': pList,
            'position': productPager * 12 + i + 1,
        };

     impressions.push(impression);
    }
   
    //For Tag Manager events
    window.dataLayer.push({
        'event': 'ecProductImpression',
        'eventCategory': 'Enhanced Ecommerce',
        'eventAction': 'Impression',
        'eventLabel': pList, // Liste İsmi
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web',
        'ecommerce': {
            'currencyCode': 'TRY', // Para birimi
            'impressions': impressions
        }
    });
}

export const addRegisterAction = label => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event':'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': 'Register',
        'eventLabel': label,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addLoginAction = label => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event':'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': 'Login',
        'eventLabel': label,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addLogoutAction = label => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event':'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': 'Logout',
        'eventLabel': label,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addChangePasswordAction = () => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event':'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': 'Click',
        'eventLabel': 'Sifremi Degistir',
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addAddAddressAction = () => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event':'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': 'Adres Ekle',
        'eventLabel': 'Lokasyon',
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addAddCreditCardAction = cardType => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event':'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': 'Kart Ekle',
        'eventLabel': cardType,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addClickEvent = ( eventLabel) => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event': 'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': 'Click',
        'eventLabel': eventLabel,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addActionEvent = (eventAction, eventLabel) => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event': 'GAEvent',
        'eventCategory': 'Functions',
        'eventAction': eventAction,
        'eventLabel': eventLabel,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addIngredientAction = (action, product) => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event': 'GAEvent',
        'eventCategory': 'Malzeme Ekle/Çıkar',
        'eventAction': action,
        'eventLabel': product.name,
        'eventValue': product.price.price,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addCouponAction = (action, label) => {
    const user = getCurrentUser();

    window.dataLayer.push({
        'event': 'GAEvent',
        'eventCategory': 'Kupon Kodu Kullan',
        'eventAction': action,
        'eventLabel': label,
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web'
    });
}

export const addClickProductEvent = ( product, position, pList, category = '') => {

   const user = getCurrentUser();

   //For Google Tag Mangaer(dataLayer) events
   window.dataLayer.push({
    'event': 'ecProductClick',
    'eventCategory': 'Enhanced Ecommerce',
    'eventAction': 'Click',
    'eventLabel': product.name,
    'userId': user.currentUserId,
    'loginStatus': user.isLoggedIn,
    'platform': 'Web',
    'ecommerce': {
        'currencyCode': 'TRY',
        'click': {
            'actionField': { 'list': pList },
            'products': [
                {
                    'name': product.name,
                    'id': product.id,
                    'price': formatMoney(product.price.price),
                    'brand': 'Little Caesars', // Marka İsmi
                    'category': category != '' ? category : product.productType, // Kategorisi
                    'variant': '', // Boş olabilir, ek istenen parametre varsa burada sayılabilir.
                    'quantity': 1, // Ürün adedi
                    'list': pList, // ”Liste Bilgisi”
                    'position': position, // Hangi sırada olduğu
                }
            ]
        }
    }
});
}

export const addProductDetailEvent = (product, position, pList, category = '') => {

    const user = getCurrentUser();

   //For Google Tag Manager
    window.dataLayer.push({
        'event': 'ecProductDetail',
        'eventCategory': 'Enhanced Ecommerce',
        'eventAction': 'Detail',
        'eventLabel': product.name, // Ürün İsmi
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web', // Android, iOS, Mobile
        'ecommerce': {
            'currencyCode': 'TRY', 
            'detail': {
                'actionField': { 'list': pList }, 
                'products': [
                    {
                        'name': product.name,
                        'id': product.id,
                        'price': formatMoney(product.price.price),
                        'brand': 'Little Caesars', 
                        'category': category != '' ? category : product.productType, // Kategorisi
                        'quantity': 1, 
                        'list': pList, 
                        'position': position, // Hangi sırada olduğu -- zaten ürün detayındayız
                    }
                ]
            }
        }
    });
}

export const addInternalPromotion = (slider, action, position = 1) => {
    
    const user = getCurrentUser();
   
    let payload = {};
    if (action === 'Promotion Click') {
        payload = {
            'promoClick': {
                'promotions': [
                    {
                        'id': slider.id,
                        'name': slider.name,
                        'creative': 'Home Page',
                        'position': 'anasayfa_banner_slide_' + position
                    }]
            }
        };

    } else {
        const promotions = [];
        for (let i = 0; i < slider.length; i++){
            const banner = slider[i];
            const pos = i + 1;
    
            const promotion = {
                id: banner.id,
                name: banner.name,
                creative: 'Home Page',
                position: 'anasayfa_banner_slide_' + pos
            }
    
            promotions.push(promotion)
        }
    
        payload = {
            'promoView': {
                promotions        
            }
        };
    }

    //For Google Tag Manager
    window.dataLayer.push({
        'event': 'ecInternalPromotion',
        'eventCategory': 'Internal Promotions',
        'eventAction': action, // Eğer görüntüleme ise Promotion Impression, eğer tıklama ise Promotion Click olarak değişmelidir.
        'eventLabel': 'Main Slider',
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn, 
        'platform': 'Web', // Android, iOS, Mobile
        'ecommerce': payload
    });
}


export const addCartOrderItemAction = (orderItem, position, action, category, list) => {
  
    const user = getCurrentUser();
    
    //Initilize Dimension parameters
    let dimensions= setDimensionParams(orderItem);
    let dimensionParams = {};

    for (let i = 0; i < dimensions.length; i++){
        dimensionParams[`dimension1${i + 1}`] = dimensions[i]
    }
   
    switch (action) {
        case 'add' : case 'increment': {
            window.dataLayer.push({
                'event': 'ecProductAddToCart',
                'eventCategory': 'Enhanced Ecommerce',
                'eventAction': 'Add To Cart',
                'eventLabel': orderItem.product, // Ürün İsmi
                'userId': user.currentUserId,
                'loginStatus': user.isLoggedIn,
                'platform': 'Web',
                'ecommerce': {
                    'currencyCode': 'TRY', // Para birimi
                    'add': {
                        'actionField': { 'list': list != '' ? list : orderItem.productType }, // Ürün Listesinin ismine göre değişecektir.
                        'products': [
                            {
                                'name': orderItem.product,
                                'id': orderItem.productId,
                                'price': formatMoney(orderItem.amount / orderItem.quantity),
                                'brand': 'Little Caesars', // Marka İsmi
                                'category':category !== null && category != '' ? category : orderItem.productType, // Kategorisi
                                'quantity': action === 'add' ? orderItem.quantity : 1, // Ürün adedi
                                'list': list != '' ? list : orderItem.productType, // Liste Bilgisi
                                'position': position, // Hangi sırada olduğu
                                'dimension16': getOrderItemExceptPizzaExceptIngredients(orderItem),
                                'dimension17': getOrderItemExtraIngredients(orderItem), // Hangi ekstra malzemelerin olduğu bilgisi 
                               ...dimensionParams                         
                            } 
                        ]
                    }
                }
            });

            break;
        }
        case 'remove' : case 'decrement': {
            window.dataLayer.push({
                'event': 'ecRemoveFromCart',
                'eventCategory': 'Enhanced Ecommerce',
                'eventAction': 'Remove From Cart',
                'eventLabel': orderItem.product, // Ürün İsmi
                'userId': user.currentUserId,
                'loginStatus': user.isLoggedIn,
                'platform': 'Web',
                'ecommerce': {
                    'currencyCode': 'TRY', // Para birimi
                    'remove': {
                        'actionField': { 'list': list != '' ? list : orderItem.productType }, // Ürün Listesinin ismine göre değişecektir.
                        'products': [
                            {
                                'name': orderItem.product,
                                'id': orderItem.productId,
                                'price': action === 'remove' ? formatMoney(orderItem.amount) : formatMoney(orderItem.amount / orderItem.quantity),
                                'brand': 'Little Caesars', // Marka İsmi
                                'category': category != '' ? category : orderItem.productType, // Kategorisi
                                'quantity': action === 'remove' ? orderItem.quantity : 1, // Ürün adedi
                                'list': list != '' ? list : orderItem.productType, // ”Liste Bilgisi”
                                'position': position, // Hangi sırada olduğu,
                                'dimension16': getOrderItemExceptPizzaExceptIngredients(orderItem),
                                'dimension17': getOrderItemExtraIngredients(orderItem), // Hangi ekstra malzemelerin olduğu bilgisi
                                 ...dimensionParams // Hangi ürün hangi hamur tipi olduğu bilgisi 
                            }
                        ]
                    }
                }
            });

            break;
        }
        default: window.dataLayer.push({

        });
    }
}

//Measuring Checkout process for Ecommerce 
export const addCheckoutTransaction = basket => {
    
    //load ecommerce plugin, once loaded it creates a transparent shopping cart object
    ReactGA.plugin.require('ecommerce'); 
   
    //add transaction data to the shopping cart
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        'id': basket.id,                  // Transaction ID. Required.
        'affiliation': 'Little Caesars',  // Affiliation or store name.
        'revenue': basket.amount,         // Grand Total.
        'shipping': '0.00',               // Shipping.
        'tax': '0.00' ,                   // Tax.
        'currency': 'TRY'                 // local currency code.
    });

    //add items to the shopping cart
    for (let i = 0; i < basket.orderItems.length; i++){
        const item = basket.orderItems[i]
        ReactGA.plugin.execute('ecommerce', 'addItem', {
            'id': basket.id,                         // Transaction ID. Required.
            'name': item.product,                    // Product name. Required.
            'sku': '',                               // SKU/code.
            'category': item.productType,            // Category or variation.
            'price': item.amount / item.quantity,    // Unit price.
            'quantity': item.quantity                // Quantity.
          });
    }
    
    //send data
    ReactGA.plugin.execute('ecommerce', 'send');

    //clear data
    ReactGA.plugin.execute('ecommerce', 'clear');
}

//Measuring Checkout process for Enhanced Ecommerce 
export const addCartTransaction = (basket, action, step = '1', extraData = null, coupon = null) => {

    const user = getCurrentUser();
    let products = [];

let isThereAGiftInTheBasket = false;
    for (let i = 0; i < basket.orderItems.length; i++) {
        const item = basket.orderItems[i];
        if(item.isGift == 'true'){
            isThereAGiftInTheBasket = true;
        }
        //Initilize Dimension parameters
        let dimensions = setDimensionParams(item);
        let dimensionParams = {};

        for (let i = 0; i < dimensions.length; i++) {
            dimensionParams[`dimension1${i + 1}`] = dimensions[i]
        }

        const product = {
            'name': item.product,
            'id': item.productId,
            'price': item.amount / item.quantity,
            'quantity': item.quantity,
            'brand': 'Little Caesars',
            'category': getFromLocalStorage(`pCategory${item.productId}`) != null ? getFromLocalStorage(`pCategory${item.productId}`) : item.productType ? item.productType : '',
            'variant': '',
            'list': getFromLocalStorage(`pList${item.productId}`) != null ? getFromLocalStorage(`pList${item.productId}`) : item.productType ? item.productType : '',
            'position': getFromLocalStorage(`prodPos${item.productId}`) != null ? parseInt(getFromLocalStorage(`prodPos${item.productId}`)) : '',
            'dimension16': getOrderItemExceptPizzaExceptIngredients(item),
            'dimension17': getOrderItemExtraIngredients(item),
            ...dimensionParams
        }

        if (product.price === 0) {
            setOrderSliceStatus(true);
        }

        products.push(product);
    }

    const branchCity = getFromLocalStorage('branchCity') !== null ? getFromLocalStorage('branchCity') : '';

    if (action === 'checkout') {
        window.dataLayer.push({
            'event': 'ecCheckout',
            'eventCategory': 'Enhanced Ecommerce',
            'eventAction': 'Checkout',
            'eventLabel': `Step ${step}`, // Birden fazla step bulunuyor ise step 1, step 2, step 3 şeklinde artacaktır.
            'userId': user.currentUserId,
            'loginStatus': user.isLoggedIn,
            'platform': 'Web', // Android, iOS, Mobile
            'branch': basket.store ? basket.store : '', // with pandaSeperator => -_- 
            'discountCode': parsePromo('name', basket),
            'discountRate': parsePromo('rate', basket), // -1 * basket.promotions[0].promotion.modifyValue || '',
            'discount': parsePromo('total', basket), // -1 * basketItems.promotions[0].amount || '',
            'ecommerce': {
                'currencyCode': 'TRY', // Para birimi
                'checkout': {
                    'actionField': { 'step': step },
                    products
                }
            }
        });
    }
    else if (action === 'purchase') {
        setSliceStatus(basket.amount);

        window.dataLayer.push({
            'event': 'ecPurchase',
            'eventCategory': 'Enhanced Ecommerce', // Sabit
            'eventAction': 'Purchase',
            'eventLabel': 'Purchase', // Ürün İsmi
            'orderType': extraData.orderType,
            'userId': user.currentUserId,
            'loginStatus': user.isLoggedIn,
            'platform': 'Web', // Android, iOS, Mobile
            'branch': basket.store ? basket.store : '', // with pandaSeperator => -_- 
            'discountCode': parsePromo('name', basket),
            'discountRate': parsePromo('rate', basket), // -1 * basket.promotions[0].promotion.modifyValue || '',
            'discount': parsePromo('total', basket), // -1 * basket.promotions[0].amount || '',
            'orderTime': extraData.orderTime,
            'paymentMethod': extraData.paymentMethod,
            'ecommerce': {
                'currencyCode': 'TRY', // Para birimi
                'purchase': {
                    'actionField': {
                        'id': basket.id, // Sipariş ID’si
                        'affiliation': '', // Boş olucaktır.
                        'revenue': basket.amount, // Toplam sepet tutarı. Nokta ile küsürat ayrımı yapılmalıdır.
                        'Tax': 0.0, // Vergi
                        'shipping': 0.0, // Kargo ücreti varsa doldurulacak, yoksa boş gelicektir.
                        'coupon': coupon // Kupon kullanıldıysa ismi gönderilecektir.
                    },
                    products
                }
            },
            'orderSliceStatus': isThereAGiftInTheBasket 
        });

        window.dataLayer.push({
            'event': 'GAEvent',
            'eventCategory': 'Enhanced Ecommerce',
            'eventAction': 'Success',
            'eventLabel': 'Basket',
            'userId': user.currentUserId,
            'loginStatus': user.isLoggedIn,
            'platform': 'Web', // Android, iOS, Mobile
        });
    }
    else if (action === 'error') {
        window.dataLayer.push({
            'event': 'GAEvent',
            'eventCategory': 'Enhanced Ecommerce',
            'eventAction': 'Failure', // Hata alındığında Failure olarak gelicektir
            'eventLabel': 'Hata oluştu.', // Hata Adı
            'userId': user.currentUserId,
            'loginStatus': user.isLoggedIn,
            'platform': 'Web', // Android, iOS, Mobile
        });
    }
};

export const sendPageView = (url, title) => {
    const user = getCurrentUser();
   
    window.dataLayer.push({
        'event': 'GAVirtual',
        'userId': user.currentUserId,
        'loginStatus': user.isLoggedIn,
        'platform': 'Web',
        'virtualPageUrl': url,
        'virtualPageTitle': title,
        'userSliceStatus': user.availablePunch 
    });
}

const formatExtraIngredients = extraIngredients => {
      
    let result = '';

       for (let i = 0; i < extraIngredients.length; i++){
           const extraIngredient = extraIngredients[i];
           result += extraIngredient.name + '_' + extraIngredient.quantity;

          if (i !== extraIngredients.length - 1) 
            result += pandaSep
            else
            result+= ''
       } 

       return result
}

const getOrderItemExtraIngredients = orderItem => {
    let extraIngredients = [];
    let result = '';
    
     if(orderItem.orderItems && orderItem.orderItems.length){
        let item;

        for (let i = 0; i < orderItem.orderItems.length; i++) {
             item = orderItem.orderItems[i];
            if (item && item.orderItems) {
                for (let k = 0; k < item.orderItems.length; k++) {
                    const itemOption = item.orderItems[k];
                    if (itemOption.productOption === 'Malzeme Seçimi') {
                        extraIngredients.push(itemOption);
                    }
                }
            }
        }

        for (let i = 0; i < extraIngredients.length; i++){
            const extraIngredient = extraIngredients[i];
            result += extraIngredient.product + '_' + extraIngredient.quantity/item.quantity;

           if (i !== extraIngredients.length - 1) 
             result += ' | ' 
             else
             result+= ''
        } 
    }  

       return result
};

const getOrderItemExceptPizzaExceptIngredients = orderItem => {
    let sides = [];
    let result = '';

    if (orderItem.orderItems && orderItem.orderItems.length) {
        let item;

        for (let i = 0; i < orderItem.orderItems.length; i++) {
            item = orderItem.orderItems[i];
            if (
                item.productType === 'Başlangıçlar'
                || item.productType === 'Tatlılar'
                || item.productType === 'Soslar'
                || item.productType === 'İçecekler'
            ) {
                sides.push(item);
            }
        }

        for (let i = 0; i < sides.length; i++) {
            const extraIngredient = sides[i];
            result += extraIngredient.product + '_' + extraIngredient.quantity/item.quantity;

            if (i !== sides.length - 1) {
                result += ' | ';
            } else {
                result+= '';
            }
        }
    }
    return result;
};

export const setDimensionParams = orderItem => {
    let dimensionParams = [];

    if(orderItem.orderItems && orderItem.orderItems.length){
        for (let i = 0; i < orderItem.orderItems.length; i++) {
            const item = orderItem.orderItems[i];

            if (item && item.orderItems) {
                for (let k = 0; k < item.orderItems.length; k++) {
                    const itemOption = item.orderItems[k];
                    
                    if (itemOption.productOption === 'Hamur Seçimi') {
                        let dimension = ''
                        dimension += `${item.product}`;
                        dimension += `${pandaSep}${itemOption.product}`;

                        //add item to dimensions array
                        dimensionParams.push(dimension);
                    }
                }
            }
        }
    } 

    return dimensionParams;
}

const getSliceStatus = () => {
    const user = getCurrentUser();
    const sliceStatus = getFromLocalStorage(`sliceStatus${user.id}`);
   
    return sliceStatus;
}

const setSliceStatus = orderTotalAmount => {
    const user = getCurrentUser();
    const currentSliceStatus = getFromLocalStorage(`sliceStatus${user.id}`) !== null ? getFromLocalStorage(`sliceStatus${user.id}`) : 0;
   
    if(orderTotalAmount >= 30)
     setLocalStorage(`sliceStatus${user.id}`, currentSliceStatus + 1);
}

const getOrderSliceStatus = () => {
    const user = getCurrentUser();
    let orderSliceStatus = '';

   if (getFromLocalStorage(`sliceStatus${user.id}`) >= 6 && (getFromLocalStorage(`orderSliceStatus${user.id}`) !== 'used')){
       orderSliceStatus = 'kullanılmadı'
   }
   else if(getFromLocalStorage(`sliceStatus${user.id}`) >= 6 && (getFromLocalStorage(`orderSliceStatus${user.id}`) === 'used')){
    orderSliceStatus = 'kullanıldı'
    setLocalStorage(`sliceStatus${user.id}`, 0);
   }
   else
   orderSliceStatus = ''

   return orderSliceStatus;
}

const setOrderSliceStatus = isUsed => {
    const user = getCurrentUser();

    if(isUsed){
        setLocalStorage(`orderSliceStatus${user.id}`, 'used');
    }
}

export const parsePromo = (part, basketItems) => {
    if (part === 'name') {
        if (basketItems && basketItems.promotions && basketItems.promotions[0]) {
            return basketItems.promotions[0].promotion.name || '';
        } else {
            return '';
        }    
    } else if (part === 'rate') {
        if (basketItems && basketItems.promotions && basketItems.promotions[0]) {
            return -1 * basketItems.promotions[0].promotion.modifyValue; 
        } else {
            return 0;
        } 
    } else if (part === 'total') {
        if (basketItems && basketItems.promotions && basketItems.promotions[0]) {
            return -1 * basketItems.promotions[0].amount || 0;
        } else {
            return 0;
        } 
    } else {
        return '';
    }
}
