import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import LcDropdown from '../elements/LcDropdown';
import Icon from '../elements/Icon';

class ProfileDropdownMenu extends Component {

    linksList = [
            {
                key: 'sezarla-kazan',
                label: 'Sezarla Kazan',
                link: '/sezarla-kazan',
                icon: 'loyalty-sidebar-icon'
            },
            {
                key: 'hesap',
                label: 'Profilim',
                link: '/profil/hesap',
                icon: 'user'
            },
            {
                key: 'favoriler',
                label: 'Favorilerim',
                link: '/profil/favoriler',
                icon: 'favorites'
            },
            {
                key: 'siparisler',
                label: 'Siparişlerim',
                link: '/profil/siparisler',
                icon: 'orders'
            },
            {
                key: 'siparis-takip',
                label: 'Sipariş Takip',
                link: '/profil/siparis-takip',
                icon: 'order-tracking'
            },
            {
                key: 'adresler',
                label: 'Adreslerim',
                link: '/profil/adresler',
                icon: 'pin'
            },
            {
                key: 'kayıtlı-kartlarım',
                label: 'Kayıtlı Kartlarım',
                link: '/profil/kayıtlı-kartlarım',
                icon: 'credit-card'
            },
        ];

    state = {
        activeLink: '',
        activeIcon: ''
    }

    componentDidMount() {
       this.getActiveLink();
    };

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (prevProps.location.pathname != location.pathname) {
            this.getActiveLink();
        }
    };

    getActiveLink = () => {
        const { location } = this.props;
        this.linksList.forEach(item => {
            if (location.pathname === item.link) {
                this.setState({ 
                    activeLink: item.label,
                    activeIcon: item.icon 
                });
            }
        });
    };

    renderMenuLinks = item => (
        <div className="profile-dropdown-menu__links">
            <div className="profile-dropdown-menu__links--item">
                <Icon name={item.icon}/>
            </div>
            <div className="profile-dropdown-menu__links--item">
                {item.label}
            </div>            
        </div>
    );

    selectLink = item => {
        const { history } = this.props;

        history.push(item.link);
        this.setState({ 
            activeLink: item.label,
            activeIcon: item.icon
        });
    };

    render() {
        const { activeLink, activeIcon } = this.state;

        return <div className="profile-dropdown-menu">
                 <LcDropdown value={activeLink} 
                             suggestions={this.linksList} 
                             firstIconName={activeIcon}
                             renderSuggestion={this.renderMenuLinks}
                             onSelect={this.selectLink} />
               </div>
    }
}

export default withRouter(ProfileDropdownMenu);