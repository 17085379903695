import React from 'react';
import Icon from '../elements/Icon';
import { formatMoney } from '../../utils';
import OrderItemDetails from './OrderItemDetails';
import PropTypes from 'prop-types';

const OrderItem = ({ orderItem, onIncrementClick, onDecrementClick, deleteClassName, onDeleteOrderItemClick }) => {
    return (
        <div key={orderItem.id} className="basket-modal__order-item">
            <div className="basket-modal__order-item-delete">
                <Icon name="cancel"
                      className={deleteClassName}
                      onClick={onDeleteOrderItemClick}
                />
            </div>
            <div className="basket-modal__order-item-top">
                <div className="basket-modal__order-item-image">
                    <img src={orderItem.image} alt={`${orderItem.product}.png`}/>
                </div>
                <div className="basket-modal__order-item-top-title">
                    <div className="basket-modal__order-item-name">
                        {orderItem.product}
                    </div>
                    {
                        orderItem.isGift === 'false'
                            ? <div className="basket-modal__order-item-top-title-amount-price">
                                <div className="basket-modal__order-item-count-buttons">
                                    <div onClick={onDecrementClick}
                                         className="basket-modal__order-item-count-button basket-modal__order-item-count-button--minus"
                                    >
                                        -
                                    </div>
                                    <div className="basket-modal__order-item-count-number">{orderItem.quantity}</div>
                                    <div onClick={onIncrementClick}
                                         className="basket-modal__order-item-count-button basket-modal__order-item-count-button--plus"
                                    >
                                        +
                                    </div>
                                </div>
                                <div className="basket-modal__order-item-price"> {formatMoney(orderItem.amount)} TL</div>
                            </div>
                            : null
                    }
                </div>
            </div>
            {
                orderItem.detail
                    ? <div className="order-item-detail">{orderItem.detail}</div>
                    : orderItem.orderItems
                    ? orderItem.orderItems.map(item =>
                        <OrderItemDetails key={item.id} item={item}/>
                    )
                    : null
            }
        </div>
    );
};

OrderItem.propTypes = {
    orderItem: PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        detail: PropTypes.string,
        item: PropTypes.object
    }).isRequired,
    onIncrementClick: PropTypes.func.isRequired,
    onDecrementClick: PropTypes.func.isRequired,
    onDeleteOrderItemClick: PropTypes.func.isRequired,
    deleteClassName: PropTypes.string.isRequired
};

export default OrderItem;