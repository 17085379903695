import React from 'react';

const Delivery = props =>
    <svg width="24" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M4.92139 5H6.88841V5.81552C6.88841 5.91754 6.971 6.00043 7.07317 6.00043H7.50135C7.60356 6.00043 7.68622 5.9175 7.68622 5.81552V5H9.65313C9.80063 5 9.92032 5.11962 9.92032 5.26712V9.46446C9.92032 9.61208 9.80063 9.73158 9.65313 9.73158H4.92139C4.77392 9.73158 4.65434 9.61212 4.65434 9.46446V5.26709C4.65434 5.11962 4.77392 5 4.92139 5Z" />
        <path d="M7.66127 19.4559C8.85608 19.4559 9.82817 18.4839 9.82817 17.2889C9.82817 16.0938 8.85608 15.1217 7.66127 15.1217C6.46616 15.1217 5.494 16.0938 5.494 17.2889C5.494 18.4839 6.4662 19.4559 7.66127 19.4559ZM7.66127 16.2138C8.25392 16.2138 8.73632 16.6961 8.73632 17.2889C8.73632 17.8815 8.25392 18.364 7.66127 18.364C7.06843 18.364 6.58615 17.8815 6.58615 17.2889C6.58619 16.6961 7.06843 16.2138 7.66127 16.2138Z" />
        <path d="M19.6778 19.4562C20.8726 19.4562 21.8448 18.4842 21.8448 17.2892C21.8448 16.0942 20.8726 15.1221 19.6778 15.1221C18.4829 15.1221 17.5107 16.0942 17.5107 17.2892C17.5107 18.4842 18.4829 19.4562 19.6778 19.4562ZM19.6778 16.2142C20.2706 16.2142 20.7529 16.6965 20.7529 17.2892C20.7529 17.8819 20.2706 18.3643 19.6778 18.3643C19.085 18.3643 18.6027 17.8819 18.6027 17.2892C18.6027 16.6965 19.085 16.2142 19.6778 16.2142Z" />
        <path d="M6.62622 13.0476V12.4538H5.11566C4.77384 12.4538 4.49669 12.1769 4.49669 11.8351V11.3946C4.49669 11.053 4.77384 10.7759 5.11566 10.7759H12.6866C13.0284 10.7759 13.3056 11.053 13.3056 11.3946V11.8416C13.3056 12.1833 13.0284 12.4604 12.6866 12.4604H11.8496V14.8385H16.0436C16.3509 14.3593 16.7537 13.9472 17.2251 13.6288L15.5754 7.67896H14.0506C13.7089 7.67896 13.4319 7.40177 13.4319 7.06007C13.4319 6.71844 13.7089 6.4414 14.0506 6.4414C14.0506 6.4414 15.6548 6.4414 16.2179 6.4414C16.2949 6.4414 16.4292 6.47371 16.495 6.50617C16.5904 6.55371 16.6737 6.62552 16.7345 6.71683L21.125 13.299C21.2558 13.4918 21.3523 13.7341 21.2801 13.9469C21.1679 14.2776 20.7887 14.3959 20.4002 14.2806C20.1051 14.1932 19.8602 14.1549 19.5487 14.1549C17.9367 14.1549 16.6252 15.4664 16.6252 17.0785C16.6252 17.4202 16.3484 17.6973 16.0066 17.6973H11.2033C10.8616 17.6973 10.5844 17.4202 10.5844 17.0785C10.5844 15.4664 9.27316 14.1549 7.66117 14.1549C6.04918 14.1549 4.7376 15.4664 4.7376 17.0785C4.7376 17.4202 4.46041 17.6973 4.11886 17.6973C3.77693 17.6973 3.5 17.4202 3.5 17.0785C3.5 15.1411 4.83094 13.5088 6.62622 13.0476Z" />
    </svg>;

export default Delivery;
