import moment from 'moment';
import { addLogoutAction } from './googleAnalyticsHelper';
import React from 'react';
import { setLocalStorage, getFromLocalStorage, removeFromLocalStorage } from './storage';

export const MIN_DESKTOP_WIDTH = 1171;

export const isMobile = () => window.outerWidth < MIN_DESKTOP_WIDTH;

export const isClient = () => typeof window !== 'undefined';

export const formatDistance = distance => {
    if (distance > 999) {
        return `${(distance / 1000).toFixed(1)}km`;
    }
    return `${distance}m`;
};

export const formatMoney = amount => amount.toFixed(2);

export const formatString = text => text.replace(',', '.');

export const formatErrorMessage = error => {
    let message = error;
    if (error.props) {
        message = error.props.children;
    }
    message = message.trim();

    return message[0].toUpperCase() + message.slice(1).toUpperCase();
};

export const formatLink = link => link[0] === '/' ? link : `/${link}`;

export const formatDate = date =>
    moment(date).format('DD.MM.YYYY');

export const formatDateTime = date =>
    moment(date).format('DD.MM.YY HH:mm');

export const calculateDateTimeBefore = date => {
    const givenDate = moment(formatDateTime(date), 'DD.MM.YY HH:mm');
    let currentDate = moment().startOf('minutes');

    return moment.duration(currentDate.diff(givenDate)).asMinutes() < 60
        ? moment.duration(currentDate.diff(givenDate)).asMinutes() + ' dakika önce'
        : Math.floor(moment.duration(currentDate.diff(givenDate)).asHours()) < 24
            ? Math.floor(moment.duration(currentDate.diff(givenDate)).asHours()) + ' saat önce'
            : Math.floor(moment.duration(currentDate.diff(givenDate)).asDays()) < 365
                ? Math.floor(moment.duration(currentDate.diff(givenDate)).asDays()) + ' gün önce'
                : Math.floor(moment.duration(currentDate.diff(givenDate)).asYears()) + ' yıl önce';
};


export const formatDateTimeAlt = date => moment(date).format('DD dddd MMMM HH:mm');

export const DEFAULT_CENTER = [41.015137, 28.979530]; // Istanbul
export const DEFAULT_ZOOM = 14;
export const GOOGLE_MAPS_API_KEY = { key: 'AIzaSyAWZbmv7qPMJCzOe_TTmqmageNo0RMHWFo' };
export const TAKE_AWAY_ORDER_TYPE = 1;
export const DELIVERY_ORDER_TYPE = 2;
export const VEHICLE_DELIVERY_ORDER_TYPE = 8;

export const getDeliveryTypeId = type => type === 'Adrese Teslim' ? DELIVERY_ORDER_TYPE : type === 'Araca Teslim' ? VEHICLE_DELIVERY_ORDER_TYPE : TAKE_AWAY_ORDER_TYPE;

export const getOrderDeliveryType = id =>

    TAKE_AWAY_ORDER_TYPE === +id ? 'Gel Al'
        :
        DELIVERY_ORDER_TYPE === +id ? 'Adrese Teslim'
            :
            VEHICLE_DELIVERY_ORDER_TYPE === +id ? 'Araca Teslim'
                :
                '';

export const logOut = () => {
    setLocalStorage('authToken', null);
    if (getFromLocalStorage('basketId')) {
        removeFromLocalStorage('basketId');
    }
    if (getFromLocalStorage('fbavatar')) {
        removeFromLocalStorage('fbavatar');
    }
    //for google analytics
    setLocalStorage('isLoggedIn', 'False');
    clearLocalStorage();
    addLogoutAction('Gmail');
    window.location.assign(window.location.origin);
};

export const orderStateIds = {
    orderTaken: [0, 1, 13, 19],
    preparing: [4, 21],
    ready: [5],
    inDelivery: [6],
    delivered: [10],
    laterDeliveryDate: [11],
    cancelled: [7, 8, 9],
    customerArrived: [22]
};

export const getOrderDeliveryTypeName = typeId => {
    if (+typeId === +DELIVERY_ORDER_TYPE) {
        return 'Adrese Teslim';
    } else if (+typeId === +TAKE_AWAY_ORDER_TYPE) {
        return 'Gel Al';
    } else if (+typeId === +VEHICLE_DELIVERY_ORDER_TYPE) {
        return 'Araca Teslim';
    } else {
        return '';
    }
};

export const extraProductOptionType = '3';

export const doRedirect = (redirect, history, setModal) => {
    if (redirect.value) {
        if (redirect.type === 'url') {
            history.push(redirect.value);
        } else if (redirect.type === 'modal' && setModal) {
            setModal(redirect.value);
        }
    }
};

export const getRestaurantLocation = restaurant => {
    if (restaurant.latitude && restaurant.longitude) {
        return { lat: +restaurant.latitude, lng: +restaurant.longitude };
    }
    return null;
};

export const getRestaurantWorkingTimes = restaurant => {
    let currentMoment = moment();
    const restaurantWorkingTime = restaurant.storeWorkingTimes.find(item => item.dayOfWeek === currentMoment.day());

    if (!restaurantWorkingTime) {
        return 'zaman belirtilmedi';
    }

    const startTime = moment(restaurantWorkingTime.startTime, 'DD.MM.YYYY hh:mm:ss').format('HH:mm');
    const endTime = moment(restaurantWorkingTime.endTime, 'DD.MM.YYYY hh:mm:ss').format('HH:mm');

    return `${startTime} - ${endTime}`;
};

export const isRestaurantOpenAtNight = restaurant => {
    let isOpen = false;
    let currentMoment = moment();
    const restaurantWorkingTime = restaurant.storeWorkingTimes.find(item => item.dayOfWeek === currentMoment.day());

    if (!restaurantWorkingTime) {
        return 'zaman belirtilmedi';
    }

    const endTime = moment(restaurantWorkingTime.endTime, 'DD.MM.YYYY hh:mm:ss').format('hh:mm');

    if (parseFloat(endTime) >= 10 || parseFloat(endTime) <= 3)
        isOpen = true;

    return isOpen;
};

export const maxIngredientCount = 2;

const getAddedExtraToppings = item => {
    let addedExtraToppings = [];

    item && item.orderItems && item.orderItems.map(orderItem => {
        if (orderItem.productOption === 'Malzeme Seçimi' && orderItem.quantity >= 1) {
            let orderItemName = orderItem.quantity > 1
                ? `${orderItem.quantity}x ${orderItem.product.toLowerCase()}`
                : orderItem.product.toLowerCase();
            addedExtraToppings.push(orderItemName);
        }
    });

    return addedExtraToppings.join(', ');
};

const getRemovedToppings = item => {
    let removedToppings = [];

    item && item.orderItems && item.orderItems.map(orderItem => {
        if (orderItem.productOption === 'Malzeme Seçimi' && orderItem.quantity == -1) {
            removedToppings.push(orderItem.product);
        }
    });

    return removedToppings.join(', ');
};

const getCrustSelection = item => {
    let crustSelection = [];

    item && item.orderItems && item.orderItems.map(orderItem => {
        if (orderItem.productOption === 'Hamur Seçimi') {
            crustSelection.push(orderItem.product);
        }
    });

    return crustSelection.join(', ');
};

export const renderOrderItemDetailString = (item, withProductName = true) => {
    let productName = item.quantity > 1 ? `${item.quantity}x ${item.product}` : item.product;
    let orderItemString = '';
    if (item.orderItems && item.orderItems.length) {
        item.orderItems.forEach((orderItem, index) => {
            orderItemString += orderItem.quantity > 1
                ? `${orderItem.quantity}x ${orderItem.product}`
                : orderItem.product;

            let addedExtraToppings = getAddedExtraToppings(orderItem);
            let removedToppings = getRemovedToppings(orderItem);
            let crustSelection = getCrustSelection(orderItem);

            if (removedToppings || addedExtraToppings || crustSelection)
                orderItemString += ' (';

            if (removedToppings !== '')
                orderItemString += `istemiyorum "${removedToppings}", `;

            if (addedExtraToppings !== '')
                orderItemString += `Ekstra "${addedExtraToppings}" ile, `;

            if (crustSelection !== '')
                orderItemString += crustSelection;

            if (removedToppings !== '' || addedExtraToppings !== '' || crustSelection !== '')
                orderItemString += ')';

            if (index < item.orderItems.length - 1)
                orderItemString += ' + ';
        });
        if (withProductName) {
            return productName + ', ' + orderItemString;
        } else {
            return orderItemString;
        }
    } else {
        if (withProductName) {
            return productName;
        }
    }
};

export const clearLocalStorage = () => {

    //Clear localstorage with certin key prefixes
    let arr = [];

    //Iterate over localStorage and insert the keys that meet the condition into arr
    for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).substring(0, 5) === 'pList' || localStorage.key(i).substring(0, 7) === 'prodPos' ||
            localStorage.key(i).substring(0, 9) === 'pCategory' || localStorage.key(i) === 'mainPageCampaigns' ||
            localStorage.key(i) === 'promoImage' || localStorage.key(i) === 'branchCity' || localStorage.key(i) === 'ecProducts' ||
            localStorage.key(i) === 'favoriteProducts' || localStorage.key(i) === 'favoriteOrders' || localStorage.key(i) === 'flavorsSuggestions') {
            arr.push(localStorage.key(i));
        }
    }

    //Iterate over arr and remove the items by key
    for (let i = 0; i < arr.length; i++) {
        removeFromLocalStorage(arr[i]);
    }
};

export const replaceTurkishChars = inputText => {
    return inputText.replace('Ğ', 'g')
        .replace('Ü', 'u')
        .replace('Ş', 's')
        .replace('I', 'i')
        .replace('İ', 'i')
        .replace('Ö', 'o')
        .replace('Ç', 'c')
        .replace('ğ', 'g')
        .replace('ü', 'u')
        .replace('ş', 's')
        .replace('ı', 'i')
        .replace('ö', 'o')
        .replace('ç', 'c');
};

export const LoginOptions = {
    auth_type: '',
    scope: '',
    return_scopes: '',
    enable_profile_selector: '',
    profile_selector_ids: ''
};

export const selectInputText = event => event.target.select();

export const formatTownName = restaurant => {
    const townName = replaceTurkishChars(restaurant.townName).replace(/[^a-zA-Z0-9-_-]/g, '');

    return townName.toLowerCase();
};

export const formatRestaurantName = restaurant => {
    const restaurantName = replaceTurkishChars(restaurant.name).split(' ').join('-').replace(/[^a-zA-Z0-9-_-]/g, '');

    return restaurantName.toLowerCase();
};


export const getRef = (instance, refName) => {
    if (instance[refName]) {
        return instance[refName];
    }
    instance[refName] = React.createRef();
    return instance[refName];
};

export const MIN_ORDER_PRICE_DELIVERY = 24.9;
export const MIN_ORDER_PRICE_OTHER = 1;

export const MFS = window.MFS;
MFS.setAddress('https://ui.masterpassturkiye.com/v2');
export const CLIENT_ID = 34706823; //34706320;
export const MACRO_MERCHANT_ID = '06823207920930110100429'; // "06320207920826111143140";

export const setAmountforMasterPass = amount => {
    let [firstPart, secondPart] = `${formatMoney(amount)}`.split('.');
    return firstPart + secondPart;
};

export const numberRegExp = /^[0-9\b]+$/;

export const scrollToWrongField = (instance, validator, scrollingElement, offset = 0, fieldToIgnore) => {
    const wrongFields = Object.keys(validator.fields).filter(key => !validator.fields[key]);
    let topWrongFieldName = wrongFields[0];
    if (topWrongFieldName === fieldToIgnore) {
        if (wrongFields.length <= 1) {
            return;
        }
        topWrongFieldName = wrongFields[1];
    }
    wrongFields.map(fieldName => {
        if (fieldToIgnore === fieldName) {
            return;
        }
        if (instance[fieldName] && instance[fieldName].current.offsetTop < instance[topWrongFieldName].current.offsetTop) {
            topWrongFieldName = fieldName;
        }
    });
    const scrollTop = typeof scrollingElement.scrollTop === 'number' ? scrollingElement.scrollTop : scrollingElement.pageYOffset;
    const height = typeof scrollingElement.offsetHeight === 'number' ? scrollingElement.offsetHeight : scrollingElement.innerHeight;
    const topWrongField = instance[topWrongFieldName];
    const topWrongFieldScrollTop = topWrongField ? topWrongField.current.offsetTop - offset : 0;
    if (topWrongFieldScrollTop > scrollTop + offset && topWrongFieldScrollTop < scrollTop + height - offset) {
        return; // If element is visible on screen, dont scroll
    }
    scrollingElement.scrollTo({
        top: topWrongFieldScrollTop,
        behavior: 'smooth'
    });
};

export const appHeaderHeight = 80;

export const isJson = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const maskCreditCardNumber = string => {
    let array = string.split('')
        .map((digit, index) => {
            if (index > 0 && index % 4 === 0) {
                return ` ${digit}`;
            }
            return digit;
        });
    return array.join('');
};

export const removeAllSpaces = string => string.replace(/ /g, '');

export const hideMobileMenu = () => {
    const element = document.querySelector('.footer-mobile-menu');
    if (element && isMobile()) {
        element.classList.add('hidden');
    }
};

export const showMobileMenu = () => {
    const element = document.querySelector('.footer-mobile-menu');
    if (element && isMobile()) {
        element.classList.remove('hidden');
    }
};

export const getUserPermissionValue = (user, name) =>
    user.marketingPermission.find(permission => permission.marketingPermissionTypeString === name).approved;

export const stopPropagation = event => event.stopPropagation();

export const getGiftProductsAmount = user =>
    Math.floor(+user.loyalty.punchCard.availablePunch / +user.loyalty.punchCard.requiredToGetGift);

export const isGiftAddedInBasket = (basket, user) =>
    !!basket.orderItems.find(product => product.productId === user.loyalty.punchCard.canOrderGiftProductID);

export const shouldShowGiftProducts = (basket, user) =>
    user.loyalty && user.loyalty.punchCard
    && user.loyalty.punchCard.canGetGift === 'true'
    && (!isGiftAddedInBasket(basket, user) || getGiftProductsAmount(user) > 1);

export const getOrderLoyaltyBlockText = (basket, user) => {
    let loyaltyBlockText = null;
    if (user.id && user.loyalty && user.loyalty.punchCard  ) {
        if (shouldShowGiftProducts(basket, user)) {
            loyaltyBlockText = 'Sezarla Kazan ile hediye pizzanı sepete eklemek ister misin?';
        } else if (user.loyalty.punchCard.canGetGift === 'false' && basket.amount < user.loyalty.punchCard.minOrderAmountToEarnPunch && user.loyalty.punchCard.nonConfirmedPunch === "0.0") {
            loyaltyBlockText = `Sepetine ${formatMoney(user.loyalty.punchCard.minOrderAmountToEarnPunch - basket.amount)} TL'lik ürün daha ekle, bizden 1 dilim kazan.`;
        }
    }
    return loyaltyBlockText;
};

export const hashKey = '123123';    // This is not secure. This is just to hide password from direct reading from localstorage.
