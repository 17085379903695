import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser } from '../../../store/actions/authActions';
import {
    getUserDeliveryAddresses,
    deleteUserDeliveryAddress,
    setAddressSelection
} from '../../../store/actions/addressActions';
import { setModal } from '../../../store/actions/modalActions';
import Button from '../../elements/Button';
import RemoveAddressPopup from './RemoveAddressPopup';

class ProfileAddresses extends Component {
    state = {
        addressIdToRemove: null
    };

    componentDidMount() {
        const { addressData, boundGetUserDeliveryAddresses } = this.props;

        if (!addressData.userDeliveryAddresses) {
            boundGetUserDeliveryAddresses();
        }
    }

    showAddAddressModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('add-address');
    };

    showEditAddressModal = (addressId, id) => () => {
        const { boundSetModal, user } = this.props;
        if (user.personPhones[0].confirmed) {                                     //    if user doesn't verified phone
            boundSetModal(`edit-address/${addressId}/${id}`);
        }
    };

    deleteAddress = () => {
        const {
            boundGetUserDeliveryAddresses, boundDeleteUserDeliveryAddress, boundSetAddressSelection, addressData
        } = this.props;
        const { addressIdToRemove } = this.state;
        boundDeleteUserDeliveryAddress(addressIdToRemove)
            .then(() => {
                boundGetUserDeliveryAddresses();
                if (addressData.addressSelection.addressId === addressIdToRemove) {
                    boundSetAddressSelection({});
                }
                this.closeRemoveAddressPopup();
            });
    };

    getOpenRemoveAddressPopup = addressId => () => this.setState({ addressIdToRemove: addressId });

    closeRemoveAddressPopup = () => this.setState({ addressIdToRemove: null });

    render() {
        const { addressData, user } = this.props;
        const { addressIdToRemove } = this.state;

        return <div className="profile-address profile__page">
            <div className="profile-address__header">
                <div className="profile__header profile-address__title">Adreslerim</div>
                <div className="profile-address__header-button-container">
                    <Button color="orange" className="profile-address__header-button"
                            onClick={this.showAddAddressModal}
                    >
                        yeni adres ekle +
                    </Button>
                </div>
            </div>
            {
                addressIdToRemove
                    ? <RemoveAddressPopup submit={this.deleteAddress}
                                        close={this.closeRemoveAddressPopup}
                    />
                    : null
            }
            <div>
                {
                    addressData.userDeliveryAddresses
                        ? addressData.userDeliveryAddresses.slice().reverse().map(address =>
                            <div className="profile-address__item" key={address.addressId}>
                                <div className="profile-address__item-header">
                                    <div className="profile-address__item-name">{address.address.name}</div>
                                </div>
                                <div className="profile-address__item-body">
                                    <div className="profile-address__item-left">
                                        <div className="profile-address__item-phone">
                                            <span>Tel: </span>{user.personPhones[0].number}
                                        </div>
                                        <div className="profile-address__item-address">{address.address.streetAddress}</div>
                                        <div className="profile-address__item-note">
                                            <span>Not: </span>{address.address.landmark}
                                        </div>
                                    </div>
                                    <div className="profile-address__item-right">
                                        <div className="profile-address__button-delete"
                                             onClick={this.getOpenRemoveAddressPopup(address.addressId)}
                                        >
                                            SİL
                                        </div>
                                        <div className=" profile-address__button"
                                             onClick={this.showEditAddressModal(address.addressId, address.id)}
                                        >
                                            <span className="button__text">Düzenle</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    boundGetUser: bindActionCreators(getUser, dispatch),
    boundGetUserDeliveryAddresses: bindActionCreators(getUserDeliveryAddresses, dispatch),
    boundDeleteUserDeliveryAddress: bindActionCreators(deleteUserDeliveryAddress, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch)
});

ProfileAddresses.propTypes = {
    boundGetUser: PropTypes.func.isRequired,
    boundGetUserDeliveryAddresses: PropTypes.func.isRequired,
    boundDeleteUserDeliveryAddress: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    addressData: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAddresses);
