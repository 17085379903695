import React from 'react';
import Icon from '../../elements/Icon';

const FavoriteNotFound = ({myFavorites, onClick}) => {
    return <div className="profile-favorites__body">
    <div className="profile-favorites__body-icon">
        <div className="big-icon-background">
            <Icon name="big-heart" />
        </div>
    </div>
    <div className="profile-favorites__body-text">Kayıtlı Favori {myFavorites} bulunmamaktadır.</div>
    <div className="product-preview__buttons-container product-preview__block">
        <div className="button button--light link product-preview__button"
            onClick={onClick} >
            <span className="button__text">sipariş ver</span>
        </div>
    </div>
</div>
}

export default FavoriteNotFound;