import React from 'react';
import PropTypes from 'prop-types';

const DeliveryAddressOption = ({ address, onSelectAddressClick }) => 
 
 <div className="delivery-address-modal__body-item">
    <div className="delivery-address-modal__body-item-info">
        <div className="delivery-address-modal__body-item-info-title">{address.address.name}</div>
        <div className="delivery-address-modal__body-item-info-details">{address.address.streetAddress}</div>
    </div>

    <div className="delivery-address-modal__body-item-button" onClick={onSelectAddressClick}>
       <div className="button-text"><span>Seç</span></div> 
    </div>
</div>


DeliveryAddressOption.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        streetAddress: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  onSelectAddressClick: PropTypes.func.isRequired
}

export default DeliveryAddressOption;