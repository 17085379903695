import React from 'react';
import { sendPageView } from '../../../googleAnalyticsHelper';

const CookiePolicy = () => {
    sendPageView('/musteri-hizmetleri/cerez-politikasi', 'Çerez Politikası - Little Caesars');
    return <section className="institutional-structures">
            <div className="container">
                {/* <app-breadcrumbs></app-breadcrumbs> */}
                <div className="institutional-structures-detail  bg-white2 ">
                    <div className="institutional-structures-detail-content clearfix">
                        {/* <!--<app-customerservicemenu></app-customerservicemenu>--> */}
                        <div className="col-md-12">
                            <div className="corpareta-detail">
                                <h1>Çerez ve Gizlilik Politikası Aydınlatma Metni Hakkında</h1>
                                <p>Bu politika kapsamında &ldquo;&Ccedil;erez&rdquo; kavramı, &ccedil;erez ve benzer amaca hizmet eden &uuml;r&uuml;nleri ifade etmektedir.</p>
<ol>
<li><strong>&Ccedil;erez Nedir?</strong></li>
</ol>
<p>&Ccedil;erez, bir siteyi ziyaret ettiğinizde sitenin bilgisayarınıza veya mobil cihazınıza kaydettiği k&uuml;&ccedil;&uuml;k boyutlu bir metin dosyasıdır. &Ccedil;erezler bir web sitesinin &ccedil;alışması, daha verimli &ccedil;alışması veya web sitesinin sahiplerine bilgi sağlamak i&ccedil;in yaygın olarak kullanılmaktadır.</p>
<p>&Ccedil;erezler hakkında daha detaylı bilgi i&ccedil;in <a href="http://www.aboutcookies.org">http://www.aboutcookies.org</a>&nbsp; ve <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>&nbsp; adreslerini ziyaret edebilirsiniz.</p>
<ol start="2">
<li><strong>Ne i&ccedil;in kullanılır?</strong></li>
</ol>
<p>&Ccedil;erezler bilgisayarlarda, telefonlarda ve diğer cihazlarda sizi tanımlayan bilgileri ve siteyi kullanırken yaptığınız hareketleri, kullanım alışkanlıklarınızı toplamak, tutmak ve devamında internet sitemizi iyileştirmek ve size &ouml;zel kişiselleştirmek i&ccedil;in kullanılır.</p>
<p>Biz de &Ccedil;elebi Hizmet Gıda İşletmeleri olarak&nbsp;<a href="http://www.littlecaesars.com.tr">www.littlecaesars.com.tr</a> internet sayfasında, site i&ccedil;eriği ile nasıl etkileşime ge&ccedil;tiğinizi tespit etmek ve kullanıcı deneyiminizi geliştirebilmek i&ccedil;in &ccedil;eşitli &ccedil;erezler kullanmaktayız.</p>
<p>Web sitemizde kullanılan &ccedil;erezler hakkında daha detaylı bilgi edinmek i&ccedil;in bu politikanın 7. Maddesindeki <strong>&Ccedil;erez Tablomuzu</strong> inceleyebilirsiniz.</p>
<ol start="3">
<li><strong>Birinci Taraf ve &Uuml;&ccedil;&uuml;nc&uuml; Taraf &Ccedil;erezler</strong></li>
</ol>
<p>&Ccedil;elebi Hizmet Gıda İşletmeleri Turizm San. ve Tic. A.Ş&rsquo; ye ait&nbsp;<a href="http://www.littlecaesars.com.tr">www.littlecaesars.com.tr</a> internet sayfasında hem birinci taraf hem de &uuml;&ccedil;&uuml;nc&uuml; taraf &ccedil;erezler kullanılmaktadır.</p>
<p>Birinci taraf &ccedil;erezler, şirketimiz tarafından littlecaesars.com.tr &nbsp;domainine yerleştirilmiş ve sadece <strong>&Ccedil;elebi Hizmet Gıda İşletmeleri Turizm San. ve Tic. A.Ş</strong> tarafından okunabilen; kullanıcının dil, lokasyon tercihi gibi temel site fonksiyonlarının yerine getirilmesi s&uuml;recinde kullanıcı deneyiminizi geliştirebilmek amacıyla kullanılan &ccedil;erezlerdir. &nbsp;</p>
<p>&nbsp;</p>
<p>&Uuml;&ccedil;&uuml;nc&uuml; taraf &ccedil;erezler, <strong>&Ccedil;elebi Hizmet Gıda İşletmeleri Turizm San. ve Tic. A.Ş</strong>&rsquo;nin iş ortakları veya servis sağlayıcılar gibi &uuml;&ccedil;&uuml;nc&uuml; kişilere ait ve &uuml;&ccedil;&uuml;nc&uuml; kişiler tarafından y&uuml;r&uuml;t&uuml;len &ccedil;erezlerdir.</p>
<ol start="4">
<li><strong>Websitemizde Kullanılan &Ccedil;erezler</strong>
<ul>
<li><strong><u>Otantikasyon &Ccedil;erezleri</u></strong><u>:</u> &Uuml;yelerimizin her sayfada şifrelerini yeniden girmemeleri i&ccedil;in kullanılan &ccedil;erezlerdir. Facebook connect girişlerinde de benzer &ccedil;erezler kullanılmaktadır.</li>
</ul>
</li>
</ol>
<p>&nbsp;</p>
<ul>
<li><strong><u>Reklam &Ccedil;erezleri:</u></strong> Davranışsal ve hedef odaklı reklamların ziyaret&ccedil;ilere g&ouml;sterilmesi amacıyla kullanılan &ccedil;erezlerdir.</li>
</ul>
<p>&nbsp;</p>
<ul>
<li><strong><u>Analitik &Ccedil;erezler:</u></strong> &Uuml;yelerimizin/ziyaret&ccedil;ilerimizin kullanım alışkanlıklarını analiz etmek ve uygulama istatistiklerini belirlemek i&ccedil;in kullanılan &ccedil;erezlerdir. (&Ouml;rneğin: Sitemizin ka&ccedil; kere a&ccedil;ıldığı, sitemizin hangi saatlerde kullanıldığı, sitemiz i&ccedil;erisinde en &ccedil;ok kullanılan b&ouml;l&uuml;mlerin neler olduğu)</li>
</ul>
<p>&nbsp;</p>
<ul>
<li><strong><u>Teknik &Ccedil;erezler:</u></strong> İnternet sitesinin d&uuml;zg&uuml;n &ccedil;alışmasının takibi amacıyla kullanılan &ccedil;erezlerdir.</li>
</ul>
<p>&nbsp;</p>
<ul>
<li><strong><u>Kişiselleştirme &Ccedil;erezleri:</u></strong> Kullanıcı davranış ve tercihlerine g&ouml;re kişiselleştirilmiş i&ccedil;erik ve deneyim sunmak amacıyla kullanılan &ccedil;erezlerdir.</li>
</ul>
<p>&nbsp;</p>
<ol start="5">
<li><strong>&Ccedil;erezleri Nasıl Silebilirim veya Reddedebilirim?</strong></li>
</ol>
<p>www.littlecaesars.com.tr internet sayfasında, sitemizin aktif ve verimli bir şekilde &ccedil;alışması i&ccedil;in birtakım zorunlu &ccedil;erezler kullanılmaktadır. Gizlilik Tercih Paneli &uuml;zerinden bu &ccedil;erezler devre dışı bırakılamamaktadır. Sitemizde kullanılan zorunlu &ccedil;erezler i&ccedil;in &ldquo;Gizlilik Tercih Paneli&rdquo;ni kullanabilirsiniz.</p>
<p>Diğer taraftan, <u>zorunlu &ccedil;erezlerin dışında kalan t&uuml;m &ccedil;erez grupları kendiliğinden &ldquo;Devre Dışı Bırakılmış&rdquo; bir şekilde gelmektedir.</u> Kullanıcılarımız, istekleri doğrultusunda bu &ccedil;erezleri tekrar aktif hale getirebilir veya aktifleştirmiş olduğu &ccedil;erezleri daha sonra tekrar devre dışı bırakabilir. Ancak &ouml;nemle belirtmek gerekir ki kullandığımız &ccedil;erezlerin devre dışı bırakılması/kalması,&nbsp;www.littlecaesars.com.tr internet sayfasındaki kullanıcı deneyiminizi etkileyebilir. Zorunlu olmayan &ccedil;erezlerin aktif hale getirilmemesi durumunda, uygulamayı ve/veya internet sitesini kullanmaya devam edebilirsiniz fakat uygulamanın ve/veya internet sitesinin t&uuml;m işlevlerine erişemeyebilirsiniz veya sınırlı bir erişim imk&acirc;nı ile karşılaşabilirsiniz. Uygulamadan ve/veya internet sitesinden tam anlamıyla faydalanmak i&ccedil;in &ccedil;erezlere izin vermenizi tavsiye ediyoruz.</p>
<ol start="6">
<li><strong>&Ccedil;erezlerin &ldquo;Gizlilik Tercihi Paneli&rdquo; Dışında Y&ouml;netilmesi:</strong></li>
</ol>
<p>Sitemizde kullanılan &ccedil;erezleri en verimli şekilde y&ouml;netimi &ldquo;Gizlilik Tercih Paneli&rdquo; &uuml;zerinden sağlanmaktadır. Ancak siz değerleri kullanıcılarımız, &ccedil;erezleri y&ouml;netmek i&ccedil;in tarayıcınıza bağlı olarak aşağıdaki y&ouml;ntemleri de tercih edebilirsiniz:</p>
<p><strong>Safari:</strong> Telefonunuzun "Ayarlar" b&ouml;l&uuml;m&uuml;nden "safari" sekmesini se&ccedil;ip, "Gizlilik ve G&uuml;venlik" B&ouml;l&uuml;m&uuml;nden t&uuml;m &ccedil;erez y&ouml;netiminizi sağlayabilirsiniz.</p>
<p>&nbsp;</p>
<p><strong>Diğer tarayıcılar:</strong> İlgili tarayıcının yardım veya destek sayfalarını inceleyebilir veya</p>
<p><a href="http://www.youronlinechoices.eu/tr/">www.youronlinechoices.eu/tr/</a>&nbsp;&nbsp; adresini ziyaret ederek detaylı bilgi alabilirsiniz.</p>
<p>&nbsp;</p>
<p>Anlık olarak ka&ccedil; sayıda &ccedil;erez ile takip edildiğinizi g&ouml;r&uuml;nt&uuml;lemek i&ccedil;in Ghostery uygulamasını veya doğrudan tarayıcıyı olarak Brave kullanabilirsiniz.</p>
<ol start="7">
<li><strong>&Ccedil;EREZ TABLOSU:</strong></li>
</ol>
<table border="1"  width="100%">
<tbody>
<tr>
<td width="121">
<p><strong><br /> &Ccedil;EREZ T&Uuml;R&Uuml;</strong></p>
</td>
<td width="173">
<p><strong><br /> &Ccedil;EREZ İSMİ</strong></p>
</td>
<td width="201">
<p><strong><br /> &Ccedil;EREZİN KULLANIM AMACI</strong></p>
</td>
<td width="137">
<p><strong>BİRİNCİ TARAF/&Uuml;&Ccedil;&Uuml;NC&Uuml; TARAF</strong></p>
</td>
</tr>
<tr>
<td rowspan="7" width="121">
<p><strong>&nbsp;</strong></p>
<p><strong>Reklam &Ccedil;erezleri</strong></p>
</td>
<td width="173">
<p>__gfp_64b</p>
</td>
<td rowspan="7" width="201">
<p>Davranışsal ve hedef odaklı reklamların ziyaret&ccedil;ilere g&ouml;sterilmesi amacıyla kullanılan &ccedil;erezlerdir.</p>
</td>
<td rowspan="7" width="137">
<p>Celebi / Doubleclick</p>
<p>Celebi / Criteo</p>
<p>Celebi / Facebook</p>
</td>
</tr>
<tr>
<td width="173">
<p>criteo_write_test</p>
</td>
</tr>
<tr>
<td width="173">
<p>test_cookie</p>
</td>
</tr>
<tr>
<td width="173">
<p>_fbp</p>
</td>
</tr>
<tr>
<td width="173">
<p>fr</p>
</td>
</tr>
<tr>
<td width="173">
<p>uid</p>
</td>
</tr>
<tr>
<td width="173">
<p>personalization_id</p>
</td>
</tr>
<tr>
<td rowspan="11" width="121">
<p><strong>&nbsp;</strong></p>
<p><strong>Analitik &Ccedil;erezler</strong></p>
</td>
<td width="173">
<p>_ga</p>
</td>
<td rowspan="11" width="201">
<p>&Uuml;yelerimizin/ziyaret&ccedil;ilerimizin kullanım alışkanlıklarını analiz etmek ve uygulama istatistiklerini belirlemek i&ccedil;in kullanılan &ccedil;erezlerdir. (&Ouml;rneğin: Sitemizin ka&ccedil; kere a&ccedil;ıldığı, sitemizin hangi saatlerde kullanıldığı, sitemiz i&ccedil;erisinde en &ccedil;ok kullanılan b&ouml;l&uuml;mlerin neler olduğu)</p>
</td>
<td rowspan="11" width="137">
<p>Celebi / Google</p>
<p>Celebi / HotJar</p>
<p>Celebi / Gemius</p>
</td>
</tr>
<tr>
<td width="173">
<p>_gid</p>
</td>
</tr>
<tr>
<td width="173">
<p>_hjTLDTest</p>
</td>
</tr>
<tr>
<td width="173">
<p>_hjid</p>
</td>
</tr>
<tr>
<td width="173">
<p>_hjAbsoluteSessionInProgress</p>
</td>
</tr>
<tr>
<td width="173">
<p>_gat_UA-39161897-5</p>
</td>
</tr>
<tr>
<td width="173">
<p>_gat_UA-39161897-6</p>
</td>
</tr>
<tr>
<td width="173">
<p>Gtest</p>
</td>
</tr>
<tr>
<td width="173">
<p>_dc_gtm_UA-39161897-6</p>
</td>
</tr>
<tr>
<td width="173">
<p>_dc_gtm_UA-39161897-5</p>
</td>
</tr>
<tr>
<td width="173">
<p>Gdyn</p>
</td>
</tr>
<tr>
<td width="121">
<p><strong>Teknik &Ccedil;erezler</strong></p>
</td>
<td width="173">
<p>__cfduid</p>
</td>
<td width="201">
<p>İnternet sitesinin d&uuml;zg&uuml;n &ccedil;alışmasının takibi amacıyla kullanılan &ccedil;erezlerdir.</p>
</td>
<td width="137">
<p>Celebi / Cloudflare</p>
</td>
</tr>
<tr>
<td rowspan="5" width="121">
<p><strong><br /> <br /> </strong></p>
<p><strong>Kişiselleştirme &Ccedil;erezleri</strong></p>
</td>
<td width="173">
<p>ins-storage-version</p>
</td>
<td rowspan="5" width="201">
<p>&nbsp;</p>
<p>Kullanıcı davranış ve tercihlerine g&ouml;re kişiselleştirilmiş i&ccedil;erik ve deneyim sunmak amacıyla kullanılan &ccedil;erezlerdir.</p>
</td>
<td rowspan="5" width="137">
<p>Celebi / Insider</p>
</td>
</tr>
<tr>
<td width="173">
<p>insdrPushCookieStatus</p>
</td>
</tr>
<tr>
<td width="173">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td width="173">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td width="173">
<p>&nbsp;</p>
</td>
</tr>
</tbody>
</table>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
};

export default CookiePolicy;