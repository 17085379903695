import React from 'react';
import { sendPageView } from '../../../googleAnalyticsHelper';
import CustomerServiceMenu from '../customer-service-menu/CustomerServiceMenu';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';

const LoyaltyTerms = () => {
    sendPageView('/musteri-hizmetleri/sezarla-kazan-kosullari', 'Sezarla Kazan - Little Caesars');
    return <section className="institutional-structures">
        <div className="container">
            <BreadCrumbs/>
            <div className="institutional-structures-detail">
                <div className="institutional-structures-detail-content clearfix">
                    <CustomerServiceMenu/>
                    <div className="col-md-9">
                        <div className="corpareta-detail">
                        <p><strong>SEZARLA KAZAN KOŞULLARI</strong></p>
                        <p><br /> Sezarla Kazan sadakat program Little Caesars Pizza b&uuml;nyesinde yapılan m&uuml;şteri memnuniyeti odaklı bir sadakat programıdır. Little Caesars Pizza online kanallarından (web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) vereceğin siparişlerinden kazanacağın her 4 dilime 1 orta boy klasik pizzayı Sezarla Kazan programı kapsamında kazanmış olursun. Kazandığın dilimler ve hediyeler 24 saat i&ccedil;inde hesabına y&uuml;klenir. Kazandığın pizzayı kullanman i&ccedil;in bir sonraki siparişinde minimum sepet değerini ge&ccedil;men gerekmektedir. Sezarla Kazan Programından faydalanmak i&ccedil;in hemen web/mobil sitemiz veya uygulamalarımız &uuml;zerinden giriş yapabilir ya da anında &uuml;ye olabilirsin.</p>
<p>Little Caesars Pizza Sezar Kazan Sadakat Programı&rsquo;nda yapacağı t&uuml;m değişiklik haklarını saklı tutar. Sezarla Kazan Sadakat Programı şartlarını veya adını değiştirebilir, programı durdurabilir, &uuml;yeliği iptal edebilir. &Uuml;yeliğin Little Caesars Pizza tarafından sona erdirilmesi durumunda &uuml;yenin hesabında biriken sanal dilim, ek sanal dilim ve Hediye Pizzalar Little Caesars Pizza tarafından silinecek ve geri alınacaktır.</p>
<p>Little Caesars Pizza Sezar Kazan Sadakat Programı koşullarını kabul eden kişiler verdiği bilgilerin doğru ve g&uuml;ncel olduğunu kabul ve taahh&uuml;t eder. Aksi takdirde, her t&uuml;rl&uuml; sorumluluk &uuml;yeye aittir.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Koşullar ve Sık&ccedil;a Sorulan Sorular</strong></p>
<p><strong>Little Caesars Sezarla Kazan Programı Nedir?</strong></p>
<p>Sezarla Kazan programı Little Ceaesars Pizza&rsquo;nın sadakat programıdır. Bu program ile &uuml;yeler sipariş verdik&ccedil;e daha &ccedil;ok pizza kazanırlar.</p>
<p><strong>Little Caesars Online Kanalları Hangi Kanalları Kapsamaktadır?</strong></p>
<p>Little Caesars internet sitesi, mobil internet sitesi, IOS uygulaması ve android uygulamasını kapsamaktadır. Sezarla Kazan programı sadece bu kanallardan verilen siparişlerde ge&ccedil;erli olmaktadır.</p>
<p><strong>Nasıl Hediye Pizza Kazanırım?</strong></p>
<p>40 TL ve &uuml;zeri online siparişlerinde 1 dilim kazanırsın, kazandığın dilimler 4 adet olduğunda,bir sonraki siparişinde kullanabileceğin orta boy klasik pizza grubundaki pizzzalarından birini kazanmaya hak kazanırsın. Ayrıca Little Caesars Pizza tarafından belirlenen d&ouml;nemlerde, belirli &uuml;r&uuml;n ve/veya kuponlara &ouml;zel ek dilimler kazanabilirsin, b&ouml;ylece ge&ccedil;erlilik tarihinden &ouml;nce dilimlerini 4 dilime tamamladığında, 4 siparişten &ouml;nce de Klasik pizza grubundan birini elde etme hakkı kazanırsın.</p>
<p><strong>Sezarla Kazan Sadakat Programına Nasıl &Uuml;ye Olurum?</strong></p>
<p>Little Caesars online kanallarından (web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) birine &uuml;ye olurken Sezarla Kazan&rsquo;a katıl diyerek ya da profilinde bulunan Sezarla Kazan sekmesinden Sezarla Kazan&rsquo;a Katıl diyerek sadakat programına katılabilirsin. Programdan yararlanman için uygulama versiyonunun IOS için 5.0.0, Anroid için 5.0.0 ve üzeri olması gerekmektedir. Uygulamanın güncel olduğundan emin olup, güncel değil ise gerekli güncellemeleri yapmalısın.</p>
<p><strong>Sezarla Kazan Programına Katılmadan Hediye Kazanabilir miyim?</strong></p>
<p>Sezarla Kazan programına katılım ger&ccedil;ekleştirmemiş kullanıcılar da dilim biriktirme hakkına sahiptir fakat birikecek 4 sanal dilim sonrası kazanacakları hediye orta boy klasik pizzayı kullanabilmeleri i&ccedil;in Sezarla Kazan programına katılmaları gerekmektedir.</p>
<p><strong>Her Siparişimden Dilim Kazanabilecek miyim?</strong></p>
<p>Little Caesars web sitesi ve mobil uygulama &uuml;zerinden vereceğin 40 TL ve &uuml;zeri her online siparişinde dilim kazanırsın. 1 g&uuml;n i&ccedil;erisinde verilen siparişlerinden sadece 1 kez dilim kazanabilirsin.</p>
<p><strong>Ge&ccedil;mişte Verdiğim Siparişlerden Dolayı Dilimlerim Birikecek mi?<br /> <br /> </strong>Hayır, programın lansman tarihi olan ( 07.12.2020 ) itibariyle verilen siparişlerden farklı g&uuml;nlerde sipariş verilerek kazandığın 4 dilim ile orta boy klasik pizza grubundan bir pizza almaya hak kazanabilirsin.</p>
<p><strong>Hediye Orta Boy Pizzamı İstediğim Şekilde Se&ccedil;ebilir miyim?</strong></p>
<p>Orta boy klasik grubundan bulunan pizzalardan istediğini se&ccedil;ebilirsin. Hediye pizzanı kullanırken minimum sepet tutarını ge&ccedil;men gerekmektedir.</p>
<p><strong>Hediye Pizza Kullanırken Yapacağım Eklemeler &Uuml;cretlendirilir mi?</strong></p>
<p>Evet, hediye pizzana ek yapılacak, kenar, sos, ek malzeme, i&ccedil;ecek vb &uuml;r&uuml;n eklemeleri ayrıca &uuml;cretlendirilmektedir.</p>
<p><strong>Hediye Pizzalarımın Kullanım S&uuml;resi Olacak mı?</strong></p>
<p>Evet, hediye pizzalarının kullanım s&uuml;resi, hediye pizza almaya hak kazandığın tarihten itibaren 180 g&uuml;nd&uuml;r.</p>
<p><strong>Dilim Toplarken Dilim hakkı Kazandırmayan Siparişler Olacak mı?</strong></p>
<p>Evet, &ouml;ncelikle sepet tutarın 40 TL ve &uuml;zerinde olmalıdır. Bu tutarın altında vereceğin siparişlerde dilim kazanımı yoktur. &nbsp;Ayrıca, Little Caesars şubelerini arayacak, m&uuml;şteri hizmetlerini arayarak ya da Yemek Sepeti, Getir , Zomato gibi kanallardan verilen siparişlerde dilim kazanımı olmayacaktır.</p>
<p><strong>Hediye Pizza Kazandım Fakat Kullanmadım, Dilim Kazanmaya Devam Edebilir miyim?</strong></p>
<p>Evet, kazandığın hediye pizza kaybolmaz ve yeni pizza kazanman i&ccedil;in dilim sayacı tekrardan başlar.</p>
<p><strong>Aynı Siparişimde 2 hediye Pizza Sepete ekleyebilir miyim?</strong></p>
<p>Hayır, Siparişinde sepetine sadece 1 adet hediye &uuml;r&uuml;n ekleyebilirsin.</p>
<p><strong>Kazandığım Dilimler ile 4 Adedi Tamamlayamazsam, Dilimlerim Silinir mi? </strong></p>
<p>Sezarla kazan dilim kazanma koşullarına uyarak kazandığın her dilim, kazandığın tarih itibariyle 180 g&uuml;n s&uuml;reyle ge&ccedil;erli olacaktır. Eğer bu s&uuml;re aşıldıysa dilimlerin silinir. Farklı kampanya ve kurallara g&ouml;re kazandığın dilimler var ise ilgili kampanya kurallarına g&ouml;re bu kural değişiklik g&ouml;stermektedir. Sezarla Kazan sekmemizden bu bilgileri takip edebilirsin.</p>
<p><strong>Kazandığım Hediye Pizza T&uuml;m Little Caesars Şubelerinde Ge&ccedil;erli Olacak mıdır?</strong></p>
<p>Evet, Kazandığın hediye pizzayı Little Caesars online kanallarından (web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) vereceğin online siparişlerde t&uuml;m Little Caesars şubelerinde kullanabilirsin.</p>
<p><strong>Hangi Sipariş Kanallarından Sipariş Verdiğimde Dilim Hakkı Kazanırım?</strong></p>
<p>Sadece Little Caesars Pizza&rsquo;ya ait online kanallardan ((web ve mobil sitemiz ve IOS veya Android mobil uygulamamız) &uuml;ye girişi yaparak verdiğin siparişlerden dilim hakkı kazanırsın.</p>
<p><strong>Siparişim İptal Olduğunda, iptal olan siparişimden Dilim Kazanabilir miyim?</strong></p>
<p>Hayır, iptal olan ya da iptal ettiğin siparişlerinden dilim hakkı kazanamazsın. Ancak tamamlanan ve teslim edilen siparişlerden dilim hakkı kazanırsın.</p>
<p><br /> <strong>Farklı &Uuml;yeliklerimde Bulunan Dilimleri Birleştirebilir miyim?<br /> </strong>&Uuml;yelerin birden fazla e-posta adresi veya telefon numarası ile ilişkilendirilmiş Sezarla Kazan &uuml;yelikleri varsa, bu farklı &uuml;yeliklerden kazandıkları sanal dilimler farklı hesaplarda birikmektedir ve birleştirilerek kullanılamamaktadır.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

export default LoyaltyTerms;