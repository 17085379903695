import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getBasket, addToBasket } from '../../../store/actions/basketActions';
import {
    getFavoriteProducts, addFavoriteProduct, deleteFavoriteProduct,
    getFavoriteOrders, deleteFavoriteOrder
} from '../../../store/actions/memberActions';
import { copyOrderAndGetBasket } from '../../../store/actions/orderActions';
import { setModal } from '../../../store/actions/modalActions';
import {
    sendPageView,
    addImpressionEvent,
    addClickProductEvent,
    addCartOrderItemAction,
    addActionEvent
} from '../../../googleAnalyticsHelper';
import Pin from '../../elements/icons/Pin';
import { setLocalStorage, getFromLocalStorage } from '../../../storage';
import MyFavoriteProduct from './MyFavoriteProduct';
import MyFavoriteOrder from './MyFavoriteOrder';
import FavoriteNotFound from './FavoriteNotFound';

class ProfileFavorites extends Component {

    state = {
        selectedProduct: {},
        myFavProductsClicked: true,
        myFavOrdersClicked: false
    };

    componentDidMount() {
        const {
            user, location, favoriteProducts, favoriteOrders,
            boundGetFavoriteProducts, boundGetFavoriteOrders
        } = this.props;

        if (user.id) {
            boundGetFavoriteProducts();
        }

        if (user.id && !favoriteOrders.length) {
            boundGetFavoriteOrders();
        }

        //for google analytics
        setLocalStorage('favoriteProducts', '');
        sendPageView(location.pathname, 'Favorilerim');
    }

    getAddProductToBasket = product => () => {
        const { basket, favoriteProducts, boundGetBasket, boundAddToBasket } = this.props;

        if (product) {
            boundAddToBasket(product, basket.id).then(() =>
                boundGetBasket().finally(() => {
                        const position = favoriteProducts.findIndex(p => p.id === product.id) + 1;
                        const orderItem = this.props.basket.orderItems.find(c => c.productId === product.id);

                        //For google analytics
                        setLocalStorage(`pList${product.id}`, 'Favori Ürünlerim');
                        setLocalStorage(`prodPos${product.id}`, position);
                        addClickProductEvent(product, position, 'Favori Ürünlerim');

                        if (orderItem)
                            addCartOrderItemAction(orderItem, position, 'add', '', 'Favori Ürünlerim');
                    }
                ));
        }
    };

    getToggleFavoriteProduct = product => () => {
        const { boundGetFavoriteProducts, boundAddFavoriteProduct, boundDeleteFavoriteProduct } = this.props;

        boundDeleteFavoriteProduct(product.favoriteId)
            .then(() => {
                boundGetFavoriteProducts();
                addActionEvent('Favorilereden Çıkar', product.name); //for ga
            });
    };

    getTabClassName = clicked => {
        const { myFavProductsClicked, myFavOrdersClicked } = this.state;

        return clicked === 'products' ?
            myFavProductsClicked ? 'profile-favorites__header-tab profile-favorites__header-tab--active'
                : 'profile-favorites__header-tab'
            :
            clicked === 'orders' ?
                myFavOrdersClicked ? 'profile-favorites__header-tab profile-favorites__header-tab--active'
                    : 'profile-favorites__header-tab'
                :
                null;
    };

    getToggleMyFavoritesTabs = clicked => () => {
        const { myFavProductsClicked, myFavOrdersClicked } = this.state;

        if (clicked === 'products') {
            if (!myFavProductsClicked && myFavOrdersClicked) {
                this.setState({
                    myFavProductsClicked: true,
                    myFavOrdersClicked: false
                });
            }
        } else if (clicked === 'orders') {
            if (myFavProductsClicked && !myFavOrdersClicked) {
                this.setState({
                    myFavProductsClicked: false,
                    myFavOrdersClicked: true
                });
            }
        }
    };

    getIconName = id => {
        const { favoriteProducts } = this.props;
        const product = favoriteProducts && favoriteProducts.length &&
            favoriteProducts.filter(item => item.id === id);
        return product ?
            'orange-heart'
            :
            'product-modal__favorite-button-icon';
    };

    getDeleteOrderFromFavorites = orderId => () => {
        const { boundGetFavoriteOrders, boundDeleteFavoriteOrder } = this.props;

        boundDeleteFavoriteOrder(orderId).then(() =>
            boundGetFavoriteOrders())
            .catch(() => console.log('error in deleting occured'));
    };

    getRepeatFavoriteOrder = orderId => () => {
        const { boundCopyOrderAndGetBasket, history } = this.props;

        boundCopyOrderAndGetBasket(orderId).then(() => history.push(`/completed-order/${orderId}`));
    };

    renderMyFavoriteProducts = products => {

        //add impression event for google analytics
        if (getFromLocalStorage('favoriteProducts') !== products[0].name) {
            setLocalStorage('favoriteProducts', products[0].name);
            addImpressionEvent(products, 'Favori Ürünlerim', 'Tüm Ürünler');
        }

        return products.map(product =>
            <MyFavoriteProduct key={product.id} product={product}
                               onAddToBasketClick={this.getAddProductToBasket(product)}
                               onToggleDeleteClick={this.getToggleFavoriteProduct(product)}
                               iconName={this.getIconName(product.id)}
            />
        );
    };

    renderMyFavoriteOrders = orders => {

        //add impression event for google analytics
        if (getFromLocalStorage('favoriteOrders') !== orders[0].name) {
            setLocalStorage('favoriteOrders', orders[0].name);
            addImpressionEvent(orders, 'Favori Siparişlerim', 'Tüm Siparişler');
        }

        return orders.map(order =>
            <MyFavoriteOrder key={order.id} order={order}
                             onDeleteClick={this.getDeleteOrderFromFavorites(order.id)}
                             onRepeatOrderClick={this.getRepeatFavoriteOrder(order.id)}
            />
        );
    };

    startOrder = () => {
        const { history } = this.props;
        history.push('/siparis/kampanyalar');
    };

    render() {
        const { loading, favoriteProducts, favoriteOrders } = this.props;
        const { myFavProductsClicked, myFavOrdersClicked } = this.state;

        return <div className="profile-favorites profile__page">
            <div className="profile-favorites__header">
                <div className="profile__block-header">
                    favorilerim
                </div>
                <div className="profile-favorites__header-tab-container">
                    <div className={this.getTabClassName('products')}
                         onClick={this.getToggleMyFavoritesTabs('products')}
                    >
                        favori ürünlerim
                    </div>
                    <div className={this.getTabClassName('orders')}
                         onClick={this.getToggleMyFavoritesTabs('orders')}
                    >
                        favori siparişlerim
                    </div>
                </div>
            </div>
            {
                myFavProductsClicked ?
                    favoriteProducts.length
                        ?
                        this.renderMyFavoriteProducts(favoriteProducts)
                        :
                        !loading
                            ? <FavoriteNotFound myFavorites="ürünunuz"
                                                onClick={this.startOrder}/>
                            : null
                    :
                    myFavOrdersClicked ?
                        favoriteOrders.length
                            ?
                            this.renderMyFavoriteOrders(favoriteOrders)
                            :
                            !loading
                                ? <FavoriteNotFound myFavorites="siparişiniz"
                                                    onClick={this.startOrder}/>
                                : null
                        : null
            }
        </div>;
    }
}

const mapStateToProps = store => ({
    loading: !!store.loader.loadingCount,
    basket: store.basket.basket,
    addressData: store.addressData,
    user: store.user.user,
    favoriteProducts: store.member.favoriteProducts,
    favoriteOrders: store.member.favoriteOrders
});

const mapDispatchToProps = dispatch => ({
    boundGetFavoriteProducts: bindActionCreators(getFavoriteProducts, dispatch),
    boundAddFavoriteProduct: bindActionCreators(addFavoriteProduct, dispatch),
    boundDeleteFavoriteProduct: bindActionCreators(deleteFavoriteProduct, dispatch),
    boundGetFavoriteOrders: bindActionCreators(getFavoriteOrders, dispatch),
    boundDeleteFavoriteOrder: bindActionCreators(deleteFavoriteOrder, dispatch),
    boundCopyOrderAndGetBasket: bindActionCreators(copyOrderAndGetBasket, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch),
    boundAddToBasket: bindActionCreators(addToBasket, dispatch),
    boundSetModal: bindActionCreators(setModal, dispatch)
});

ProfileFavorites.propTypes = {
    boundGetFavoriteProducts: PropTypes.func.isRequired,
    boundAddFavoriteProduct: PropTypes.func.isRequired,
    boundDeleteFavoriteProduct: PropTypes.func.isRequired,
    boundGetFavoriteOrders: PropTypes.func.isRequired,
    boundDeleteFavoriteOrder: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    boundAddToBasket: PropTypes.func.isRequired,
    boundCopyOrderAndGetBasket: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    addressData: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileFavorites));
