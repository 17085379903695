import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Swipeable } from 'react-swipeable';    // Docs here https://www.npmjs.com/package/react-swipeable
import Icon from '../../components/elements/Icon';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { addClickEvent } from '../../googleAnalyticsHelper';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
    sidebarRef = React.createRef();
    sidebarElement = null;

    state = {
        status: 'closed'
    };

    componentDidMount() {
        this.sidebarElement = this.sidebarRef.current;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.status !== this.state.status) {
            if (this.state.status === 'opened') {
                disableBodyScroll(this.sidebarElement);
            }
            if (this.state.status === 'closed') {
                enableBodyScroll(this.sidebarElement);
            }
        }
    }

    toggleSidebar = (withAnimation = true) => {
        this.setState(prevState => {
            let newSidebarStatus = '';
            if (prevState.status === 'closed') {
                newSidebarStatus = 'opened';
            } else if (prevState.status === 'opened' && withAnimation) {
                newSidebarStatus = 'closing';
            } else {
                newSidebarStatus = 'closed';
            }
            return { status: newSidebarStatus };
        });
    };

    getSwipeAction = (swipeSide) => {
        const { rightSide } = this.props;

        if (swipeSide === 'right' && rightSide) {
            return this.toggleSidebar;
        }
        if (swipeSide === 'left' && !rightSide) {
            return this.toggleSidebar;
        }
    };

    toggleSidebarWithoutAnimation = () => {
        this.toggleSidebar(false);
    };

    closeSidebar = () => {
        const { status } = this.state;
        if (status === 'closing') {
            this.toggleSidebar();
        }
    };

    getSidebarClassName = () => {
        const { rightSide } = this.props;
        const { status } = this.state;

        let result = `sidebar sidebar--${status}`;
        if (rightSide) {
            result += ' sidebar--right-side';
        }
        return result;
    };

    renderSidebarLinks = () => {
        const { links, rightSide } = this.props;

        const className = rightSide ? 'profile-sidebar-link' : 'sidebar-link-wrapper';
        return links.map(link =>
            <div className={className} key={link.key}
                 onClick={this.toggleSidebarWithoutAnimation}
            >
                {link}
                {!rightSide && <Icon name="chevron-right" className="sidebar__arrow"/>}
            </div>
        );
    };

    openLiveSupport = () => {
        addClickEvent('Canlı Destek');
        window.psvW5Sow();
    };

    callLCP = () => {
        addClickEvent('Ara Gelsin');
    };

    render() {
        const { trigger, children, rightSide, isAuthorizedUser } = this.props;

        return (
            <div className="sidebar-with-trigger-container">
                <div className="sidebar-with-trigger-container__click-area" onClick={this.toggleSidebar}>
                    {trigger}
                </div>
                <div className={this.getSidebarClassName()}>
                    <Swipeable onSwipedLeft={this.getSwipeAction('left')} onSwipedRight={this.getSwipeAction('right')}>
                        <div className="sidebar__container">
                            <div className="sidebar__body"
                                 onAnimationEnd={this.closeSidebar}
                                 ref={this.sidebarRef}
                            >
                                <div className="sidebar__top">
                                    {
                                        !rightSide && !isAuthorizedUser
                                            && <div className="sidebar__top-buttons">
                                                    <Link className="big-button big-button--light_green sidebar__top-button"
                                                          to='/hesap/giris' 
                                                          onClick={this.toggleSidebar}>
                                                          Giriş Yap
                                                    </Link>
                                                    <Link className="big-button big-button--orange sidebar__top-button" 
                                                          to='/hesap/uye-ol'
                                                          onClick={this.toggleSidebar}> 
                                                          Kayıt Ol
                                                    </Link>
                                            </div>
                                    }
                                    {children}
                                    {this.renderSidebarLinks()}
                                </div>
                                <div className="sidebar__bottom">
                                    <div className="sidebar__bottom-button sidebar__bottom-button--live-support"
                                         onClick={this.openLiveSupport}
                                    >
                                        Canlı Destek
                                    </div>
                                    <div className="sidebar__bottom-button sidebar__bottom-button--contact icon-with-label"
                                         onClick={this.callLCP}
                                    >
                                        <Icon name="phone"/>
                                        <span>444 52 44</span>
                                    </div>
                                </div>
                            </div>
                            <div onClick={this.toggleSidebar} className="sidebar__dimmer"></div>
                        </div>
                    </Swipeable>
                </div>
            </div>
        );
    }
}

Sidebar.propTypes = {
    links: PropTypes.array.isRequired,
    trigger: PropTypes.node.isRequired,
    rightSide: PropTypes.bool,
    isAuthorizedUser: PropTypes.bool
};

export default Sidebar;
