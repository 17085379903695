import { createReducer } from '../storeUtils';
import { SET_LOADING } from '../actions/loaderActions';

const initialState = {
  loadingCount: 0, 
  loadedCount: 0
};

function setLoading(state, action) {
  let newLoadingCount = state.loadingCount;
  let newLoadedCount = state.loadedCount;

  if (action.payload) {
    newLoadingCount++;
  } else {
    newLoadingCount--;
    newLoadedCount++;
  }
  if (!newLoadingCount) {
    newLoadedCount = 0;
  }
  return {
    ...state,
    loadingCount: newLoadingCount,
    loadedCount: newLoadedCount
  };
}

export default createReducer(initialState, {
  [SET_LOADING]: setLoading
});
