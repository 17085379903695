import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Icon from '../elements/Icon';
import { logOut } from '../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAddressSelection } from '../../store/actions/addressActions';
import { getProductDetails } from '../../store/actions/productActions';
import LoyaltyPreview from '../loyalty/LoyaltyPreview';
import LoyaltyPreviewButton from '../loyalty/LoyaltyPreviewButton';

class ProfileMenuModal extends Component {
    exit = () => {
        const { boundSetAddressSelection } = this.props;
        boundSetAddressSelection({});
        logOut();
    };

    getLinkClassName = url => this.props.location.pathname === url
        ? 'icon-with-label profile-menu-modal__link link profile-sidebar-link link--active'
        : 'icon-with-label profile-menu-modal__link link profile-sidebar-link';

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        const { user } = this.props;

        return <div className="profile-menu-modal modal-content">
            <div className="modal__triangle"/>
            <div className="profile-menu-modal__name">
                <span className="profile-menu-modal__hello-text">Merhaba</span> {user.name}
            </div>
            {
                user.loyalty && user.loyalty.punchCard
                    ? <>
                        <LoyaltyPreview/>
                        <LoyaltyPreviewButton/>
                    </>
                    : null
            }
            <div className="profile-menu-modal__links-container">
                <Link onClick={this.closeModal}
                      to="/profil/hesap"
                      className={this.getLinkClassName('/profil/hesap')}
                >
                    <Icon name="user"/>
                    <span>Profilim</span>
                </Link>
                <Link onClick={this.closeModal}
                      to="/profil/favoriler"
                      className={this.getLinkClassName('/profil/favoriler')}
                >
                    <Icon name="favorites"/>
                    <span>Favorilerim</span>
                </Link>
                <Link onClick={this.closeModal}
                      to="/profil/siparisler"
                      className={this.getLinkClassName('/profil/siparisler')}
                >
                    <Icon name="orders"/>
                    <span>Siparişlerim</span>
                </Link>
                <Link onClick={this.closeModal}
                      to="/profil/siparis-takip"
                      className={this.getLinkClassName('/profil/siparis-takip')}
                >
                    <Icon name="order-tracking"/>
                    <span>Sipariş Takip</span>
                </Link>
                <Link onClick={this.closeModal}
                      to="/profil/adresler"
                      className={this.getLinkClassName('/profil/adresler')}
                >
                    <Icon name="pin"/>
                    <span>Adreslerim</span>
                </Link>
                <Link onClick={this.closeModal}
                      to="/profil/kayıtlı-kartlarım"
                      className={this.getLinkClassName('/profil/kayıtlı-kartlarım')}
                >
                    <Icon name="credit-card"/>
                    <span>Kayıtlı Kartlarım</span>
                </Link>
                <div onClick={this.exit}
                     className={this.getLinkClassName()}
                >
                    <Icon name="exit"/>
                    <span>Çıkış</span>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user,
    basket: store.basket.basket
});

const mapDispatchToProps = dispatch => ({
    boundSetAddressSelection: bindActionCreators(setAddressSelection, dispatch),
    boundGetProductDetails: bindActionCreators(getProductDetails, dispatch)
});

ProfileMenuModal.propTypes = {
    user: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setModal: PropTypes.func.isRequired,
    boundSetAddressSelection: PropTypes.func.isRequired,
    boundGetProductDetails: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileMenuModal));
