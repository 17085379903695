import React, { Component } from 'react';
import PropTypes from "prop-types";

class TextArea extends Component {
    getClassName = () => {
        const { disabled } = this.props;

        let resultClassName = 'textarea';

        if (disabled) {
            resultClassName += ' textarea--disabled';
        }
        return resultClassName;
    };

    render() {
        const { cols, className, disabled, maxLength, minLength, name, placeholder, required, rows, onBlur, comment } = this.props;

        return <div className={className}>
            <textarea 
             className={this.getClassName()} 
             cols={cols}
             disabled={disabled}
             maxLength={maxLength}
             minLength={minLength}
             name={name}
             placeholder={placeholder}
             required={required}
             rows={rows}
             onBlur={onBlur}
    >{comment}</textarea>
        </div>
    }
}

TextArea.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.string,
    error: PropTypes.any,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    cols: PropTypes.number,
    maxlength: PropTypes.string,
    minlength: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    comment: PropTypes.string
};

export default TextArea;