import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../utils';

const FlavorSuggestion = ({ flavor, onAddToBasketClick }) => {
    return <div className="extra-product-card swiper-slide">
        <div className="extra-product-image">
            <img src={flavor.image} alt={`${flavor.name}.png`}/>
        </div>
        <div className="extra-product-body">
            <div className="extra-product-title">
                {flavor.name}
            </div>
            <div className="extra-product-price">
                {formatMoney(flavor.price.price)} TL
            </div>
            <div className="button extra-product-button" onClick={onAddToBasketClick}>
                <span>Sepete Ekle</span>
            </div>
        </div>
    </div>;
};

FlavorSuggestion.propTypes = {
    flavor: PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.string,
        price: PropTypes.shape({
            price: PropTypes.number
        })
    }).isRequired,
    onAddToBasketClick: PropTypes.func.isRequired
};

export default FlavorSuggestion;