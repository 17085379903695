import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCreditCards } from '../../../store/actions/memberActions';

class SuccessfullyLinkedCustomer extends Component {
    componentDidMount() {
        const { setModal } = this.props;
        setTimeout(() => setModal(''), 5000);
    }

    render() {
        const { phoneNumber, boundGetCreditCards } = this.props;
        boundGetCreditCards(phoneNumber);
        return (
            <div className="modal-content successfully-added-card-modal">
            <div className="successfully-added-card-modal__container">
                    <div>
                        <img src="/images/bank-cards/success.png" alt="by-masterpass-logo.png"/>
                    </div>
                    <div className="successfully-added-card-modal__text-container">
                        <span className="successfully-added-card-modal__header">
                            Hesap Onayı
                        </span>
                        <span>
                            Kredi kartı bilgilerinizi tekrar girmeden dilediğinizce pizza sipariş edebilirsiniz.
                        </span>
                    </div>
                </div>
                <div className="masterpass">
                        <img src="/images/masterpass.svg" alt="masterpass.svg"/>
                    </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    phoneNumber: `90${store.user.user.personPhones[0].number}`
});

const mapDispatchToProps = dispatch => ({
    boundGetCreditCards: bindActionCreators(getCreditCards, dispatch)
});

SuccessfullyLinkedCustomer.propTypes = {
    boundGetCreditCards: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfullyLinkedCustomer);
