import React from 'react';

const OrangeHeart = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="42" height="42" fill="#383838"/>
        <rect x="-1175" y="-315" width="1366" height="1522" fill="#F8F8F8"/>
        <path d="M-776.5 -184C-776.5 -188.142 -773.142 -191.5 -769 -191.5H85C89.1422 -191.5 92.5 -188.142 92.5 -184V710C92.5 714.142 89.1422 717.5 85 717.5H-769C-773.142 717.5 -776.5 714.142 -776.5 710V-184Z" fill="white" stroke="#E5E5E5"/>
        <path d="M-746.5 -16C-746.5 -17.933 -744.933 -19.5 -743 -19.5H59C60.933 -19.5 62.5 -17.933 62.5 -16V202C62.5 203.933 60.933 205.5 59 205.5H-743C-744.933 205.5 -746.5 203.933 -746.5 202V-16Z" fill="white" stroke="#E5E5E5"/>
        <circle cx="21" cy="21" r="21" fill="#F8F8F8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21 29C20.744 29 20.497 28.9076 20.304 28.7389C19.577 28.103 18.876 27.5067 18.257 26.9804L18.254 26.9774C16.441 25.4333 14.875 24.1004 13.785 22.7869C12.567 21.318 12 19.9252 12 18.4044C12 16.9263 12.507 15.563 13.428 14.5655C14.36 13.5567 15.638 13 17.028 13C18.067 13 19.019 13.3281 19.856 13.9752C20.279 14.3023 21 15.1689 21 15.1689C21 15.1689 21.721 14.3023 22.144 13.9752C22.981 13.3281 23.933 13 24.972 13C26.361 13 27.641 13.5567 28.572 14.5655C29.493 15.563 30 16.9263 30 18.4044C30 19.9252 29.433 21.318 28.215 22.7859C27.125 24.1004 25.56 25.4323 23.746 26.9774C23.127 27.5036 22.425 28.102 21.696 28.7389C21.503 28.9076 21.256 29 21 29Z" fill="#F37021" stroke="#F37021" strokeWidth="2"/>
        </svg>
    );
};

export default OrangeHeart;