import React from 'react';

const ChevronRight = props =>
    <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <path d="M1.12737 0L0 1.12737L3.70421 4.83158L0 8.53579L1.12737 9.66316L5.95895 4.83158L1.12737 0Z" />
        </g>
        <defs>
            <clipPath>
                <path d="M0 0H6V10H0V0Z" />
            </clipPath>
        </defs>
    </svg>;

export default ChevronRight;
