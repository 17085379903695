import { SET_BASKET } from '../actions/basketActions';
import { setLocalStorage } from '../../storage';

const initialState = {
  basket: {}
};

const basketReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_BASKET:
      setLocalStorage('basketId', JSON.stringify(action.payload.id));
      return {
        ...state,
        basket: action.payload
      }

      default:
        return state
  }
}

export default basketReducer;
