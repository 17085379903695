import React from 'react';

const Mastercard = props =>
    <svg width="52" height="32" viewBox="0 0 52 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16.1699 32C25.1002 32 32.3397 24.8366 32.3397 16C32.3397 7.16344 25.1002 0 16.1699 0C7.23949 0 0 7.16344 0 16C0 24.8366 7.23949 32 16.1699 32Z"
            fill="#EB001B"/>
        <path
            d="M35.7007 32C44.6311 32 51.8706 24.8366 51.8706 16C51.8706 7.16344 44.6311 0 35.7007 0C26.7703 0 19.5309 7.16344 19.5309 16C19.5309 24.8366 26.7703 32 35.7007 32Z"
            fill="#F79E1B"/>
        <path
            d="M25.9311 3.22217C29.8244 6.14141 32.3313 10.7675 32.3313 15.9679C32.3313 21.1683 29.8244 25.8024 25.9311 28.7217C22.0377 25.8024 19.5309 21.1763 19.5309 15.9679C19.5309 10.7596 22.0458 6.14141 25.9311 3.22217Z"
            fill="#FF5F00"/>
        <path
            d="M50.8633 26.624V26.7596H50.5651V27.5253H50.42V26.7596H50.1217V26.624H50.8633ZM51.9999 26.624V27.5253H51.8548V26.7835L51.5888 27.4376H51.4276L51.1616 26.7915V27.5253H51.0165V26.624H51.2422L51.5082 27.2701L51.7742 26.624H51.9999Z"
            fill="#F79E1B"/>
    </svg>
;

export default Mastercard;
