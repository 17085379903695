import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router, withRouter, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProductDepartments, getProducts, getProductSizes } from '../../store/actions/productActions';
import { toast } from 'react-toastify';
import Toast from '../elements/Toast';
import { setRedirect } from '../../store/actions/modalActions';
import ProductCard from './ProductCard';
import ProductCategoryLink from './ProductCategoryLink';
import ProductSizeLink from './ProductSizeLink';
import ViewPromotionModal from './ViewPromotionModal';
import ViewProductModal from './ViewProductModal';
import ModalWithAnimation from '../elements/ModalWithAnimation';
import { addClickProductEvent, addImpressionEvent, sendPageView } from '../../googleAnalyticsHelper';
import Icon from '../elements/Icon';
import { setLocalStorage, getFromLocalStorage } from '../../storage';

class ProductManager extends Component {
    state = {
        products: [],
        pizzaSizes: [],
        currentCategoryName: '',
        currentSubCategoryName: '',
        currentNumOfPeople: 'Kişi Sayısı',
        isNumOfPeopleDropdownOpened: false,
        currentSize: '',
        shouldSetInitialSubCategory: true,
        productIdForModal: null,
        loaderSpinnerVisible: true
    };

    componentDidMount() {
        this.onChangeCategory();
        this.initilizeLocalStorage();
    }

    componentDidUpdate(prevProps, prevState) {
        const { location, history } = this.props;
        const { products } = this.state;
        const currentCategory = this.getCurrentCategory();

        //Check if (sub)category has changed or not to send pageview event to gtm
        if (currentCategory) {
            const currentSubCategory = this.getCurrentSubCategory(currentCategory);
            const productCategory = currentSubCategory !== null && currentSubCategory !== undefined ? currentSubCategory : currentCategory;

            if (getFromLocalStorage('prodSubCategory') === '' || getFromLocalStorage('prodSubCategory') === null) {
                setLocalStorage('prodSubCategory', productCategory.name);
            }

            if (prevState.currentCategoryName !== this.state.currentCategoryName) {
                sendPageView(location.pathname, productCategory.name);
            } else if (productCategory.name !== getFromLocalStorage('prodSubCategory')) {
                setLocalStorage('prodSubCategory', productCategory.name);
                sendPageView(location.pathname, productCategory.name);
            }
        }

        const splittedUrl = location.pathname.split('/');

        // On change category we should reset previous category and get products for new category
        if (currentCategory && !prevProps.location.pathname.includes(currentCategory.link)) {
            this.onChangeCategory();
        }
        // On change address we should get products again
        if (this.props.addressData.addressSelection.address !== prevProps.addressData.addressSelection.address) {
            this.getProducts(currentCategory);
        }
        // Handle case when user comes to product page by url with productId
        if (currentCategory && products.length) {
            const prevSplittedUrl = prevProps.location.pathname.split('/');
            let productLinkName;
            let prevProductLinkName;
            if (this.hasSubCategories(currentCategory)) {
                productLinkName = splittedUrl[4];
                prevProductLinkName = prevSplittedUrl[4];
            } else {
                productLinkName = splittedUrl[3];
                prevProductLinkName = prevSplittedUrl[3];
            }
            if (productLinkName && (prevProductLinkName !== productLinkName || this.state.products !== prevState.products)) {
                let correctProductId = false;
                products.map(product => {
                    if (this.getLinkLastItem(product.link) === productLinkName) {
                        correctProductId = true;
                        this.getViewProductModal(product)(false);
                    }
                });
                if (!correctProductId) {
                    history.push(splittedUrl.slice(0, splittedUrl.length - 1).join('/'));
                }
            }
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.closeNumOfPeopleDropdown();
            if (splittedUrl.length === 3 && !splittedUrl.includes('tumu')) {
                history.push(location.pathname + '/tumu');
            }
        }

        // On change sub category - change url (no need)
        // if (
        //     currentCategory && this.hasSubCategories(currentCategory)
        //     && this.state.currentSubCategoryName
        //     && prevState.currentSubCategoryName !== this.state.currentSubCategoryName
        // ) {
        //     let nextLocation = `${currentCategory.link}/${this.state.currentSubCategoryName}`;
        //     if (splittedUrl.length === 5) {
        //         nextLocation += `/${splittedUrl[4]}`;
        //     }
        //      if (nextLocation !== location.pathname) {
        //         history.push(nextLocation);
        //     }
        // }

        // On change url sub category to empty - select first one (no need)
        // if (currentCategory && this.hasSubCategories(currentCategory) && splittedUrl.length < 4) {
        //     const firstSubCategory = this.getSubCategories()[0];
        //     //  this.getSelectSubCategory(firstSubCategory.linkName)();
        //     const nextLocation = `${currentCategory.link}/${firstSubCategory.linkName}`;
        //     let correctSubCategory = false;
        //     currentCategory.subCategories.map(subCategory => {
        //         const splittedLink = subCategory.link.split('/');
        //         const subCategoryLinkName = splittedLink[splittedLink.length - 1];
        //         if (subCategoryLinkName === firstSubCategory.linkName) {
        //             correctSubCategory = true;
        //         }
        //     });
        //     if (correctSubCategory && nextLocation !== location.pathname) {
        //         history.push(nextLocation);
        //     }
        // }

        // Handle case when user comes to product page by url with subCategory
        // if (shouldSetInitialSubCategory) {
        //     const initialSubCategory = this.getSubCategoryFromUrl();
        //     if (this[initialSubCategory]) {
        //         this.setState({ shouldSetInitialSubCategory: false });
        //         // this.getSelectSubCategory(initialSubCategory)();
        //     }
        // }
    }

    closeModal = () => {
        const { history, location } = this.props;
        this.setState({ productIdForModal: null });
        const splittedUrl = location.pathname.split('/');
        splittedUrl.pop();
        history.push(splittedUrl.join('/'));
    };

    getSubCategoryFromUrl = () => {
        const { location } = this.props;
        let result = null;
        const currentCategory = this.getCurrentCategory();
        if (
            currentCategory
            && this.hasSubCategories(currentCategory)
            && location.pathname.split('/').length >= 4
        ) {
            const locationSubCategory = location.pathname.split('/')[3];
            this.getSubCategories(currentCategory).map(subCategory => {
                if (locationSubCategory === subCategory.linkName) {
                    result = subCategory.linkName;
                }
            });
        }
        return result;
    };

    getLinkLastItem = link => link.split('/').pop();

    getLinkSecondLastItem = link => {
        const splittedLink = link.split('/');
        splittedLink.pop();
        return splittedLink.pop();
    };

    getLinkThirdLastItem = link => {
        const splittedLink = link.split('/');
        splittedLink.pop();
        splittedLink.pop();
        return splittedLink.pop();
    };

    initilizeLocalStorage = () => {
        setLocalStorage('ecProducts', '');
        setLocalStorage('prodCategory', '');
    };

    onChangeCategory = () => {
        const { productData, boundGetProductDepartments } = this.props;
        const currentCategory = this.getCurrentCategory();

        this.setState({ currentSubCategoryName: '', currentSize: '', shouldSetInitialSubCategory: true });

        if (!productData.productDepartments.length) {
            boundGetProductDepartments()
                .then(response => {
                    this.getDataOnChangeCategory(this.getCurrentCategory(response.payload));
                    this.setState({ currentCategoryName: this.getCurrentCategory(response.payload).name });
                });
        } else {
            this.getDataOnChangeCategory(currentCategory);
            this.setState({ currentCategoryName: currentCategory.name });
        }
    };

    getDataOnChangeCategory = currentCategory => {
        const { pizzaSizes } = this.state;
        this.getProducts(currentCategory);
        if (currentCategory && this.hasSizeCategories(currentCategory)) {
            if (!pizzaSizes.length) {
                this.getPizzaSizes();
            } else {
                this.setState({ currentSize: pizzaSizes[0].name });
            }
        }
    };

    selectSize = size => () => this.setState({ currentSize: size.name });

    hasSubCategories = category =>
        (category.name === 'Pizzalar' || category.name === 'Kampanyalar' || category.name === 'Yan Ürünler')
        && this.getSubCategories().length;

    hasSizeCategories = category => category.name === 'Pizzalar';

    getPizzaSizes = () => {
        const { boundGetProductSizes } = this.props;
        return boundGetProductSizes().then(() => {
            this.setState({ pizzaSizes: this.props.productData.productSizes }, () => {
                if (this.state.pizzaSizes && this.state.pizzaSizes.length) {
                    this.setState({ currentSize: this.state.pizzaSizes[0].name });
                }
            });
        });
    };

    getViewProductModal = (product, pos) => (withPush = true) => {
        const { history, location } = this.props;

        let modalUrl;

        if (withPush) {
            const pathNameItems = location.pathname.split('/');
            if (pathNameItems.length === 3) {
                modalUrl = `${location.pathname}/tumu/${this.getLinkLastItem(product.link)}`;
            } else {
                modalUrl = `${location.pathname}/${this.getLinkLastItem(product.link)}`;
            }
        } else {
            modalUrl = location.pathname;
        }

        //Get the list of which the product falls in
        const currentCategory = this.getCurrentCategory();
        const currentSubCategory = this.getCurrentSubCategory(currentCategory);
        const productCategory = currentSubCategory ? currentSubCategory : currentCategory;

        //Set the position & list of the product
        if (pos) {
            const list = currentCategory.name;
            const category = productCategory.name === 'Kampanyalar' || productCategory.name === 'Yan Ürünler' ? 'Tümü' : productCategory.name;
            setLocalStorage(`prodPos${product.id}`, pos);
            setLocalStorage(`pList${product.id}`, list);
            setLocalStorage(`pCategory${product.id}`, category);
            addClickProductEvent(product, pos, list, category);
        }
        if (withPush) {
            history.push(modalUrl);
        }
        this.setState({ productIdForModal: product.id });

    };

    getCurrentCategory = productDepartments => {
        const { location, productData } = this.props;
        const departments = productDepartments || productData.productDepartments;
        return departments.find(department => location.pathname.includes(`${department.link}`));
    };

    getCurrentSubCategory = currentCategory => {
        const { location } = this.props;
        return currentCategory.subCategories.find(subCategory => location.pathname.includes(`${subCategory.link}`));
    };

    getSubCategoriesWithFilteredProducts = products => {
        const { currentSize } = this.state;
        const subCategoriesWithProducts = {};
        let filteredProducts = products;
        if (currentSize) {
            filteredProducts = filteredProducts.filter(product => product.size === currentSize);
        }
        filteredProducts.map(product => {
            let productSubCategoryLinkName;
            if (this.hasSizeCategories(this.getCurrentCategory())) {
                productSubCategoryLinkName = this.getLinkThirdLastItem(product.link);
            } else {
                //check if yan ürünler we get link name from subCategory link
                if (this.getCurrentCategory().name.toLowerCase() !== 'yan ürünler') {
                    productSubCategoryLinkName = this.getLinkSecondLastItem(product.link);
                } else {
                    this.getCurrentCategory().subCategories.forEach(subCategory => {
                        if (product.productTypeId === subCategory.id) {
                            productSubCategoryLinkName = this.getLinkLastItem(subCategory.link);
                        }
                    });
                }
            }
            if (productSubCategoryLinkName) {
                if (subCategoriesWithProducts.hasOwnProperty(productSubCategoryLinkName)) {
                    subCategoriesWithProducts[productSubCategoryLinkName].push(product);
                } else {
                    subCategoriesWithProducts[productSubCategoryLinkName] = [product];
                }
            }
        });
        return subCategoriesWithProducts;
    };

    getSubCategories = () => {
        const { products } = this.state;
        const subCategoriesWithProducts = this.getSubCategoriesWithFilteredProducts(products);
        return Object.keys(subCategoriesWithProducts).map(subCategoryName =>
            ({ name: subCategoriesWithProducts[subCategoryName][0].productType, linkName: subCategoryName })
        );
    };

    getProducts = category => {
        const { boundGetProducts, basket, location } = this.props;

        this.setState({ products: [] });
        if (category) {
            this.toggleloaderSpinnerVisible(true);
            boundGetProducts(category.id, basket.id, this.props.addressData.addressSelection.address)
                .then(response => {
                    if (this.props.location.pathname.includes(category.link)) {
                        // Don't use here "const { location } = this.props;". Beware of a bug
                        this.setState({ products: response.data.result });
                    }
                    this.toggleloaderSpinnerVisible(false);
                });
        } else {
            toast(<Toast text={`Karegori bulunamadı: ${location.pathname}`} status="error"/>);
        }
    };

    toggleloaderSpinnerVisible = (state) => {
        this.setState({loaderSpinnerVisible: state});
    }

    getClassName = () => {
        const { products } = this.state;
        const currentCategory = this.getCurrentCategory();
        let className = 'product';
        if (currentCategory && this.hasSubCategories(currentCategory) && products.length) {
            className += ' product--with-subheader';
        }
        if (currentCategory && this.hasSizeCategories(currentCategory) && products.length) {
            className += ' product--with-2-subheaders';
        }
        return className;
    };

    getSubCategoryLinkClassName = subCategory => {
        const { location } = this.props;
        let className = 'subcategories-header__link';
        if (location.pathname === subCategory.link || location.pathname === subCategory.link + '/tumu') {
            className += ' subcategories-header__link--active';
        }
        if (this.getCurrentCategory().name !== 'Pizzalar') {
            className += ' subcategories-header__link--with-image';
        }
        return className;
    };

    getNumOfPeopleLinkClassName = numOfPeople => {
        const { currentNumOfPeople } = this.state;
        let className = 'subcategories-header__link';
        if (currentNumOfPeople === numOfPeople.name) {
            className += ' subcategories-header__link--active';
        }
        return className;
    };

    getSizeLinkClassName = size => {
        const { currentSize } = this.state;

        return currentSize === size.name
            ? 'subcategories-header__size-link subcategories-header__size-link--active'
            : 'subcategories-header__size-link';
    };

    getLinkImageClassName = subCategory => {
        const { location } = this.props;

        return location.pathname === subCategory.link || location.pathname === subCategory.link + '/tumu'
            ? 'subcategories-header__link-image subcategories-header__link-image--active'
            : 'subcategories-header__link-image';
    };

    getSizeLinkImageClassName = size => {
        const { currentSize } = this.state;

        return currentSize === size.name
            ? 'subcategories-header__size-link-image subcategories-header__size-link-image--active'
            : 'subcategories-header__size-link-image';
    };

    setNumOfPeopleSubCategory = numOfPeopleName => this.setState({ currentNumOfPeople: numOfPeopleName });

    getSelectNumOfPeopleSubCategory = currentNumOfPeople => () => {
        const { history } = this.props;

        this.setNumOfPeopleSubCategory(currentNumOfPeople.name);
        history.push(currentNumOfPeople.link);
    };


    getLinkImage = subCategory => {
        if (this.getCurrentCategory().name === 'Pizzalar') {
            return null;
        }
        return subCategory.image;
    };

    getLinksContainerClassName = () => {
        if (this.getCurrentCategory().name === 'Pizzalar') {
            return 'subcategories-header__links';
        }
        return 'subcategories-header__links subcategories-header__links--with-images';
    };

    getNumOfPeopleSubCategories = subCategory => {
        const currentCategory = this.getCurrentCategory();
        if (!currentCategory || !this.hasSubCategories(currentCategory)) {
            return [];
        }
        return subCategory.subCategories.filter(subCategory =>
            subCategory.name.includes('1') || subCategory.name.includes('2')
            || subCategory.name.includes('3') || subCategory.name.includes('4')
            || subCategory.name.includes('5') || subCategory.name.includes('6')
        );
    };

    closeNumOfPeopleDropdown = () => this.setState({ isNumOfPeopleDropdownOpened: false });

    openNumOfPeopleDropdown = () => this.setState({ isNumOfPeopleDropdownOpened: true });

    renderSubCategoriesHeader = () => {
        const { pizzaSizes, currentNumOfPeople } = this.state;
        const currentCategory = this.getCurrentCategory();
        let numOfPeopleSubCategories = this.getNumOfPeopleSubCategories(currentCategory);

        if (!currentCategory || !this.hasSubCategories(currentCategory)) {
            return null;
        }
        return <div className="subcategories-header">
            <div className="subcategories-header__body">
                <div className={this.getLinksContainerClassName()}>
                    <ProductCategoryLink linkUrl={currentCategory.link + '/tumu'}
                                         altTitle="tümü.png"
                                         imageUrl={this.getLinkImage({
                                             image: currentCategory.name.toLowerCase() === 'kampanyalar'
                                                 ? '/images/products/all_pizzas.png'
                                                 : '/images/products/allsideproducts.png'
                                         })}
                                         subCategoryLinkClassName={this.getSubCategoryLinkClassName(currentCategory)}
                                         linkTitle="tümü"
                                         linkImageClassName={this.getLinkImageClassName(currentCategory)}
                    />
                    {
                        currentCategory.subCategories
                            .sort((a, b) =>
                                a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0
                            )
                            .map(subCategory =>
                                subCategory.name.includes('1') || subCategory.name.includes('2')
                                || subCategory.name.includes('3') || subCategory.name.includes('4')
                                || subCategory.name.includes('5') || subCategory.name.includes('6')
                                    ? null
                                    : <ProductCategoryLink subCategory={subCategory}
                                                           subCategoryLinkClassName={this.getSubCategoryLinkClassName(subCategory)}
                                                           linkImageClassName={this.getLinkImageClassName(subCategory)}
                                                           imageUrl={this.getLinkImage(subCategory)}
                                                           key={subCategory.id}
                                    />
                            )
                    }
                    {
                        numOfPeopleSubCategories.length
                            ? <div onClick={this.openNumOfPeopleDropdown}
                                   className="subcategories-header__link-container subcategories-header__link-dropdown-container"
                            >
                                <img src="/images/products/number.png" alt="kişi sayıysı.png"
                                     className={this.getLinkImageClassName(numOfPeopleSubCategories[0])}
                                />
                                <div
                                    className="simple-dropdown__selected-item icon-with-label link subcategories-header__link-dropdown subcategories-header__link-caption">
                                    <span>{currentNumOfPeople}</span>
                                    <Icon name="chevron-down"/>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
            {
                this.hasSizeCategories(currentCategory)
                    ? <div className="subcategories-header__body subcategories-header__body--2">
                        <div className="subcategories-header__links">
                            {
                                pizzaSizes.map(size =>
                                    <ProductSizeLink size={size}
                                                     sizeLinkClassName={this.getSizeLinkClassName(size)}
                                                     imageUrl="/images/products/slice.png"
                                                     altTitle="slice.png"
                                                     linkImageClassName={this.getSizeLinkImageClassName(size)}
                                                     onClick={this.selectSize(size)}
                                    />
                                )
                            }
                        </div>
                    </div>
                    : null
            }
        </div>;
    };

    renderProducts = () => {
        const { loading, location, history } = this.props;
        const { products, currentSubCategoryName, currentSize, loaderSpinnerVisible } = this.state;
        let renderedProducts = [];
        const currentCategory = this.getCurrentCategory();
        if (!products.length) {
            if (loading) {
                return <div className={`product__loading ${loaderSpinnerVisible ? '' : 'dn'}`}>
                    <img src="/images/products/little-caesars-loading.gif"/>
                </div>;
            } else {
                return <div className="product__no-products">
                    Şu an size muhteşem pizzalarımızı gösteremiyoruz, ufak bir sorunla karşılaştık, bir süre sonra tekrar deneyiniz.
                </div>;
            }
        }

        /*get SubCategories with filtered items */
        let subCategoriesWithProducts = this.getSubCategoriesWithFilteredProducts(products);

        if (this.hasSubCategories(currentCategory) && !currentSubCategoryName) {
            this.setState({ currentSubCategoryName: Object.keys(subCategoriesWithProducts)[0] });
            if (location.pathname.split('/').length < 4) {
                history.push(location.pathname + '/tumu');
            }
        }
        if (location.pathname.includes('tumu')) {
            if (currentSize) {
                renderedProducts = products.filter(product => product.size === currentSize);
            } else {
                renderedProducts = products;
            }
        } else if (this.hasSubCategories(currentCategory)) {
            for (let i = 0; i < Object.keys(subCategoriesWithProducts).length; i++) {
                if (currentCategory.name.toLowerCase() !== 'yan ürünler') {
                    if (subCategoriesWithProducts[Object.keys(subCategoriesWithProducts)[i]][0].link.includes(this.getSubCategoryFromUrl())) {
                        renderedProducts.push(...subCategoriesWithProducts[Object.keys(subCategoriesWithProducts)[i]]);
                    }
                } else {
                    if (Object.keys(subCategoriesWithProducts)[i] === this.getLinkLastItem(location.pathname)) {
                        renderedProducts.push(...subCategoriesWithProducts[Object.keys(subCategoriesWithProducts)[i]]);
                    }
                }
            }
        }

        if (!renderedProducts.length) {
            return <div className="product__no-products"></div>;
        }

        //add impression for Google Analytics
        let productCategory;
        let counter = 0;
        let pos = 0;
        let productPager = 0
        const currentSubCategory = this.getCurrentSubCategory(currentCategory);
        productCategory = currentSubCategory !== null && currentSubCategory !== undefined ? currentSubCategory : currentCategory;
        const pCategory = productCategory.name.charAt(0).toUpperCase() + productCategory.name.substring(1);

        if (renderedProducts && renderedProducts.length && getFromLocalStorage('ecProducts') !== productCategory.name) {
            if (renderedProducts.length > 12) {
                while (counter <= renderedProducts.length) {
                    addImpressionEvent(renderedProducts.slice(counter, counter + 12), currentCategory.name, pCategory === currentCategory.name ? 'Tümü' : pCategory, productPager);
                    counter = counter + 12;
                    productPager++;
                }
            } else
                addImpressionEvent(renderedProducts, currentCategory.name, pCategory === currentCategory.name ? 'Tümü' : pCategory);

            setLocalStorage('ecProducts', productCategory.name);
        }

        if (currentCategory.name.toLowerCase() === 'yan ürünler' && location.pathname.includes('tumu')) {
            return <div className="product__body">
                <div className="product__subcategory-block">
                    {
                        currentCategory.subCategories
                            .sort((a, b) => a.orderIndex < b.orderIndex ? -1 : a.orderIndex > b.orderIndex ? 1 : 0)
                            .map(subCategory =>
                                subCategoriesWithProducts[this.getLinkLastItem(subCategory.link)].map(product => {
                                    pos++;
                                    return <ProductCard product={product}
                                                        onClick={this.getViewProductModal(product, pos)}
                                                        key={product.id}
                                    />;
                                })
                            )
                    }
                </div>
            </div>;
        }
        return <div className="product__body">
            <div className="product__subcategory-block">
                {
                    renderedProducts.map(product => {
                        pos++;
                        return <ProductCard product={product}
                                            onClick={this.getViewProductModal(product, pos)}
                                            key={product.id}
                        />;
                    })
                }
            </div>
        </div>;
    };

    renderPreviewModalContent = props => {
        const { productIdForModal, products } = this.state;
        const currentCategory = this.getCurrentCategory();
        if (!currentCategory || !productIdForModal) {
            return null;
        }
        const currentProduct = products.find(product => product.id === productIdForModal);

        if (currentCategory.name === 'Kampanyalar' || currentProduct.isCampaign) {
            return <ViewPromotionModal {...props} closeModal={this.closeModal} productId={productIdForModal}/>;
        } else {
            return <ViewProductModal {...props} closeModal={this.closeModal} productId={productIdForModal}/>;
        }
    };

    renderPreviewModal = props => {
        const { productIdForModal } = this.state;

        return <ModalWithAnimation closeModal={this.closeModal} showModalContent={!!productIdForModal}>
            {this.renderPreviewModalContent(props)}
        </ModalWithAnimation>;
    };

    renderCampaignFooter = currentCategory => {

        if (currentCategory && currentCategory.name === 'Kampanyalar') {
            return <div className="product__footer">

                <div className="product__footer-column">
                    <p className="product__footer-paragraph">
                        Little Caesars sizlere özel farklı fırsatlar ve teklifler sunmaya devam ediyor. Birbirinden
                        lezzetli pizza çeşitleriyle süslenen özel menülerde her kişinin damak zevkine ve bütçesine uygun
                        bir menü yer alıyor. Taze ve günlük pizza hamurlarıyla hazırlanan ve sezon mahsulü gerçek
                        malzemeler ile sizlere ulaşan pizzalar, mozzarella peyniriyle yeniden hayat buluyor.
                    </p>
                    <p className="product__footer-paragraph">
                        Dünya’nın önde gelen Pizza markaları arasında yer alan ve lezzetini uzun yıllardır koruyan
                        Little Caesars pizza fiyatları konusunda da sizlere pek çok farklı seçenek sunuyor. Little
                        Caesars pizza fiyatları seçeceğiniz pizzanın malzemeleri ve boyutları gibi detaylara göre
                        belirleniyor. Eşsiz pizza lezzetini tüm şubelerinde sunan Little Caesars ürünlerinde aradığınız
                        lezzeti en uygun fırsatlar eşliğinde bulabilirsiniz. 1 kişilik, 1-2 kişilik, 2-3 kişilik, 3-4
                        kişilik ve 5-6 kişilik gibi pek çok farklı seçeneğe sahip olan kampanyalar, pizzalar ve menüler
                        herkes için farklı seçeneklerle yer alıyor.
                    </p>
                </div>
                <div className="product__footer-column">
                    <p className="product__footer-paragraph">
                        Klasik lezzetlerden alternatif lezzetlere kadar farklı pizza seçenekleri için geçerli olan
                        kampanyalar ile uygun fiyatlı pizza sipariş edebilirsiniz.
                    </p>
                    <p className="product__footer-paragraph">
                        Herkese uygun lezzetler sunan Little Caesars’ın pizza kampanya seçeneklerini sürekli olarak
                        takip ederek yeni kampanyalardan da haberdar olabilirsiniz. Nefis kenarlardan lezzetli
                        soslarına, taptaze hamurundan malzemelerine kadar Little Caesars’ın fark yarattığı pizza
                        seçenekleri arasında hem tek pizzalar hem de pizza menüsü alternatifleri de yer alıyor. Sizler
                        de birbirinden lezzetli pizza siparişleri için Little Caesars kampanyaları ile tanışmaya
                        başlayabilirsiniz.
                    </p>
                </div>
            </div>;

        }
        return null;
    };

    render() {
        const { match } = this.props;
        const { isNumOfPeopleDropdownOpened } = this.state;
        const currentCategory = this.getCurrentCategory();
        const numOfPeopleSubCategories = this.getNumOfPeopleSubCategories(currentCategory);

        return <div className={this.getClassName()}>
            <Route path={`${match.url}/:productId`} component={this.renderPreviewModal}/>
            {
                currentCategory
                    ? <Helmet title={currentCategory.name}/>
                    : null
            }
            {this.renderSubCategoriesHeader()}
            {
                isNumOfPeopleDropdownOpened
                    ? <div className="simple-dropdown__items-modal-layout" onClick={this.closeNumOfPeopleDropdown}>
                        <div className="simple-dropdown__items-container subcategories-header__dropdown-items-container">
                            <div className="simple-dropdown__triangle"></div>
                            <div className="simple-dropdown__triangle simple-dropdown__triangle--2">
                                <div></div>
                            </div>
                            {
                                numOfPeopleSubCategories.map(numOfPeopleSubCategory =>
                                    <div className={this.getNumOfPeopleLinkClassName(numOfPeopleSubCategory)}
                                         onClick={this.getSelectNumOfPeopleSubCategory(numOfPeopleSubCategory)}
                                         key={numOfPeopleSubCategory.id}
                                    >
                                        {numOfPeopleSubCategory.name}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    : null
            }
            <div className="app-content-container">
                {this.renderProducts()}
                {this.renderCampaignFooter(currentCategory)}
            </div>
        </div>;
    }
}

const mapStateToProps = store => ({
    basket: store.basket.basket,
    productData: store.productData,
    addressData: store.addressData,
    loading: !!store.loader.loadingCount,
    user: store.user.user
});

const mapDispatchToProps = dispatch => ({
    boundGetProductDepartments: bindActionCreators(getProductDepartments, dispatch),
    boundGetProductSizes: bindActionCreators(getProductSizes, dispatch),
    boundSetRedirect: bindActionCreators(setRedirect, dispatch),
    boundGetProducts: bindActionCreators(getProducts, dispatch)
});

ProductManager.propTypes = {
    productData: PropTypes.object.isRequired,
    basket: PropTypes.object.isRequired,
    addressData: PropTypes.object.isRequired,
    boundGetProductDepartments: PropTypes.func.isRequired,
    boundGetProductSizes: PropTypes.func.isRequired,
    boundSetRedirect: PropTypes.func.isRequired,
    boundGetProducts: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductManager));
