import React from 'react';

const MenuChat = props =>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.994 0C15.201 0 18.2101 1.2482 20.4784 3.51628C22.7466 5.78436 24 8.79323 24 12C24 15.2068 22.7517 18.2156 20.4834 20.4837C18.1695 22.7975 15.0944 24 11.9889 24C10.0555 24 8.10698 23.5332 6.32586 22.5742C4.72743 23.726 3.16451 23.9746 2.10396 23.9746C1.70816 23.9746 1.3834 23.9391 1.15505 23.9036C0.667905 23.8275 0.2924 23.4469 0.216284 22.9598C0.140168 22.4727 0.388813 22.0008 0.830286 21.7827C1.74875 21.3311 2.36783 20.4279 2.75348 19.6516C-1.17918 14.9175 -0.874712 7.90021 3.50957 3.51628C5.77783 1.2482 8.78695 0 11.994 0ZM6.61003 21.1687C10.7812 23.6194 16.0839 22.9395 19.5092 19.5146C23.6549 15.3691 23.6549 8.63087 19.5041 4.48541C15.3634 0.339958 8.61949 0.339958 4.47371 4.48541C0.505525 8.45328 0.312697 14.8465 4.02716 19.0427C4.12357 19.1543 4.17939 19.2913 4.19461 19.4283C4.23013 19.5704 4.21999 19.7226 4.15909 19.8698C3.77851 20.7374 3.12899 21.8537 2.09889 22.6047H2.10396C2.99706 22.6047 4.34685 22.3814 5.72201 21.3159C5.73723 21.3006 5.75753 21.2854 5.77783 21.2753C5.99603 21.0571 6.33601 21.0063 6.61003 21.1687Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0016 12.8216C12.4555 12.8216 12.8235 12.4536 12.8235 11.9997C12.8235 11.5457 12.4555 11.1777 12.0016 11.1777C11.5477 11.1777 11.1797 11.5457 11.1797 11.9997C11.1797 12.4536 11.5477 12.8216 12.0016 12.8216Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.275 12.8216C16.729 12.8216 17.097 12.4536 17.097 11.9997C17.097 11.5457 16.729 11.1777 16.275 11.1777C15.8211 11.1777 15.4531 11.5457 15.4531 11.9997C15.4531 12.4536 15.8211 12.8216 16.275 12.8216Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.72426 12.8216C8.17819 12.8216 8.54618 12.4536 8.54618 11.9997C8.54618 11.5457 8.17819 11.1777 7.72426 11.1777C7.27033 11.1777 6.90234 11.5457 6.90234 11.9997C6.90234 12.4536 7.27033 12.8216 7.72426 12.8216Z" />
    </svg>;

export default MenuChat;
