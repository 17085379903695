import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../elements/Icon';

const DeliveryButtonBlock = props =>
    <div key={props.keyValue} className="delivery-button-block" onClick={props.openDeliveryModal}>
        <div className="delivery-button-block__left">
            <div className="delivery-button-block__icon">
                <img src="/images/order/delivery.png" alt=""/>
            </div>
            <div className="delivery-button-block__text-block">
                <div className="delivery-button-block__header">
                    {props.deliveryType ? props.deliveryType : ''}
                </div>
                <div className="delivery-button-block__text">
                    {props.address ? props.address : 'Cadde Seç'}
                </div>
            </div>
        </div>
        <div className="delivery-button-block__arrow-container">
            <Icon name="chevron-right" className="delivery-button-block__arrow"/>
        </div>
    </div>;

DeliveryButtonBlock.propTypes = {
    openDeliveryModal: PropTypes.func.isRequired,
    deliveryType: PropTypes.string,
    address: PropTypes.string,
    keyValue: PropTypes.string
};

export default DeliveryButtonBlock;
