import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';
import CustomerServiceMenu from '../../customer-service/customer-service-menu/CustomerServiceMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const Delivery30Min = () => {
    sendPageView('/kurumsal/30-dkk-teslim', '30 Dakikada Teslim Garantisi Koşulları - Little Caesars');  //for ga
    return  (
        <section class="institutional-structures">
            <div class="container">
                <BreadCrumbs />
                <div class="institutional-structures-detail">
                    <div class="institutional-structures-detail-content clearfix">
                <CustomerServiceMenu />
                <div class="col-md-9">
                            <div className="corpareta-detail">
                    <h1>30 Dakikada Teslim Garantisi Koşulları </h1>
                    <ul class="full-page-cont-list">
                        <li> • Siparişinizin 30 dakikadan geç teslim edilmesi durumunda, sipariş başına iade edilecek miktar, Little Caesars Pizza menüsünün en yüksek fiyatlı ürünü (ekstra malzemeler hariç, örnek: büyük boy Gurme Pizzalar 69,90 TL. vb.) kadardır. Fiş tutarından bu miktarda indirim yapılacaktır. Siparişin bedeli daha azsa müşteriden hiç bedel alınmayacak, fazlaysa aradaki fark müşteriden tahsil edilecektir.</li>
                        <li> • “30 Dakikada Teslim Garantisi” sipariş verilen şubenin servis sınırları içinde geçerlidir.</li>
                        <li> • 4 ürün ve üzerindeki siparişler, toplu sipariş sayılır ve garanti verilmez. Bu süreç Adrese Teslim, Gel-Al ve Araca teslim teslimat tipleri için de geçerlidir.</li>
                        <li> • Little Caesars Pizza, 30 dakikayı geçen teslimatlardan dolayı personeline ceza vermez ya da maaşlarından kesinti yapmaz.</li>
                        <li> • Little Caesars Pizza, garantiyi önceden haber vermeksizin geri çekme hakkını saklı tutar.</li>
                        <li> • Promosyon günlerinde, özel günlerde, kötü hava koşullarında ve saat 23:30’dan sonra garanti geçerli değildir.</li>
                        <li> • Hastane, okul, iş merkezi ve site gibi alanlarda, sipariş edilen ürünlerin, güvenlik ya da danışma birimlerine ulaşması itibariyle sona erer.</li>
                        <li> • Siparişinizin farklı bir şubeye yönlendirilmesi halinde yönlendirme yapılan şubeye iletildiği anda başlayacaktır.</li>
                        <li> • Adres bildirimlerindeki yazım hatalarında (cadde/sokak adı veya bina numarası hatalı yazıldığında) “30 Dakikada Teslimat Garantisi” verilmemektedir.</li>
                        <li> • Personelin sürüş güvenliği için servis alanları sınırlıdır.</li>
                    </ul>
                </div>
            </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Delivery30Min;