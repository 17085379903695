import Icon from "../elements/Icon";
import React from "react";
import PropTypes from "prop-types";

const DeliveryButton = props =>
    <div key={props.keyValue} className={`delivery-button ${props.className}`} onClick={props.openDeliveryModal}>
        {/* <Icon name="delivery" className="delivery-button__icon icon--red" /> */}
        <div className="delivery-button__icon"><img src="/images/order/delivery.png" alt=""/></div>
        <div className="delivery-button__text-block">
            <div className="delivery-button__header">{props.deliveryType ? props.deliveryType : ''}</div>
            <div className="delivery-button__text">{props.address ? props.address : 'Cadde Seç'}</div>
        </div>
        <div className="delivery-button__arrow-container">
            <Icon name="chevron-down" />
        </div>
    </div>;

DeliveryButton.propTypes = {
    openDeliveryModal: PropTypes.func.isRequired,
    deliveryType: PropTypes.string,
    address: PropTypes.string,
    keyValue: PropTypes.string
};

export default DeliveryButton;
