import React, { Component } from 'react';
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import Input from "./Input";
import { hideMobileMenu, showMobileMenu } from '../../utils';

class LcPhoneInput extends Component {
    onBlur = event => {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur(event);
        }
        showMobileMenu();
    };

    onFocus = event => {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus(event);
        }
        hideMobileMenu();
    };

    renderPhoneInput = () => {
        const { value, onChange } = this.props;
        return <PhoneInput value={value}
                           onChange={onChange}
                           disableDropdown
                           defaultMask="(...) ... .. .."
                           alwaysDefaultMask
                           inputClass="input__body"
                           country="tr"
                           countryCodeEditable={false}
                           specialLabel={null}
                           onFocus={this.onFocus}
                           onBlur={this.onBlur}
        />;
    };

    render() {
        const { value, className, validator, labelClassName, label, error, rootRef } = this.props;
    
        return <div className={className}>
            {
                label
                    ? <label className={labelClassName}>{label}</label>
                    : null
            }
            <Input type="phone"
                   name="phone"
                   inputComponent={this.renderPhoneInput()}
                   error={validator ? validator.message('telefon numarası', value, 'required|turkish_phone_number') : error}
                   rootRef={rootRef}
            />
        </div>;
    }
}

LcPhoneInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    validator: PropTypes.object,
    error: PropTypes.string,
    rootRef: PropTypes.object
};

export default LcPhoneInput;
