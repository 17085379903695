import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LcCarousel from '../elements/LcCarousel';
import { withRouter } from 'react-router-dom';
import Icon from '../elements/Icon';
import { bindActionCreators } from 'redux';
import { setModal } from '../../store/actions/modalActions';
import { getPromoImages, getMainPageCampaigns, setMainPageCampaigns } from '../../store/actions/homePageActions';
import { connect } from 'react-redux';
import { getPromotionCode } from '../../store/actions/productActions';
import { getOrders, copyOrderAndGetBasket } from '../../store/actions/orderActions';
import { getBasket } from '../../store/actions/basketActions';
import { formatMoney, calculateDateTimeBefore, orderStateIds, isMobile, MIN_DESKTOP_WIDTH } from '../../utils';
import TopInfoBlockItem from './TopInfoBlockItem';
import BottomInfoBlockItem from './BottomInfoBlockItem';
import LoyaltyBlockItem from './LoyaltyBlockItem';
import {
    addImpressionEvent, addClickProductEvent, addInternalPromotion, sendPageView
} from '../../googleAnalyticsHelper';
import { getFromLocalStorage, setLocalStorage } from '../../storage';

const lastOrdersCarouselBreakpoints = {
    320: {
        slidesPerView: 'auto',
        spaceBetween: 15
    },
    [MIN_DESKTOP_WIDTH]: {
        slidesPerView: 3,
        spaceBetween: 15
    }
};

const bottomPromoCarouselBreakpoints = {
    320: {
        slidesPerView: 'auto',
        spaceBetween: 15
    },
    [MIN_DESKTOP_WIDTH]: {
        slidesPerView: 3,
        spaceBetween: 30
    }
};

class HomeManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            couponCode: ''
        };
    }

    componentDidMount() {
        const { user, location } = this.props;

        this.getPromoImagesAndBanners();
        if (user.id) {
            this.getLastOrders();
        }
        // send first promo image to the GA
        sendPageView(location.pathname, 'Ana Sayfa');
        this.clearLocalStorage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.id !== prevProps.user.id && this.props.user.id) {
            this.getLastOrders();
        }
        if (
            this.props.addressData.addressSelection.type !== prevProps.addressData.addressSelection.type
            && this.props.addressData.addressSelection.type === 'Adrese Teslim'
        ) {
            this.getPromoImagesAndBanners();
        }
    }

    getPromoImagesAndBanners = () => {
        const { boundGetPromoImages, boundGetMainPageCampaigns, boundSetMainPageCampaigns, addressData } = this.props;
        const promoType = addressData.addressSelection.type === 'Adrese Teslim' ? 'PromoNew' : (addressData.addressSelection.type === 'Gel Al' ? 'PromoPickupNew' : 'PromoVehicleNew');
        boundGetPromoImages(promoType);
        boundSetMainPageCampaigns([]);  // need this to update swiper
        boundGetMainPageCampaigns();
    };

    getViewProductModal = product => () => {
        const { homePage, boundSetModal } = this.props;
        const position = homePage.mainPageCampaigns.findIndex(p => p.id === product.id) + 1;

        //Save list name & position to retrieve them later in google analytics
        setLocalStorage(`prodPos${product.id}`, position);
        setLocalStorage(`pList${product.id}`, 'Anasayfa Özel seçtiğimiz pizzalar');
        setLocalStorage(`pCategory${product.id}`, 'Tüm Kampanyalar');

        //Send action events for google analytics helper
        addClickProductEvent(product, position, 'Anasayfa Özel seçtiğimiz pizzalar', 'Tüm Kampanyalar');

        boundSetModal(`view-promotion/${product.id}`);
    };

    clearLocalStorage = () => {
        setLocalStorage('promoImage', '');
        setLocalStorage('mainPageCampaigns', '');
    };

    renderPromoBanners = () => {
        const { homePage } = this.props;
        if (homePage.promoImages && homePage.promoImages.length && getFromLocalStorage('promoImage') !== homePage.promoImages[0].name) {
            setLocalStorage('promoImage', homePage.promoImages[0].name);
            addInternalPromotion(homePage.promoImages, 'Promotion Impression');
        }

        return <>
            <div className="home__promo-block">
                <div className="home__promo-block-left">
                    <div className="home__promo-image-container"
                         onClick={this.getOnPromoImageClick(homePage.promoImages[0])}
                    >
                        <img className="home__promo-image"
                             src={homePage.promoImages[0].image}
                             alt={homePage.promoImages[0].name}
                        />
                    </div>
                </div>
                <div className="home__promo-block-right">
                    {
                        homePage.promoImages.map((promoImage, index) =>
                            index > 0 && index < 5
                                ? <div className="home__promo-image-container home__promo-block-right-image"
                                       key={promoImage.id}
                                       onClick={this.getOnPromoImageClick(promoImage)}
                                >
                                    <img src={promoImage.image}
                                         alt={promoImage.name}
                                         className="home__promo-image"
                                    />
                                </div>
                                : null
                        )
                    }
                </div>
            </div>
            <div className="home__carousel home__carousel--desktop-hidden">
                <LcCarousel showPagination={true}>
                    {
                        homePage.promoImages.map(promoImage =>
                            <div className="swiper-slide"
                                 key={promoImage.id}
                                 onClick={this.getOnPromoImageClick(promoImage)}
                            >
                                <img src={promoImage.imageMobile}
                                     alt={promoImage.name}
                                />
                            </div>
                        )
                    }
                </LcCarousel>
            </div>
        </>;
    };

    getOnPromoImageClick = promoImage => () => {
        const { homePage, history } = this.props;
        const position = homePage.promoImages.findIndex(c => c.id === promoImage.id) + 1;
        const list = 'Banner';
        const category = 'Tüm Kampanyalar';
        setLocalStorage('prodPos', position);
        setLocalStorage('pList', list);
        setLocalStorage('pCategory', category);
        addInternalPromotion(promoImage, 'Promotion Click', position);
        history.push(promoImage.link);
    };

    getRepeatOrderButtonClassName = () => {
        const { loading } = this.props;

        let result = ' last-orders-block__item-bottom-button link icon-with-label';
        if (loading) {
            result += ' button--disabled';
        }
        return result;
    };

    getLastOrders = () => {
        const { boundGetOrders } = this.props;
        boundGetOrders();
    };

    goToMyOrders = () => {
        const { history } = this.props;

        history.push('/profil/siparisler');
    };

    repeatOrder = orderId => event => {
        const { boundCopyOrderAndGetBasket, history, basket } = this.props;

        event.stopPropagation();
        boundCopyOrderAndGetBasket(orderId, basket.id).then(() => {
            history.push(`/sepet/sepetim`);
        });
    };

    renderOrder = order =>
        <div key={order.id} className="last-orders-block__item swiper-slide">
            <div className="last-orders-block__item-top-orange-line"/>
            <div className="last-orders-block__item-header">
                {calculateDateTimeBefore(order.orderDate)}
            </div>
            <div className="last-orders-block__item-body">
                {order.orderItems[0].quantity}x {order.orderItems[0].product}
            </div>
            <div className="last-orders-block__item-bottom">
                <div className="last-orders-block__item-bottom-price">
                    <span className="subtitle-2">{formatMoney(order.amount)} TL</span>
                </div>
                <div className={this.getRepeatOrderButtonClassName()}
                     onClick={this.repeatOrder(order.id)}
                >
                    <Icon name="refresh" color="white" className="button__icon"/>
                    <span className="button__text">Tekrarla</span>
                </div>
            </div>
        </div>;

    renderLastOrders = () => {
        const { orders } = this.props;
        let completeOrders = [];

        orders.forEach(order => {
            if (!orderStateIds.cancelled.includes(+order.orderStateId)) {
                completeOrders.push(order);
            }
        });
        if (!completeOrders || !completeOrders.length) {
            return null;
        }
        return (
            <div className="last-orders-block">
                <div className="last-orders-block__body">
                    <div className="last-orders-block__left">
                        <div className="last-orders-block__title">
                            Son Siparişleriniz
                        </div>
                        <div className="last-orders-block__button" onClick={this.goToMyOrders}>
                            tümünü gör
                        </div>
                    </div>
                    <div className="last-orders-block__right">
                        <LcCarousel breakpoints={lastOrdersCarouselBreakpoints}
                                    centeredSlides={isMobile()}
                                    showNavigation={!isMobile()}
                        >
                            {completeOrders.map(order => this.renderOrder(order))}
                        </LcCarousel>
                    </div>
                </div>
            </div>
        );
    };

    renderTopInfoBlock = () => {
        return <div className="top-info-block">
            <TopInfoBlockItem title="Sezarla Kazan"
                              details="4 dilim topla orta boy pizza kazan."
                              imageUrl="/images/homepage/loyalty-logo-with-circle.png"
                              imageName="loyalty-logo.png"
                              linkUrl="/siparis/kampanyalar"
                              linkTitle="Siparişe Başla"
                              color="indigo"
            />
            <TopInfoBlockItem title="Haftanın Kampanyaları"
                              details="Bu hafta sana özel neler var?"
                              imageUrl="/images/homepage/haftaninkampanyalari.png"
                              imageName="haftaninkampanyalari.png"
                              linkUrl="/siparis/kampanyalar/haftanin-kampanyalari"
                              linkTitle="Siparişe Başla"
                              color="orange"
            />
            <TopInfoBlockItem title="temassız sipariş"
                              details="Siparişinizi getirelim kapınıza asalım."
                              imageUrl="images/homepage/contactless-delivery.png"
                              imageName="contactless-delivery.png"
                              linkUrl="/siparis/kampanyalar"
                              linkTitle="siparişe başla"
                              color="yellow"
            />
        </div>;
    };

    handleInputChange = e => {
        this.setState({
            couponCode: e.target.value
        });
    };

    validateInput = () => {
        const { couponCode } = this.state;
        let inputIsvalid = true;

        if (!couponCode) {
            inputIsvalid = false;
        }

        return inputIsvalid;
    };

    handleFormSubmit = e => {
        const { boundGetPromotionCode, promotionCode } = this.props;
        const { couponCode } = this.state;
        e.preventDefault();

        if (this.validateInput()) {
            boundGetPromotionCode(couponCode).then(() => {
                this.props.history.push(promotionCode.productItem.link);
            });
        }
    };

    viewRestaurants = () => {
        const { history } = this.props;

        history.push('/franchise/franchise-basvuru-formu');
    };

    viewHygiene = () => {
        const { history } = this.props;

        history.push('/musteri-hizmetleri/covid-19-onlemleri-bildirisi');
    };

    openAddCouponModal = () => {
        const { boundSetModal } = this.props;
        boundSetModal('add-coupon');
    };

    renderMainPageCampaigns = () => {
        const { homePage } = this.props;

        //add impression event for Google Analytics
        if (
            homePage.mainPageCampaigns
            && homePage.mainPageCampaigns.length
            && getFromLocalStorage('mainPageCampaigns') !== homePage.mainPageCampaigns[0].name
        ) {
            setLocalStorage('mainPageCampaigns', homePage.mainPageCampaigns[0].name);
            addImpressionEvent(homePage.mainPageCampaigns, 'Anasayfa Özel seçtiğimiz pizzalar ', 'Tüm Kampanyalar');
        }

        return homePage.mainPageCampaigns.map(product =>
            <div key={product.id}
                 className="special-selections-block__item swiper-slide"
                 onClick={this.getViewProductModal(product)}
            >
                <div className="home-product__item-image">
                    {
                        product.image
                            ? <img src={product.image} alt={`${product.name}.png`}/>
                            : <img src={'/images/product/promo-placeholder-image.png'} alt="promo-placeholder.png"/>
                    }
                </div>
                <div className="home-product__info">
                    <div className="home-product__info-text">
                        <div className="home-product__price">{formatMoney(product.price.price)} TL</div>
                        <div className="home-product__name">{product.name}</div>
                        {
                            product.detail
                                ? <div className="home-product__details">{product.detail}</div>
                                : null
                        }
                    </div>
                    <div className="home-product__link-button-container">
                        <div className="home-product__link-button">
                            sipariş ver
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderLoyaltyBlock = () => {
        return <div className="loyalty-block">
            <div className="container">
                <div className="loyalty-block__header">
                    <img src="/images/homepage/loyalty-logo.png" alt="loyalty-logo.png" className="loyalty-block__logo"/>
                    <div className="loyalty-block__header-sub-text">Her<b> 40 TL</b> ve üzeri siparişinden <b>1
                        dilim</b> kazan. 4 <b>dilim</b> birliktir hediye pizzanı al.
                    </div>
                </div>
                {
                    isMobile()
                        ? <LcCarousel showPagination={true}>
                            <div className="swiper-slide">
                                <LoyaltyBlockItem imageUrl="/images/homepage/maskot-1.svg"
                                                  altTitle="maskot-1.png"
                                                  title="Sipariş ver"
                                                  details="İster web sitemizden ister uygulamamızdan siparişini ver."
                                                  triangleClassName="loyalty-block__triangle--1"
                                                  triangleContentText="1"
                                />
                            </div>
                            <div className="swiper-slide">
                                <LoyaltyBlockItem imageUrl="/images/homepage/maskot-2.png"
                                                  altTitle="maskot-2.svg"
                                                  title="Dilimleri biriktir"
                                                  details="Her 40 TL ve üzeri siparişinden 1 dilim kazan."
                                                  triangleClassName="loyalty-block__triangle--2"
                                                  triangleContentText="2"
                                />
                            </div>
                            <div className="swiper-slide">
                                <LoyaltyBlockItem imageUrl="/images/homepage/maskot-3.png"
                                                  altTitle="maskot-3.svg"
                                                  title="Orta boy pizza kazan"
                                                  details="4 dilim biriktir hediye pizzanı profil sayfandan al."
                                                  triangleClassName="loyalty-block__triangle--3"
                                                  triangleContentText="3"
                                />
                            </div>
                        </LcCarousel>
                        : <div className="loyalty-block__body">
                            <LoyaltyBlockItem imageUrl="/images/homepage/maskot-1.svg"
                                              altTitle="maskot-1.png"
                                              title="Sipariş ver"
                                              details="İster web sitemizden ister uygulamamızdan siparişini ver."
                                              triangleClassName="loyalty-block__triangle--1"
                                              triangleContentText="1"
                            />
                            <div className="arrow-image">
                                <img src="/images/homepage/arrow.png" alt="arrow.png"/>
                            </div>
                            <LoyaltyBlockItem imageUrl="/images/homepage/maskot-2.png"
                                              altTitle="maskot-2.svg"
                                              title="Dilimleri biriktir"
                                              details="Her 40 TL ve üzeri siparişinden 1 dilim kazan."
                                              triangleClassName="loyalty-block__triangle--2"
                                              triangleContentText="2"
                            />
                            <div className="arrow-image">
                                <img src="/images/homepage/arrow.png" alt="arrow.png"/>
                            </div>
                            <LoyaltyBlockItem imageUrl="/images/homepage/maskot-3.png"
                                              altTitle="maskot-3.svg"
                                              title="Orta boy pizza kazan"
                                              details="4 dilim biriktir hediye pizzanı profil sayfandan al."
                                              triangleClassName="loyalty-block__triangle--3"
                                              triangleContentText="3"
                            />
                        </div>
                }
            </div>
        </div>;
    };

    renderBottomInfoBlock = () => {
        return <div className="bottom-info-block">
            <div className="container">
                <div className="bottom-info-block__body">
                    <BottomInfoBlockItem title="Franchisee Fırsatları"
                                         details="Avantajlı franchisee fırsatlarımızdan yararlanmak için geç kalmayın!"
                                         imageClassName="image-coupon"
                                         imageUrl="/images/homepage/franchisee-firsatlari.jpg"
                                         altTitle="franchisee-firsatlari.jpg"
                                         linkTitle="Başvuru Formu"
                                         onClick={this.viewRestaurants}/>

                    <BottomInfoBlockItem title="kupon kodu kullan"
                                         details="Kupon kodun mu var? Buradan kullanabilirsin."
                                         imageClassName="image-coupon"
                                         imageUrl="/images/homepage/kupon-kodu-kullan.jpg"
                                         altTitle="kupon-kodu-kullan.jpg"
                                         linkTitle="kodu gir"
                                         onClick={this.openAddCouponModal}/>

                    <BottomInfoBlockItem title="Önceliğimiz Hijyen"
                                         details="Little Caesars Türkiye ailesi olarak müşterilerimizin ve çalışanlarımızın sağlığını çok önemseriz."
                                         imageClassName="image-coupon"
                                         imageUrl="/images/homepage/önceligimiz-hijyen.jpg"
                                         altTitle="önceligimiz-hijyen.jpg"
                                         linkTitle="Detay"
                                         onClick={this.viewHygiene}/>
                </div>
            </div>
        </div>;
    };

    renderMobileAppLinks = () => {
        return <div className="social-media">
            <div className="social-media__container">
                <div className="social-media__application-icon-header">
                    <p>Her yerden kolay sipariş için uygulamamızı indirin</p>
                </div>
                <div className="social-media__application-icon-body">
                    <ul>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=tr.com.littlecaesars"
                               rel="noopener noreferrer" target="_blank">
                                <img className="img-responsive" src="/images/footer/google-play.svg"
                                     alt="google-play.svg"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://itunes.apple.com/tr/app/little-caesars-t%C3%BCrkiye/id904413305?l=tr&mt=8"
                               rel="noopener noreferrer" target="_blank">
                                <img className="img-responsive" src="/images/footer/ios-appstore.svg"
                                     alt="ios-appstore.svg"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>;
    };

    render() {
        const { homePage, user, orders } = this.props;

        return (
            <div className="home">
                <div className="app-content-container home__container">
                    {
                        homePage.promoImages && homePage.promoImages.length
                            ? this.renderPromoBanners()
                            : null
                    }
                    {
                        user.id && orders.length
                            ? this.renderLastOrders()
                            : null
                    }
                    {this.renderTopInfoBlock()}
                    {
                        homePage.mainPageCampaigns && homePage.mainPageCampaigns.length
                            ? <div className="home__carousel">
                                <div className="special-selections-block">
                                    <div className="special-selections-block__header">
                                        Sana Özel Seçtiğimiz Pizzalar #Evdekal
                                    </div>
                                    <div className="special-selections-block__body">
                                        <LcCarousel breakpoints={bottomPromoCarouselBreakpoints}
                                                    centeredSlides={isMobile()}
                                                    showNavigation={!isMobile()}
                                        >
                                            {this.renderMainPageCampaigns()}
                                        </LcCarousel>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            : null
                    }
                    {this.renderLoyaltyBlock()}
                    {this.renderBottomInfoBlock()}
                    {this.renderMobileAppLinks()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    homePage: store.homePage,
    productData: store.productData,
    user: store.user.user,
    loading: !!store.loader.loadingCount,
    basket: store.basket.basket,
    orders: store.orderData.orders,
    addressData: store.addressData
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch),
    boundGetPromoImages: bindActionCreators(getPromoImages, dispatch),
    boundGetMainPageCampaigns: bindActionCreators(getMainPageCampaigns, dispatch),
    boundSetMainPageCampaigns: bindActionCreators(setMainPageCampaigns, dispatch),
    boundGetOrders: bindActionCreators(getOrders, dispatch),
    boundCopyOrderAndGetBasket: bindActionCreators(copyOrderAndGetBasket, dispatch),
    boundGetPromotionCode: bindActionCreators(getPromotionCode, dispatch),
    boundGetBasket: bindActionCreators(getBasket, dispatch)
});

HomeManager.propTypes = {
    boundGetPromoImages: PropTypes.func.isRequired,
    boundGetMainPageCampaigns: PropTypes.func.isRequired,
    boundGetOrders: PropTypes.func.isRequired,
    boundCopyOrderAndGetBasket: PropTypes.func.isRequired,
    boundSetMainPageCampaigns: PropTypes.func.isRequired,
    boundSetModal: PropTypes.func.isRequired,
    boundGetBasket: PropTypes.func.isRequired,
    homePage: PropTypes.object.isRequired,
    productData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    basket: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    addressData: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeManager));
