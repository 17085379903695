import React from 'react';

const ChevronLeft = props =>
    <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <path d="M4.87263 10L6 8.87263L2.29579 5.16842L6 1.46421L4.87263 0.336843L0.0410524 5.16842L4.87263 10Z" />
        </g>
        <defs>
            <clipPath>
                <path d="M6 10L0 10L-8.74228e-07 5.24537e-07L6 0L6 10Z" />
            </clipPath>
        </defs>
    </svg>;

export default ChevronLeft;
