import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../elements/TextInput';
import { connect } from 'react-redux';
import getValidator from '../../getValidator';
import Button from '../elements/Button';
import Icon from '../elements/Icon';
import { getCustomerPhoneId } from '../../store/actions/authActions';
import { bindActionCreators } from 'redux';

class ConfirmationPhoneSuggestionModal extends Component {

    state = {};

    submit = () => {
        const { setModal, boundGetCustomerPhoneId, changedUserData } = this.props;
        const customerPhoneNumber = changedUserData.phone;

        boundGetCustomerPhoneId(customerPhoneNumber);
        setModal('phone-confirmation');
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal('');
    };

    render() {
        const { messageText, modalHeading } = this.props;

        return (
            <div className="modal-content phone-confirmation-suggestion-modal">
                <div className="modal-content__header phone-confirmation-suggestion-modal__header">
                    Telefon Doğrulaması
                    <Icon name="delete" onClick={this.closeModal}
                          className="phone-confirmation-suggestion-modal__close-modal-icon"/>
                </div>

                <div className="phone-confirmation-suggestion-modal__label-block">
                    <div className="phone-confirmation-suggestion-modal__label">
                        SMS ile doğrulama yapılacaktır. Devam etmek istiyor musunuz?
                    </div>
                </div>
                <div className="phone-confirmation-suggestion-modal__buttons-block">
                    <Button color="light_green"
                            className="phone-confirmation-suggestion-modal__button"
                            onClick={this.submit}
                    >
                        Evet
                    </Button>
                    <Button color="white"
                            className="phone-confirmation-suggestion-modal__button"
                            onClick={this.closeModal}
                    >
                        Hayır
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    changedUserData: store.user.changedUserData
});

const mapDispatchToProps = dispatch => ({
    boundGetCustomerPhoneId: bindActionCreators(getCustomerPhoneId, dispatch),
});

ConfirmationPhoneSuggestionModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    boundGetCustomerPhoneId: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPhoneSuggestionModal);
