import React from 'react';
import CustomerServiceMenu from '../customer-service-menu/CustomerServiceMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';
import BreadCrumbs from '../../elements/BreadCrumbscustomerservices';

const TransactionGuide = () => {
    sendPageView('/musteri-hizmetleri/islem-rehberi', 'İşlem Rehberi - Little Caesars'); // for ga

    return <section class="institutional-structures">
    <div class="container">
        <BreadCrumbs />
        <div class="institutional-structures-detail">
            <div class="institutional-structures-detail-content clearfix">
                <CustomerServiceMenu />
                <div class="col-md-9">
                    <div class="corpareta-detail">
                        <h1>İŞLEM REHBERİ</h1>
                        <h2>Üyelik</h2>
                        <p><b>1. Yeni Üye Olma Linkine Tıklanması</b></p>
                        <p>www.littlecaesars.com.tr'ye üye olmak için ana sayfamızdan sağ üst köşede "ÜYE OL" seçeneğine tıklayınız.</p>
                        <p><b>2. Üyelik Formunun Doldurulması</b></p>
                        <p>Açılan "Üye Ol" sayfasında Ad, Soyad, E-posta, Cep Telefonu ve Şifre bilgilerini eksiksiz doldurarak ya da Facebook hesabınız ile üye olabilirsiniz.
                            Kampanyalardan e-mail kanalı ile haberdar olmak istiyorsanız, "Kampanyalardan haberdar olmak istiyorum", SMS kanalı ile kampanyalardan haberdar olmak istiyorsanız "SMS ile bilgilendirilmek istiyorum" kutucuklarını işaretleyebilirsiniz.
                            "Kullanım Koşullarını ve Gizlilik Sözleşmesini okudum kabul ediyorum." kutucuğunu işaretleyip "Kayıt Ol" butonuna tıklayarak üyeliğinizi tamamlayabilirsiniz. Üyelik işlemi gerçekleştiğinde, e-posta adresinize mail gönderilecektir.
                            3. Profil Bilgilerinin Görüntülenmesi ve/veya Değişiklik Yapılması
                            Ana sayfamızdan sağ üst köşede yer alan, Hoş geldiniz (isminiz)'e tıklayarak, Üyelik Bilgilerinizi, Önceki Siparişlerinizi, Kayıtlı Adreslerinizi, Favori Ürünlerinizi, Siparişinizin Nerede olduğunu görebilir ve Çıkış yapabilirsiniz.
                            "Üyelik Bilgilerim" sekmesinden, e-posta adresinizi ve "Şifremi Değiştir" bölümünden de şifrenizi güncelleyebilirsiniz.
                            "Siparişlerim" sekmesinden siparişlerinizin sipariş tarihini, tutarını, durumunu görebilirsiniz.
                            "Adreslerim" sekmesinden yeni adres bilgisi girebilir veya girdiğiniz adresleri düzenleyebilirsiniz ya da silebilirsiniz.
                            "Siparişim Nerede?" sekmesinden teslimatını beklediğiniz siparişinizin aşamaları online takip edebilirsiniz. </p>
                        <h2>Üye Girişi Adımları</h2>
                        <p><b>1. Üye Girişi Yapılması</b></p>
                        <p>www.littlecaesars.com.tr adresi üzerinden sağ üst köşedeki "Giriş Yap" linkine tıklayınız. Açılan sayfada, üyeliğinizi oluştururken kullandığınız e-posta adresinizi ve belirlediğiniz şifre alanlarını doldurarak "Giriş Yap" butonuna basınız. <br />
                            Dilerseniz, Facebook üyelik bilgileriniz ile doğrudan girebilirsiniz. </p>
                        <p><b> 2. Üyelik Şifresinin Unutulması ve Tekrar Talep Edilmesi</b></p>
                        <p>Eğer şifrenizi unuttuysanız "Şifremi Unuttum" linkine tıklayınız. Açılan pop-up penceredeki e-posta alanına üyeliğinizi oluştururken kullandığınız e-posta adresinizi girin ve "Şifremi Hatırla" butonuna basınız. Şifreniz size e-posta ile bildirilecektir.</p>
                        <p><b> 3. Ürünlerin Teslimat Adresinin Oluşturulması</b></p>
                        <p> "Teslimat Adresi" sayfasında, siparişiniz adresinize teslim edilmesini istiyorsanız, adres bilgilerinizi girerek kayıt oluşturup kaydetmelisiniz.</p>
                        <h2> Ürün Satın Alma Adımları</h2>
                        <p><b> 1. www.littlecaesars.com.tr Sitesinin Açılması</b></p>
                        <p> www.littlecaesars.com.tr adresine girip sipariş verebilirsiniz.</p>
                        <p><b> 2. Sepete Ürün Eklenmesi</b></p>
                        <p> Sipariş verirken seçtiğiniz pizzanın adedi, boyutu, kenar seçeneği, ve ekstra malzemelerini seçtikten sonra "Sepete Ekle" butonu ile ekleyebilirsiniz.</p>
                        <p><b> 3. Alışveriş Sepetinde Değişiklik Yapılması</b></p>
                        <p>Sepetiniz sayfasında adet kısmından ürününüzün adedini değiştirebilir, ürününüzün KDV dahil toplam tutarını görebilir veya sepetinizden ürünleri silebilirsiniz.</p>
                        <p><b>4. Alışveriş Sepetinizdeki Ürünlerin Satın Alınması</b></p>
                        <p> Sepet sayfasının ilk aşamasında sepetinizdeki ürünleri görüntüleyebilirsiniz. "Siparişi Ver" butonuna bastığınızda, siparişinizin işleme alınması için asgari sepet tutarının 9,90.-TL olması gerekmektedir. <br />
                            "Sipariş Ver" butonuna tıkladığınız zaman, henüz üye girişi yapmamış iseniz, Üye Girişi sayfasına yönlendirileceksiniz. Açılan sayfada, üyeliğinizi oluştururken kullandığınız e-posta adresinizi ve belirlediğiniz şifrenizi kullanarak veya Facebook üyelik bilgilerinizi kullanarak giriş yapabilirsiniz.</p>
                        <p><b> 5. Kampanya Kodu</b></p>
                        <p> Little Caesars Pizza ya da anlaşmalı olduğu şirketlerden temin edilmiş kampanya kuponunuz var ise, "Siparişi Ver" butonunun altındaki kutucuğa ya da ürün detay sayfasındaki "Kampanya Kodu" alanına ilgili kodu girerek, "Ekle" butonuna basınız. Kuponun geçerli olması halinde, indirim miktarı otomatik olarak sipariş tutarına yansıyacaktır.</p>
                        <p><b> 6. Ödeme Şekli Bilgilerinin Siteye Girilmesi</b></p>
                        <p> Ödeme Şekli bölümünden, seçtiğiniz şubeye bağlı olarak ekranda görünen Kapıda Ödeme seçeneklerinden (Nakit, Kredi Kartı, Sodexo, Ticket Restaurant, Web (Online), Pass Card, Smart Ticket, Multinet, SetCard.) herhangi biri seçilir.</p>
                        <p><b> 7. Gönderim Zamanı</b></p>
                        <p> Siparişinizin, gönderim zamanını hemen ya da ileri bir tarih ve saat olarak seçebilirsiniz.</p>
                        <p><b> 8. Sipariş Notu</b></p>
                        <p> Siparişiniz ile ilgili Little Caesars’a iletmek istediğiniz not var ise, Sipariş Notu bölümünü kullanabilirsiniz. Sipariş notlarınızı kaydedebilirsiniz.</p>
                        <p><b> 9. Siparişin Tamamlanması</b></p>
                        <p> Ödeme için bilgilerinizi doğru girdiğinizde siparişinizin tamamlandığını belirten bir sayfa karşınıza gelecektir. Bu sayfada sipariş numaranız bulunmaktadır. Siparişiniz ile ilgili bilgiler aynı şekilde e-posta adresinize de gönderilmektedir.</p>
                        <p><b> 10. Onay ve Sonrası</b></p>
                        <p> Siparişinize dair Little Caesars'tan kaynaklı bir iptal olması durumunda ise çağrı merkezi ya da şube tarafından aranarak bilgilendirileceksiniz.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
};

export default TransactionGuide;