import React from 'react';
import PropTypes from 'prop-types';
import { calculateDateTimeBefore, formatMoney, orderStateIds, renderOrderItemDetailString } from '../../../utils';
import Icon from '../../elements/Icon';

const renderOrderStateIcon = orderStateId => {
    const id = +orderStateId;
    if (
        orderStateIds.orderTaken.includes(id)
        || orderStateIds.preparing.includes(id)
        || orderStateIds.inDelivery.includes(id)
        || orderStateIds.orderTaken.includes(id)) {
        return <Icon name="info" className="icon--yellow-light"/>;
    }
    if (orderStateIds.delivered.includes(id)) {
        return <Icon name="check-filled" className="icon--black"/>;
    }
    if (orderStateIds.cancelled.includes(id)) {
        return <Icon name="delete" className="icon--red"/>;
    }
};

const renderOrderStateTitleClassName = orderStateId => {
    const id = +orderStateId;
    return !orderStateIds.delivered.includes(id) ?
        'order-preview__header-status-title order-preview__header-status-title--active'
        :
        'order-preview__header-status-title';
};

const renderOrderStateTitle = order => {
    const id = +order.orderStateId;

    return !orderStateIds.delivered.includes(id)
        ? order.orderState
        : order.orderState + ' Edildi';
};

const getProductListString = order => {
    let products = '';
    if (order.orderItems && order.orderItems.length) {
        order.orderItems.forEach(item => products += renderOrderItemDetailString(item));
    }
    return products;
};

const Order = ({ order, onShowOrderCommentModalClick, onShowOrderDetailsModalClick }) => {

    return <div className="order-preview profile-order__item">
        <div className=" order-preview__header">
            <div className="order-preview__header-status">
                <div className="order-preview__header-status-icon">
                    {renderOrderStateIcon(order.orderStateId)}
                </div>
                <div className={renderOrderStateTitleClassName(order.orderStateId)}>
                    {renderOrderStateTitle(order)}
                </div>
            </div>
            <div className="order-preview__header-date">
                <span>{calculateDateTimeBefore(order.orderDate)}</span>
            </div>
        </div>
        <div className="order-preview__body">
            <div className="order-preview__body-order-row">
                <div className="order-preview__product-list-string">
                    {getProductListString(order)}
                </div>
                <div className="order-preview__body-price">
                    <span>{formatMoney(order.amount)} TL</span>
                </div>
            </div>

            <div className="order-preview__buttons-container">
                <div className="button button--light link order-preview__button-grey order-preview__button"
                     onClick={onShowOrderCommentModalClick}
                >
                    <span className="button__text">yorumla</span>
                </div>
                <div className="button button--light link order-preview__button-grey order-preview__button"
                     onClick={onShowOrderDetailsModalClick}
                >
                    <span className="button__text">detay</span>
                </div>
            </div>
        </div>
    </div>;
};

Order.propTypes = {
    order: PropTypes.shape({
        orderStateId: PropTypes.string.isRequired,
        orderDate: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired
    }).isRequired,
    iconName: PropTypes.string,
    onShowOrderCommentModalClick: PropTypes.func.isRequired,
    onShowOrderDetailsModalClick: PropTypes.func.isRequired
};

export default Order;
