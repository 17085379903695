import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { postContactForm } from '../../../../store/actions/memberActions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Validator from 'validator';
import { GOOGLE_MAPS_API_KEY } from '../../../../utils';
import BreadCrumbs from '../../../elements/BreadCrumbs';
import TextInputField from '../../../elements/TextInput';
import Checkbox from '../../../elements/Checkbox';
import { sendPageView } from '../../../../googleAnalyticsHelper';
import { toast } from 'react-toastify';
import Toast from '../../../elements/Toast';
import CorporateMenu from '../../corporate-menu/CorporateMenu';
import GoogleMapReact from 'google-map-react';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                subject: '',
                nameSurname: '',
                mail: '',
                phone: '',
                address: '',
                message: '',
                isAgreementAccepted: false
            },
            formSubmitted: false,
            formIsvalid: false,
            touched: {},
            errors: {}
        };

        this.myOptions = [
            { label: 'Öneri', value: 'oneri' },
            { label: 'Şikâyet', value: 'sikayet' },
            { label: 'Memnuniyet', value: 'memnuniyet' }
        ];
    }

    componentDidMount = () => {
        const { location } = this.props;

        sendPageView(location.pathname, 'İletişim Formu - Little Caesars'); // for google analytics

    };

    setUpMap = ({ map, maps }) => {

        this.map = map;
        this.maps = maps;


    };
    handleInputChange = e => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            fields: {
                ...this.state.fields, [e.target.name]: value
            }
        }, () => this.validateForm());
    };

    handleInputBlur = e => {
        const { touched } = this.state;

        if (e.target.name && touched[e.target.name] !== true) {
            touched[e.target.name] = true;
            this.setState({
                touched
            }, () => this.validateForm());
        }
    };


    handleSubjectChange = selectedOption => {
        this.setState({
            fields: {
                ...this.state.fields,
                subject: selectedOption
            }
        });
    };


    handleFormSubmit = e => {
        const { fields } = this.state;
        const { boundPostContactForm } = this.props;
        e.preventDefault();

        if (this.validateForm()) {
            this.setState({
                errors: {},
                formSubmitted: true
            });

            boundPostContactForm(fields.subject.value, fields.nameSurname, fields.mail,
                fields.phone, fields.address, fields.message)
                .then(() => {
                    this.resetForm();
                    toast(
                        <Toast text="Başvurunuz alınmıştır, sizinle en yakın zamanda iletişime geçilecektir."
                               status="success"/>,
                        { autoClose: 3000 }
                    );
                })
                .catch(() => {
                    toast(<Toast text="Başvurunuz alınırken bir sorun oluştu, bir süre sonra tekrar deneyiniz."
                                 status="error"/>);
                });
        }
    };

    resetForm = () => {
        let fields = {};
        fields['subject'] = '';
        fields['nameSurname'] = '';
        fields['mail'] = '';
        fields['phone'] = '';
        fields['address'] = '';
        fields['message'] = '';
        fields['isAgreementAccepted'] = false;
        let touched = {};
        this.setState({
            fields,
            touched,
            isAgreementAccepted: false,
            formSubmitted: false,
            formIsValid: false
        });
    };

    validateForm = () => {
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;
        this.setState({ errors });
        if (!fields.subject) {
            formIsValid = false;
            errors.subject = 'Lütfen konu seçiniz';
        }
        //validate nameSurname
        if (!fields.nameSurname) {
            formIsValid = false;
            errors.nameSurname = 'Lütfen Ad ve Soyad giriniz';
        }

        //validate email
        if (!fields.mail) {
            formIsValid = false;
            errors.mail = 'Lütfen email adresi giriniz';
        } else {
            if (!Validator.isEmail(fields.mail)) {
                formIsValid = false;
                errors.mail = 'Yanlış formatta email adresi girdiniz';
            }
        }

        //validate telephone
        if (!fields.phone) {
            formIsValid = false;
            errors.phone = 'Lütfen Telefon giriniz';
        } else {
            if (!fields.phone.match(/^[5]{1}[0-9]{9}/)) {
                formIsValid = false;
                errors.phone = 'Telefon numarasını 5 ile başlayıp sadece rakamlardan oluşacak şekilde giriniz';
            } else {
                if (!fields.phone.length === 10) {
                    formIsValid = false;
                    errors.phone = 'Telefon Numarası 10 karakter olmalıdır';
                }
            }
        }

        //validate city
        if (!fields.address) {
            formIsValid = false;
            errors.invCity = 'Lütfen adresinizi giriniz';
        }

        //validate district
        if (!fields.message) {
            formIsValid = false;
            errors.message = 'Lütfen mesaj alanını boş bırakmayınız';
        }
        if (!fields.isAgreementAccepted) {
            formIsValid = false;
        }
        this.setState({
            errors,
            formIsValid
        });

        return formIsValid;
    };

    render() {
        const {
            fields, formSubmitted, formIsValid, cityList, districtList,
            touched, errors
        } = this.state;
        return (
            <section className="institutional-structures">
                <div className="container">
                    <BreadCrumbs/>
                    <div className="institutional-structures-detail">
                        <div className="institutional-structures-detail-content clearfix">
                            <CorporateMenu/>
                            <div class="col-md-9">
                                <div class="corpareta-detail clearfix">
                                    <h1>İletişim</h1>

                                    <div class="contact-map" id="contactMap">
                                        <GoogleMapReact
                                            bootstrapURLKeys={GOOGLE_MAPS_API_KEY}
                                            defaultZoom={12}
                                            center={[41.0287886, 28.8119387]}
                                            yesIWantToUseGoogleMapApiInternals/>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="contact-text">
                                                <h2>Bize Yazın</h2>
                                                <p>Merkez Ofis</p>
                                                <a href="mailto:corporate@littlecaesars.com.tr">corporate@littlecaesars.com.tr</a>
                                                <p>Destek</p>
                                                <a href="mailto:info@littlecaesars.com.tr">info@littlecaesars.com.tr</a>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="contact-text">
                                                <h2>Bizi Arayın</h2>
                                                <p>Merkez Ofis</p>
                                                <a onclick="ga('send', 'event', 'Telefon Arama', 'Click', 'CONTACT');"
                                                   href="tel:+902126041414">+90 212 604 14 14</a>
                                                <p>Faks</p>
                                                <a href="tel:+902126303535">+90 212 630 35 35</a>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="contact-text adress-contact">
                                                <h2>Bizi Ziyaret Edin</h2>
                                                <p>Merkez Adres</p>
                                                <span>15 Temmuz Mah. Bahar Cad. Polat iş Merkezi<br/> No:6 A. Blok Kat:1 34212<br/> Bağcılar-Güneşli/İstanbul</span>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="basic-form" onSubmit={this.handleFormSubmit}>
                                        <div className="row">
                                            <div className="col-xs-12 col-md-9">
                                                <div className="form-group">
                                                    <Select options={this.myOptions}
                                                            name="invAmount"
                                                            value={fields.subject}
                                                            placeholder="Mesaj Konusunu Seçin"
                                                            components={{ ContactForm }}
                                                            onChange={this.handleSubjectChange}
                                                            error={(formSubmitted || touched.subject) ? errors.subject : null}/>
                                                </div>
                                                <TextInputField type="text"
                                                                placeholder="*Ad Soyad"
                                                                name="nameSurname"
                                                                value={fields.nameSurname}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.nameSurname) ? errors.nameSurname : null}/>

                                                <TextInputField type="email"
                                                                placeholder="*E-Mail"
                                                                name="mail"
                                                                value={fields.mail}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.mail) ? errors.mail : null}/>

                                                <TextInputField type="text"
                                                                placeholder="*Telefon"
                                                                name="phone"
                                                                value={fields.phone}
                                                                minlength="10"
                                                                maxlength="10"
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.phone) ? errors.phone : null}/>


                                                <TextInputField type="text"
                                                                placeholder="Adres"
                                                                name="address"
                                                                value={fields.address}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.address) ? errors.address : null}/>

                                                <TextInputField type="text"
                                                                placeholder="*Mesajınız"
                                                                name="message"
                                                                value={fields.message}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                                error={(formSubmitted || touched.message) ? errors.message : null}/>

                                                <Checkbox className="lc-chekbox"
                                                          activeColor="black"
                                                          label="Kişisel Verilerin Korunması Kanumu'na ait"
                                                          name="isAgreementAccepted"
                                                          value={fields.isAgreementAccepted}
                                                          onChange={this.handleInputChange}/>
                                                <Link to="/musteri-hizmetleri/kisisel-verilerin-korunmasi"
                                                      target="_blank">sözleşmeyi</Link> okudum ve onaylıyorum.
                                                {/* <input type="text" placeholder="*Adres" formControlName="cAddress" /> */}

                                                <button type="submit"
                                                        className={`big-button big-button--orange ${!formIsValid ? ' big-button--disabled' : ''}`}>Gönder
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

const mapDispatchToProps = dispatch => ({
    boundPostContactForm: bindActionCreators(postContactForm, dispatch)
});

ContactForm.propTypes = {
    boundPostContactForm: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(ContactForm);

