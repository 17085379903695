import React from 'react';
import BreadCrumbs from '../../elements/BreadCrumbs';
import CorporateMenu from '../../corporate/corporate-menu/CorporateMenu';
import { sendPageView } from '../../../googleAnalyticsHelper';

const ManagerMessage = () => {
     sendPageView('/kurumsal/genel-mudur-mesaj', 'Müdür Mesajı - Little Caesars');
     
    return <section class="institutional-structures">
            <div class="container">
                 <BreadCrumbs />

                <div class="institutional-structures-detail">
                    <div class="institutional-structures-detail-content clearfix">
                        <CorporateMenu />
                        <div class="col-md-9">
                            <div class="corpareta-detail">
                                <h1>Genel Müdür’ün Mesajı</h1>
                                <div class="row">
                                    <div class="col-md-4">
                                        <img class="img-responsive" src="/images/corporate/genel-mudur.jpg" alt="genel-mudur.jpg" />
                                        <div class="general-manager-title">
                                            <h3>Banu Güney Arıduru</h3>
                                            <p>Çelebi Grubu, Little Caesars<br />Türkiye Genel Müdürü</p>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                    <p>
    Değerli Little Caesars Dostları;
</p>
<p>
    Dünyanın en hızlı büyüyen Franchise zincirlerinden biri olarak beş kıtada 6 bine
    yakın şube ile hizmet veriyoruz. Türkiye’de ise 1997’den bu yana Türkiye
    ekonomisinin önemli oyuncularından Çelebi Holding yönetiminde
    faaliyetlerimizi sürdürüyoruz.
</p>
<p>
    Her geçen gün sizlerin değerli seçimleriyle büyüyen ve güçlenen bir
    markayız. Hali hazırda İstanbul, Adana, Ankara, Balıkesir, Bursa,
    Çanakkale, Eskişehir, Hatay, Kocaeli, Mersin, Muğla, Sakarya, Samsun,
    Tekirdağ ve Zonguldak olmak üzere 14 ilde 85 şubemiz ve 1700 çalışanımız
    ile hizmet veriyoruz.
</p>
<p>
    Pizza deyince akla, pizza hamuru, mozzarella peyniri ve domates sosu gelir.
    Bizi de rakiplerimizden ayıran en önemli farkımız bu üç malzemede gizli.
    Little Caesars olarak her gün her şubemizde yeniden hazırlanan taze, günlük
    hamur kullanıyoruz. Çocuklarımızla birlikte keyifle yediğimiz harika
    pizzalarımızın arkasında günlük hamur yanında taze mozzarellamız ve
    mevsiminde toplanan domateslerden yapılan sosumuzun lezzeti var. Bu sayede
    bu kadar lezzetli pizzaları pizzaseverlere sunabiliyoruz, takdir
    topluyoruz. Kurumumuz, son 12 yıldır Amerika’da “ödenen paranın karşılığını
    en iyi veren marka” seçiliyor.
</p>
</div>
                                </div>
                               
<p>
    Türkiye’de nefis kenarın mucidiyiz ve düzenli olarak en yenilikçi ürünleri
    tüketicilerimizle buluşturuyoruz. Pide de Pizza, Sezar Et Döner ve Sezar 4
    Boyutlu Pizza bunlardan bazıları… Bundan sonra da sizleri mutlu edecek
    yenilikçi lezzetleri beğeninize sunmaya devam edeceğiz. Biz, enfes
    lezzetlerimiz ile bol ve lezzetli akşam yemeği arayan ailelerden ekonomik
    ve duyurucu menü arayan öğrencilere, acelesi olan gençlerden, gamerlara
    kadar her yaşa ve kitleye sesleniyoruz. Bu kadar geniş bir yelpazeye
    seslenebiliyor olmamızı yüksek kalitemiz ve doyumsuz lezzetlerimize
    borçluyuz.
</p>
<p>
    Her zaman en iyiyi sunmak için canla başla çalışan ekiplerimiz ve
    lezzetlerimiz, aslında bir telefon kadar sizlere yakın. 444 52 44
    numarasını arayarak, gece geç saatlere kadar canınız her pizza çektiğinde
    sipariş verebilir, 245 derecede ve yüksek hijyen standartlarında hazırlanan
    enfes lezzetlerimizi tadabilirsiniz.
</p>
<p>
    Eğer ki Little Caesars lezzetlerinin müdavimi iseniz sizi bir üst noktaya,
    yatırımcımız olmaya davet ediyoruz. İnsan sevdiği işi yapmalı ki başarılı
    olsun. Keyifle ve azimle çalışan ailemizin bir profesyoneli ya da
    Franchisee’si olmak isterseniz sizlerle görüşüp tanışmaktan mutlu oluruz.
    Bu işe inanmak ve benimsemek çok önemli. Böyle düşünen tüm yatırımcıları
    ailemize davet ediyoruz. Bol pizzalı ve nefis kenarlı günleriniz olsun!
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
};

export default ManagerMessage;