import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setModal } from '../../store/actions/modalActions';

class LoyaltyPreviewButton extends Component {
    onButtonClick = () => {
        const { user, history, boundSetModal } = this.props;
        if (user.loyalty.punchCard.canGetGift === 'true') {
            history.push('/sezarla-kazan');
        } else {
            history.push('/siparis/kampanyalar');
        }
        boundSetModal('');
    };

    getButtonClassName = () => {
        const { user } = this.props;
        let className = 'button loyalty-preview-button';
        if (user.loyalty.punchCard.canGetGift === 'true') {
            className += ' button--green-light';
        } else {
            className += ' button--grey';
        }
        return className;
    };

    getButtonText = () => {
        const { user } = this.props;
        if (user.loyalty.punchCard.canGetGift === 'true') {
            return 'Hediyeni Al';
        }
        return 'Siparişe Başla';
    };

    render() {
        return <div className={this.getButtonClassName()} onClick={this.onButtonClick}>
            {this.getButtonText()}
        </div>;
    }
}

const mapStateToProps = store => ({
    user: store.user.user
});

const mapDispatchToProps = dispatch => ({
    boundSetModal: bindActionCreators(setModal, dispatch)
});

LoyaltyPreviewButton.propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    boundSetModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoyaltyPreviewButton));
