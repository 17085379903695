import React from 'react';
import PropTypes from "prop-types";
import Icon from "../../elements/Icon";
import Button from "../../elements/Button";
import Popup from "../../elements/Popup";

const RemoveAddressPopup = props => {
    const { close, submit } = props;

    return <Popup>
        <Icon name="info" className="popup__icon" />
        <div className="popup__header">Adres sil</div>
        <div className="popup__detail">
            Adresi silmek istediğinizden emin misiniz?
        </div>
        <Button className="popup__submit-button" onClick={close}>
            İptal
        </Button>
        <div>
            <span className="link cancel-button" onClick={submit}>
                Evet
            </span>
        </div>
    </Popup>;
};

RemoveAddressPopup.propTypes = {
    submit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
};

export default RemoveAddressPopup;
